import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { months } from "../../../utils/commonVariables";
import { getTwoDigits, getDaysInMonth } from "../../../utils/helper";

function DateOfBirth(props) {
  const [user, setUser] = useState(props.user),
    [dobDay, setDobDay] = useState(
      props.user.user.profile.dob
        ? props.user.user.profile.dob.split("-")[0]
        : ""
    ),
    [dobMonth, setDobMonth] = useState(
      props.user.user.profile.dob
        ? props.user.user.profile.dob.split("-")[1]
        : ""
    ),
    [dobYear, setDobYear] = useState(
      props.user.user.profile.dob
        ? props.user.user.profile.dob.split("-")[2]
        : ""
    ),
    [dobPrivate, setDobPrivate] = useState(
      props.user.user.profile.dob_private || 3
    ),
    [currentMonth, setCurrentMonth] = useState(new Date().getMonth()),
    [currentDate, setCurrentDate] = useState(new Date().getDate()),
    { addToFilledItems, removeFromFilledItems } = props;
  useEffect(() => {
    setUser(props.user);
  }, [props.user]);

  const _renderMonths = () => {
    return [
      <option key={`month-0`} value="">
        Month
      </option>,
      ...months.map((month, monthIndex) => {
        return (
          <option
            disabled={
              dobYear == new Date().getFullYear() - 13
                ? month.numeric <= currentMonth + 1
                  ? false
                  : true
                : false
            }
            value={month.numeric}
            key={`month-${monthIndex + 1}`}
          >
            {month.fullName}
          </option>
        );
      }),
    ];
  };

  const _renderDate = () => {
    let dates = [
      <option key={`dates-0`} value="">
        Date
      </option>,
    ];
    const daysInCurrentMonth = getDaysInMonth(dobYear, dobMonth);
    for (let i = 1; i <= daysInCurrentMonth; i++) {
      const value = getTwoDigits(i);
      dates.push(
        <option
          disabled={
            dobYear == new Date().getFullYear() - 13 &&
            dobMonth == currentMonth + 1
              ? value <= currentDate
                ? false
                : true
              : false
          }
          key={`dates-${i}`}
          value={value}
        >
          {value}
        </option>
      );
    }
    return dates;
  };

  const _renderYear = () => {
    let years = [
      <option key={`year-0`} value="">
        Year
      </option>,
    ];
    for (let i = new Date().getFullYear() - 13; i > 1900; i--) {
      years.push(
        <option key={`years-${i}`} value={i}>
          {i}
        </option>
      );
    }
    return years;
  };

  useEffect(() => {
    if (dobDay > getDaysInMonth(dobYear, dobMonth)) {
      setDobDay("");
    }
    if (
      dobDay &&
      dobMonth &&
      dobYear &&
      new Date(`${dobYear}-${dobMonth}-${dobDay}`) !== "Invalid Date"
    ) {
      addToFilledItems("dob", {
        dob: `${dobDay}-${dobMonth}-${dobYear}`,
        dob_private: dobPrivate,
      });
    } else {
      removeFromFilledItems("dob");
    }
  }, [dobDay, dobMonth, dobYear, dobPrivate]);
  const _selectInputFocus = (e) => {
    e.target.parentElement.classList.add(
      "profile-edit-modal__form__date--active"
    );
  };

  const _selectInputBlur = (e) => {
    e.target.parentElement.classList.remove(
      "profile-edit-modal__form__date--active"
    );
  };

  return (
    <div>
      <div className="profile-edit-modal__form__privacy">
        <label className="form-label">Birthday</label>
        <div className="profile-edit-modal__form__privacy__container">
          <label className="profile-edit-modal__form__privacy__label">
            Visibility:
          </label>
          <select
            name="dob_private"
            value={dobPrivate}
            className="profile-edit-modal__form__privacy__select"
            onChange={(e) => Number(setDobPrivate(e.target.value))}
          >
            <option value="1">Everyone</option>
            <option value="2">People I follow</option>
            <option value="3">No one</option>
          </select>
        </div>
      </div>
      <div className="profile-edit-modal__form__date profile-edit-modal__form__date--secondary">
        <select
          name="dobDay"
          className="profile-edit-modal__form__date__select profile-edit-modal__form__date__select--secondary"
          onChange={(e) => setDobDay(e.target.value)}
          onFocus={_selectInputFocus}
          onBlur={_selectInputBlur}
          value={dobDay}
        >
          {_renderDate()}
        </select>
        <select
          name="dobMonth"
          className="profile-edit-modal__form__date__select profile-edit-modal__form__date__select--secondary"
          onChange={(e) => {
            setDobMonth(e.target.value);
            if (
              dobYear == new Date().getFullYear() - 13 &&
              e.target.value == "" + getTwoDigits(new Date().getMonth() + 1)
            ) {
              setDobDay(new Date().getDate());
            }
          }}
          onFocus={_selectInputFocus}
          onBlur={_selectInputBlur}
          value={dobMonth}
        >
          {_renderMonths()}
        </select>
        <select
          name="dobYear"
          className="profile-edit-modal__form__date__select profile-edit-modal__form__date__select--secondary"
          onChange={(e) => {
            setDobYear(e.target.value);
            if (e.target.value == new Date().getFullYear() - 13) {
              if (dobMonth > currentMonth + 1) {
                setDobDay(new Date().getDate());
                setDobMonth("" + getTwoDigits(new Date().getMonth() + 1));
              }
              if (dobMonth == currentMonth + 1 && dobDay > currentDate) {
                setDobDay(new Date().getDate());
              }
            }
          }}
          onFocus={_selectInputFocus}
          onBlur={_selectInputBlur}
          value={dobYear}
        >
          {_renderYear()}
        </select>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    token: state.login.token,
    user: state.user,
  };
}

DateOfBirth.propTypes = {
  addToFilledItems: PropTypes.func,
  removeFromFilledItems: PropTypes.func,
};

export default connect(mapStateToProps)(DateOfBirth);
