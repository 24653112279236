import { GET_TTFB_LEAD_BY_ID_REQUEST,
    GET_TTFB_LEAD_BY_ID_SUCCESS,
    GET_TTFB_LEAD_BY_ID_FAILURE,
    RESET_TTFB_LEAD_BY_ID } from '../../actions/business/constants';

const INITIAL_STATE = {
    fetching: false,
    data: null,
    error: false
}

export default function (state = INITIAL_STATE, action) {
    switch(action.type) {
        case GET_TTFB_LEAD_BY_ID_REQUEST:
            return {
                ...state,
                fetching: true
            }
        case GET_TTFB_LEAD_BY_ID_SUCCESS:
            return {
                ...state,
                fetching: false,
                data: action.payload,
                hasFetched: true
            }
        case GET_TTFB_LEAD_BY_ID_FAILURE:
            return {
                ...state,
                ...(!action.error.__CANCEL__ && {
                    error: true,
                    errorObj: action.error
                }),
                fetching: false
            }
        case RESET_TTFB_LEAD_BY_ID:
            return INITIAL_STATE
        default:
            return state;
    }
}