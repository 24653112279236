import React, { Component } from 'react';
import {
    NavLink,
    withRouter
} from 'react-router-dom';
import { screenBreakpoints, blogLinks } from '../../utils/commonVariables';
import { debounce } from '../../utils/helper';
import HamburgerMenuIcon from '../../assets/images/bar/menu-options.svg';
import Logo from '../../assets/images/bar/logo.svg';
import CloseIcon from '../../assets/images/common/modals/close.svg';
import { miscLinks } from '../../utils/internalLinks';
import { ToastContainer } from 'react-toastify';
import CommonPortal from '../modals/common/CommonPortal';

class LoggedOutBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: window.innerWidth <= screenBreakpoints.mob,
            showMobileNavMenu: false,
            topBarWithBorder: false
        }
        this._handleResize = debounce(this._handleResize.bind(this), 100);
        this._handleScroll = debounce(this._handleScroll.bind(this), 10);
        this._toggleMobileNavMenu = this._toggleMobileNavMenu.bind(this);
    }

    _handleResize() {
        this.setState({
            isMobile: window.innerWidth <= screenBreakpoints.mob
        });
    }

    _handleScroll() {
        const windowHeightToCheck = this.state.isMobile ? 56 : 65;
        if (window.scrollY >= windowHeightToCheck) {
            this.setState({
                topBarWithBorder: true
            })
        } else {
            this.setState({
                topBarWithBorder: false
            })
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this._handleResize);
        document.addEventListener('scroll', this._handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this._handleResize);
        document.removeEventListener('scroll', this._handleScroll);
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this._toggleMobileNavMenu(false);
        }
    }

    _toggleMobileNavMenu(visibleType) {
        document.querySelector('body').style.overflow = (this.state.showMobileNavMenu || !visibleType) ? '' : 'hidden';
        this.setState({
            showMobileNavMenu: typeof visibleType === "boolean"
                ? visibleType
                : !this.state.showMobileNavMenu
        })
    }

    render() {
        const { isMobile,
            showMobileNavMenu,
            topBarWithBorder } = this.state,
            { inView } = this.props;
        return (<div className={`top-bar ${(topBarWithBorder) ? '' : 'top-bar--secondary'}`}>
            <div className="top-bar__content">
                <div className="top-bar__content__left">
                    {isMobile && <button className="top-bar__hamburger" onClick={this._toggleMobileNavMenu}><img alt="menu" src={HamburgerMenuIcon} /></button>}
                    <NavLink onClick={() => {
                        if (window.location.pathname === "/") {
                            window.scroll(0, 0);
                        }
                    }} className="top-bar__content__logo__container" to="/">
                        <img className="top-bar__content__logo" src={Logo} alt="logo" />
                    </NavLink>
                </div>
                <div className="top-bar__content__right">
                    <div className="top-bar__content__quick-links__container">
                        {
                            !isMobile && (<>
                                {/* <NavLink className="top-bar__content__quick-links tab-hidden" to={miscLinks.about}>About</NavLink> */}
                                {/* <NavLink className="top-bar__content__quick-links" to={miscLinks.knowledge}>Knowledge Base</NavLink> */}
                                <a href={blogLinks.all} target="_blank" rel="noopener noreferrer" className="top-bar__content__quick-links">Blog</a>
                            </>)
                        }
                        <NavLink className={`top-bar__content__quick-links
                            ${inView
                                ? ''
                                : document.querySelector('#login-action')
                                    ? 'top-bar__content__quick-links--black'
                                    : ''}`} to={miscLinks.register}>Sign up</NavLink>
                        <NavLink className="top-bar__content__quick-links" to={miscLinks.login}>Log in</NavLink>
                    </div>
                </div>
            </div>
            {
                showMobileNavMenu
                && isMobile && (<div className="full-screen-modal">
                    <div className="full-screen-modal__close">
                        <button onClick={this._toggleMobileNavMenu} className="full-screen-modal__close__btn"><img className="full-screen-modal__close__btn" src={CloseIcon} alt="close modal" /></button>
                    </div>
                    <div className="top-bar__popup__navmodal">
                        {/* <NavLink onClick={this._toggleMobileNavMenu} className="top-bar__popup__navmodal__link" to={miscLinks.about}>About</NavLink> */}
                        {/* <NavLink onClick={this._toggleMobileNavMenu} className="top-bar__popup__navmodal__link" to={miscLinks.knowledge}>Knowledge Base</NavLink> */}
                        <a href={blogLinks.all} onClick={this._toggleMobileNavMenu} target="_blank" rel="noopener noreferrer" className="top-bar__popup__navmodal__link">Blog</a>
                        <NavLink onClick={this._toggleMobileNavMenu} className="top-bar__popup__navmodal__link" to={miscLinks.register}>Sign up</NavLink>
                        <NavLink onClick={this._toggleMobileNavMenu} className="top-bar__popup__navmodal__link" to={miscLinks.login}>Log in</NavLink>
                    </div>
                </div>)
            }
            <CommonPortal>
                <ToastContainer className="toast-container" />
            </CommonPortal>
        </div>)
    };
}

export default withRouter(LoggedOutBar);