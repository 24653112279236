import { GET_PREMIUM_COMPANIES_REQUEST,
    GET_PREMIUM_COMPANIES_SUCCESS,
    GET_PREMIUM_COMPANIES_FAILURE } from "./profile/constants";
import { onRequest,
    getResponseData,
    onSuccess,
    onError } from "../utils/helper";
import { getBaseUrl,
    profileLinks } from "../utils/links";
import Axios from "axios";
const CancelToken = Axios.CancelToken;

export let getPremiumCompaniesCancelToken = undefined;
export const getPremiumCompanies = (token) => {
    return dispatch => {
        if (getPremiumCompaniesCancelToken) getPremiumCompaniesCancelToken();
        dispatch(onRequest(GET_PREMIUM_COMPANIES_REQUEST));
        let url = `${getBaseUrl()}${profileLinks.premiumCompanies}`,
            options = {
                url,
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                cancelToken: new CancelToken((c) => getPremiumCompaniesCancelToken = c)
            };
        return Axios(options)
            .then(response => {
                let successData = getResponseData(response);
                if (successData) {
                    dispatch(onSuccess(GET_PREMIUM_COMPANIES_SUCCESS, successData));
                } else {
                    dispatch(onError(GET_PREMIUM_COMPANIES_FAILURE, response.data.errors))
                }
                return successData;
            })
            .catch(error => {
                dispatch(onError(GET_PREMIUM_COMPANIES_FAILURE, error));
                return false;
            })
    }
}