// PR MANAGE APPLICANTS
export const GET_PR_APPLICANTS_REQUEST = 'GET_PR_APPLICANTS_REQUEST';
export const GET_PR_APPLICANTS_SUCCESS = 'GET_PR_APPLICANTS_SUCCESS';
export const GET_PR_APPLICANTS_FAILURE = 'GET_PR_APPLICANTS_FAILURE';

// REJECTED APPLICANTS
export const GET_PR_REJECTED_APPLICANTS_REQUEST = 'GET_PR_REJECTED_APPLICANTS_REQUEST';
export const GET_PR_REJECTED_APPLICANTS_SUCCESS = 'GET_PR_REJECTED_APPLICANTS_SUCCESS';
export const GET_PR_REJECTED_APPLICANTS_FAILURE = 'GET_PR_REJECTED_APPLICANTS_FAILURE';

// INVITED APPLICANTS
export const GET_PR_INVITED_APPLICANTS_REQUEST = 'GET_PR_INVITED_APPLICANTS_REQUEST';
export const GET_PR_INVITED_APPLICANTS_SUCCESS = 'GET_PR_INVITED_APPLICANTS_SUCCESS';
export const GET_PR_INVITED_APPLICANTS_FAILURE = 'GET_PR_INVITED_APPLICANTS_FAILURE';

// UNASSIGNED APPLICANTS
export const GET_PR_UNASSIGNED_APPLICANTS_REQUEST = 'GET_PR_UNASSIGNED_APPLICANTS_REQUEST';
export const GET_PR_UNASSIGNED_APPLICANTS_SUCCESS = 'GET_PR_UNASSIGNED_APPLICANTS_SUCCESS';
export const GET_PR_UNASSIGNED_APPLICANTS_FAILURE = 'GET_PR_UNASSIGNED_APPLICANTS_FAILURE';

// PR RESET APPLICANTS
export const RESET_PR_APPLICANTS_REQUEST = 'RESET_PR_APPLICANTS_REQUEST';
export const RESET_PR_REJECTED_APPLICANTS = 'RESET_PR_REJECTED_APPLICANTS';
export const RESET_PR_INVITED_APPLICANTS = 'RESET_PR_INVITED_APPLICANTS';
export const RESET_PR_UNASSIGNED_APPLICANTS = 'RESET_PR_UNASSIGNED_APPLICANTS';

// METHOD TO SHORTLIST AN APPLICANT
export const PR_SHORTLIST_APPLICANT_REQUEST = 'PR_SHORTLIST_APPLICANT_REQUEST';
export const PR_SHORTLIST_APPLICANT_SUCCESS = 'PR_SHORTLIST_APPLICANT_SUCCESS';
export const PR_SHORTLIST_APPLICANT_FAILURE = 'PR_SHORTLIST_APPLICANT_FAILURE';

// METHOD TO REJECT AN APPLICANT
export const PR_REJECT_APPLICANT_REQUEST = 'PR_REJECT_APPLICANT_REQUEST';
export const PR_REJECT_APPLICANT_SUCCESS = 'PR_REJECT_APPLICANT_SUCCESS';
export const PR_REJECT_APPLICANT_FAILURE = 'PR_REJECT_APPLICANT_FAILURE';

// INVITE AN APPLICANT
export const PR_INVITE_APPLICANT_REQUEST = 'PR_INVITE_APPLICANT_REQUEST';
export const PR_INVITE_APPLICANT_SUCCESS = 'PR_INVITE_APPLICANT_SUCCESS';
export const PR_INVITE_APPLICANT_FAILURE = 'PR_INVITE_APPLICANT_FAILURE';
export const PR_MANAGE_ADD_TO_INVITED_APPLICANTS = 'PR_MANAGE_ADD_TO_INVITED_APPLICANTS';

// PR BEGIN TASTING
export const PR_BEGIN_TASTING_REQUEST = 'PR_BEGIN_TASTING_REQUEST';
export const PR_BEGIN_TASTING_SUCCESS = 'PR_BEGIN_TASTING_SUCCESS';
export const PR_BEGIN_TASTING_FAILURE = 'PR_BEGIN_TASTING_FAILURE';

// PR REJECT AN APPLICANT
export const PR_REJECT_AN_APPLICANT_REQUEST = 'PR_REJECT_AN_APPLICANT_REQUEST';
export const PR_REJECT_AN_APPLICANT_SUCCESS = 'PR_REJECT_AN_APPLICANT_SUCCESS';
export const PR_REJECT_AN_APPLICANT_FAILURE = 'PR_REJECT_AN_APPLICANT_FAILURE';

// PR SHORTLIST AN APPLICANT
export const PR_SHORTLIST_AN_REQUEST = 'PR_SHORTLIST_AN_REQUEST';
export const PR_SHORTLIST_AN_SUCCESS = 'PR_SHORTLIST_AN_SUCCESS';
export const PR_SHORTLIST_AN_FAILURE = 'PR_SHORTLIST_AN_FAILURE';

// PR INVITE AN APPLICANT
export const PR_INVITE_AN_APPLICANT_REQUEST = 'PR_INVITE_AN_APPLICANT_REQUEST';
export const PR_INVITE_AN_APPLICANT_SUCCESS = 'PR_INVITE_AN_APPLICANT_SUCCESS';
export const PR_INVITE_AN_APPLICANT_FAILURE = 'PR_INVITE_AN_APPLICANT_FAILURE';

// PR GET FILTERS
export const GET_PR_MANAGE_FILTERS_REQUEST = 'GET_PR_MANAGE_FILTERS_REQUEST';
export const GET_PR_MANAGE_FILTERS_SUCCESS = 'GET_PR_MANAGE_FILTERS_SUCCESS';
export const GET_PR_MANAGE_FILTERS_FAILURE = 'GET_PR_MANAGE_FILTERS_FAILURE';

// PR FILTERS TOGGLING
export const UPDATE_PR_MANAGE_SELECTED_FILTERS = 'UPDATE_PR_MANAGE_SELECTED_FILTERS';
export const RESET_PR_MANAGE_SELECTED_FILTERS = 'RESET_PR_MANAGE_SELECTED_FILTERS';

// EXCLUDE PROFILES
export const PR_MANAGE_REPORTS_INCLUDE_TASTER_ADD = 'PR_MANAGE_REPORTS_INCLUDE_TASTER_ADD';
export const PR_MANAGE_REPORTS_INCLUDE_TASTER_REMOVE = 'PR_MANAGE_REPORTS_INCLUDE_TASTER_REMOVE';
export const PR_MANAGE_REPORTS_EXCLUDE_TASTER_ADD = 'PR_MANAGE_REPORTS_EXCLUDE_TASTER_ADD';
export const PR_MANAGE_REPORTS_EXCLUDE_TASTER_REMOVE = 'PR_MANAGE_REPORTS_EXCLUDE_TASTER_REMOVE';

// RESET PR FILTERS
export const RESET_PR_MANAGE_FILTERS = 'RESET_PR_MANAGE_FILTERS';

// GET REPORT SUMMARY
export const GET_PR_MANAGE_REPORT_SUMMARY_REQUEST = 'GET_PR_MANAGE_REPORT_SUMMARY_REQUEST';
export const GET_PR_MANAGE_REPORT_SUMMARY_SUCCESS = 'GET_PR_MANAGE_REPORT_SUMMARY_SUCCESS';
export const GET_PR_MANAGE_REPORT_SUMMARY_FAILURE = 'GET_PR_MANAGE_REPORT_SUMMARY_FAILURE';
export const RESET_PR_MANAGE_REPORT_SUMMARY = 'RESET_PR_MANAGE_REPORT_SUMMARY';

// POST HEADER WEIGHT
export const STORE_PR_HEADER_WEIGHT_REQUEST = 'STORE_PR_HEADER_WEIGHT_REQUEST';
export const STORE_PR_HEADER_WEIGHT_SUCCESS = 'STORE_PR_HEADER_WEIGHT_SUCCESS';
export const STORE_PR_HEADER_WEIGHT_FAILURE = 'STORE_PR_HEADER_WEIGHT_FAILURE';
export const UPDATE_PR_HEADER_WEIGHT = 'UPDATE_PR_HEADER_WEIGHT';

// GET REPORT
export const GET_PR_MANAGE_HEADER_REPORT_REQUEST = 'GET_PR_MANAGE_HEADER_REPORT_REQUEST';
export const GET_PR_MANAGE_HEADER_REPORT_SUCCESS = 'GET_PR_MANAGE_HEADER_REPORT_SUCCESS';
export const GET_PR_MANAGE_HEADER_REPORT_FAILURE = 'GET_PR_MANAGE_HEADER_REPORT_FAILURE';
export const RESET_PR_MANAGE_HEADER_REPORT = 'RESET_PR_MANAGE_HEADER_REPORT';

// QUESTION COMMENTS
export const GET_PR_MANAGE_QUESTION_COMMENTS_REQUEST = 'GET_PR_MANAGE_QUESTION_COMMENTS_REQUEST';
export const GET_PR_MANAGE_QUESTION_COMMENTS_SUCCESS = 'GET_PR_MANAGE_QUESTION_COMMENTS_SUCCESS';
export const GET_PR_MANAGE_QUESTION_COMMENTS_FAILURE = 'GET_PR_MANAGE_QUESTION_COMMENTS_FAILURE';
export const RESET_PR_MANAGE_QUESTION_COMMENTS = 'RESET_PR_MANAGE_QUESTION_COMMENTS';

// GRAPH REPORT
export const GET_GRAPH_FILTERS_SUCCESS = 'GET_GRAPH_FILTERS_SUCCESS';
export const GET_GRAPH_FILTERS_FAILURE = 'GET_GRAPH_FILTERS_FAILURE';
export const UPDATE_GRAPH_FILTERS_SELECTED_FILTERS = 'UPDATE_GRAPH_FILTERS_SELECTED_FILTERS';
export const RESET_GRAPH_FILTERS_SELECTED_FILTERS = 'RESET_GRAPH_FILTERS_SELECTED_FILTERS';
