import { GET_FAQS_CATEGORIES_REQUEST,
    GET_FAQS_CATEGORIES_SUCCESS,
    GET_FAQS_CATEGORIES_FAILURE,
    GET_FAQ_QUESTIONS_SUCCESS,
    RESET_FAQ,
    GET_FAQ_QUESTIONS_REQUEST,
    UPDATE_FAQ_ACTIVE_CATEGORY,
    UPDATE_FAQ_ACTIVE_CATEGORY_QUESTION} from "../../actions/misc/constants";

const INITIAL_STATE = {
    categories: null,
    categoryQuestions: {},
    fetching: false,
    error: false,
    activeCategory: 0,
    activeQuestionId: 0
}

export default function faqs(state = INITIAL_STATE, action) {
    switch(action.type) {
        case GET_FAQS_CATEGORIES_REQUEST:
            return {
                ...state,
                fetching: true
            }
        case GET_FAQS_CATEGORIES_SUCCESS:
            return {
                ...state,
                categories: action.payload
            }
        case GET_FAQS_CATEGORIES_FAILURE:
            return {
                ...state,
                fetching: false,
                error: true,
                errorMsg: action.error
            }
        case GET_FAQ_QUESTIONS_REQUEST:
            return {
                ...state,
                categoryQuestions: {
                    ...state.categoryQuestions,
                    [action.payload.categoryId]: {
                        fetching: true,
                        questions: []
                    }
                },
                activeCategory: action.payload.categoryId
            }
        case GET_FAQ_QUESTIONS_SUCCESS:
            return {
                ...state,
                categoryQuestions: {
                    ...state.categoryQuestions,
                    [action.payload.categoryId]: {
                        fetching: false,
                        questions: action.payload.questions
                    }
                },
                activeCategory: action.payload.categoryId
            }
        case UPDATE_FAQ_ACTIVE_CATEGORY:
            return {
                ...state,
                activeCategory: action.payload.categoryId
            }
        case UPDATE_FAQ_ACTIVE_CATEGORY_QUESTION:
            return {
                ...state,
                activeQuestionId: action.payload.questionId
            }
        case RESET_FAQ:
            return INITIAL_STATE
        default:
            return state;
    }
}
