import React, { useState } from 'react';
import Modal from '../common/Modal';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ShippingAddressForm from './ShippingAddressForm';
import RemoveEntity from '../common/RemoveEntity';
import { deleteShippingAddress } from '../../../actions/profile/update';

function ShippingAddressEditOrUpdate({ shippingAddress,
    handleClose,
    user,
    deleteShippingAddress,
    token }) {
    const [idForDelete, setIdForDelete] = useState(null),
        [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false),
        [processing, setProcessing] = useState(false);
    const handleDelete = () => {
        if (processing) return;
        setProcessing(true);
        deleteShippingAddress(token,
            user.user.profile.id,
            idForDelete)
            .then(_ => {
                setProcessing(false);
                if (_) {
                    handleClose();
                }
            })
    }
    return (<>
        <Modal title={shippingAddress ? 'Edit Address' : 'Add Address'}
            handleClose={handleClose}
            className="show-interest-product-modal"
            showHeader={true}>
            <ShippingAddressForm removeAddress={!!shippingAddress}
                handleSave={handleClose}
                handleUpdate={handleClose}
                deleteProcessing={processing}
                handleDelete={(shippingId) => {
                    setIdForDelete(shippingId);
                    setShowDeleteConfirmation(true);
                }}
                shippingAddress={shippingAddress} />
        </Modal>
        {
            showDeleteConfirmation
            && <RemoveEntity handleClick={handleDelete}
                title="Remove Address"
                description="Are you sure you want to remove the address?"
                handleClose={() => {
                    setIdForDelete(null);
                    setShowDeleteConfirmation(false)
                }}
                removing={processing} />
        }
    </>)
};

ShippingAddressEditOrUpdate.propTypes = {
    shippingAddress: PropTypes.object,
    handleClose: PropTypes.func
}

function mapStateToProps(state) {
    return {
        token: state.login.token,
        user: state.user
    }
}

const mapDispatchToProps = {
    deleteShippingAddress
}
export default connect(mapStateToProps, mapDispatchToProps)(ShippingAddressEditOrUpdate);