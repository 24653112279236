import {
  GET_TTFB_SIDEBAR_REQUEST,
  GET_TTFB_SIDEBAR_SUCCESS,
  GET_TTFB_SIDEBAR_FAILURE,
  SET_TTFB_SIDEBAR_DROPDOWN,
} from "../../actions/business/constants";

const INITIAL_STATE = {
  fetching: false,
  data: [],
  error: false,
  sidebarDropdown: null,
};
export default function sideBar(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_TTFB_SIDEBAR_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case GET_TTFB_SIDEBAR_SUCCESS:
      return {
        ...state,
        fetching: false,
        data: action.payload,
      };
    case GET_TTFB_SIDEBAR_FAILURE:
      return {
        ...state,
        fetching: false,
        ...(!action.error.__CANCEL__ && {
          error: true,
          errorObj: action.error,
        }),
      };
    case SET_TTFB_SIDEBAR_DROPDOWN:
      return {
        ...state,
        sidebarDropdown: action.payload,
      };
    default:
      return state;
  }
}
