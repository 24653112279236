import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  likeAPost,
  getLikeActionForPost,
} from "../../../../actions/feed/common";
import {
  getAPostComment,
  toggleComments,
} from "../../../../actions/feed/comments";
import SharePopUp from "../../../../components/SharePopUp";
import { toggleShareAPostModal } from "../../../../actions/common/modals";
import { getCommentsIDForReducer } from "../../../../utils/helper";
import Like from "../../../../assets/images/feed/feed-like.svg";
import Liked from "../../../../assets/images/feed/feed-liked.svg";
import Comment from "../../../../assets/images/feed/comment.svg";
import Share from "../../../../assets/images/feed/feed-share.svg";
import LoggedoutMetaBar from "../../../../components/bars/LoggedOutMetaBar";

class MetaBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showShareOptions: false,
    };
    this._handleLike = this._handleLike.bind(this);
    this.getComments = this.getComments.bind(this);
    this.toggleShareOptions = this.toggleShareOptions.bind(this);
    this.toggleShareAPost = this.toggleShareAPost.bind(this);
    this.togglePostComments = this.togglePostComments.bind(this);
    this._getLikes = this._getLikes.bind(this);
  }

  _handleLike() {
    const {
        token,
        likeAPost,
        postType,
        postId,
        userType,
        userId,
        isShared,
        sharedId,
        meta,
        isAd,
        likeActionProcessing,
        isPublic,
      } = this.props,
      { hasLiked } = meta;
    if (isPublic) return;
    if (likeActionProcessing) {
      return;
    }

    likeAPost(
      postType,
      postId,
      token,
      isShared,
      userType,
      userId,
      sharedId,
      hasLiked,
      isAd
    );
  }

  getComments() {
    const {
      token,
      getAPostComment,
      postType,
      postId,
      isShared,
      fetchingComments,
      comments,
      sharedId,
      isAd,
      isPublic,
    } = this.props;
    if (isPublic) {
      return;
    }
    if (fetchingComments) {
      return;
    }
    const postIdToCheck = getCommentsIDForReducer(
      postType,
      postId,
      isShared,
      sharedId,
      isAd
    );
    if (
      comments.byPostId[postIdToCheck] &&
      comments.byPostId[postIdToCheck].showComments
    ) {
      this.togglePostComments();
      return;
    }
    getAPostComment(
      postType,
      postId,
      token,
      1,
      postType,
      isShared,
      sharedId,
      isAd
    );
  }

  togglePostComments() {
    const { postType, postId, isShared, sharedId, isAd, toggleComments } =
      this.props;
    toggleComments(postType, postId, isShared, sharedId, isAd);
  }

  toggleShareAPost() {
    const { toggleShareAPostModal, postToShare } = this.props;
    if (!toggleShareAPostModal) return;
    toggleShareAPostModal(postToShare);
  }

  toggleShareOptions() {
    if (this.state.showShareOptions) {
      document.removeEventListener("click", this.toggleShareOptions);
    } else {
      document.addEventListener("click", this.toggleShareOptions);
    }
    this.setState({
      showShareOptions: !this.state.showShareOptions,
    });
  }

  /**
   * Function to get likes for a given post
   * Will add it to modal reducer -> like modal
   */
  _getLikes() {
    const {
      getLikeActionForPost,
      postId,
      postType,
      isShared,
      sharedId,
      token,
      likeModal,
    } = this.props;
    if (!postId || !postType || likeModal.fetching) {
      return;
    }
    getLikeActionForPost(postType, postId, token, 1, isShared, sharedId);
  }

  render() {
    const {
        meta,
        likeActionProcessing,
        isShared,
        postType,
        hideInternalSharing,
        isPublic,
        publicClickAction,
        postId,
        sharedId,
        likeModal,
      } = this.props,
      { hasLiked } = meta,
      feedSharePostIdToCheck = getCommentsIDForReducer(
        postType,
        postId,
        isShared,
        sharedId
      );

    if (isPublic)
      return (
        <LoggedoutMetaBar meta={meta} publicClickAction={publicClickAction} />
      );

    return (
      <div className="post-action-bar">
        <div className="post-action-bar__meta">
          {meta.commentCount ? (
            <button
              className="cursor-pointer post-action-bar__meta__btn post-action-bar__meta__btn--default"
              onClick={this.getComments}
            >
              <span className="post-action-bar__meta__btn__text">
                {meta.commentCount}{" "}
                {meta.commentCount === 1 ? "comment" : "comments"}
              </span>
            </button>
          ) : null}
          {meta.likeCount && meta.commentCount ? (
            <span className="post-action-bar__meta__seprator">&bull;</span>
          ) : null}
          {meta.likeCount ? (
            <button
              disabled={likeModal.fetching}
              onClick={this._getLikes}
              className={`cursor-pointer post-action-bar__meta__btn post-action-bar__meta__btn--pink
                                    ${
                                      likeModal.fetching
                                        ? "post-action-bar__meta__btn--disabled"
                                        : ""
                                    }`}
            >
              <span className="post-action-bar__meta__btn__text">
                {meta.likeCount} {meta.likeCount === 1 ? "like" : "likes"}
              </span>
            </button>
          ) : null}
        </div>
        <div className="post-action-bar__action">
          <div className="post-action-bar__action__popup-container">
            <button
              onClick={this.toggleShareOptions}
              className={`post-action-bar__action__btn cursor-pointer
                                ${
                                  this.state.showShareOptions
                                    ? "post-action-bar__action__btn--active"
                                    : ""
                                }`}
            >
              <img
                className="post-action-bar__action__btn__image"
                src={Share}
                alt="share a post"
              />
              <span className="post-action-bar__action__btn__text">Share</span>
            </button>
            {this.state.showShareOptions && (
              <SharePopUp
                shareOnFeed={this.toggleShareAPost}
                postId={postId}
                hideInternalSharing={hideInternalSharing || false}
                sharedId={sharedId}
                postType={postType}
              />
            )}
          </div>
          {
            <button
              onClick={this.getComments}
              className="post-action-bar__action__btn cursor-pointer"
            >
              <img
                className="post-action-bar__action__btn__image"
                src={Comment}
                alt="comment on a post"
              />
              <span className="post-action-bar__action__btn__text">
                Comment
              </span>
            </button>
          }
          {
            <button
              onClick={this._handleLike}
              className={`post-action-bar__action__btn post-action-bar__action__btn--pink cursor-pointer
                                    ${
                                      likeActionProcessing
                                        ? "post-action-bar__action__btn--disabled"
                                        : ""
                                    } ${
                hasLiked ? "post-action-bar__action__btn__like" : ""
              }`}
            >
              {hasLiked ? (
                <>
                  <img
                    className="post-action-bar__action__btn__image"
                    src={Liked}
                    alt="unlike a post"
                  />
                  <span className="post-action-bar__action__btn__text">
                    Like
                  </span>
                </>
              ) : (
                <>
                  <img
                    className="post-action-bar__action__btn__image"
                    src={Like}
                    alt="like a post"
                  />
                  <span className="post-action-bar__action__btn__text">
                    Like
                  </span>
                </>
              )}
            </button>
          }
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    token: state.login.token,
    comments: state.feedReducer.comments,
    likeModal: state.modalReducer.postModals.likeModal,
  };
}

MetaBar.propTypes = {
  postType: PropTypes.string.isRequired,
  postId: PropTypes.any.isRequired,
  likeActionProcessing: PropTypes.bool,
  fetchingComments: PropTypes.bool,
  meta: PropTypes.object.isRequired,
  postToShare: PropTypes.object,
  isPublic: PropTypes.bool,
  isAd: PropTypes.bool,
  publicClickAction: PropTypes.func,
  hideInternalSharing: PropTypes.bool,
};

const mapDispatchToProps = {
  likeAPost,
  getAPostComment,
  toggleShareAPostModal,
  getLikeActionForPost,
  toggleComments,
};

export default connect(mapStateToProps, mapDispatchToProps)(MetaBar);
