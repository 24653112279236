import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '../user/Avatar';
import SharedIcon from '../../assets/images/feed/reply.svg';
import UserName from '../profile/UserName';

function LoggedoutUserBar({ name,
    image,
    isCompany,
    publicClickAction,
    createdAt,
    isShared,
    isVerified,
    isEdited }) {
    return (<div className="user-block">
        <div onClick={publicClickAction} className="user-block__details">
            <Avatar src={image || ''}
                alt={name}
                className="user-block__image cursor-pointer"
                isCompany={isCompany}  />
            <div className="user-block__info">
                <p className={`user-block__info__name cursor-pointer ${!!isVerified && 'user-block__info__name--verified'}`}>
                <UserName isVerified={!!isVerified || false}
                    name={name} />
                </p>
                {
                    (createdAt || isShared || isEdited)
                    && (<p className="user-block__info__secondary cursor-pointer">
                        {
                            isEdited
                            && !isShared
                            && (<>
                                <span>Edited</span>
                                <span className="user-block__info__secondary--divider">&bull;</span>
                            </>)
                        }
                        {
                            isShared
                            && (<span className="user-block__info__secondary user-block__info__secondary--link">
                                <img className="user-block__info__secondary--shared-icon" src={SharedIcon} alt="shared post"/>
                                Shared
                            </span>)
                        }
                        {
                            isShared
                            && createdAt
                            && (<span className="user-block__info__secondary--divider">&bull;</span>)
                        }
                        {
                            createdAt
                            && <span className="user-block__info__secondary user-block__info__secondary--link">
                                { createdAt }
                            </span>
                        }
                    </p>)
                }
            </div>
        </div>
    </div>)
};

LoggedoutUserBar.propTypes = {
    name: PropTypes.string.isRequired,
    image: PropTypes.string,
    isCompany: PropTypes.bool,
    publicClickAction: PropTypes.func,
    isEdited: PropTypes.bool,
    createdAt: PropTypes.string,
    isShared: PropTypes.bool,
    isVerified: PropTypes.bool
}

export default LoggedoutUserBar;