import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toggleProfileCompletionModal } from '../../actions/common/modals';
import CloseIcon from '../../assets/images/common/modals/close.svg';
import JobProfileModal from './profileCompletion/JobProfileModal';
import CuisinesModal from './profileCompletion/CuisinesModal';
import EatingHabitsModal from './profileCompletion/EatingHabitsModal';
import SpecializationsModal from './profileCompletion/SpecializationsModal';
import AllergensModal from './profileCompletion/AllergensModal';

const components = {
    cuisine: CuisinesModal,
    eating: EatingHabitsModal,
    job: JobProfileModal,
    specialization: SpecializationsModal,
    allergens: AllergensModal
}
class ProfileCompletionModal extends Component {
    renderModal = () => {
        const { type } = this.props;
        if (!type || (type && !components[type])) {
            this.handleClose();
            return;
        };
        const ComponentToRender = components[type];
        return <ComponentToRender {...this.props}
            onClose={this.handleClose} />
    }

    handleClose = () => {
        const { toggleProfileCompletionModal } = this.props;
        toggleProfileCompletionModal();
    }

    componentDidMount() {
        document.querySelector('body').classList.add('overflow-hidden');
    }
    componentWillUnmount() {
        this.handleClose();
        document.querySelector('body').classList.remove('overflow-hidden');
    }

    render() {
        return (
            <div className="default-modal profile-completion-modal">
                <div className="default-modal__backdrop">
                    <div className="default-modal__container">
                        <div className="default-modal__header">
                            <div></div>
                            <button onClick={this.handleClose} className="default-modal__header__close">
                                <img src={CloseIcon} alt="close modal" />
                            </button>
                        </div>
                        {
                            this.renderModal()
                        }
                    </div>
                </div>
            </div>
        )
    };
}

const mapDispatchToProps = {
    toggleProfileCompletionModal
}

ProfileCompletionModal.propTypes = {
    handleUserUpdate: PropTypes.func,
    profile: PropTypes.object
}

export default connect(null, mapDispatchToProps)(ProfileCompletionModal);