import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateUser } from '../../../actions/profile/update';
import { getSpecializations } from '../../../actions/profile/getSpecializations';
import Chef from '../../../assets/images/profile/completion/chef.svg';

const texts = {
    title: 'What do you Specialise in?',
    helper: 'Select the specialisations that matches your profile the most.'
}

class SpecializationsModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            specialization_id: props.profile.profile_specializations.map(_ => _.id)
        }
        // binding function to see specializations
        this.handleSpecializationSelect = this.handleSpecializationSelect.bind(this);
        // binding function to save profile
        this.handleProfileSave = this.handleProfileSave.bind(this);
    }

    componentDidMount() {
        const { token,
            specializations,
            getSpecializations } = this.props;
        if (!specializations.all.length) {
            getSpecializations(token);
        }
    }

    handleSpecializationSelect(e) {
        let isChecked = e.target.checked,
            value = parseInt(e.target.value);
        this.setState({
            specialization_id: isChecked
                ? this.state.specialization_id.concat(value)
                : this.state.specialization_id.filter(specialization => specialization !== value)
        })
    }

    handleProfileSave() {
        const { specialization_id } = this.state;
        // if (!specialization_id.length) return;
        const { token,
            updateUser,
            profile,
            handleMandatorySave,
            onClose } = this.props,
            dataToSend = {
                '_method': 'PATCH',
                specialization_id
            };
        updateUser(profile.id,
            dataToSend,
            token)
            .then(val => {
                if (val) {
                    if (handleMandatorySave) {
                        handleMandatorySave(specialization_id)
                    }
                    onClose();
                }
            })
    }

    render() {
        const { specializations,
            onClose } = this.props;
        return (<div className="profile-completion-modal__content">
            <div className="profile-completion-modal__header">
                <img src={Chef} alt="cuisine" className="profile-completion-modal__header__icon" />
                <p className="profile-completion-modal__header__title">{texts.title}</p>
                <p className="profile-completion-modal__header__helper">{texts.helper}</p>
            </div>
            <div className="profile-completion-modal__body">
                {
                    specializations
                        && specializations.fetching
                        ? (null)
                        : specializations
                        && specializations.all
                        && !!specializations.all.length
                        && (<ul className="profile-completion-modal__pill-input-list">
                            {
                                specializations.all.map((specialization, specializationIndex) => {
                                    return (
                                        <li className="profile-completion-modal__pill-input-list__item" key={`specialization-${specializationIndex}-key`}>
                                            <label className="profile-pill-label" htmlFor={`specialization-${specializationIndex}`}>
                                                <input type="checkbox"
                                                    className="profile-pill-label__input"
                                                    onChange={this.handleSpecializationSelect}
                                                    name="specialization_id[]"
                                                    id={`specialization-${specializationIndex}`}
                                                    checked={(this.state.specialization_id.includes(specialization.id))}
                                                    value={specialization.id} />
                                                <div className="profile-pill-label__eating-list profile-pill-label__eating-list--specialisation">
                                                    <div>
                                                        <div className="profile-pill-label__specialisation-image__container">
                                                            <img src={specialization.image}
                                                                alt="specialisation"
                                                                className="profile-pill-label__specialisation-image profile-pill-label__specialisation-image--specialisation"
                                                            />
                                                        </div>
                                                        <div>
                                                            <p className="profile-pill-label__list-text">{specialization.name}</p>
                                                            <p className="profile-pill-label__list-text__helper">{specialization.description}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </label>
                                        </li>
                                    )
                                })
                            }
                        </ul>)
                }
            </div>
            <div className="profile-completion-btn__btn">
                <button className="profile-completion-btn__btn__cancel-btn"
                    onClick={onClose}>
                    <span>Cancel</span>
                </button>
                <button className={`profile-completion-btn__btn__submit-btn`}
                    onClick={this.handleProfileSave}>
                    <span>Done</span>
                </button>
            </div>
        </div>)
    }
}

function mapStateToProps(state) {
    return {
        token: state.login.token,
        specializations: state.profileReducer.specializations,
        profile: state.user.user.profile,
    }
}

const mapDispatchToProps = {
    updateUser,
    getSpecializations
}

SpecializationsModal.propTypes = {
    onClose: PropTypes.func,
    handleMandatorySave: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(SpecializationsModal);

