import { LOGOUT_REQUEST, LOGOUT_API_REQUEST } from "./constants";
import { onSuccess, handleAuthState } from "../../utils/helper";
import { loggedOutUrls, getBaseUrl } from "../../utils/links";
import { getResponseData } from "../../utils/helper";
import Axios from "axios";
import { resetSession } from "../../utils/sessionHelper";
export const logoutUserLocally = (logoutMessage = false) => {
  handleAuthState.remove();
  resetSession();
  return onSuccess(LOGOUT_REQUEST, { logoutMessage });
};

export const userLogout = (token) => {
  let url = `${getBaseUrl()}${loggedOutUrls.logout}`,
    options = {
      url,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: "POST",
    };
  return Axios(options)
    .then((response) => {
      let successData = getResponseData(response);
      return successData;
    })
    .catch((_) => false);
};
