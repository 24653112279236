import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import UserBar from "../common/UserBar";
import MetaBar from "../common/MetaBar";
import CompanyBar from "../common/CompanyBar";
import PostComments from "../common/PostComments";
import PollIcon from "../../../../assets/images/feed/create/poll.svg";
import { voteAPoll, renewAPoll } from "../../../../actions/poll/common";
import {
  decodeString,
  isYoutubeVideo,
} from "../../../../utils/helper";
import RenewPollIcon from "../../../../assets/images/poll/renew-poll.svg";
import ReadMoreLinkified from "../common/ReadMoreLinkified";
import TextOptions from "../../../../components/polls/card/TextOptions";
import ImageOptions from "../../../../components/polls/card/ImageOptions";
import {
  availableFeedCardPostTypes,
  pollTypes,
} from "../../../../utils/commonVariables";
import { showPhotoModal } from "../../../../actions/common/modals";
import SmallPreview from "../common/SmallPreview";
import YoutubeEmbed from "../common/YoutubeEmbed";
import { gaEvents } from "../../../../utils/events";
import CommonPlayer from "../../../../components/CommonPlayer";
import { PROMPT_TYPE } from "../../../../components/modals/profilePrompts/constants";
const optionTypes = {
  [pollTypes.text]: TextOptions,
  [pollTypes.imageQuestion]: TextOptions,
  [pollTypes.imageOption]: ImageOptions,
};

class PollingCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVotingProcess: false,
    };
    this.handlePollSubmit = this.handlePollSubmit.bind(this);
    this.handleImageZoom = this.handleImageZoom.bind(this);
    // Question Image
    this.pollRef = React.createRef();
  }

  handlePollSubmit(pollId, optionId) {
    if (!pollId || !optionId || this.state.isVotingProcess) return;
    const { token, voteAPoll } = this.props;
    this.setState({
      isVotingProcess: true,
    });
    voteAPoll(pollId, optionId, token).then((_) => {
      this.setState({
        isVotingProcess: false,
      });
      if (!_.status) {
        alert(_.error);
        return false;
      };
      const GAData = {
        screenName: "See All Poll Page",
        modelName: availableFeedCardPostTypes.poll,
        promptType: PROMPT_TYPE.INTERACTION
      }
      if (this.props.handlePrompt) {
        this.props.handlePrompt({ GAData, delay: 1000 });
      }
    });
  }

  renewPoll = () => {
    const {
      cardData: {
        polling: { id },
      },
      renewAPoll,
      token,
    } = this.props;
    renewAPoll(id, token);
  };

  handleImageZoom(photo, type = "") {
    gaEvents.polls.imageExpand(type);
    this.props.showPhotoModal({
      photos: [photo],
      isMultiple: false,
      selectedPhoto: photo,
      selectedIndex: 0,
    });
  }

  render() {
    const {
      cardData,
      isShared,
      hideMetaBar,
      showOptionsIcon,
      commentsData,
      isDetailsPage,
      isAd,
      isPublic,
      showFlagOptions,
      publicClickAction,
      user: {
        user: { profile },
      },
      handleDelete,
    } = this.props;

    const { polling, meta, shared } = cardData;
    const createdAt = polling && polling.created_at ? polling.created_at : null;
    const pollId = polling && polling.id ? polling.id : null,
      title = polling && polling.title ? decodeString(polling.title) : "",
      originalPostMeta = isShared
        ? meta.originalPostMeta || meta.original_post_meta || false
        : false;
    const options = polling.options,
      selfVote = isShared
        ? originalPostMeta &&
          originalPostMeta.self_vote &&
          originalPostMeta.self_vote.id
          ? originalPostMeta.self_vote
          : false
        : meta && meta.self_vote && meta.self_vote.id
          ? meta.self_vote
          : false;
    const optionSelected =
      selfVote && selfVote.poll_option_id ? selfVote.poll_option_id : null,
      pollMeta = polling.poll_meta || false,
      isExpired = pollMeta && !!pollMeta.is_expired,
      isCompany = !!cardData.company,
      voteCount = pollMeta && pollMeta.vote_count ? pollMeta.vote_count : 0,
      voteCountText =
        parseInt(voteCount) === 1
          ? `${voteCount} Response`
          : `${voteCount} Responses`,
      isOwner = isPublic
        ? false
        : isCompany
          ? (profile && profile.id && polling.profile_id === profile.id) ||
          (cardData.meta && cardData.meta.isAdmin)
          : profile && profile.id && polling.profile_id === profile.id,
      isCreator = isPublic ? false : polling.profile_id === profile.id,
      isQuestionImage = polling.type === 1,
      questionImage =
        isQuestionImage &&
          polling.image_meta &&
          polling.image_meta.original_photo
          ? polling.image_meta.original_photo
          : null,
      OptionsToShow = optionTypes[polling.type] || null,
      preview = polling.preview || null;
    const youtubeId = isYoutubeVideo(title);

    const isVideoAvailable = polling && polling.videos_meta;
    return (
      <div className="feed-card">
        {cardData && cardData.profile && !cardData.company && (
          <UserBar
            isPublic={isPublic}
            publicClickAction={publicClickAction}
            postType="polling"
            postId={pollId}
            showOptionsIcon={showOptionsIcon}
            cardData={cardData}
            createdAt={createdAt}
            isOwner={isOwner}
            showFlagOptions={showFlagOptions}
            hideMetaBar={hideMetaBar}
            isAd={isAd}
            isDetailsPage={isDetailsPage}
            profile={cardData.profile}
          >
            {isOwner && isExpired && (
              <li className="user-block__action__container__list__item">
                <button
                  onClick={this.renewPoll}
                  tabIndex="0"
                  className="user-block__action__container__list__item__btn"
                >
                  <img
                    src={RenewPollIcon}
                    className="user-block__action__container__list__item__btn__icon"
                    alt="renew poll"
                  />
                  <span className="user-block__action__container__list__item__btn__text">
                    Renew
                  </span>
                </button>
              </li>
            )}
          </UserBar>
        )}
        {cardData && cardData.company && (
          <CompanyBar
            isPublic={isPublic}
            publicClickAction={publicClickAction}
            postType="polling"
            postId={pollId}
            hideMetaBar={hideMetaBar}
            showOptionsIcon={showOptionsIcon}
            cardData={cardData}
            createdAt={createdAt}
            showFlagOptions={showFlagOptions}
            profileId={
              cardData.company && cardData.company.profile_id
                ? cardData.company.profile_id
                : null
            }
            isOwner={isOwner}
            isDetailsPage={isDetailsPage}
            isAd={isAd}
            company={cardData.company}
          >
            {isOwner && isExpired && (
              <li className="user-block__action__container__list__item">
                <button
                  onClick={this.renewPoll}
                  tabIndex="0"
                  className="user-block__action__container__list__item__btn"
                >
                  <img
                    src={RenewPollIcon}
                    className="user-block__action__container__list__item__btn__icon"
                    alt="renew poll"
                  />
                  <span className="user-block__action__container__list__item__btn__text">
                    Renew
                  </span>
                </button>
              </li>
            )}
          </CompanyBar>
        )}
        <div className="poll-card">
          {isExpired && (
            <p className="poll-card__expired-text">This poll has expired</p>
          )}
          <div className="poll-card__header">
            <div>
              <img
                className="poll-card__header__icon"
                src={PollIcon}
                alt="poll"
              />
              <span className="poll-card__header__text">POLL</span>
            </div>
            {
              // am_owner || have_voted && expired
              (isCreator || selfVote) && !!voteCount && (
                <p className="poll-card__header__helper">{voteCountText}</p>
              )
            }
          </div>
          <div className="poll-card__details" >
            {isExpired && <div className="poll-card__details__overlay"></div>}
            {questionImage ? (
              <div onClick={() =>
                this.handleImageZoom(polling.image_meta, "Question")
              }
                className="poll-card__details__image__container"
              >
                <img
                  className="poll-card__details__image"
                  src={questionImage}
                  alt={title}
                />
              </div>
            ) : isVideoAvailable ?
              <>
                {Array.isArray(isVideoAvailable) && isVideoAvailable.length !== 0 && isVideoAvailable.map((video) => {
                  return (
                    <CommonPlayer
                      playerId={`poll-card-video-${pollId}-${isShared ? `shared-card-${shared.id}` : ''}`}
                      videosMeta={[video] || []}
                      withoverlay={false}
                    />
                  )
                })}
              </>
              : youtubeId ? <YoutubeEmbed youtubeId={youtubeId} /> : null}
            {title && (
              <ReadMoreLinkified
                hashtagEventLabel="Poll"
                className="poll-card__details__title"
                text={title}
              />
            )}
            {preview && !youtubeId && <SmallPreview preview={preview} />}
            {!!OptionsToShow && (
              <OptionsToShow
                optionSelected={optionSelected}
                selfVote={selfVote}
                isExpired={isExpired}
                isCreator={isCreator}
                isPublic={isPublic}
                publicClickAction={publicClickAction}
                handlePollSubmit={this.handlePollSubmit}
                handleImageZoom={this.handleImageZoom}
                pollId={pollId}
                voteCount={voteCount}
                options={options}
              />
            )}
          </div>
        </div>
        {!!!isShared && !hideMetaBar && meta && (
          <>
            <MetaBar
              postType="polling"
              postId={pollId}
              isAd={isAd}
              isPublic={isPublic}
              publicClickAction={publicClickAction}
              likeActionProcessing={cardData.likeActionProcessing}
              fetchingComments={commentsData && commentsData.fetching}
              postToShare={cardData}
              meta={meta}
            />
          </>
        )}
        {commentsData && !hideMetaBar && !!!isShared && (
          <PostComments
            showOptionsIcon={showOptionsIcon}
            postType="polling"
            isAd={isAd}
            commentsData={commentsData}
            postId={pollId}
          />
        )}
      </div>
    );
  }
}

PollingCard.ppropTypes = {
  cardData: PropTypes.object.isRequired,
  commentsData: PropTypes.object,
  isShared: PropTypes.bool,
  showOptionsIcon: PropTypes.bool,
  hideMetaBar: PropTypes.bool,
  isPublic: PropTypes.bool,
  publicClickAction: PropTypes.func,
  isAd: PropTypes.bool,
  isDetailsPage: PropTypes.bool,
  showFlagOptions: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    token: state.login.token,
    user: state.user,
  };
}

const mapDispatchToProps = {
  renewAPoll,
  voteAPoll,
  showPhotoModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(PollingCard);
