import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import { hashtagLinks } from '../../utils/internalLinks';
import CarouselHeader from './Carousel/Header';
import { decodeString } from '../../utils/helper';
import { gaEvents } from '../../utils/events';

const HashtagItem = (props) => {
  const { hashtagData } = props;

  const tagWithoutHash = hashtagData.tag.replace('#', '').trim();
  const link = hashtagLinks.feed(tagWithoutHash);
  const text = decodeString(get(hashtagData, 'tag', ''));
  const posts = `${get(hashtagData, 'total_count', 0)} post${
    get(hashtagData, 'total_count', 0) > 1 ? 's' : ''
  }`;

  return (
    <Link
      className="home__hashtag__item"
      to={link}
      onClick={() => gaEvents.landingpage.trendingHashtag(tagWithoutHash)}
    >
      <p className="home__hashtag__item__tag">{text}</p>
      <p className="home__hashtag__item__count">{posts}</p>
    </Link>
  );
};

const TrendingHashtagsMobile = (props) => {
  const { data } = props;

  return (
    <div className="home__card">
      <CarouselHeader data={data} gaFn={() => gaEvents.landingpage.trendingHashtag('See More')} />
      <div className="home__hashtag">
        {get(data, 'elements', []).map((hashtagData, i) => (
          <HashtagItem key={i} hashtagData={hashtagData} />
        ))}
      </div>
    </div>
  );
};

HashtagItem.propTypes = {
  hashtagData: PropTypes.object,
  eventLabel: PropTypes.string,
};
TrendingHashtagsMobile.propTypes = {
  maxCount: PropTypes.number,
  showAllAction: PropTypes.func,
  eventLabel: PropTypes.string,
  data: PropTypes.object,
};

export default TrendingHashtagsMobile;
