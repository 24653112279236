import React from "react";
import { profileLimits } from "../../../utils/commonVariables";
import TextareaAutosize from "react-autosize-textarea";
import PromptFooter from "./PromptFooter";

const About = (props) => {
    const { form: { isUpdating, error, value },
        setForm,
        handleUpdate,
        handleClose,
    } = props;
    const handleSave = () => {
        let data = {
            _method: "PATCH",
            profile: {
                about: value
            }
        }
        handleUpdate(data);
    };
    const handleFormUpdate = (e) => {
        if (!e) return false;
        const { value } = e.target;
        setForm((preForm) => {
            return { ...preForm, value: value }
        });
    }
    return (
        <>
            <div className="profile-prompt__content">
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '8px' }}>
                    <label className="profile-prompt__content--label">Write Your Bio</label>
                    <span className="profile-prompt__content--label secondary">{value.length}/{profileLimits.about}</span>
                </div>
                <TextareaAutosize
                    name="about"
                    value={value}
                    className="form-textarea form-textarea--expandable"
                    onChange={handleFormUpdate}
                    maxLength={profileLimits.about}
                    placeholder={"A brief which describes you..."}
                />
            </div>
            <PromptFooter
                value={value}
                error={error}
                isUpdating={isUpdating}
                handleClose={handleClose}
                handleSave={handleSave}
            />

        </>
    )
}

export default About;