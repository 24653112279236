import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Chevron from '../../assets/images/home/chevron-right-grey.svg';
import ProductIcon from '../../assets/images/home/product-available.svg';
import AvailableProductsModal from './AvailableProductsModal';
import { getAvailableProducts, getAvailableProductsCancelToken } from '../../actions/landingPage';
import { gaEvents } from '../../utils/events';
import CommonPortal from '../modals/common/CommonPortal';
import { connect } from 'react-redux';
import TimingAndDailyLimitModal from '../modals/ShowTimingAndLimitModal';
import { startPrivateReview } from '../../actions/products';
import { collaborationInternalLinks } from '../../utils/internalLinks';
import Loader from '../misc/Loader';

const ProductsAvailable = ({ data, token, history, reviewData, startPrivateReview }) => {
  // const [showAvailableProducts, setShowAvailableProducts] = useState(false);
  const [availableProducts, setAvailableProducts] = useState([]);
  const [showTimingModal, setShowTimingModal] = useState(false);
  const [batchAddressId, setBatchAddressId] = useState();
  const title = get(data, 'title', '');
  const subtitle = get(data, 'sub_title', '');
  const [showAvailableProducts, setShowAvailableProducts] = useState({
    loading: false,
    modalOpen: false,
    prodcuts: []
  })
  const { loading, prodcuts, modalOpen } = showAvailableProducts;
  useEffect(() => {
    return () => {
      if (getAvailableProductsCancelToken) getAvailableProductsCancelToken();
    };
  }, []);

  const handleProductsAvailable = () => {
    gaEvents.landingpage.productAvailable();
    if (prodcuts.length > 0) {
      setShowAvailableProducts({ ...showAvailableProducts, modalOpen: true });
      return;
    }
    setShowAvailableProducts({ ...showAvailableProducts, loading: true });
    getAvailableProducts(token).then((res) => {
      if (res) {
        setShowAvailableProducts({ ...showAvailableProducts, loading: false, prodcuts: [...res], modalOpen: true });
      } else {
        setShowAvailableProducts({ ...showAvailableProducts, loading: false, prodcuts: [] });
      }
    }).catch((err) => {
      setShowAvailableProducts({ ...showAvailableProducts, loading: false, prodcuts: [] });
    });
  }
  const handleClose = () => {
    setShowAvailableProducts({ ...showAvailableProducts, modalOpen: false, loading: false });
  }

  const handleClickButton = (id) => {
    setShowAvailableProducts(false);
    setShowTimingModal(true);
    if (id) {
      setBatchAddressId(id);
    }
  }
  const handleReviewProceed = (type) => {
    setShowTimingModal(false);
    if (type === "review_timer") {
      setShowAvailableProducts(true);
    } else {
      const data = {
        "enforce": true
      }
      const collabData = availableProducts.filter((val) => val.id === reviewData.data.collabId);
      const batchData = collabData && collabData[0].batches && collabData[0].batches.filter((val) => val.id === reviewData.data.batchId);
      const link = collaborationInternalLinks.tastingProcess(collabData[0].id, batchData[0].id);
      startPrivateReview(token, collabData[0].id, batchData[0].id, data).then((res) => {
        history.push(link);
      })
    }
  }

  return (
    <>
      <div
        className="cursor-pointer feed-product-available"
        onClick={handleProductsAvailable}
      >
        <img src={ProductIcon} />
        <div className="feed-product-available__text">
          <span className="feed-product-available__text__title">{title}</span>
          {
            !!loading ?
              <Loader isWhite />
              :
              <div className="feed-product-available__text__cta">
                <span className="feed-product-available__text__cta__text">{subtitle}</span>
                <img src={Chevron} className="feed-product-available__text__cta__icon" />
              </div>
          }
        </div>
      </div>
      {!!modalOpen && prodcuts.length > 0 && (
        <AvailableProductsModal
          data={prodcuts}
          closeModal={handleClose}
          history={history}
          token={token}
          handleClickButton={handleClickButton}
        />
      )}
      {showTimingModal &&
        <CommonPortal>
          <TimingAndDailyLimitModal
            handleClose={() => setShowTimingModal(false)}
            handleProceed={handleReviewProceed}
            data={reviewData.data}
          />
        </CommonPortal>
      }
    </>
  );
};

ProductsAvailable.propTypes = {
  data: PropTypes.object,
  token: PropTypes.string,
  history: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    token: state.login.token,
    reviewData: state.tasting
  };
}

const mapDispatchToProps = {
startPrivateReview
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductsAvailable);
