import {
    GET_COLLAB_USER_BATCHES_REQUEST,
    GET_COLLAB_USER_BATCHES_SUCCESS,
    GET_COLLAB_USER_BATCHES_FAILURE,
    RESET_COLLAB_USER_BATCHES,
    TOGGLE_COLLAB_USER_MODAL,
    GET_BATCH_DEMOGRAPHY_SUCCESS,
    GET_BATCH_DEMOGRAPHY_FAILURE
} from "../../../actions/collaborations/batches/constants";

const INITIAL_STATE = {
    fetching: false,
    data: null,
    error: false,
    showModal: false,
    demography: {
        data: null,
        error: false
    }
}

export default function userBatches(state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_COLLAB_USER_BATCHES_REQUEST:
            return {
                ...state,
                fetching: true,
            }
        case GET_COLLAB_USER_BATCHES_SUCCESS:
            return {
                ...state,
                fetching: false,
                data: action.payload.data,
                showModal: true
            }
        case GET_BATCH_DEMOGRAPHY_SUCCESS:
            return {
                ...state,
                fetching: false,
                demography: {
                    ...INITIAL_STATE.demography,
                    data: action.payload.data
                },
            }
        case GET_BATCH_DEMOGRAPHY_FAILURE:
            return {
                ...state,
                fetching: false,
                demography: {
                    ...INITIAL_STATE.demography,
                    error: true,
                },
            }
        case GET_COLLAB_USER_BATCHES_FAILURE:
            return {
                ...state,
                fetching: false,
                error: true,
                errorMsg: action.error
            }
        case TOGGLE_COLLAB_USER_MODAL:
            return {
                ...state,
                showModal: !state.showModal
            }
        case RESET_COLLAB_USER_BATCHES:
            return INITIAL_STATE
        default:
            return state;
    }
}