import profile from './profile';
// import training from './training';
import photos from './photos';
import activity from './activity';
import { combineReducers } from 'redux';

const view = combineReducers({
    profile,
    // training,
    photos,
    activity
});

export default view;