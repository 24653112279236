import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { ReactComponent as ViewIcon } from '../../assets/images/home/view-icon.svg';
import { modelRedirectFn } from './utils';
import CarouselHeader from './Carousel/Header';
import CarouselContent from './Carousel/Content';
import FreeSlider from '../reviews/common/FreeSlide';
import { gaEvents } from '../../utils/events';

const ImageCarousel = (props) => {
  const { data, history, isMobile } = props;

  const handleClick = (obj) => {
    const type = get(obj, 'type');
    gaEvents.landingpage.tagtasteInsights(type);

    const imageRedirectUrl = modelRedirectFn(type, get(obj, `[${type}].id`, ''));
    history.push(imageRedirectUrl);
  };

  const listItems = get(data, 'elements', []).map((obj, i) => {
    const imageJSON = get(obj, 'photo.image_meta', '')
      ? JSON.parse(get(obj, 'photo.image_meta', ''))
      : '';
    const image = get(obj, 'photo.image_meta', '') ? imageJSON.original_photo : '';
    const viewCount = get(obj, 'meta.seen_count', '');

    return (
      <div
        key={`slider-${i}`}
        className="cursor-pointer home__imagecarousel__slideritem"
        onClick={() => {
          handleClick(obj);
        }}
      >
        <img src={image} className="home__imagecarousel__slideritem__sliderimg" />
        {viewCount && (
          <div className="home__imagecarousel__slideritem__viewicon">
            <ViewIcon className="home__imagecarousel__slideritem__viewicon__img" />
            <span className="home__imagecarousel__slideritem__viewicon__text">{viewCount}</span>
          </div>
        )}
      </div>
    );
  });

  return (
    <div className="home__card">
      <CarouselHeader data={data} gaFn={() => gaEvents.landingpage.tagtasteInsights('See More')} />

      {isMobile ? (
        <FreeSlider>{listItems}</FreeSlider>
      ) : (
        <CarouselContent>{listItems}</CarouselContent>
      )}
    </div>
  );
};

ImageCarousel.propTypes = {
  data: PropTypes.object,
  history: PropTypes.object,
  isMobile: PropTypes.bool,
};

export default ImageCarousel;
