import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import GooglePlacesAutoComplete from '../../misc/GooglePlacesAutoComplete';

function Hometown(props) {
    const [profile, setProfile] = useState(props.user.user.profile),
        [hometown, setHometown] = useState(props.user.user.profile.hometown || ''),
        { addToFilledItems,
            removeFromFilledItems } = props;
    useEffect(() => {
        setProfile(props.user.user.profile);
    }, [props.user.user.profile]);
    useEffect(() => {
        if (props.user.user.profile.hometown) {
            addToFilledItems('hometown', {
                'hometown': hometown
            })
        }
    }, []);
    return (<div className="profile-edit-modal__form__group">
        <label className="form-label">Hometown</label>
        <GooglePlacesAutoComplete
            className="form-input form-input--secondary"
            searchOptions={{types: ['(cities)']}}
            handleLocationChange={(hometown) => {
                setHometown(hometown.split(',')[0])
                if (hometown) {
                    addToFilledItems('hometown', {
                        'hometown': hometown.split(',')[0]
                    })
                } else {
                    removeFromFilledItems('hometown')
                }
            }}
            suggestionContainerClassName="collaboration-form__suggestion__container"
            suggestionClassName="collaboration-form__suggestion"
            location={hometown} />
    </div>)
};

function mapStateToProps(state) {
    return {
        user: state.user
    }
}

Hometown.propTypes = {
    addToFilledItems: PropTypes.func,
    removeFromFilledItems: PropTypes.func
}

export default connect(mapStateToProps)(Hometown);