import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { maxFileSize, pollTextLimits } from "../../utils/commonVariables";
import {
  globalUploadPhotoWithoutAction,
  globalUploadPhotoWithoutActionCancelToken,
} from "../../actions/common/createPost";
import ImageEditIcon from "../../assets/images/poll/create/image-edit.svg";
import ImageCreateIcon from "../../assets/images/poll/create/image-upload.svg";
import Loader from "../misc/Loader";
import { GIF_TYPE, twoDecimal } from "../../utils/helper";
import ImageCropperModal from "../create/ImageCropperModal";
import { pollOptionImageRatio } from "../../utils/commonVariables";

const ImageOptionItem = (props) => {
  const ref = useRef(null);
  const {
    token,
    isEdit,
    disabled,
    option,
    handleOptionsChange,
    handleRemoveOptions,
    index,
    options,
    editablePost,
  } = props;
  const [uploadingImage, setUploadingImage] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [ratio] = useState(pollOptionImageRatio);
  const [width, setWidth] = useState(253);
  const [heigth, setHeight] = useState(width / pollOptionImageRatio);
  const [showImageCropper, setShowImageCropper] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  useEffect(() => {
    if (ref.current) {
      const parentElementWidth = ref.current.parentElement.offsetWidth,
        itemMaxWidth = parentElementWidth / 2 - 8;
      if (itemMaxWidth < width) {
        setWidth(twoDecimal(itemMaxWidth));
        setHeight(twoDecimal(itemMaxWidth / ratio));
      }
    }
    if (globalUploadPhotoWithoutActionCancelToken)
      globalUploadPhotoWithoutActionCancelToken();
  }, []);

  const handleImageChange = (e) => {
    e.preventDefault();
    if (uploadingImage) return;
    setImageError(false);
    const files = e.target.files;
    if (!files || (files && !files.length)) return;
    const file = files[0];
    if (file.size > maxFileSize || !file.type.includes("image")) {
      setImageError(true);
      return;
    }
    if (file.type.includes(GIF_TYPE)) {
      handleUploadImage(file);
    } else {
      setShowImageCropper(true);
    }

    setSelectedImage(file);
  };

  const handleUploadImage = (croppedImage) => {
    setUploadingImage(true);
    handleOptionsChange(
      {
        image: {
          ...(option.image && option.image),
          uploading: true,
        },
      },
      index
    );

    var fileOfBlob = new File([croppedImage], selectedImage.name, {
      type: selectedImage.type,
      lastModified: Date.now(),
    });

    let formData = new FormData();
    formData.append("image", fileOfBlob);

    globalUploadPhotoWithoutAction(formData, token).then((_) => {
      setShowImageCropper(false);
      setSelectedImage("");

      setUploadingImage(false);
      if (_) {
        handleOptionsChange({ image: _ }, index);
      }
    });
  };

  /* const handleImageChange = (e) => {
    e.preventDefault();
    if (uploadingImage) return;
    setImageError(false);
    const files = e.target.files;
    if (!files || (files && !files.length)) return;
    const file = files[0];
    if (file.size > maxFileSize || !file.type.includes('image')) {
      setImageError(true);
      return;
    }
    setUploadingImage(true);
    handleOptionsChange(
      {
        image: {
          ...(option.image && option.image),
          uploading: true,
        },
      },
      index,
    );
    let formData = new FormData();
    formData.append('image', file);
    globalUploadPhotoWithoutAction(formData, token).then((_) => {
      setUploadingImage(false);
      if (_) {
        handleOptionsChange({ image: _ }, index);
      }
    });
  }; */

  return (
    <>
      <li
        style={{ width: `${width}px`, minHeight: `${heigth}px` }}
        ref={ref}
        className="poll-create__image__option"
      >
        <div className="poll-create__image__option__image">
          {((!disabled && !isEdit) || (isEdit && !option.id)) && (
            <label className="poll-create__image__option__icon">
              {uploadingImage ? (
                <Loader isCentered={true} />
              ) : (
                <>
                  <img
                    src={option.image ? ImageEditIcon : ImageCreateIcon}
                    alt="option"
                  />
                  <input
                    type="file"
                    onChange={handleImageChange}
                    accept="image/*"
                  />
                  {!option.image && (
                    <>
                      <p className="poll-create__image__option__helper">
                        Upload Image
                      </p>
                      <p className="poll-create__image__option__helper poll-create__image__option__helper--secondary">
                        jpg, png, gif (Max: 10MB)
                      </p>
                    </>
                  )}
                </>
              )}
            </label>
          )}

          {option.image && option.image.original_photo && (
            <>
              <img
                className={
                  option.image.meta.mime == GIF_TYPE
                    ? "poll-create__image__option__image__img gif_image"
                    : "poll-create__image__option__image__img"
                }
                src={option.image.original_photo}
                style={{ height: `${heigth}px` }}
                alt="option"
              />
              {isEdit ? (
                <>
                  <label className="poll-create__image__option__icon">
                    <img
                      src={option.image ? ImageEditIcon : ImageCreateIcon}
                      alt="option"
                    />
                    <input
                      type="file"
                      onChange={handleImageChange}
                      accept="image/*"
                    />
                  </label>
                </>
              ) : (
                ""
              )}
            </>
          )}
        </div>
        {imageError && (
          <p className="error-text error-text-left">
            Please select image file less than 10MB
          </p>
        )}
        {isEdit ? (
          <input
            type="text"
            className="poll-create__image__option__title"
            maxLength={pollTextLimits.imageOptionText}
            placeholder={`Choice ${index + 1}`}
            onChange={(e) =>
              handleOptionsChange({ text: e.target.value }, index)
            }
            value={option.text}
          />
        ) : (
          <input
            type="text"
            className="poll-create__image__option__title"
            disabled={disabled}
            maxLength={pollTextLimits.imageOptionText}
            placeholder={`Choice ${index + 1}`}
            onChange={(e) =>
              handleOptionsChange({ text: e.target.value }, index)
            }
            value={option.text}
          />
        )}

        {!disabled ? (
          !!(index && index >= 2) && (
            <button
              className="poll-create__image__option__remove"
              onClick={() => handleRemoveOptions(index, isEdit)}
            >
              <span>&times;</span>
            </button>
          )
        ) : editablePost.length !== options.length ? (
          index && index >= options.length - 1 ? (
            <button
              className="poll-create__image__option__remove"
              onClick={() => handleRemoveOptions(index, isEdit)}
            >
              <span>&times;</span>
            </button>
          ) : (
            ""
          )
        ) : (
          ""
        )}
      </li>
      {showImageCropper && (
        <ImageCropperModal
          modalTitle="Crop Image"
          image={selectedImage}
          closeModal={() => setShowImageCropper(false)}
          aspectRatio={5 / 4}
          handleImageChange={handleImageChange}
          handleUploadImage={handleUploadImage}
        />
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    token: state.login.token,
  };
}

ImageOptionItem.propTypes = {
  token: PropTypes.string,
  option: PropTypes.object,
  handleOptionsChange: PropTypes.func,
  handleRemoveOptions: PropTypes.func,
  isEdit: PropTypes.bool,
  index: PropTypes.number,
  disabled: PropTypes.bool,
};

export default connect(mapStateToProps)(ImageOptionItem);
