import React, { useState,
    useRef,
    useEffect } from 'react';
import { decodeString, twoDecimal } from '../../../../utils/helper';
import { Link } from 'react-router-dom';
import ReadMoreText from '../../../../containers/loggedIn/Feed/common/ReadMoreText';
import { companiesLink } from '../../../../utils/internalLinks';
import FollowCompanyBtn from '../../../companies/FollowCompanyBtn';
import FeedCardShareBtn from './FeedCardShareBtn';
import Check from '../../../../assets/images/profile/followers/check.svg';
import UserName from '../../../profile/UserName';

function CompanyFeedCard({ cardData,
    ...props }) {
    const { meta, feedCard } = cardData;
    if (!meta
        || (!feedCard || (feedCard && !feedCard.company))) return null;
    const { company,
        description,
        icon,
        title,
        subtitle,
        id,
        tagline,
        image_meta,
        name } = feedCard,
        { isFollowing } = meta,
        nameToShow = decodeString(name) || decodeString(company.name),
        companyLink = companiesLink.view(company.id);
    const [width, setWidth] = useState(null),
        [height, setHeight] = useState(null),
        ratio = 1.84,
        ref = useRef(null);
    useEffect(() => {
        if (ref.current) {
            const parentElementWidth = ref.current.offsetWidth;
            if (parentElementWidth < 570) {
                setWidth(twoDecimal(parentElementWidth));
                setHeight(twoDecimal(parentElementWidth/ratio))
            }
        }
    }, []);
    return (<div className="feed-card" ref={ref}>
        { title && <p className="feed-card__title">{decodeString(title)}</p> }
        { subtitle && <p className="feed-card__title feed-card__title--secondary">{decodeString(subtitle)}</p> }
        <div className="feed-card__card">
            <Link className="feed-card__image__container" to={companyLink}>
                {icon && <img className="feed-card__image__icon" alt={title} src={icon} />}
                <img alt={nameToShow}
                    className="feed-card__image__img"
                    style={{ ...(width
                        && height
                        && {
                            width: `${width}px`,
                            height: `${height}px`
                        })}}
                    src={image_meta.original_photo} />
            </Link>
            <div className="feed-card__details">
                <div className="feed-card__details__meta">
                    <Link className={`feed-card__details__title ${!!company.verified && 'feed-card__details__title--verified'}`} to={companyLink}>
                        <UserName isVerified={!!company.verified}
                            name={nameToShow} />
                    </Link>
                    {
                        isFollowing
                        ?   <button className="following-btn">
                                <img src={Check} alt="following" />
                                Following
                            </button>
                        : <FollowCompanyBtn isPublic={props.isPublic}
                            publicClickAction={props.publicClickAction}
                            className={`follow-btn ${props.isDetailsPage || props.isPublic ? 'follow-btn--big' : ''}`}
                            profileId={company.profile_id}
                            companyId={company.id}>
                            + FOLLOW
                        </FollowCompanyBtn>
                    }
                </div>
                { tagline && <p className="feed-card__details__tagline">{decodeString(tagline)}</p> }
                <ReadMoreText className="feed-card__details__text"
                    isOpen={!!props.isDetailsPage}
                    isPublic={props.isPublic}
                    publicClickAction={props.publicClickAction}
                    text={description} />
                <FeedCardShareBtn isPublic={props.isPublic}
                        publicClickAction={props.publicClickAction}
                        postId={id} />
            </div>
        </div>
    </div>);
};

export default CompanyFeedCard;