import React, { Component } from "react";
import { connect } from "react-redux";
import { Prompt } from "react-router-dom";
import PropTypes from "prop-types";
import get from "lodash/get";
import TaggableTextarea from "../TaggableTextarea";
import Loader from "../misc/Loader";
import { miscLinks, photosInternalLinks } from "../../utils/internalLinks";
import {
  transformTaggedContent,
  makeTaggedContentEditable,
  decodeString,
} from "../../utils/helper";
import PostBtn from "../../assets/images/feed/create/post-btn.svg";
import {
  createAPost,
  globalUploadPhoto,
  createAPhotoPost,
} from "../../actions/common/createPost";
import RemovePreviewIcon from "../../assets/images/common/icons/close-white.svg";
import {
  emptyAPreview,
  hideEditAPostModal,
  setPreview,
  getLinkPreviewCancelToken,
  resetPreviews,
} from "../../actions/feed/common";
import { updateAPost, updateAPhotoPost } from "../../actions/common/updatePost";
import SmallPreview from "../../containers/loggedIn/Feed/common/SmallPreview";
import {
  postMaxLength,
  maxVideoFileSize,
  maxFileSize,
} from "../../utils/commonVariables";
import UserSelect from "./UserSelect";
import CloseWhiteIcon from "../../assets/images/feed/create/close.svg";
import CloseIcon from "../../assets/images/common/modals/close.svg";
import PhotoIcon from "../../assets/images/feed/create/camera.svg";
import VideoIcon from "../../assets/images/feed/create/video-add-button.svg";
import gifIcon from "../../assets/images/feed/blog/gif-icon.svg";
import DiscardPostDraft from "../modals/common/DiscardPost";
import showToaster, { TOAST_MESSAGE } from "../misc/Toaster";

const postPreview = "create-post",
  updatePreview = "update-post",
  emptyPost = {
    content: "",
    transformedContent: "",
    images: [],
    gifs: [],
    media_file: undefined,
    blobURL: undefined,
    previewType: postPreview,
    hasGeneratedPreview: false,
    selectedUser: null,
    isUserCompany: false,
    videoError: false,
    imageError: false,
    imageUploadingError: false,
    filesSkipped: 0,
    imageUploadingFileSkipped: 0,
    formSubmitting: false,
    errorSubmitting: false,
    discardPost: false,
    discardImage: false,
    dicardVideo: false,
    discardModal: false,
  },
  texts = {
    videoError: `Please select video less than 20MB`,
    imageError: (fileNumber = 0) =>
      fileNumber
        ? `Please select image file less than 10MB`
        : `Please select image files less than 10MB`,
    imageUploadingError: (fileNumber = 0) =>
      `Error uploading ${fileNumber} ${fileNumber === 1 ? "file" : "files"}.`,
    errorSubmitting: `Something went wrong. Please try again!`,
    postUploadingPrompt: "Please wait while the post is uploading...",
    unsavedPost: "You have unsaved changes. Do you want to process?",
  };
class CreatePost extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...emptyPost,
    };
    // binding handle input change
    this.handleChange = this.handleChange.bind(this);
    // binding function to submit create form
    this.handleSubmit = this.handleSubmit.bind(this);
    // binding function to submit update form
    this.handleUpdate = this.handleUpdate.bind(this);
    // binding handling video file change - only when create
    this.handleVideoFileChange = this.handleVideoFileChange.bind(this);
    // binding function to remove video file
    this.removeVideo = this.removeVideo.bind(this);
    // binding handling image filye change - only when create
    this.handleImageChange = this.handleImageChange.bind(this);
    // binding function to handle render images
    this.renderImages = this.renderImages.bind(this);
    // binding function to remove images
    this.removeImage = this.removeImage.bind(this);
    // binding function to render preview
    this.renderPreview = this.renderPreview.bind(this);
    // binding function to remove preview
    this.removePreview = this.removePreview.bind(this);
    // binding function to handle selected user change
    this.handleUserSelectChange = this.handleUserSelectChange.bind(this);
    // removing error
    this.hideError = this.hideError.bind(this);
    //Input ref
    this.imagesRef = React.createRef(); //images
    this.videoRef = React.createRef(); //video ref
    // binding function to empty ref
    this.emptyRefs = this.emptyRefs.bind(this);
  }

  // hiding video and image error
  hideError() {
    this.setState({
      videoError: false,
      imageError: false,
      filesSkipped: 0,
      imageUploadingError: false,
      imageUploadingFileSkipped: 0,
    });
  }

  // setting content for post
  handleChange(content) {
    this.setState({
      content,
      transformedContent: transformTaggedContent(content),
      discardPost: true,
    });
    if (!content || (content && !content.trim().length)) {
      this.setState({
        hasGeneratedPreview: false,
        discardPost: false,
      });
    }
  }

  componentDidMount() {
    // Checking if editable post
    const { isEdit, editablePost, setPreview, editablePostType } = this.props;
    // updating state if editable post
    if (isEdit) {
      const postTypeToCheck = editablePostType || editablePost.type,
        contentToCheck = postTypeToCheck === "photo" ? "caption" : "content",
        editableTextContent =
          editablePost[postTypeToCheck][contentToCheck] &&
            editablePost[postTypeToCheck][contentToCheck].profiles
            ? makeTaggedContentEditable(
              decodeString(
                editablePost[postTypeToCheck][contentToCheck].text
              ),
              editablePost[postTypeToCheck][contentToCheck].profiles
            )
            : decodeString(editablePost[postTypeToCheck][contentToCheck]);
      this.setState({
        content: editableTextContent || "",
        transformedContent: editablePost[postTypeToCheck][contentToCheck] || "",
        previewType: updatePreview,
        selectedUser: editablePost.company
          ? editablePost.company
          : editablePost.profile,
        isUserCompany: !!editablePost.company,
      });
      if (
        editablePost &&
        editablePostType === "shoutout" &&
        editablePost[editablePostType] &&
        editablePost[editablePostType].preview
      ) {
        setPreview(updatePreview, editablePost[editablePostType].preview);
      }
      // Setting images if photo
      if (postTypeToCheck === "photo") {
        this.setState({
          images: editablePost["photo"].images,
        });
      }
      // setting video file if available
      if (
        postTypeToCheck !== "photo" &&
        editablePost["shoutout"] &&
        editablePost["shoutout"].media_json
      ) {
        this.setState({
          mediaJson: editablePost["shoutout"].media_json,
        });
      }
      document.body.style.overflow = "hidden";
    }
    // Updating user
    if (
      this.props.user &&
      this.props.user.user &&
      this.props.user.user.profile &&
      !isEdit
    ) {
      this.setState({
        selectedUser: this.props.user.user.profile,
        isUserCompany: false,
      });
    }
  }

  componentDidUpdate() {
    if (
      this.props.preview &&
      this.props.preview[this.state.previewType] &&
      !this.props.preview[this.state.previewType].fetching &&
      !this.state.hasGeneratedPreview
    ) {
      this.setState({
        hasGeneratedPreview: true,
      });
    }
  }

  componentWillUnmount() {
    if (getLinkPreviewCancelToken) getLinkPreviewCancelToken();
    this.props.emptyAPreview();
    this.props.resetPreviews();
    document.body.style.overflow = "auto";
  }

  // create a new post - photo or shoutout function
  handleSubmit() {
    if (this.state.formSubmitting) return;
    this.setState({
      errorSubmitting: false,
      formSubmitting: true,
    });
    const {
      createAPost,
      token,
      createAPhotoPost,
      emptyAPreview,
      handleClose,
      user: {
        user: { profile },
      },
    } = this.props,
      data = {
        privacy_id: 1,
      },
      creatorData = {
        profile,
      };
    if (this.state.isUserCompany) {
      creatorData["company"] = this.state.selectedUser;
    }
    // adding company id if company
    if (this.state.isUserCompany) {
      data["company_id"] = this.state.selectedUser.id;
    } else {
      data["profile_id"] = profile.id;
    }
    if (
      (this.state.images && this.state.images.length) ||
      (this.state.gifs && this.state.gifs.length)
    ) {
      data["images"] = this.state.images.length
        ? this.state.images.map((image) => JSON.stringify(image.meta))
        : this.state.gifs.map((gif) => JSON.stringify(gif.meta));
      data["caption"] = this.state.transformedContent;
      createAPhotoPost(data, token, creatorData).then((val) => {
        if (val) {
          emptyAPreview(postPreview);
          this.setState({
            ...emptyPost,
          });
          handleClose();
          showToaster({
            text: TOAST_MESSAGE.PostCreate,
            link: photosInternalLinks.singlePhoto(get(val, "id", ""))
          }
          );
        } else {
          this.setState({
            errorSubmitting: true,
            formSubmitting: false,
          });
        }
      });
    } else {
      data["content"] = this.state.transformedContent;
      let formData = new FormData();
      Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
      });
      if (this.state.media_file && this.state.blobURL) {
        formData.append("media_file", this.state.media_file);
      }
      // adding preview if not media file
      if (
        !this.state.media_file &&
        !this.state.blobURL &&
        this.props.preview[postPreview] &&
        !!this.props.preview[postPreview].preview
      ) {
        Object.keys(this.props.preview[postPreview].preview).forEach(
          (previewKey) => {
            formData.append(
              `preview[${previewKey}]`,
              this.props.preview[postPreview].preview[previewKey]
            );
          }
        );
      }
      createAPost(formData, token, creatorData).then((val) => {
        emptyAPreview(postPreview);
        if (val) {
          this.setState({
            ...emptyPost,
          });
          handleClose();
          showToaster({ text: TOAST_MESSAGE.PostCreate, link: miscLinks.shoutout(get(val, "id", "")) });
        } else {
          this.setState({
            errorSubmitting: true,
            formSubmitting: false,
          });
        }
      });
    }
  }

  // hanlding update of a post
  handleUpdate() {
    if (this.state.formSubmitting) return;
    this.setState({
      errorSubmitting: false,
      formSubmitting: true,
    });
    const { editablePost, editablePostType } = this.props,
      postTypeToCheck = editablePostType || editablePost.type;
    if (postTypeToCheck === "photo") {
      const { token, updateAPhotoPost, hideEditAPostModal } = this.props,
        { transformedContent } = this.state;
      let data = {
        caption: transformedContent,
        images: editablePost.photo.images.map((image) => JSON.stringify(image)),
      };
      // adding company id if company id
      if (this.state.isUserCompany) {
        data["company_id"] = this.state.selectedUser.id;
      }
      updateAPhotoPost(editablePost[postTypeToCheck].id, data, token).then(
        (val) => {
          if (val) {
            hideEditAPostModal();
          } else {
            this.setState({
              errorSubmitting: true,
              formSubmitting: false,
            });
          }
        }
      );
      return;
    }
    const { token, updateAPost, hideEditAPostModal } = this.props,
      { content, transformedContent } = this.state;
    if (
      ((content && !content.trim().length) ||
        (transformedContent && !transformedContent.trim().length)) &&
      !this.state.mediaJson
    )
      return;
    let data = {
      content: transformedContent,
    };
    //adding preview only if the media file is not available and generated
    if (
      this.props.preview[updatePreview] &&
      this.props.preview[updatePreview].preview
    ) {
      data["preview"] = this.props.preview[updatePreview].preview;
    }
    // adding company id if company id
    if (this.state.isUserCompany) {
      data["company_id"] = this.state.selectedUser.id;
    }
    updateAPost(editablePost[postTypeToCheck].id, data, token).then((val) => {
      if (val) {
        hideEditAPostModal();
      } else {
        this.setState({
          errorSubmitting: true,
          formSubmitting: false,
        });
      }
    });
  }

  // function to handle video file change
  handleVideoFileChange(e) {
    this.hideError();
    let file = e.target.files[0];
    if (file) {
      if (file.size > maxVideoFileSize) {
        // show error
        this.setState({
          videoError: true,
        });
        setTimeout(this.hideError, 3000);
        this.emptyRefs();
        return;
      }
      let blobURL = URL.createObjectURL(file);
      this.setState({
        blobURL,
        media_file: file,
        dicardVideo: true,
      });
    }
  }

  // function to handle remove video
  removeVideo() {
    this.setState({
      media_file: undefined,
      blobURL: undefined,
      dicardVideo: false,
    });
  }

  // function to handle image file changes
  handleImageChange(e, format) {
    if (format === "jpeg") {
      this.hideError();
      let files = e.target.files;
      let filesSkipped = 0;
      // let imageUploadingFileSkipped = 0;
      let newImagesArray = [];
      let imageObj = {
        meta: null,
        uploading: true,
      };
      if (files.length) {
        // uploading file images
        const imagesLength = this.state.images.length;
        const checkforFifteenImages = 15 - imagesLength;
        const imagesCanUpload =
          files.length <= checkforFifteenImages
            ? files.length
            : checkforFifteenImages;
        const { token, globalUploadPhoto } = this.props;

        for (let i = 0; i < imagesCanUpload; i++) {
          // Skipping files if size or mime type or limit of images length reached
          // const imagesLength = this.state.images.length;
          if (
            files[i].size > maxFileSize ||
            files[i].type.indexOf("image") === -1
          ) {
            filesSkipped = filesSkipped + 1; // doing this cuz of es lint error
          } else {
            // adding file object to images
            // not calling direct setState since the below code
            // is atomic
            // read https://reactjs.org/docs/react-component.html#setstate for more
            let fauxKey = Math.random(),
              currentImageObj = {
                ...imageObj,
                keyToCheck: fauxKey,
              };
            newImagesArray.push(currentImageObj);
            let formData = new FormData();
            formData.append("image", files[i]);
            globalUploadPhoto(formData, token).then((response) => {
              if (response) {
                this.setState({
                  images: this.state.images.map((image) => {
                    if (image.keyToCheck !== fauxKey) return image;
                    return {
                      ...image,
                      meta: response,
                      uploading: false,
                    };
                  }),
                });
              } else {
                this.setState({
                  images: this.state.images.filter(
                    (image) => image.keyToCheck !== fauxKey
                  ),
                });
              }
            });
          }
        }
        this.setState({
          images: [...this.state.images, ...newImagesArray],
        });
        if (filesSkipped) {
          this.setState({
            filesSkipped,
            imageError: true,
          });
          setTimeout(this.hideError, 3000);
        }
      }
    } else {
      let files = e.target.files;
      let filesSkipped = 0;
      let newGifsArray = [];
      let gifsObj = {
        meta: null,
        uploading: true,
      };
      if (files.length) {
        const gifsLength = this.state.gifs.length;
        const checkforFifteenGifs = 15 - gifsLength;
        const gifsCanUpload =
          files.length <= checkforFifteenGifs
            ? files.length
            : checkforFifteenGifs;
        const { token, globalUploadPhoto } = this.props;
        for (let i = 0; i < gifsCanUpload; i++) {
          if (
            files[i].size > maxFileSize ||
            files[i].type.indexOf("image") === -1
          ) {
            filesSkipped = filesSkipped + 1;
          } else {
            let fauxKey = Math.random(),
              currentGifsObj = {
                ...gifsObj,
                keyToCheck: fauxKey,
              };
            newGifsArray.push(currentGifsObj);
            let formData = new FormData();
            formData.append("image", files[i]);
            globalUploadPhoto(formData, token).then((response) => {
              if (response) {
                this.setState({
                  gifs: this.state.gifs.map((gif) => {
                    if (gif.keyToCheck !== fauxKey) return gif;
                    return {
                      ...gif,
                      meta: response,
                      uploading: false,
                    };
                  }),
                });
              } else {
                this.setState({
                  gifs: this.state.gifs.filter(
                    (gif) => gif.keyToCheck !== fauxKey
                  ),
                });
              }
            });
          }
        }
        this.setState({
          gifs: [...this.state.gifs, ...newGifsArray],
        });
        if (filesSkipped) {
          this.setState({
            filesSkipped,
            gifError: true,
          });
          setTimeout(this.hideError, 3000);
        }
      }
    }
  }

  // function to remove image
  removeImage(imageIndexToRemove) {
    this.setState({
      images: this.state.images.filter(
        (_, imageIndex) => imageIndex !== imageIndexToRemove
      ),
    });
    if (this.state.images.length < 2) {
      this.setState({ discardImage: false });
    }
  }

  removeGif(gifIndexToRemove) {
    this.setState({
      gifs: this.state.gifs.filter(
        (_, gifIndex) => gifIndex !== gifIndexToRemove
      ),
    });
  }

  // function to handle user select
  handleUserSelectChange(selectedUser = null, isUserCompany = false) {
    if (!selectedUser) return;
    this.setState({
      selectedUser,
      isUserCompany,
    });
    document.querySelector(".tag-box__input").focus();
  }

  emptyRefs() {
    if (this.imagesRef.current) {
      this.imagesRef.current.value = "";
    }
    if (this.videoRef.current) {
      this.videoRef.current.value = "";
    }
  }

  // function to render images
  renderImages() {
    const { images } = this.state,
      { isEdit } = this.props;
    return (
      <div
        className={`create-image__container ${isEdit ? "create-image__container--disabled" : ""
          }`}
      >
        {images.map((image, imageIndex) => {
          return (
            <div className="create-image" key={`uploading-index-${imageIndex}`}>
              {image.uploading ? (
                <p className="create-image__image create-image__image--placeholder">
                  <Loader />
                </p>
              ) : (
                <>
                  <img
                    className="create-image__image"
                    alt="post uploading"
                    src={
                      isEdit ? image.original_photo : image.meta.original_photo
                    }
                  />
                  {!isEdit && (
                    <button
                      className="create-image__image-remove"
                      onClick={() => {
                        if (this.state.formSubmitting) return;
                        this.removeImage(imageIndex);
                      }}
                    >
                      <img
                        className="create-image__image-remove__icon"
                        src={CloseWhiteIcon}
                        alt={`remove at index ${imageIndex}`}
                      />
                    </button>
                  )}
                </>
              )}
            </div>
          );
        })}
      </div>
    );
  }

  renderGifs() {
    const { gifs } = this.state,
      { isEdit } = this.props;
    return (
      <div
        className={`create-image__container ${isEdit ? "create-image__container--disabled" : ""
          }`}
      >
        {gifs.map((gif, gifIndex) => {
          return (
            <div className="create-image" key={`uploading-index-${gifIndex}`}>
              {gif.uploading ? (
                <p className="create-image__image create-image__image--placeholder">
                  <Loader />
                </p>
              ) : (
                <>
                  <img
                    className="create-image__image"
                    alt="post uploading"
                    src={isEdit ? gif.original_photo : gif.meta.original_photo}
                  />
                  {!isEdit && (
                    <button
                      className="create-image__image-remove"
                      onClick={() => {
                        if (this.state.formSubmitting) return;
                        this.removeGif(gifIndex);
                      }}
                    >
                      <img
                        className="create-image__image-remove__icon"
                        src={CloseWhiteIcon}
                        alt={`remove at index ${gifIndex}`}
                      />
                    </button>
                  )}
                </>
              )}
            </div>
          );
        })}
      </div>
    );
  }

  // Function to show preview
  renderPreview(preview) {
    return (
      <>
        <SmallPreview preview={preview} />
        <button
          className="post-bar__post__preview-container__close"
          onClick={this.removePreview}
        >
          <img src={RemovePreviewIcon} alt="remove preview" />
        </button>
      </>
    );
  }

  // Function to remove preview
  removePreview() {
    const { emptyAPreview } = this.props;
    emptyAPreview(this.state.previewType);
    this.setState({
      hasGeneratedPreview: false,
    });
  }

  componentDidUpdate() {
    if (
      this.state.dicardVideo ||
      this.state.discardImage ||
      this.state.discardPost ||
      this.props.isEdit
    ) {
      window.onbeforeunload = function (e) {
        return "";
      };
    } else {
      window.onbeforeunload = null;
    }
  }
  render() {
    const { preview, isEdit, user, uploading, hideArrow } = this.props;
    const shouldGeneratePreview =
      !this.state.media_file &&
      !this.state.images.length &&
      !this.state.mediaJson,
      updateDisabled =
        (!this.state.transformedContent.length &&
          !this.state.content.length &&
          !this.state.images &&
          this.state.images &&
          !this.state.images.length &&
          !this.state.media_file &&
          !this.state.gifs &&
          this.state.gifs &&
          !this.state.gifs.length) ||
        (!this.state.content.length &&
          !this.state.images.length &&
          !this.state.gifs.length &&
          !this.state.media_file &&
          !this.state.mediaJson);
    const {
      videoError,
      imageError,
      filesSkipped,
      imageUploadingFileSkipped,
      imageUploadingError,
      errorSubmitting,
      formSubmitting,
    } = this.state;
    return (
      <>
        <Prompt
          when={!updateDisabled && !formSubmitting}
          message={texts.unsavedPost}
        />
        <Prompt when={formSubmitting} message={texts.postUploadingPrompt} />
        <Prompt
          when={isEdit && this.state.content.length < 1}
          message={"Changes you made may not be saved."}
        />
        <div
          className={`post-bar__post ${!isEdit ? "post-bar__post--active" : ""
            }`}
        >
          {!isEdit && !hideArrow && (
            <div className="post-bar__post__arrow post-bar__post__arrow--post"></div>
          )}
          <div className="post-bar__post__content">
            {!isEdit && (
              <button
                className="post-bar__post__cancel"
                type="button"
                onClick={() => {
                  if (formSubmitting) return;
                  if (
                    !this.state.dicardVideo &&
                    !this.state.discardImage &&
                    !this.state.discardPost
                  ) {
                    this.props.handleClose();
                  }
                  if (
                    this.state.dicardVideo ||
                    this.state.discardImage ||
                    this.state.discardPost
                  ) {
                    this.setState({ discardModal: true });
                  }
                }}
              >
                <img
                  className="post-bar__post__cancel__icon"
                  src={CloseIcon}
                  alt="close post update"
                />
              </button>
            )}
            {this.state.selectedUser && (
              <UserSelect
                isEdit={isEdit}
                isUserCompany={this.state.isUserCompany}
                selectedUser={this.state.selectedUser}
                handleUserSelectChange={this.handleUserSelectChange}
                companies={user.user.companies}
                profile={user.user.profile}
              />
            )}
            <div className="tag-box__overflow-div">
              <TaggableTextarea
                maxLength={postMaxLength}
                className="post-bar__post__textarea"
                value={this.state.content}
                onChange={this.handleChange}
                shouldGeneratePreview={shouldGeneratePreview}
                // handleYoutubeCheck={true}
                autoFocus={true}
                placeholder="Post Something..."
                isDisabled={formSubmitting}
                hasGeneratedPreview={this.state.hasGeneratedPreview}
                previewType={this.state.previewType}
                isEveryone
              />
            </div>
            <div className="post-bar__post__content__editable">
              {this.state.blobURL && this.state.media_file && (
                <div className="create-video-container">
                  <video key={this.state.blobURL}>
                    <source src={this.state.blobURL} />
                  </video>
                  <button
                    onClick={() => {
                      if (formSubmitting) return;
                      this.removeVideo();
                    }}
                    className="create-video-container__remove-btn"
                  >
                    <img
                      className="create-video-container__remove-btn__icon"
                      src={CloseWhiteIcon}
                      alt="remove video"
                    />
                  </button>
                  {uploading &&
                    uploading.current &&
                    uploading.current === postPreview && (
                      <p className="post-bar__post__content__editable__percentage">
                        Uploading ({uploading.percentage}%)
                      </p>
                    )}
                </div>
              )}
              {this.state.images && this.state.images.length
                ? this.renderImages()
                : null}
              {this.state.gifs && this.state.gifs.length
                ? this.renderGifs()
                : null}
              {isEdit &&
                this.state.mediaJson &&
                this.state.mediaJson.thumbnail ? (
                <div className="create-video-container create-image__container--disabled">
                  <img alt="video" src={this.state.mediaJson.thumbnail} />
                </div>
              ) : null}
            </div>
            {preview &&
              shouldGeneratePreview &&
              preview[this.state.previewType] && (
                <div className="post-bar__post__preview-container">
                  {preview[this.state.previewType] &&
                    preview[this.state.previewType].fetching && (
                      <div className="post-bar__post__preview-container__content">
                        Fetching preview...
                      </div>
                    )}
                  {preview[this.state.previewType] &&
                    !preview[this.state.previewType].fetching &&
                    preview[this.state.previewType].preview &&
                    !!preview[this.state.previewType].preview && (
                      <div className="post-bar__post__preview-container__content">
                        {this.renderPreview(
                          preview[this.state.previewType].preview
                        )}
                      </div>
                    )}
                </div>
              )}
            {videoError && <p className="error-text">{texts.videoError}</p>}
            {imageError && (
              <p className="error-text">{texts.imageError(filesSkipped)}</p>
            )}
            {imageUploadingError && (
              <p className="error-text">
                {texts.imageUploadingError(imageUploadingFileSkipped)}
              </p>
            )}
            {errorSubmitting && (
              <p className="error-text">{texts.errorSubmitting}</p>
            )}
          </div>
          <div className="post-bar__post__action-container">
            <div className="post-bar__post__action-container__actions">
              {!isEdit && (
                <label
                  className={`post-bar__post__action-container__btn
                                ${!!this.state.blobURL ||
                      formSubmitting ||
                      !!this.state.gifs.length
                      ? "post-bar__post__action-container__btn--disabled"
                      : ""
                    }`}
                >
                  <input
                    onChange={(e) => {
                      this.handleImageChange(e, "jpeg");
                    }}
                    disabled={
                      !!this.state.blobURL ||
                      formSubmitting ||
                      !!this.state.gifs.length
                    }
                    className="post-bar__post__action-container__btn__file"
                    type="file"
                    accept="image/jpg,image/png,image/jpeg"
                    name="images[]"
                    ref={this.imagesRef}
                    max={15}
                    multiple
                  />
                  <img src={PhotoIcon} alt="add" />
                  <span className="post-bar__post__action-container__btn__text">
                    Photo
                  </span>
                </label>
              )}
              {!isEdit && (
                <label
                  className={`post-bar__post__action-container__btn
                                ${!!this.state.images.length ||
                      formSubmitting ||
                      !!this.state.gifs.length
                      ? "post-bar__post__action-container__btn--disabled"
                      : ""
                    }`}
                >
                  <input
                    disabled={
                      !!this.state.images.length ||
                      formSubmitting ||
                      !!this.state.gifs.length
                    }
                    onChange={this.handleVideoFileChange}
                    accept="video/*"
                    className="post-bar__post__action-container__btn__file"
                    ref={this.videoRef}
                    type="file"
                  />
                  <img src={VideoIcon} alt="add video" />
                  <span className="post-bar__post__action-container__btn__text">
                    Video
                  </span>
                </label>
              )}
              {!isEdit && (
                <label
                  className={`post-bar__post__action-container__btn
                                ${!!this.state.blobURL ||
                      formSubmitting ||
                      !!this.state.images.length
                      ? "post-bar__post__action-container__btn--disabled"
                      : ""
                    }`}
                >
                  <input
                    onChange={(e) => {
                      this.handleImageChange(e, "gifs");
                    }}
                    disabled={
                      !!this.state.blobURL ||
                      formSubmitting ||
                      !!this.state.images.length
                    }
                    className="post-bar__post__action-container__btn__file"
                    type="file"
                    accept="image/gif"
                    name="gifs[]"
                  // ref={this.imagesRef}
                  />
                  <img src={gifIcon} alt="add" />
                  <span className="post-bar__post__action-container__btn__text">
                    Gif
                  </span>
                </label>
              )}
            </div>
            <button
              disabled={updateDisabled || formSubmitting}
              className={`post-bar__post__action-btn
                            ${updateDisabled || formSubmitting
                  ? "post-bar__post__action-btn--disabled"
                  : ""
                }`}
              onClick={() => {
                if (isEdit) {
                  this.handleUpdate();
                  return;
                }
                this.handleSubmit();
                return;
              }}
            >
              <img
                className="post-bar__post__action-btn__icon"
                src={PostBtn}
                alt="post btn"
              />
              <span className="post-bar__post__action-btn__text">
                {isEdit ? "Update" : "Post"}
              </span>
            </button>
          </div>
          {this.state.discardModal ? (
            <DiscardPostDraft
              modalTitle={"Discard draft?"}
              text={
                "This will remove all the changes you have made to this post."
              }
              handleClose={() => this.setState({ discardModal: false })}
              handleAction={() => {
                window.onbeforeunload = null;
                this.props.handleClose();
              }}
              actionText="Discard"
            />
          ) : null}
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    token: state.login.token,
    user: state.user,
    preview: state.feedReducer.preview,
    uploading: state.feedReducer.uploading,
  };
}

const mapDispatchToProps = {
  createAPost,
  globalUploadPhoto,
  emptyAPreview,
  createAPhotoPost,
  updateAPost,
  hideEditAPostModal,
  updateAPhotoPost,
  resetPreviews,
  setPreview,
};

CreatePost.propTypes = {
  isEdit: PropTypes.bool,
  editablePost: PropTypes.object,
  preview: PropTypes.object,
  handleClose: PropTypes.func,
  hideArrow: PropTypes.bool,
  token: PropTypes.string,
  setPreview: PropTypes.func,
  editablePostType: PropTypes.string,
  user: PropTypes.object,
  emptyAPreview: PropTypes.func,
  resetPreviews: PropTypes.func,
  createAPost: PropTypes.func,
  createAPhotoPost: PropTypes.func,
  updateAPhotoPost: PropTypes.func,
  hideEditAPostModal: PropTypes.func,
  updateAPost: PropTypes.func,
  globalUploadPhoto: PropTypes.func,
  uploading: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreatePost);
