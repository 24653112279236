import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import ReactTooltip from "react-tooltip";
// import Cookies from 'universal-cookie';
import { saveDonationModalData, setHideDonationModal, setShowDonationModal, showProfilePassbookModal, toggleProfileMenuModal } from "../actions/common/modals";
import { logoutUserLocally, userLogout } from "../actions/login/logout";
import CloseIcon from "../assets/images/common/modals/close.svg";
import RedTriangle from "../assets/images/profile/popup/redarrow.svg";
import { genericErrorText } from "../utils/commonVariables";
import { miscLinks, profileLinks } from "../utils/internalLinks";
import { ProfileUrls } from "../utils/links";
import UserName from "./profile/UserName";
import ProfilePopUpList from "./ProfilePopUpList";
import Avatar from "./user/Avatar";

class ProfilePopUp extends Component {
  constructor(props) {
    super(props);
    // binding function to toggle modal
    this.toggleModal = this.toggleModal.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.handleOpenPassbook = this.handleOpenPassbook.bind(this);
    this.handleOpenPaidTasterProgram =
      this.handleOpenPaidTasterProgram.bind(this);
  }

  toggleModal(type) {
    const { toggleProfileMenuModal } = this.props;
    toggleProfileMenuModal(type);
  }

  handleLogout() {
    const { logoutUserLocally, history, token } = this.props;
    // const cookies = new Cookies();
    // cookies.remove('passwordVerified');

    userLogout(token)
      .then((res) => {
        if (!res) {
          alert(genericErrorText.general);
        } else {
          logoutUserLocally();
          history.replace(miscLinks.home);
        }
      })
      .catch((error) => {
        alert(genericErrorText.general);
        return false;
      });
  }

  handleOpenPassbook(earning) {
    const { history } = this.props;
    if (earning > 0) {
      history.push(profileLinks.myPassbook);
    }
  }

  handleOpenPaidTasterProgram() {
    const { history } = this.props;
    history.push(profileLinks.paidTasterProgram);
  }

  render() {
    const {
      user: {
        user: { profile, companies },
      },
      isMobile,
      handleDonationModal,
    } = this.props;

    if (!profile)
      return (
        <div className="profile-menu-popup">
          <div className="profile-menu-popup__list__container profile-menu-popup__list__container--secondary">
            <ul className="profile-menu-popup__list">
              <li
                className="profile-menu-popup__list__item"
                onClick={this.handleLogout}
              >
                <button className="profile-menu-popup__list__title">
                  Logout
                </button>
              </li>
            </ul>
          </div>
        </div>
      );
    return (
      <div className="profile-menu-popup">
        <div>
          <Link
            to={ProfileUrls.userProfileLink(profile.handle, profile.id)}
            className="profile-menu-popup__head"
          >
            <div className="profile-menu-popup__head__img-container">
              <Avatar
                className="profile-menu-popup__head__img"
                src={
                  profile.image_meta
                    ? JSON.parse(profile.image_meta).original_photo
                    : ""
                }
                alt="user"
                profile={profile}
              />
            </div>
            <div className="profile-menu-popup__head__details">
              <p className="profile-menu-popup__head__name">
                <UserName
                  isVerified={!!profile.verified}
                  addClassToText={true}
                  name={profile.name}
                />
              </p>
              <p className="profile-menu-popup__head__handle">
                @{profile.handle}
              </p>
            </div>
            <img src={RedTriangle} alt="close profile popup" className={`${isMobile ? "red-triangle" : ""}`}/>
          </Link>
          {isMobile && (
            <button className="icon-btn profile-menu-popup__close">
              <img src={CloseIcon} alt="close profile popup" />
            </button>
          )}
        </div>
          <ProfilePopUpList
            handleDonationModal={handleDonationModal}
            profile={profile}
            companies={companies}
            handleLogout={this.handleLogout}
            toggleModal={this.toggleModal}
            handleOpenPassbook={this.handleOpenPassbook}
            handleOpenPaidTasterProgram={this.handleOpenPaidTasterProgram}
          />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    token: state.login.token,
    showDonationModal: state.modalReducer.collaboration.showDonationModal,
  };
}

const mapDispatchToProps = {
  toggleProfileMenuModal,
  logoutUserLocally,
  showProfilePassbookModal,
  setShowDonationModal,
  setHideDonationModal,
  saveDonationModalData
};

ProfilePopUp.propTypes = {
  isMobile: PropTypes.bool,
  isLoggedOut: PropTypes.bool,
  user: PropTypes.object,
  history: PropTypes.object,
  toggleProfileMenuModal: PropTypes.func,
  logoutUserLocally: PropTypes.func,
  showProfilePassbookModal: PropTypes.func,
  handleDonationModal: PropTypes.func
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProfilePopUp)
);
