import {
    GET_NOTIFICATIONS_REQUEST,
    GET_NOTIFICATIONS_SUCCESS,
    GET_NOTIFICATIONS_FAILURE,
    RESET_NOTIFICATIONS,
    MARK_ALL_NOTIFICATIONS_READ_REQUEST,
    MARK_ALL_NOTIFICATIONS_READ_SUCCESS,
    MARK_ALL_NOTIFICATIONS_READ_FAILURE,
    READ_NOTIFICATION_FROM_SOCKET,
    NOTIFICATION_READ_SUCCESS,
    UPDATE_NOTIFICATION_STATUS
} from "../actions/notifications/constants";
import { FOLLOW_USER_SUCCESS } from "../actions/profile/constants";

const INITIAL_STATE = {
    all: [],
    page: 1,
    fetching: false,
    markingAllAsReadProcessing: false,
    hasFetched: false
}

export default function notifications(state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_NOTIFICATIONS_REQUEST:
            return {
                ...state,
                fetching: true
            };
        case GET_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                fetching: false,
                all: state.page === 1
                    ? action.payload.data
                    : [...state.all, ...action.payload.data],
                page: state.page + 1,
                hasFetched: true,
                hasEndReached: !action.payload.data.length,

            }
        case GET_NOTIFICATIONS_FAILURE:
            return {
                ...state,
                fetching: false,
                error: true,
                errorMsg: action.error
            }
        case MARK_ALL_NOTIFICATIONS_READ_REQUEST:
            return {
                ...state,
                markingAllAsReadProcessing: true
            }
        case UPDATE_NOTIFICATION_STATUS:
            return {
                ...state,
                all: state.all.map((val, index) => {
                    if (val.id !== action.payload.id) return val;
                    return { ...val, ["status"]: action.payload.data.status }
                })
            }
        case MARK_ALL_NOTIFICATIONS_READ_SUCCESS:
            return {
                ...state,
                markingAllAsReadProcessing: false,
                all: state.all.map((notification) => {
                    return {
                        ...notification,
                        read_at: new Date()
                    }
                })
            }
        case MARK_ALL_NOTIFICATIONS_READ_FAILURE:
            return {
                ...state,
                markingAllAsReadProcessing: false,
            }
        case READ_NOTIFICATION_FROM_SOCKET:
            return {
                ...state,
                all: [{
                    id: action.payload.data.id,
                    ...action.payload
                }, ...state.all],
            }
        case NOTIFICATION_READ_SUCCESS:
            return {
                ...state,
                all: state.all.map(notification => {
                    if (notification.id !== action.payload.notificationId) {
                        return notification
                    }
                    return {
                        ...notification,
                        read_at: new Date()
                    }
                })
            }
        case FOLLOW_USER_SUCCESS:
            return {
                ...state,
                all: state.hasFetched
                    ? state.all
                        && state.all.length
                        ? state.all.map(notification => {
                            if (notification.data.profile
                                && notification.data.profile.id === action.payload.profileId) {
                                return {
                                    ...notification,
                                    isFollowing: true,
                                    showFollowingButton: true
                                }
                            }
                            return notification;
                        })
                        : state.all
                    : state.all
            }
        case RESET_NOTIFICATIONS:
            return INITIAL_STATE;
        default:
            return state;
    }
}