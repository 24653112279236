import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toggleProfileCompletionModal } from '../../../../actions/common/modals';
import FoodInterest from '../../../../assets/images/profile/completion/food-interest.svg';
import ProfileCompletionCompleted from './ProfileCompletionCompleted';


const texts = {
    title: 'Cuisines you absolutely dig',
    helper: 'Tell us something about the kind of food & beverage items that satiate your palate. This will enable us to personalize your feed on new products, tasting classes, and assignments.',
    btn: '+ Favourite Cuisines'
}

class CuisinesCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasProfileCompleted: (this.props.profile
                && this.props.profile.cuisines
                && !!this.props.profile.cuisines.length)
        }
        // binding function to toggle modal
        this.toggleModal = this.toggleModal.bind(this);
    }

    toggleModal() {
        const { toggleProfileCompletionModal } = this.props;
        toggleProfileCompletionModal('cuisine');
    }

    componentDidUpdate() {
        if (this.props.profile.cuisines
            && this.props.profile.cuisines.length
            && !this.state.hasProfileCompleted) {
                this.setState({
                    hasProfileCompleted: true
                })
            }
    }

    render() {
        return (<div className="feed-common-cards profile-completion-card">
            <div className="profile-completion-card__image__container">
                <img className="profile-completion-card__image" src={FoodInterest} alt={texts.title} />
            </div>
            <div className="profile-completion-card__details">
                    <p className="profile-completion-card__details__title">{texts.title}</p>
                    <p className="profile-completion-card__details__helper">{texts.helper}</p>
            </div>
            <div className="profile-completion-card__action">
                <button className="profile-completion-card__action__btn" onClick={this.toggleModal}>
                    <span className="profile-completion-card__action__btn__text">{texts.btn}</span>
                </button>
            </div>
            { this.state.hasProfileCompleted && <ProfileCompletionCompleted /> }
        </div>)
    }
};

function mapStateToProps(state) {
    return {
        token: state.login.token
    };
}

const mapDispatchToProps = {
    toggleProfileCompletionModal
}

export default connect(mapStateToProps, mapDispatchToProps)(CuisinesCard);