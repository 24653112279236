import { HIDE_TTFB_BUSINESS_MODAL,
    SHOW_TTFB_BUSINESS_MODAL } from '../../actions/common/constants';

const INITIAL_STATE = {
    isVisible: false,
    data: null
}
export default function businessModals(state = INITIAL_STATE, action) {
    switch(action.type) {
        case SHOW_TTFB_BUSINESS_MODAL:
            return {
                ...state,
                isVisible: true,
                data: action.payload.data,
                type: action.payload.type
            }
        case HIDE_TTFB_BUSINESS_MODAL:
            return INITIAL_STATE
        default:
            return state;
    }
}