import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CommentsListItem from './CommentsListItem';

function CommentsList(props) {
    const [comments, setComments] = useState(props.comments);
    useEffect(() => {
        setComments(props.comments);
    }, [props.comments]);
    const commentsList = comments.map((comment, commentIndex) => <CommentsListItem
        key={`comment-index-${commentIndex}`}
        { ...props }
        comment={comment} />)
    return (<ul className="post-comments-list">{commentsList}</ul>)
};

CommentsList.propTypes = {
    comments: PropTypes.array.isRequired,
    showOptionsIcon: PropTypes.bool,
    profileId: PropTypes.number,
    handleDelete: PropTypes.func,
}

export default CommentsList;