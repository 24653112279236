import React, { Component } from "react";
import PropTypes from "prop-types";
import CommonCard from "./Common";
import UserBar from "../common/UserBar";
import MetaBar from "../common/MetaBar";
import PostComments from "../common/PostComments";
import TaggedText from "../common/TaggedText";
import LoginForm from "../../../loggedOut/Login/LoginForm";
class SharedCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoginForm: false,
    };
    this.setShowLoginForm = this.setShowLoginForm.bind(this);
    this._renderCard = this._renderCard.bind(this);
  }

  setShowLoginForm = function (value) {
    this.setState({
      showLoginForm: value,
    });
  };

  _renderCard() {
    const { cardData, autoFocusComments, isPublic, handlePrompt } = this.props,
      { type } = cardData;
    return (
      <CommonCard
        publicClickAction={() => this.setShowLoginForm(true)}
        isPublic={isPublic}
        type={type}
        showOptionsIcon={false}
        isShared={true}
        hideMetaBar={true}
        autoFocusComments={
          typeof autoFocusComments === "boolean" ? autoFocusComments : true
        }
        cardData={cardData}
        handlePrompt={handlePrompt}
      />
    );
  }

  render() {
    const {
      cardData,
      commentsData,
      isDetailsPage,
      isPublic,
      publicClickAction,
      showFlagOptions,
      showOptionsIcon,
    } = this.props,
      { meta, shared, sharedBy, type } = cardData;
    const createdAt = shared && shared.created_at ? shared.created_at : null,
      sharedId = shared && shared.id ? shared.id : null,
      postId = cardData[type] && cardData[type].id ? cardData[type].id : null,
      isFullWidth = type === "product",
      sharedText =
        shared && shared.content
          ? shared.content.text
            ? shared.content.text
            : shared.content
          : "",
      sharedTaggedProfiles =
        shared && shared.content && shared.content.profiles
          ? shared.content.profiles
          : [];
    return (
      <div className="shared-card">
        <UserBar
          postType={type}
          postId={postId}
          isShared={true}
          sharedId={sharedId}
          showOptionsIcon={showOptionsIcon}
          cardData={cardData}
          createdAt={createdAt}
          isDetailsPage={isDetailsPage}
          publicClickAction={publicClickAction}
          isPublic={isPublic}
          showFlagOptions={showFlagOptions}
          profile={sharedBy}
        />
        {sharedText && (
          <div className="post-details">
            <TaggedText
              isLinkified={true}
              hashtagEventLabel="Shared Post"
              isPublic={isPublic}
              isOpen={!!isDetailsPage}
              className="post-details__text shared-card__text"
              profiles={sharedTaggedProfiles}
              text={sharedText}
            />
          </div>
        )}

        {cardData[type] && (
          <div
            className={`shared-card__original-card  ${isFullWidth ? "shared-card__original-card--full" : ""
              }`}
          >
            <div
              className={`shared-card__original-card__container ${type == "polling"
                  ? "shared-card__original-card__container--spacing"
                  : ""
                } ${isFullWidth ? "shared-card__original-card__container--full" : ""
                }`}
            >
              {this._renderCard()}
            </div>
          </div>
        )}
        {postId && meta && (
          <MetaBar
            isPublic={isPublic}
            postType={type}
            isShared={true}
            postId={postId}
            meta={meta}
            likeActionProcessing={cardData.likeActionProcessing}
            fetchingComments={commentsData && commentsData.fetching}
            postToShare={cardData}
            publicClickAction={publicClickAction}
            sharedId={sharedId}
          />
        )}
        {commentsData && (
          <PostComments
            showOptionsIcon={showOptionsIcon}
            isShared={true}
            sharedId={sharedId}
            postId={postId}
            commentsData={commentsData}
            postType={type}
          />
        )}
        {this.state.showLoginForm && (
          <LoginForm
            shouldOnlyToggle={true}
            toggleLoginForm={() => this.setShowLoginForm(false)}
          />
        )}
      </div>
    );
  }
}

SharedCard.propTypes = {
  cardData: PropTypes.object.isRequired,
  autoFocusComments: PropTypes.bool,
  isDetailsPage: PropTypes.bool,
  showFlagOptions: PropTypes.bool,
};

export default SharedCard;
