import React from 'react';
import { Link } from 'react-router-dom';
import { socialLinks } from '../utils/links';
import FbFooter from '../assets/images/footer/social/facebook.svg';
import IGfooter from '../assets/images/footer/social/instagram.svg';
import TWfooter from '../assets/images/footer/social/twitter.svg';
import { miscLinks } from '../utils/internalLinks';

const socialFooter = [{
    title: 'Twitter',
    link: socialLinks.twitter,
    icon: TWfooter
}, {
    title: 'Facebook',
    link: socialLinks.facebook,
    icon: FbFooter
}, {
    title: 'Instagram',
    link: socialLinks.instagram,
    icon: IGfooter
}], footerLinks = [{
    title: 'Blog',
    link: miscLinks.blog,
    externalLink: true
}, {
    title: 'Join us',
    link: miscLinks.career
}, {
    title: 'Terms & Conditions',
    link: miscLinks.terms
}, {
    title: 'Privacy Policy',
    link: miscLinks.privacy
}];

function Footer() {
    return (<footer className="login__footer login__footer--white">
        <div className="login__footer__container">
            <div className="login__footer__container__item">
                {
                    socialFooter.map((_, i) => {
                        return (<a className="login__footer__social" key={`footer-social-${i}`} target="_blank" rel="noopener noreferrer" href={_.link}>
                            <img className="login__footer__social__icon" src={_.icon} alt={_.title}/>
                        </a>)
                    })
                }
            </div>
            <div className="login__footer__links login__footer__container__item">
                <ul className="login__footer__links__list">
                    {
                        footerLinks.map((_, i) => {
                            return (<li key={`footer-links-${i}`} className="login__footer__links__list__item">
                                {
                                    _.externalLink
                                    ? <a target="_blank" rel="noopener noreferrer" className="login__footer__link" href={_.link}>{_.title}</a>
                                    : <Link className="login__footer__link" to={_.link}>{_.title}</Link>
                                }
                            </li>)
                        })
                    }
                </ul>
            </div>
            <p className="login__footer__link login__footer__container__item--right login__footer__container__item">© TagTaste Foods Pvt. Ltd. | {new Date().getFullYear()}</p>
        </div>
        <div className="login__footer__helper">
            <p className="login__footer__link">Made with <span role="img" aria-label="love">❤️</span> in India</p>
        </div>
    </footer>)
};

export default Footer;