import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { miscLinks } from '../../../utils/internalLinks';
import CloseIcon from '../../../assets/images/login/close.svg';
import SocialLogin from '../../../components/login/SocialLogin';

const DefaultLogin = (props) => {
  useEffect(() => {
    if (!window.FB) {
      const script = document.createElement('script');
      script.src = 'https://connect.facebook.net/en_US/sdk.js';
      script.async = true;
      script.defer = true;
      script.type = 'text/javascript';
      script.onload = function () {
        window.fbAsyncInit = function () {
          if (window.FB) {
            window.FB.init({
              appId: process.env.REACT_APP_FACEBOOK_ID,
              autoLogAppEvents: true,
              xfbml: true,
              version: process.env.REACT_APP_FACEBOOK_VERSION,
            });
          }
        };
      };
      document.body.appendChild(script);
    }
  }, []);
  useEffect(() => {
    function initSigninV2() {
      window.auth2 = window.gapi.auth2.init({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        scope: 'profile',
      });
    }
    if (!window.gapi) {
      const script = document.createElement('script');
      script.src = 'https://apis.google.com/js/platform.js';
      script.async = true;
      script.defer = true;
      script.type = 'text/javascript';
      script.onload = function () {
        if (window.gapi) {
          window.gapi.load('auth2', initSigninV2);
        }
      };
      document.body.appendChild(script);
    }
  }, []);

  return (
    <div className="login-form">
      <div className="login-form__backdrop">
        {props.shouldOnlyToggle ? (
          <button type="button" className="login-form__cancel" onClick={props.toggleLoginForm}>
            <img src={CloseIcon} alt="go to homepage" />
          </button>
        ) : (
          <Link to={miscLinks.home} className="login-form__cancel">
            <img src={CloseIcon} alt="go to homepage" />
          </Link>
        )}
        <div className="login-form__container">
          <div>
            <p className="login-form__title">Log in</p>
            <SocialLogin publicPageLink={props.publicPageLink} />
            <button
              onClick={
                props.shouldOnlyToggle && props.openLoginForm
                  ? props.openLoginForm
                  : props.toggleLoginForm
              }
              className="login-form__action__btn login-form__action__btn--email"
            >
              Email
            </button>
          </div>

          <div className="login-form__divider">
            <div className="login-form__divider__line"></div>
            <span className="login-form__divider__text">or</span>
            <div className="login-form__divider__line"></div>
          </div>

          <div>
            <button
              onClick={() => props.toggleLoginForm('phone')}
              className="login-form__action__btn"
            >
              Log in via Phone
            </button>
            <p className="login-form__link">
              Don&apos;t have an account?
              {props.shouldOnlyToggle && props.openSignUpForm ? (
                <button
                  onClick={props.openSignUpForm}
                  className="login-form__link login-form__link--btn login-form__link--blue"
                >
                  Sign up
                </button>
              ) : (
                <Link className="login-form__link login-form__link--blue" to={miscLinks.register}>
                  Sign up
                </Link>
              )}
            </p>
            <p className="login-form__helper-link">
              By signing up on TagTaste, you are agreeing to our &nbsp;
              <Link
                className="login-form__helper-link login-form__helper-link--blue"
                to={miscLinks.terms}
              >
                Terms &amp; Conditions
              </Link>
              &nbsp;and&nbsp;
              <Link
                className="login-form__helper-link login-form__helper-link--blue"
                to={miscLinks.privacy}
              >
                Privacy Policy
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

DefaultLogin.propTypes = {
  toggleLoginForm: PropTypes.func,
  publicPageLink: PropTypes.string,
  openLoginForm: PropTypes.func,
  openSocialLogin: PropTypes.func,
  openSignUpForm: PropTypes.func,
  shouldOnlyToggle: PropTypes.bool,
};

export default DefaultLogin;
