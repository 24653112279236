import React from "react";
import PropTypes from "prop-types";
import { pollTextLimits } from "../../utils/commonVariables";

const TextOptionsItem = ({
  option,
  handleOptionsChange,
  handleRemoveOptions,
  isEdit,
  disabled,
  index,
  editablePost,
  options,
}) => {
  return (
    <li className="poll-create-container__options__item">
      <input
        className="poll-create-container__options__item__input"
        type="text"
        value={option.text}
        maxLength={pollTextLimits.textOption}
        placeholder={`Choice ${index + 1}`}
        onChange={(e) => handleOptionsChange({ text: e.target.value }, index)}
      />
      {!disabled ? (
        !!(index && index >= 2) && (
          <button
            className="poll-create-container__options__item__remove-option"
            onClick={() => handleRemoveOptions(index, isEdit)}
          >
            <span className="poll-create-container__options__item__remove-option__text">
              &times;
            </span>
          </button>
        )
      ) : editablePost.length !== options.length ? (
        !!(index && index >= options.length - 1) ? (
          <button
            className="poll-create-container__options__item__remove-option"
            onClick={() => handleRemoveOptions(index, isEdit)}
          >
            <span className="poll-create-container__options__item__remove-option__text">
              &times;
            </span>
          </button>
        ) : (
          ""
        )
      ) : (
        ""
      )}
    </li>
  );
};

function TextOptions(props) {
  const {
    handleRemoveOptions,
    isEdit,
    options,
    disabled,
    handleOptionsChange,
    editablePost,
  } = props;
  if (!options || (options && !options.length)) return null;
  const optionItems = options.map((option, index) => (
    <TextOptionsItem
      key={`poll-option-${index}`}
      handleOptionsChange={handleOptionsChange}
      handleRemoveOptions={handleRemoveOptions}
      isEdit={isEdit}
      disabled={disabled}
      index={index}
      option={option}
      editablePost={editablePost}
      options={options}
    />
  ));
  return <ul className="poll-create-container__options">{optionItems}</ul>;
}

TextOptions.propTypes = {
  options: PropTypes.array,
  handleOptionsChange: PropTypes.func,
  handleRemoveOptions: PropTypes.func,
  isEdit: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default TextOptions;
