import React from "react";
import CommonPortal from "../common/CommonPortal";
import ImageCropperModal from "../../create/ImageCropperModal";
import { ProfileUrls } from "../../../utils/links";
import UploadPhotoIcon from "../../../assets/images/profile/prompts/photo.svg";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Loader from "../../misc/Loader";

const ProfilePhoto = (props) => {
    const { form: { isUpdating, selectedMedia, error },
        handleMediaChange,
        handleUpdate,
        handleClose,
        profile: { imageUrl, handle, id }
    } = props;
    const title = isUpdating ? "Uploading..." : error ? "Upload Failed" : "Photo uploaded successfully";
    const subtitle = isUpdating ? "Connect the dots of your digital presence start with your profile picture!" : error ? error : null;
    const handleMediaUpload = (croppedImage) => {
        let data = new FormData();
        data.append("image", croppedImage);
        data.append("_method", "PUT");
        handleUpdate(data, true);
    }
    return (
        <>
            {selectedMedia ?
                <CommonPortal>
                    <ImageCropperModal
                        modalTitle="Crop Image"
                        image={selectedMedia}
                        closeModal={handleClose}
                        aspectRatio={1 / 1}
                        handleImageChange={handleMediaChange}
                        handleUploadImage={handleMediaUpload}
                    />
                </CommonPortal>
                : null
            }
            <div className="profile-prompt__container">
                <div className="profile-prompt__placeholder__container">
                    {isUpdating ? <Loader isBothCentered /> : <img src={imageUrl || UploadPhotoIcon} alt={title} className="profile-prompt__placeholder" />}
                </div>
                <h4 className={`profile-prompt__title ${isUpdating ? '' : error ? 'error' : imageUrl ? 'success' : ''}`}>{title}</h4>
                {subtitle ? <h5 className="profile-prompt__subtitle">{subtitle}</h5> : null}
                {!imageUrl ?
                    <label htmlFor="profile-prompt-image-input" className={`profile-prompt__action ${isUpdating ? 'disabled' : null}`}>Upload your photo</label>
                    : null}
                <input
                    id="profile-prompt-image-input"
                    type="file"
                    accept="image/jpg,image/png,image/jpeg"
                    onInput={!isUpdating ? handleMediaChange : null}
                    hidden
                    disabled={isUpdating}
                />
                <Link
                    className={`profile-prompt__link ${isUpdating ? 'disabled' : ''}`}
                    to={isUpdating ? null : ProfileUrls.userProfileLink(handle, id)}
                >
                    View Profile
                </Link>
            </div>
        </>
    )
};



export default ProfilePhoto;