import React from 'react';
import PropTypes from 'prop-types';
import CompanyFeedCard from './CompanyFeedCard';
import ProfileFeedCard from './ProfileFeedCard';

const feedCardTypes = {
    profile: ProfileFeedCard,
    company: CompanyFeedCard
}

function FeedCard({ cardData,
    ...props }) {
    if (!cardData) return null;
    const FeedCardType = feedCardTypes[cardData.meta.type] || null;
    if (!FeedCardType) return FeedCardType;
    return (<FeedCardType cardData={cardData} {...props} />)
};

FeedCard.propTypes = {
    meta: PropTypes.object,
    cardData: PropTypes.object
}

export default FeedCard;