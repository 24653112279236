import React from 'react';
import PropTypes from 'prop-types';
import PostCard from '../PostCard';
import PhotoCard from '../PhotoCard';
import CollaborateCard from '../CollaborateCard';
import PollingCard from '../PollingCard';

const Cards = {
    shoutout: PostCard,
    photo: PhotoCard,
    collaborate: CollaborateCard,
    polling: PollingCard
}

function PostAd(props) {
    const { adData: { payload },
        comments,
        showOptionsIcon } = props,
        cardType = payload.type,
        ComponentCard = cardType && Cards[cardType] ? Cards[cardType] : null;
    if (!ComponentCard || !payload[cardType]) return null;
    const type = payload.type,
        commentShareObj = `${type}-${payload[type].id}-ad`,
        commentsData = comments.byPostId[commentShareObj] || null;
    return (<ComponentCard cardData={payload}
        isAd={true}
        type={type}
        commentsData={commentsData}
        showOptionsIcon={showOptionsIcon} />)
};

PostAd.propTypes = {
    adData: PropTypes.object,
    comments: PropTypes.object,
    showOptionsIcon: PropTypes.bool
}

export default PostAd;