/* eslint-disable no-undef */
import React from "react";
import { toast, Slide } from "react-toastify";
import { Link } from "react-router-dom";
import get from "lodash/get";

export const showToast = (
  type = "",
  link = "",
  token = false,
  id = false,
  handleAction = "",
  handleClose = () => { },
  autoClose
) => {
  let text;
  let unblockProfile = get(handleAction, "unblockProfile", false);
  switch (type) {
    case "post":
      text = "Your post has been created";
      break;
    case "poll":
      text = "Your poll has been created";
      break;
    case "pollUpdate":
      text = "Your poll has been updated";
      break;
    case "blockedprofile":
      text = "Successfully Blocked";
      break;
    case "blockedcompany":
      text = "Successfully Blocked";
      break;
    case "unblockedprofile":
      text = "Successfully unblocked";
      break;
    case "polling":
      text = "Your poll has been deleted";
      break;
    case "collaborate":
      text = "Your collaboration has been deleted";
      break;
    case "surveys":
      text = "Your survey has been deleted";
      break;
    case "product-review":
      text = "Your tasting has been deleted";
      break;
    case "photo":
      text = "Your photo has been deleted";
      break;
    case "shared":
      text = "Your shared post has been deleted";
      break;
    case "quiz":
      text = "Your quiz has been deleted";
      break;
    case "postDelete":
      text = "Your post has been deleted";
      break;
    case "alreadyblockedprofile":
      text =
        "You have already blocked this profile. Please refresh your screens.";
      break;
    case "alreadyblockedcompany":
      text =
        "You have already blocked this company. Please refresh your screens.";
      break;
    case "mandatoryError":
      text =
        link.length > 1
          ? "Please answer mandatory questions:"
          : "Please answer mandatory question:";
      break;
    case "noPassBookTransactions":
      text = "No transaction history available";
      break;
    case "reorder":
      text = link;
      break;
    case "sizeExceed":
      text = "Maximum file size should not exceed 10 MB";
      break;
    case "inviteSuccess":
      text = link;
      break;
    case "alreadyInviteTester":
      text = "Taster has been already Invited";
      break;
    case "genericErrorText":
      text = link;
      break;
    default:
      text = "";
  }
  const getErrorToastText = () => {
    if (link.length === 0) {
      return "";
    } else if (link.length === 1) {
      return `Q${link[0]["position"]}`;
    } else if (link.length === 2) {
      return `Q${link[0]["position"]}` + " and " + `Q${link[1]["position"]}`;
    } else {
      var lastElement = link.pop();
      var joinedElements = link
        .map(function (elem) {
          return `Q${elem["position"]}`;
        })
        .join(", ");
      return joinedElements + " and " + `Q${lastElement["position"]}`;
    }
  };
  let LinkComponent;
  if (type === "blockedprofile" || type === "blockedcompany") {
    LinkComponent = (
      <Link
        className="createbar__toast__content__link"
        onClick={() => {
          if (unblockProfile) unblockProfile();
        }}
      >
        Unblock
      </Link>
    );
  } else {
    LinkComponent = (
      <Link className="createbar__toast__content__link" to={link}>
        View
      </Link>
    );
  }

  const typeWithNoLinks = [
    "pollUpdate",
    "deletePoll",
    "unblockedprofile",
    "alreadyblockedprofile",
    "alreadyblockedcompany",
    "mandatoryError",
    "noPassBookTransactions",
    "reorder",
    "deleteCollaborate",
    "polling",
    "collaborate",
    "surveys",
    "product-review",
    "photo",
    "shared",
    "quiz",
    "postDelete",
    "inviteSuccess",
    "alreadyInviteTester",
    "genericErrorText",
  ];

  const ToastComponent = (
    <div className="createbar__toast__content">
      <span className="createbar__toast__content__text">
        {text}{" "}
        {type === "mandatoryError" ? <span>{getErrorToastText()}</span> : null}
      </span>
      {typeWithNoLinks.includes(type) ? null : LinkComponent}
    </div>
  );

  toast(ToastComponent, {
    className: `createbar__toast toast_${type}`,
    position: "bottom-center",
    autoClose: autoClose || 3000,
    transition: Slide,
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    closeButton: false,
    theme: "dark",
    toastId: id,
    onClose: handleClose
  });
};

export const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

export function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}

export function rotateSize(width, height, rotation) {
  const rotRad = getRadianAngle(rotation);

  return {
    width:
      Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height:
      Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  };
}

export async function getCroppedImg(
  imageSrc,
  pixelCrop,
  rotation = 0,
  flip = { horizontal: false, vertical: false }
) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    return null;
  }

  const rotRad = getRadianAngle(rotation);

  // calculate bounding box of the rotated image
  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
    image.width,
    image.height,
    rotation
  );

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth;
  canvas.height = bBoxHeight;

  // translate canvas context to a central location to allow rotating and flipping around the center
  ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
  ctx.rotate(rotRad);
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
  ctx.translate(-image.width / 2, -image.height / 2);

  // draw rotated image
  ctx.drawImage(image, 0, 0);

  // croppedAreaPixels values are bounding box relative
  // extract the cropped image using these values
  const data = ctx.getImageData(
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height
  );

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image at the top left corner
  ctx.putImageData(data, 0, 0);

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve) => {
    canvas.toBlob((file) => {
      // resolve(URL.createObjectURL(file));
      resolve(file);
    }, "image/jpeg");
  });
}

export const previewWithAspectRatio = (imageFile, aspectRatio, callback, fillColor = "#7f7f7f") => {
  const reader = new FileReader();
  reader.onload = (e) => {
    const image = new Image();
    image.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      const originalWidth = image.width;
      const originalHeight = image.height;

      let desiredWidth;
      let desiredHeight;

      if (originalWidth / originalHeight > aspectRatio) {
        desiredWidth = originalWidth;
        desiredHeight = Math.floor(desiredWidth / aspectRatio);
      } else {
        desiredHeight = originalHeight;
        desiredWidth = Math.floor(desiredHeight * aspectRatio);
      }

      canvas.width = desiredWidth;
      canvas.height = desiredHeight;

      ctx.fillStyle = fillColor;
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      ctx.drawImage(
        image,
        0,
        0,
        originalWidth,
        originalHeight,
        (desiredWidth - originalWidth) / 2,
        (desiredHeight - originalHeight) / 2,
        originalWidth,
        originalHeight
      );

      const dataURL = canvas.toDataURL("image/png");
      callback(dataURL);
    };

    image.src = e.target.result;
  };

  reader.readAsDataURL(imageFile);
};