import { GET_TTFB_LEAD_STATUS_REQUEST,
    GET_TTFB_LEAD_STATUS_SUCCESS,
    GET_TTFB_LEAD_STATUS_FAILURE } from "../../actions/business/constants";

const INITIAL_STATE = {
    fetching: false,
    all: [],
    error: false
}

export default function status(state = INITIAL_STATE, action) {
    switch(action.type) {
        case GET_TTFB_LEAD_STATUS_REQUEST:
            return {
                ...state,
                fetching: true
            }
        case GET_TTFB_LEAD_STATUS_SUCCESS:
            return {
                ...state,
                all: action.payload,
                fetching: false,
            }
        case GET_TTFB_LEAD_STATUS_FAILURE:
            return {
                ...state,
                fetching: false,
                error: true
            }
        default:
            return state;
    }
}