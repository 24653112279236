import React from 'react';
import PropTypes from 'prop-types'
import { decodeString } from '../../../utils/helper';

const ShowInterestAddressCheckBoxItem = ({ address,
    index,
    handleSelect,
    isChecked }) => {
    return (<li>
        <input className="pr-radio-btn"
            id={`applier_address-${index}`}
            type="radio"
            name="applier_address_select"
            checked={isChecked || false}
            onChange={(e) => {
                if (e.target.checked) {
                    handleSelect(address.id, address);
                }
            }} />
        <label htmlFor={`applier_address-${index}`} className="show-interest-product-modal__choose-address__label">
            <span className="pr-radio-btn-img modal-header-img"></span>
            <div className="show-interest-product-modal__choose-address__option">
                <p className="show-interest-product-modal__choose-address__option__head">{decodeString(address.label)}</p>
                <p className="show-interest-product-modal__choose-address__option__sub-head">{address.house_no ? `${decodeString(address.house_no)}, ` : ''}
                    {address.locality ? `${decodeString(address.locality)}, ` : ''}
                    {address.landmark ? `${decodeString(address.landmark)}, ` : ''}
                    {address.city ? `${decodeString(address.city)}, ` : ''}
                    {address.state ? `${decodeString(address.state)}, ` : ''}
                    {address.country ? `${decodeString(address.country)}, ` : ''}
                    {address.pincode ? `${decodeString(address.pincode)}` : ''}
                </p>
            </div>
        </label>
    </li>)
}

function ShowInterestAddressCheckBox({ addresses,
    selectedId,
    handleSelect }) {
    const addressesItem = addresses
        && addresses.length
        ? addresses.map((address, index) => {
            const isChecked = selectedId
                && address.id === selectedId;
            return (<ShowInterestAddressCheckBoxItem index={index}
                key={`collab-address-${index}`}
                isChecked={isChecked}
                handleSelect={handleSelect}
                address={address} />)
        })
        : null;
    if (!addressesItem) return null;
    return (<ul className="add-address add-address--full-height show-interest-product-modal__choose-address">{addressesItem}</ul>)
};

ShowInterestAddressCheckBox.propTypes = {
    addresses: PropTypes.array,
    selectedId: PropTypes.number,
    handleSelect: PropTypes.func
}

export default ShowInterestAddressCheckBox;