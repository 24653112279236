import React from 'react';

function ImageAd(props) {
    const { adData: { image,
        link,
        title } } = props;
    return (<a className="feed-ad" href={link} target="_blank" rel="noopener noreferrer">
        <div className="feed-ad__overlay"></div>
        <img className="feed-ad__image" src={image.original_photo} alt={title} />
    </a>)
};

export default ImageAd;