import {
    SHOW_PHOTO_MODAL,
    RESET_PHOTO_MODAL
} from "../../actions/common/constants";

const INITIAL_STATE = {
    isVisible: false,
    isMultiple: false,
    photos: null,
    selectedPhoto: null
};

export default function photoModal(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SHOW_PHOTO_MODAL:
            return {
                isVisible: true,
                isMultiple: !!action.payload.isMultiple,
                photos: action.payload.photos,
                selectedPhoto: action.payload.selectedPhoto,
                selectedIndex: action.payload.selectedIndex,
                removePhoto: action.payload.removePhoto
            }
        case RESET_PHOTO_MODAL:
            return INITIAL_STATE
        default:
            return state;
    }
}