import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import PillFilters from "../../../components/filters/PillFilters";
import SeoHeadMeta from "../../../components/misc/SeoHeadMeta";
import { genericErrorText, seoTitles } from "../../../utils/commonVariables";
import { INVITATIONS_STATES, INVITATIONS_STATES_ARR } from "../../../utils/helper";
import EmptyState from "../../../../src/assets/images/rsvp/empty-state.svg"
import { offsetLimits } from "../../../utils/helper";
import InvitationListItem from "../../../components/invitations/InvitationListItem";
import InfinteScroll from "../../../components/misc/InfiniteScroll";
import Loader from "../../../components/misc/Loader";
import { INVITATIONS_KEYS, invitationGAData } from "../../../components/collaborations/prManage/schedule/constants";
import { handleInvitation } from "../../../components/collaborations/prManage/schedule/helper";
import { invitationsList, myInvitationsCancelToken } from "../../../actions/invitations/list";
import { invitationAction } from "../../../actions/invitations/update";
const MyInvitations = (props) => {
    const { token, user: {
        user: { profile }
    } } = props;
    const [activeFilter, setActiveFilter] = useState(INVITATIONS_STATES_ARR[0]);
    const [invitationList, setInvitationList] = useState([]);
    const [page, setPage] = useState(1);
    const [fetching, setFetching] = useState(false);
    const [hasEnd, setHasEnd] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const getParams = () => {
        return {
            page: page,
            status: activeFilter.state,
            limit: offsetLimits.invitation.myInvitation
        }
    }
    const getInvitationList = () => {
        if (fetching) return;
        setFetching(true);
        let params = { ...getParams() }
        invitationsList(token, params, profile.id).then(res => {
            if (!!res && !!res.data) {
                if (params.page === 1) {
                    setInvitationList([]);
                }
                setInvitationList(
                    page === 1
                        ? [...(res.data.invitations)]
                        : [...invitationList, ...(res.data.invitations && res.data.invitations)]
                );
                setPage(val => val + 1);
                setFetching(false);
                setHasEnd(!res.data.invitations.length);
            } else {
                setFetching(false);
                alert(genericErrorText.general);
            }
        })
    }

    const handleRemoveCard = (id) => {
        const newList = invitationList.filter(item => item.id !== id);
        setInvitationList([...newList]);
    }

    const handlePendingAction = (id, type) => {
        const data = {
            "accepted": type === "accept" ? 1 : 0
        }
        setDisabled(true);
        invitationAction(token, id, data, profile.id).then((res) => {
            if (!!res && !!res.data) {
                setInvitationList((prevData) => {
                    return prevData.map((val, index) => {
                        if (val.id !== id) return val;
                        return { ...val, ["status"]: res.data.status }
                    })
                });
                const data = invitationGAData(type === "accept" ? INVITATIONS_KEYS.ACCEPT : INVITATIONS_KEYS.REJECT, INVITATIONS_KEYS.CLICK, INVITATIONS_KEYS.MY_INVITTAION);
                handleInvitation(data);
                setTimeout(() => {
                    handleRemoveCard(id);
                    setDisabled(false)
                }, 300)
            } else {
                setDisabled(false)
            }
        })
    }

    const handleAcceptAcion = () => {
        const data = invitationGAData(INVITATIONS_KEYS.ADD_CALENDAR, INVITATIONS_KEYS.CLICK, INVITATIONS_KEYS.MY_INVITTAION);
        handleInvitation(data);
    }
    const handleUpdate = (e, id, type) => {
        if (!e) return;
        e.preventDefault();
        if (type === "addToCalendar") {
            handleAcceptAcion();
        } else {
            handlePendingAction(id, type);
        }
    }
    const handleFilter = (filter) => {
        setActiveFilter(filter);
        setPage(1);

    };
    useEffect(() => {
        getInvitationList();
        return function cleanup() {
            if (myInvitationsCancelToken) myInvitationsCancelToken();
        }
    }, [activeFilter])
    return (
        <>
            <SeoHeadMeta title={seoTitles.invitation.myInvitation}>
                <title>{seoTitles.invitation.myInvitation}</title>
            </SeoHeadMeta>
            <div className="container container--centered">
                <div className="see-all-container">
                    <div className="see-all-container__header">
                        <p className="see-all-container__title">My Invitations</p>
                        <p className="see-all-container__helper">
                            View and manage all your invites
                        </p>
                    </div>
                    <div>
                        <PillFilters
                            activeFilterState={activeFilter}
                            isInvitation={true}
                            filtersList={INVITATIONS_STATES_ARR}
                            handleClick={handleFilter}
                            disabled={disabled}
                        />
                        <div className="invitation-list">
                            {
                                invitationList && invitationList.length > 0 && invitationList.map((listData, index) => {
                                    return <InvitationListItem key={index} data={listData} modal={false} handleUpdate={handleUpdate} inactive={activeFilter.state === INVITATIONS_STATES.INACTIVE}
                                    />
                                })

                            }
                            {fetching && <Loader isCentered={true} withMargin={true} />}
                            {
                                !fetching && !invitationList.length &&
                                <div className="invitation-empty-view">
                                    <img src={EmptyState} height="auto" width="32%" />
                                    <p className="title">No {activeFilter.title} Invites</p>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>

            {!fetching && !hasEnd && page > 1 && (
                <InfinteScroll callbackFunc={getInvitationList} />
            )}
        </>
    );
};

MyInvitations.propTypes = {
    token: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
    return {
        token: state.login.token,
        user: state.user,
    };
}
export default connect(mapStateToProps)(MyInvitations);
