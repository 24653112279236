import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Slider from "react-slick";
import UserBar from "../common/UserBar";
import MetaBar from "../common/MetaBar";
import CompanyBar from "../common/CompanyBar";
import TaggedText from "../common/TaggedText";
import PostComments from "../common/PostComments";
import ChevronRight from "../../../../assets/images/photos/chevron-right.svg";
import { showPhotoModal } from "../../../../actions/common/modals";
import GIF from "../../../../assets/images/feed/blog/gif.svg";
import { GIF_TYPE } from "../../../../utils/helper";

class PhotoCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxHeightAnImageCanTake: window.innerHeight - 136, // subtracting post top and bottom bar
      isFullWidth: window.innerWidth < 820,
      maxContianerWidth: window.innerWidth < 820 ? window.innerWidth : 568,
    };
    this.getMaxHeight = this.getMaxHeight.bind(this);
  }

  _togglePhotoModal = (photos, photoIndex) => {
    const isMultiple = !!(photos.length > 1);
    this.props.showPhotoModal({
      photos,
      isMultiple,
      selectedPhoto:
        typeof photoIndex !== "undefined" ? photos[photoIndex] : photos[0],
      selectedIndex: typeof photoIndex !== "undefined" ? photoIndex : 0,
    });
  };

  //function to get max height of images
  getMaxHeight(isMultiplePhoto, photos, imageMeta) {
    let maxHeight = null;
    if ((!photos || (photos && !photos.length)) && !imageMeta) return maxHeight;
    // getting max height from a photo if multiple photos
    if (isMultiplePhoto) {
      photos.forEach((photo) => {
        if (photo && photo.meta && photo.meta.width && photo.meta.height) {
          let ratioOfImage = photo.meta.width / photo.meta.height,
            maxHeightOfCurrentImageByRatio =
              this.state.maxContianerWidth / ratioOfImage,
            currentPermissableHeight =
              photo.meta.height < maxHeightOfCurrentImageByRatio
                ? photo.meta.height
                : maxHeightOfCurrentImageByRatio;
          if (
            !maxHeight ||
            (maxHeight && currentPermissableHeight > maxHeight)
          ) {
            maxHeight = currentPermissableHeight;
          }
        }
      });
    } else {
      let metaJSON = JSON.parse(imageMeta),
        ratioOfImage = metaJSON.meta.width / metaJSON.meta.height,
        maxHeightOfCurrentImageByRatio =
          this.state.maxContianerWidth / ratioOfImage,
        currentPermissableHeight =
          metaJSON.meta.height < maxHeightOfCurrentImageByRatio
            ? metaJSON.meta.height
            : maxHeightOfCurrentImageByRatio;
      if (!maxHeight || (maxHeight && currentPermissableHeight > maxHeight)) {
        maxHeight = currentPermissableHeight;
      }
    }
    // checking if maxHeight is greater then allowed
    if (maxHeight > this.state.maxHeightAnImageCanTake) {
      maxHeight = this.state.maxHeightAnImageCanTake;
    }
    return maxHeight;
  }
  render() {
    const {
        cardData,
        isShared,
        hideMetaBar,
        showOptionsIcon,
        isDetailsPage,
        isAd,
        showFlagOptions,
        isPublic,
        publicClickAction,
        commentsData,
      } = this.props,
      { photo } = cardData,
      createdAt = photo && photo.created_at ? photo.created_at : false,
      photoText =
        photo && photo.caption
          ? photo.caption.text
            ? photo.caption.text
            : photo.caption
          : "",
      photoProfiles =
        photo && photo.caption && photo.caption.profiles
          ? photo.caption.profiles
          : [],
      photosArray = isPublic ? photo.image : photo.images,
      isMultiplePhotos = photosArray && photosArray.length,
      photoUrl =
        photo && photo.image_meta ? photo.image_meta.original_photo : null,
      postIdForIndex = isShared
        ? `shared-${cardData.shared.id}-${photo.id}`
        : `${photo.id}`,
      maxHeightOfImage = this.getMaxHeight(
        isMultiplePhotos,
        photo.images,
        photo.image_meta
      );
    return (
      <div className="feed-card">
        {cardData.profile ? (
          <UserBar
            showOptionsIcon={showOptionsIcon}
            postType="photo"
            postId={photo.id}
            hideMetaBar={hideMetaBar}
            cardData={cardData}
            createdAt={createdAt}
            showFlagOptions={showFlagOptions}
            isPublic={isPublic}
            publicClickAction={publicClickAction}
            isAd={isAd}
            profile={cardData.profile}
            isDetailsPage={isDetailsPage}
          />
        ) : cardData.company ? (
          <CompanyBar
            showOptionsIcon={showOptionsIcon}
            postType="photo"
            postId={photo.id}
            cardData={cardData}
            hideMetaBar={hideMetaBar}
            showFlagOptions={showFlagOptions}
            createdAt={createdAt}
            isPublic={isPublic}
            publicClickAction={publicClickAction}
            isAd={isAd}
            profileId={
              cardData.company && cardData.company.profile_id
                ? cardData.company.profile_id
                : null
            }
            company={cardData.company}
            isDetailsPage={isDetailsPage}
          />
        ) : null}
        {photo ? (
          <div className="photo-details">
            {photoText && (
              <div className="photo-details__caption">
                <TaggedText
                  isLinkified={true}
                  isOpen={!!isDetailsPage}
                  hashtagEventLabel="Photo"
                  className="post-details__text"
                  text={photoText}
                  profiles={photoProfiles}
                />
              </div>
            )}
            <div className="photo-details__container">
              {isMultiplePhotos && photosArray.length ? (
                <div
                  className="photo-details__container__slider"
                  style={{ height: maxHeightOfImage }}
                >
                  {
                    <Slider {...slickSettings}>
                      {photosArray.map((photo, photoIndex) => {
                        const isPhotoGif = photo.meta.mime == GIF_TYPE;
                        return (
                          <>
                            <div
                              onClick={() =>
                                this._togglePhotoModal(photosArray, photoIndex)
                              }
                              style={{ minHeight: maxHeightOfImage }}
                              className="photo-details__container__slider__slide"
                              key={`${postIdForIndex}-${photoIndex} container`}
                            >
                              <img
                                style={{ maxHeight: maxHeightOfImage }}
                                alt={`${photoText.substring(
                                  0,
                                  20
                                )} pic ${photoIndex}`}
                                src={photo.original_photo}
                              />
                            </div>
                            {isPhotoGif && (
                              <div className="gif_icon_feed">
                                <img src={GIF} alt="" />
                              </div>
                            )}
                          </>
                        );
                      })}
                    </Slider>
                  }
                </div>
              ) : photoUrl ? (
                <div
                  onClick={() => this._togglePhotoModal([photoUrl], 0)}
                  style={{ minHeight: maxHeightOfImage }}
                  className="photo-details__container__slider__slide"
                >
                  <img
                    style={{ maxHeight: maxHeightOfImage }}
                    alt={photoText}
                    src={photoUrl}
                    className="photo-details__container__image"
                  />
                </div>
              ) : null}
            </div>
          </div>
        ) : null}
        {!!!isShared && !hideMetaBar && cardData.meta && (
          <MetaBar
            postType="photo"
            isAd={isAd}
            isPublic={isPublic}
            publicClickAction={publicClickAction}
            postId={photo.id}
            likeActionProcessing={cardData.likeActionProcessing}
            userType={cardData.company ? "companies" : "profiles"}
            fetchingComments={commentsData && commentsData.fetching}
            userId={
              cardData.company ? cardData.company.id : cardData.profile.id
            }
            postToShare={cardData}
            meta={cardData.meta}
          />
        )}
        {commentsData && !!!isShared && !hideMetaBar && (
          <PostComments
            showOptionsIcon={showOptionsIcon}
            commentsData={commentsData}
            postType="photo"
            isAd={isAd}
            autoFocus={
              typeof this.props.autoFocusComments === "boolean"
                ? this.props.autoFocusComments
                : true
            }
            postId={photo.id}
          />
        )}
      </div>
    );
  }
}

// Arrow for slider
function SliderArrow(props) {
  const {
    onClick,
    to,
    iconClassName,
    currentSlide,
    slideCount,
    classNameToAdd,
  } = props;
  return (
    <button
      aria-hidden="true"
      aria-disabled={
        (to === "prev" && currentSlide === 0) ||
        (to === "next" && currentSlide === slideCount - 1)
      }
      type="button"
      onClick={onClick}
      className={`${classNameToAdd} ${
        (to === "prev" && currentSlide === 0) ||
        (to === "next" && currentSlide === slideCount - 1)
          ? "photo-details__container__slider__arrow--disabled"
          : ""
      }`}
    >
      <img
        className={`photo-details__container__slider__arrow__icon ${
          iconClassName || ""
        }`}
        src={ChevronRight}
        alt="go to next slide"
      />
    </button>
  );
}

// Slick settings
const slickSettings = {
  dots: true,
  dotsClass: "slick-dots slick-dots-custom",
  infinite: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  prevArrow: (
    <SliderArrow
      classNameToAdd="photo-details__container__slider__arrow photo-details__container__slider__arrow--left"
      iconClassName="photo-details__container__slider__arrow__icon--left"
      to="prev"
    />
  ),
  nextArrow: (
    <SliderArrow
      classNameToAdd="photo-details__container__slider__arrow photo-details__container__slider__arrow--right"
      to="next"
    />
  ),
};

PhotoCard.propTypes = {
  cardData: PropTypes.object.isRequired,
  isShared: PropTypes.bool,
  showOptionsIcon: PropTypes.bool,
  hideMetaBar: PropTypes.bool,
  autoFocusComments: PropTypes.bool,
  isDetailsPage: PropTypes.bool,
  isAd: PropTypes.bool,
  isPublic: PropTypes.bool,
  publicClickAction: PropTypes.func,
  showFlagOptions: PropTypes.bool,
};

export default connect(null, { showPhotoModal })(PhotoCard);
