import { GET_PUBLIC_VIEW_POST_DATA_REQUEST,
    GET_PUBLIC_VIEW_POST_DATA_SUCCESS,
    GET_PUBLIC_VIEW_POST_DATA_FAILURE,
    RESET_PUBLIC_VIEW_DATA } from './constants';
import { onRequest,
    onSuccess,
    getResponseData,
    onError } from '../../utils/helper';
import { getBaseUrl, publicViewLinks } from '../../utils/links';
import axios from 'axios';
const CancelToken = axios.CancelToken;

export let getPostDataCancelToken = undefined;
export const getPostData = (postType,
    postId,
    formattedPostType = null) => {
    return dispatch => {
        if (getPostDataCancelToken) getPostDataCancelToken();
        dispatch(onRequest(GET_PUBLIC_VIEW_POST_DATA_REQUEST));
        let url = `${getBaseUrl()}${publicViewLinks.post(postType, postId)}`,
            options = {
                url,
                cancelToken: new CancelToken((c) => getPostDataCancelToken = c)
            };
        return axios(options)
            .then(response => {
                let successData = getResponseData(response);
                if (successData) {
                    dispatch(onSuccess(GET_PUBLIC_VIEW_POST_DATA_SUCCESS, {
                        postType: formattedPostType || postType,
                        postData: successData
                    }));
                    return successData;
                } else {
                    dispatch(onError(GET_PUBLIC_VIEW_POST_DATA_FAILURE, response.data.errors))
                }
                return successData;
            })
            .catch(error => {
                dispatch(onError(GET_PUBLIC_VIEW_POST_DATA_FAILURE, error));
                return false;
            })
    }
}

export const resetPublicView = () => onSuccess(RESET_PUBLIC_VIEW_DATA);

export let getProfileIdByHandleCancelToken = undefined;
export const getProfileIdByHandle = (handle) => {
    return dispatch => {
        if (getProfileIdByHandleCancelToken) getProfileIdByHandleCancelToken();
        dispatch(onRequest(GET_PUBLIC_VIEW_POST_DATA_REQUEST));
        let url = `${getBaseUrl()}${publicViewLinks.profileHandle(handle)}`,
            options = {
                url,
                cancelToken: new CancelToken((c) => getProfileIdByHandleCancelToken = c)
            };
        return axios(options)
            .then(response => {
                let successData = getResponseData(response);
                if (successData) {
                    dispatch(onSuccess(GET_PUBLIC_VIEW_POST_DATA_SUCCESS, {
                        postType: 'profile',
                        postData: successData
                    }));
                } else {
                    dispatch(onError(GET_PUBLIC_VIEW_POST_DATA_FAILURE, response.data.errors));
                }
                return successData;
            })
            .catch(error => {
                dispatch(onError(GET_PUBLIC_VIEW_POST_DATA_FAILURE, error));
                if (!axios.isCancel(error)) {
                    return false;
                } else {
                    return {
                        cancelled: true
                    }
                }
            })
    }
}

export let getProfileDataCancelToken = undefined;
export function getProfileData(id) {
    return dispatch => {
        if(getProfileDataCancelToken) getProfileDataCancelToken();
        dispatch(onRequest(GET_PUBLIC_VIEW_POST_DATA_REQUEST));
        let url = `${getBaseUrl()}${publicViewLinks.profileDataById(id)}`,
            options = {
                method: 'GET',
                url,
                cancelToken: new CancelToken((c) => getProfileDataCancelToken = c)
            };
        return axios(options)
            .then(response => {
                let data = response.data && response.data.data ? response.data.data : false;
                if (data) {
                    dispatch(onSuccess(GET_PUBLIC_VIEW_POST_DATA_SUCCESS, {
                        postType: 'profile',
                        postData: data
                    }));
                } else {
                    dispatch(onError(GET_PUBLIC_VIEW_POST_DATA_FAILURE, response.data.errors));
                }
                return data;
            })
            .catch(err => {
                dispatch(onError(GET_PUBLIC_VIEW_POST_DATA_FAILURE, err));
                return false;
            });
    };
}
