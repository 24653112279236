import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import Joyride from "react-joyride";
import { Link } from "react-router-dom";
import { screenBreakpoints, seoTitles } from "../../../utils/commonVariables";
import {
  getLandingPageData,
  getLandingPageDataCancelToken,
  resetLandingPage,
} from "../../../actions/landingPage";
import { updateUser } from "../../../actions/profile/update";
import CreateBar from "../../../components/create/CreateBar";
import QuickLinks from "../../../components/bars/QuickLinks";
import LinkFooter from "../../../components/bars/LinkFooter";
import SeoHeadMeta from "../../../components/misc/SeoHeadMeta";
import UserInfo from "../Feed/userCards/UserInfo";
import CommonProfileCompletionCard from "../Feed/profileCompletion/CommonProfileCompletionCard";
import RightSidePanel from "../Feed/cards/RightSidePanel";
import BigBanner from "../../../components/landingPage/BigBanner";
import Passbook from "../../../components/landingPage/Passbook";
import ProductsAvailable from "../../../components/landingPage/ProductsAvailable";
import Banner from "../../../components/landingPage/Banner";
import TrendingHashtagsMobile from "../../../components/landingPage/TrendingHashtagsMobile";
import FromFeed from "../../../components/landingPage/FromFeed";
import ImageCarousel from "../../../components/landingPage/ImageCarousel";
import CarouselComponent from "../../../components/landingPage/CarouselComponent";
import SuggestedPost from "../../../components/landingPage/SuggestedPost";
import PostCommonModals from "../../../components/modals/common/PostCommonModals";
import { getProductTourSteps, getMobileProductTourSteps } from "./constant";
import { feedLink } from "../../../utils/internalLinks";
import RightArrow from "../../../assets/images/products/right-arrow.svg";
import FeedPlaceholder from "../../../components/placeholders/FeedPlaceholder";
import RetryFetch from "../../../components/misc/RetryFetch";
import { PROMPT_TYPE } from "../../../components/modals/profilePrompts/constants";
import InvitationList from "../../../components/feed/InvitationList";

const LandingPage = (props) => {
  const {
    token,
    history,
    user,
    updateUser,
    getLandingPageData,
    landingPageData,
    resetLandingPage,
  } = props;
  const [isMobile, setIsMobile] = useState(
    window.innerWidth <= screenBreakpoints.mob
  );
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [showLeft, setShowLeft] = useState(
    window.innerWidth >= screenBreakpoints.mob
  );
  const [showRight, setShowRight] = useState(
    window.innerWidth >= screenBreakpoints.tab
  );
  const [productTourUsers] = useState(
    window.localStorage.getItem("productTourUsers")
      ? JSON.parse(window.localStorage.getItem("productTourUsers"))
      : []
  );
  const [run, setRun] = useState(false);
  const [productTourSteps, setProductTourSteps] = useState([]);

  const userId = get(user, "user.profile.id", "");

  useEffect(() => {
    resetLandingPage();
    window.scrollTo(0, 0);
    window.addEventListener("resize", handleResize);
    getLandingPageData(token, isMobile ? "mobile" : "web").then((_) => {
      if (_) {
        const GAData = {
          screenName: "Home - Landing Page",
          modelName: "feed",
          promptType: PROMPT_TYPE.SESSION
        }
        if (props.handlePrompt) {
          props.handlePrompt({ GAData });
        }
      }
    });

    return () => {
      window.removeEventListener("resize", handleResize);
      if (getLandingPageDataCancelToken) getLandingPageDataCancelToken();
    };
  }, []);

  useEffect(() => {
    if (productTourSteps.length > 0 && !isEmpty(landingPageData.data)) {
      const userIndex = productTourUsers.findIndex(
        (obj) => obj.userId === userId
      );
      if (productTourUsers.length === 0 || userIndex === -1) {
        setRun(true);
      }
    }

  }, [productTourSteps, landingPageData.data]);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= screenBreakpoints.mob);
    setShowLeft(window.innerWidth >= screenBreakpoints.mob);
    setShowRight(window.innerWidth >= screenBreakpoints.tab);
  };

  const toggleCreateBar = (type) => {
    setIsCreateOpen(typeof type !== "undefined" ? type : !isCreateOpen);
  };

  const handleUserUpdate = (profileId, dataToSend, callBack) => {
    updateUser(
      profileId,
      {
        _method: "PATCH",
        ...dataToSend,
      },
      token
    ).then((_) => {
      if (_ && callBack) {
        callBack();
      }
    });
  };

  const getComponent = (obj) => {
    const uiType = get(obj, "ui_type");
    switch (uiType) {
      case "quick_links":
        return (
          <QuickLinks isMobile={isMobile} mobileData={obj} token={token} />
        );
      case "passbook":
        return <Passbook profile={get(user, "user.profile")} {...props} />;
      case "product_available":
        return <ProductsAvailable data={obj} {...props} />;
      case "banner":
        return <Banner data={obj} {...props} />;
      case "hashtag":
        return (
          <TrendingHashtagsMobile
            maxCount={5}
            eventLabel="Feed"
            data={obj}
            {...props}
          />
        );
      case "big_banner":
        return <BigBanner data={obj} history={history} isMobile={isMobile} />;
      case "feed":
        return <FromFeed data={obj} {...props} />;
      case "image_carousel":
        return <ImageCarousel isMobile={isMobile} data={obj} {...props} />;
      case "carousel":
        return <CarouselComponent isMobile={isMobile} data={obj} {...props} />;
      case "suggestion":
        return <SuggestedPost data={obj} {...props} />;
      default:
        return null;
    }
  };

  const handleTourClose = () => {
    const newProductTourUsers = [...productTourUsers];
    const userObject = { userId: userId };
    newProductTourUsers.push(userObject);
    window.localStorage.setItem(
      "productTourUsers",
      JSON.stringify(newProductTourUsers)
    );
  };

  const getHelpers = (helpers) => {
    if (isMobile) {
      setProductTourSteps(
        getMobileProductTourSteps(user, helpers, handleTourClose)
      );
    } else {
      setProductTourSteps(getProductTourSteps(user, helpers, handleTourClose));
    }
  };
  window.onbeforeunload = null;
  return (
    <>
      <SeoHeadMeta title={seoTitles.loggedIn.home}>
        <title>{seoTitles.loggedIn.home}</title>
      </SeoHeadMeta>
      {userId && (
        <Joyride
          steps={productTourSteps}
          run={run}
          continuous
          scrollToFirstStep
          disableCloseOnEsc
          disableOverlayClose
          getHelpers={getHelpers}
          scrollOffset={isMobile ? 80 : 100}
          styles={{
            options: {
              zIndex: 10000,
            },
          }}
        />
      )}
      <div className="container feed-container">
        {showLeft && (
          <div className="feed-container__left">
            {get(user, "user.profile") && <UserInfo user={user.user} />}
            {get(user, "user.profile") && (
              <QuickLinks isMobile={isMobile} token={token} />
            )}
            <LinkFooter />
          </div>
        )}
        <div className="feed-container__center">
          <CreateBar
            token={token}
            handleOpen={toggleCreateBar}
            user={user}
            isMobile={isMobile}
          />

          <ul id="feed-list" className="feed-list">
            {landingPageData.fetching ? (
              <div className="home__loader">
                {/* these are just placeholder components 
                to achieve shimmer effect hence adding multi components next to each other */}
                <FeedPlaceholder />
                <FeedPlaceholder />
                <FeedPlaceholder />
              </div>
            ) : (
              <>
                {landingPageData.data.map((obj, i) => {
                  return (
                    <React.Fragment key={i}>{getComponent(obj)}</React.Fragment>
                  );
                })}
                {landingPageData.error && (
                  <RetryFetch
                    handleClick={() => getLandingPageData(token, isMobile ? "mobile" : "web")}
                  />
                )}

                <div className="landingmobile__footer">
                  {!landingPageData.error ? <Link to={feedLink} className="landingmobile__footer__button">
                    <span>Go to feed</span>
                    <img
                      src={RightArrow}
                      className="landingmobile__footer__button__icon"
                      alt="go to feed"
                    />
                  </Link> : null}
                  {isMobile && (
                    <p className="landingmobile__footer__copy">
                      © TagTaste Foods Pvt. Ltd. | {new Date().getFullYear()}
                    </p>
                  )}
                </div>
              </>
            )}
          </ul>
        </div>
        {showRight && (
          <div className="feed-container__right">
            <InvitationList  {...props} isFeed={true} profile={user.user.profile} />
            {get(user, "user.profile") && (
              <CommonProfileCompletionCard
                handleUserUpdate={handleUserUpdate}
                profile={user.user.profile}
              />
            )}
            <RightSidePanel profile={user.user.profile} {...props} />
          </div>
        )}
      </div>
      <PostCommonModals />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.login.token,
    comments: state.feedReducer.comments,
    user: state.user,
    landingPageData: state.landingPageReducer.landingPageData,
  };
};

const mapDispatchToProps = {
  getLandingPageData,
  getLandingPageDataCancelToken,
  resetLandingPage,
  updateUser,
};

LandingPage.propTypes = {
  user: PropTypes.object,
  landingPageData: PropTypes.object,
  history: PropTypes.object,
  token: PropTypes.string,
  updateUser: PropTypes.func,
  getLandingPageData: PropTypes.func,
  resetLandingPage: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);
