import React from 'react';
import PropTypes from 'prop-types';
import { decodeString } from '../../utils/helper';
import VerifiedLogo from '../misc/VerifiedLogo';

function UserName({ name, isVerified, styles, addClassToText, textStyle, width, height, isDisplay = false }) {
  const nameToShow = decodeString(name);
  const verifiedPropsToUser = {
    style: {
      marginLeft: '8px',
      flexShrink: 0,
      verticalAlign: 'text-bottom',
      ...(styles && styles),
    },
    width: width || 16,
    height: height || 16,
  };

  return (
    <span>
      <span
        className={addClassToText ? "verified__text" : ""}
        style={{ ...(textStyle && textStyle) }}
      >
        {nameToShow}
      </span>
      <span>
      {!!isVerified && <VerifiedLogo {...verifiedPropsToUser} />}
      </span>
    </span>
  );
}

UserName.propTypes = {
  name: PropTypes.string,
  isVerified: PropTypes.bool,
  styles: PropTypes.object,
  width: PropTypes.number,
  height: PropTypes.number,
  addClassToText: PropTypes.bool,
  textStyle: PropTypes.object,
};

export default UserName;
