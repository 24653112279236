import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Modal from '../../modals/common/Modal';
import { hideProfileModal } from '../../../actions/common/modals';
import {
  getTesterProgramRequirement,
  getTesterProgramRequirementCancelToken,
  postEnrollPaidTester,
  postEnrollPaidTesterCancelToken,
} from '../../../actions/profile/passbook/enrollUser';
import Loader from '../../../components/misc/Loader';

const MainModal = ({ data, handleEnrollButton, formSubmitted }) => {
  return (
    <div className="biz__modal__triodata">
      <div className="passbook__modal__row1">
        <img
          className="passbook__modal__row1__icon"
          src={get(data, 'pop_up.icon')}
          alt="modal icon"
        />
        <p className="passbook__modal__row1__title">{get(data, 'pop_up.title')}</p>
        <p className="passbook__modal__row1__subtitle">{get(data, 'pop_up.sub_title')}</p>
        <hr className="passbook__modal__row1__hr" />
      </div>

      <div className="passbook__modal__row2">
        <p className="passbook__modal__row2__datetype">{get(data, 'title')}</p>
        <p className="passbook__modal__row2__title">{get(data, 'sub_title')}</p>
      </div>

      <div className="passbook__enrolusermodal__row3">
        {get(data, 'headers', []).map((header, index) => (
          <div key={index} className="passbook__enrolusermodal__row3__block">
            <p className="passbook__enrolusermodal__row3__title">{get(header, 'title')}</p>
            <ul className="passbook__enrolusermodal__row3__header">
              {get(header, 'child', []).map((obj, i) => (
                <li key={i}>
                  <span className="passbook__enrolusermodal__row3__header__text">
                    {get(obj, 'title')}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      <div className="passbook__enrolusermodal__row4">
        <button
          className="cursor-pointer passbook__enrolusermodal__row4__button"
          onClick={handleEnrollButton}
          disabled={formSubmitted}
        >
          Enroll for paid taster program
        </button>
      </div>
    </div>
  );
};

const NonPaidUserOnboardModal = (props) => {
  const { token, hideProfileModal } = props;

  const [isDataFetched, setIsDataFetched] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [data, setData] = useState({});
  const MainModalComponent = React.memo(MainModal);
  useEffect(() => {
    fetchRequirement();

    return () => {
      if (getTesterProgramRequirementCancelToken) getTesterProgramRequirementCancelToken();
      if (postEnrollPaidTesterCancelToken) postEnrollPaidTesterCancelToken();
    };
  }, []);

  const fetchRequirement = () => {
    getTesterProgramRequirement(token).then((response) => {
      if (response.status) {
        const data = get(response, 'data.data', []);
        setData(data);
      } else {
        setData({});
      }
      setIsDataFetched(true);
    });
  };

  const handleEnrollButton = () => {
    setFormSubmitted(true);
    postEnrollPaidTester(token).then((response) => {
      if (response.status) {
        const enrolledMessage = get(response, 'data.data.sub_title', '');
        hideProfileModal();
        alert(enrolledMessage);
      } else {
        alert('Some error occured');
      }
    });
  };

  return (
    <Modal showHeader className="passbook__enrolusermodal__width" handleClose={hideProfileModal}>
      {!isDataFetched ? (
        <Loader isCentered={true} withMargin={true} />
      ) : (
        <MainModalComponent
          data={data}
          handleEnrollButton={handleEnrollButton}
          formSubmitted={formSubmitted}
        />
      )}
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.login.token,
  };
};

const mapDispatchToProps = {
  hideProfileModal,
};

MainModal.propTypes = {
  data: PropTypes.object,
  handleEnrollButton: PropTypes.func,
  formSubmitted: PropTypes.func,
};
NonPaidUserOnboardModal.propTypes = {
  token: PropTypes.string,
  hideProfileModal: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(NonPaidUserOnboardModal);
