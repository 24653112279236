import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SmallPreview from '../../containers/loggedIn/Feed/common/SmallPreview';
import { resetPreviews } from '../../actions/feed/common';
import CloseIcon from '../../assets/images/common/modals/close.svg';
import AsyncSelect from 'react-select/async';
import { getProfilesForTagging } from '../../actions/tagging';
import { sendDirectMessage, sendDirectMessageCancelToken } from '../../actions/messages/message';
import Avatar from '../user/Avatar';
import { chatLimits } from '../../utils/commonVariables';
import UserName from '../profile/UserName';
import { decodeString } from '../../utils/helper';

const texts = {
  messageSend: 'Message sent successfully',
  messageError: 'Something went wrong. Please try again.',
};
const Option = (props) => {
  const {
    innerProps,
    isFocused,
    data: { label, imageMeta, profile },
  } = props;
  return (
    <div
      className={`tag-box__suggestions__item ${
        isFocused ? 'tag-box__suggestions__item--focused' : ''
      }`}
      {...innerProps}
    >
      <Avatar
        src={imageMeta ? JSON.parse(imageMeta).original_photo : ''}
        alt={label}
        className="tag-box__suggestions__item__icon"
        profile={profile}
        smallSuperTaster
      />
      <div>
        <UserName name={label} isVerified={profile && !!profile.verified} />
      </div>
    </div>
  );
};

function SendMessage(props) {
  const [preview] = useState(props.preview),
    [token] = useState(props.token),
    [message, setMessage] = useState(''),
    [sendingMessage, setSendingMessage] = useState(false),
    [selectedProfiles, setSelectedProfiles] = useState([]),
    [messageSend, setMessageSend] = useState(false),
    [errorSending, setErrorSending] = useState(false),
    { resetPreviews } = props;

  function closeModal() {
    resetPreviews();
  }

  function handleSubmit() {
    if (sendingMessage || !selectedProfiles || (selectedProfiles && !selectedProfiles.length))
      return;
    setErrorSending(false);
    const { sendDirectMessage } = props;
    let messageToSend = `${message} ${preview.deeplink}`;
    messageToSend = messageToSend.trim();
    let dataToSend = {
      message: messageToSend,
      preview,
      profileId: selectedProfiles.map((_) => _.value),
    };
    setSendingMessage(true);
    sendDirectMessage(dataToSend, token).then((_) => {
      setSendingMessage(false);
      if (_) {
        setMessageSend(true);
        setTimeout(resetPreviews, 2000);
      } else {
        setErrorSending(true);
      }
    });
  }

  useEffect(() => {
    return function cleanup() {
      if (sendDirectMessageCancelToken) sendDirectMessageCancelToken();
      resetPreviews();
    };
  }, []);

  function loadProfile(inputValue) {
    if (!inputValue || (inputValue && !inputValue.trim().length)) return;
    const { tagging } = props;
    const { profilesByTerm } = tagging;
    let taggingObj = profilesByTerm[inputValue];

    if (
      taggingObj &&
      taggingObj.fetchTime &&
      (Date.parse(new Date()) - taggingObj.fetchTime) / (1000 * 60) <= 5
    ) {
      return Promise.resolve().then(() =>
        taggingObj.data.map((user) => ({
          value: user.id,
          label: decodeString(user.name),
          profile: user,
          imageMeta: user.image_meta,
        })),
      );
    }
    const { getProfilesForTagging } = props;
    return getProfilesForTagging(inputValue, token).then((_) => {
      if (!_) return [];
      return _.map((user) => ({
        value: user.id,
        label: decodeString(user.name),
        imageMeta: user.image_meta,
        profile: user,
      }));
    });
  }

  return (
    <div className="default-modal send-message-modal">
      <div className="default-modal__backdrop">
        <div className="default-modal__container">
          <div className="default-modal__header">
            <p className="default-modal__header__title">Send Message</p>
            <button
              onClick={() => {
                if (sendingMessage) return;
                closeModal();
              }}
              className="default-modal__header__close"
            >
              <img src={CloseIcon} alt="close modal" />
            </button>
          </div>
          <div className="default-modal__content">
            {messageSend ? (
              <p className="share-post-modal__content__helper">{texts.messageSend}</p>
            ) : (
              <>
                <div className="send-message-modal__profile-select__container">
                  <p className="send-message-modal__profile-select__helper">To</p>
                  <div className="send-message-modal__profile-select__select__container">
                    <AsyncSelect
                      components={{ Option }}
                      cacheOptions
                      isMulti
                      placeholder="Enter Names..."
                      classNamePrefix="tag-box-select"
                      noOptionsMessage={() => null}
                      value={selectedProfiles}
                      onChange={(selectedProfiles) => setSelectedProfiles(selectedProfiles)}
                      loadOptions={loadProfile}
                    />
                  </div>
                </div>
                <div className="send-message-modal__message__container">
                  <textarea
                    autoFocus={true}
                    maxLength={chatLimits.chatMaxLength}
                    placeholder="Write something along..."
                    value={message}
                    className="send-message-modal__message__textarea"
                    onChange={(e) => setMessage(e.target.value)}
                  />
                  <div className="send-message-modal__preview__container">
                    <p className="send-message-modal__preview__message">{preview.deeplink}</p>
                    <SmallPreview preview={preview} />
                  </div>
                  {errorSending && <p className="error-text">{texts.messageError}</p>}
                </div>
              </>
            )}
          </div>
          {!messageSend && (
            <div className="default-modal__footer">
              <div></div>
              {/* <button type="button"
                            className={`default-modal__footer__clear-btn
                                ${sendingMessage ? 'default-modal__footer__btn--disabled' : ''}`}
                            onClick={closeModal}
                            disabled={sendingMessage}>Cancel</button> */}
              <button
                className={`default-modal__footer__action-btn
                            ${
                              sendingMessage ||
                              !selectedProfiles ||
                              (selectedProfiles && !selectedProfiles.length)
                                ? 'default-modal__footer__btn--disabled'
                                : ''
                            }`}
                disabled={
                  sendingMessage ||
                  !selectedProfiles ||
                  (selectedProfiles && !selectedProfiles.length)
                }
                onClick={handleSubmit}
              >
                Send Message
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

Option.propTypes = {
  innerProps: PropTypes.object,
  isFocused: PropTypes.object,
  data: PropTypes.object,
};
SendMessage.propTypes = {
  preview: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired,
  resetPreviews: PropTypes.func,
  sendDirectMessage: PropTypes.func,
  tagging: PropTypes.object,
  getProfilesForTagging: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    token: state.login.token,
    tagging: state.tagging,
    user: state.user,
  };
}

const mapDispatchToProps = {
  getProfilesForTagging,
  resetPreviews,
  sendDirectMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(SendMessage);
