import Qs from 'qs';
import Axios from "axios";
import { invitationsLinks } from '../../utils/links';
import { getBaseUrl } from '../../utils/links';
import { getResponseData } from '../../utils/helper';

const CancelToken = Axios.CancelToken;

export let myInvitationsCancelToken = undefined;
export const invitationsList = (token, params, profileId) => {
    if (myInvitationsCancelToken) myInvitationsCancelToken();
    let url = `${getBaseUrl()}${invitationsLinks.invitationsList(profileId)}`,
        options = {
            url,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params,
            paramsSerializer: function (params) {
                return Qs.stringify(params, {
                    arrayFormat: 'brackets',
                    encodeValuesOnly: true
                })
            },
            cancelToken: new CancelToken((c) => (myInvitationsCancelToken = c)),
        };
    return Axios(options)
        .then((response) => {
            return response.data;
        })
        .catch(() => false);
};


// get invitation collaboration
export let collabInvitationsCancelToken = undefined;
export const collabInvitationsList = (token, collabId, profileId) => {
    if (collabInvitationsCancelToken) collabInvitationsCancelToken();
    let url = `${getBaseUrl()}${invitationsLinks.collabInvitations(collabId, profileId)}`,
        options = {
            url,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            cancelToken: new CancelToken((c) => (collabInvitationsCancelToken = c)),
        };
    return Axios(options)
        .then((response) => {
            return response.data;
        })
        .catch(() => false);
};


// get invitation collaboration
export let invitationDetailsByIdCancelToken = undefined;
export const invitationDetailsById = (token, invitationId, profileId) => {
    if (invitationDetailsByIdCancelToken) invitationDetailsByIdCancelToken();
    let url = `${getBaseUrl()}${invitationsLinks.invitationUpdate(invitationId, profileId)}`,
        options = {
            url,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            cancelToken: new CancelToken((c) => (invitationDetailsByIdCancelToken = c)),
        };
    return Axios(options)
        .then((response) => {
            return response.data;
        })
        .catch(() => false);
};