import { GET_EXPLORE_DATA_REQUEST,
    GET_EXPLORE_DATA_SUCCESS,
    RESET_EXPLORE_DATA,
    GET_EXPLORE_DATA_FAILURE,
    UPDATE_EXPLORE_TEXT } from "../../actions/explore/constants";
import { FOLLOW_USER_SUCCESS } from "../../actions/profile/constants";
import { FOLLOW_COMPANY_SUCCESS } from "../../actions/company/constants";

const INITIAL_STATE = {
    fetching: false,
    data: null,
    error: false,
    hasFetched: false,
    text: ''
}

export default function explore(state = INITIAL_STATE, action) {
    switch(action.type) {
        case GET_EXPLORE_DATA_REQUEST:
            return {
                ...state,
                fetching: true
            }
        case GET_EXPLORE_DATA_SUCCESS:
            return {
                ...state,
                fetching: false,
                hasFetched: true,
                data: action.payload
            }
        case GET_EXPLORE_DATA_FAILURE:
            return {
                ...state,
                fetching: false,
                ...(!action.error.__CANCEL__ && {
                    error: true,
                    errorMsg: action.error
                }),
            }
        case UPDATE_EXPLORE_TEXT:
            return {
                ...state,
                text: action.payload
            }
        case RESET_EXPLORE_DATA:
            return INITIAL_STATE;
        case FOLLOW_COMPANY_SUCCESS:
            return {
                ...state,
                ...(state.hasFetched && {
                    data: state.data.map(explore => {
                        if (explore.ui_type === 7
                            && explore.sub_type === 'company') {
                            return {
                                ...explore,
                                elements: {
                                    ...explore.elements,
                                    company: explore.elements.company.map(_ => {
                                        if (_.id === action.payload.companyId) {
                                            return {
                                                ..._,
                                                isFollowing: true
                                            }
                                        }
                                        return _;
                                    })
                                }
                            }
                        }
                        return explore;
                    })
                })
            }
        case FOLLOW_USER_SUCCESS:
            return {
                ...state,
                ...(state.hasFetched && {
                    data: state.data.map(explore => {
                        if (explore.ui_type === 4
                            && explore.sub_type === 'profile') {
                            return {
                                ...explore,
                                elements: {
                                    ...explore.elements,
                                    profile: explore.elements.profile.map(_ => {
                                        if (_.id === action.payload.profileId) {
                                            return {
                                                ..._,
                                                isFollowing: true
                                            }
                                        }
                                        return _;
                                    })
                                }
                            }
                        }
                        return explore;
                    })
                })
            }
        default:
            return state;
    }
}