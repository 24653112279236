import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  readMoreText,
  decodeString,
  updatingStringToHashTag,
} from "../../../../utils/helper";
import linkifyHtml from "linkifyjs/html";
import { readMoreLength } from "../../../../utils/commonVariables";
import { Link } from "react-router-dom";
import Interweave from "interweave";
import { withRouter } from "react-router-dom";
import { gaEvents } from "../../../../utils/events";

const linkifyOptions = {
  className: "post-details__link",
  target: {
    url: "_blank",
  },
  attributes: {
    ref: "nofollow",
  },
  nl2br: true,
};

const formatText = (text, tagged, readMoreLenToSend) => {
  return tagged
    ? readMoreText(text, readMoreLenToSend)
    : readMoreText(text, readMoreLenToSend);
};
function ReadMoreLinkified(props) {
  const {
      text,
      hashtagEventLabel,
      className,
      disabled,
      tagged,
      linksAsDiv,
      history,
      readMoreLen,
      isPublic,
      publicClickAction,
    } = props,
    readMoreLenToSend = readMoreLen || readMoreLength;
  const [isOpen, setIsOpen] = useState(props.isOpen || false);
  const string = isOpen
    ? tagged
      ? updatingStringToHashTag(decodeString(linkifyHtml(text, linkifyOptions)))
      : updatingStringToHashTag(decodeString(linkifyHtml(text, linkifyOptions)))
    : updatingStringToHashTag(
        decodeString(
          formatText(
            linkifyHtml(text, linkifyOptions),
            tagged,
            readMoreLenToSend
          )
        )
      );
  return (
    <p
      className={className}
      onClick={(e) => {
        if (disabled) return;
        if (isPublic) {
          if (publicClickAction && typeof publicClickAction === "function") {
            publicClickAction();
          }
          return;
        }
        setIsOpen(true);
      }}
    >
      <Interweave
        transform={(node, child) => {
          if (node.tagName === "A" && node.getAttribute("data-tagged")) {
            return linksAsDiv ? (
              <span
                className="post-details__link--tagged post-details__link--inline-block"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  history.push(node.getAttribute("href"));
                }}
              >
                {child}
              </span>
            ) : (
              <Link
                className="post-details__link--tagged post-details__link--inline-block"
                to={node.getAttribute("href")}
              >
                {child}
              </Link>
            );
          }
          if (node.tagName === "A" && node.getAttribute("data-hashtag")) {
            return linksAsDiv ? (
              <span
                className="post-details__link"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  gaEvents.hashtags.click(hashtagEventLabel);
                  history.push(node.getAttribute("href"));
                }}
              >
                {child}
              </span>
            ) : (
              <Link
                className="post-details__link"
                onClick={() => gaEvents.hashtags.click(hashtagEventLabel)}
                to={node.getAttribute("href")}
              >
                {child}
              </Link>
            );
          }
        }}
        content={string}
      />
    </p>
  );
}

ReadMoreLinkified.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  isOpen: PropTypes.bool,
  tagged: PropTypes.bool,
  readMoreLen: PropTypes.number,
  isPublic: PropTypes.bool,
  publicClickAction: PropTypes.func,
  linksAsDiv: PropTypes.bool,
  hashtagEventLabel: PropTypes.string,
};

export default withRouter(ReadMoreLinkified);
