import { GET_COMPANY_TYPES_REQUEST,
    GET_COMPANY_TYPES_SUCCESS,
    GET_COMPANY_TYPES_FAILURE,
    GET_COMPANY_STATUS_REQUEST,
    GET_COMPANY_STATUS_SUCCESS,
    GET_COMPANY_STATUS_FAILURE,
    UPDATE_COMPANY_INFO_REQUEST,
    UPDATE_COMPANY_INFO_SUCCESS,
    UPDATE_COMPANY_INFO_FAILURE,
    UPDATE_COMPANY_PROFILE_REQUEST,
    UPDATE_COMPANY_PROFILE_SUCCESS,
    UPDATE_COMPANY_PROFILE_FAILURE, } from '../../actions/company/constants';

const INITIAL_STATE = {
    updating: false,
    error: false,
    errorMsg: null,
    types: {
        fetching: false,
        error: null,
        all: []
    },
    statuses: {
        fetching: false,
        error: null,
        all: []
    }
}
export default function update(state = INITIAL_STATE, action) {
    switch(action.type) {
        case UPDATE_COMPANY_PROFILE_REQUEST:
        case UPDATE_COMPANY_INFO_REQUEST:
            return {
                ...INITIAL_STATE,
                updating: true
            }
        case UPDATE_COMPANY_PROFILE_SUCCESS:
        case UPDATE_COMPANY_INFO_SUCCESS:
            return {
                ...state,
                updating: false
            }
        case UPDATE_COMPANY_PROFILE_FAILURE:
        case UPDATE_COMPANY_INFO_FAILURE:
            return {
                ...state,
                updating: false,
                error: true,
                errorMsg: action.error
            }
        case GET_COMPANY_TYPES_REQUEST:
            return {
                ...state,
                types: {
                    ...INITIAL_STATE.types,
                    fetching: true
                }
            }
        case GET_COMPANY_TYPES_SUCCESS:
            return {
                ...state,
                types: {
                    ...state.types,
                    fetching: false,
                    all: Object.keys(action.payload).map(_ => ({
                        id: _,
                        value: action.payload[_]
                    }))
                }
            }
        case GET_COMPANY_TYPES_FAILURE:
            return {
                ...state,
                types: {
                    ...state.types,
                    fetching: false,
                    error: true,
                    errorMsg: action.error
                }
            }
        case GET_COMPANY_STATUS_REQUEST:
            return {
                ...state,
                statuses: {
                    ...INITIAL_STATE.statuses,
                    fetching: true
                }
            }
        case GET_COMPANY_STATUS_SUCCESS:
            return {
                ...state,
                statuses: {
                    ...state.statuses,
                    fetching: false,
                    all: Object.keys(action.payload).map(_ => ({
                        id: _,
                        value: action.payload[_]
                    }))
                }
            }
        case GET_COMPANY_STATUS_FAILURE:
            return {
                ...state,
                statuses: {
                    ...state.statuses,
                    fetching: false,
                    error: true,
                    errorMsg: action.error
                }
            }
        default:
            return state;
    }
}