import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import { ProfileUrls } from '../../utils/links';
import Avatar from '../user/Avatar';
import { formatDate } from '../../utils/helper';
import TrashIcon from '../../assets/images/feed/options/trash.svg';
import TaggedText from '../../containers/loggedIn/Feed/common/TaggedText';
import UserName from '../profile/UserName';
import ReplyIcon from '../../assets/images/feed/reply.svg';

function CommentsListItem(props) {
  const [comment, setComment] = useState(props.comment),
    [showOptionsIcon] = useState(props.showOptionsIcon),
    [profileId] = useState(props.profileId),
    [showCommentsOption, setShowCommentsOption] = useState(false),
    { handleDelete, handleReply } = props;
  const is_tasting_expert = get(props, 'comment.is_tasting_expert');

  useEffect(() => {
    setComment(props.comment);
  }, [props.comment]);

  useEffect(() => {
    if (!showCommentsOption) {
      document.removeEventListener('click', handleCommentsPopUp);
    } else {
      document.addEventListener('click', handleCommentsPopUp);
    }
    return function cleanup() {
      document.removeEventListener('click', handleCommentsPopUp);
    };
  }, [showCommentsOption]);

  function handleCommentsPopUp() {
    setShowCommentsOption(!showCommentsOption);
  }

  const commentText =
      comment.content && comment.content.text ? comment.content.text : comment.content,
    commentTextProfiles =
      comment.content && comment.content.profiles ? comment.content.profiles : [],
    commenterProfileId = comment.profile_id,
    commenterHandle = comment.handle,
    commentId = comment.id,
    commenterProfileImage = comment.profile_image_meta
      ? JSON.parse(comment.profile_image_meta).original_photo
      : comment.profileImage,
    commenterName = comment.name,
    commentCreatedAt = comment.created_at,
    profileLink = ProfileUrls.userProfileLink(commenterHandle, commenterProfileId);

  return (
    <li className="post-comments-list__item">
      <Link className="post-comments-list__item__profile-image-container" to={profileLink}>
        <Avatar
          className="post-comments-list__item__profile-image-container__image"
          src={commenterProfileImage}
          alt={commenterName}
          is_tasting_expert={is_tasting_expert}
        />
      </Link>
      <div className="post-comments-list__details">
        <div className="post-comments-list__details__header__container">
          <Link className="post-comments-list__details__header" to={profileLink}>
            <p
              className={`post-comments-list__details__header__text
                        ${
                          !!comment.verified &&
                          'post-comments-list__details__header__text--verified'
                        }`}
            >
              <UserName isVerified={!!comment.verified} name={commenterName} />
            </p>
            {commentCreatedAt && (
              <p className="post-comments-list__details__header__helper">
                <span className="post-comments-list__details__header__helper__divider">&bull;</span>
                <span className="post-comments-list__details__header__helper__text">
                  {formatDate.post(commentCreatedAt)}
                </span>
              </p>
            )}
          </Link>
          {
            // isOwner or isAdmin or isCommentor
            (showOptionsIcon || profileId === commenterProfileId) && (
              <div className="post-comments-list__action">
                <button
                  onClick={handleCommentsPopUp}
                  className="post-comments-list__details__header__action-btn"
                >
                  <span className="post-comments-list__details__header__action-btn__text">•••</span>
                </button>
                {showCommentsOption && (
                  <ul className="post-comments-list__action__list post-comments-list__action__list--left">
                    <li
                      onClick={() => handleDelete(commentId)}
                      className="post-comments-list__action__list__item"
                    >
                      <img
                        className="post-comments-list__action__list__item__icon"
                        src={TrashIcon}
                        alt="remove comment"
                      />
                      <span className="post-comments-list__action__list__item__text post-comments-list__action__list__item__text--red">
                        Remove comment
                      </span>
                    </li>
                  </ul>
                )}
              </div>
            )
          }
        </div>
        <TaggedText
          isLinkified={true}
          hashtagEventLabel="Comments"
          className="post-comments-list__details__content"
          profiles={commentTextProfiles}
          text={commentText}
        />
        {handleReply && (
          <button className="post-comments-list__reply" onClick={() => handleReply(comment)}>
            <img src={ReplyIcon} alt="reply" />
            Reply
          </button>
        )}
      </div>
    </li>
  );
}

CommentsListItem.propTypes = {
  comment: PropTypes.object.isRequired,
  showOptionsIcon: PropTypes.bool,
  profileId: PropTypes.number,
  handleDelete: PropTypes.func,
  handleReply: PropTypes.func,
};

export default CommentsListItem;
