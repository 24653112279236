import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateUser } from '../../../actions/profile/update';
import { getJobProfile } from '../../../actions/profile/getJobProfile';
import Job from '../../../assets/images/profile/completion/farmers.svg';

const texts = {
    title: 'What do you do?',
    helper: 'Select the nature of your job'
}

class JobProfileModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            occupation_id: props.profile.profile_occupations
                ? props.profile.profile_occupations[0].id
                : 0
        }
        // binding function to see job profiles
        this.handlejobProfileSelect = this.handlejobProfileSelect.bind(this);
        // binding function to save profile
        this.handleProfileSave = this.handleProfileSave.bind(this);
    }

    componentDidMount() {
        const { token,
            jobProfile,
            getJobProfile } = this.props;
        if (!jobProfile.all.length) {
            getJobProfile(token);
        }
    }

    handlejobProfileSelect(e) {
        this.setState({
            occupation_id: parseInt(e.target.value)
        })
    }

    handleProfileSave() {
        const { occupation_id } = this.state;
        if (!occupation_id) return;
        const { token,
            updateUser,
            profile,
            onClose } = this.props,
            dataToSend = {
                '_method': 'PATCH',
                profile: {
                    occupation_id
                }
            };
        updateUser(profile.id,
            dataToSend,
            token)
            .then(val => {
                if (val) {
                    onClose();
                }
            })
    }

    render() {
        const { jobProfile,
            onClose } = this.props;
        return (<div className="profile-completion-modal__content">
            <div className="profile-completion-modal__header">
                <img src={Job} alt="job-profile" className="profile-completion-modal__header__icon" />
                <p className="profile-completion-modal__header__title">{texts.title}</p>
                <p className="profile-completion-modal__header__helper">{texts.helper}</p>
            </div>
            <div className="profile-completion-modal__body">
                {
                    jobProfile
                        && jobProfile.fetching
                        ? (null)
                        : jobProfile
                        && jobProfile.all
                        && !!jobProfile.all.length
                        && (<ul className="profile-completion-modal__pill-input-list">
                            {
                                jobProfile.all.map((jobProfile, jobProfileIndex) => {
                                    return (
                                        <li className="profile-completion-modal__pill-input-list__item" key={`job-profile-${jobProfileIndex}-key`}>
                                            <label className="profile-pill-label">
                                                <input type="radio"
                                                    className="profile-pill-label__input"
                                                    onChange={this.handlejobProfileSelect}
                                                    name="profile[occupation_id]"
                                                    id={`job-profile-${jobProfileIndex}`}
                                                    checked={(parseInt(this.state.occupation_id) === jobProfile.id)}
                                                    value={jobProfile.id} />
                                                <div className="profile-pill-label__job-profile-list">
                                                    <img src={jobProfile.image}
                                                        className="profile-pill-label__specialisation-image"
                                                        alt="occupation"
                                                    />
                                                    <p className="profile-pill-label__list-text profile-pill-label__list-text--centered-hidden">{jobProfile.name}</p>
                                                </div>
                                            </label>
                                        </li>
                                    )
                                })
                            }
                        </ul>)
                }
            </div>
            <div className="profile-completion-btn__btn">
                <button className="profile-completion-btn__btn__cancel-btn"
                    onClick={onClose}>
                    <span>Cancel</span>
                </button>
                <button className={`profile-completion-btn__btn__submit-btn ${!this.state.occupation_id ? "disabled" : ""}`}
                    onClick={this.handleProfileSave} disabled={!this.state.occupation_id}>
                    <span>Done</span>
                </button>
            </div>
        </div>)
    }
}

function mapStateToProps(state) {
    return {
        token: state.login.token,
        jobProfile: state.profileReducer.jobProfile,
        profile: state.user.user.profile
    }
}

const mapDispatchToProps = {
    updateUser,
    getJobProfile
}

JobProfileModal.propTypes = {
    onClose: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(JobProfileModal);
