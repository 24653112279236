import { GET_EATING_HABITS_REQUEST,
    GET_EATING_HABITS_SUCCESS,
    GET_EATING_HABITS_FAILURE } from '../../actions/profile/constants';

const INITIAL_STATE = {
    fetching: false,
    error: false,
    errorMsg: null,
    all: []
}

export default function eatingHabits(state = INITIAL_STATE, action)  {
    switch (action.type) {
        case GET_EATING_HABITS_REQUEST:
            return {
                ...state,
                fetching: true,
            }
        case GET_EATING_HABITS_SUCCESS:
            return {
                ...state,
                all: action.payload,
                fetching: false
            }
        case  GET_EATING_HABITS_FAILURE:
            return {
                ...state,
                fetching: false,
                error: true,
                errorMsg: action.error
            }
        default:
            return state;
    }
}