import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  UpdateNotificationItem,
  markNotificationRead,
  markNotificationReadCancelToken,
} from '../../../actions/notifications';
import { internalNotificationLink } from '../../../utils/notificationHelper';
import { followUser } from '../../../actions/profile/follow';
import Avatar from '../../../components/user/Avatar';
import Check from '../../../assets/images/common/suggestions/check.svg';
import { decodeString } from '../../../utils/helper';
import UserName from '../../../components/profile/UserName';
import InvitationAction from '../../../components/invitations/InvitationAction';
import { invitationGAData } from '../../../components/collaborations/prManage/schedule/constants';
import { handleInvitation } from '../../../components/collaborations/prManage/schedule/helper';
import { showInvitationDetailsModal } from '../../../actions/common/modals';

const NotificationActionButton = ({ action, handleFollow, isFollowing, showFollowingButton, data, status, handleInvitationModal, is_active }) => {
  const invitationData = { status: status, id: data.action_id }
  if (action !== 'follow' && action !== 'expirepoll' && action !== "tastingscheduleinvitation") return null;
  if (action === 'follow') {
    if (!isFollowing) {
      return (
        <div className="notification__action__container">
          <button onClick={handleFollow} className="notification__action__btn">
            + FOLLOW
          </button>
        </div>
      );
    } else {
      if (showFollowingButton) {
        return (
          <div className="notification__action__container">
            <p className="notification__action__btn notification__action__btn--following">
              <img className="notification__action__btn__icon" src={Check} alt="following" />
              Following
            </p>
          </div>
        );
      }
      return null;
    }
  }
  if (action === 'expirepoll') {
    return (
      <div className="notification__action__container">
        <button className="notification__action__btn notification__action__btn--poll">
          VIEW RESULTS
        </button>
      </div>
    );
  }
  if (action === "tastingscheduleinvitation") {
    return (
      <>
        {!!is_active ?
          <InvitationAction isDetails={true} isNotification={true} handleInvitationModal={handleInvitationModal} invitation={invitationData} link={data.link} />
          : null}
      </>
    )
  }
};

class NotificationListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFollowing: false,
      showInvitationDetail: false
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleFollow = this.handleFollow.bind(this);
    this.handleInvitationModal = this.handleInvitationModal.bind(this);
  }

  handleClick(id) {
    if (!id) return;
    const { token, markNotificationRead } = this.props;
    markNotificationRead(id, token);
  }

  handleInvitationModal(e) {
    const { showInvitationDetailsModal, notificationItem: { data: { action_id }, id } } = this.props;
    e.preventDefault();
    e.nativeEvent.stopImmediatePropagation();
    const data = invitationGAData("info_details", "click", "bell_notifications")
    handleInvitation(data);
    const reduxdata = {
      invitation_id: action_id,
      model: "notification",
      notification_id: id
    }
    showInvitationDetailsModal(reduxdata)
  }

  handleFollow(e, profile, notificationId) {
    e.preventDefault();
    e.nativeEvent.stopImmediatePropagation();
    if (!profile) return;
    let profileId = profile.id,
      { token, followUser } = this.props;
    this.setState(
      {
        isFollowing: true,
      },
      () => {
        followUser(profileId, token).then(() => {
          this.setState({ isFollowing: false });
        });
      },
    );
    this.handleClick(notificationId);
  }

  render() {
    const {
      notificationItem: { isFollowing, read_at, showFollowingButton, data, created_at, status, is_active },
      user,
    } = this.props;
    if (!data) return null;
    const { action, actionModel, model, profile, action_id, sub_title } = data;
    const seenClass = read_at ? '' : 'notification__list__item--unread';
    const invitationClass = action === "tastingscheduleinvitation" ? "notification__list__item--invitation" : ""
    const createdAt = created_at ? new Date(created_at.split(' ')[0]) : new Date();
    const createdAtTime = new Intl.DateTimeFormat('en-GB', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    }).format(new Date(createdAt));
    const name =
      actionModel && actionModel.name
        ? actionModel.name
        : profile && profile.name
          ? profile.name
          : 'Your ';
    const profileImage =
      name === 'Your '
        ? user && user.user && user.user.profile && user.user.profile.image_meta
          ? JSON.parse(user.user.profile.image_meta).original_photo
          : ''
        : profile.imageUrl;
    const isVerified =
      actionModel && typeof actionModel.verified !== 'undefined' && name !== 'Your '
        ? !!actionModel.verified
        : profile && typeof profile.verified !== 'undefined'
          ? !!profile.verified
          : false;
    const notificationText = data ? data.notification : '';
    const imageToShow = model.name === 'photo' && model.image ? model.image : false;
    const notificationId = this.props.notificationItem ? this.props.notificationItem.id : null;
    const linkToGo = this.props.notificationItem
      ? internalNotificationLink(this.props.notificationItem)
      : '#';
    const doesItIncludeName = notificationText.includes(name);

    let notificationTextToShow = doesItIncludeName
      ? notificationText.replace(name, ' ')
      : notificationText;
    notificationTextToShow = ` ${decodeString(notificationTextToShow)}`;

    const avatarProfile = name === 'Your ' ? user && user.user && user.user.profile : profile;
    const handleFollowNotification = (e) => this.handleFollow(e, profile, notificationId);
    return (
      <>
        <Link
          to={linkToGo}
          onClick={() => this.handleClick(notificationId)}
          className={`notification__list__item ${seenClass} ${invitationClass}`}
        >
          <div className="notification__details">
            <div className="notification__details__container">
              <Avatar
                className="notification__profile-icon"
                src={profileImage}
                alt={name}
                profile={avatarProfile}
              />
              <div>
                <p className="notification__title">
                  {doesItIncludeName && (
                    <span className="notification__title--secondary">
                      <UserName name={name} isVerified={isVerified} />
                    </span>
                  )}
                  {notificationTextToShow}
                </p>
                {action === "tastingscheduleinvitation" && <p className="notification__sub-title">{sub_title}</p>}
                <p className="notification__title--helper">{createdAtTime}</p>

                <NotificationActionButton
                  isFollowing={isFollowing}
                  showFollowingButton={showFollowingButton}
                  handleFollow={handleFollowNotification}
                  action={action}
                  status={status}
                  data={data}
                  handleInvitationModal={this.handleInvitationModal}
                  is_active={is_active}
                />
              </div>
            </div>
            {imageToShow && (
              <img className="notification__image" alt={notificationTextToShow} src={imageToShow} />
            )}
          </div>
        </Link>

      </>
    );
  }
}

NotificationListItem.propTypes = {
  notificationItem: PropTypes.object.isRequired,
  user: PropTypes.object,
  token: PropTypes.string,
  markNotificationRead: PropTypes.func,
  followUser: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    token: state.login.token,
    user: state.user,
  };
}

const mapDispatchToProps = {
  markNotificationRead,
  markNotificationReadCancelToken,
  followUser,
  UpdateNotificationItem,
  showInvitationDetailsModal
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationListItem);
