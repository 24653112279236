import { GET_PROFILE_MY_COLLABORATIONS_REQUEST,
    GET_PROFILE_MY_COLLABORATIONS_SUCCESS,
    GET_PROFILE_MY_COLLABORATIONS_FAILURE,
    RESET_PROFILE_MY_COLLABORATIONS } from "../../actions/profile/constants";

const INITIAL_STATE = {
    fetching: false,
    error: false,
    errorMsg: null,
    collaborations: [],
    page: 1,
    count: 0,
    hasEnd: false
}

export default function myCollaborations(state = INITIAL_STATE,
    action) {
    switch(action.type) {
        case GET_PROFILE_MY_COLLABORATIONS_REQUEST:
            return {
                ...state,
                fetching: true
            }
        case GET_PROFILE_MY_COLLABORATIONS_SUCCESS:
            return {
                ...state,
                fetching: false,
                count: action.payload.count,
                collaborations: state.page === 1
                    ? action.payload.collaborations
                    : [...state.collaborations, ...action.payload.collaborations],
                hasEnd: !action.payload.collaborations.length,
                page: state.page + 1
            }
        case GET_PROFILE_MY_COLLABORATIONS_FAILURE:
            return {
                ...state,
                fetching: false,
                ...(!action.error.__CANCEL__ && {
                    error: true,
                    errorMsg: action.error
                })
            }
        case RESET_PROFILE_MY_COLLABORATIONS:
            return INITIAL_STATE;
        default:
            return state;
    }
}