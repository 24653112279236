import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { format, parseISO } from 'date-fns';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Modal from '../../modals/common/Modal';
import { hideProfileModal } from '../../../actions/common/modals';
import {
  getPassbookTransactions,
  getPassbookTransactionsCancelToken,
  postTransactionRaiseConcern,
  postTransactionRaiseConcernCancelToken,
} from '../../../actions/profile/passbook/passbook';
import Loader from '../../../components/misc/Loader';
import { getStateColumnCssClasses } from '../../../components/profile/MyPassbook/utils';
import FieldError from '../../../components/FieldError';
import TitleRedirectIcon from '../../../assets/images/profile/view/icons-24-redirect.svg';

const TransactionModal = ({ hideProfileModal, handleConcernButtonClick, data }) => {
  const statusData = getStateColumnCssClasses(get(data, 'status'));
  const createdDate = get(data, 'created_at', '')
    ? format(parseISO(data.created_at), 'dd MMM yyyy')
    : '';
  const updatedDate = get(data, 'updated_at', '')
    ? format(parseISO(data.updated_at), 'dd MMM yyyy')
    : '';
    const popupAmount = get(data, 'pop_up.amount', '');
    const isStatusId = get(data, 'status.id') === 2;
    const tdsAmount = "tds_amount" in data;
    const charityName = get(data, 'donation_organisation.title', '');
    const isDonation = get(data, 'donation_organisation_id', '');
  return (
    <React.Fragment>
      <div className="biz__modal__triodata">
        <div className="passbook__modal__row1">
          <img
            className="passbook__modal__row1__icon passbook__modal__row1__image"
            src={get(data, 'pop_up.icon')}
            alt="modal icon"
          />
          {popupAmount && (
            <p className="passbook__modal__row1__amount">{get(data, 'pop_up.amount', '')}</p>
          )}
          <p className="passbook__modal__row1__title">{get(data, 'pop_up.title', '')}</p>
          <p className="passbook__modal__row1__subtitle">{get(data, 'pop_up.sub_title', '')}</p>
          <hr className="passbook__modal__row1__hr" />
        </div>
        <div className="passbook__modal__row2">
          <p className="passbook__modal__row2__datetype">{`${createdDate} • ${get(
            data,
            'model',
          )}`}</p>
          {(updatedDate && !isDonation) && (
            <p className="passbook__modal__row2__datetype">Updated Date - {updatedDate}</p>
          )}
          <div className="passbook__modal__row2__titlediv">
            <a
              className="passbook__modal__row2__titlediv__text"
              href={get(data, 'deeplink')}
              target="_blank"
              rel="noopener noreferrer"
            >
              {get(data, 'title')}
            </a>
            <a href={get(data, 'deeplink')} target="_blank" rel="noopener noreferrer">
              <img
                className="passbook__modal__row2__titlediv__icon"
                src={TitleRedirectIcon}
                alt="title redirect"
              />
            </a>
          </div>
          <p className="passbook__modal__row2__transactionid">{`Transaction ID - ${get(
            data,
            'transaction_id',
          )}`}</p>
          <div className="passbook__modal__row2__status">
            <span className="passbook__modal__row2__status__text">Amount - </span>
            <span
              className="passbook__modal__row2__status__type"
              style={{ color: '#171717'}}
            >{`₹${get(data,'amount',)}`}</span>
          </div>
          {
            (tdsAmount && !isDonation) && (
              <div className="passbook__modal__row2__status">
              <span className="passbook__modal__row2__status__text">Tax/TDS Deduction (10%) - </span>
              <span
                className="passbook__modal__row2__status__type"
                style={{ color: '#171717' }}
              >{`₹${get(data, "tds_amount")}`}</span>
            </div>
            )
          }
         
          <div className="passbook__modal__row2__status">
            <span className="passbook__modal__row2__status__text">Status - </span>
            <span
              className="passbook__modal__row2__status__type"
              style={{ color: get(data, 'status.text_color', '#171717') }}
            >{` ${statusData.statusText}`}</span>
          </div>

          { isDonation && <div className="passbook__modal__row2__status">
            <span className="passbook__modal__row2__status__text">Charity - </span>
            <span
              className="passbook__modal__row2__status__charity_name"
            >{charityName}</span>
          </div>}
        </div>
        <div className="passbook__modal__row3">
          <button
            className="cursor-pointer passbook__modal__row3__button"
            onClick={handleConcernButtonClick}
          >
            Have any issue? Tap to raise a ticket
          </button>
        </div>

        {isStatusId && (
          <div className="default-modal__footer passbook__modal__footer default-modal__footer--bottom">
            <button type="button" className="biz__modal__cancel-btn" onClick={hideProfileModal}>
              Cancel
            </button>
            <a
              type="button"
              className="passbook__modal__footer__redeembutton cursor-pointer"
              href={get(data, 'link')}
              target="_blank"
              rel="noopener noreferrer"
            >
              Redeem Now
            </a>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

const ConcernModal = ({ token, hideProfileModal, transactionId }) => {
  const initialValues = {
    title: '',
    description: '',
  };
  const validationSchema = {
    title: Yup.string().trim().max(150, 'Must be 150 characters or less').required('Required'),
    description: Yup.string()
      .trim()
      .max(2000, 'Must be 2000 characters or less')
      .required('Required'),
  };
  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object(validationSchema),
    onSubmit: (values) => handleSendConcern(values),
  });

  useEffect(() => {
    formik.validateForm();
  }, []);

  const handleSendConcern = (values) => {
    const { title, description } = values;

    const data = {
      title: title.trim(),
      description: description.trim(),
    };

    postTransactionRaiseConcern(token, transactionId, data).then((response) => {
      if (response.status) {
        hideProfileModal();
        alert(
          `Thanks for reporting your concern regarding ${transactionId} we will get back to you soon.`,
        );
      } else {
        alert('Some Error Occurred');
      }
    });
  };
  return (
    <React.Fragment>
      <div className="biz__modal__triodata">
        <p className="passbook__modal__concern__title">Describe a brief of your concern.</p>
        <form onSubmit={formik.handleSubmit}>
          <div className="profile-edit-modal__form__group">
            <label className="form-label">Title*</label>
            <input
              type="text"
              name="title"
              className="form-input form-input--secondary passbook__modal__concern__formtitle"
              placeholder="Title"
              {...formik.getFieldProps('title')}
            />
            {formik.touched.title && formik.errors.title ? (
              <FieldError text={formik.errors.title} />
            ) : null}
          </div>

          <div className="profile-edit-modal__form__group">
            <label className="form-label">Description*</label>
            <textarea
              type="text"
              name="concernText"
              className="form-textarea form-input--secondary passbook__modal__concern__textarea"
              placeholder="Write your transaction concern"
              {...formik.getFieldProps('description')}
            />
            {formik.touched.description && formik.errors.description ? (
              <FieldError text={formik.errors.description} />
            ) : null}
          </div>
          <div className="default-modal__footer default-modal__footer--bottom">
            <button type="button" className="biz__modal__cancel-btn" onClick={hideProfileModal}>
              Cancel
            </button>
            <button
              disabled={!formik.isValid || formik.isSubmitting}
              className={`passbook__modal__concern__submitbutton
                    ${
                      !formik.isValid || formik.isSubmitting
                        ? 'passbook__modal__concern__submitbutton__disabled'
                        : 'cursor-pointer'
                    }`}
              type="submit"
            >
              Send Concern
            </button>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};

const TransactionDetailsModal = (props) => {
  const { token, hideProfileModal } = props;
  const transactionId = get(props, 'data.transactionId');

  const [isDataFetched, setIsDataFetched] = useState(false);
  const [transactionData, setTransactionData] = useState({});
  const [modalTitle, setModalTitle] = useState('');
  const [showConcernModal, setShowConcernModal] = useState(false);

  const TransactionModalComponent = React.memo(TransactionModal);
  const ConcernModalComponent = React.memo(ConcernModal);

  useEffect(() => {
    fetchTransactionDetails();

    return () => {
      if (getPassbookTransactionsCancelToken) getPassbookTransactionsCancelToken();
      if (postTransactionRaiseConcernCancelToken) postTransactionRaiseConcernCancelToken();
    };
  }, [transactionId]);

  const fetchTransactionDetails = () => {
    getPassbookTransactions(token, transactionId).then((response) => {
      if (response.status) {
        const data = get(response, 'data.data', {});
        setTransactionData(data);
        setIsDataFetched(true);
      } else {
        setTransactionData({});
        setIsDataFetched(true);
      }
    });
  };

  const handleConcernButtonClick = () => {
    const modalTitle = `Transaction ID - ${transactionId}`;
    setModalTitle(modalTitle);
    setShowConcernModal(true);
  };

  return (
    <Modal
      showHeader
      className="passbook__modal__width"
      handleClose={hideProfileModal}
      title={modalTitle}
    >
      {!isDataFetched ? (
        <Loader isCentered={true} withMargin={true} />
      ) : (
        <React.Fragment>
          {!showConcernModal ? (
            <TransactionModalComponent
              hideProfileModal={hideProfileModal}
              handleConcernButtonClick={handleConcernButtonClick}
              data={transactionData}
            />
          ) : (
            <ConcernModalComponent
              token={token}
              hideProfileModal={hideProfileModal}
              transactionId={transactionId}
            />
          )}
        </React.Fragment>
      )}
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.login.token,
  };
};

const mapDispatchToProps = {
  hideProfileModal,
};

TransactionModal.propTypes = {
  data: PropTypes.object,
  hideProfileModal: PropTypes.func,
  handleConcernButtonClick: PropTypes.func,
};
ConcernModal.propTypes = {
  token: PropTypes.string,
  hideProfileModal: PropTypes.func,
  transactionId: PropTypes.string,
};
TransactionDetailsModal.propTypes = {
  token: PropTypes.string,
  hideProfileModal: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionDetailsModal);
