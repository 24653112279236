import React from 'react'
import { AddToCalendarButton, atcb_action } from 'add-to-calendar-button-react';
import { getOS } from '../../utils/helper';
import CommonPortal from '../modals/common/CommonPortal';
import { add, format, parse } from 'date-fns';
import MouseIcon from '../../../src/assets/images/rsvp/mouse-icon.svg';


function AddToCalendar(props) {
    const { invitation, handleUpdate, isDetails, isNotification } = props;
    let optionList = "'Google','iCal','Outlook.com','Microsoft 365'";
    const os = getOS();
    if (os === "Mac" || os === "iOS") {
        let optionArray = optionList.split(',');
        optionArray.push("'Apple'");
        optionList = optionArray.join(',');
    }
    const dateTimeString = `${invitation.date}T${invitation.reporting_time}`;
    const startDateTime = parse(dateTimeString, "yyyy-MM-dd'T'HH:mm:ss", new Date());
    const duration = {
        hours: 3,
        minutes: 0
    };
    const endDateTime = add(startDateTime, duration);
    const formattedEndDate = format(endDateTime, "yyyy-MM-dd");
    const formattedEndTime = format(endDateTime, "HH:mm");
    return (

        <div className={`schedule__add-to-calendar-wrapper ${!!isDetails ? "details-view" : ""}`} onClick={(e) => handleUpdate(e, "", "addToCalendar")}>

            <AddToCalendarButton
                name={invitation.collaboration.title}
                startDate={invitation.date}
                startTime={invitation.reporting_time}
                styleLight={`--btn-background: #ffffff; --btn-text: #f47816; --btn-border-radius: 8px; --btn-border:#f47816; --font: 14px; --btn-font-weight: 600; --btn-shadow: none;--btn-underline:transparent;--btn-hover-shadow:none;--btn-padding-y: 8px;--btn-padding-x: 16px;--btn-hover-text:#f47816; --list-background: #ffffff;--overlay-cursor: ${MouseIcon} !important`}
                endTime={formattedEndTime}
                endDate={formattedEndDate}
                listStyle={`${!!isDetails ? "dropdown-static" : "dropup-static"}`}
                buttonStyle="text"
                timeZone="Asia/Kolkata"
                location={`Title : ${invitation.tasting_schedule_city.location.title} , Link : ${invitation.tasting_schedule_city.location.link}`}
                options={optionList}
                hideIconButton
                hideCheckmark
                hideBackground
                hideBranding
                description={invitation.description ? `TagTaste Event: \n ${invitation.description}` : ""}
                lightMode="bodyScheme"
            >
            </AddToCalendarButton>

        </div>

    )
}

export default AddToCalendar