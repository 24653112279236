import React from "react";
import PropTypes from "prop-types";
import {
  collaborationInternalLinks,
  surveyLinks,
  hashtagLinks,
  polling,
  collectionLinks,
  profileLinks,
  photosInternalLinks,
  feedLink,
  miscLinks,
  quizLinks,
} from "../../utils/internalLinks";
import ChevronRight from "../../assets/images/photos/chevron-right.svg";

export const modelRedirectFn = (modelName, modelId) => {
  let link;
  switch (modelName) {
    case "collaborate":
      if (modelId) link = collaborationInternalLinks.collaboratePage(modelId);
      else link = collaborationInternalLinks.all;
      break;

    case "product-review":
      if (modelId) link = collaborationInternalLinks.productReviewPage(modelId);
      else link = collaborationInternalLinks.productReviews;
      break;

    case "surveys":
      if (modelId) link = surveyLinks.view(modelId);
      else link = surveyLinks.all;
      break;

    case "quiz":
      if (modelId) link = quizLinks.view(modelId);
      else link = quizLinks.all;
      break;

    case "polling":
      if (modelId) link = polling.view(modelId);
      else link = polling.all;
      break;

    case "product":
      if (modelId) link = collaborationInternalLinks.productPage(modelId);
      else link = collaborationInternalLinks.allProducts;
      break;

    case "companies":
      if (modelId) link = profileLinks.company(modelId);
      break;

    case "profile":
      if (modelId) link = profileLinks.viewById(modelId);
      break;

    case "collection":
      if (modelId) link = collectionLinks.single(modelId);
      break;

    case "hashtag":
      if (modelId) link = hashtagLinks.feed(modelId);
      else link = hashtagLinks.emptyFeed;
      break;

    case "paid_taster":
      link = profileLinks.paidTasterProgram;
      break;

    case "photo":
      link = photosInternalLinks.singlePhoto(modelId);
      break;

    case "shoutout":
      link = miscLinks.shoutout(modelId);
      break;

    case "feed":
      link = feedLink;
      break;
  }

  return link;
};

export const SliderArrow = (props) => {
  const {
    userClick,
    onClick,
    to,
    currentSlide,
    slideCount,
    isBigbanner,
    gaFn,
  } = props;
  const maxSlide = isBigbanner ? slideCount - 1 : slideCount - 2;
  const isDisabled =
    (to === "prev" && currentSlide === 0) ||
    (to === "next" && currentSlide === maxSlide);

  return (
    <button
      aria-hidden="true"
      aria-disabled={isDisabled}
      type="button"
      disabled={isDisabled}
      className={`suggestions__arrow suggestions__arrow--${to} ${
        isDisabled ? "suggestions__arrow--disabled" : ""
      }`}
      onClick={() => {
        if (userClick) userClick();
        onClick();

        if (!isBigbanner && gaFn) {
          gaFn();
        }
      }}
    >
      <img
        className={`suggestions__arrow__icon suggestions__arrow__icon--${to}`}
        src={ChevronRight}
        alt={`go to ${to} slide`}
      />
    </button>
  );
};

SliderArrow.propTypes = {
  slideCount: PropTypes.number,
  currentSlide: PropTypes.number,
  to: PropTypes.string,
  userClick: PropTypes.func,
  onClick: PropTypes.func,
  isBigbanner: PropTypes.bool,
  gaFn: PropTypes.func,
};
