import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateUser } from '../../../actions/profile/update';
import { getCuisines } from '../../../actions/profile/cuisines';
import FoodInterest from '../../../assets/images/profile/completion/food-interest.svg';

const texts = {
    title: 'Cuisines you absolutely dig!',
    helper: 'Tell us something about the kind of food & beverage items that satiate your palate. This will enable us to personalize your feed on new products, tasting classes, and assignments.'
}

class CuisineModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cuisine_id: props.profile.cuisines.map(_ => _.id)
        }
        // binding function to see cuisine
        this.handleCuisineSelect = this.handleCuisineSelect.bind(this);
        // binding function to save profile
        this.handleProfileSave = this.handleProfileSave.bind(this);
    }

    componentDidMount() {
        const { token,
            cuisines,
            getCuisines } = this.props;
        if (!cuisines.all.length) {
            getCuisines(token);
        }
    }

    handleCuisineSelect(e) {
        let isChecked = e.target.checked,
            value = parseInt(e.target.value);
        this.setState({
            cuisine_id: isChecked
                ? this.state.cuisine_id.concat(value)
                : this.state.cuisine_id.filter(cuisine => cuisine !== value)
        })
    }

    handleProfileSave() {
        const { cuisine_id } = this.state;
        const { token,
            updateUser,
            profile,
            onClose } = this.props,
            dataToSend = {
                '_method': 'PATCH',
                cuisine_id
            };
        updateUser(profile.id,
            dataToSend,
            token)
            .then(val => {
                if (val) {
                    onClose();
                }
            })
    }

    render() {
        const { cuisines,
            onClose } = this.props;
        return (<div className="profile-completion-modal__content">
            <div className="profile-completion-modal__header">
                <img src={FoodInterest} alt="cuisine" className="profile-completion-modal__header__icon" />
                <p className="profile-completion-modal__header__title">{texts.title}</p>
                <p className="profile-completion-modal__header__helper">{texts.helper}</p>
            </div>
            <div className="profile-completion-modal__body">
                {
                    cuisines
                        && cuisines.fetching
                        ? (<p>Loading...</p>)
                        : cuisines
                        && cuisines.all
                        && !!cuisines.all.length
                        && (<ul className="profile-completion-modal__pill-input-list">
                            {
                                cuisines.all.map((cuisine, cuisineIndex) => {
                                    return (
                                        <li className="profile-completion-modal__pill-input-list__item" key={`cuisine-${cuisineIndex}-key`}>
                                            <label htmlFor={`cuisine-${cuisineIndex}`}>
                                                <input
                                                    onChange={this.handleCuisineSelect}
                                                    className="profile-completion-modal__pill-input-list__input"
                                                    id={`cuisine-${cuisineIndex}`}
                                                    type="checkbox"
                                                    checked={(this.state.cuisine_id.includes(cuisine.id))}
                                                    value={cuisine.id} />
                                                <span className="profile-completion-modal__pill-input-list__pill">{cuisine.name}</span>
                                            </label>
                                        </li>
                                    )
                                })
                            }
                        </ul>)
                }
            </div>
            <div className="profile-completion-btn__btn">
                <button type="button"
                    className="profile-completion-btn__btn__cancel-btn"
                    onClick={onClose}>
                    <span>Cancel</span>
                </button>
                <button type="submit"
                    className={`profile-completion-btn__btn__submit-btn`}
                    onClick={this.handleProfileSave}>
                    <span>Done</span>
                </button>
            </div>
        </div>)
    }
}

function mapStateToProps(state) {
    return {
        token: state.login.token,
        cuisines: state.profileReducer.cuisines,
        profile: state.user.user.profile,
    }
}

const mapDispatchToProps = {
    updateUser,
    getCuisines
}

CuisineModal.propTypes = {
    onClose: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(CuisineModal);