import React from 'react';
import { Route } from 'react-router-dom';
import LoggedInBar from '../../components/bars/LoggedInBar';
import Footer from '../../components/Footer';
import LoggedOutBar from '../../components/bars/LoggedOutBar';

function PublicPages({ component: Component,
    publicComponent: PublicComponent,
    withFooter,
    disableTracker,
    sendMeta,
    isPreview,
    ...rest }) {
    return (<Route {...rest} render={props => (
        window.localStorage.getItem('authToken')
            ? (<>
                <LoggedInBar />
                <Component sendMeta={sendMeta} key={new Date()} {...props} />
                {withFooter && <Footer />}
            </>)
            : (<>
                <LoggedOutBar />
                <PublicComponent sendMeta={sendMeta} {...props} />
                {!isPreview ? <Footer /> : null}
            </>)
    )} />);
};

export default PublicPages;