import { GET_TASTING_METHODOLOGY_REQUEST,
    GET_TASTING_METHODOLOGY_SUCCESS,
    GET_TASTING_METHODOLOGY_FAILURE } from '../../../actions/collaborations/constants';

const INITIAL_STATE = {
    all: [],
    fetching: false,
    error: null
}

export default function tastingMethodologies(state = INITIAL_STATE, action) {
    switch(action.type) {
        case  GET_TASTING_METHODOLOGY_REQUEST:
            return {
                ...state,
                fetching: true
            }
        case  GET_TASTING_METHODOLOGY_SUCCESS:
            return {
                ...state,
                all: action.payload,
                fetching: false
            }
        case  GET_TASTING_METHODOLOGY_FAILURE:
            return {
                ...state,
                fetching: false,
                error: true,
                errorMsg: action.error
            }
        default:
            return state;
    }
}