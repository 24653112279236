import React from 'react';
import PropTypes from 'prop-types';
import { homeLink } from '../../utils/internalLinks';
import { Route, Redirect } from 'react-router-dom';

function OnlyLoggedOut({ component: Component, showMeta, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        window.localStorage.getItem('authToken') ? (
          <Redirect to={{ pathname: homeLink }} />
        ) : (
          <>
            <Component showMeta={showMeta} {...props} />
          </>
        )
      }
    />
  );
}

OnlyLoggedOut.propTypes = {
  component: PropTypes.any,
  showMeta: PropTypes.bool,
};

export default OnlyLoggedOut;
