import React from 'react';
import PropTypes from 'prop-types';
import { taggedText,
    decodeString } from '../../../../utils/helper';
import ReadMoreText from './ReadMoreText';
import ReadMoreLinkified from './ReadMoreLinkified';


function TaggedText(props) {
    const { text,
        profiles,
        skipReadMore,
        className,
        isLinkified,
        hashtagEventLabel,
        isOpen } = props;
    const ComponentToUse = isLinkified
        ? ReadMoreLinkified
        : ReadMoreText,
        textToUse = isLinkified
            ? taggedText((text), profiles, skipReadMore)
            : taggedText(decodeString(text), profiles, skipReadMore)
    return (<ComponentToUse
        isOpen={isOpen}
        hashtagEventLabel={hashtagEventLabel}
        className={className || ''}
        tagged={true}
        text={textToUse} />)
};

TaggedText.propTypes = {
    text: PropTypes.string.isRequired,
    profiles: PropTypes.array.isRequired,
    skipReadMore: PropTypes.bool,
    className: PropTypes.string,
    isOpen: PropTypes.bool,
    isLinkified: PropTypes.bool,
    hashtagEventLabel: PropTypes.string
}

export default TaggedText;