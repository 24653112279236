import Axios from "axios";
import { invitationsLinks } from '../../utils/links';
import { getBaseUrl } from '../../utils/links';


// invitation action
export const invitationAction = (token, id, data, profileId) => {
    let url = `${getBaseUrl()}${invitationsLinks.invitationUpdate(id, profileId)}`,
        options = {
            url,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            method: 'POST',
            data
        };
    return Axios(options)
        .then((response) => {
            let successData = response.data;
            return successData;
        })
        .catch((_) => {
            return false;
        });
};
