import { GET_TTFB_PRODUCT_DETAILS_REQUEST,
    GET_TTFB_PRODUCT_DETAILS_SUCCESS,
    GET_TTFB_PRODUCT_DETAILS_FAILURE,
    RESET_TTFB_PRODUCT_DETAILS } from '../../actions/business/constants';

const INITIAL_STATE = {
    fetching: false,
    productInfo: null,
    error: false,
    hasFetched: false
}

export default function product(state = INITIAL_STATE, action) {
    switch(action.type) {
        case GET_TTFB_PRODUCT_DETAILS_REQUEST:
            return {
                ...state,
                fetching: true
            }
        case GET_TTFB_PRODUCT_DETAILS_SUCCESS:
            return {
                ...state,
                fetching: false,
                productInfo: action.payload.data,
                hasFetched: true
            }
        case GET_TTFB_PRODUCT_DETAILS_FAILURE:
            return {
                ...state,
                ...(!action.error.__CANCEL__ && {
                    error: true,
                    errorMsg: action.error
                }),
                fetching: false
            }
        case RESET_TTFB_PRODUCT_DETAILS:
            return INITIAL_STATE
        default:
            return state;
    }
}