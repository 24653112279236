import { UPDATE_A_POST_REQUEST,
    UPDATE_A_POST_SUCCESS,
    UPDATE_A_POST_FAILURE,
    UPDATE_A_PHOTO_POST_REQUEST,
    UPDATE_A_PHOTO_POST_SUCCESS,
    UPDATE_A_PHOTO_POST_FAILURE } from './constants';
import { onRequest,
    getResponseData,
    onSuccess,
    onError } from '../../utils/helper';
import { getBaseUrl,
    postUrls,
    photoUrls } from '../../utils/links';
import Axios from 'axios';

// Action to update a post
export const updateAPost = (postId,
    data,
    token) => {
    return dispatch => {
        dispatch(onRequest(UPDATE_A_POST_REQUEST));
        let url = `${getBaseUrl()}${postUrls.updateShoutout(postId)}`,
            options = {
                url,
                method: 'PATCH',
                data,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };
        return Axios(options)
            .then((response) => {
                let successData = getResponseData(response);
                if (successData) {
                    dispatch(onSuccess(UPDATE_A_POST_SUCCESS, {
                        postType: 'shoutout',
                        postId,
                        data: successData
                    }));
                    return true;
                } else {
                    dispatch(onError(UPDATE_A_POST_FAILURE, response.data.errors));
                    return false;
                }
            })
            .catch(error => {
                dispatch(onError(UPDATE_A_POST_FAILURE, error));
                return false;
            })
    }
}

// Action to update a photo post
export const updateAPhotoPost = (postId,
    data,
    token) => {
        return dispatch => {
            dispatch(onRequest(UPDATE_A_PHOTO_POST_REQUEST));
            let url = `${getBaseUrl()}${photoUrls.updatePhotoPost(postId)}`,
                options = {
                    url,
                    method: 'PATCH',
                    data,
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                };
            return Axios(options)
                .then(response => {
                    let successData = getResponseData(response);
                    if (successData) {
                        dispatch(onSuccess(UPDATE_A_PHOTO_POST_SUCCESS, {
                            postType: 'photo',
                            postId,
                            data: successData
                        }));
                        return true;
                    } else {
                        dispatch(onError(UPDATE_A_PHOTO_POST_FAILURE, response.data.errors));
                        return false;
                    }
                })
                .catch(error => {
                    dispatch(onError(UPDATE_A_PHOTO_POST_FAILURE, error));
                    return false;
                })
        }
    }