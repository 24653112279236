import { GET_SPECIALIZATION_REQUEST,
    GET_SPECIALIZATION_SUCCESS,
    GET_SPECIALIZATION_FAILURE } from '../../actions/profile/constants';

const INITIAL_STATE = {
    fetching: false,
    error: false,
    errorMsg: null,
    all: []
}

export default function specializations(state = INITIAL_STATE, action)  {
    switch (action.type) {
        case GET_SPECIALIZATION_REQUEST:
            return {
                ...state,
                fetching: true,
            }
        case GET_SPECIALIZATION_SUCCESS:
            return {
                ...state,
                all: action.payload,
                fetching: false,
            }
        case GET_SPECIALIZATION_FAILURE:
            return {
                ...state,
                fetching: false,
                error: true,
                errorMsg: action.error
            }
        default:
            return state;
    }
}