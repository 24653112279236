import React, { useState } from "react";
import { verifyPreviewOTP } from "../../../../actions/collaborations/questionnairePreview/all";
import { connect } from "react-redux";
import Modal from "../../../../components/modals/common/Modal";
import { isValidNumber } from "../../../../utils/helper";

const maxOtpLength = 6;

const errorTexts = {
    otp: 'Please enter a valid OTP',
};
const initalFormValues = {
    submitting: false,
    otp: "",
    invalidOTP: false,
    otpError: ""
};

const OTPContent = (
    { email,
        isFormDisabled,
        invalidOTP,
        otp,
        handleOTPBlur,
        handleOTPChange,
        handleOTPFocus,
        otpError,
        handleSubmit,
        isModal,
        submitting }) => {
    return (
        <div className="otp-form">
            {!isModal ? <div className="otp-form__header">
                <p className="otp-form__header__title">Enter OTP</p>
            </div> : null}
            <p className="otp-form__subtitle">
                Please enter the one-time password (OTP) that was sent to your registered email {email}. This is a confidential information that is required to access the questionnaire.
            </p>
            <form
                className="login-form__form"
                disabled={isFormDisabled}
                onSubmit={(e) => {
                    e.preventDefault();
                }}
                noValidate
            >
                <div className={`loginphone__container loginphone__container__noerror ${invalidOTP ? 'loginphone__container__error' : ''}`}>
                    <input
                        type="text"
                        placeholder="OTP"
                        value={otp}
                        onChange={handleOTPChange}
                        onBlur={handleOTPBlur}
                        onFocus={handleOTPFocus}
                        maxLength={maxOtpLength}
                        max={maxOtpLength}
                        className="otp-form__otp"
                    />
                </div>
                <div className="otp-form__errors">
                    {invalidOTP && <p className="error-text">{errorTexts.otp}</p>}
                    {otpError && <p className="error-text">{otpError}</p>}
                </div>
                <button
                    type="button"
                    onClick={handleSubmit}
                    disabled={isFormDisabled}
                    className={`login-form__action__btn login-form__action__btn--default`}
                >
                    {submitting ? 'Submitting....' : 'Continue'}
                </button>
            </form>
        </div>
    )
}

const QuestionnaireOTP = (props) => {

    const { email, id, verifyPreviewOTP, isModal } = props;

    const [showOTPModal, setShowOTPModal] = useState(isModal);

    const [form, setForm] = useState(initalFormValues);

    const { submitting, otp, invalidOTP, otpError } = form;

    const handleOTPChange = (e) => {
        const { value } = e.target;
        setForm((preForm) => {
            return { ...preForm, otp: isValidNumber(value) }
        });
    }

    const handleOTPFocus = () => {
        setForm((preForm) => {
            return { ...preForm, invalidOTP: false, otpError: "" }
        })
    }

    const handleOTPBlur = () => {
        setForm((preForm) => {
            if (!otp || otp.trim().length !== maxOtpLength) {
                return { ...preForm, invalidOTP: true }
            } else {
                return { ...preForm, invalidOTP: false }
            }
        });
    }
    const handleSubmit = () => {
        if (isFormDisabled) return
        setForm((...preForm) => {
            return { ...preForm, submitting: true }
        });

        const data = { email, otp };
        verifyPreviewOTP(id, data).then((res) => {
            if (res && res.errors && res.errors.status) {
                setForm({ ...initalFormValues, otpError: res.errors.display_message || "Error" })
            } else {
                setForm(initalFormValues);
            }
        }).catch(() => setForm({ ...initalFormValues }))
    };

    const handleOTPModal = () => {
        setShowOTPModal(!showOTPModal);
    }

    const isFormDisabled = submitting || otp.trim().length !== maxOtpLength;

    return (
        <>
            {isModal && showOTPModal ?
                <Modal
                    handleClose={handleOTPModal}
                    showHeader
                    title="Enter OTP"
                    className="preview__otp-form__modal"
                >
                    {
                        <OTPContent
                            email={email}
                            isFormDisabled={isFormDisabled}
                            invalidOTP={invalidOTP}
                            otp={otp}
                            handleOTPBlur={handleOTPBlur}
                            handleOTPChange={handleOTPChange}
                            handleOTPFocus={handleOTPFocus}
                            otpError={otpError}
                            handleSubmit={handleSubmit}
                            submitting={submitting}
                            isModal={isModal}
                        />
                    }
                </Modal> : <OTPContent
                    email={email}
                    isFormDisabled={isFormDisabled}
                    invalidOTP={invalidOTP}
                    otp={otp}
                    handleOTPBlur={handleOTPBlur}
                    handleOTPChange={handleOTPChange}
                    handleOTPFocus={handleOTPFocus}
                    otpError={otpError}
                    handleSubmit={handleSubmit}
                    submitting={submitting}
                />}
        </>
    )
}

function mapStateToProps(state) {
    return {
        tempToken: state.collaborationReducer.previewAuth.tempToken,
        error: state.collaborationReducer.previewAuth.error,
    };
}

const mapDispatchToProps = {
    verifyPreviewOTP
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionnaireOTP);