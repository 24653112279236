// get quicklinks
export const GET_QUICK_LINKS = 'GET_QUICK_LINKS';
export const GET_QUICK_LINKS_SUCCESS = 'GET_QUICK_LINKS_SUCCESS';
export const GET_QUICK_LINKS_FAILURE = 'GET_QUICK_LINKS_FAILURE';

//get rightsidedata
export const GET_RIGHT_SIDE_DATA = 'GET_RIGHT_SIDE_DATA';
export const GET_RIGHT_SIDE_DATA_SUCCESS = 'GET_RIGHT_SIDE_DATA_SUCCESS';
export const GET_RIGHT_SIDE_DATA_FAILURE = 'GET_RIGHT_SIDE_DATA_FAILURE';

//get landingPage data
export const GET_LANDING_PAGE_DATA = 'GET_LANDING_PAGE_DATA';
export const GET_LANDING_PAGE_DATA_SUCCESS = 'GET_LANDING_PAGE_DATA_SUCCESS';
export const GET_LANDING_PAGE_DATA_FAILURE = 'GET_LANDING_PAGE_DATA_FAILURE';
export const RESET_LANDING_PAGE_DATA = 'RESET_LANDING_PAGE_DATA';

// get feed data
export const GET_FROM_FEED_REQUEST = 'GET_FROM_FEED_REQUEST';
export const GET_FROM_FEED_SUCCESS = 'GET_FROM_FEED_SUCCESS';
export const GET_FROM_FEED_FAILURE = 'GET_FROM_FEED_FAILURE';
export const RESET_FROM_FEED_DETAILS = 'RESET_FROM_FEED_DETAILS';

// get landing top_bar
export const GET_LANDING_TOP_BAR_DATA = 'GET_LANDING_TOP_BAR_DATA';
export const GET_LANDING_TOP_BAR_DATA_SUCCESS = 'GET_LANDING_TOP_BAR_DATA_SUCCESS';
export const GET_LANDING_TOP_BAR_DATA_FAILURE = 'GET_LANDING_TOP_BAR_DATA_FAILURE';
