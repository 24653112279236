import get from 'lodash/get';
import {
  GET_LANDING_PAGE_DATA,
  GET_LANDING_PAGE_DATA_SUCCESS,
  GET_LANDING_PAGE_DATA_FAILURE,
  RESET_LANDING_PAGE_DATA,
} from '../../actions/landingPage/constants';
import {
  LIKE_A_POST_REQUEST,
  LIKE_A_POST_SUCCESS,
  LIKE_A_POST_FAILURE,
} from '../../actions/constants';
import { CREATE_A_COMMENT_SUCCESS } from '../../actions/common/constants';
import { GET_A_POST_COMMENT_SUCCESS, DELETE_A_COMMENT_SUCCESS } from '../../actions/feed/constants';
import { PLAY_A_POLL_SUCCESS } from '../../actions/poll/constants';

const INITIAL_STATE = {
  fetching: false,
  data: [],
  error: false,
  hasFetched: false,
};

const isCurrentItem = (state, actionPollId) => {
  if (!state.data) return false;

  let currentPostIndex = state.data.findIndex(
    (obj) => get(obj, `suggestion[${get(obj, `suggestion.type`, '')}].id`) === actionPollId,
  );
  return currentPostIndex === -1 ? false : true;
};

export default function landingPageData(state = INITIAL_STATE, action) {
  const newState = JSON.parse(JSON.stringify(state));
  let currentPostId = get(action, 'payload.postId');

  let currentPostIndex = newState.data.findIndex(
    (obj) => get(obj, `suggestion[${get(obj, `suggestion.type`, '')}].id`) === currentPostId,
  );

  let updatedPost;

  switch (action.type) {
    case GET_LANDING_PAGE_DATA:
      return {
        ...state,
        fetching: true,
        error: false,
      };
    case GET_LANDING_PAGE_DATA_SUCCESS:
      return {
        ...state,
        fetching: false,
        data: get(action, 'payload.data', []),
        hasFetched: true,
      };
    case GET_LANDING_PAGE_DATA_FAILURE:
      return {
        ...state,
        fetching: false,
        error: true,
        errorMsg: action.error,
      };

    case LIKE_A_POST_REQUEST:
    case LIKE_A_POST_SUCCESS:
    case LIKE_A_POST_FAILURE:
      if (isCurrentItem(state, get(action, 'payload.postId'))) {
        updatedPost = {
          ...newState.data[currentPostIndex],
          suggestion: {
            ...newState.data[currentPostIndex].suggestion,
            meta: {
              ...newState.data[currentPostIndex].suggestion.meta,
              hasLiked:
                action.type === LIKE_A_POST_REQUEST
                  ? !action.payload.hasLiked
                  : action.payload.hasLiked,
              likeCount:
                action.type === LIKE_A_POST_REQUEST
                  ? action.payload.hasLiked
                    ? newState.data[currentPostIndex].suggestion.meta.likeCount === 0
                      ? 0
                      : newState.data[currentPostIndex].suggestion.meta.likeCount - 1
                    : newState.data[currentPostIndex].suggestion.meta.likeCount + 1
                  : action.type === LIKE_A_POST_FAILURE
                  ? action.payload.hasLiked
                    ? newState.data[currentPostIndex].suggestion.meta.likeCount + 1
                    : newState.data[currentPostIndex].suggestion.meta.likeCount === 0
                    ? 0
                    : newState.data[currentPostIndex].suggestion.meta.likeCount - 1
                  : newState.data[currentPostIndex].suggestion.meta.likeCount,
            },
          },
        };
        newState.data[currentPostIndex] = updatedPost;
        return newState;
      } else {
        return state;
      }

    case CREATE_A_COMMENT_SUCCESS:
    case GET_A_POST_COMMENT_SUCCESS:
      if (isCurrentItem(state, get(action, 'payload.postId'))) {
        updatedPost = {
          ...newState.data[currentPostIndex],
          suggestion: {
            ...newState.data[currentPostIndex].suggestion,
            meta: {
              ...newState.data[currentPostIndex].suggestion.meta,
              commentCount:
                action.type === CREATE_A_COMMENT_SUCCESS
                  ? newState.data[currentPostIndex].suggestion.meta.commentCount + 1
                  : action.payload.count,
            },
          },
        };
        newState.data[currentPostIndex] = updatedPost;
        return newState;
      } else {
        return state;
      }

    case DELETE_A_COMMENT_SUCCESS:
      if (isCurrentItem(state, get(action, 'payload.postId'))) {
        updatedPost = {
          ...newState.data[currentPostIndex],
          suggestion: {
            ...newState.data[currentPostIndex].suggestion,
            meta: {
              ...newState.data[currentPostIndex].suggestion.meta,
              commentCount:
                newState.data[currentPostIndex].suggestion.meta.commentCount - 1 <= 0
                  ? 0
                  : newState.data[currentPostIndex].suggestion.meta.commentCount - 1,
            },
          },
        };
        newState.data[currentPostIndex] = updatedPost;

        return newState;
      } else {
        return state;
      }

    case PLAY_A_POLL_SUCCESS:
      if (isCurrentItem(state, get(action, 'payload.polling.id'))) {
        currentPostId = get(action, 'payload.polling.id');
        currentPostIndex = newState.data.findIndex(
          (obj) => get(obj, `suggestion[${get(obj, `suggestion.type`, '')}].id`) === currentPostId,
        );

        updatedPost = {
          ...newState.data[currentPostIndex],
          suggestion: {
            ...newState.data[currentPostIndex].suggestion,
            meta: {
              ...action.payload.meta,
            },
            polling: {
              ...action.payload.polling,
            },
          },
        };
        newState.data[currentPostIndex] = updatedPost;
        return newState;
      } else {
        return state;
      }

    case RESET_LANDING_PAGE_DATA:
      return INITIAL_STATE;

    default:
      return state;
  }
}
