import { ADD_COLLABORATION_OUTLET,
    REMOVE_COLLABORATION_OUTLET,
    UPDATE_COLLABORATION_OUTLET,
    LIKE_A_COLLABORATION_FAILURE} from "../../../actions/collaborations/constants";

const INITIAL_STATE = {
    fetching: false,
    data: [],
    error: false
}

const defaultOutletData = {
    city: null,
    outlets: []
}

export default function outlets(state = INITIAL_STATE, action) {
    switch(action.type) {
        case ADD_COLLABORATION_OUTLET:
            return {
                ...state,
                data: [...state.data, {...defaultOutletData}]
            }
        case REMOVE_COLLABORATION_OUTLET:
            return {
                ...state,
                data: state.data.filter((_, index) => index !== action.payload)
            }
        case UPDATE_COLLABORATION_OUTLET:
            return {
                ...state,
                data: state.data.map((_, index) => {
                    if (action.payload.isViaIndex && index === action.payload.index) {
                        return {
                            ..._,
                            ...action.payload.data
                        }
                    }
                    if (!action.payload.isViaIndex && _.id === action.payload.data.id) {
                        return {
                            ..._,
                            ...action.payload.data
                        }
                    }
                    return _;
                })
            }
        default:
            return state;
    }
}