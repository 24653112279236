import React, { useState } from "react";
import PromptFooter from "./PromptFooter";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarIcon from "../../../assets/images/survey/reports/calender.svg";
import { format } from 'date-fns';
import moment from "moment";

const DateOfBirth = (props) => {
    const { form: { isUpdating, error, value },
        setForm,
        handleUpdate,
        handleClose,
    } = props;
    const [visibility, setVisibility] = useState(1);
    const handleSave = () => {
        let data = {
            _method: "PATCH",
            profile: {
                dob: format(value, "dd-MM-yyyy"),
                dob_private: visibility
            }
        }
        handleUpdate(data);
    }
    const handleFormUpdate = (val) => {
        if (!val) return false;
        setForm((preForm) => {
            return { ...preForm, value: val }
        });
    }
    const handleVisibility = (e) => {
        if (!e) return;
        setVisibility(e.target.value);
    }
    return (
        <>
            <div className="profile-prompt__content">
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '8px' }}>
                    <label className="profile-prompt__content--label">Select Birthday</label>
                    <div>
                        <label htmlFor="dob-visibility" className="profile-prompt__content--label secondary">Visibility:</label>
                        <select className="input__select profile-prompt__content--label secondary" onChange={handleVisibility}>
                            <option value={1}>Everyone</option>
                            <option value={2}>People i follow</option>
                            <option value={3}>No one</option>
                        </select>
                    </div>
                </div>
                <div className="input__container input__date--container">
                    <label htmlFor="profile-prompt-dob"><img src={CalendarIcon} /></label>
                    <DatePicker
                        id="profile-prompt-dob"
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Select Date"
                        selected={value}
                        className="input__date"
                        onChange={handleFormUpdate}
                        maxDate={moment().subtract(13, 'years').toDate()}
                        showYearDropdown
                        yearDropdownItemNumber={100} // Allows scrolling through 100 years
                        scrollableYearDropdown
                        customInput={
                            <input type="text" readOnly />
                        }
                        autoComplete="off"
                    />
                </div>

            </div>
            <PromptFooter
                value={value}
                error={error}
                isUpdating={isUpdating}
                handleClose={handleClose}
                handleSave={handleSave}
            />
        </>
    )
}

export default DateOfBirth;