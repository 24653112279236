import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

const TermsConditions = ({ modal, hideModal }) => {
  const history = useHistory();

  const text = modal ? `Terms & Conditions Apply` : `*Terms & Conditions Apply`;
  const textCSS = modal ? 'termsconditions__modaltext' : 'termsconditions__text';

  const handleConditionClick = () => {
    if (hideModal) hideModal();
    history.push('/terms');
  };

  return (
    <div className="cursor-pointer termsconditions">
      <span className={textCSS} onClick={handleConditionClick}>
        {text}
      </span>
    </div>
  );
};

TermsConditions.propTypes = {
  modal: PropTypes.bool,
  hideModal: PropTypes.func,
};
export default TermsConditions;
