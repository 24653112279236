import {
  TOGGLE_COMPANY_EDIT_MODAL,
  TOGGLE_REMOVE_COMPANY_ADMIN_MODAL,
  TOGGLE_MAKE_COMPANY_SUPER_ADMIN_MODAL,
} from "../../actions/common/constants";

const INITIAL_STATE = {
  edit: {
    isVisible: false,
    type: null, // edit | awards | admins | patents | publications(BOOKS)
  },
  admins: {
    remove: {
      isVisible: false,
      profile: null,
    },
    makeSuperAdmin: {
      isVisible: false,
      profile: null,
    },
  },
};

export default function companyModals(state = INITIAL_STATE, action) {
  switch (action.type) {
    case TOGGLE_COMPANY_EDIT_MODAL:
      return {
        ...state,
        edit: {
          type: action.payload || null,
          isVisible: !state.edit.isVisible,
        },
      };
    case TOGGLE_REMOVE_COMPANY_ADMIN_MODAL:
      return {
        ...state,
        admins: {
          ...state.admins,
          remove: {
            isVisible: !state.admins.remove.isVisible,
            profile: action.payload || null,
          },
        },
      };
    case TOGGLE_MAKE_COMPANY_SUPER_ADMIN_MODAL:
      return {
        ...state,
        admins: {
          ...state.admins,
          makeSuperAdmin: {
            isVisible: !state.admins.makeSuperAdmin.isVisible,
            profile: action.payload || null,
          },
        },
      };
    default:
      return state;
  }
}
