import { GET_PRODUCT_FILTERS_REQUEST,
    GET_PRODUCT_FILTERS_SUCCESS,
    GET_PRODUCT_FILTERS_FAILURE,
    RESET_PRODUCT_REVIEWS_PAGE,
    RESET_PRODUCT_REVIEWS,
    GET_PRODUCT_REVIEWS_SUCCESS,
    GET_PRODUCT_REVIEWS_REQUEST,
    DELETE_PRODUCT_REVIEW_COMMENTS_SUCCESS,
    GET_PRODUCT_REVIEWS_FAILURE,
    POST_PRODUCT_REVIEW_COMMMENT_SUCCESS } from "../../actions/products/constants";

const INITIAL_STATE = {
    fetching: false,
    hasFetched: false,
    all: [],
    hasEnd: false,
    page: 1
}

export default function reviews(state = INITIAL_STATE, action) {
    switch(action.type) {
        case GET_PRODUCT_REVIEWS_REQUEST:
            return {
                ...state,
                fetching: true
            };
        case GET_PRODUCT_REVIEWS_SUCCESS:
            return {
                ...state,
                hasFetched: true,
                all: state.page === 1
                    ? action.payload
                    : [...state.all, ...action.payload],
                page: state.page + 1,
                hasEnd: !action.payload.length || action.payload.length < 5,
                fetching: false
            }
        case GET_PRODUCT_REVIEWS_FAILURE:
            return {
                ...state,
                error: true,
                errorMsg: action.payload.error,
                fetching: false
            }
        case DELETE_PRODUCT_REVIEW_COMMENTS_SUCCESS:
            return {
                ...state,
                ...(state.hasFetched
                    && state.all.length
                    && {
                    all: state.all.map(_ => {
                        if (_.id !== action.payload.reviewId) return _;
                        return {
                            ..._,
                            comment_count: _.comment_count - 1 < 0
                                ? 0
                                : _.comment_count - 1
                        }
                    })
                })
            }
        case POST_PRODUCT_REVIEW_COMMMENT_SUCCESS:
            return {
                ...state,
                ...(state.hasFetched
                    && state.all.length
                    && {
                        all: state.all.map(_ => {
                            if (_.id !== action.payload.reviewId) return _;
                            return {
                                ..._,
                                comment_count: _.comment_count + 1
                            }
                        })
                    })
            }
        case RESET_PRODUCT_REVIEWS_PAGE:
            return {
                ...state,
                page: 1
            }
        case RESET_PRODUCT_REVIEWS:
            return INITIAL_STATE
        default:
            return state;
    }
}