import { GET_FOLLOWERS_LIST_REQUEST,
    GET_FOLLOWERS_LIST_SUCCESS,
    GET_FOLLOWERS_LIST_FAILURE,
    RESET_FOLLOWERS_LIST,
    GET_FOLLOWING_LIST_REQUEST,
    GET_FOLLOWING_LIST_SUCCESS,
    GET_FOLLOWING_LIST_FAILURE,
    RESET_FOLLOWING_LIST,
    ADD_TO_FOLLOWING_LIST,
    REMOVE_FROM_FOLLOWING_LIST,
    FOLLOW_USER_SUCCESS,
    UNFOLLOW_USER_SUCCESS } from '../../actions/profile/constants';
import { FOLLOW_COMPANY_SUCCESS,
    UNFOLLOW_COMPANY_SUCCESS } from '../../actions/company/constants';

const INITIAL_STATE = {
    followers: {
        page: 1,
        all: [],
        fetching: false,
        hasFetched: false,
    },
    following: {
        page: 1,
        all: [],
        fetching: false,
        hasFetched: false,
    }
}

const checkForFollowersPage = (state) => state.followers.hasFetched;

const checkForFollowingPage = (state) => state.following.hasFetched;

export default function followers(state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_FOLLOWERS_LIST_REQUEST:
        case GET_FOLLOWING_LIST_REQUEST:
            return {
                ...state,
                [action.payload.type]: {
                    ...state[action.payload.type],
                    fetching: true
                }
            };
        case GET_FOLLOWERS_LIST_SUCCESS:
        case GET_FOLLOWING_LIST_SUCCESS:
            return {
                ...state,
                [action.payload.type]: {
                    ...state[action.payload.type],
                    all: state[action.payload.type].page === 1
                        ? action.payload.data.profile
                        : [...state[action.payload.type].all, ...action.payload.data.profile],
                    page: state[action.payload.type].page + 1,
                    hasEndReached: !action.payload.data.profile.length,
                    hasFetched: true,
                    error: null,
                    fetching: false
                }
            }
        case GET_FOLLOWERS_LIST_FAILURE:
        case GET_FOLLOWING_LIST_FAILURE:
            return {
                ...state,
                [action.payload.type]: {
                    ...state[action.payload.type],
                    error: true,
                    errorMsg: action.error,
                    fetching: false
                }
            }
        case RESET_FOLLOWERS_LIST:
        case RESET_FOLLOWING_LIST:
            return {
                ...state,
                [action.payload.type]: INITIAL_STATE[action.payload.type]
            }
        case REMOVE_FROM_FOLLOWING_LIST:
            return {
                ...state,
                following: {
                    ...state.following,
                    all: state.following.all.map((profile) => profile.id !== action.payload)
                }
            }
        case ADD_TO_FOLLOWING_LIST:
            return {
                ...state,
                following: {
                    ...state.following,
                    all: [action.payload, ...state.following.all]
                }
            }
        case FOLLOW_USER_SUCCESS:
            return {
                ...state,
                following: checkForFollowingPage(state)
                    ? {
                        ...state.following,
                        all: state.following.all.map(profile => {
                            if (profile.id === action.payload.profileId
                                && !profile.profileId) {
                                    return {
                                        ...profile,
                                        isFollowing: true
                                    }
                            }
                            return profile
                        })
                    }
                    : state.following,
                followers: checkForFollowersPage(state)
                        ? {
                            ...state.followers,
                            all: state.followers.all.map(profile => {
                                if (profile.id === action.payload.profileId
                                    && !profile.profileId) {
                                        return {
                                            ...profile,
                                            isFollowing: true
                                        }
                                }
                                return profile
                            })
                        }
                        : state.followers
            }
        case UNFOLLOW_USER_SUCCESS:
                return {
                    ...state,
                    following: checkForFollowingPage(state)
                        ? {
                            ...state.following,
                            all: state.following.all.map(profile => {
                                if (profile.id === action.payload.profileId
                                    && !profile.profileId) {
                                        return {
                                            ...profile,
                                            isFollowing: false
                                        }
                                }
                                return profile
                            })
                        }
                        : state.following,
                    followers: checkForFollowersPage(state)
                        ? {
                            ...state.followers,
                            all: state.followers.all.map(profile => {
                                if (profile.id === action.payload.profileId
                                    && !profile.profileId) {
                                        return {
                                            ...profile,
                                            isFollowing: false
                                        }
                                }
                                return profile
                            })
                        }
                        : state.followers
                }
        case FOLLOW_COMPANY_SUCCESS:
            return {
                ...state,
                following: checkForFollowingPage(state)
                    ? {
                        ...state.following,
                        all: state.following.all.map(profile => {
                            if (profile.id === action.payload.companyId
                                && profile.profileId === action.payload.profileId) {
                                    return {
                                        ...profile,
                                        isFollowing: true
                                    }
                            }
                            return profile
                        })
                    }
                    : state.following,
                followers: checkForFollowersPage(state)
                    ? {
                        ...state.followers,
                        all: state.followers.all.map(profile => {
                            if (profile.id === action.payload.companyId
                                && profile.profileId === action.payload.companyId) {
                                    return {
                                        ...profile,
                                        isFollowing: true
                                    }
                            }
                            return profile
                        })
                    }
                    : state.followers
            }
        case UNFOLLOW_COMPANY_SUCCESS:
            return {
                ...state,
                following: checkForFollowingPage(state)
                    ? {
                        ...state.following,
                        all: state.following.all.map(profile => {
                            if (profile.id === action.payload.companyId
                                && profile.profileId === action.payload.profileId) {
                                    return {
                                        ...profile,
                                        isFollowing: false
                                    }
                            }
                            return profile
                        })
                    }
                    : state.following,
                followers: checkForFollowersPage(state)
                    ? {
                        ...state.followers,
                        all: state.followers.all.map(profile => {
                            if (profile.id === action.payload.companyId
                                && profile.profileId === action.payload.companyId) {
                                    return {
                                        ...profile,
                                        isFollowing: false
                                    }
                            }
                            return profile
                        })
                    }
                    : state.followers
            }
        default:
            return state;
    }
}