import { combineReducers } from 'redux';
import postModals from './posts';
import profileModals from './profile';
import collaboration from './collaboration';
import companyModals from './company';
import photoModal from './photo';
import businessModals from './business';
import promptModals from './prompts';

const modalReducer = combineReducers({
    postModals,
    profileModals,
    collaboration,
    companyModals,
    photoModal,
    businessModals,
    promptModals,
});

export default modalReducer;
