import { CHAT_SEARCH_REQUEST,
    CHAT_SEARCH_SUCCESS,
    CHAT_SEARCH_FAILURE,
    CLEAR_CHAT_SEARCH,
    SELECT_CHAT_ITEM,
    RESET_CHAT} from '../../actions/chat/constants';

const INITIAL_STATE = {
    fetching: false,
    error: false,
    errorMsg: null,
    groups: [],
    profiles: [],
    hasSearched: false,
    term: '',
    show: false,
    selectedChatItem: null
}

const getInitialState = () => INITIAL_STATE;

export default function chatSearch(state = getInitialState(), action) {
    switch (action.type) {
        case CHAT_SEARCH_REQUEST:
            return {
                ...state,
                fetching: true,
                error: false,
                errorMsg: null,
                hasSearched: true,
                term: action.payload.term,
                show: true,
            }
        case CHAT_SEARCH_SUCCESS:
            return {
                ...state,
                fetching: false,
                profiles: action.payload.profile && action.payload.profile.length ? action.payload.profile : [],
                groups: action.payload.groups && action.payload.groups.length ? action.payload.groups: []
            }
        case CHAT_SEARCH_FAILURE:
            return {
                ...state,
                fetching: false,
                error: true,
                errorMsg: action.error
            }
        case SELECT_CHAT_ITEM:
            return {
                ...INITIAL_STATE,
                selectedChatItem: action.payload,
            }
        case CLEAR_CHAT_SEARCH:
            return {
                ...INITIAL_STATE
            }
        case RESET_CHAT:
            return INITIAL_STATE
        default:
            return state;
    }
}