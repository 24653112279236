import { GET_PROFILE_ALLERGENS_REQUEST,
    GET_PROFILE_ALLERGENS_SUCCESS,
    GET_PROFILE_ALLERGENS_FAILURE, } from './constants';
import axios from 'axios';
import { onRequest, onSuccess, onError, getResponseData } from '../../utils/helper';
import { getBaseUrl, profileLinks } from '../../utils/links';
const CancelToken = axios.CancelToken;

// action to get all allergens
export let getAllergensCancelToken = undefined; // cancel token
export const getAllergens = (token) => {
    return dispatch => {
        if (getAllergensCancelToken) getAllergensCancelToken();
        dispatch(onRequest(GET_PROFILE_ALLERGENS_REQUEST));
        let url = `${getBaseUrl()}${profileLinks.allergens}`,
            options = {
                url,
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                cancelToken: new CancelToken((c) => getAllergensCancelToken = c)
            };
        return axios(options)
            .then(response => {
                let successData = getResponseData(response);
                if (successData) {
                    dispatch(onSuccess(GET_PROFILE_ALLERGENS_SUCCESS, successData))
                } else {
                    dispatch(onError(GET_PROFILE_ALLERGENS_FAILURE, response.data.errors))
                }
                return successData;
            })
            .catch(error => {
                dispatch(onError(GET_PROFILE_ALLERGENS_FAILURE, error))
            })
    }
}