import {
    REGISTER_USER_REQUEST,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_FAILURE
} from './constants';
import {
    getBaseUrl,
    loggedOutUrls
} from '../../utils/links';
import {
    onSuccess,
    onError,
    onRequest,
    handleAuthState
} from '../../utils/helper';
import axios from 'axios';
import { getShortProfileCurrentUser, getCurrentUser } from "../../actions/user";
import { async } from 'q';
// Register action

export const emailVerification = (data, token) => {
    let url = `${getBaseUrl()}${loggedOutUrls.profileEmailVerify}`,
        options = {
            url,
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        };
    return axios(options)
        .then(response => {
            let successData = response
                && response.data
                ? response.data
                : false;
            return successData;
        })
        .catch(_ => {
            return false;
        });
}
export const emailOtpVerification = (data, token, type) => {
    return async (dispatch) => {
        dispatch(onRequest(REGISTER_USER_REQUEST));
        let url = `${getBaseUrl()}${loggedOutUrls.profileEmailOtpVerify}`,
            options = {
                url,
                method: 'POST',
                data,
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            };
        try {
            const response = await axios(options);
            if (response.data.data) {
                if (type == "personal") {
                    await dispatch(getCurrentUser(token));
                } else {
                    dispatch(onSuccess(REGISTER_USER_SUCCESS, {
                        token: token
                    }));
                }
                return response.data;
            } else {
                if (type == "personal") {
                    return response.data
                } else {
                    dispatch(onError(REGISTER_USER_FAILURE, response.data.errors));
                    return response.data
                }

            }

        } catch (error) {

            dispatch(onError(REGISTER_USER_FAILURE, error));
            return false
        }

    }

}
export const register = (data) => {
    return dispatch => {
        dispatch(onRequest(REGISTER_USER_REQUEST));
        let url = `${getBaseUrl()}${loggedOutUrls.register}`,
            options = {
                url,
                method: 'POST',
                data
            };
        return axios(options)
            .then(response => {
                if (response && response.data && response.data.status === "success") {
                    return { status: true, data: response.data.result.token }
                } else {
                    return {
                        status: false,
                        error: response.data.errors
                            && response.data.errors
                            ? response.data.errors
                            : null
                    }
                }
            })
            .catch(error => {
                dispatch(onError(REGISTER_USER_FAILURE, error));
                return {
                    status: false,
                    error
                }
            })
    }
}