import React from 'react';
import PropTypes from 'prop-types';
import LoggedInBar from '../../components/bars/LoggedInBar';
import Footer from '../../components/Footer';
// import Tracker from '../../components/Tracker';
import { Redirect, Route } from 'react-router-dom';

function PrivateRoute({ component: Component, hideTopBar, withFooter, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        window.localStorage.getItem('authToken') ? (
          <>
            {!hideTopBar && <LoggedInBar />}
            <Component {...props} />
            {withFooter && <Footer />}
            {/* <Tracker /> */}
          </>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}

PrivateRoute.propTypes = {
  location: PropTypes.object,
  component: PropTypes.any,
  hideTopBar: PropTypes.bool,
  withFooter: PropTypes.bool,
};

export default PrivateRoute;
