import React, { Component } from 'react';
import Notifications from '../containers/loggedIn/Notifications/Notifications';

class NotificationPopUP extends Component {
  render() {
    return (
      <div>
        <Notifications isPopUp={true} />
      </div>
    );
  }
}

export default NotificationPopUP;
