import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import LoginForm from "./Login/LoginForm";
import DefaultLogin from "./Login/DefaultLogin";
import LoggedOutHome from "./LoggedOutHome";
import { linkedInSocial, socialLogin } from "../../actions/login/login";
import { miscLinks, onboardingLink, homeLink } from "../../utils/internalLinks";
import SeoHeadMeta from "../../components/misc/SeoHeadMeta";
import { seoTitles } from "../../utils/commonVariables";

function Login(props) {
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [loginVia, setLoginVia] = useState("");
  let path = props.location.state && props.location.state.from.pathname;
  useEffect(() => {
    document.querySelector("body").classList.add("overflow-hidden");
    return () => {
      document.querySelector("body").classList.remove("overflow-hidden");
    };
  });
  useEffect(() => {
    let currentSearchParams = new URL(document.location).searchParams;
    let linkedInCode = currentSearchParams.get("code");
    if (!showLoginForm && linkedInCode) {
      props.history.replace(miscLinks.login);
      linkedInSocial(linkedInCode).then((res) => {
        if (res) {
          props.socialLogin("linkedin", res).then((val) => {
            if (!val.status) {
              alert(val.error || "Something went wrong. Please try again?");
            } else {
              props.history.replace(
                val.newRegistered ? onboardingLink : homeLink
              );
            }
          });
        } else {
          alert("Something went wrong! Please try again.");
        }
      });
    }
  });
  function toggleLoginForm(type = "") {
    setShowLoginForm(!showLoginForm);
    setLoginVia(type);
  }
  return (
    <>
      <SeoHeadMeta title={seoTitles.loggedOut.login}>
        <title>{seoTitles.loggedOut.login}</title>
      </SeoHeadMeta>
      <LoggedOutHome />
      {showLoginForm ? (
        <LoginForm
          toggleLoginForm={toggleLoginForm}
          loginVia={loginVia}
          path={path}
        />
      ) : (
        <DefaultLogin toggleLoginForm={toggleLoginForm} />
      )}
    </>
  );
}

export default connect(null, { socialLogin })(Login);
