import { combineReducers } from 'redux';
import create from './create';
import view from './view';
import update from './update';
import admins from './admins';
import activity from './activity';
import photos from './photos';
import all from './all';

const companyReducer = combineReducers({
    create,
    activity,
    view,
    update,
    admins,
    photos,
    all
});

export default companyReducer;