import { getResponseData, onError, onSuccess } from "../../../utils/helper";
import { previewUrls, getBaseUrl, surveyLinks } from "../../../utils/links";
import get from "lodash/get";
import axios from "axios";
import { PREVIEW_USER_FAILURE, PREVIEW_USER_SUCCESS } from "../constants";

// OTPVerify
export const verifyPreviewOTP = (id, data) => {
    return (dispatch) => {
        let url = `${getBaseUrl()}${previewUrls.verifyPreviewOTP(id)}`,
            options = {
                url,
                method: 'POST',
                data: data,
            };
        return axios(options)
            .then((response) => {
                let hasError = get(response, 'data.errors.status', false);

                if (hasError) {
                    return get(response, 'data')
                }

                let successData = getResponseData(response);
                const authToken = successData.token;
                if (authToken) {
                    dispatch(onSuccess(PREVIEW_USER_SUCCESS, { tempToken: authToken }));
                }
                return successData;
            })
            .catch((error) => {
                dispatch(onError(PREVIEW_USER_FAILURE, error));
                return false;
            });
    };
};

// inviteList
export const inviteForPreview = (token, id, data, isSurvey) => {
    let url = isSurvey ? `${getBaseUrl()}${surveyLinks.shareSurvey(id)}` : `${getBaseUrl()}${previewUrls.sharePreview(id)}`,
        options = {
            url,
            headers: {
                'Authorization': `Bearer ${token}`
            },
            method: 'POST',
            data: data,
        };
    return axios(options)
        .then((response) => {
            let hasError = get(response, 'data.errors.status', false);
            if (hasError) {
                return get(response, 'data')
            } else if (!!isSurvey) {
                return response.data;
            } else {
                let successData = getResponseData(response);
                return successData;
            }
        })
        .catch((_) => {
            return false;
        });
};
