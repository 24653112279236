import React from 'react';
import get from 'lodash/get';
import CloseIcon from '../../../assets/images/common/modals/close.svg';
import DefaultAvatar from '../../../assets/images/default/avatar.jpg';

export const getProductTourSteps = (user, helpers, handleTourClose) => {
  return [
    {
      content: (
        <React.Fragment>
          <img
            src={get(user, 'user.profile.imageUrl') || DefaultAvatar}
            className="product-tour__first__image"
          />
          <p className="product-tour__first__title">Welcome</p>
          <p className="product-tour__first__subtitle">Explore TagTaste with a quick tour</p>
          <button
            className="cursor-pointer product-tour__first__startbutton"
            onClick={() => helpers.next()}
          >
            Start Tour
          </button>
          <div className="cursor-pointer product-tour__first__skipdiv">
            <button
              className="cursor-pointer product-tour__first__skipbutton"
              onClick={() => {
                helpers.skip();
                handleTourClose();
              }}
            >
              Skip
            </button>
          </div>
        </React.Fragment>
      ),
      placement: 'center',
      target: 'body',
      hideFooter: true,
      styles: {
        options: {
          width: 472,
        },
        buttonClose: {
          display: 'none',
        },
      },
    },
    {
      target: '.home-link',
      content: (
        <div className="product-tour__second">
          <div className="product-tour__second__header">
            <div className="product-tour__second__header__new">New</div>
            <span className="product-tour__second__header__title">Home Page</span>
            <img
              src={CloseIcon}
              className="cursor-pointer product-tour__second__header__close"
              alt="close tour"
              onClick={() => {
                helpers.skip();
                handleTourClose();
              }}
            />
          </div>
          <div className="product-tour__second__text">
            See what is happening on TagTaste, get quick access to polls, surveys, reviews,
            collaborations, contests etc.
          </div>
          <div className="product-tour__second__footer">
            <button
              className="cursor-pointer product-tour__second__footer__next"
              onClick={() => helpers.next()}
            >
              Next
            </button>
          </div>
        </div>
      ),
      placement: 'bottom',
      hideFooter: true,
      styles: {
        options: {
          width: 508,
        },
        buttonClose: {
          display: 'none',
        },
      },
    },
    {
      target: '#feed-link',
      content: (
        <div className="product-tour__second">
          <div className="product-tour__second__header">
            <span className="product-tour__second__header__title">Your Feed</span>
            <img
              src={CloseIcon}
              className="cursor-pointer product-tour__second__header__close"
              alt="close tour"
              onClick={() => {
                helpers.skip();
                handleTourClose();
              }}
            />
          </div>
          <div className="product-tour__second__text">
            Check out what is going on in your network. Like, share and comment on their activity.
          </div>
          <div className="product-tour__second__footer">
            <button
              className="cursor-pointer product-tour__second__footer__next"
              onClick={() => helpers.next()}
            >
              Next
            </button>
          </div>
        </div>
      ),
      placement: 'bottom',
      hideFooter: true,
      styles: {
        options: {
          width: 508,
        },
        buttonClose: {
          display: 'none',
        },
      },
    },
    {
      target: '.passbook-tour',
      content: (
        <div className="product-tour__second">
          <div className="product-tour__second__header">
          <div className="product-tour__second__header__new">New</div>
            <span className="product-tour__second__header__title">Your Passbook</span>
            <img
              src={CloseIcon}
              className="cursor-pointer product-tour__second__header__close"
              alt="close tour"
              onClick={() => {
                helpers.skip();
                handleTourClose();
              }}
            />
          </div>
          <div className="product-tour__second__text">
            Keep track of your transaction with Passbook
          </div>
          <div className="product-tour__second__footer">
            <button
              className="cursor-pointer product-tour__second__footer__next"
              onClick={() => helpers.next()}
            >
              Next
            </button>
          </div>
        </div>
      ),
      placement: 'bottom',
      hideFooter: true,
      styles: {
        options: {
          width: 508,
        },
        buttonClose: {
          display: 'none',
        },
      },
    },
    {
      target: '#messenger-link',
      content: (
        <div className="product-tour__second">
          <div className="product-tour__second__header">
            <span className="product-tour__second__header__title">Messages</span>
            <img
              src={CloseIcon}
              className="cursor-pointer product-tour__second__header__close"
              alt="close tour"
              onClick={() => {
                helpers.skip();
                handleTourClose();
              }}
            />
          </div>
          <div className="product-tour__second__text">
            Have individual or group conversations with people on TagTaste
          </div>
          <div className="product-tour__second__footer">
            <button
              className="cursor-pointer product-tour__second__footer__next"
              onClick={() => helpers.next()}
            >
              Next
            </button>
          </div>
        </div>
      ),
      placement: 'bottom',
      hideFooter: true,
      styles: {
        options: {
          width: 508,
        },
        buttonClose: {
          display: 'none',
        },
      },
    },
    {
      target: '#notification-link',
      content: (
        <div className="product-tour__second">
          <div className="product-tour__second__header">
            <span className="product-tour__second__header__title">Notifications</span>
            <img
              src={CloseIcon}
              className="cursor-pointer product-tour__second__header__close"
              alt="close tour"
              onClick={() => {
                helpers.skip();
                handleTourClose();
              }}
            />
          </div>
          <div className="product-tour__second__text">Check out all your notifications here.</div>
          <div className="product-tour__second__footer">
            <button
              className="cursor-pointer product-tour__second__footer__next"
              onClick={() => helpers.next()}
            >
              Next
            </button>
          </div>
        </div>
      ),
      placement: 'bottom',
      hideFooter: true,
      styles: {
        options: {
          width: 508,
        },
        buttonClose: {
          display: 'none',
        },
      },
    },
    {
      target: '#profile-link',
      content: (
        <div className="product-tour__second">
          <div className="product-tour__second__header">
            <span className="product-tour__second__header__title">You</span>
            <img
              src={CloseIcon}
              className="cursor-pointer product-tour__second__header__close"
              alt="close tour"
              onClick={() => {
                helpers.skip();
                handleTourClose();
              }}
            />
          </div>
          <div className="product-tour__second__text">
            Manage your profile & settings, get access to your companies, collaborations, surveys,
            passbook, etc.
          </div>
          <div className="product-tour__second__footer">
            <button
              className="cursor-pointer product-tour__second__footer__next"
              onClick={() => helpers.next()}
            >
              Next
            </button>
          </div>
        </div>
      ),
      placement: 'bottom',
      hideFooter: true,
      styles: {
        options: {
          width: 508,
        },
        buttonClose: {
          display: 'none',
        },
      },
    },
    {
      target: '#quick-links',
      content: (
        <div className="product-tour__second">
          <div className="product-tour__second__header">
            <div className="product-tour__second__header__new">New</div>
            <span className="product-tour__second__header__title">Quick Links</span>
            <img
              src={CloseIcon}
              className="cursor-pointer product-tour__second__header__close"
              alt="close tour"
              onClick={() => {
                helpers.skip();
                handleTourClose();
              }}
            />
          </div>
          <div className="product-tour__second__text">
            Get quick access to collaborations, reviews, polls, surveys, etc.
          </div>
          <div className="product-tour__second__footer">
            <button
              className="cursor-pointer product-tour__second__footer__next"
              onClick={() => {
                helpers.close();
                handleTourClose();
              }}
            >
              Got It
            </button>
          </div>
        </div>
      ),
      placement: 'right-start',
      disableScrolling: true,
      hideFooter: true,
      offset: 0,
      spotlightPadding: 0,
      styles: {
        options: {
          width: 508,
        },
        buttonClose: {
          display: 'none',
        },
      },
    },
  ];
};

export const getMobileProductTourSteps = (user, helpers, handleTourClose) => {
  return [
    {
      content: (
        <React.Fragment>
          <img
            src={get(user, 'user.profile.imageUrl') || DefaultAvatar}
            className="product-tour__first__image"
          />
          <p className="product-tour__first__title">Welcome</p>
          <p className="product-tour__first__subtitle">Explore TagTaste with a quick tour</p>
          <button
            className="cursor-pointer product-tour__first__startbutton"
            onClick={() => helpers.next()}
          >
            Start Tour
          </button>
          <div className="cursor-pointer product-tour__first__skipdiv">
            <button
              className="cursor-pointer product-tour__first__skipbutton"
              onClick={() => {
                helpers.skip();
                handleTourClose();
              }}
            >
              Skip
            </button>
          </div>
        </React.Fragment>
      ),
      placement: 'center',
      target: 'body',
      hideFooter: true,
      styles: {
        options: {
          width: 472,
        },
        buttonClose: {
          display: 'none',
        },
      },
    },
    {
      target: '.top-bar__hamburger',
      content: (
        <div className="product-tour__second">
          <div className="product-tour__second__header">
            <span className="product-tour__second__header__title">Menu</span>
            <img
              src={CloseIcon}
              className="cursor-pointer product-tour__second__header__close"
              alt="close tour"
              onClick={() => {
                helpers.skip();
                handleTourClose();
              }}
            />
          </div>
          <div className="product-tour__second__text">
            You can explore home, search and feed from here
          </div>
          <div className="product-tour__second__footer">
            <button
              className="cursor-pointer product-tour__second__footer__next"
              onClick={() => helpers.next()}
            >
              Next
            </button>
          </div>
        </div>
      ),
      placement: 'bottom',
      hideFooter: true,
      styles: {
        options: {
          width: 408,
        },
        buttonClose: {
          display: 'none',
        },
        tooltip: {
          left: 15,
        },
      },
    },
    {
      target: '.passbook-tour',
      content: (
        <div className="product-tour__second">
          <div className="product-tour__second__header">
          <div className="product-tour__second__header__new">New</div>
            <span className="product-tour__second__header__title">Your Passbook</span>
            <img
              src={CloseIcon}
              className="cursor-pointer product-tour__second__header__close"
              alt="close tour"
              onClick={() => {
                helpers.skip();
                handleTourClose();
              }}
            />
          </div>
          <div className="product-tour__second__text">
            Keep track of your transaction with Passbook
          </div>
          <div className="product-tour__second__footer">
            <button
              className="cursor-pointer product-tour__second__footer__next"
              onClick={() => helpers.next()}
            >
              Next
            </button>
          </div>
        </div>
      ),
      placement: 'bottom',
      hideFooter: true,
      styles: {
        options: {
          width: 508,
        },
        buttonClose: {
          display: 'none',
        },
      },
    },
    {
      target: '#mobile-notification-link',
      content: (
        <div className="product-tour__second">
          <div className="product-tour__second__header">
            <span className="product-tour__second__header__title">Notifications</span>
            <img
              src={CloseIcon}
              className="cursor-pointer product-tour__second__header__close"
              alt="close tour"
              onClick={() => {
                helpers.skip();
                handleTourClose();
              }}
            />
          </div>
          <div className="product-tour__second__text">Check out all your notifications here.</div>
          <div className="product-tour__second__footer">
            <button
              className="cursor-pointer product-tour__second__footer__next"
              onClick={() => helpers.next()}
            >
              Next
            </button>
          </div>
        </div>
      ),
      placement: 'bottom',
      hideFooter: true,
      styles: {
        options: {
          width: 508,
        },
        buttonClose: {
          display: 'none',
        },
      },
    },
    {
      target: '#profile-link',
      content: (
        <div className="product-tour__second">
          <div className="product-tour__second__header">
            <span className="product-tour__second__header__title">You</span>
            <img
              src={CloseIcon}
              className="cursor-pointer product-tour__second__header__close"
              alt="close tour"
              onClick={() => {
                helpers.skip();
                handleTourClose();
              }}
            />
          </div>
          <div className="product-tour__second__text">
            Manage your profile & settings, get access to your companies, collaborations, surveys,
            passbook, etc.
          </div>
          <div className="product-tour__second__footer">
            <button
              className="cursor-pointer product-tour__second__footer__next"
              onClick={() => helpers.next()}
            >
              Next
            </button>
          </div>
        </div>
      ),
      placement: 'bottom',
      hideFooter: true,
      styles: {
        options: {
          width: 508,
        },
        buttonClose: {
          display: 'none',
        },
        tooltip: {
          right: 0,
        },
      },
    },
    {
      target: '#mobilie-quick-links',
      content: (
        <div className="product-tour__second">
          <div className="product-tour__second__header">
            <div className="product-tour__second__header__new">New</div>
            <span className="product-tour__second__header__title">Quick Links</span>
            <img
              src={CloseIcon}
              className="cursor-pointer product-tour__second__header__close"
              alt="close tour"
              onClick={() => {
                helpers.skip();
                handleTourClose();
              }}
            />
          </div>
          <div className="product-tour__second__text">
            Get quick access to collaborations, reviews, polls, surveys, etc.
          </div>
          <div className="product-tour__second__footer">
            <button
              className="cursor-pointer product-tour__second__footer__next"
              onClick={() => {
                helpers.close();
                handleTourClose();
              }}
            >
              Got It
            </button>
          </div>
        </div>
      ),
      placement: 'bottom',
      disableScrolling: true,
      hideFooter: true,
      offset: 0,
      spotlightPadding: 5,
      styles: {
        options: {
          width: 508,
        },
        buttonClose: {
          display: 'none',
        },
      },
    },
  ];
};
