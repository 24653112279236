import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Avatar from '../user/Avatar';
import { companiesLink } from '../../utils/internalLinks';
import Slider from 'react-slick';
import ChevronRight from '../../assets/images/photos/chevron-right.svg';
import { followCompany } from '../../actions/company/follow';
import { screenBreakpoints } from '../../utils/commonVariables';
import Check from '../../assets/images/common/suggestions/check.svg';
import { decodeString } from '../../utils/helper';
import UserName from '../profile/UserName';

const sliderSettings = {
    dots: false,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: (<SliderArrow to="prev" />),
    nextArrow: (<SliderArrow to="next" />),
    centerMode: false
}

function SliderArrow(props){
    const { onClick,
    to,
    currentSlide,
    slideCount } = props,
    isDisabled = ((to === 'prev' && currentSlide === 0) || (to === 'next' && currentSlide === (slideCount - 3)));
    return (<button aria-hidden="true"
        aria-disabled={isDisabled}
        type="button"
        disabled={isDisabled}
        className={`suggestions__arrow suggestions__arrow--${to} ${isDisabled ? 'suggestions__arrow--disabled' : ''}`}
        onClick={onClick}>
            <img className={`suggestions__arrow__icon suggestions__arrow__icon--${to}`}
                src={ChevronRight} alt="go to next slide" />
    </button>)
}

const CompanySuggestionItem = (props) => {
    const { company, handleFollow } = props;
    if (!company) return null;
    const imageToShow = company.logo_meta
            ? JSON.parse(company.logo_meta).original_photo
            : '',
        name = decodeString(company.name),
        id = company.id,
        isFollowing = company.isFollowing;
    return <div className="suggestions__profile">
        <Link to={companiesLink.view(id)}>
            <Avatar alt={name}
                isCompany={true}
                className="suggestions__profile__img"
                src={imageToShow} />
        </Link>
        <div className="suggestions__profile__info">
            <Link to={companiesLink.view(id)} className={`suggestions__profile__name ${!!company.verified && 'suggestions__profile__name--verified'}`}>
                <UserName isVerified={!!company.verified}
                    name={name} />
            </Link>
            {
                !isFollowing
                ? <button className="suggestions__follow-btn" onClick={() => handleFollow(company)}>+ FOLLOW</button>
                : <p className="suggestions__following"><img src={Check} alt="is following" />Following</p>
            }
        </div>
    </div>
}

function CompanySuggestion(props) {
    const [suggestions, setSuggestions] = useState(props.suggestions || []),
        [subTitle, setSubTitle] = useState(props.subTitle || 'Suggested for you'),
        [token] = useState(props.token),
        [followRequest, setFollowRequest] = useState(false),
        [isMobile] = useState(window.innerWidth < screenBreakpoints.mob);
    function _handleFollow(company) {
        setFollowRequest(true);
        props.followCompany(1,
            company.id,
            token)
            .then(_ => {
                setFollowRequest(false);
            }); //API_ALERT: Sending 1 because backend doesnt check for profile id
    }
    useEffect(() => {
        setSuggestions(props.suggestions);
        setSubTitle(props.subTitle);
    }, [props.suggestions, props.title, props.subTitle]);
    if (!suggestions.length) return null;
    return (<div>
            <div className="suggestions__info">
                <p className="suggestions__info__title">{subTitle}</p>
            </div>
            {
                isMobile
                ? <div className="suggestions suggestions--profile">
                    {
                        suggestions.map((_, i) => {
                            return <CompanySuggestionItem company={_}
                                handleFollow={_handleFollow}
                                followRequest={followRequest}
                                key={`company-suggestion-${_.id}-${i}`}  />
                        })
                    }
                </div>
                : <Slider {...sliderSettings} className="suggestions">
                    {
                        suggestions.map((_, i) => {
                            return <CompanySuggestionItem company={_}
                                handleFollow={_handleFollow}
                                followRequest={followRequest}
                                key={`company-suggestion-${_.id}-${i}`}  />
                        })
                    }
                </Slider>
            }
        </div>)
};

function mapStateToProps(state) {
    return {
        token: state.login.token
    }
}
export default connect(mapStateToProps, { followCompany })(CompanySuggestion);