// This file is for maintaining session & prompts

import axios from "axios";
import { PROFILE_COMPLETION_GA, PROFILE_COMPLETION_GA_KEYS, PROFILE_KEYS, PROMPT_TYPE } from "../components/modals/profilePrompts/constants";
import { gaEvents } from "./events";

const profileSessionCount = 7;
const profileSessionTimer = 15;
const profileSessionTimerInSeconds = profileSessionTimer * 60 * 1000;

export const removeSessionInterceptor = (addSessionInterceptor) => {
    // Clean up interceptor
    axios.interceptors.response.eject(addSessionInterceptor);
    resetSession();
}

export const getProfileItemsToShow = (profile) => {
    if (!profile) return [];
    const availablePromptTypes = Object.values(PROFILE_KEYS);

    let promptsToShow = [];
    for (const key in availablePromptTypes) {
        if (!profile[availablePromptTypes[key]]
            || profile[availablePromptTypes[key]] === ''
            || (availablePromptTypes[key] === "phone" && profile.verified_phone === 0)) {
            promptsToShow.push(availablePromptTypes[key]);
        }
    }
    return [...new Set(promptsToShow)];
}

export const handleSession = () => {

    const currentTime = new Date().getTime(); // Current time in milliseconds

    // Retrieve session data from localStorage
    let sessionData = JSON.parse(localStorage.getItem('session')) || null;

    // const promptsToShow = getProfileItemsToShow(profile);
    // If session data doesn't exist, initialize it and add to localStorage
    if (!sessionData) {

        sessionData = {
            promptToShowIndex: 0,
            lastUpdatedAt: currentTime,
            sessionCount: 0,
            isPromptShownInCurrentSession: false,
        };

        // Add session data to localStorage
        setPromptDataInLocalStorage(sessionData);
    }

    // Check if it's time to update the session (15 minutes)
    if (currentTime - sessionData.lastUpdatedAt > profileSessionTimerInSeconds) {
        sessionData.sessionCount++;
        sessionData.lastUpdatedAt = currentTime;
        sessionData.isPromptShownInCurrentSession = false;
        // Update session data in localStorage
    }
    //this will be executed on all apis even if no session upgrade 
    setPromptDataInLocalStorage(sessionData);
}


export const resetSession = () => {
    localStorage.removeItem('session');
}

export const checkIfPromptToShow = ({ profileItemsToBeFilled, promptType, delay }) => {
    const delayTime = delay || 2000;
    return new Promise((resolve, reject) => {
        const session = JSON.parse(localStorage.getItem("session")) || false;
        if (!session) {
            resolve(false);
            return;
        }
        let shouldPromptShow = false;
        switch (promptType) {
            case PROMPT_TYPE.INTERACTION:
                shouldPromptShow = !!profileItemsToBeFilled.length;
                break;
            default:
                shouldPromptShow = !session.isPromptShownInCurrentSession
                    && !!profileItemsToBeFilled.length
                    && (session.sessionCount !== 0 && session.sessionCount % profileSessionCount === 0)
                break;
        }
        if (shouldPromptShow) {
            setTimeout(() => {
                resolve(shouldPromptShow);
            }, delayTime);
        } else {
            resolve(shouldPromptShow);
        }
    });
}

export const sendProfilePromptGA = (event, screenName, modelName, type, profileItemsToBeFilled) => {
    const sessionData = JSON.parse(localStorage.getItem("session")) || null;
    if (!sessionData) return;
    const { sessionCount, promptToShowIndex } = sessionData;
    const content = PROFILE_COMPLETION_GA_KEYS[profileItemsToBeFilled[promptToShowIndex]];
    if (!event || !content || !screenName || !modelName) return false;
    if (event === PROFILE_COMPLETION_GA.VIEW) {
        gaEvents.prompts.profile.view(content, sessionCount, screenName, modelName, type);
    } else if (event === PROFILE_COMPLETION_GA.SKIP) {
        gaEvents.prompts.profile.skip(content, sessionCount, screenName, modelName, type);
    } else if (event === PROFILE_COMPLETION_GA.INTERACT) {
        gaEvents.prompts.profile.interact(content, sessionCount, screenName, modelName, type);
    }
};

export const setPromptDataInLocalStorage = (session) => {
    if (!session) return;
    localStorage.setItem('session', JSON.stringify(session));
}
