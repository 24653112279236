import Axios from 'axios';
import {
  GET_PRODUCT_DETAILS_REQUEST,
  GET_PRODUCT_DETAILS_SUCCESS,
  GET_PRODUCT_DETAILS_FAILURE,
  RESET_PRODUCT_DETAILS,
  GET_PRODUCT_FOOD_SHOTS_REQUEST,
  GET_PRODUCT_FOOD_SHOTS_SUCCESS,
  GET_PRODUCT_FOOD_SHOTS_FAILURE,
  RESET_PRODUCT_FOOD_SHOTS,
} from './constants';
import axios from 'axios';
import {
  onRequest,
  onSuccess,
  onError,
  getResponseData,
  getResponseDataWithStatus,
} from '../../utils/helper';
import { getBaseUrl, productLinks, publicViewLinks, surveyLinks, tastingUrls } from '../../utils/links';
import { START_REVIEW_SUCCESS } from '../tasting/constants';
let CancelToken = axios.CancelToken;

// action to get product detail
export let getProductDetailsCancelToken = undefined;
export const getProductDetails = (productId, token) => {
  return (dispatch) => {
    if (getProductDetailsCancelToken) getProductDetailsCancelToken();
    dispatch(onRequest(GET_PRODUCT_DETAILS_REQUEST));
    let url = `${getBaseUrl()}${productLinks.single(productId)}`,
      options = {
        url,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        cancelToken: new CancelToken((c) => (getProductDetailsCancelToken = c)),
      };
    return axios(options)
      .then((response) => {
        let successData = getResponseData(response);
        if (successData) {
          dispatch(onSuccess(GET_PRODUCT_DETAILS_SUCCESS, successData));
        } else {
          dispatch(onError(GET_PRODUCT_DETAILS_FAILURE, response.data.errors));
        }
        return successData;
      })
      .catch((error) => {
        dispatch(onError(GET_PRODUCT_DETAILS_FAILURE, error));
        return false;
      });
  };
};

// resetting product details
export const resetProductDetails = () => ({ type: RESET_PRODUCT_DETAILS });

export let getProductFoodShotsCancelToken = undefined;
export const getProductFoodShots = (token, productId, isPublic) => {
  return (dispatch) => {
    if (getProductFoodShotsCancelToken) getProductFoodShotsCancelToken();
    dispatch(onRequest(GET_PRODUCT_FOOD_SHOTS_REQUEST));
    let url = isPublic
        ? `${getBaseUrl()}${publicViewLinks.foodShot(productId)}`
        : `${getBaseUrl()}${productLinks.foodShot(productId)}`,
      options = {
        url,
        ...(!isPublic && {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }),
        cancelToken: new CancelToken((c) => (getProductFoodShotsCancelToken = c)),
      };
    return axios(options)
      .then((response) => {
        let successData = getResponseData(response);
        if (successData) {
          dispatch(onSuccess(GET_PRODUCT_FOOD_SHOTS_SUCCESS, successData));
        } else {
          dispatch(onError(GET_PRODUCT_FOOD_SHOTS_FAILURE, response.data.errors));
        }
        return successData;
      })
      .catch((error) => {
        dispatch(onError(GET_PRODUCT_FOOD_SHOTS_FAILURE, error));
      });
  };
};

export const resetProductFoodShots = () => onSuccess(RESET_PRODUCT_FOOD_SHOTS);

// getting product filters
export let getProductReviewFiltersCancelToken = undefined;
export const getProductReviewFilters = (token, productId) => {
  if (getProductReviewFiltersCancelToken) getProductReviewFiltersCancelToken();
  let url = `${getBaseUrl()}${productLinks.filters(productId)}`,
    options = {
      url,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      cancelToken: new CancelToken((c) => (getProductReviewFiltersCancelToken = c)),
    };
  return axios(options)
    .then((response) => {
      return getResponseData(response);
    })
    .catch((_) => {
      return false;
    });
};

// getting similar products
export let getSimilarProductsCancelToken = undefined;
export const getSimilarProducts = (token, productId) => {
  if (getSimilarProductsCancelToken) getSimilarProductsCancelToken();
  let url = `${getBaseUrl()}${productLinks.similarProducts(productId)}`,
    options = {
      url,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      cancelToken: new CancelToken((c) => (getSimilarProductsCancelToken = c)),
    };
  return axios(options)
    .then((response) => {
      return getResponseData(response);
    })
    .catch((_) => {
      return false;
    });
};

// getProductMandatory Fields
export let getPublicReviewMandatoryFieldsCancelToken = undefined;
export const getPublicReviewMandatoryFields = (token, productId, params = {}) => {
  if (getPublicReviewMandatoryFieldsCancelToken) getPublicReviewMandatoryFieldsCancelToken();
  const url = `${getBaseUrl()}${productLinks.mandatoryItems(productId)}`;

  const options = {
    url,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
    cancelToken: new CancelToken((c) => (getPublicReviewMandatoryFieldsCancelToken = c)),
  };
  return Axios(options)
    .then((response) => {
      return getResponseDataWithStatus(response);
    })
    .catch(() => false);
};


export let startPrivateReviewCancelToken = undefined;
export const startPrivateReview = (token, collabId, batchId, data) => {
  return (dispatch) => {
    if (startPrivateReviewCancelToken) startPrivateReviewCancelToken();
    let url = `${getBaseUrl()}${tastingUrls.collaboration.startPrivateReview(collabId, batchId)}`,
      options = {
        url,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data,
        cancelToken: new CancelToken((c) => (startPrivateReviewCancelToken = c)),
      };
    return axios(options)
      .then((response) => {
        dispatch(onSuccess(START_REVIEW_SUCCESS, {...response.data, batchId: batchId, collabId: collabId}));
        return response.data;
      })
      .catch((_) => {
        return false;
      });
  }
};

export let postStartPublicReviewCancelToken = undefined;
export const startPublicReview = (token, productId, data) => {
  return (dispatch) => {
    if (postStartPublicReviewCancelToken) postStartPublicReviewCancelToken();
    let url = `${getBaseUrl()}${productLinks.startPublicReview(productId)}`,
      options = {
        url,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data,
        cancelToken: new CancelToken((c) => (postStartPublicReviewCancelToken = c)),
      };
    return axios(options)
      .then((response) => {
        dispatch(onSuccess(START_REVIEW_SUCCESS, {...response.data}));
        return response.data;
      })
      .catch((_) => {
        return false;
      });
  }
};

export let getCollabSubmissionsCancelToken = undefined;
export const getCollabSubmissions = (token, collabId,batchId,profileId, contentToShow) => {
  if (getCollabSubmissionsCancelToken) getCollabSubmissionsCancelToken();
  let url = contentToShow ? 
    `${getBaseUrl()}${tastingUrls.collaboration.collabSubmissions(collabId,batchId,profileId)}`:
    `${getBaseUrl()}${tastingUrls.collaboration.collabFlagLogSubmissions(collabId,batchId,profileId)}`,
    options = {
      url,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      cancelToken: new CancelToken((c) => (getCollabSubmissionsCancelToken = c)),
    };
  return axios(options)
    .then((response) => {
      return getResponseData(response);
    })
    .catch((_) => {
      return false;
    });
};

export let postFlagUnflagDataCancelToken = undefined;
export const postFlagUnflagData = (token, collabId,batchId,profileId, data) => {
  if (postFlagUnflagDataCancelToken) postFlagUnflagDataCancelToken();
  let url = `${getBaseUrl()}${tastingUrls.collaboration.collabFlagLogSubmissions(collabId,batchId,profileId)}`,
    options = {
      url,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
      cancelToken: new CancelToken((c) => (postFlagUnflagDataCancelToken = c)),
    };
  return axios(options)
    .then((response) => {
      return response;
    })
    .catch((_) => {
      return false;
    });
};


