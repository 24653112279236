import React, { useState, useEffect } from 'react';
import Modal from '../common/Modal';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getReportContentData,
    getReportContentDataCancelToken,
    reportContentOrProfileCancelToken,
    reportContentOrProfile,
    flagReportSuccess} from '../../../actions/flags/reports';
import Loader from '../../misc/Loader';
import RadioBoxOn from '../../../assets/images/reports/radio_on.svg';
import RadioBoxOff from '../../../assets/images/reports/radio_off.svg';
import { Link } from 'react-router-dom';
import { miscLinks } from '../../../utils/internalLinks';

const texts = {
    title: 'Tell us what happened',
    error: 'Something went wrong! Please try again later.',
    content: {
        successTitle: 'Thank you for reporting this post',
        success: 'We’ve received your feedback. Our moderators will check the post for the reported issues.',
    },
    profile: {
        successTitle: 'Thank you for reporting this profile',
        success: 'We’ve received your feedback. Our moderators will check the profile for the reported issues.',
    },
    company: {
        successTitle: 'Thank you for reporting this profile',
        success: 'We’ve received your feedback. Our moderators will check the profile for the reported issues.',
    }
}
const ReportContentItem = ({ reportType,
    handleChange,
    isSelected }) => {
    return (<li className="flag-content__list__item" onClick={() => handleChange(reportType.id)}>
        {<img className="flag-content__list__img"
            src={isSelected ? RadioBoxOn : RadioBoxOff}
            alt={isSelected ? 'selected' : 'selected' } />}
        <p className="flag-content__list__title">{reportType.name}</p>
    </li>)
}
function ReportContentModal(props) {
    const [reportContent, setReportContent] = useState(null),
        [selectedId, setSelectedId] = useState(null),
        [fetching, setFetching] = useState(false),
        [processing, setProcessing] = useState(false),
        [comment, setComment] = useState(''),
        [error, setError] = useState(false),
        [reportSuccess, setReportSuccess] = useState(false),
        [reportSuccessData, setReportSuccessData] = useState(null);
    const { token,
        type,
        flagReportSuccess,
        handleClose } = props;
    useEffect(() => {
        setFetching(true);
        getReportContentData(token, type)
        .then(_ => {
            setFetching(false);
            if (_) {
                setReportContent(_);
            }
        })
        return () => {
            if (getReportContentDataCancelToken) getReportContentDataCancelToken();
            if (reportContentOrProfileCancelToken) reportContentOrProfileCancelToken();
        }
    }, []);

    const _handlePostReport = () => {
        const { isShared,
            sharedId,
            postType,
            postId,
            cardData,
            company
        } = props;
        const dataToSend = {
            ...(isShared && {
                is_shared: 1,
                shared_id: sharedId,
                ...(cardData.sharedBy && {
                    reported_profile_id: cardData.sharedBy.id
                })
            }),
            ...(!isShared && {
                is_shared: 0,
                ...(company && {
                    reported_company_id: company.id,
                }),
                ...(cardData
                    && cardData.profile
                    && {
                    reported_profile_id: cardData.profile.id
                })
            }),
            content_type: postType,
            content_id: postId,
            report_type_id: selectedId,
            report_comment: comment
        }
        reportContentOrProfile(token,
            dataToSend,
            type)
            .then(_ => {
                setProcessing(false);
                if (_
                    && _.data
                    && _.status) {
                        setReportSuccess(true);
                        setReportSuccessData(_.data);
                } else {
                    setError(true);
                }
            });
    }

    const _handleClose = () => {
        if (reportSuccessData) {
            flagReportSuccess(reportSuccessData);
        }
        handleClose();
    }

    const _handleProfilReport = () => {
        const { view } = props;
        const dataToSend = {
            user_type: type,
            ...(type === 'profile' && {
                user_id: view.profile.profile.id
            }),
            ...(type === 'company' && {
                user_id: view.company.id
            }),
            report_type_id: selectedId,
            report_comment: comment
        }
        reportContentOrProfile(token,
            dataToSend,
            type)
            .then(_ => {
                setProcessing(false);
                if (_
                    && _.data
                    && _.status) {
                        setReportSuccess(true);
                } else {
                    setError(true);
                }
            });
    }

    const _handleReportContent = () => {
        if (processing) return;
        setProcessing(true);
        setError(false);
        if (type === 'content') {
            _handlePostReport();
        } else if (type === 'profile' || type === 'company') {
            _handleProfilReport();
        }
    }
    const reportItems = reportContent
        && reportContent.length
        ? reportContent.map((reportType, index) => <ReportContentItem key={`report-content-${index}`}
            handleChange={(selectedId) => setSelectedId(selectedId)}
            isSelected={reportType.id === selectedId}
            reportType={reportType}/>)
        : null;
    return <Modal handleClose={_handleClose}
        title={reportSuccess ? texts[type].successTitle : texts.title}
        className={`flag-content__modal ${reportSuccess || error ? 'flag-content__modal--centered' : ''}`}
        disabledClose={processing}
        showHeader={true}>
            {
                reportSuccess || error
                ? <p className="flag-content__title">{ error ? texts.error : texts[type].success }</p>
                : <>
                {
                        fetching && <Loader isCentered={true}
                            withMargin={true} />
                    }
                    {
                        !fetching
                        && <>
                            <p className="flag-content__title flag-content__title--secondary">Please select a problem to continue</p>
                            {
                                reportItems && <ul className="flag-content__list">{reportItems}</ul>
                            }
                            {
                                selectedId && <textarea onChange={e => setComment(e.target.value)}
                                    placeholder="Add comment..."
                                    maxLength="300"
                                    className="flag-content__textarea"
                                    value={comment} />
                            }
                            <p className="flag-content__helper"><Link target="_blank" rel="noopener noreferrer" to={miscLinks.communityGuidelines}>Click here</Link> for our community guidelines.</p>
                            { error && <p className="error-text">Something went wrong! Please try again.</p> }
                            <div className="default-modal__footer default-modal__footer--bottom">
                                <button onClick={_handleReportContent}
                                    disabled={!selectedId || processing}
                                    className={`biz__modal__done-btn cursor-pointer ${(!selectedId || processing)
                                        ? 'biz__modal__done-btn--disabled'
                                        : ''}`}>
                                    Report
                                </button>
                            </div>
                        </>
                    }
                </>
            }
    </Modal>
};

function mapStateToProps(state) {
    return {
        token: state.login.token,
        user: state.user
    }
}

const mapDispatchToProps = {
    flagReportSuccess
}

ReportContentModal.propTypes = {
    handleClose: PropTypes.func,
    handleSave: PropTypes.func,
    type: PropTypes.string,
    isOpen: PropTypes.bool
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportContentModal);