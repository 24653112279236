import { combineReducers } from 'redux';
import categories from './categories';
import types from './types';
import questionnaire from './questionnaire';
import cities from './cities';
import tastingMethodologies from './tastingMethodologies';
import batches from './batches';
import userBatches from './userBatches';
import applicants from './applicants';
import productApplicants from './productApplicants';
import filters from './filters';
import reports from './reports';
import roles from './roles';
import outlet from './outlets';

const productReview = combineReducers({
    categories,
    types,
    questionnaire,
    cities,
    tastingMethodologies,
    batches,
    userBatches,
    applicants,
    productApplicants,
    filters,
    reports,
    roles,
    outlet
});

export default productReview;