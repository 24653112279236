import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import CloseIcon from "../../../assets/images/common/modals/close.svg";

function RemoveEntity(props) {
  const [updating, setUpdating] = useState(false),
    [title] = useState(props.title || "Remove"),
    [description] = useState(
      props.description || "Are you sure you want to delete?"
    ),
    [errorRemoving, setErrorRemoving] = useState(props.errorRemoving),
    { isHTML, centered, subAction, subActionText, buttonTitle } = props;
  function _handleClick() {
    const { handleClick } = props;
    handleClick();
  }
  useEffect(() => {
    // document.querySelector("body").classList.add("overflow-hidden");
    // return () => {
    //   document.querySelector("body").classList.remove("overflow-hidden");
    // };
    document.body.style.overflow = "hidden";
    return () => {
      //enable body scroll when modal is closed
      document.body.style.overflow = "auto";
    };
  }, []);

  useEffect(() => {
    setUpdating(props.removing);
    setErrorRemoving(props.errorRemoving);
  }, [props.removing, props.errorRemoving]);

  const { handleClose } = props;
  return (
    <div
      className={`default-modal remove-entity-modal ${centered ? "default-modal--centered" : ""}`}
    >
      <div className="default-modal__backdrop">
        <div className="default-modal__container">
          <div className="default-modal__header">
            <p className="default-modal__header__title">{title}</p>
            <button
              type="button"
              className="default-modal__header__close"
              onClick={() => {
                if (updating) return;
                handleClose();
              }}
            >
              <img src={CloseIcon} alt="close modal" />
            </button>
          </div>
          <div className="default-modal__content">
            {isHTML ? (
              <div
                className="admin-modal__remove__title"
                dangerouslySetInnerHTML={{
                  __html: !errorRemoving
                    ? description
                    : "Something went wrong! Please try again.",
                }}
              />
            ) : (
              <p className="admin-modal__remove__title">
                {!errorRemoving
                  ? description
                  : "Something went wrong! Please try again."}
              </p>
            )}
          </div>
          {!errorRemoving && (
            <div className="default-modal__footer1 default-modal__footer1--multi default-modal__footer1--bottom">
              <button
                type="button"
                disabled={updating}
                className={`profile-edit-modal__btn profile-edit-modal__btn--secondary
                                ${updating
                    ? "profile-edit-modal__btn--disabled"
                    : ""
                  }`}
                onClick={subAction ? subAction : handleClose}
              >
                {subActionText ? subActionText : "Cancel"}
              </button>
              <button
                className={`default-modal__remove-btn
                            ${updating
                    ? "default-modal__remove-btn--disabled"
                    : ""
                  }`}
                disabled={updating}
                onClick={_handleClick}
                type="button"
              >
                {buttonTitle || "Remove"}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

RemoveEntity.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  handleClick: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  isHTML: PropTypes.bool,
  buttonTitle: PropTypes.string,
  centered: PropTypes.bool,
  subAction: PropTypes.func,
  subActionText: PropTypes.string,
};

export default RemoveEntity;
