import {
    GET_NOTIFICATIONS_REQUEST,
    GET_NOTIFICATIONS_SUCCESS,
    GET_NOTIFICATIONS_FAILURE,
    RESET_NOTIFICATIONS,
    MARK_ALL_NOTIFICATIONS_READ_REQUEST,
    MARK_ALL_NOTIFICATIONS_READ_SUCCESS,
    MARK_ALL_NOTIFICATIONS_READ_FAILURE,
    NOTIFICATION_READ_REQUEST,
    NOTIFICATION_READ_SUCCESS,
    NOTIFICATION_READ_FAILURE,
    MARK_NOTIFICATIONS_SEEN_REQUEST,
    MARK_NOTIFICATIONS_SEEN_SUCCESS,
    MARK_NOTIFICATIONS_SEEN_FAILURE,
    MARK_CHAT_NOTIFICATIONS_SEEN_REQUEST,
    MARK_CHAT_NOTIFICATIONS_SEEN_SUCCESS,
    MARK_CHAT_NOTIFICATIONS_SEEN_FAILURE,
    READ_NOTIFICATION_FROM_SOCKET,
    UPDATE_PROFILE_UNREAD_CHAT_NOTIFICATION_COUNT,
    UPDATE_PROFILE_UNREAD_NOTIFICATION_COUNT,
    UPDATE_NOTIFICATION_STATUS
} from './constants';
import Axios from 'axios';
import {
    onRequest,
    onSuccess,
    onError,
    getResponseData
} from '../../utils/helper';
import { getBaseUrl, notificationsLinks } from '../../utils/links';
let CancelToken = Axios.CancelToken;

// action to get notifications
export let getNotificationsCancelToken = undefined;
export const getNotifications = (page = 1,
    token) => {
    return dispatch => {
        if (getNotificationsCancelToken) getNotificationsCancelToken();
        dispatch(onRequest(GET_NOTIFICATIONS_REQUEST));
        let url = `${getBaseUrl()}${notificationsLinks.all}`,
            options = {
                url,
                params: {
                    page
                },
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                cancelToken: new CancelToken((c) => getNotificationsCancelToken = c)
            };
        return Axios(options)
            .then(response => {
                let successData = getResponseData(response);
                if (successData) {
                    dispatch(onSuccess(GET_NOTIFICATIONS_SUCCESS, successData));
                } else {
                    dispatch(onError(GET_NOTIFICATIONS_FAILURE, response.data.errors));
                }
                return successData;
            })
            .catch(error => {
                dispatch(onError(GET_NOTIFICATIONS_FAILURE, error));
                return false;
            })
    }
}

// action to reset notifications
export const resetNotifications = () => ({ type: RESET_NOTIFICATIONS });

// action to show notification count on top bar
export const updateNotificationCount = () => ({ type: UPDATE_PROFILE_UNREAD_NOTIFICATION_COUNT });
//action to show Message count on top bar
export const updateChatNotificationCount = () => ({ type: UPDATE_PROFILE_UNREAD_CHAT_NOTIFICATION_COUNT });

// action to mark all as read
export let markAllNotificationsAsReadCancelToken = undefined;
export const markAllNotificationsAsRead = (token) => {
    return dispatch => {
        dispatch(onRequest(MARK_ALL_NOTIFICATIONS_READ_REQUEST));
        let url = `${getBaseUrl()}${notificationsLinks.markAllAsRead}`,
            options = {
                url,
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                cancelToken: new CancelToken((c) => markAllNotificationsAsReadCancelToken = c)
            };
        return Axios(options)
            .then(response => {
                let successData = getResponseData(response);
                if (successData) {
                    dispatch(onSuccess(MARK_ALL_NOTIFICATIONS_READ_SUCCESS, successData));
                } else {
                    dispatch(onSuccess(MARK_ALL_NOTIFICATIONS_READ_FAILURE, response.data.errors));
                }
                return successData;
            })
            .catch(error => {
                dispatch(onError(MARK_ALL_NOTIFICATIONS_READ_FAILURE, error));
                return false;
            })
    }
}

// marking notfication read
export let markNotificationReadCancelToken = undefined;
export const markNotificationRead = (notificationId,
    token) => {
    return dispatch => {
        if (markNotificationReadCancelToken) markNotificationReadCancelToken();
        dispatch(onRequest(NOTIFICATION_READ_REQUEST));
        let url = `${getBaseUrl()}${notificationsLinks.markRead(notificationId)}`,
            options = {
                url,
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                cancelToken: new CancelToken((c) => markNotificationReadCancelToken = c)
            };
        return Axios(options)
            .then(response => {
                dispatch(onSuccess(NOTIFICATION_READ_SUCCESS, {
                    data: response.data.data,
                    notificationId
                }))
            })
            .catch(error => {
                dispatch(onError(NOTIFICATION_READ_FAILURE, error));
                return false;
            })
    }
}

// marking notification seen
export let markNotificationSeenCancelToken = undefined;
export const markNotificationSeen = (token) => {
    return dispatch => {
        if (markNotificationSeenCancelToken) markNotificationSeenCancelToken();
        dispatch(onRequest(MARK_NOTIFICATIONS_SEEN_REQUEST));
        let url = `${getBaseUrl()}${notificationsLinks.notificationSeen}`,
            options = {
                url,
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                cancelToken: new CancelToken((c) => markNotificationSeenCancelToken = c)
            };
        return Axios(options)
            .then(response => {
                let successData = getResponseData(response);
                if (successData) {
                    dispatch(onSuccess(MARK_NOTIFICATIONS_SEEN_SUCCESS, successData))
                } else {
                    dispatch(onError(MARK_NOTIFICATIONS_SEEN_FAILURE, response.data.errors));
                }
                return successData
            })
            .catch(error => {
                dispatch(onError(MARK_NOTIFICATIONS_SEEN_FAILURE, error));
                return false;
            })
    }
}

export let markChatNotificationSeenCancelToken = undefined;
export const markChatNotificationSeen = (token) => {
    return dispatch => {
        if (markNotificationSeenCancelToken) markNotificationSeenCancelToken();
        dispatch(onRequest(MARK_CHAT_NOTIFICATIONS_SEEN_REQUEST));
        let url = `${getBaseUrl()}${notificationsLinks.messageSeen}`,
            options = {
                url,
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                cancelToken: new CancelToken((c) => markNotificationSeenCancelToken = c)
            };
        return Axios(options)
            .then(response => {
                let successData = getResponseData(response);
                if (successData) {
                    dispatch(onSuccess(MARK_CHAT_NOTIFICATIONS_SEEN_SUCCESS, successData))
                } else {
                    dispatch(onError(MARK_CHAT_NOTIFICATIONS_SEEN_FAILURE, response.data.errors));
                }
                return successData
            })
            .catch(error => {
                dispatch(onError(MARK_CHAT_NOTIFICATIONS_SEEN_FAILURE, error));
                return false;
            })
    }
}

// action to read notification from socket
export const readNotificationFromSocket = (payload) => {
    return {
        type: READ_NOTIFICATION_FROM_SOCKET,
        payload
    }
}

// action to update notification item
export const UpdateNotificationItem = (payload) => {
    return {
        type: UPDATE_NOTIFICATION_STATUS,
        payload
    }
}
