import React from 'react';
import PropTypes from 'prop-types';
import InView from 'react-intersection-observer';

function InfinteScroll({ isHidden,
    callbackFunc}) {
    if (isHidden || !callbackFunc) return null;
    const handleInView = (inView) => {
        if (inView) {
            callbackFunc();
        }
    }
    return (<InView as="div"
        onChange={(inView) => handleInView(inView)} >
            <div style={{minHeight:'20px'}}></div>
        </InView>)
};

InfinteScroll.propTypes = {
    isHidden: PropTypes.bool,
    callbackFunc: PropTypes.func.isRequired,
}

export default InfinteScroll;