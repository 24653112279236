import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { SliderArrow } from '../utils';

const CarouselContent = (props) => {
  const { children, gaFn } = props;
  const sliderSettings = {
    dots: false,
    infinite: false,
    autoplay: false,
    slidesToShow: 2.2,
    slidesToScroll: 1,
    speed: 500,
    cssEase: 'linear',
    prevArrow: <SliderArrow to="prev" gaFn={gaFn} />,
    nextArrow: <SliderArrow to="next" gaFn={gaFn} />,
    pauseOnHover: false,
  };
  return (
    <div className="home__card__content home__imagecarousel">
      <Slider {...sliderSettings} className="suggestions ">
        {children}
      </Slider>
    </div>
  );
};

CarouselContent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  gaFn: PropTypes.func,
};

export default CarouselContent;
