import { AccordionToggle } from 'react-bootstrap';
import { GET_COLLABORATION_QUESTIONNAIRE_REQUEST,
    GET_COLLABORATION_QUESTIONNAIRE_SUCCESS,
    GET_COLLABORATION_QUESTIONNAIRE_FAILURE,
    UPDATE_COLLAB_QUESTIONNAIRE_REQUEST,
    UPDATE_COLLAB_QUESTIONNAIRE_SUCCESS,
    UPDATE_COLLAB_QUESTIONNAIRE_FAILURE,
    RESET_QUESTIONNAIRES } from '../../../actions/collaborations/constants';

const INITIAL_STATE = {
    all: [],
    fetching: false,
    error: null,
    uploading: false,
    uploadingError: null,
    page: 1,
    count: 0,
    hasFetched: false
}

export default function questionnaire(state = INITIAL_STATE, action) {
    switch(action.type) {
        case  GET_COLLABORATION_QUESTIONNAIRE_REQUEST:
            return {
                ...state,
                fetching: true
            }
        case  GET_COLLABORATION_QUESTIONNAIRE_SUCCESS:
            return {
                ...state,
                all: [...state.all, ...action.payload.questionnaire],
                fetching: false,
                page: state.page + 1,
                hasEnd: !action.payload.questionnaire.length,
                count: action.payload.count,
                hasFetched: true
            }
        case  GET_COLLABORATION_QUESTIONNAIRE_FAILURE:
            return {
                ...state,
                fetching: false,
                error: true,
                errorMsg: action.error
            }
        case UPDATE_COLLAB_QUESTIONNAIRE_REQUEST:
            return {
                ...state,
                uploading: true
            }
        case UPDATE_COLLAB_QUESTIONNAIRE_SUCCESS:
            return {
                ...state,
                uploading: false,
                uploadingError: null
            }
        case UPDATE_COLLAB_QUESTIONNAIRE_FAILURE:
            return {
                ...state,
                uploading: false,
                uploadingError: action.error
            }
        case RESET_QUESTIONNAIRES:
            return INITIAL_STATE
        default:
            return state;
    }
}