import React from 'react';
import PropTypes from 'prop-types';
import PostAd from './PostAd';
import ImageAd from './ImageAd';

const Cards = {
    post: PostAd,
    image: ImageAd
}

function CommonAd(props) {
    const { cardData,
        comments,
        showOptionsIcon } = props,
        { advertisement } = cardData,
        ComponentCard = advertisement && advertisement.type ? Cards[advertisement.type] : null;
    if (!ComponentCard) return null;
    return <ComponentCard showOptionsIcon={showOptionsIcon}
        comments={comments}
        adData={advertisement} />
};

CommonAd.propTypes = {
    cardData: PropTypes.object,
    showOptionsIcon: PropTypes.bool,
    comments: PropTypes.object
}

export default CommonAd;