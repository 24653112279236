import { combineReducers } from 'redux';
import feed from './feed';
import comments from './comments';
import preview from './preview';
import uploading from './uploading';

const feedReducer = combineReducers({
    feed,
    comments,
    preview,
    uploading
})

export default feedReducer;