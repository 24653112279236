import {  GET_PROFILE_PHOTO_REQUEST,
    GET_PROFILE_PHOTO_SUCCESS,
    GET_PROFILE_PHOTO_FAILURE,
    RESET_PROFILE_PHOTOS,
    SET_PROFILE_PHOTO_DATA
} from '../../actions/profile/constants';

const INITIAL_STATE = {
    fetching: false,
    error: false,
    errorMsg: null,
    photos: null,
    page: 1
}

export default function photos(state = INITIAL_STATE, action)  {
    switch (action.type) {
        case GET_PROFILE_PHOTO_REQUEST:
            return {
                ...state,
                fetching: true,
            }
        case GET_PROFILE_PHOTO_SUCCESS:
            return {
                ...state,
                photos: state.page === 1
                    ? action.payload
                    : [...state.photos, ...action.payload],
                fetching: false,
                page: state.page + 1,
                hasEndReached: action.payload.length === 0
            }
        case SET_PROFILE_PHOTO_DATA:
            return {
                ...state,
                photos: action.payload,
                page: state.page + 1
            }
        case GET_PROFILE_PHOTO_FAILURE:
            return {
                ...state,
                fetching: false,
                error: true,
                errorMsg: action.error
            }
        case RESET_PROFILE_PHOTOS:
            return INITIAL_STATE;
        default:
            return state;
    }
}