import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CloseIcon from '../../../assets/images/common/modals/close.svg';
import ImageUploader from '../../misc/ImageUploader';
import { updloadDocuments } from '../../../actions/common/modals';
import { Link } from 'react-router-dom';
import { miscLinks } from '../../../utils/internalLinks';
import { replaceNullData, replaceImageIndex } from '../../../utils/collaborationHelper';

const texts = {
    modalHead: 'Age Proof',
    title: 'Please upload any of the folowing documents clearly showing your date of birth for verification purposes. We will never share these documents with anyone.',
    lists: {
        title: 'Any of the following will do:',
        items: ['PAN Card', 'Driving Licence','Passport', `Voter's ID Card`]
    },
    subTitle: 'Upload Document'
}
function AlcoholDocumentProof(props) {
    const { updloadDocuments,
        user: {user: {profile}},
        toggleShowInterestModal,
        closeModal,
        toggleTermsAccepted,
        isShowInterest,
        isMandatoryItem,
        addToFilledItems,
        removeFromFilledItems,
        termsAccepted,
        token } = props;
    let defaultFrontSide = profile.document_meta
        && profile.document_meta.images
        && profile.document_meta.images[0]
        ? profile.document_meta.images[0].original_photo
        :  '';
    let defaultBackSide = profile.document_meta
        && profile.document_meta.images
        && profile.document_meta.images[1]
        ? profile.document_meta.images[1].original_photo
        :  '';
    let defaultImages = profile.document_meta
        && profile.document_meta.images
        ? profile.document_meta.images.map((image) => JSON.stringify(image))
        : [];
    const [frontSide, setFrontSide] = useState(defaultFrontSide);
    const [backSide, setBackSide] = useState(defaultBackSide);
    const [images, setImages] = useState(defaultImages);
    const [isChecked, setIsChecked] = useState(termsAccepted);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isImageChanged, setIsImageChanged] = useState(false);

    useEffect(() => {
       let currentIsDisabled = isShowInterest
            ? !frontSide.length || !isChecked
            : !frontSide.length && !!backSide.length
            ? true
            : false;
        if (isMandatoryItem
            && removeFromFilledItems
            && currentIsDisabled) {
            removeFromFilledItems('document_meta');
        }
       setIsDisabled(currentIsDisabled );
    }, [frontSide,
        backSide,
        isChecked]);

    function handleUpdate(imgObj, index, isRemoved = false) {
        if(isRemoved) {
            let newImages = images;
            newImages = replaceImageIndex(newImages, index);
            setImages(newImages);
            if (isMandatoryItem && removeFromFilledItems) {
                removeFromFilledItems('document_meta');
            }
        }
        if (imgObj.hasOwnProperty('frontSide')) {
            setFrontSide(imgObj.frontSide);
        } else if(imgObj.hasOwnProperty('backSide')) {
            setBackSide(imgObj.backSide);
        }
        setIsImageChanged(true);
    }
    function handleImageResponse(obj) {
        obj.hasOwnProperty('frontSide')
            ? images[0] = JSON.stringify(obj['frontSide'])
            : images[1] = JSON.stringify(obj['backSide']);
        setImages(images);
    }
    function submitDocuments() {
        let data = { images : [...replaceNullData(images)]};
        if(isImageChanged) {
            updloadDocuments(data, token)
                .then(() => {
                    if(isShowInterest) {
                        toggleShowInterestModal();
                    }
                    if (addToFilledItems
                        && data.images.length
                        && isMandatoryItem) {
                        addToFilledItems('document_meta', true);
                    }
                    closeModal();
                })
        } else {
            if(isShowInterest) {
                toggleShowInterestModal();
            }
            closeModal();
        }

    }
    function handleCheck() {
        setIsChecked(!isChecked);
        toggleTermsAccepted();
    }
    return (<div className="default-modal document-upload-modal">
        <div className="default-modal__backdrop">
            <div className="default-modal__container">
                <div className="default-modal__header">
                    <p className="default-modal__header__title">{texts.modalHead}</p>
                    <button className="default-modal__header__close">
                        <img src={CloseIcon} onClick={props.closeModal} alt="close modal" />
                    </button>
                </div>
                <div className="default-modal__content document-upload-modal">
                    <p className="document-upload-modal__title">{texts.title}</p>
                    <p className="document-upload-modal__sub-title">Any of the following will do:</p>
                    <ul className="document-upload-modal__list">
                        { texts.lists.items.map((item, key) => <li key={key} className="document-upload-modal__list__item">{item}</li>) }
                    </ul>
                    <p className="document-upload-modal__documents__title">Upload Document</p>
                    <div className="document-upload-modal__documents__container">
                        <div>
                            <ImageUploader
                                isGrayPlaceholder={true}
                                isBluePlaceholder={true}
                                name="frontSide"
                                nameToDisplay="Front Side"
                                id="front_side"
                                handleUpdate={handleUpdate}
                                handleImageResponse={handleImageResponse}
                                imageIndex={0}
                                images={frontSide} />
                        </div>
                        <div>
                            <ImageUploader
                                isGrayPlaceholder={false}
                                isBluePlaceholder={true}
                                name="backSide"
                                nameToDisplay="Back Side"
                                id="back_side"
                                handleUpdate={handleUpdate}
                                handleImageResponse={handleImageResponse}
                                imageIndex={1}
                                images={backSide} />
                        </div>
                    </div>
                    {
                        isShowInterest
                        && <div className="document-upload-modal__checkbox-container">
                            <input type="checkbox"
                                className="document-upload-modal__checkbox-container__checkbox"
                                checked={isChecked}
                                onChange={handleCheck}/>
                            <label className="document-upload-modal__checkbox-container__text">Check to indicate that you have read and agree to the terms presented in the <Link to={miscLinks.terms} target="_blank" className="document-upload-modal__checkbox-container__link">Terms and Conditions</Link> agreement.</label>
                        </div>
                    }

                </div>
                <div className="default-modal__footer document-upload-modal__footer">
                    <button className="default-modal__footer__clear-btn" onClick={props.closeModal}>
                        <span className="default-modal__footer__clear-btn__text">Cancel</span>
                    </button>
                    <button className={`default-modal__footer__action-btn
                        default-modal__footer__action-btn--primary
                        ${isDisabled ? 'default-modal__footer__action-btn--disabled' : ''}`}
                        disabled={isDisabled}
                        onClick={submitDocuments}>
                        <span className="default-modal__footer__action-btn__text">{isShowInterest ? 'Proceed' : 'Save'}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>)
};

function mapStateToProps(state) {
    return {
        token: state.login.token,
        user: state.user
    }
}

const mapDispatchToProps = {
    updloadDocuments
}

AlcoholDocumentProof.propTypes = {
    updloadDocuments: PropTypes.func,
    isMandatoryItem: PropTypes.bool,
    addToFilledItems: PropTypes.func,
    removeFromFilledItems: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(AlcoholDocumentProof);