import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { socialLogin } from '../../actions/login/login';
import { onboardingLink, homeLink } from '../../utils/internalLinks';

function SocialLogin(props) {
  function _handleLoginOrSignUp(provider, params) {
    props.socialLogin(provider, params).then((val) => {
      if (!val.status) {
        alert(val.error || 'Something went wrong. Please try again?');
      } else {
        props.history.replace({
          pathname: val.newRegistered
            ? onboardingLink
            : props.publicPageLink
            ? props.publicPageLink
            : homeLink,
          ...(val.newRegistered && {
            state: {
              publicPageLink: props.publicPageLink || '',
            },
          }),
        });
      }
    });
  }
  function _handleGoogleLogin() {
    if (window.auth2) {
      window.auth2
        .signIn()
        .then(() => {
          const googleProfile = window.auth2.currentUser.get().getBasicProfile(),
            id = googleProfile.getId(),
            name = googleProfile.getName(),
            imageUrl = googleProfile.getImageUrl(),
            email = googleProfile.getEmail(),
            token = window.auth2.currentUser.get().getAuthResponse().access_token,
            data = {
              id,
              name,
              avatar_original: imageUrl,
              token,
              email,
            };
          _handleLoginOrSignUp('google', data);
        })
        .catch(() => {
          alert('Something went wrong. Please try again!');
        });
    }
  }
  function _handleFbLogin() {
    if (window.FB) {
      window.FB.login(
        function (response) {
          if (response.authResponse && response.authResponse.accessToken) {
            const token = response.authResponse.accessToken;
            window.FB.api(`/me?fields=id, name, email, picture`, function (response) {
              let data = {
                id: response.id,
                email: response.email,
                name: response.name,
                ...(response.link && {
                  user: {
                    link: response.link,
                  },
                }),
                ...(response.picture &&
                  response.picture.data &&
                  response.picture.data.url && {
                    avatar_original: response.picture.data.url,
                  }),
                token,
              };
              _handleLoginOrSignUp('facebook', data);
            });
          }
        },
        { scope: 'email' },
      );
    }
  }
  function _handleLinkedInLogin() {
    window.location.href = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${process.env.REACT_APP_LINKEDIN_ID}&redirect_uri=${process.env.REACT_APP_LINKEDIN_REDIRECT_URI}&scope=r_liteprofile%20r_emailaddress%20w_member_social`;
  }
  return (
    <>
      <button
        onClick={_handleFbLogin}
        className="login-form__action__btn login-form__action__btn--facebook"
      >
        Facebook
      </button>
      <button
        onClick={_handleLinkedInLogin}
        className="login-form__action__btn login-form__action__btn--linkedin"
      >
        Linkedin
      </button>
      <button
        onClick={_handleGoogleLogin}
        className="login-form__action__btn login-form__action__btn--google"
      >
        Google
      </button>
    </>
  );
}

SocialLogin.propTypes = {
  publicPageLink: PropTypes.string,
  socialLogin: PropTypes.func,
  history: PropTypes.object,
};
export default withRouter(connect(null, { socialLogin })(SocialLogin));
