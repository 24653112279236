import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
    resetPhotoModal,
    showPhotoModal
} from '../../actions/common/modals';
import CloseIcon from '../../assets/images/photos/close.svg';
import { withRouter } from 'react-router-dom';
import ChevronLeft from '../../assets/images/chevrons/chevron_left_grey.png';
import ChevronRight from '../../assets/images/chevrons/chevron_right_grey.png';
import DefaultAvatar from '../../assets/images/default/avatar.jpg';

function PhotoModal(props) {
    const [photos, setPhotos] = useState(props.photoModal.photos || []),
        [isMultiple, setIsMultiple] = useState(props.photoModal.isMultiple || false),
        [selectedPhoto, setSelectedPhoto] = useState(props.photoModal.selectedPhoto || null),
        [selectedIndex, setSelectedIndex] = useState(props.photoModal.selectedIndex || null),
        [leftDisabled, setLeftDisabled] = useState(false),
        [rightDisabled, setRightDisabled] = useState(false);
    const _goRight = () => {
        if (rightDisabled) return;
        let data = {
            photos,
            isMultiple,
            selectedPhoto: photos[selectedIndex + 1],
            selectedIndex: selectedIndex + 1
        }
        props.showPhotoModal(data);
    }

    const _goLeft = () => {
        if (leftDisabled) return;
        let data = {
            photos,
            isMultiple,
            selectedPhoto: photos[selectedIndex - 1],
            selectedIndex: selectedIndex - 1
        }
        props.showPhotoModal(data);
    }

    useEffect(() => {
        setLeftDisabled(props.photoModal.isMultiple && props.photoModal.selectedIndex === 0);
        setRightDisabled(props.photoModal.isMultiple && props.photoModal.selectedIndex + 1 === props.photoModal.photos.length);
    }, []);

    useEffect(() => {
        if (props.photoModal.isVisible) {
            document.querySelector('body').classList.add('overflow-hidden');
        } else {
            document.querySelector('body').classList.remove('overflow-hidden');
        }
    }, [props.photoModal.isVisible]);

    useEffect(() => {
        return () => {
            document.querySelector('body').classList.remove('overflow-hidden');
            props.resetPhotoModal();
        }
    }, [props.location]);
    useEffect(() => {
        setPhotos(props.photoModal.photos);
        setIsMultiple(props.photoModal.isMultiple);
        setSelectedPhoto(props.photoModal.selectedPhoto);
        setSelectedIndex(props.photoModal.selectedIndex);
        if (props.photoModal.isMultiple) {
            setLeftDisabled(isMultiple && props.photoModal.selectedIndex === 0);
            setRightDisabled(isMultiple && props.photoModal.selectedIndex + 1 === props.photoModal.photos.length);
        }
    }, [props.photoModal]);
    if (!props.photoModal.photos) return null;
    return (<div className="photo-modal">
        <div className="photo-modal__container">
            <button className="photo-modal__close" onClick={() => props.resetPhotoModal()}><img className="photo-modal__close__icon" src={CloseIcon} alt="close modal" /></button>
            <div className="photo-modal__content">
                {
                    isMultiple
                    && <p className="photo-modal__helper">{selectedIndex + 1} OF {photos.length}</p>
                }
                {
                    isMultiple && <button onClick={_goLeft} className={`photo-modal__img__btn
                        photo-modal__img__btn--left
                        ${leftDisabled ? 'photo-modal__img__btn--disabled' : ''}`}
                        disabled={leftDisabled}>
                        <img className="photo-modal__img__btn__img" src={ChevronLeft} alt="go left" />
                    </button>
                }
                <div className="photo-modal__img__container"><img className="photo-modal__img" alt="post" src={selectedPhoto && selectedPhoto.original_photo ? selectedPhoto.original_photo : selectedPhoto} /></div>
                {
                    isMultiple && <button onClick={_goRight} className={`photo-modal__img__btn
                        photo-modal__img__btn--right
                        ${rightDisabled ? 'photo-modal__img__btn--disabled' : ''}`}
                        disabled={rightDisabled}>
                        <img className="photo-modal__img__btn__img" src={ChevronRight} alt="go right" />
                    </button>
                }
                <div className="photo-modal__download__container">
                    {props.photoModal.removePhoto ? <button className='button remove' onClick={props.photoModal.removePhoto}>Remove</button> : <a alt="download"
                        className="photo-modal__download"
                        rel="noopener noreferrer"
                        target="_blank"
                        download
                        href={selectedPhoto && selectedPhoto.original_photo ? selectedPhoto.original_photo : selectedPhoto}>Download</a>}
                </div>
            </div>
        </div>
    </div>)
};

function mapStateToProps(state) {
    return {
        photoModal: state.modalReducer.photoModal
    }
}

const mapDipatchToProps = {
    resetPhotoModal,
    showPhotoModal
}

export default withRouter(connect(mapStateToProps, mapDipatchToProps)(PhotoModal));