import {
    UPDATE_CURRENT_VIDEO
} from '../../actions/seeAllPages/constants';

const INITIAL_STATE = {
    current_video: null
};

export default function player(state = INITIAL_STATE, action) {
    switch (action.type) {
        case UPDATE_CURRENT_VIDEO:
            return {
                ...state,
                current_video: action.payload
            }
        default:
            return state;
    }
}
