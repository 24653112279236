import { combineReducers } from 'redux';
import jobProfile from './jobProfile';
import specializations from './specializations';
import eatingHabits from './eatingHabits';
import cuisines from './cuisines';
import followers from './followers';
import allergens from './allergens';
import update from './update';
import photos from './photos';
import view from './view';
import myCollaborations from './myCollaborations';
import all from './all';
import specializationProfiles from './specializationProfiles';

const profileReducer = combineReducers({
    jobProfile,
    specializations,
    eatingHabits,
    cuisines,
    followers,
    allergens,
    update,
    view,
    photos,
    myCollaborations,
    all,
    specializationProfiles
});

export default profileReducer;
