import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { useHistory } from 'react-router-dom';
import Modal from '../../modals/common/Modal';
import { hideProfileModal } from '../../../actions/common/modals';
import {
  getPaymentReviewConditions,
  getPaymentReviewConditionsCancelToken,
} from '../../../actions/profile/passbook/passbook';
import Loader from '../../../components/misc/Loader';
import TermsConditions from '../../../components/profile/MyPassbook/TermsConditions';

const TransactionModal = ({
  hideProfileModal,
  data,
  linkTo,
  historyStateData,
  continueButtonAction,
  modalType,
  isPaid,
}) => {
  const history = useHistory();

  let row3TitleCss;
  let continueBtnCss;
  if (modalType === 'surveyReview') {
    row3TitleCss = 'passbook__beforereviewmodal__row3__survey__title';
    continueBtnCss = 'passbook__beforereviewmodal__row4__survey__button';
  } else if (modalType === 'productReview' || modalType === 'collaborationReview') {
    row3TitleCss = 'passbook__beforereviewmodal__row3__collaboration__title';
    continueBtnCss = 'passbook__beforereviewmodal__row4__collaboration__button';
  }

  const handleContinueButton = () => {
    if (continueButtonAction) {
      continueButtonAction();
    } else {
      if (historyStateData) {
        history.push({
          pathname: linkTo,
          state: historyStateData,
        });
      } else {
        history.push({
          pathname: linkTo,
          state: {
            isPaid,
          },
        });
      }
    }
    hideProfileModal();
  };
  return (
    <React.Fragment>
      <div className="biz__modal__triodata">
        <div className="passbook__modal__row1">
          <img
            className="passbook__modal__row1__icon"
            src={get(data, 'pop_up.icon')}
            alt="modal icon"
          />
          <p className="passbook__modal__row1__title">{get(data, 'pop_up.title', '')}</p>
          <p className="passbook__modal__row1__subtitle">{get(data, 'pop_up.sub_title', '')}</p>
          <hr className="passbook__modal__row1__hr" />
        </div>
        <div className="passbook__beforereviewmodal__row2">
          <p className="passbook__beforereviewmodal__row2__title">{get(data, 'title')}</p>
        </div>

        <div className="passbook__beforereviewmodal__row3">
          {get(data, 'headers', []).map((header, index) => (
            <div key={index} className="passbook__beforereviewmodal__row3__block">
              <p className={row3TitleCss}>{get(header, 'title')}</p>
              <ul className="passbook__beforereviewmodal__row3__header">
                {get(header, 'child', []).map((obj, i) => (
                  <li key={i}>
                    <span className="passbook__beforereviewmodal__row3__header__text">
                      {get(obj, 'title')}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        <div className="passbook__beforereviewmodal__termscondition">
          <TermsConditions modal hideModal={hideProfileModal} />
        </div>

        <div className="passbook__beforereviewmodal__row4">
          <button className={`cursor-pointer ${continueBtnCss}`} onClick={handleContinueButton}>
            Accept and Continue
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

const BeforeStartReviewModal = (props) => {
  const { token, hideProfileModal } = props;
  const modalType = get(props, 'data.modalType');
  const id = get(props, 'data.data.id');
  const linkTo = get(props, 'data.data.linkTo');
  const historyStateData = get(props, 'data.data.historyStateData');
  const continueButtonAction = get(props, 'data.data.continueButtonAction');

  const [isDataFetched, setIsDataFetched] = useState(false);
  const [transactionData, setTransactionData] = useState({});

  const TransactionModalComponent = React.memo(TransactionModal);
  useEffect(() => {
    fetchReviewConditions();

    return () => {
      if (getPaymentReviewConditionsCancelToken) getPaymentReviewConditionsCancelToken();
    };
  }, []);

  const fetchReviewConditions = () => {
    let fetchId = id;
    let type;

    if (modalType === 'productReview') type = 'product';
    if (modalType === 'collaborationReview') {
      const collaborationId = get(props, 'data.data.collaborationId');
      type = 'collaborate';
      fetchId = `${collaborationId}/${fetchId}`;
    }
    if (modalType === 'surveyReview') type = 'survey';
    getPaymentReviewConditions(token, type, fetchId).then((response) => {
      if (response.status) {
        const data = get(response, 'data.data', {});
        setTransactionData(data);
        setIsDataFetched(true);
      } else {
        setTransactionData({});
        setIsDataFetched(true);
      }
    });
  };

  return (
    <Modal showHeader className="passbook__beforereviewmodal__width" handleClose={hideProfileModal}>
      {!isDataFetched ? (
        <Loader isCentered={true} withMargin={true} />
      ) : (
        <TransactionModalComponent
          hideProfileModal={hideProfileModal}
          data={transactionData}
          linkTo={linkTo}
          historyStateData={historyStateData}
          continueButtonAction={continueButtonAction}
          modalType={modalType}
          isPaid={get(props, 'data.data.isPaid', false)}
        />
      )}
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.login.token,
  };
};

const mapDispatchToProps = {
  hideProfileModal,
};

TransactionModal.propTypes = {
  data: PropTypes.object,
  hideProfileModal: PropTypes.func,
  linkTo: PropTypes.string,
  historyStateData: PropTypes.object,
  continueButtonAction: PropTypes.func,
  modalType: PropTypes.string,
  isPaid: PropTypes.bool,
};

BeforeStartReviewModal.propTypes = {
  token: PropTypes.string,
  hideProfileModal: PropTypes.func,
  historyStateData: PropTypes.object,
  continueButtonAction: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(BeforeStartReviewModal);
