import React from "react";
import PropTypes from "prop-types";
import Modal from "./Modal";
import { Link } from "react-router-dom";

function DiscardPostDraft({
  text,
  handleClose,
  actionText,
  handleAction,
  modalTitle,
}) {
  const textToShow =
    text || "This will remove all the changes you have made to this post.",
    actionTextToShow = actionText || "Discard";
  return (
    <Modal
      title={modalTitle || "Discard draft?"}
      showHeader={true}
      className="discard-post"
      handleClose={handleClose}
    >
      <p  className= {actionTextToShow == 'Block' ?"default-modal__footer__modaltext" : null}>{textToShow}</p>
      <div className= { actionTextToShow == 'Block' || actionTextToShow == 'detailsBlock' ?
       "default-modal__footer default-modal__footer--multi default-modal__footer--bottom-no-shadow"
       :"default-modal__footer default-modal__footer--multi default-modal__footer--bottom"
        }>
        <button
          className="default-modal__footer__clear-btn"
          onClick={handleClose}
          type="button"
        >
          <span
            className="default-modal__footer__clear-btn__text"
          >
            Cancel
          </span>
        </button>
        {actionTextToShow === "detailsBlock"?
        <Link  to={"/home"}  >
          <button
          type="submit"
          onClick={handleAction || handleClose}
          className="profile-edit-modal__btn profile-edit-modal__btn--buttonBlackFill cursor-pointer">
          Block
        </button>
        </Link>:
      
            <button
              type="submit"
              onClick={handleAction || handleClose}
              className= {actionTextToShow == 'Block' ?"profile-edit-modal__btn profile-edit-modal__btn--buttonBlackFill cursor-pointer":
              "profile-edit-modal__btn profile-edit-modal__btn--buttonRedFill cursor-pointer"
        }>
              {actionTextToShow}
            </button>
}
            
        

      </div>
    </Modal>
  );
}

DiscardPostDraft.propTypes = {
  handleClose: PropTypes.func,
  text: PropTypes.string,
  actionText: PropTypes.string,
  handleAction: PropTypes.func,
};

export default DiscardPostDraft;
