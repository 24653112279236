import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
// import PlayIcon from '../../../assets/images/poll/record.svg';
import { GIF_TYPE, decodeString, twoDecimal } from "../../../utils/helper";
import ZoomIcon from "../../../assets/images/poll/poll-zoom.svg";
import SubmitIcon from "../../../assets/images/poll/poll-submit.svg";
import { pollOptionImageRatio } from "../../../utils/commonVariables";
const ImageOption = ({
  option,
  optionSelected,
  selfVote,
  isExpired,
  isPublic,
  publicClickAction,
  handleImageZoom,
  isCreator,
  pollId,
  handlePollSubmit,
  voteCount,
  //   index,
}) => {
  const [ratio] = useState(pollOptionImageRatio);
  const [width, setWidth] = useState(253);
  const [height, setHeight] = useState(width / ratio);
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      const parentElementWidth = ref.current.parentElement.offsetWidth;
      const itemMaxWidth = parentElementWidth / 2 - 8;

      if (itemMaxWidth < width) {
        setWidth(twoDecimal(itemMaxWidth));
        setHeight(twoDecimal(itemMaxWidth / ratio));
      }
    }
  }, []);
  const image =
    option.image_meta && option.image_meta.original_photo
      ? option.image_meta.original_photo
      : null;
  const percentage =
    !!voteCount && !!option.count
      ? twoDecimal(option.count, option.count === 100)
      : 0;
  const gifClass =
    option.image_meta.meta.mime == GIF_TYPE
      ? "poll-card__image__img  gif_image"
      : "poll-card__image__img";

  const handleSubmit = () => {
    if (isExpired) return;
    if (isPublic) {
      publicClickAction();
      return;
    }
    if (isCreator) {
      alert(`You can't participate in your own poll.`);
      return;
    }
    if (selfVote) {
      alert(`You have already voted.`);
      return;
    }
    handlePollSubmit(pollId, option.id);
  };
  const handleZoom = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleImageZoom(option.image_meta, "Option");
  };
  const isSelfVoted = selfVote && option.id === optionSelected;
  const disableVote = selfVote || isExpired || isCreator;
  return (
    <li
      style={{ width: `${width}px`, minHeight: `${height}px` }}
      ref={ref}
      className={`poll-card__image__options__item ${isSelfVoted
        ? "poll-card__image__options__item--selected"
        : ""
        } ${disableVote
          ? "poll-card__image__options__item--unplayable"
          : ""
        }`}

    >
      <div className={`poll-card__image__options__item__media ${disableVote ? 'disabled' : ''}`} onClick={disableVote && handleZoom}>
        <div className={`media__action--wrapper ${disableVote ? 'disabled' : ''}`}>
          <div className="media__action">
            <button onClick={handleSubmit}>
              <img src={SubmitIcon} alt="submit" />
            </button>
            <button onClick={handleZoom}>
              <img src={ZoomIcon} alt="zoom" />
            </button>
          </div>
        </div>
        <img
          className={gifClass}
          src={image}
          style={{ height: `${height}px` }}
          alt={option.text}
        />
        {(selfVote || isExpired || isCreator) && !!voteCount && !!percentage && (
          <div className="poll-card__image__overlay"></div>
        )}
        {
          // Showing icon
          (selfVote || isExpired || isCreator) && !!voteCount && !!percentage && (
            <div
              className="poll-card__image__percentage"
              style={{
                ...(height
                  ? {
                    top: `${height - 34}px`,
                  }
                  : {
                    top: "50%",
                    transform: `translate(-50%, -50%)`,
                  }),
              }}
            >
              <div
                className="poll-card__image__percentage__filled"
                style={{ width: `${percentage}%` }}
              ></div>
              <span
                className={`poll-card__image__percentage__text ${percentage === 100
                  ? "poll-card__image__percentage__text--centered"
                  : ""
                  }`}
              >
                {percentage}%
              </span>
            </div>
          )
        }
      </div>
      <p className="poll-card__image__title">{decodeString(option.text)}</p>
    </li>
  );
};

function ImageOptions(props) {
  const [options, setOptions] = useState(props.options || []);

  useEffect(() => {
    setOptions(props.options);
  }, [props.options]);

  if (!options.length) return null;
  const optionItems = options.map((option, index) => (
    <ImageOption
      option={option}
      {...props}
      key={`current-poll-option=${index}`}
    />
  ));
  return <ul className="poll-card__image__options">{optionItems}</ul>;
}

ImageOption.propTypes = {
  option: PropTypes.object,
  optionSelected: PropTypes.any,
  selfVote: PropTypes.any,
  isExpired: PropTypes.bool,
  isPublic: PropTypes.bool,
  publicClickAction: PropTypes.func,
  handleImageZoom: PropTypes.func,
  isCreator: PropTypes.bool,
  pollId: PropTypes.string,
  handlePollSubmit: PropTypes.func,
  voteCount: PropTypes.number,
  index: PropTypes.number,
};

ImageOptions.propTypes = {
  options: PropTypes.array,
  optionSelected: PropTypes.any,
  selfVote: PropTypes.any,
  isExpired: PropTypes.bool,
  isPublic: PropTypes.bool,
  publicClickAction: PropTypes.func,
  handlePollSubmit: PropTypes.func,
  handleImageZoom: PropTypes.func,
};

export default ImageOptions;
