import { GET_PRODUCT_FOOD_SHOTS_SUCCESS,
    GET_PRODUCT_FOOD_SHOTS_FAILURE,
    GET_PRODUCT_FOOD_SHOTS_REQUEST,
    RESET_PRODUCT_FOOD_SHOTS } from "../../actions/products/constants";

const INITIAL_STATE = {
    fetching: false,
    error: false,
    all: [],
    page: 1,
    hasFetched: false,
    hasEnd: false
}

export default function foodShots(state = INITIAL_STATE, action) {
    switch(action.type) {
        case GET_PRODUCT_FOOD_SHOTS_SUCCESS:
            return {
                ...state,
                all: state.hasFetched
                    ? [...state.all, ...action.payload]
                    : action.payload,
                page: state.page + 1,
                hasFetched: true,
                fetching: false,
                hasEnd: !action.payload.length
            }
        case GET_PRODUCT_FOOD_SHOTS_REQUEST:
            return {
                ...state,
                fetching: true
            }
        case GET_PRODUCT_FOOD_SHOTS_FAILURE:
            return {
                ...state,
                fetching: false,
                error: true,
                errorMsg: action.payload.error
            }
        case RESET_PRODUCT_FOOD_SHOTS:
            return INITIAL_STATE;
        default:
            return state;
    }
}