export const converArrayToString = (arr) => {
    return arr.map(_ => _.name).join(', ');
}

// showing collaboration
export const shouldShowCollaboration = (percentageComplete) => {
    const currentSession = window.localStorage.getItem('_cs');
    if (!currentSession) {
        window.localStorage.setItem('_cs', 1);
        return;
    }
    let showProfileCompletion = false;
    if ((percentageComplete <= 40 && currentSession % 2 === 0)
    || (percentageComplete > 40 && percentageComplete <= 50 && currentSession % 8 === 0)
    || (percentageComplete > 50 && currentSession % 35 === 0)) {
        showProfileCompletion = true;
    }
    window.localStorage.setItem('_cs', (currentSession + 1));
    return showProfileCompletion;
}

// check for handle
export const checkForValidHandle = (handle) => {
    if (!handle) return false;
    const regexForSpecialCharacters = new RegExp(/[^a-zA-Z0-9_+]/gmi);
    return !handle.match(regexForSpecialCharacters);
}

// check if bypassed onboarding check
export const checkForBypassingOnboardingCheck = () => {
    let currentParams =  new URLSearchParams(window.location.search);
    return currentParams.has('_ob');
}

// to check for overlay on hero image
export const overlayImageMinScrollTop = 350;

// method to check if can see palete discovery
export const canSeePaleteSensitivityOnProfile = (profile, user) => {
    const { palate_sensitivity,
        isFollowedBy,
        palate_visibility } = profile;
    if (!palate_sensitivity || (palate_sensitivity && !palate_sensitivity.length)) return null;
    if ((user.profile.id === profile.id)
        || (user.profile.id !== profile.id
            && ((palate_visibility === 2 && isFollowedBy)
                || palate_visibility === 1))) return true;
    return false;
}