import React, { PureComponent } from 'react';

class FeedPlaceholder extends PureComponent {
    render() {
        return (<div className="feed-list__item feed-placeholder">
            <div className="feed-placeholder__user__container">
                <div className="feed-placeholder__user__avatar"></div>
                <div className="feed-placeholder__user__info">
                    <div className="placeholder__block"></div>
                    <div className="placeholder__block"></div>
                </div>
            </div>
            <div className="feed-placeholder__content">
                <div>
                    <div className="placeholder__block"></div>
                    <div className="placeholder__block"></div>
                </div>
                <div>
                    <div className="placeholder__block"></div>
                    <div className="placeholder__block"></div>
                    <div className="placeholder__block"></div>
                    <div className="placeholder__block"></div>
                </div>
                <div>
                    <div className="placeholder__block"></div>
                    <div className="placeholder__block"></div>
                </div>
            </div>
            <div className="feed-placeholder__footer">
                <div>
                    <div className="placeholder__block"></div>
                    <div className="placeholder__block"></div>
                </div>
                <div>
                    <div className="placeholder__block"></div>
                    <div className="placeholder__block"></div>
                    <div className="placeholder__block"></div>
                </div>
            </div>
        </div>)
    };
}

export default FeedPlaceholder;