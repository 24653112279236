import { GET_SHOUTOUT_DETAILS_REQUEST,
    GET_SHOUTOUT_DETAILS_SUCCESS,
    GET_SHOUTOUT_DETAILS_FAILURE,
    RESET_SHOUTOUT_DETAILS } from '../../actions/details/constants';
import { LIKE_A_POST_REQUEST,
    LIKE_A_POST_SUCCESS,
    LIKE_A_POST_FAILURE } from '../../actions/constants';
import { CREATE_A_COMMENT_SUCCESS, UPDATE_A_POST_SUCCESS } from '../../actions/common/constants';
import { GET_A_POST_COMMENT_SUCCESS,
    DELETE_A_COMMENT_SUCCESS } from '../../actions/feed/constants';

const INITIAL_STATE = {
    fetching: false,
    details: null,
}

const defaultPostType = "shoutout";

const isCurrentItem = (state, action) => {
    if (!state.details) return false;
    return (action.payload.postType === defaultPostType
        && !action.payload.isShared
        && state.details
        && state.details.shoutout
        && state.details.shoutout.id === parseInt(action.payload.postId))
}

export default function post(state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_SHOUTOUT_DETAILS_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case GET_SHOUTOUT_DETAILS_SUCCESS:
            return {
                ...state,
                fetching: false,
                details: action.payload.details,
                isFromPreviousData: !!action.payload.isFromPreviousData,
            }
        case GET_SHOUTOUT_DETAILS_FAILURE:
            return {
                ...state,
                fetching: false,
                error: true,
                errorMsg: action.error,
            }
        case LIKE_A_POST_REQUEST:
        case LIKE_A_POST_SUCCESS:
        case LIKE_A_POST_FAILURE:
            return {
                ...state,
                details: state.details
                    ? isCurrentItem(state, action)
                    ? {
                        ...state.details,
                        meta: {
                            ...state.details.meta,
                            hasLiked: action.type === LIKE_A_POST_REQUEST
                                    ? !action.payload.hasLiked
                                    : action.payload.hasLiked,
                                likeCount: action.type === LIKE_A_POST_REQUEST
                                    ? action.payload.hasLiked
                                    ? state.details.meta.likeCount === 0
                                    ? 0
                                    : state.details.meta.likeCount - 1
                                    : state.details.meta.likeCount + 1
                                    : action.type === LIKE_A_POST_FAILURE
                                    ? action.payload.hasLiked
                                    ? state.details.meta.likeCount + 1
                                    : state.details.meta.likeCount === 0
                                    ? 0
                                    : state.details.meta.likeCount - 1
                                    : state.details.meta.likeCount
                        }
                    }
                    : state.details
                    : null
            }
        case CREATE_A_COMMENT_SUCCESS:
        case GET_A_POST_COMMENT_SUCCESS:
            return {
                ...state,
                details: state.details
                    ? isCurrentItem(state, action)
                    ? {
                        ...state.details,
                        meta: {
                            ...state.details.meta,
                            commentCount: action.type === CREATE_A_COMMENT_SUCCESS
                                ? state.details.meta.commentCount + 1
                                : action.payload.count
                        }
                    }
                    : state.details
                    : null
            }
        case DELETE_A_COMMENT_SUCCESS:
            return {
                ...state,
                details: state.details
                    ? isCurrentItem(state, action)
                    ? {
                        ...state.details,
                        meta: {
                            ...state.details.meta,
                                commentCount: (state.details.meta.commentCount - 1 <= 0)
                                ? 0
                                : (state.details.meta.commentCount - 1)
                        }
                    }
                    : state.details
                    : null
            }
        case UPDATE_A_POST_SUCCESS:
            return {
                ...state,
                details: state.details
                    && isCurrentItem(state, action)
                    ? {
                        ...state.details,
                        ...action.payload.data
                    }
                    : state.details
            }
        case RESET_SHOUTOUT_DETAILS:
            return INITIAL_STATE
        default:
            return state;
    }
}