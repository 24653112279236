import { GET_PEOPLE_BY_SPECIALIZATION_REQUEST,
    GET_PEOPLE_BY_SPECIALIZATION_SUCCESS,
    GET_PEOPLE_BY_SPECIALIZATION_FAILURE,
    RESET_PROFILE_BY_SPECIALIZATION,
    FOLLOW_USER_SUCCESS} from "../../actions/profile/constants";

const INITIAL_STATE = {
    page: 1,
    fetching: false,
    error: null,
    people: [],
    hasEnd: false,
    hasFetched: false
}
export default function specializationProfiles(state = INITIAL_STATE, action) {
    switch(action.type) {
        case GET_PEOPLE_BY_SPECIALIZATION_REQUEST:
            return {
                ...state,
                fetching: true,
                error: false
            }
        case GET_PEOPLE_BY_SPECIALIZATION_SUCCESS:
                return {
                    ...state,
                    fetching: false,
                    people: state.page === 1
                        ? action.payload
                        : [...state.people, ...action.payload],
                    page: state.page + 1,
                    hasEnd: (!action.payload.length),
                    hasFetched: true
                }
        case GET_PEOPLE_BY_SPECIALIZATION_FAILURE:
                return {
                    ...state,
                    ...(!action.error.__CANCEL__ && {
                        error: true,
                        errorMsg: action.error
                    }),
                    fetching: false,
                }
        case FOLLOW_USER_SUCCESS:
            return {
                ...state,
                people: !!state.people.length
                    ? state.people.map((profile) => {
                        if (profile.id !== action.payload.profileId) return profile;
                        return {
                            ...profile,
                            isFollowing: true
                        }
                    })
                    : state.people
            }
        case RESET_PROFILE_BY_SPECIALIZATION:
            return INITIAL_STATE;
        default:
            return state;
    }
}