import { GET_COMPANY_ACTIVITY_REQUEST,
    GET_COMPANY_ACTIVITY_SUCCESS,
    GET_COMPANY_ACTIVITY_FAILURE,
    RESET_COMPANY_ACTIVITY} from '../../actions/company/constants';
import { LIKE_A_POST_FAILURE,
    LIKE_A_POST_SUCCESS,
    LIKE_A_POST_REQUEST } from '../../actions/constants';
import { PLAY_A_POLL_SUCCESS,
    RENEW_A_POLL_SUCCESS } from '../../actions/poll/constants';
import { UPDATE_A_POST_SUCCESS,
    UPDATE_A_PHOTO_POST_SUCCESS,
    UPDATE_A_POLL_SUCCESS,
    DELETE_A_POST_SUCCESS,
    CREATE_A_COMMENT_SUCCESS } from '../../actions/common/constants';
import { DELETE_A_COMMENT_SUCCESS } from '../../actions/feed/constants';
import { FLAG_REPORT_POST_SUCCESS } from '../../actions/flags/constants';
import { _handleReportedContent } from '../../utils/reducerHelpers';

const INITIAL_STATE = {
    fetching: false,
    error: false,
    page: 1,
    errorMsg: null,
    hasEndReached: false,
    all: [],
    hasFetched: false
}

const _isCurrentPost = (action, feedCard) => {
    return (!action.payload.isShared
        && feedCard[action.payload.postType]
        && !feedCard.shared
        && feedCard[action.payload.postType].id === action.payload.postId)
}

export default function view(state = INITIAL_STATE, action)  {
    switch (action.type) {
        case GET_COMPANY_ACTIVITY_REQUEST:
            return {
                ...state,
                fetching: true,
            }
        case GET_COMPANY_ACTIVITY_SUCCESS:
            return {
                ...state,
                all: [...state.all, ...action.payload],
                page: state.page + 1,
                fetching: false,
                hasEndReached: !action.payload || (action.payload && !action.payload.length),
                hasFetched: true
            }
        case GET_COMPANY_ACTIVITY_FAILURE:
            return {
                ...state,
                fetching: false,
                error: true,
                errorMsg: action.error
            }
        case LIKE_A_POST_FAILURE:
        case LIKE_A_POST_SUCCESS:
        case LIKE_A_POST_REQUEST:
                return {
                    ...state,
                    ...(state.hasFetched && {
                        all: state.all.map((feedCard) => {
                            if (((!action.payload.isShared
                                && feedCard[action.payload.postType]
                                && !feedCard.shared
                                && feedCard[action.payload.postType].id === action.payload.postId))
                                || (action.payload.isShared
                                && feedCard.shared
                                && feedCard.shared.id === action.payload.sharedId)) {
                                return {
                                    ...feedCard,
                                    meta: {
                                        ...feedCard.meta,
                                        hasLiked: action.type === LIKE_A_POST_REQUEST
                                            ? !action.payload.hasLiked
                                            : action.payload.hasLiked,
                                        likeCount: action.type === LIKE_A_POST_REQUEST
                                            ? action.payload.hasLiked
                                            ? feedCard.meta.likeCount === 0
                                            ? 0
                                            : feedCard.meta.likeCount - 1
                                            : feedCard.meta.likeCount + 1
                                            : action.type === LIKE_A_POST_FAILURE
                                            ? action.payload.hasLiked
                                            ? feedCard.meta.likeCount + 1
                                            : feedCard.meta.likeCount === 0
                                            ? 0
                                            : feedCard.meta.likeCount - 1
                                            : feedCard.meta.likeCount
                                    },
                                    likeActionProcessing: action.type === LIKE_A_POST_REQUEST
                                }
                            }
                            return feedCard
                        })
                    })
                }
            case RENEW_A_POLL_SUCCESS:
                return {
                    ...state,
                    ...(state.hasFetched && {
                        all: state.all.map((feedCard) => {
                            if (feedCard.type !== "polling" || feedCard.shared) return feedCard;
                            if (action.payload.pollId
                                && feedCard.polling
                                && feedCard.polling.id
                                && feedCard.polling.id === action.payload.pollId) {
                                return {
                                    ...feedCard,
                                    polling: {
                                        ...feedCard.polling,
                                        poll_meta: {
                                            ...feedCard.polling.poll_meta,
                                            is_expired: 0
                                        }
                                    },
                                    meta: {
                                        ...feedCard.meta,
                                        is_expired: 0
                                    }
                                }
                            }
                            return feedCard;
                        })
                    })
                }
        case PLAY_A_POLL_SUCCESS:
            return {
                ...state,
                ...(state.hasFetched && {
                    all: state.all.map((feedCard) => {
                        if (feedCard.type !== "polling") return feedCard;
                        if (action.payload.polling
                            && feedCard.polling
                            && feedCard.polling.id
                            && action.payload.polling.id
                            && feedCard.polling.id === action.payload.polling.id) {
                                if (feedCard.shared) {
                                    return {
                                        ...feedCard,
                                        meta: {
                                            ...feedCard.meta,
                                            originalPostMeta: action.payload.meta
                                        },
                                        polling: {
                                            ...action.payload.polling,
                                            poll_meta: action.payload.meta
                                        }
                                    }
                                }
                            return {
                                ...feedCard,
                                ...action.payload,
                                ...(action.payload.polling
                                    && !action.payload.polling.poll_meta
                                    && {
                                    polling: {
                                        ...action.payload.polling,
                                        poll_meta: action.payload.meta
                                    }
                                })
                            }
                        }
                        return feedCard;
                    })
                })
            }
        case UPDATE_A_POST_SUCCESS:
        case UPDATE_A_PHOTO_POST_SUCCESS:
        case UPDATE_A_POLL_SUCCESS:
            return {
                ...state,
                ...(state.hasFetched && {
                    all: state.all.map(_ => {
                        if (_.type === action.payload.postType
                            && !_.shared
                            && _[_.type]
                            && _[_.type].id === action.payload.data[action.payload.postType].id) {
                            return {
                                    ..._,
                                    ...action.payload.data
                            }
                        }
                        return _;
                    })
                })
            }
        case DELETE_A_POST_SUCCESS:
                return {
                    ...state,
                    ...(state.hasFetched && {
                        all: state.all.filter(_ => {
                            return !((action.payload.isShared
                                && action.payload.sharedId
                                && !!_.shared
                                && _.shared.id === action.payload.sharedId)
                                || (!action.payload.isShared
                                    && !action.payload.sharedId
                                    && !_.shared
                                    && _[action.payload.postType]
                                    && _[action.payload.postType].id === action.payload.postId))
                        })
                    })
                }
        case CREATE_A_COMMENT_SUCCESS:
            return {
                ...state,
                ...state.hasFetched && {
                    all: state.all.map(feedCard => {
                        if (_isCurrentPost(action, feedCard)) {
                            return {
                                ...feedCard,
                                meta: {
                                    ...feedCard.meta,
                                    ...action.payload.meta
                                }
                            }
                        }
                        return feedCard
                    })
                }
            }
        case DELETE_A_COMMENT_SUCCESS:
            return {
                ...state,
                ...(state.hasFetched && {
                    all: state.all.map(feedCard => {
                        if (_isCurrentPost(action, feedCard)) {
                            return {
                                ...feedCard,
                                meta: {
                                    ...feedCard.meta,
                                    commentCount: (feedCard.meta.commentCount - 1) === 0
                                        ? 0
                                        : (feedCard.meta.commentCount - 1)
                                }
                            }
                        }
                        return feedCard
                    })
                })
            }
        case FLAG_REPORT_POST_SUCCESS:
            return {
                ...state,
                ...(state.hasFetched
                    && {
                        all: state.all.filter(_ => _handleReportedContent(_, action.payload))
                    })
            }
        case RESET_COMPANY_ACTIVITY:
            return INITIAL_STATE;
        default:
            return state;
    }
}