import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { connect } from "react-redux";
import UserBar from "../common/UserBar";
import CompanyBar from "../common/CompanyBar";
import MetaBar from "../common/MetaBar";
import PostComments from "../common/PostComments";
import { Link } from "react-router-dom";
import { quizLinks } from "../../../../utils/internalLinks";
import ReadMoreLinkified from "../common/ReadMoreLinkified";
import ReadMoreText from "../common/ReadMoreText";
import {
  GIF_TYPE,
  decodeString,
  removeStripTags,
  makePlaylistForJWPlayer,
} from "../../../../utils/helper";
import { availableFeedCardPostTypes } from "../../../../utils/commonVariables";
import { QUIZ_APPLICANT_STATES } from "../../../../utils/quizHelper";
import CommonPlayer from "../../../../components/CommonPlayer";
const QuizCard = (props) => {
  const {
    cardData,
    isShared,
    showOptionsIcon,
    hideMetaBar,
    isAd,
    showFlagOptions,
    user,
    commentsData,
  } = props,
    { quiz, meta, shared } = cardData,
    postId = get(quiz, "id"),
    createdAt = quiz.created_at,
    title = decodeString(quiz.title),
    description = removeStripTags(decodeString(quiz.description), ["br"]);

  const originalPostMeta =
    isShared && meta && meta.originalPostMeta ? meta.originalPostMeta : null,
    metaToUse = isShared ? originalPostMeta : meta,
    responseCount =
      metaToUse && metaToUse.answerCount ? metaToUse.answerCount : 0;
  const isOwner =
    user && user.user && user.user.profile
      ? quiz.company_id
        ? get(metaToUse, "isAdmin") ||
        (cardData &&
          cardData.profile &&
          user.user.profile.id === get(cardData, "profile.id"))
        : user.user.profile.id === get(cardData, "profile.id")
      : false;

  let actionText;
  const canReplay = get(quiz, "replay");
  if (isOwner) {
    const totalApplicants = get(quiz, "totalApplicants");

    actionText =
      get(quiz, "is_private") === 1
        ? `${totalApplicants} APPLICANT${totalApplicants === 1 ? "" : "S"}`
        : `${responseCount} RESPONSE${responseCount === 1 ? "" : "S"}`;
  } else {
    const applicationStatus = get(metaToUse, "applicationStatus");
    if (applicationStatus === QUIZ_APPLICANT_STATES.NOTSTARTED) {
      actionText = "PLAY QUIZ";
    } else if (applicationStatus === QUIZ_APPLICANT_STATES.INPROGRESS) {
      actionText = "RESUME QUIZ";
    } else if (
      applicationStatus === QUIZ_APPLICANT_STATES.COMPLETED &&
      !canReplay
    ) {
      actionText = "VIEW QUIZ";
    } else if (
      applicationStatus === QUIZ_APPLICANT_STATES.COMPLETED &&
      canReplay
    ) {
      actionText = "PLAY AGAIN";
    }
  }

  const quizImagePlaceHolder =
    "https://s3.ap-south-1.amazonaws.com/static3.tagtaste.com/app/placeholder_images/quiz/feed_placeholder.png";

  const linkToQuiz = quizLinks.view(postId);
  const imagesMeta =
    quiz.image_meta && typeof quiz.image_meta === "string"
      ? JSON.parse(quiz.image_meta)
      : quiz.image_meta;
  const imageToShow =
    imagesMeta && imagesMeta.length && imagesMeta[0].original_photo
      ? imagesMeta[0].original_photo
      : quizImagePlaceHolder;
  const gifImage =
    imagesMeta &&
    imagesMeta.length &&
    imagesMeta[0] &&
    imagesMeta[0].meta &&
    imagesMeta[0].meta.mime === GIF_TYPE;
  const CreatorBar = cardData.company ? CompanyBar : UserBar;
  const isMediaJson =
    quiz.videos_meta &&
      quiz.videos_meta.length &&
      quiz.videos_meta[0] &&
      quiz.videos_meta[0].media_json
      ? quiz.videos_meta[0].media_json
      : false;
  const jwPlayerId = isShared
    ? `shared-${cardData.shared.id}-quiz-${quiz.id}`
    : `quiz-${quiz.id}`;
  return (
    <div className="feed-card">
      {
        <CreatorBar
          postType={availableFeedCardPostTypes.quiz}
          postId={postId}
          showOptionsIcon={showOptionsIcon}
          cardData={cardData}
          createdAt={createdAt}
          isAd={isAd}
          hideMetaBar={hideMetaBar}
          {...(cardData.company
            ? {
              profileId:
                cardData.company && cardData.company.profileId
                  ? cardData.company.profileId
                  : null,
              company: cardData.company,
            }
            : {
              profile: cardData.profile,
            })}
          showFlagOptions={showFlagOptions}
        />
      }
      <Link to={linkToQuiz} className="quiz__card">
        <h2 className="quiz__card__header">Quiz</h2>
        <ReadMoreLinkified
          text={title}
          linksAsDiv={true}
          className="quiz__card__title"
          hashtagEventLabel="Quiz"
        />
        <ReadMoreText text={description} className="quiz__card__description" />
        {isMediaJson ? (
          <div
            className="quiz__card__img__wrapper quiz__card__img"
            onClick={(e) => e.preventDefault()}
          >
            <CommonPlayer
              playerId={jwPlayerId}
              videosMeta={quiz.videos_meta || []} withoverlay={false}
            />
          </div>
        ) : (
          imageToShow && (
            <div className="quiz__card__img__wrapper">
              {get(metaToUse, "applicationStatus") ===
                QUIZ_APPLICANT_STATES.COMPLETED && (
                  <span className="quiz__card__score overlap">
                    {metaToUse.score_text}
                  </span>
                )}
              <img
                src={imageToShow}
                className={` ${gifImage
                    ? "quiz__card__img  big_gif_image background_gif"
                    : "quiz__card__img"
                  }`}
                alt={title}
                style={gifImage ? { border: "1px solid #D1D1D1" } : {}}
              />
            </div>
          )
        )}
        <button className="quiz__card__action">{actionText}</button>
      </Link>
      {!isShared && !hideMetaBar && cardData.meta && (
        <MetaBar
          postType={availableFeedCardPostTypes.quiz}
          postId={postId}
          isAd={isAd}
          likeActionProcessing={cardData.likeActionProcessing}
          fetchingComments={commentsData && commentsData.fetching}
          postToShare={cardData}
          meta={cardData.meta}
        />
      )}
      {commentsData && !hideMetaBar && !isShared && (
        <PostComments
          showOptionsIcon={showOptionsIcon}
          commentsData={commentsData}
          postType={availableFeedCardPostTypes.quiz}
          isAd={isAd}
          postId={postId}
        />
      )}
    </div>
  );
};

QuizCard.propTypes = {
  cardData: PropTypes.object.isRequired,
  isShared: PropTypes.bool,
  showOptionsIcon: PropTypes.bool,
  hideMetaBar: PropTypes.bool,
  isAd: PropTypes.bool,
  showFlagOptions: PropTypes.bool,
  user: PropTypes.object,
  commentsData: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps)(QuizCard);
