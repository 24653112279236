import { combineReducers } from 'redux';
import details from './details';
import headers from './headers';
import summary from './summary';
import foodShots from './foodShots';
import reviews from './reviews';
import reports from './reports';
import review from './review';
import anyOther from './anyother';
import all from './all';

const productReducer = combineReducers({
    all,
    details,
    headers,
    summary,
    foodShots,
    reviews,
    reports,
    review,
    anyOther
});

export default productReducer;