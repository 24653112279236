import { GET_COLLABORATION_DETAILS_REQUEST,
    GET_COLLABORATION_DETAILS_SUCCESS,
    GET_COLLABORATION_DETAILS_FAILURE,
    RESET_COLLABORATION_DETAILS,
    APPLY_PRODUCT_COLLABORATION_SUCCESS,
    ACCEPT_COLLABORATION_INVITE_SUCCESS,
    APPLY_COLLABORATION_SUCCESS,
    REJECT_COLLABORATION_INVITE_SUCCESS,
    UPDATE_CHANGES_IN_COLLABORATION_APPLICATION_SUCCESS} from '../../actions/collaborations/constants';
import { DELETE_A_COMMENT_SUCCESS } from '../../actions/feed/constants';
import { CREATE_A_COMMENT_SUCCESS } from '../../actions/common/constants';
import { LIKE_A_POST_FAILURE,
    LIKE_A_POST_REQUEST,
    LIKE_A_POST_SUCCESS } from '../../actions/constants';
import { PR_INVITE_AN_APPLICANT_SUCCESS } from '../../actions/collaborations/prManage/constants';
import { decodeString } from '../../utils/helper';

const INITIAL_STATE = {
    collaboration: null,
    fetching: false,
    error: null,
    hasFetched: false
}

export default function view(state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_COLLABORATION_DETAILS_REQUEST:
            return {
                ...state,
                fetching: true
            };
        case GET_COLLABORATION_DETAILS_SUCCESS:
            return {
                ...state,
                fetching: false,
                hasFetched: true,
                collaboration: {
                    ...action.payload.collaboration,
                    addresses: !!action.payload.collaboration.track_consistency
                        && action.payload.collaboration.addresses.length
                        ? action.payload.collaboration.addresses.map(city => {
                            return {
                                ...city,
                                outlets: city.outlets
                                    && city.outlets.length
                                    ? city.outlets.map(outlet => {
                                        return {
                                            ...outlet,
                                            name: decodeString(outlet.name)
                                        }
                                    })
                                    : []
                            }
                        })
                        : action.payload.collaboration.addresses
                },
                meta: action.payload.meta
                    ? action.payload.meta
                    : state.meta,
                seoTags: action.payload.seoTags
                    ? action.payload.seoTags
                    : null,
                isEdit: action.payload.isEdit
            }
        case GET_COLLABORATION_DETAILS_FAILURE:
            return {
                ...state,
                fetching: false,
                ...(!action.error.__CANCEL__ && {
                    error: true,
                    errorMsg: action.payload.error,
                }),
                hasFetched: false,
            }
        case LIKE_A_POST_FAILURE:
        case LIKE_A_POST_SUCCESS:
        case LIKE_A_POST_REQUEST:
            return {
                ...state,
                ...(state.hasFetched && {
                    meta: {
                        ...state.meta,
                        hasLiked: action.type === LIKE_A_POST_REQUEST
                            ? !action.payload.hasLiked
                            : action.payload.hasLiked,
                        likeCount: action.type === LIKE_A_POST_REQUEST
                            ? action.payload.hasLiked
                            ? state.meta.likeCount === 0
                            ? 0
                            : state.meta.likeCount - 1
                            : state.meta.likeCount + 1
                            : action.type === LIKE_A_POST_FAILURE
                            ? action.payload.hasLiked
                            ? state.meta.likeCount + 1
                            : state.meta.likeCount === 0
                            ? 0
                            : state.meta.likeCount - 1
                            : state.meta.likeCount
                    }
                })
            }
        case CREATE_A_COMMENT_SUCCESS: // New comment added
            return {
                ...state,
                ...(state.hasFetched && {
                    meta: action.payload.meta
                        ? {
                            ...state.meta,
                            ...action.payload.meta
                        }
                        : state.meta
                })
            }
        case UPDATE_CHANGES_IN_COLLABORATION_APPLICATION_SUCCESS:
            return {
                ...state,
                ...(state.hasFetched && {
                    meta: {
                        ...state.meta,
                        ...action.payload
                    }
                })
            }
        case DELETE_A_COMMENT_SUCCESS:
            return {
                ...state,
                ...(state.hasFetched && {
                    meta: {
                        ...state.meta,
                        commentCount: (state.meta.commentCount - 1) === 0
                            ? 0
                            : (state.meta.commentCount - 1)
                    }
                })
            }
        case APPLY_COLLABORATION_SUCCESS:
            return {
                ...state,
                meta: {
                    ...state.meta,
                    interested: true,
                    interestedCount: state.meta.interestedCount + 1
                }
            }
        case APPLY_PRODUCT_COLLABORATION_SUCCESS:
            return {
                ...state,
                collaboration: {
                    ...state.collaboration,
                    product_review_meta: {
                        ...state.collaboration.product_review_meta,
                        is_interested: true
                    }
                }
            }
        case ACCEPT_COLLABORATION_INVITE_SUCCESS:
            return {
                ...state,
                ...(state.hasFetched && {
                    collaboration: {
                        ...state.collaboration,
                        product_review_meta: {
                            ...state.collaboration.product_review_meta,
                            is_invitation_accepted: true,
                            is_invited: true,
                            is_actioned: true
                        }
                    }
                })
            }
        case REJECT_COLLABORATION_INVITE_SUCCESS:
            return {
                ...state,
                ...(state.hasFetched && {
                    collaboration: {
                        ...state.collaboration,
                        product_review_meta: {
                            ...state.collaboration.product_review_meta,
                            is_invited: false
                        }
                    }
                })
            }
        case PR_INVITE_AN_APPLICANT_SUCCESS:
            return {
                ...state,
                ...(state.hasFetched && {
                    collaboration: {
                        ...state.collaboration,
                        applicationCount: state.collaboration.applicationCount + 1
                    }
                })
            }
        case RESET_COLLABORATION_DETAILS:
            return INITIAL_STATE
        default:
            return state;
    }
}