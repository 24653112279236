import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { toggleProfileMenuModal, toggleOtpModal } from '../../actions/common/modals';
import ChangePasswordModal from './profileMenu/ChangePasswordModal';
import CreatePasswordModal from './profileMenu/CreatePasswordModal';
import InviteFriendsModal from './profileMenu/InviteFriendsModal';
import CreateCompanyModal from './profileMenu/CreateCompanyModal';

const components = {
    change_password: ChangePasswordModal,
    company: CreateCompanyModal,
    invite: InviteFriendsModal,
    create_password: ChangePasswordModal
}

class ProfileMenuModal extends Component {
    renderModal = () => {
        const { type } = this.props;
        if (!type || (type && !components[type])) {
            this.handleClose();
            return;
        };
        const ComponentToRender = components[type];
        return <ComponentToRender onClose={this.handleClose} type={type} handleSubmit={this.handleSubmitPassword} />
    }

    handleSubmitPassword = async (password) => {
        const { type } = this.props;
        await this.props.toggleOtpModal({"type" : type,"password":password});
        this.handleClose();
    }

    handleClose = () => this.props.toggleProfileMenuModal();

    componentDidMount() {
        document.querySelector('body').classList.add('overflow-hidden');
    }

    componentWillUnmount() {
        document.querySelector('body').classList.remove('overflow-hidden');
    }

    render() {
        const { type } = this.props;
        if (!type) return null;
        return (
            <div className="default-modal profile-form-modals">
                <div className="default-modal__backdrop">
                    <div className="default-modal__container">
                        {this.renderModal()}
                    </div>
                </div>
            </div>
        )
    };
}


const mapDispatchToProps = {
    toggleProfileMenuModal,
    toggleOtpModal
}

ProfileMenuModal.propTypes = {
    type: PropTypes.any
}

export default connect(null, mapDispatchToProps)(ProfileMenuModal);