import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import ChevronRight from "../../assets/images/chevrons/icon_chevron_big.svg";
import Avatar from "../user/Avatar";
import { companiesLink, profileLinks } from "../../utils/internalLinks";
import { decodeString } from "../../utils/helper";
import UserName from "../profile/UserName";
import isString from "lodash/isString";

function UserSelect(props) {
  const [isOpen, setIsOpen] = useState(false),
    [companies, setCompanies] = useState(props.companies || []),
    [profile, setProfile] = useState(props.profile || {}),
    [isUserCompany, setIsUserCompany] = useState(props.isUserCompany || false),
    [selectedUser, setSelectedUser] = useState(props.selectedUser || {}),
    [isEdit, setIsEdit] = useState(props.isEdit || false),
    [onlyCompanies, setOnlyCompanies] = useState(props.onlyCompanies || false),
    [canClick, setCanClick] = useState(props.canClick || false),
    [showCompanySquare, setShowCompanySquare] = useState(
      props.showCompanySquare || false
    );
  useEffect(() => {
    setCompanies(props.companies || []);
    setProfile(props.profile || {});
    setIsUserCompany(props.isUserCompany || false);
    setSelectedUser(props.selectedUser || {});
    setIsEdit(props.isEdit || false);
    setOnlyCompanies(props.onlyCompanies || false);
    setCanClick(props.canClick || false);
    setShowCompanySquare(props.showCompanySquare || false);
  }, [props]);
  useEffect(() => {
    function handleBodyClick() {
      if (isOpen) {
        setIsOpen(false);
      }
    }
    document.addEventListener("click", handleBodyClick);
    return () => {
      document.removeEventListener("click", handleBodyClick);
    };
  });

  function handleClick(e, selectedUser, isUserCompany = false) {
    e.preventDefault();
    props.handleUserSelectChange(selectedUser, isUserCompany);
    setIsOpen(false);
  }

  if (isEdit) {
    return (
      <div
        className={`user-create-block ${canClick ? "cursor-pointer" : ""}`}
        onClick={() => {
          if (!canClick) return;
          const link = isUserCompany
            ? companiesLink.view(selectedUser.id)
            : profileLinks.view(selectedUser.handle);
          props.history.push(link);
        }}
      >
        <Avatar
          alt={selectedUser.name || "user"}
          isCompany={isUserCompany}
          src={
            isUserCompany
              ? selectedUser.logo_meta
                ? selectedUser.logo_meta && isString(selectedUser.logo_meta)
                  ? JSON.parse(selectedUser.logo_meta).original_photo
                  : selectedUser.logo_meta.original_photo
                : ""
              : selectedUser.image_meta
              ? selectedUser.image_meta && isString(selectedUser.image_meta)
                ? JSON.parse(selectedUser.image_meta).original_photo
                : selectedUser.image_meta.original_photo
              : ""
          }
          className={`user-create-block__avatar ${
            showCompanySquare && isUserCompany
              ? "user-create-block__avatar--square"
              : ""
          }`}
          profile={profile}
        />
        <p
          className={`user-create-block__name ${
            !!selectedUser.verified && "user-create-block__name--verified"
          }`}
        >
          <UserName
            isVerified={!!selectedUser.verified || false}
            name={selectedUser.name}
          />
        </p>
      </div>
    );
  }

  return (
    <div
      className="user-create-block__container"
      style={{ marginBottom: "12px" }}
    >
      <div
        onClick={() => setIsOpen(!isOpen)}
        className="user-create-block cursor-pointer"
      >
        <Avatar
          alt={selectedUser.name}
          isCompany={isUserCompany}
          src={
            isUserCompany
              ? selectedUser.logo_meta
                ? selectedUser.logo_meta && isString(selectedUser.logo_meta)
                  ? JSON.parse(selectedUser.logo_meta).original_photo
                  : selectedUser.logo_meta.original_photo
                : ""
              : selectedUser.image_meta
              ? selectedUser.image_meta && isString(selectedUser.image_meta)
                ? JSON.parse(selectedUser.image_meta).original_photo
                : selectedUser.image_meta.original_photo
              : ""
          }
          className={`user-create-block__avatar ${
            showCompanySquare && isUserCompany
              ? "user-create-block__avatar--square"
              : ""
          }`}
          profile={profile}
        />
        <p
          className={`user-create-block__name ${
            !!profile.verified && "user-create-block__name--verified"
          }`}
        >
          <UserName
            isVerified={!!selectedUser.verified || false}
            name={selectedUser.name}
          />
        </p>
        <img
          className="user-create-block__chevron"
          src={ChevronRight}
          alt="change profile create"
        />
      </div>
      {isOpen && (
        <ul className="user-create-block__select">
          {!onlyCompanies && (
            <li
              onClick={(e) => handleClick(e, profile, false)}
              className="user-create-block"
            >
              <Avatar
                className="user-create-block__avatar"
                alt={profile.name}
                src={
                  profile.image_meta
                    ? JSON.parse(profile.image_meta).original_photo
                    : ""
                }
                profile={profile}
              />
              <p
                className={`user-create-block__name ${
                  !!profile.verified && "user-create-block__name--verified"
                }`}
              >
                <UserName
                  isVerified={!!profile.verified || false}
                  name={profile.name}
                />
              </p>
            </li>
          )}
          {companies &&
            !!companies.length &&
            companies.map((company, companyIndex) => {
              if (!company) return null;
              const companyName = decodeString(company.name);
              return (
                <li
                  onClick={(e) => handleClick(e, company, true)}
                  className="user-create-block"
                  key={`create-company-${companyIndex}`}
                >
                  <Avatar
                    className={`user-create-block__avatar
                                ${
                                  showCompanySquare
                                    ? "user-create-block__avatar--square"
                                    : ""
                                }`}
                    isCompany={true}
                    alt={companyName}
                    src={
                      company.logo_meta
                        ? JSON.parse(company.logo_meta).original_photo
                        : ""
                    }
                  />
                  <p
                    className={`user-create-block__name ${
                      !!company.verified && "user-create-block__name--verified"
                    }`}
                  >
                    <UserName
                      isVerified={!!company.verified || false}
                      name={company.name}
                    />
                  </p>
                </li>
              );
            })}
        </ul>
      )}
    </div>
  );
}

UserSelect.propTypes = {
  companies: PropTypes.array,
  profile: PropTypes.object.isRequired,
  handleUserSelectChange: PropTypes.func,
  onlyCompanies: PropTypes.bool,
  canClick: PropTypes.bool,
  isUserCompany: PropTypes.bool,
  isEdit: PropTypes.bool,
  selectedUser: PropTypes.object,
  showCompanySquare: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  history: PropTypes.object,
};

export default withRouter(UserSelect);
