import {
    GET_JOB_PROFILE_REQUEST,
    GET_JOB_PROFILE_SUCCESS,
    GET_JOB_PROFILE_FAILURE
} from './constants';
import axios from 'axios';
import {onRequest, onError, onSuccess} from '../../utils/helper';
import { getBaseUrl, profileLinks } from '../../utils/links';

export function getJobProfile(token) {
    return dispatch => {
        dispatch(onRequest(GET_JOB_PROFILE_REQUEST));
        let url = `${getBaseUrl()}${profileLinks.jobProfiles}`,
            options = {
                method: 'GET',
                url,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };
        return axios(options)
            .then(response => {
                let data = response.data && response.data.data ? response.data.data : null;
                if (data) {
                    dispatch(onSuccess(GET_JOB_PROFILE_SUCCESS, data));
                } else {
                    let err = response.errors;
                    dispatch(onError(GET_JOB_PROFILE_FAILURE, err));
                }
            })
            .catch(err => {
                dispatch(onError(GET_JOB_PROFILE_FAILURE, err));
            });
    };
}