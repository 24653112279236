import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import TextOptions from "./TextOptions";
import TextareaAutosize from "react-autosize-textarea";
import {
  maxFileSize,
  maxPollQuestionImageWidth,
  maxPollQuestionImageHeight,
  pollQuestionImageRatio,
  maxVideoFileSize,
  VIDEO_MODULE_TYPES,
} from "../../utils/commonVariables";
import {
  globalUploadPhotoWithoutAction,
  globalUploadPhotoWithoutActionCancelToken,
  globalUploadVideoWithoutAction,
  globalUploadVideoWithoutActionCancelToken,
} from "../../actions/common/createPost";
import ImageCreateIcon from "../../assets/images/poll/create/image-upload.svg";
import Loader from "../misc/Loader";
import { GIF_TYPE, VIDEO_TYPE, makePlaylistForJWPlayer, twoDecimal } from "../../utils/helper";
import { pollTextLimits } from "../../utils/commonVariables";
import ImageCropperModal from "../create/ImageCropperModal";
import PollMedia from "./PollMedia";
import CommonPortal from "../modals/common/CommonPortal";
import CloseIcon from "../../assets/images/common/icons/close-filled-red.svg";

function ImageQuestionPoll(props) {
  const [imageUploading, setImageUploading] = useState(false);
  const [mediaError, setMediaError] = useState({
    hasError: false,
    fileType: ''
  });
  const [width, setWidth] = useState(maxPollQuestionImageWidth);
  const [heigth, setHeight] = useState(maxPollQuestionImageHeight);
  const [ratio] = useState(pollQuestionImageRatio);
  const [showImageCropper, setShowImageCropper] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState("");

  const ref = useRef(null);
  const {
    isEdit,
    token,
    options,
    editablePost,
    disabled,
    questionImage,
    questionVideo,
    handleQuestionMedia,
    handleOptionsChange,
    handleRemoveOptions,
    handleTitleChange,
  } = props;
  useEffect(() => {
    if (ref.current) {
      const parentElementWidth = ref.current.offsetWidth;
      if (parentElementWidth < width) {
        setWidth(twoDecimal(parentElementWidth));
        setHeight(twoDecimal(parentElementWidth / ratio));
      }
    }
    if (globalUploadPhotoWithoutActionCancelToken)
      globalUploadPhotoWithoutActionCancelToken();

    return () => { if (globalUploadVideoWithoutActionCancelToken) globalUploadVideoWithoutActionCancelToken() };
  }, []);

  const handleMediaChange = (e) => {
    e.preventDefault();
    if (imageUploading) return;

    setMediaError({
      hasError: false
    });
    const files = e.target.files;
    if (!files || (files && !files.length)) return;
    const file = files[0];
    if (file.type.includes("image") && file.size > maxFileSize || file.size > maxVideoFileSize) {
      setMediaError({
        hasError: true,
        fileType: file.type
      });
      return;
    }
    const disableCropperTypes = [GIF_TYPE, VIDEO_TYPE];

    setSelectedMedia(file);
    if (disableCropperTypes.find((_) => file.type.includes(_))) {
      handleUploadMedia(file);
    } else {
      setShowImageCropper(true);
    }
  };


  const handleUploadMedia = async (media) => {

    const isImage = media.type.includes("image");

    var fileOfBlob = new File([media], media.name, {
      type: media.type,
      lastModified: Date.now(),
    });

    let formData = new FormData();
    formData.append(isImage ? "image" : "media_file", fileOfBlob);

    handleMediaUpload(formData, isImage);

  };

  const handleMediaUpload = async (formData, isImage) => {
    try {
      setImageUploading(true)
      let data;
      if (isImage) {
        handleQuestionMedia({ uploadingImage: true });
        data = await globalUploadPhotoWithoutAction(formData, token);
      } else {
        handleQuestionMedia({ uploadingVideo: true });
        data = await globalUploadVideoWithoutAction(formData, VIDEO_MODULE_TYPES.poll, token);
      }
      if (data) {
        handleQuestionMedia(data);
      } else {
      setImageUploading(false);
      handleQuestionMedia(null)
      if(globalUploadVideoWithoutActionCancelToken)
      globalUploadVideoWithoutActionCancelToken()
      }
    } catch (error) {
      console.error("An error occurred during media upload:", error);
      handleQuestionMedia(null);
    } finally {
      setShowImageCropper(false);
      setSelectedMedia("");
      setImageUploading(false);
    }
  };

  const handleVideoDiscard = (e) => {
    handleQuestionMedia(null)
    e.preventDefault();
    if(globalUploadVideoWithoutActionCancelToken)
    globalUploadVideoWithoutActionCancelToken()
  }

  const pollImageStylings = {
    'width': `${width}px`,
    'minHeight': `${heigth - 40}px`
  };

  const isMediaToShow = questionImage || questionVideo;

  const isMediaUploading = (questionImage && questionImage.uploading) || (questionVideo && questionVideo.uploading) || imageUploading;

  // Helper function to render Input field
  const renderInputField = () => (
    <label className="poll-create__image__question__icon" htmlFor="poll-media-input">
      <img src={ImageCreateIcon} alt="poll-question-media" />
      <input
        id="poll-media-input"
        type="file"
        accept="image/*,video/*"
        onChange={handleMediaChange}
      />
      <p className="poll-create__image__question__icon__text">Upload </p>
      <p className="poll-create__image__question__icon__helper">
        jpg, png, gif (Max: 10MB) | Video (Max: 100MB)</p>
    </label>
  );


  return (
    <>
      <div>
        <div className="poll-create__image__question" style={{
          position: "relative"
        }}>
          <div
            className={`poll-create__image__question__image ${Boolean(questionVideo) ? 'video' : ''}`}
            style={Boolean(questionVideo) ? {} : pollImageStylings}
            ref={ref}
          >
            {isMediaUploading ?
              <div className="loader__container"> <span>Uploading</span> <Loader isCentered={true} />
                </div>
              : isMediaToShow ? questionVideo ? <PollMedia
                isVideo
                isEdit
                data={questionVideo}
                handleMediaDiscard={handleVideoDiscard}
              />
                : <PollMedia
                  isEdit
                  data={questionImage}
                  handleMediaChange={handleMediaChange} />
                : renderInputField()
            }
              
          </div>
          {mediaError.hasError && (
            <p className="error-text error-text-left">
              {mediaError.fileType.includes('image') ? <span>Please select image file less than 10MB.</span> : <span>Please select video file less than 100MB.</span>}
            </p>
          )}
        {isMediaUploading && <img style={{
          position: "absolute",
          top: "7px",
          right: "7px"
         }} src={CloseIcon} alt="close-icon" onClick={handleVideoDiscard} />}
        </div>
        {isEdit ? (
          <TextareaAutosize
            className="poll-create-container__title"
            type="text"
            name="title"
            placeholder="Your question..."
            value={props.title}
            onChange={(e) => handleTitleChange(e.target.value)}
            autoComplete="off"
            maxLength={pollTextLimits.title}
            autoFocus={!disabled}
          />
        ) : (
          <TextareaAutosize
            className="poll-create-container__title"
            type="text"
            name="title"
            placeholder="Your question..."
            value={props.title}
            onChange={(e) => handleTitleChange(e.target.value)}
            autoComplete="off"
            maxLength={pollTextLimits.title}
            disabled={disabled}
            autoFocus={!disabled}
          />
        )}
        <TextOptions
          handleOptionsChange={handleOptionsChange}
          handleRemoveOptions={handleRemoveOptions}
          disabled={disabled}
          isEdit={isEdit}
          options={options}
          editablePost={editablePost}
        />
      </div>
      {showImageCropper &&
        <CommonPortal>
          <ImageCropperModal
            modalTitle="Crop Image"
            image={selectedMedia}
            closeModal={() => setShowImageCropper(false)}
            aspectRatio={16 / 9}
            handleImageChange={handleMediaChange}
            handleUploadImage={handleUploadMedia}
          />
        </CommonPortal>}

    </>
  );
}

ImageQuestionPoll.propTypes = {
  options: PropTypes.array,
  handleTitleChange: PropTypes.func,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  handleOptionsChange: PropTypes.func,
  handleRemoveOptions: PropTypes.func,
  isEdit: PropTypes.bool,
  questionImage: PropTypes.object,
  handleQuestionMedia: PropTypes.func,
  token: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    token: state.login.token,
  };
}

export default connect(mapStateToProps)(ImageQuestionPoll);
