import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { connect } from 'react-redux';
import Passbook from '../../../../../components/landingPage/Passbook';
import { getRightSideData, getRightSideDataCancelToken } from '../../../../../actions/landingPage';
import ProductsAvailable from '../../../../../components/landingPage/ProductsAvailable';
import Banner from '../../../../../components/landingPage/Banner';
import TrendingHashtags from '../../../../../components/landingPage/TrendingHashtags';

const RightSidePanel = (props) => {
  const getRightSideComponents = (obj) => {
    const uiType = obj.ui_type;
    switch (uiType) {
      case 'passbook':
        return <Passbook {...props} />;
      case 'product_available':
        return <ProductsAvailable data={obj} {...props} />;
      case 'banner':
        return <Banner data={obj} {...props} />;
      case 'hashtag':
        return <TrendingHashtags maxCount={5} eventLabel="Feed" data={obj} />;
      default:
        return null;
    }
  };

  const { token, getRightSideData, getRightSideDataCancelToken, rightSideData } = props;
  useEffect(() => {
    if (!get(rightSideData, 'data', []).length) getRightSideData(token);

    return () => {
      if (getRightSideDataCancelToken) getRightSideDataCancelToken();
    };
  }, []);

  return (
    <div className="feed-blog">
      {get(rightSideData, 'data', []).map((obj, i) => (
        <React.Fragment key={i}>{getRightSideComponents(obj)}</React.Fragment>
      ))}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.login.token,
    rightSideData: state.landingPageReducer.rightSideData,
  };
};

const mapDispatchToProps = {
  getRightSideData,
  getRightSideDataCancelToken,
};

RightSidePanel.propTypes = {
  token: PropTypes.string,
  getRightSideData: PropTypes.func,
  getRightSideDataCancelToken: PropTypes.func,
  rightSideData: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(RightSidePanel);
