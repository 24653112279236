import { GET_CUISINES_REQUEST,
    GET_CUISINES_SUCCESS,
    GET_CUISINES_FAILURE } from './constants';
import axios from 'axios';
import {onRequest,
    onError,
    onSuccess,
    getResponseData} from '../../utils/helper';
import { getBaseUrl,
    profileLinks } from '../../utils/links';

export function getCuisines(token) {
    return dispatch => {
        dispatch(onRequest(GET_CUISINES_REQUEST));
        let url = `${getBaseUrl()}${profileLinks.cuisines}`,
            options = {
                url,
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };
        return axios(options)
            .then(response => {
                let successData = getResponseData(response);
                if (successData) {
                    dispatch(onSuccess(GET_CUISINES_SUCCESS, successData));
                } else {
                    dispatch(onError(GET_CUISINES_FAILURE, response.data.errors));
                }
                return successData;
            })
            .catch(err => {
                dispatch(onError(GET_CUISINES_FAILURE, err));
                return false;
            });
    };
}