import React from "react";
import PropTypes from "prop-types";
import ImageOptions from "./ImageOptions";
import PollText from "./PollText";

function ImageOptionPoll(props) {
  const {
    isEdit,
    disabled,
    options,
    handleOptionsChange,
    handleRemoveOptions,
    editablePost,
  } = props;
  return (
    <>
      <PollText {...props} />
      <ImageOptions
        handleOptionsChange={handleOptionsChange}
        handleRemoveOptions={handleRemoveOptions}
        isEdit={isEdit}
        disabled={disabled}
        options={options}
        editablePost={editablePost}
      />
    </>
  );
}

ImageOptionPoll.propTypes = {
  options: PropTypes.array,
  handleTitleChange: PropTypes.func,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  handleOptionsChange: PropTypes.func,
  handleRemoveOptions: PropTypes.func,
  isEdit: PropTypes.bool,
};

export default ImageOptionPoll;
