import { GET_COLLABORATE_APPLICANTS_REQUEST,
    GET_COLLABORATE_APPLICANTS_FAILURE,
    GET_COLLABORATE_APPLICANTS_SUCCESS,
    GET_COLLABORATE_REJECTED_APPLICANTS_REQUEST,
    GET_COLLABORATE_REJECTED_APPLICANTS_SUCCESS,
    GET_COLLABORATE_REJECTED_APPLICANTS_FAILURE
 } from '../.././actions/constants';

 const INITIAL_STATE = {
    applicantsData: {
        data: {},
        fetching: false,
        error: false
    },
    rejectedApplicantsData: {
        data: {},
        fetching: false,
        error: false
    },
    fetching: false,
    error: false
}

export default function manage(state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_COLLABORATE_APPLICANTS_REQUEST:
            return {
                ...state,
                applicantsData:  {
                    ...state.applicantsData,
                    fetching: true
                }
            }
        case GET_COLLABORATE_APPLICANTS_SUCCESS:
            return {
                ...state,
                applicantsData:  {
                    ...state.applicantsData,
                    fetching: false,
                    data: action.payload
                }
            }
        case GET_COLLABORATE_APPLICANTS_FAILURE:
            return {
                ...state,
                applicantsData:  {
                    ...state.applicantsData,
                    fetching: false,
                    error: action.error
                }
            }
        case GET_COLLABORATE_REJECTED_APPLICANTS_REQUEST:
            return {
                ...state,
                rejectedApplicantsData:  {
                    ...state.rejectedApplicantsData,
                    fetching: true
                }
            }
        case GET_COLLABORATE_REJECTED_APPLICANTS_SUCCESS:
            return {
                ...state,
                rejectedApplicantsData:  {
                    ...state.rejectedApplicantsData,
                    fetching: false,
                    data: action.payload
                }
            }
        case GET_COLLABORATE_REJECTED_APPLICANTS_FAILURE:
            return {
                ...state,
                rejectedApplicantsData:  {
                    ...state.rejectedApplicantsData,
                    fetching: false,
                    error: action.error
                }
            }
        default:
            return state
    }
}
