import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import ProfilePrompts from '../profilePrompts';

function PromptCommonModal(props) {
    const [promptModals, setPromptModals] = useState(props.promptModals);
    useEffect(() => {
        setPromptModals(props.promptModals);
    }, [props.promptModals]);
    return (<>
        {
            promptModals
            && promptModals.profilePrompts
            && promptModals.profilePrompts.isVisible
            && (<ProfilePrompts
                {...promptModals.profilePrompts}
            />)
        }
    </>);
};

function mapStateToProps(state) {
    return {
        promptModals: state.modalReducer.promptModals
    }
}

export default connect(mapStateToProps)(PromptCommonModal);