import { DELETE_A_COMMENT_REQUEST,
    DELETE_A_COMMENT_SUCCESS,
    DELETE_A_COMMENT_FAILURE,
    GET_A_POST_COMMENT_REQUEST,
    GET_A_POST_COMMENT_SUCCESS,
    GET_A_POST_COMMENT_FAILURE,
    TOGGLE_POST_COMMENT_CONTAINER,
    RESET_COMMENTS } from './constants';
import { onRequest,
    getResponseData,
    onSuccess,
    onError } from '../../utils/helper';
import { getBaseUrl,
    postUrls } from '../../utils/links';
import Axios from 'axios';
const CancelToken = Axios.CancelToken; //cancel token for requests

// action to delete a post comment
export const deleteAComment = (commentId,
    postType,
    postId,
    token,
    isShared,
    sharedId,
    isAd) => {
    const dataToSend = {
        commentId,
        postType,
        postId,
        isShared,
        sharedId,
        isAd
    }
    return dispatch => {
        dispatch(onRequest(DELETE_A_COMMENT_REQUEST, dataToSend));
        let url = isShared
            ? `${getBaseUrl()}${postUrls.deleteAShowPostComment(commentId,
                postType,
                sharedId)}`
            : `${getBaseUrl()}${postUrls.deleteComment(commentId,
                postType,
                postId)}`,
            options = {
                url,
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };
        return Axios(options)
            .then(response => {
                let successData = getResponseData(response);
                if (successData) {
                    dispatch(onSuccess(DELETE_A_COMMENT_SUCCESS, {
                        ...dataToSend,
                        ...successData
                    }));
                    return true
                } else {
                    dispatch(onError(DELETE_A_COMMENT_FAILURE,
                        response.data.errors,
                        dataToSend));
                    return false;
                }
            })
            .catch(error => {
                dispatch(onError(DELETE_A_COMMENT_FAILURE,
                    error,
                    dataToSend));
            })
    }
}

// action to get a post comments
export let getAPostCommentCancelToken = undefined;
export const getAPostComment = (postType,
    postId,
    token,
    page = 1,
    fetchType = null,
    isShared = false,
    sharedId = null,
    isAd = false) => {
    return dispatch => {
        if (getAPostCommentCancelToken) getAPostCommentCancelToken();
        dispatch(onRequest(GET_A_POST_COMMENT_REQUEST, {
            postType,
            postId,
            isShared,
            sharedId,
            page,
            isAd,
            fetchingPrev: (fetchType === 'prev'),
            fetchingNext: (fetchType === 'next') }));
        let url = (isShared
            ? `${getBaseUrl()}${postUrls.sharePostComments(postType, sharedId)}`
            : `${getBaseUrl()}${postUrls.comments(postType, postId)}`),
            options = {
                url,
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                cancelToken: new CancelToken((c) => getAPostCommentCancelToken = c),
                params: {
                    page
                }
            };
        return Axios(options)
            .then(response => {
                let data = getResponseData(response);
                if (data) {
                    let payload = {
                        postId,
                        postType,
                        isShared,
                        sharedId,
                        isAd,
                        comments: data.data,
                        count: data.count,
                        nextPage: data.next_page,
                        previousPage: data.previous_page,
                        fetchingPrev: (fetchType === 'prev'),
                    }
                    dispatch(onSuccess(GET_A_POST_COMMENT_SUCCESS, payload))
                } else {
                    dispatch(onError(GET_A_POST_COMMENT_FAILURE, response.data.error))
                }
                return data
            })
            .catch(error => {
                dispatch(onError(GET_A_POST_COMMENT_FAILURE, error));
            })
    }
}

// function to open or close comments box
export const toggleComments = (postType,
    postId,
    isShared,
    sharedId,
    isAd) => onSuccess(TOGGLE_POST_COMMENT_CONTAINER, {postType,
        postId,
        isShared,
        sharedId,
        isAd});


// function to reset comments
export const resetComments = () => ({type: RESET_COMMENTS});