import Axios from 'axios';
import { getBaseUrl, paymentUrls } from '../../../utils/links';
import { getResponseDataWithStatus } from '../../../utils/helper';
const CancelToken = Axios.CancelToken;

export let getPassbookTransactionOverviewCancelToken = undefined;
export const getPassbookTransactionOverview = (token, params = {}) => {
  if (getPassbookTransactionOverviewCancelToken) getPassbookTransactionOverviewCancelToken();
  const url = `${getBaseUrl()}${paymentUrls.overview}`;

  const options = {
    url,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
    cancelToken: new CancelToken((c) => (getPassbookTransactionOverviewCancelToken = c)),
  };
  return Axios(options)
    .then((response) => {
      return getResponseDataWithStatus(response);
    })
    .catch(() => false);
};

export let getPassbookTransactionFiltersCancelToken = undefined;
export const getPassbookTransactionFilters = (token, params = {}) => {
  if (getPassbookTransactionFiltersCancelToken) getPassbookTransactionFiltersCancelToken();
  const url = `${getBaseUrl()}${paymentUrls.filters}`;

  const options = {
    url,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
    cancelToken: new CancelToken((c) => (getPassbookTransactionFiltersCancelToken = c)),
  };
  return Axios(options)
    .then((response) => {
      return getResponseDataWithStatus(response);
    })
    .catch(() => false);
};

export let getPassbookTransactionsCancelToken = undefined;
export const getPassbookTransactions = (token, transactionId = '', params = {}) => {
  if (getPassbookTransactionsCancelToken) getPassbookTransactionsCancelToken();
  const url = `${getBaseUrl()}${paymentUrls.transactions(transactionId)}`;

  const options = {
    url,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
    cancelToken: new CancelToken((c) => (getPassbookTransactionsCancelToken = c)),
  };
  return Axios(options)
    .then((response) => {
      return getResponseDataWithStatus(response);
    })
    .catch(() => false);
};

export let postTransactionRaiseConcernCancelToken = undefined;
export const postTransactionRaiseConcern = (token, transactionId, data, params = {}) => {
  if (postTransactionRaiseConcernCancelToken) postTransactionRaiseConcernCancelToken();
  const url = `${getBaseUrl()}${paymentUrls.raiseConcern(transactionId)}`;

  const options = {
    url,
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
    params,
    cancelToken: new CancelToken((c) => (postTransactionRaiseConcernCancelToken = c)),
  };
  return Axios(options)
    .then((response) => {
      return getResponseDataWithStatus(response);
    })
    .catch(() => false);
};

export let getPaymentReviewConditionsCancelToken = undefined;
export const getPaymentReviewConditions = (token, type, id = '', params = {}) => {
  if (getPaymentReviewConditionsCancelToken) getPaymentReviewConditionsCancelToken();
  const url = `${getBaseUrl()}${paymentUrls.reviewConditions(type, id)}`;

  const options = {
    url,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
    cancelToken: new CancelToken((c) => (getPaymentReviewConditionsCancelToken = c)),
  };
  return Axios(options)
    .then((response) => {
      return getResponseDataWithStatus(response);
    })
    .catch(() => false);
};
