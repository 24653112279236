import {
    TOGGLE_SHOW_INTEREST_MODAL,
    HIDE_SHOW_INTEREST_MODAL,
    SHOW_DONATION_MODAL,
    HIDE_DONATION_MODAL,
    SAVE_DONATION_DATA_SUCCESS,
    SHOW_INVITTAION_MODAL,
    HIDE_INVITATION_MODAL
} from '../../actions/common/constants';

const INITIAL_STATE = {
    showInterestModal: {
        isVisible: false
    },
    showDonationModal: {
        isVisible: false,
        type: null
    },
    showInvitationModal: {
        isVisible: false,
        data: {}
    },
    donationPrefrence: {
        donate: "",
        preference: false,
        terms: false,
        organization: null
    }
}

export default function collaborations(state = INITIAL_STATE, action) {
    switch (action.type) {
        case TOGGLE_SHOW_INTEREST_MODAL:
            return {
                ...state,
                showInterestModal: {
                    isVisible: !state.showInterestModal.isVisible
                }
            }
        case HIDE_SHOW_INTEREST_MODAL:
            return {
                ...state,
                showInterestModal: {
                    isVisible: false
                }
            }
        case SHOW_DONATION_MODAL:
            return {
                ...state,
                showDonationModal: {
                    isVisible: true,
                    type: action.payload.type
                }
            }
        case HIDE_DONATION_MODAL:
            return {
                ...state,
                showDonationModal: {
                    isVisible: false,
                    type: action.payload.type
                }
            }
        case SHOW_INVITTAION_MODAL:
            return {
                ...state,
                showInvitationModal: {
                    isVisible: true,
                    data: action.payload.data
                }
            }
        case HIDE_INVITATION_MODAL:
            return {
                ...state,
                showInvitationModal: {
                    ...state.showInvitationModal,
                    isVisible: false,
                }
            }
        case SAVE_DONATION_DATA_SUCCESS:
            return {
                ...state,
                donationPrefrence: action.payload.data
            }
        default:
            return state
    }
}