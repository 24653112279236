import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Avatar from '../user/Avatar';
import { collaborationInternalPath } from '../../utils/collaborationHelper';
import { companiesLink,
    profileLinks } from '../../utils/internalLinks';
import DownwardArrow from '../../assets/images/common/suggestions/directional-down-arrow.svg';
import { decodeString } from '../../utils/helper';
import UserName from '../profile/UserName';

function CollaborationSuggestion(props) {
    const [suggestions, setSuggestions] = useState(props.suggestions || []),
        [subTitle, setSubTitle] = useState(props.subTitle || 'Suggested for you');
    useEffect(() => {
        setSuggestions(props.suggestions);
        setSubTitle(props.subTitle);
    }, [props.suggestions, props.title, props.subTitle]);
    if (!suggestions.length) return null;
    function goToLink(e, link) {
        if (e) e.preventDefault();
        const { history } = props;
        history.push(link);
    }
    return (<div className="suggestions">
            <div className="suggestions__info">
                <p className="suggestions__info__title">{subTitle}</p>
            </div>
            <ul className="suggestions__collaboration__list">
                {
                    suggestions.map((_, i) => {
                        const collabId = _.id,
                            collabLink = collaborationInternalPath.view(_.collaborate_type, collabId),
                            title = decodeString(_.title),
                            isCompany = !!_.company_id,
                            ownerImg = isCompany
                                ? _.company
                                ? _.company.logo_meta
                                : null
                                : _.profile
                                ? _.profile.image_meta
                                : null,
                            ownerImgToShow = ownerImg
                                ? JSON.parse(ownerImg).original_photo
                                : '',
                            ownerLink = isCompany
                                ? _.company
                                ? companiesLink.view(_.company.id)
                                : profileLinks.view(_.profile.handle)
                                : profileLinks.view(_.profile.handle),
                            ownerName = isCompany
                                ? _.company
                                ? _.company.name
                                : _.profile.name
                                : _.profile.name,
                            isVerified = isCompany
                                && _.company
                                ? !!_.company.verified
                                : !!_.profile.verified;
                        return (<li key={`suggestion-${collabId}-${i}`}>
                            <Link to={collabLink} className="suggestions__collaboration__list__item">
                                <div onClick={(e) => goToLink(e, ownerLink)}
                                    className="suggestions__collaboration__list__icon">
                                    <Avatar alt={ownerName}
                                        isCompany={isCompany}
                                        src={ownerImgToShow} />
                                </div>
                                <div className="suggestions__collaboration__list__info">
                                    <p className="suggestions__collaboration__list__title">{title}</p>
                                    <p onClick={(e) => goToLink(e, ownerLink)} className={`suggestions__collaboration__list__helper ${isVerified && 'suggestions__collaboration__list__helper--verified'}`}>
                                        <UserName name={ownerName}
                                            isVerified={isVerified} />
                                    </p>
                                </div>
                                <p className="suggestions__collaboration__list__arrow"><img src={DownwardArrow} alt={`open ${title}`} /></p>
                            </Link>
                        </li>)
                    })
                }
            </ul>
        </div>)
};

export default withRouter(CollaborationSuggestion);