import { GET_COMPANY_DETAILS_REQUEST,
    GET_COMPANY_DETAILS_SUCCESS,
    GET_COMPANY_DETAILS_FAILURE,
    UPDATE_COMPANY_PROFILE_SUCCESS,
    RESET_COMPANY_PROFILE,
    UPDATE_COMPANY_INFO_SUCCESS,
    FOLLOW_COMPANY_SUCCESS,
    UNFOLLOW_COMPANY_SUCCESS,
    DELETE_COMPANY_INFO_SUCCESS} from '../../actions/company/constants';
import { DELETE_A_POST_SUCCESS } from '../../actions/common/constants';
import { REMOVE_COMPANY_IMAGE_SUCCESS,REMOVE_COMPANY_COVER_IMAGE_SUCCESS } from '../../actions/profile/constants';


const INITIAL_STATE = {
    fetching: false,
    company: null,
    error: false,
    errorMsg: null,
    hasFetched: false
}

export default function view(state = INITIAL_STATE, action) {
    switch(action.type) {
        case GET_COMPANY_DETAILS_REQUEST:
            return {
                ...state,
                fetching: true,
                hasFetched: false
            }
        case GET_COMPANY_DETAILS_SUCCESS:
            return {
                ...state,
                fetching: false,
                hasFetched: true,
                company: action.payload
            }
        case GET_COMPANY_DETAILS_FAILURE:
            return {
                ...state,
                fetching: false,
                error: true,
                errorMsg: action.error
            }
        case UPDATE_COMPANY_PROFILE_SUCCESS:
            return {
                ...state,
                company: action.payload
            }
        case DELETE_COMPANY_INFO_SUCCESS:
            return {
                ...state,
                company: {
                    ...state.company,
                    [action.payload.infoType]: state.company[action.payload.infoType]
                        ? state.company[action.payload.infoType].filter(_ => _.id !== action.payload.infoId)
                        : state.company[action.payload.infoType]
                }
            }
        case UPDATE_COMPANY_INFO_SUCCESS:
            return {
                ...state,
                company: {
                    ...state.company,
                    [action.payload.infoType]: action.payload.obj
                        ? action.payload.isEdit
                        ? state.company[action.payload.infoType].map(_ => {
                            if (_.id !== action.payload.obj.id) return _;
                            return {
                                ..._,
                                ...action.payload.obj
                            }
                        })
                        : [...state.company[action.payload.infoType], action.payload.obj]
                        : state.company[action.payload.infoType]
                }
            }
        case DELETE_A_POST_SUCCESS:
            return {
                ...state,
                company: state.hasFetched
                    ? {
                        ...state.company,
                        ...(state.company.id === action.payload.companyId && {
                            totalPostCount: state.company.totalPostCount - 1 < 0
                                ? 0
                                : state.company.totalPostCount - 1,
                            imagePostCount: action.payload.postType === 'photo'
                                ? (state.company.imagePostCount - 1 < 0)
                                ? 0
                                : state.company.imagePostCount - 1
                                : state.company.imagePostCount
                        })
                    }
                    : state.company
            }
        case FOLLOW_COMPANY_SUCCESS:
            return {
                ...state,
                company: state.hasFetched
                    ? {
                        ...state.company,
                        ...((state.company.id === action.payload.companyId && {
                            isFollowing: true
                        }))
                    }
                    : state.company
            }
        case UNFOLLOW_COMPANY_SUCCESS:
                return {
                    ...state,
                    company: state.hasFetched
                        ? {
                            ...state.company,
                            ...((state.company.id === action.payload.companyId && {
                                isFollowing: false
                            }))
                        }
                        : state.company
                }

                case REMOVE_COMPANY_IMAGE_SUCCESS :
                    return {
                        ...state,company:{
                            ...state.company,
                            logo_meta:null
                        }
                       
                    }
                    case REMOVE_COMPANY_COVER_IMAGE_SUCCESS :{
                        return{
                            ...state,company:{
                                ...state.company,
                                hero_image_meta:null
                            }
                        }
                    }
        case RESET_COMPANY_PROFILE:
            return INITIAL_STATE
        default:
            return state;
    }
}