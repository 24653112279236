import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import NonPaidUserOnboardModal from './NonPaidUserOnboardModal';
import TransactionDetailsModal from './TransactionDetailsModal';
import BeforeStartReviewModal from './BeforeStartReviewModal';
import ReviewSubmissionModal from './ReviewSubmissionModal';

const modalType = {
  nonPaidUserOnboard: NonPaidUserOnboardModal,
  transactionDetails: TransactionDetailsModal,
  beforeStartReview: BeforeStartReviewModal,
  reviewSubmission: ReviewSubmissionModal,
};

const PassbookModal = ({ passbookModals }) => {
  const { type, data, isVisible } = passbookModals;
  if (!isVisible || (isVisible && !modalType[type])) return null;
  const ElementToShow = modalType[type];
  return <ElementToShow data={data} />;
};

const mapStateToProps = (state) => {
  return {
    passbookModals: state.modalReducer.profileModals.profilePassbook,
  };
};

PassbookModal.propTypes = {
  passbookModals: PropTypes.object,
};

export default connect(mapStateToProps)(PassbookModal);
