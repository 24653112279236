import React, { useEffect, useState } from "react";
import {
  setHideDonationModal,
  saveDonationModalData,
} from "../../../actions/common/modals";
import { connect } from "react-redux";
import Modal from "../common/Modal";
import DonationRupeeImage from "../../../assets/images/donation/donation-rupee.png";
import ChevronDown from "../../../assets/images/chevrons/icon_chevron_down_curved.svg";
import AsyncSelect from "react-select/async";
import {
  getDonationOrganizations,
  postDonationOrganization,
} from "../../../actions/common/donation";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { miscLinks } from "../../../utils/internalLinks";
import { components } from "react-select";
import { prefrenceData } from "../../../utils/tastingHelper";
import { isEmpty, isEqual } from "lodash";

const SelectDonationOrg = ({ token, handleMenuData, menuData }) => {
  const organizationToSelect = (menuData && menuData.donate) === "donate" ? (menuData && menuData.organization) : null;
  const { id, title, image_url } = organizationToSelect
  const formatedSelectedOrganization = id ? {
    value: id,
    label: title,
    image: image_url
  } : null;
  const loadOptions = (value, callback) => {
    getDonationOrganizations(token).then((_) => {
      if (_) {
        const options = _.map((org) => ({
          value: org.id,
          label: org.title,
          image: org.image_url,
          original_data: org,
        }));

        callback(options);
      }
      return false;
    });
  };

  const CustomOption = (props) => {
    const {
      innerProps,
      isDisabled,
      children,
      data: { image },
    } = props;

    return !isDisabled ? (
      <div className="donation__organisations--list__item" {...innerProps}>
        <div className="image__wrapper">
          <img
            className="image"
            src={image}
            alt={`select option ${children}`}
          />
        </div>
        <h5 className="title">{children}</h5>
      </div>
    ) : null;
  };

  const CustomPlaceholder = (props) => {
    return (
      <components.Placeholder {...props}>
        <div className="placeholder">
          <span>Select Foundation or Charity</span>
        </div>
      </components.Placeholder>
    )
  }

  const CustomDropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={ChevronDown} alt="select-org" />
      </components.DropdownIndicator>
    )
  }

  const CustomSingleValue = (props) => {
    const { data } = props;
    const { image, label } = data;
    return (
      <components.SingleValue {...props}>
        <div className="value">
          <div className="image__wrapper">
            <img
              className="image"
              src={image}
              alt={`value-${label}`}
            />
          </div>
          <h5 className="title">{label}</h5>
        </div>
      </components.SingleValue>
    );
  };
  const handleChange = (organization, ...data) => {
    handleMenuData((menuData) => {
      return { ...menuData, ["organization"]: organization.original_data };
    });
  };

  return (

    <AsyncSelect
      isSearchable={false}
      cacheOptions
      components={{
        IndicatorSeparator: () => null,
        Option: CustomOption,
        SingleValue: CustomSingleValue,
        Placeholder: CustomPlaceholder,
        DropdownIndicator: CustomDropdownIndicator,
      }}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          flexWrap: 'nowrap',
          boxShadow: '0px 1px 2px 0px #00000026',
          borderRadius: '12px',
          borderColor: '#F2F2F2'
        }),
        container: (baseStyles, state) => ({
          ...baseStyles,
          marginTop: "12px"
        }),
        valueContainer: (baseStyles) => ({
          ...baseStyles,
          minHeight: '64px'
        }),
        dropdownIndicator: (baseStyles) => ({
          ...baseStyles,
          marginRight: '8px'
        }),

      }}
      onChange={handleChange}
      defaultValue={formatedSelectedOrganization}
      loadOptions={loadOptions}
      defaultOptions
      placeholder="Select Foundation or Charity"
      className="donation__organisations--list"
    />


  );
};

const DonationMenu = ({
  token,
  menuData,
  handleMenuData,
  type,
  profile,
  setChangeData
}) => {
  const { donate, preference, terms, organization } = menuData;
  const handleClick = (e) => {
    const { value, name, type, checked } = e.target;
    setChangeData(true)
    handleMenuData((menuData) => {
      return type === 'checkbox'
        ? { ...menuData, [name]: checked }
        : { ...menuData, [name]: value };
    });
  };

  const showDonationList = donate === "donate";
  const showPrefrenceCheck = profile.is_donation === null && type !== "profile";

  return (
    <>
      <div className="menu">
        <div className="menu__item">
          <div className="menu__item--input__wrapper">
            <input
              type="radio"
              name="donate"
              value="donate"
              id="radio-donate"
              onClick={handleClick}
              className={`menu__item--input ${donate === "donate" ? 'checked' : ''}`}
            />
            <label htmlFor="radio-donate" className="menu__item--label">
              Donate
            </label>
          </div>
          {showDonationList && (
            <SelectDonationOrg
              token={token}
              handleMenuData={handleMenuData}
              menuData={menuData}
            />
          )}
        </div>
        <div className="menu__item">
          <div className="menu__item--input__wrapper">
            <input
              type="radio"
              name="donate"
              value="self"
              id="radio-self"
              onClick={handleClick}
              className={`menu__item--input ${donate === "self" ? 'checked' : ''}`}
            />
            <label htmlFor="radio-self" className="menu__item--label">
              Self
            </label>
          </div>
        </div>
      </div>
      <div className="menu">
        {showPrefrenceCheck && (
          <div className="menu__item">
            <div className="menu__item--input__wrapper">
              <input
                type="checkbox"
                name="preference"
                id="checkbox-preference"
                onClick={handleClick}
                className={`menu__item--input ${preference ? 'checked' : ''}`}
                checked={preference}
              />
              <label
                htmlFor="checkbox-preference"
                className="menu__item--label"
              >
                Save my preference and don’t ask again. You can change it later.
              </label>
            </div>
          </div>
        )}

        {showPrefrenceCheck && (
          <div className="menu__item">
            <div className="menu__item--input__wrapper">
              <input
                type="checkbox"
                name="terms"
                checked={terms}
                id="checkbox-terms"
                onClick={handleClick}
                className={`menu__item--input ${terms ? 'checked' : ''}`}
              />
              <label htmlFor="checkbox-terms" className="menu__item--label">
                Accept{" "}
                <Link to={miscLinks.terms} className="terms__link">
                  Terms & Conditions{" "}
                </Link>
              </label>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const DonationModal = (props) => {
  const {
    token,
    showDonationModal: { isVisible, type },
    user: { user: { profile } },
    setHideDonationModal,
    handleDonation,
    saveDonationModalData,
    isPublic = true
  } = props;

  const [menuData, setMenuData] = useState({});
  const { terms, donate, organization } = menuData;
  const [changeData, setChangeData] = useState(false);
  const handleClose = () => {
    setHideDonationModal();
  };
  const handleMenuData = (menuData) => {
    setMenuData(menuData)
  };
  const handleSubmit = () => {
    const { donate, preference, organization } = menuData;
    const { postDonationOrganization, saveDonationModalData } = props
    if (preference || type === "profile") {
      const data = {
        is_donation: donate == "donate" ? 1 : 0,
        donation_organisation: donate == "donate" ? organization : null,
      };
      postDonationOrganization(token, data).then((_) => {
      });
    }
    saveDonationModalData(menuData)
    handleDonation();
  };
  useEffect(() => {
    setMenuData(prefrenceData(profile))
  }, [profile])
  if (!isVisible) return null;
  const isActionDisabled = () => {
    if (profile.is_donation !== null) {
      const isDatachanged = isEqual(prefrenceData(profile), menuData);
      if (isDatachanged) return true;
    }
    let isDisabled = !terms || !donate || (donate == "donate" && isEmpty(organization))
    return isDisabled
  }
  return (
    <>
      <Modal
        showHeader
        handleClose={handleClose}
        containerClassName="donation__modal--container"
      >
        <div className="donation">
          <div className="donation__header">
            <img src={DonationRupeeImage} alt="rupee" />
            <h4>Reward Preference</h4>
            <p>Select your reward preference for paid activity</p>
          </div>
          <hr className="donation__horizontal" />
          <div className="donation__content">
            <h4 className="title">
              What would you prefer to do with your reward for this activity?
            </h4>
            <DonationMenu
              token={token}
              handleMenuData={handleMenuData}
              menuData={menuData}
              type={type}
              profile={profile}
              setChangeData={setChangeData}
            />
          </div>
          <div className="donation__footer">
            <button
              className={`btn ${type} ${!isPublic ? "public" : ""}`}
              onClick={handleSubmit}
              disabled={isActionDisabled()}
            >
              {profile.is_donation == null ? "Continue" : "Save"}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};
function mapStateToProps(state) {
  return {
    token: state.login.token,
    showDonationModal: state.modalReducer.collaboration.showDonationModal,
    user: state.user,
  };
}

const mapDispatchToProps = {
  setHideDonationModal,
  saveDonationModalData,
  postDonationOrganization
};

export default connect(mapStateToProps, mapDispatchToProps)(DonationModal);
