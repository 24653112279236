import { GET_PRODUCT_REPORT_SUMMARY_REQUEST,
    GET_PRODUCT_REPORT_SUMMARY_SUCCESS,
    GET_PRODUCT_REPORT_SUMMARY_FAILURE,
    RESET_PRODUCT_REPORT_SUMMARY } from "../../actions/products/constants";

const INITIAL_STATE = {
    fetching: false,
    summary: null,
    productId: null,
    error: false
}

export default function productSummary(state = INITIAL_STATE, action) {
    switch(action.type) {
        case GET_PRODUCT_REPORT_SUMMARY_REQUEST:
            return {
                ...state,
                fetching: true
            }
        case GET_PRODUCT_REPORT_SUMMARY_SUCCESS:
            return {
                ...state,
                summary: action.payload.summary,
                productId: action.payload.productId
            }
        case GET_PRODUCT_REPORT_SUMMARY_FAILURE:
            return {
                ...state,
                error: true,
                errorMsg: action.payload.error
            }
        case RESET_PRODUCT_REPORT_SUMMARY:
            return INITIAL_STATE;
        default:
            return state;
    }
}