import { ttfbLinks } from "../../utils/internalLinks";

const sideBarObj = {
  overview: {
    title: "Overview",
    link: (groupId) => ttfbLinks.overview,
  },
  manufacturer: {
    title: "Manufacturer",
    link: (groupId) => ttfbLinks.manufacturers,
  },
  all_products: {
    title: "All Products",
    link: (groupId) => ttfbLinks.products,
  },
  distributor: {
    title: "Distributor",
    link: (groupId) => ttfbLinks.distributors,
  },
  partner: {
    title: "Partner",
    link: (groupId) => ttfbLinks.partners,
  },
  track_consistency: {
    title: "Track Consistency",
    link: (groupId) => ttfbLinks.trackConsistency(groupId),
  },
};

const BUSINESS_MODULES_SUPPORTED = {
  lead_management: {
    overview: {
      title: "Overview",
      // link: (groupId) => ttfbLinks.overview,
      link: (groupId) => ttfbLinks.leadsManagement.overview(groupId),
    },
    manufacturers: {
      title: "Manufacturers",
      // link: (groupId) => ttfbLinks.manufacturers,
      link: (groupId) => ttfbLinks.leadsManagement.manufacturers(groupId),
    },
    all_products: {
      title: "All Products",
      // link: (groupId) => ttfbLinks.products,
      link: (groupId) => ttfbLinks.leadsManagement.products(groupId),
    },
    distributor: {
      title: "Distributors",
      // link: (groupId) => ttfbLinks.distributors,
      link: (groupId) => ttfbLinks.leadsManagement.distributors(groupId),
    },
    partner: {
      title: "Partners",
      // link: (groupId) => ttfbLinks.partners,
      link: (groupId) => ttfbLinks.leadsManagement.partners(groupId),
    },
  },
  product_consistency: {
    track_consistency: {
      title: "Track Consistency",
      link: (groupId) => ttfbLinks.consistency.index(groupId),
    },
  },
  main_group: "main_group",
};

export { sideBarObj, BUSINESS_MODULES_SUPPORTED };
