import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { postApplication } from '../../../actions/careers';
import close from '../../../assets/images/close.svg'
import { genericErrorText, readMoreLength } from '../../../utils/commonVariables';
import Captcha from '../../../components/misc/Captcha';

class CareerModal extends Component {
    render() {
        const { selectedJob } = this.props;
        return (<div className='career-modal-backdrop'>
            <div className='close-btn-container cursor-pointer'>
                <img src={close} alt="close modal" className='close-button' onClick={this.props.onClose} />
            </div>
            <div className='career-modal'>
                <div>
                    {
                        selectedJob
                            ? (<div className='career-header-container'>
                                <h2 className='career-heading'>
                                    {selectedJob.designation}
                                </h2>
                                <p className='career-mini-description'>
                                    <span>{`${selectedJob.location}`}</span>
                                    {selectedJob.experience ?
                                        <>
                                            <span> &bull; </span>
                                            <span>{`${selectedJob.experience}`}</span>
                                        </>
                                        : (null)}
                                </p>
                            </div>)
                            : (null)
                    }
                    {
                        selectedJob
                            ? (<div className='career-modal-content'>
                                <div className='career-description-container'>
                                    {
                                        selectedJob.description ?
                                            (<div className="career-description-block">
                                                <h2 className='career-head'>About the Profile</h2>
                                                <p className='career-description'>{selectedJob.description}</p>
                                            </div>) : (null)
                                    }

                                    <h2 className='career-head'>An Average Day For You</h2>
                                    <ul className="career-description-block">
                                        {selectedJob.roles
                                            ? selectedJob.roles.map((role, roleIndex) => {
                                                return (
                                                    <li key={roleIndex} className='career-description'>{role}</li>
                                                )
                                            }) : (null)}
                                    </ul>

                                    <h2 className='career-head'>Our Requirements</h2>
                                    <ul className="career-description-block">
                                        {selectedJob.requirements
                                            ? selectedJob.requirements.map((requirement, roleIndex) => {
                                                return (
                                                    <li key={roleIndex} className='career-description'>{requirement}</li>
                                                )
                                            }) : (null)}
                                    </ul>
                                    <h2 className='career-head'>We would love to see</h2>
                                    <ul className="career-description-block">
                                        {selectedJob.preferred
                                            ? selectedJob.preferred.map((prefer, roleIndex) => {
                                                return (
                                                    <li key={roleIndex} className='career-description'>{prefer}</li>
                                                )
                                            }) : (null)}
                                    </ul>
                                </div>
                                <div className='career-form-container'>
                                    <CareerForm selectedJob={selectedJob} />
                                </div>
                            </div>)
                            : (null)
                    }
                </div>
            </div>
        </div>);
    }
}
class CareerForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            'name': '',
            'email': '',
            'description': '',
            submitting: false,
            submitted: false,
            fileError: false,
            selectedJob: props.selectedJob,
            isCaptchaVerified: false,
        }
        this.filesInput = React.createRef();
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCaptcha = this.handleCaptcha.bind(this);

    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.isSubmitDisabled()) return;
        this.setState({
            submitting: true
        })
        let formData = new FormData(e.target);
        postApplication(formData)
            .then(_ => {
                this.setState({
                    submitting: false
                });
                if (!_) {
                    alert(genericErrorText.general)
                } else {
                    this.setState({
                        submitted: true
                    });
                };
            });
    }

    handleButtonClick() {
        if (this.filesInput.current) {
            this.filesInput.current.click();
        }
    }

    handleEmailChange(event) {
        this.setState({ email: event.target.value });
    }

    handleNameChange(event) {
        this.setState({ name: event.target.value });
    }

    handleFileChange(e) {
        this.setState({
            fileError: false
        })
        let file = e.target.files
            && e.target.files.length
            ? e.target.files[0]
            : null;
        if (!file) {
            return;
        }
        let fileSize = file.size / 1024 / 1024;
        if (fileSize > 5
            || !file.type.includes('pdf')) {
            this.setState({
                fileError: true
            });
            this.filesInput.current.value = null;
            return;
        }
    }

    handleCaptcha(token) {
        this.setState({ isCaptchaVerified: !!token });
    }

    isSubmitDisabled = () => {
        const isFileUploaded = this.filesInput.current
            && this.filesInput.current.files
            && !!this.filesInput.current.files.length
            && this.filesInput.current.files[0].name;
        const isDisabled = this.state.submitting || this.state.fileError || !this.state.isCaptchaVerified || !this.state.name.trim().length || !this.state.email.trim().length || !isFileUploaded;
        return isDisabled;
    }


    render() {

        return (
            <div>
                {
                    this.state.submitted
                        ? <div className="career-application-form">
                            <h2 className="career-head">Apply Now</h2>
                            <p className="career-description">Your application has been submitted successfully. Good luck!</p>
                        </div>
                        : <form onSubmit={this.handleSubmit} className='career-application-form'>
                            <h2 className="career-head">Apply Now</h2>
                            <div className="application-form-block">
                                <label htmlFor="name" className="application-form-label"><sup className='error'>*</sup>Name</label>
                                {this.state.selectedJob
                                    && this.state.selectedJob.designation
                                    && <input type="hidden"
                                        name="job"
                                        value={this.state.selectedJob.designation}
                                    />
                                }
                                <input name="name" type="text" id="name" className="application-form-input" onChange={this.handleNameChange} value={this.state.name} required />
                            </div>
                            <div className="application-form-block">
                                <label htmlFor="email" className="application-form-label"><sup className='error'>*</sup>Email</label>
                                <input type="email" id="email" name="email" className="application-form-input" onChange={this.handleEmailChange} value={this.state.email} required />
                                <div className='name-error hide error' id='email-error' >Invalid email</div>
                            </div>
                            <div className="application-form-block">
                                <label className="application-form-label">What makes you a good fit for this role?</label>
                                <textarea rows="2"
                                    maxLength={readMoreLength}
                                    name="description"
                                    className="application-form-textarea"></textarea>
                            </div>
                            <div className="application-form-block">
                                <div className='application-form-resume'>
                                    <label><sup className='error'>*</sup>Upload Resume</label>
                                    <button type="button"
                                        className='choose-file-button'
                                        onClick={() => this.handleButtonClick()}>
                                        <span className='choose-file-text'>Choose File</span>
                                    </button>
                                    <input className='input-file-button'
                                        onChange={(e) => this.handleFileChange(e)}
                                        type="file"
                                        name="resume"
                                        accept=".pdf"
                                        ref={this.filesInput} />
                                </div>
                                {this.filesInput.current
                                    && this.filesInput.current.files
                                    && !!this.filesInput.current.files.length
                                    && this.filesInput.current.files[0].name
                                    && <p>{this.filesInput.current.files[0].name}</p>}
                                {this.state.fileError && <p className='file-error error' id='file-error'>PDF • Max 5 MB</p>}
                            </div>
                            <div>
                                <Captcha
                                    handler={this.handleCaptcha}
                                />
                            </div>
                            <button type="submit"
                                disabled={this.isSubmitDisabled()}
                                className={`send-application ${this.isSubmitDisabled() ? 'btn-disabled' : ''}`}>
                                <span>APPLY NOW</span>
                            </button>
                        </form>
                }
            </div>
        )
    }
}

CareerModal.propTypes = {
    selectedJob: PropTypes.object.isRequired
}

export default CareerModal;