import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ProfileSuggestions from './ProfileSuggestion';
import ProductSuggestions from './ProductSuggestion';
import CollaborationSuggestion from './CollaborationSuggestion';
import CompanySuggestion from './CompanySuggestion';

class Suggestions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            profile: ProfileSuggestions,
            company: CompanySuggestion,
            collaborate: CollaborationSuggestion,
            product: ProductSuggestions
        }
    }

    render() {
        const { cardData } = this.props;
        if (!cardData
            || (cardData && !cardData.meta)
            || (cardData.meta && !cardData.meta.sub_type)
            || (cardData.meta && cardData.meta.sub_type && !this.state[cardData.meta.sub_type])
            || (!cardData.suggestion || (cardData.suggestion && !cardData.suggestion.length))) return null;
        const SuggestionToRender = this.state[cardData.meta.sub_type];
        return (<SuggestionToRender
            subTitle={cardData.meta.text}
            title={cardData.meta.sub_type}
            suggestions={cardData.suggestion} />)
    };
}

Suggestions.propTypes = {
    cardData: PropTypes.object.isRequired
}

export default Suggestions;