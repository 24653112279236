import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { useHistory } from 'react-router-dom';
import Modal from '../../modals/common/Modal';
import { hideProfileModal } from '../../../actions/common/modals';
import { getCurrentUser } from '../../../actions/user';
import { profileLinks } from '../../../utils/internalLinks';

const ReviewSubmissionModal = (props) => {
  const { token, hideProfileModal, getCurrentUser } = props;
  const modalType = get(props, 'data.modalType');
  const data = get(props, 'data.data.data');
  const linkTo = get(props, 'data.data.linkTo');

  const history = useHistory();
  const getPaid = get(data, 'get_paid');

  useEffect(() => {
    if (getPaid) {
      getCurrentUser(token);
    }
  }, []);

  const handleContinuelButton = () => {
    hideProfileModal();
    if (modalType === 'produtReviewSubmission') {
      if (getPaid) {
        history.push(profileLinks.myPassbook);
      } else {
        history.push(linkTo);
      }
    } else {
      if (getPaid) {
        history.push(profileLinks.myPassbook);
      }
    }
  };

  const handleModalClose = () => {
    hideProfileModal();
  };

  const handleKnowMore = () => {
    hideProfileModal();
    history.push('/paid-taster-program');
  };

  return (
    <Modal showHeader className="passbook__beforereviewmodal__width" handleClose={handleModalClose}>
      <React.Fragment>
        <div className="biz__modal__triodata">
          <div className="passbook__modal__row1">
            {get(data, 'icon') && (
              <img
                className="passbook__modal__row1__icon"
                src={get(data, 'icon')}
                alt="modal icon"
              />
            )}
            {get(data, 'title') && (
              <p className="passbook__modal__row1__title">{get(data, 'title', '')}</p>
            )}
            {get(data, 'subTitle') && (
              <React.Fragment>
                <p className="passbook__modal__row1__subtitle">{get(data, 'subTitle', '')}</p>
                <hr className="passbook__modal__row1__hr" />
              </React.Fragment>
            )}
          </div>
          <div
            className={
              getPaid
                ? `passbook__afterreviewmodal__row2`
                : `passbook__afterreviewmodal__row2__margintop`
            }
          >
            <p className="passbook__afterreviewmodal__row2__title">{get(data, 'helper')}</p>
          </div>

          {getPaid ? (
            <div className="passbook__modal__row3">
              <button
                className="cursor-pointer passbook__modal__row3__button"
                onClick={handleContinuelButton}
              >
                <div className="passbook__modal__row3__button__text">
                  Go to - <span className="profile-menu-popup__mypassbook__header__textmy">My</span>
                  <span className="profile-menu-popup__mypassbook__header__textpassbook">
                    Passbook
                  </span>
                </div>
              </button>
            </div>
          ) : (
            <div className="passbook__modal__row3">
              <button
                className="cursor-pointer passbook__modal__row3__knowmorebutton"
                onClick={handleKnowMore}
              >
                Know more
              </button>
            </div>
          )}
        </div>
      </React.Fragment>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.login.token,
  };
};

const mapDispatchToProps = {
  hideProfileModal,
  getCurrentUser,
};

ReviewSubmissionModal.propTypes = {
  token: PropTypes.string,
  hideProfileModal: PropTypes.func,
  getCurrentUser: PropTypes.func,
  modalType: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewSubmissionModal);
