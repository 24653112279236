// GETTING NOTIFICATIONS
export const GET_NOTIFICATIONS_REQUEST = 'GET_NOTIFICATIONS_REQUEST';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAILURE = 'GET_NOTIFICATIONS_FAILURE';
// RESET NOTIFICATIONS
export const RESET_NOTIFICATIONS = 'RESET_NOTIFICATIONS';
// MARKING NOTIFICATIONS READ
export const MARK_ALL_NOTIFICATIONS_READ_REQUEST = 'MARK_ALL_NOTIFICATIONS_READ_REQUEST';
export const MARK_ALL_NOTIFICATIONS_READ_SUCCESS = 'MARK_ALL_NOTIFICATIONS_READ_SUCCESS';
export const MARK_ALL_NOTIFICATIONS_READ_FAILURE = 'MARK_ALL_NOTIFICATIONS_READ_FAILURE';
// Reading a single notification
export const NOTIFICATION_READ_REQUEST = 'NOTIFICATION_READ_REQUEST';
export const NOTIFICATION_READ_SUCCESS = 'NOTIFICATION_READ_SUCCESS';
export const NOTIFICATION_READ_FAILURE = 'NOTIFICATION_READ_FAILURE';
// Seen request
export const MARK_NOTIFICATIONS_SEEN_REQUEST = 'MARK_NOTIFICATIONS_SEEN_REQUEST';
export const MARK_NOTIFICATIONS_SEEN_SUCCESS = 'MARK_NOTIFICATIONS_SEEN_SUCCESS';
export const MARK_NOTIFICATIONS_SEEN_FAILURE = 'MARK_NOTIFICATIONS_SEEN_FAILURE';
//chat Seem Request
export const MARK_CHAT_NOTIFICATIONS_SEEN_REQUEST = 'MARK_CHAT_NOTIFICATIONS_SEEN_REQUEST';
export const MARK_CHAT_NOTIFICATIONS_SEEN_SUCCESS = 'MARK_CHAT_NOTIFICATIONS_SEEN_SUCCESS';
export const MARK_CHAT_NOTIFICATIONS_SEEN_FAILURE = 'MARK_CHAT_NOTIFICATIONS_SEEN_FAILURE';
// READ NOTIFICATION FROM SOCKET
export const READ_NOTIFICATION_FROM_SOCKET = 'READ_NOTIFICATION_FROM_SOCKET';
// UPDATE NOTIFICATION COUNT
export const UPDATE_PROFILE_UNREAD_NOTIFICATION_COUNT = 'UPDATE_PROFILE_UNREAD_NOTIFICATION_COUNT';
export const UPDATE_PROFILE_UNREAD_CHAT_NOTIFICATION_COUNT = 'UPDATE_PROFILE_UNREAD_CHAT_NOTIFICATION_COUNT';
export const UPDATE_NOTIFICATION_STATUS = 'UPDATE_NOTIFICATION_STATUS';
