import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import ArrowIcon from '../../../assets/images/business/chevron-right.svg';
import { getStateColumnCssClasses } from './utils';
import { formatDate } from '../../../utils/helper';

export const TransactionIdColumn = ({ data }) => {
  const title = get(data, 'transaction_id', '');

  const type = get(data, 'model', '');
  const createdDate = get(data, 'created_at', '')
    ? formatDate.post(get(data, 'created_at', ''))
    : '';

  return (
    <div className={`biz__table__consis-product`}>
      <div className="biz__table__consis-info">
        <p className="passbook__table__body__row__col__transaction__id">{title}</p>
        <p className="passbook__table__body__row__col__transaction__date">{`${createdDate} • ${type}`}</p>
      </div>
    </div>
  );
};

export const AmountColumn = ({ data }) => {
  const value = get(data, 'amount') ? `₹${get(data, 'amount')}` : '--';
  return <p className="passbook__table__body__row__col__amount">{value}</p>;
};

export const StatusColumn = ({ data }) => {
  const statusData = getStateColumnCssClasses(get(data, 'status'));
  const fontColor =
    get(data, 'status.id') === 2 ? `#ffffff` : get(data, 'status.text_color', '#171717');

  let statusText = '';
  if (get(data, 'status.id') === 2) statusText = 'Redeem';
  else if (get(data, 'status.id') === 3) statusText = 'Redeemed';
  else statusText = get(data, 'status.value');

  return (
    <div className="passbook__table__body__row__col__status">
      <span className={statusData.cssClass} style={{ color: fontColor }}>
        {statusText}
      </span>
      <img src={ArrowIcon} className={statusData.chevronClass} alt="view product" />
    </div>
  );
};

TransactionIdColumn.propTypes = {
  data: PropTypes.object,
};
AmountColumn.propTypes = {
  data: PropTypes.object,
};
StatusColumn.propTypes = {
  data: PropTypes.object,
};
