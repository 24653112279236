import { GET_PUBLIC_VIEW_POST_DATA_REQUEST,
    GET_PUBLIC_VIEW_POST_DATA_SUCCESS,
    GET_PUBLIC_VIEW_POST_DATA_FAILURE,
    GET_PUBLIC_SHARED_POST_DETAILS_REQUEST,
    GET_PUBLIC_SHARED_POST_DETAILS_SUCCESS,
    GET_PUBLIC_SHARED_POST_DETAILS_FAILURE,
    RESET_PUBLIC_VIEW_DATA } from '../../actions/publicView/constants';

const INITIAL_STATE = {
    fetching: false,
    viewData: null,
    error: false,
    errorMsg: null
}

export default function publicView(state = INITIAL_STATE, action) {
    switch(action.type) {
        case GET_PUBLIC_VIEW_POST_DATA_REQUEST:
            return {
                ...state,
                fetching: true
            }
        case GET_PUBLIC_VIEW_POST_DATA_SUCCESS:
            return {
                ...state,
                 fetching: false,
                 viewData: {
                     ...action.payload.postData,
                     ...(action.payload.postData[action.payload.postType].owner && {
                        [action.payload.postData[action.payload.postType].company_id ? 'company' : 'profile'] : action.payload.postData[action.payload.postType].owner
                     })
                 }
            }
        case GET_PUBLIC_VIEW_POST_DATA_FAILURE:
            return {
                ...state,
                fetching: false,
                ...(!action.error.__CANCEL__ && {
                    error: true,
                    errorMsg: action.error
                })
            }
            case GET_PUBLIC_SHARED_POST_DETAILS_REQUEST:
            return {
                ...state,
                fetching: true
            }
        case GET_PUBLIC_SHARED_POST_DETAILS_SUCCESS:
            return {
                ...state,
                fetching: false,
                viewData: {
                    ...action.payload.postData,
                    ...(action.payload.postData[action.payload.postType].owner && {
                    [action.payload.postData[action.payload.postType].company_id ? 'company' : 'profile'] : action.payload.postData[action.payload.postType].owner
                    })
                }
            }
        case GET_PUBLIC_SHARED_POST_DETAILS_FAILURE:
            return {
                ...state,
                fetching: false,
                error: true,
                errorMsg: action.error
            }
        case RESET_PUBLIC_VIEW_DATA:
            return INITIAL_STATE;
        default:
            return state;
    }
}