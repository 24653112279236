import React from "react";
import WhitePlayIcon from "../../../../assets/images/collaborations/view/white-play.svg";
function SmallVideoPreview(props) {
  const { image, className, type, playIconClassName } = props;
  return (
    <div
      style={{
        display: "flex",
        position: "relative",
      }}
    >
      {image && (
        <>
          <img
            src={image}
            alt="video"
            className={className}
            style={{ objectFit: "cover" }}
          />
          <img
            src={WhitePlayIcon}
            className={playIconClassName}
            style={{
              position: "absolute",
              zIndex: 2,
            }}
          />
        </>
      )}
    </div>
  );
}

export default SmallVideoPreview;
