import { GET_COMPANY_PHOTO_REQUEST,
    GET_COMPANY_PHOTO_SUCCESS,
    GET_COMPANY_PHOTO_FAILURE,
    SET_COMPANY_PHOTOS_DATA,
    RESET_COMPANY_PHOTOS
} from '../../actions/company/constants';

const INITIAL_STATE = {
    fetching: false,
    error: false,
    errorMsg: null,
    photos: null,
    page: 1
}

export default function photos(state = INITIAL_STATE, action)  {
    switch (action.type) {
        case GET_COMPANY_PHOTO_REQUEST:
            return {
                ...state,
                fetching: true,
            }
        case GET_COMPANY_PHOTO_SUCCESS:
            return {
                ...state,
                photos: state.page === 1
                    ? action.payload
                    : [...state.photos, ...action.payload],
                fetching: false,
                page: state.page + 1,
                hasEndReached: action.payload.length === 0
            }
        case GET_COMPANY_PHOTO_FAILURE:
            return {
                ...state,
                fetching: false,
                error: true,
                errorMsg: action.error
            }
        case SET_COMPANY_PHOTOS_DATA:
            return {
                ...state,
                photos: action.payload,
                page: state.page + 1
            }
        case RESET_COMPANY_PHOTOS:
            return INITIAL_STATE;
        default:
            return state;
    }
}