import { combineReducers } from 'redux';
import view from './view';
import all from './all';
import manage from './manage';
import previewAuth from './previewQuestionnaire';
import productReview from './productReview/index';

const collaborationReducer = combineReducers({
    view,
    all,
    manage,
    productReview,
    previewAuth
});

export default collaborationReducer;