import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SharePopUp from '../../../SharePopUp';
import Share from '../../../../assets/images/feed/feed-share.svg';

function FeedCardShareBtn(props) {
    const [showShareOptions, setShowShareOptions] = useState(false),
    { postId,
        isPublic,
        publicClickAction } = props;
    const _toggleShareOptions = () => setShowShareOptions(!showShareOptions);
    useEffect(() => {
        if (showShareOptions) {
            document.addEventListener('click',_toggleShareOptions);
        } else {
            document.removeEventListener('click', _toggleShareOptions);
        }
        return () => {
            document.removeEventListener('click', _toggleShareOptions);
        }
    }, [showShareOptions])
    return (<div className="post-action-bar__action">
        <div className="post-action-bar__action__popup-container">
            <button onClick={(e) => {
                if (e.preventDefault) {
                    e.preventDefault();
                }
                if (e.nativeEvent && e.nativeEvent.preventDefault) {
                    e.nativeEvent.preventDefault();
                }
                if (isPublic) {
                    if (publicClickAction && typeof publicClickAction === "function") {
                        publicClickAction();
                    }
                    return;
                }
                setShowShareOptions(!showShareOptions)
            }}
                className={`post-action-bar__action__btn cursor-pointer
                    ${(showShareOptions ? 'post-action-bar__action__btn--active' : '')}`}>
                <img className="post-action-bar__action__btn__image" src={Share} alt="share a post"/>
                <span className="post-action-bar__action__btn__text">Share</span>
            </button>
            {
                showShareOptions && <SharePopUp postId={postId}
                    hideInternalSharing={true}
                    postType={"feedCard"} />
            }
        </div>
    </div>)
};

FeedCardShareBtn.propTypes = {
    postId: PropTypes.number,
    isPublic: PropTypes.bool,
    publicClickAction: PropTypes.func
}

export default FeedCardShareBtn;