import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { globalUploadPhoto } from "../../actions/common/createPost";
import { maxFileSize } from "../../utils/commonVariables";
import PhotoIcon from "../../assets/images/collaborations/form/photography-frame.svg";
import CloseIcon from "../../assets/images/close.svg";
import ImagePlaceholder from "../../assets/images/collaborations/form/image-placeholder.svg";
import BlueImagePlaceholder from "../../assets/images/collaborations/form/blue-image-placeholder.svg";
import Loader from "./Loader";

class ImageUploader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imagesError: false,
      images: "",
      uploadingImages: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.removeImage = this.removeImage.bind(this);
  }

  removeImage(e) {
    const { isMulti, handleUpdate, imageIndex, name } = this.props,
      indexToRemove = !!isMulti ? e.target.getAttribute("data-index") : null;
    this.setState(
      {
        images: !!isMulti
          ? this.state.images.filter((_, i) => i !== indexToRemove)
          : "",
      },
      () => {
        handleUpdate({ [name]: this.state.images }, imageIndex, true);
      }
    );
  }

  handleChange(e) {
    this.setState({
      imagesError: false,
    });
    let files = e.target.files;
    if (files.length) {
      const {
        token,
        globalUploadPhoto,
        name,
        handleImageResponse,
        imageIndex,
        handleUpdate,
      } = this.props;
      let hasError = false;
      for (let i = 0; i < files.length; i++) {
        const { images } = this.state,
          fauxKey = Math.random();
        if (
          files[i].size > maxFileSize ||
          files[i].type.indexOf("image") === -1
        ) {
          hasError = true;
        } else {
          // start uploading image
          this.setState({
            uploadingImages: true,
          });
          let formData = new FormData();
          formData.append("image", files[i]);
          globalUploadPhoto(formData, token).then((response) => {
            if (response && response.original_photo) {
              this.setState({
                images: response.original_photo,
                uploadingImages: false,
              });
              if (handleUpdate) {
                handleUpdate(
                  { [name]: response.original_photo },
                  imageIndex,
                  false
                );
              }
              if (handleImageResponse) {
                handleImageResponse({ [name]: response });
              }
            }
          });
        }
      }
      if (hasError) {
        this.setState({
          imagesError: true,
        });
      }
    }
  }

  componentDidMount() {
    this.setState({
      images: this.props.images || "",
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.images !== prevProps.images) {
      this.setState({
        images: this.props.images || "",
      });
    }
  }

  render() {
    const {
      isMulti,
      maxLimit,
      isBluePlaceholder,
      nameToDisplay,
      isGrayPlaceholder,
    } = this.props;
    const { images, uploadingImages } = this.state;
    
    return (
      <div>
        {images && images.length ? (
          !isMulti ? (
            <div
              className={
                isBluePlaceholder
                  ? "image-placeholder__image image-placeholder__image--alcohol"
                  : "image-placeholder__image"
              }
            >
              <button
                className="image-placeholder__remove"
                onClick={this.removeImage}
              >
                <img src={CloseIcon} alt="remove" />
              </button>
              <img
                className={`image-placeholder ${
                  isBluePlaceholder ? "image-placeholder--alcohol" : ""
                }`}
                src={images}
                alt="post"
              />
            </div>
          ) : (
            <div className="image-placeholder__container">
              {images.map((image, imageIndex) => {
                return (
                  <div
                    className="image-placeholder__image"
                    key={`post-image-${imageIndex}`}
                  >
                    <button
                      className="image-placeholder__remove"
                      data-index={imageIndex}
                      onClick={this.removeImage}
                    >
                      <img src={CloseIcon} alt="remove" />
                    </button>
                    <img className="image-placeholder" src={image} alt="post" />
                  </div>
                );
              })}
            </div>
          )
        ) : null}
        {uploadingImages && (
          <div
            className={`image-placeholder__image ${
              isBluePlaceholder
                ? `image-placeholder__image--alcohol image-placeholder__image--centered`
                : `image-placeholder__image--centered`
            }`}
          >
            <Loader />
          </div>
        )}
        {((isMulti && images && images.length < maxLimit - 1) ||
          (!images && !images.length && !uploadingImages)) && (
          <label
            className={`image-placeholder__image image-placeholder__image--centered ${
              isGrayPlaceholder ? " image-placeholder__image--gray" : ""
            } ${isBluePlaceholder ? " image-placeholder__image--blue" : ""}`}
          >
            <input
              type="file"
              className="image-placeholder__input"
              multiple={isMulti}
              accept="image/png, image/jpg, image/jpeg"
              onChange={this.handleChange}
            />
            {isBluePlaceholder ? (
              <div className="image-placeholder__image--blue--select">
                <img src={BlueImagePlaceholder} alt="select" />
                <div>{nameToDisplay}</div>
              </div>
            ) : (
              <img src={ImagePlaceholder} alt="select" />
            )}
          </label>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    token: state.login.token,
  };
}

ImageUploader.propTypes = {
  id: PropTypes.string,
  images: PropTypes.any,
  isMulti: PropTypes.bool,
  className: PropTypes.string,
  maxLimit: PropTypes.number,
  handleUpdate: PropTypes.func,
  isGrayPlaceholder: PropTypes.bool,
};

const mapDispatchToProps = {
  globalUploadPhoto,
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageUploader);
