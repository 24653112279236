import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga4";
import { decodeString } from "../../utils/helper";

function SeoHeadMeta({ title, ...props }) {
  useEffect(() => {
    const page = window.location.pathname;
    ReactGA.set({ page, title: decodeString(title) });
    ReactGA.send("pageview");
  }, [title]);
  return <Helmet>{props.children}</Helmet>;
}

export default SeoHeadMeta;
