import {
    GET_FOLLOWERS_FAILURE,
    GET_FOLLOWERS_SUCCESS,
    GET_FOLLOWERS_REQUEST,
    SEARCH_FOLLOWERS_FAILURE,
    SEARCH_FOLLOWERS_SUCCESS,
    SEARCH_FOLLOWERS_REQUEST,
    SEARCH_GROUP_FOLLOWERS_FAILURE,
    SEARCH_GROUP_FOLLOWERS_SUCCESS,
    SEARCH_GROUP_FOLLOWERS_REQUEST,
    RESET_SEARCH_FOLLOWERS,
    RESET_SEARCH_GROUP_FOLLOWERS,
    RESET_FOLLOWERS,
    RESET_CHAT
} from '../../actions/chat/constants';

const INITIAL_STATE = {
    fetching: false,
    error: false,
    errorMsg: null,
    profiles: [],
    page: 1,
    hasEndReached: false,
    hasSearchedFollowers: false,
    searchedProfiles: [],
    group: {
        hasSearchedFollowers: false,
        searchedProfiles: []
    }
}

const getInitialState = () => INITIAL_STATE;

export default function followers(state = getInitialState(), action) {
    switch (action.type) {
        case GET_FOLLOWERS_REQUEST:
            return {
                ...state,
                fetching: true,
                hasEndReached: false,
                error: false,
                errorMsg: null
            }
        case GET_FOLLOWERS_SUCCESS:
            return {
                ...state,
                profiles: [...state.profiles, ...action.payload],
                page: state.page + 1,
                hasEndReached: !action.payload.length,
                fetching: false
            }
        case SEARCH_FOLLOWERS_FAILURE:
        case GET_FOLLOWERS_FAILURE:
            return {
                ...state,
                fetching: false,
                error: true,
                errorMsg: action.error
            }
        case RESET_FOLLOWERS:
            return {
                ...INITIAL_STATE
            }
        case SEARCH_FOLLOWERS_REQUEST:
            return {
                ...state,
                fetching: true,
                hasSearchedFollowers: true
            }
        case SEARCH_FOLLOWERS_SUCCESS:
            return {
                ...state,
                fetching: false,
                searchedProfiles: action.payload,
                hasSearchedFollowers: true
            }
        case RESET_SEARCH_FOLLOWERS:
            return {
                ...state,
                searchedProfiles: [],
                hasSearchedFollowers: false,
            }
        case SEARCH_GROUP_FOLLOWERS_REQUEST:
            return {
                ...state,
                group: {
                    ...state.group,
                    fetching: true,
                    hasSearchedFollowers: true
                }
            }
        case SEARCH_GROUP_FOLLOWERS_SUCCESS:
            return {
                ...state,
                group: {
                    ...state.group,
                    fetching: false,
                    searchedProfiles: action.payload,
                    hasSearchedFollowers: true
                }
            }
        case SEARCH_FOLLOWERS_FAILURE:
            return {
                ...state,
                group: {
                    fetching: false,
                    hasSearchedFollowers: false,
                    searchedProfiles: []
                }
            }
        case RESET_SEARCH_GROUP_FOLLOWERS:
            return {
                ...state,
                group: {
                    ...state.group,
                    searchedProfiles: [],
                    hasSearchedFollowers: false,
                }
            }
        case RESET_CHAT:
            return INITIAL_STATE
        default:
            return state;
    }
}