import { GET_REVIEWS_DATA_REQUEST,
    GET_REVIEWS_DATA_SUCCESS,
    RESET_REVIEWS_DATA,
    GET_REVIEWS_DATA_FAILURE } from "../../actions/reviews/constants";
import { FOLLOW_USER_SUCCESS } from "../../actions/profile/constants";

const INITIAL_STATE = {
    fetching: false,
    error: false,
    all: [],
    hasFetched: false
}
export default function reviews(state = INITIAL_STATE, action) {
    switch(action.type) {
        case GET_REVIEWS_DATA_REQUEST:
            return {
                ...state,
                fetching: true
            }
        case GET_REVIEWS_DATA_SUCCESS:
            return {
                ...state,
                fetching: false,
                all: action.payload,
                hasFetched: true
            }
        case GET_REVIEWS_DATA_FAILURE:
            return {
                ...state,
                ...(!action.error.__CANCEL__ && {
                    error: true,
                    errorMsg: action.error
                }),
                fetching: false
            }
        case FOLLOW_USER_SUCCESS:
            return {
                ...state,
                ...(state.hasFetched && {
                    all: state.all.map(review => {
                        if (review.category_type === 'profile') {
                            return {
                                ...review,
                                elements: review.elements.map(_ => {
                                    if ((action.type === 'FOLLOW_USER_SUCCESS'
                                        && _.id === action.payload.profileId)) {
                                        return {
                                            ..._,
                                            isFollowing: true
                                        }
                                    }
                                    return _
                                })
                            }
                        }
                        return review;
                    })
                })
            }
        case RESET_REVIEWS_DATA:
            return INITIAL_STATE;
        default:
            return state;
    }
}