import React, { useState } from "react";
import PropTypes from "prop-types";
import { readMoreText, decodeString } from "../../../../utils/helper";
import linkifyHtml from "linkifyjs/html";
import { readMoreLength } from "../../../../utils/commonVariables";

const linkifyOptions = {
  className: "post-details__link",
  target: {
    url: "_blank",
  },
  attributes: {
    ref: "nofollow",
  },
  nl2br: true,
};

const formatText = (text, tagged, readMoreLenToSend) => {
  return tagged
    ? readMoreText(text, readMoreLenToSend)
    : readMoreText(decodeString(text), readMoreLenToSend);
};

function ReadMoreText(props) {
  const {
      text,
      className,
      disabled,
      tagged,
      readMoreLen,
      isPublic,
      publicClickAction,
      preventRouteChangeOnClick,
    } = props,
    readMoreLenToSend = readMoreLen || readMoreLength;
  const [isOpen, setIsOpen] = useState(props.isOpen || false);
  return (
    <p
      className={className}
      onClick={(e) => {
        if (disabled) return;
        if (isPublic) {
          if (publicClickAction && typeof publicClickAction === "function") {
            publicClickAction();
          }
          return;
        }
        if (preventRouteChangeOnClick) {
          e.preventDefault();
          e.stopPropagation();
        }
        setIsOpen(true);
      }}
      dangerouslySetInnerHTML={{
        __html: isOpen
          ? tagged
            ? linkifyHtml(text.replace(/&nbsp;/g,''), linkifyOptions)
            : linkifyHtml(decodeString(text.replace(/&nbsp;/g,'')), linkifyOptions)
          : linkifyHtml(
              formatText(text.replace(/&nbsp;/g,''), tagged, readMoreLenToSend),
              linkifyOptions
            ),
      }}
    />
  );
}

ReadMoreText.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  isOpen: PropTypes.bool,
  tagged: PropTypes.bool,
  readMoreLen: PropTypes.number,
  isPublic: PropTypes.bool,
  publicClickAction: PropTypes.func,
};

export default ReadMoreText;
