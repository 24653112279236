import { GET_PRODUCT_HEADERS_REQUEST,
    GET_PRODUCT_HEADERS_SUCCESS,
    GET_PRODUCT_HEADERS_FAILURE,
    RESET_PRODUCT_HEADERS } from "../../actions/products/constants";

const INITIAL_STATE = {
    fetching: false,
    productId: null,
    error: false,
    data: null,
    hasFetched: false
}

export default function productHeaders(state = INITIAL_STATE, action) {
    switch(action.type) {
        case GET_PRODUCT_HEADERS_REQUEST:
            return {
                ...state,
                fetching: true,
                hasFetched: false,
                productId: action.payload.productId
            }
        case GET_PRODUCT_HEADERS_SUCCESS:
            return {
                ...state,
                fetching: false,
                hasFetched: true,
                data: action.payload
            }
        case GET_PRODUCT_HEADERS_FAILURE:
            return {
                ...state,
                fetching: false,
                error: true,
                errorMsg: action.payload.error
            }
        case RESET_PRODUCT_HEADERS:
            return INITIAL_STATE
        default:
            return state;
    }
}