import { GET_PRODUCT_ANY_OTHER_DATA_REQUEST,
    GET_PRODUCT_ANY_OTHER_DATA_SUCCESS,
    GET_PRODUCT_ANY_OTHER_DATA_FAILURE,
    RESET_PRODUCT_ANY_OTHER_DATA } from "../../actions/products/constants";

const INITIAL_STATE = {
    page: 1,
    fetching: false,
    error: false,
    hasEnd: false,
    showModal: false,
    count: 0,
    values: []
}

export default function anyOther(state = INITIAL_STATE, action) {
    switch(action.type) {
        case GET_PRODUCT_ANY_OTHER_DATA_REQUEST:
            return {
                ...state,
                fetching: true,
                ...(state.page === 1 && {...action.payload})
            };
        case GET_PRODUCT_ANY_OTHER_DATA_SUCCESS:
            return {
                ...state,
                fetching: false,
                count: state.page === 1
                    ? action.payload.count
                    : state.count,
                values: state.page === 1
                    ? action.payload.values
                    : [...state.values, ...action.payload.values],
                hasEnd: !action.payload.values || (action.payload.values && !action.payload.values.length),
                showModal: true,
                page: state.page + 1
            }
        case GET_PRODUCT_ANY_OTHER_DATA_FAILURE:
            return {
                ...state,
                fetching: false,
                error: true,
                errorMsg: action.error
            }
        case RESET_PRODUCT_ANY_OTHER_DATA:
            return INITIAL_STATE
        default:
            return state;
    }
}