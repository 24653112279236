import { GET_PREVIEW_SUCCESS,
    EMPTY_PREVIEW,
    RESET_ALL_PREVIEW,
    GET_PREVIEW_REQUEST,
    GET_PREVIEW_FAILURE,
    SET_PREVIEW } from '../../actions/feed/constants';

const INITIAL_STATE = {}

export default function preview(state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_PREVIEW_REQUEST:
            return {
                ...state,
                [action.payload.previewType]: {
                    fetching: true
                }
            }
        case GET_PREVIEW_SUCCESS:
            if (!!!Object.keys(action.payload.preview).length) {
                return {
                    ...state,
                    [action.payload.previewType]: {
                        fetching: false
                    }
                }
            }
            return {
                ...state,
                [action.payload.previewType]: {
                    fetching: false,
                    preview: action.payload.preview
                }
            }
        case GET_PREVIEW_FAILURE:
        case EMPTY_PREVIEW:
            let stateCopy = {...state};
            delete stateCopy[action.payload];
            return stateCopy;
        case SET_PREVIEW:
            return {
                ...state,
                [action.payload.previewType]: {
                    preview: action.payload.preview
                }
            }
        case RESET_ALL_PREVIEW:
            return INITIAL_STATE;
        default:
            return state;
    }
}