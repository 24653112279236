import React from 'react';
import PropTypes from 'prop-types';
import { decodeString } from '../../../../utils/helper';

function SmallPreview(props) {
    const { preview } = props;
    const siteName = decodeString(preview.site_name || ''),
        url = preview.url || '',
        description = decodeString(preview.description || ''),
        image = preview.image || '',
        title = decodeString(preview.title || '');
    return (<a className="feed-card-preview" rel="noopener noreferrer" href={url} target="_blank">
        <div className="feed-card-preview--small">
            { image && <img src={image} alt={title} className="feed-card-preview--small__image"/> }
            <div className='feed-card-preview--small__info'>
                { title && <p className='feed-card-preview--small__info--title'>{title}</p> }
                { description && <p className='feed-card-preview--small__info--description'>{description}</p> }
                <p className='feed-card-preview--small__info--sitename'>{(siteName.trim().length
                    ? siteName
                    : url)}</p>
            </div>
        </div>
    </a>)
};

SmallPreview.propTypes = {
    preview: PropTypes.object.isRequired
}

export default SmallPreview;