import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { profileLimits } from '../../../../utils/commonVariables';
import ProfileCompletionCompleted from './ProfileCompletionCompleted';

class TaglineCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tagline: props.profile.tagline || '',
            error: null,
            submitting: false,
            hasSubmit: !!props.profile.tagline
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.state.submitting) return;
        this.setState({
            error: null
        });
        let formData = {
            '_method': 'PATCH',
            profile: {
                tagline: this.state.tagline.trim()
            }
        }
        if (!this.state.tagline.trim().length || this.state.tagline.trim().length > profileLimits.tagline) {
            this.setState({
                error: `Please enter a tagline within ${profileLimits.tagline} characters.`
            })
            return;
        }
        this.setState({
            submitting: true
        })
        const { handleUserUpdate,
            profile } = this.props;
        handleUserUpdate(profile.id, formData, () => {
            this.setState({
                submitting: false,
                hasSubmit: true
            })
        });
    }

    render() {
        const { submitting, hasSubmit } = this.state;
        return (<form onSubmit={this.handleSubmit} className="feed-common-cards profile-updation-data__container">
            <div className="profile-updation-data__details">
                <p className="profile-updation-data__title">What is your tagline?</p>
                <div className="profile-updation-data__meta">
                    <textarea className="profile-updation-data__textarea"
                        value={this.state.tagline}
                        name="tagline"
                        onChange={this.handleChange}
                        maxLength={profileLimits.tagline}
                        placeholder="Eg. I roast the best eggplants ever!">
                    </textarea>
                </div>
            </div>
            <div className="profile-updation-data__btn-container">
                {
                    this.state.error
                    && (<p className="profile-updation-data__error">{this.state.error}</p>)
                }
                <button type="submit"
                    disabled={submitting}
                    className={`profile-updation-data__btn ${submitting ? 'profile-updation-data__btn--disabled' : ''}`}>Save</button>
            </div>
            { hasSubmit && <ProfileCompletionCompleted /> }
        </form>)
    }
};

TaglineCard.propTypes = {
    profile: PropTypes.object,
    handleUserUpdate: PropTypes.func
}

export default TaglineCard;
