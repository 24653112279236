import { GET_COMPANY_ADMINS_REQUEST,
    GET_COMPANY_ADMINS_SUCCESS,
    GET_COMPANY_ADMINS_FAILURE,
    ADD_COMPANY_ADMIN_REQUEST,
    ADD_COMPANY_ADMIN_SUCCESS,
    ADD_COMPANY_ADMIN_FAILURE,
    DELETE_COMPANY_ADMIN_REQUEST,
    DELETE_COMPANY_ADMIN_SUCCESS,
    DELETE_COMPANY_ADMIN_FAILURE,
    RESET_COMPANY_ADMINS } from "../../actions/company/constants";

const INITIAL_STATE = {
    fetching: false,
    error: false,
    errorMsg: null,
    all: [],
    updating: false,
    updatingError: false,
    updatingErrorMsg: null
}

export default function admins(state = INITIAL_STATE, action) {
    switch(action.type) {
        case GET_COMPANY_ADMINS_REQUEST:
            return {
                ...INITIAL_STATE,
                fetching: true
            }
        case GET_COMPANY_ADMINS_SUCCESS:
            return {
                ...state,
                fetching: false,
                all: action.payload
                    && action.payload.length
                    ? action.payload.filter(_ => !!_.profile)
                    : action.payload
            }
        case GET_COMPANY_ADMINS_FAILURE:
            return {
                ...state,
                fetching: false,
                error: true,
                errorMsg: action.error
            }
        case ADD_COMPANY_ADMIN_REQUEST:
        case DELETE_COMPANY_ADMIN_REQUEST:
            return {
                ...state,
                updating: true
            }
        case ADD_COMPANY_ADMIN_SUCCESS:
            return {
                ...state,
                updating: false,
                all: [...state.all, {
                    profile: action.payload.profileObj
                }]
            }
        case DELETE_COMPANY_ADMIN_SUCCESS:
            return {
                ...state,
                updating: false,
                all: state.all.filter(_ => _.profile.id !== action.payload.userId)
            }
        case ADD_COMPANY_ADMIN_FAILURE:
        case DELETE_COMPANY_ADMIN_FAILURE:
            return {
                ...state,
                updating: false,
                updatingError: true,
                updatingErrorMsg: action.error
            }
        case RESET_COMPANY_ADMINS:
            return INITIAL_STATE
        default:
            return state;
    }
}