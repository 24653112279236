import { combineReducers } from 'redux';
import post from './post';
import photo from './photo';
import shared from './shared';
import polling from './polling';
import feedCard from './feedCard';

const detailPageReducer = combineReducers({
    post,
    photo,
    shared,
    polling,
    feedCard
});

export default detailPageReducer;