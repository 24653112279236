import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toggleProfileCompletionModal } from '../../../../actions/common/modals';
import EatingHabits from '../../../../assets/images/profile/completion/eating-habits.svg';
import ProfileCompletionCompleted from './ProfileCompletionCompleted';

const texts = {
    title: 'What are your eating habits?',
    helper: 'Here are some of the classifications based on the dietary choices. Select the one that resembles you the most.',
    btn: '+ Eating Habits'
}
class EatingHabitsCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasProfileCompleted: (this.props.profile
                && !!this.props.profile.foodie_type_id)
        }
        // binding toggle modal
        this.toggleModal = this.toggleModal.bind(this);
    }

    toggleModal() {
        const { toggleProfileCompletionModal } = this.props;
        toggleProfileCompletionModal('eating');
    }

    componentDidUpdate() {
        if (this.props.profile
            && !!this.props.profile.foodie_type_id
            && !this.state.hasProfileCompleted) {
                this.setState({
                    hasProfileCompleted: true
                })
            }
    }

    render() {
        return (<div className="feed-common-cards profile-completion-card">
            <div className="profile-completion-card__image__container">
                <img className="profile-completion-card__image" src={EatingHabits} alt={texts.title} />
            </div>
            <div className="profile-completion-card__details">
                    <p className="profile-completion-card__details__title">{texts.title}</p>
                    <p className="profile-completion-card__details__helper">{texts.helper}</p>
            </div>
            <div className="profile-completion-card__action">
                <button className="profile-completion-card__action__btn" onClick={this.toggleModal}>
                    <span className="profile-completion-card__action__btn__text">{texts.btn}</span>
                </button>
            </div>
            { this.state.hasProfileCompleted && <ProfileCompletionCompleted /> }
        </div>)
    }
}

const mapDispatchToProps = {
    toggleProfileCompletionModal
}

export default connect(null, mapDispatchToProps)(EatingHabitsCard);