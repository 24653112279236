import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import ProfileCompletionModal from '../ProfileCompletionModal';
import ProfileMenuModal from '../ProfileMenuModal';
import SignupEmailOtpForm from '../../../containers/loggedOut/SignUp/SignupEmailOtpForm';
import CommonOtpModal from './CommonOtpModal';
import SuccessOtpModal from './SuccessOtpModal';

function ProfileCommonModals(props) {
    const [profileModals, setProfileModals] = useState(props.profileModals);
    useEffect(() => {
        setProfileModals(props.profileModals);
    }, [props.profileModals]);
    return (<>
        {
            profileModals
            && profileModals.profileCompletion
            && profileModals.profileCompletion.isVisible
            && (<ProfileCompletionModal
                type={profileModals.profileCompletion.type} />)
        }
        {
            profileModals
            && profileModals.profileMenu
            && profileModals.profileMenu.isVisible
            && (<ProfileMenuModal
                type={profileModals.profileMenu.type} />)
        }
        {
            profileModals
            && profileModals.otpModal
            && !!profileModals.otpModal.isVisible && <CommonOtpModal />
        }
        {
            profileModals
            && profileModals.successOtpModal
            && !!profileModals.successOtpModal.isVisible && <SuccessOtpModal />
        }
    </>);
};

function mapStateToProps(state) {
    return {
        profileModals: state.modalReducer.profileModals
    }
}

export default connect(mapStateToProps)(ProfileCommonModals);