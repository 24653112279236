import React from "react";

const PromptFooter = ({ handleClose, value, isUpdating, handleSave }) => {
    return (
        <>
            <footer className="profile-prompt__footer">
                <buttoon className="button profile-prompt__action secondary" onClick={handleClose}>Cancel</buttoon>
                <buttoon className={`button profile-prompt__action primary ${(!value || isUpdating) ? 'disabled' : ''}`} onClick={handleSave}>Save</buttoon>
            </footer>
        </>
    )
}

export default PromptFooter;