import { GET_TTFB_PROFILE_DATA_REQUEST,
    GET_TTFB_PROFILE_DATA_SUCCESS,
    GET_TTFB_PROFILE_DATA_FAILURE,
    RESET_TTFB_PROFILE_DATA } from "../../actions/business/constants";

const INITIAL_STATE = {
    fetching: false,
    data: null,
    error: false,
    hasFetched: false
}
export default function profile(state = INITIAL_STATE, action) {
    switch(action.type) {
        case GET_TTFB_PROFILE_DATA_REQUEST:
            return {
                ...state,
                fetching: true
            }
        case GET_TTFB_PROFILE_DATA_SUCCESS:
            return {
                ...state,
                fetching: false,
                data: action.payload,
                hasFetched: true
            }
        case GET_TTFB_PROFILE_DATA_FAILURE:
            return {
                ...state,
                fetching: false,
                ...(!action.error.__CANCEL__ && {
                    error: true,
                    errorObj: action.error
                })
            }
        case RESET_TTFB_PROFILE_DATA:
            return INITIAL_STATE
        default:
            return state;
    }
}