import React from "react";
import PropTypes from "prop-types";

function PillFiltersItems({ filter, handleClick, isSurvey, isActive, isQuiz, isInvitation, disabled }) {
  const classKeyword = isSurvey ? "--survey" : isQuiz ? "--quiz" : isInvitation ? "--invitation" : "";

  const classNames = `pill-filters pill-filters${classKeyword} ${isActive ? `pill-filters${classKeyword}--selected` : ""
    } ${disabled ? "disabled" : ""}`;

  return (
    <li className={classNames} onClick={handleClick}>
      {filter.title}
    </li>
  );
}

PillFiltersItems.propTypes = {
  filter: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
  isSurvey: PropTypes.bool,
};

export default PillFiltersItems;
