import React from "react";
import ReactDOM from 'react-dom';
import PropTypes from "prop-types";

const CommonPortal = (props) => {
    const { children } = props;
    return ReactDOM.createPortal(
        children,
        document.body
    );
}

CommonPortal.propTypes = {
    children: PropTypes.element.isRequired
}

export default CommonPortal;
