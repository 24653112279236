import React from "react";
import ReactJWPlayer from "react-jw-player";
import ImageEditIcon from "../../assets/images/poll/create/image-edit.svg";
import CloseIcon from "../../assets/images/common/icons/close-filled-red.svg";
import { GIF_TYPE, makePlaylistForJWPlayer } from "../../utils/helper";
import PropTypes from "prop-types";
import CommonPlayer from "../CommonPlayer";
const PollMedia = ({ isVideo, isEdit, data, handleMediaChange, handleMediaDiscard }) => {
    if (!data) return false;
    if (!data.original_photo && isVideo) {
        return (
            <>
                {Array.isArray(data) ? data.map((video, videoIndex) => (
                      <CommonPlayer
                        playerId={`poll-video-${videoIndex}`}
                        videosMeta={[video] || []}
                    />
                )) : null}
                {isEdit && <div className={'poll-create__image__question__icon video'}>
                    <img src={CloseIcon} alt="close-icon" onClick={handleMediaDiscard} />
                </div>}
            </>
        )
    }
    return (
        <>
            <img
                src={data.original_photo}
                className={data.meta.mime == GIF_TYPE ? "big_gif_image" : ""}
                alt="poll-question-image"
            />
            {isEdit &&
                <label className="poll-create__image__question__icon" htmlFor="poll-media-input">
                    <img src={ImageEditIcon} alt="poll-question-media" />
                    <input
                        id="poll-media-input"
                        type="file"
                        accept="image/*,video/*"
                        onChange={handleMediaChange}
                    />
                </label>
            }
        </>
    )
}

PollMedia.propTypes = {
    isVideo: PropTypes.bool,
    isEdit: PropTypes.bool,
    data: PropTypes.oneOfType([PropTypes.array.isRequired, PropTypes.object.isRequired]),
    handleMediaChange: PropTypes.func,
    handleMediaDiscard: PropTypes.func,
}

export default PollMedia;