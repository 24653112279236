import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { decodeString, twoDecimal } from '../../utils/helper';
import { profileLinks } from '../../utils/internalLinks';
import Chevron from '../../assets/images/home/chevron-right-grey.svg';
import PassbookIcon from '../../assets/images/home/passbook.svg';
import { gaEvents } from '../../utils/events';

const Passbook = (props) => {
  const { history, profile } = props;
  const isPaidTester = get(profile, 'is_paid_taster', 0);
  const earning = get(profile, 'payment.earning', 0)
    ? twoDecimal(get(profile, 'payment.earning', 0))
    : '0.00';
  const formattedEarning = decodeString(get(profile, 'payment.formatted_earning', ''));
  const ctaText =
    isPaidTester || earning > 0 ? 'View passbook' : 'Become a paid taster to start earning';

  const handleOpenPassbook = () => {
    gaEvents.landingpage.passbook(isPaidTester ? 'Paid' : 'Unpaid');

    if (isPaidTester || earning > 0) history.push(profileLinks.myPassbook);
    else history.push(profileLinks.paidTasterProgram);
  };

  const showDisabled = !isPaidTester && earning <= 0 ? true : false;

  return (
    <div className="feed-passbook">
      <div className="feed-passbook__amountrow">
        <img src={PassbookIcon} />
        <span className="feed-passbook__amountrow__text">You have earned</span>
        <span
          className={`feed-passbook__amountrow__amount ${
            showDisabled ? 'feed-passbook__amountrow__amount__disabled' : ''
          }`}
        >
          {formattedEarning}
        </span>
      </div>
      <div className="feed-passbook__ctarow">
        {!!earning && (
          <div
            className="cursor-pointer feed-passbook__ctarow__link"
            onClick={() => handleOpenPassbook()}
          >
            <span
              className={`feed-passbook__ctarow__link__text ${
                showDisabled ? 'feed-passbook__ctarow__link__text__disabled' : ''
              }`}
            >
              {ctaText}
            </span>
            <img src={Chevron} className="feed-product-available__text__cta__icon" />
          </div>
        )}
      </div>
    </div>
  );
};

Passbook.propTypes = {
  history: PropTypes.object,
  profile: PropTypes.object,
};

export default Passbook;
