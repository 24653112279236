import { FLAG_REPORT_POST_SUCCESS} from './constants';
import { getResponseData,
    onRequest,
    onSuccess,
    getResponseDataWithStatus} from "../../utils/helper";
import { reportLinks,
    getBaseUrl } from "../../utils/links";
import Axios from "axios";
const CancelToken = Axios.CancelToken;

export let getReportContentDataCancelToken = undefined;
export const getReportContentData = (token,
    type) => {
    if (getReportContentDataCancelToken) getReportContentDataCancelToken();
    let url = `${getBaseUrl()}${reportLinks.byType(type)}`,
        options = {
            url,
            headers: {
                'Authorization': `Bearer ${token}`
            },
            cancelToken: new CancelToken((c) => getReportContentDataCancelToken = c)
        };
    return Axios(options)
        .then(response => {
            let successData = getResponseData(response);
            return successData;
        })
        .catch(error => {
            return false;
        });
}

// report content
export let reportContentOrProfileCancelToken = undefined;
export const reportContentOrProfile = (token,
    data,
    type) => {
    if (reportContentOrProfileCancelToken) reportContentOrProfileCancelToken();
    let url = `${getBaseUrl()}${type === 'content'
        ? reportLinks.reportContent
        : reportLinks.reportUser}`,
        options = {
            url,
            headers: {
                'Authorization': `Bearer ${token}`
            },
            data,
            method: 'POST',
            cancelToken: new CancelToken((c) => reportContentOrProfileCancelToken = c)
        };
    return Axios(options)
        .then(response => {
            return getResponseDataWithStatus(response.data);
        })
        .catch(_ => {
            return getResponseDataWithStatus();
        })
}

export const flagReportSuccess = (payload) => onSuccess(FLAG_REPORT_POST_SUCCESS, payload);