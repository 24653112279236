import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { availableGenders } from '../../../utils/commonVariables';

function Gender(props) {
    const [profile, setProfile] = useState(props.user.user.profile),
        [gender, setGender] = useState(props.user.user.profile.gender || '');
    const { addToFilledItems,
        removeFromFilledItems } = props;
    useEffect(() => {
        setProfile(props.user.user.profile);
    }, [props.user.user.profile]);
    const handleInputChange = (e) => {
        setGender(e.target.value);
        if (e.target.value) {
            if (addToFilledItems) {
                addToFilledItems('gender', {
                    gender: e.target.value
                });
            }
        } else {
            if (removeFromFilledItems('gender'));
        }
    }
    return (<div>
        <label className="form-label">Select Gender</label>
        <select className="form-select form-select--secondary"
            value={gender}
            onChange={handleInputChange}
            name="gender">
            <option value=''>Select Gender</option>
            {
                availableGenders.map((gender, genderIndex) => {
                    return (<option key={`gender-${genderIndex}`} value={gender}>{gender}</option>)
                })
            }
        </select>
    </div>)
};

function mapStateToProps(state) {
    return {
        user: state.user,
        token: state.login.token
    }
}
Gender.propTypes = {
    addToFilledItems: PropTypes.func,
    removeFromFilledItems: PropTypes.func
}

export default connect(mapStateToProps)(Gender);