import React, { useState,
    useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types'
import EmptyField from '../../../containers/loggedIn/Profile/EmptyField';
import EditIcon from '../../../assets/images/profile/edit/edit.svg';
import { toggleProfileCompletionModal } from '../../../actions/common/modals';
import { converArrayToString } from '../../../utils/profileHelper';

const texts = {
    subTitle: 'Allergic to peanuts, eggs?',
    title: 'Allergens'
}

function Allergens(props) {
    const [user, setUser] = useState(props.user),
        { toggleProfileCompletionModal } = props;
    useEffect(() => {
        setUser(props.user)
    }, [props.user]);
    const profile = user.user.profile,
    allergensToShow = converArrayToString(profile.allergens);
    const { isMandatoryModal,
        toggleAllergensModal } = props,
        toggleModal = () => isMandatoryModal
        ? toggleAllergensModal()
        : toggleProfileCompletionModal('allergens');
    return (<>
        {
            !profile.allergens.length
            ? <div className="profile-view__details__block">
                <EmptyField clickFunc={() => toggleModal()}
                    title={texts.title}
                    subTitle={texts.subTitle} />
            </div>
            : (<div className="profile-view__details__block profile-view__details__block__personal__block">
                <p className="profile-view__details__block__personal__title">Allergens</p>
                <div className="profile-view__details__block__personal__description">
                    { allergensToShow }
                    <button onClick={() => toggleModal()} className="profile-view__details__edit-button">
                        <img src={EditIcon} alt="add allergens" className="profile-view__details__edit-button__image"/>
                    </button>
                </div>
            </div>)
        }
    </>)
};

function mapStateToProps(state) {
    return {
        user: state.user,
        token: state.login.token,
        view: state.profileReducer.view,
    }
}

Allergens.propTypes = {
    isSelf: PropTypes.bool,
    toggleAllergensModal: PropTypes.func,
    isMandatoryModal: PropTypes.bool
}

export default connect(mapStateToProps, { toggleProfileCompletionModal })(Allergens);