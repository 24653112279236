import { GET_PR_APPLICANTS_REQUEST,
    GET_PR_APPLICANTS_SUCCESS,
    GET_PR_APPLICANTS_FAILURE,
    GET_PR_REJECTED_APPLICANTS_REQUEST,
    GET_PR_REJECTED_APPLICANTS_SUCCESS,
    GET_PR_REJECTED_APPLICANTS_FAILURE,
    GET_PR_INVITED_APPLICANTS_REQUEST,
    GET_PR_INVITED_APPLICANTS_SUCCESS,
    GET_PR_INVITED_APPLICANTS_FAILURE,
    GET_PR_UNASSIGNED_APPLICANTS_REQUEST,
    GET_PR_UNASSIGNED_APPLICANTS_SUCCESS,
    GET_PR_UNASSIGNED_APPLICANTS_FAILURE,
    RESET_PR_APPLICANTS_REQUEST,
    RESET_PR_REJECTED_APPLICANTS,
    RESET_PR_INVITED_APPLICANTS,
    RESET_PR_UNASSIGNED_APPLICANTS,
    PR_REJECT_AN_APPLICANT_SUCCESS,
    PR_SHORTLIST_AN_SUCCESS,
    PR_INVITE_AN_APPLICANT_SUCCESS,
    PR_MANAGE_ADD_TO_INVITED_APPLICANTS } from "../../../actions/collaborations/prManage/constants";
import { ACCEPT_AGE_PROOF_DOCUMENT_SUCCESS, REJECT_AGE_PROOF_DOCUMENT_SUCCESS } from "../../../actions/collaborations/constants";

const COMMON_INITIAL_STATE = {
    fetching: false,
    page: 1,
    currentApplicants: [],
    error: false,
    totalApplicants: 0,
    rejectedApplicants: 0,
    invitedApplicantsCount: 0
}

const INITIAL_STATE = {
    applicants: { ...COMMON_INITIAL_STATE },
    rejected: { ...COMMON_INITIAL_STATE },
    invited: { ...COMMON_INITIAL_STATE },
    unassigned: { ...COMMON_INITIAL_STATE }
}

// method to start fetching
const updateForFetching = (state, applicantsType) => {
    return {
        ...state,
        [applicantsType]: {
            ...state[applicantsType],
            fetching: true
        }
    }
}

// method to get list type
const getListType = (type) => {
    return (type === 'applicants' || type === 'unassigned')
        ? 'applicants'
        : type === 'rejected'
        ? 'rejectedApplicantList'
        : type === 'invited'
        ? 'invitedApplicants'
        : '';
}

// method to handle success after fetching
const handleSuccessFetching = (state, payload) => {
    const listType = getListType(payload.type);
    if (!listType) return state;
    const data = { ...payload.data };
    data['currentApplicants'] = payload.data[listType];
    delete data[listType];
    return {
        ...state,
        [payload.type]: 
            {
                ...data,
                fetching: false,
                page: state[payload.type].page+1,
                hasEnd: !data['currentApplicants'].length,
                ...({
                    totalApplicants: payload.data.totalApplicants || 0,
                    rejectedApplicants: payload.data.rejectedApplicantsCount || 0,
                    invitedApplicantsCount: payload.data.invitedApplicantsCount || 0
                })
            },
            ...({
                totalApplicants: payload.data.totalApplicants || 0,
                rejectedApplicants: payload.data.rejectedApplicantsCount || 0,
                invitedApplicantsCount: payload.data.invitedApplicantsCount || 0
            })
           
    }
}

// method to handle failure of fetching
const handleFailureFetching = (state, error, type) => {
    return {
        ...state,
        [type]: {
            ...state[type],
            fetching: false,
            error: true,
            errorMsg: error
        }
    }
}

// method to reset applicants by type
const resetApplicantsByType = (state, applicantsType) => {
    return {
        ...state,
        [applicantsType]: COMMON_INITIAL_STATE
    }
}

// TODO: Very Verbose (DRY should be followed here)
export default function applicants(state = INITIAL_STATE, action) {
    switch(action.type) {
        case GET_PR_APPLICANTS_REQUEST:
        case GET_PR_REJECTED_APPLICANTS_REQUEST:
        case GET_PR_INVITED_APPLICANTS_REQUEST:
        case GET_PR_UNASSIGNED_APPLICANTS_REQUEST:
            return updateForFetching(state, action.payload)
        case GET_PR_APPLICANTS_SUCCESS:
        case GET_PR_REJECTED_APPLICANTS_SUCCESS:
        case GET_PR_INVITED_APPLICANTS_SUCCESS:
        case GET_PR_UNASSIGNED_APPLICANTS_SUCCESS:
            return handleSuccessFetching(state, action.payload)
        case GET_PR_APPLICANTS_FAILURE:
        case GET_PR_REJECTED_APPLICANTS_FAILURE:
        case GET_PR_INVITED_APPLICANTS_FAILURE:
        case GET_PR_UNASSIGNED_APPLICANTS_FAILURE:
            return handleFailureFetching(state, action.error, action.payload);
        case RESET_PR_APPLICANTS_REQUEST:
        case RESET_PR_INVITED_APPLICANTS:
        case RESET_PR_REJECTED_APPLICANTS:
        case RESET_PR_UNASSIGNED_APPLICANTS:
            return resetApplicantsByType(state, action.payload);
        case PR_REJECT_AN_APPLICANT_SUCCESS:
            return {
                ...state,
                applicants: {
                    ...state.applicants,
                    currentApplicants: state.applicants.currentApplicants.filter(_ => !action.payload.profile_id.includes(_.profile.id))
                },
                totalApplicants: state.totalApplicants - 1 < 0
                    ? 0
                    : state.totalApplicants - 1,
                rejectedApplicants: state.rejectedApplicants + 1
            }
        case PR_SHORTLIST_AN_SUCCESS:
            return {
                ...state,
                rejected: {
                    ...state.rejected,
                    currentApplicants: state.rejected.currentApplicants.filter(_ => !action.payload.profile_id.includes(_.profile.id))
                },
                totalApplicants: state.totalApplicants + 1,
                rejectedApplicants: state.rejectedApplicants - 1 < 0
                    ? 0
                    :  state.rejectedApplicants - 1
            }
        case PR_INVITE_AN_APPLICANT_SUCCESS:
            return {
                ...state,
                invitedApplicantsCount: (state.invitedApplicantsCount || 0) + 1
            }
        case PR_MANAGE_ADD_TO_INVITED_APPLICANTS:
            return {
                ...state,
                invited: {
                    ...state.invited,
                    currentApplicants: [...state.invited.currentApplicants, ...action.payload]
                }
            }
        case ACCEPT_AGE_PROOF_DOCUMENT_SUCCESS:
            if (!action.payload.isPR) return {...state};
            let applicantsDataToUse = action.payload.isActiveApplicants
                ? 'applicants'
                : 'rejected'
            return {
                ...state,
                [applicantsDataToUse]: state[applicantsDataToUse]
                    && state[applicantsDataToUse].currentApplicants.length
                    ? {
                        ...state[applicantsDataToUse],
                        currentApplicants: state[applicantsDataToUse].currentApplicants.map(_ => {
                            if (_.profile_id !== action.payload.profileId) return _;
                            return {
                                ..._,
                                documents_verified: 1
                            }
                        })
                    }
                    : state[applicantsDataToUse]
            }
        case REJECT_AGE_PROOF_DOCUMENT_SUCCESS:
            if (!action.payload.isPR) return {...state};
            let applicantsDataToUseForReject = action.payload.isActiveApplicants
                ? 'applicants'
                : 'rejected'
            return {
                ...state,
                [applicantsDataToUseForReject]: state[applicantsDataToUseForReject]
                    && state[applicantsDataToUseForReject].currentApplicants.length
                    ? {
                        ...state[applicantsDataToUseForReject],
                        currentApplicants: state[applicantsDataToUseForReject].currentApplicants.filter(_ => _.profile_id !== action.payload.profileId),
                    }
                    : state[applicantsDataToUseForReject],
                totalApplicants: state.totalApplicants - (action.payload.isActiveApplicants ? 1 : 0),
                rejectedApplicants: state.rejectedApplicants - (!action.payload.isActiveApplicants ? 1 : 0)
            }
        default:
            return state;
    }
}