import { GET_PUBLIC_SHARED_POST_DETAILS_REQUEST,
    GET_PUBLIC_SHARED_POST_DETAILS_SUCCESS,
    GET_PUBLIC_SHARED_POST_DETAILS_FAILURE,
    RESET_PUBLIC_SHARED_POST_DETAILS } from './constants';
import { onRequest,
    onSuccess,
    onError,
    getResponseData } from '../../utils/helper';
import { publicViewLinks,
    getBaseUrl } from '../../utils/links';
import Axios from 'axios';
let CancelToken = Axios.CancelToken;
// action to get details
export let getASharedPostCancelToken = undefined;
export const getASharedPost = (sharedId,
    postType,
    postId,
    token) => {
    return dispatch => {
        if (getASharedPostCancelToken) getASharedPostCancelToken();
        dispatch(onRequest(GET_PUBLIC_SHARED_POST_DETAILS_REQUEST));
        let url = `${getBaseUrl()}${publicViewLinks.sharedPost(sharedId,
            postType,
            postId)}`,
            options = {
                url,
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                cancelToken: new CancelToken((c) => getASharedPostCancelToken = c)
            };
        return Axios(options)
            .then(response => {
                let successData = getResponseData(response);
                if (successData) {
                    dispatch(onSuccess(GET_PUBLIC_SHARED_POST_DETAILS_SUCCESS, {
                        postType,
                        postData: successData
                    }));
                } else {
                    dispatch(onError(GET_PUBLIC_SHARED_POST_DETAILS_FAILURE, response.data.errors));
                }
                return successData;
            })
            .catch(error => {
                dispatch(onError( GET_PUBLIC_SHARED_POST_DETAILS_FAILURE, error));
                return false;
            });
    }
}

// action to reset shoutou details
export const resetSharedDetailPost = () => ({type: RESET_PUBLIC_SHARED_POST_DETAILS});

// action to set data data
export const setSharedPostData = (details) => (onSuccess(GET_PUBLIC_SHARED_POST_DETAILS_SUCCESS, { details,
    isFromPreviousData: true}));