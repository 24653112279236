import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { modelRedirectFn } from './utils';
import { gaEvents } from '../../utils/events';

const Banner = (props) => {
  const { data, history } = props;
  const imageMeta = get(data, 'images_meta', {});

  const handleClick = (obj) => {
    const modelName = get(obj, 'model_name');
    gaEvents.landingpage.imageCard(modelName);
    const redirectUrl = modelRedirectFn(modelName, get(obj, 'model_id'));
    history.push(redirectUrl);
  };

  return (
    <div
      className="cursor-pointer feed-bannerimage"
      onClick={() => {
        handleClick(data);
      }}
    >
      <img src={get(imageMeta, 'original_photo', '')} alt="banner" />
    </div>
  );
};

Banner.propTypes = {
  data: PropTypes.object,
  history: PropTypes.object,
};
export default Banner;
