import React from "react";
import { toast, Slide } from "react-toastify";
import { Link } from "react-router-dom";

const getDeleteText = {
  collaborate: "collaboration",
  polling: "poll",
  surveys: "survey",
  photo: "photo",
  quiz: "quiz",
  shoutout: "post",
  tasting: "tasting"
}
export const TOAST_MESSAGE = {
  InviteUser: "Taster has been already Invited",
  PollUpdate: "Your poll has been updated",
  PollCreate: "Your poll has been created",
  PostCreate: "Your post has been created",
  ChatGropuLimit: "Maximum Participants Limit Reached !",
  UnblockProfile: "Successfully unblocked",
  BlockedProfile: "Successfully Blocked",
  ImageSizeExceed: "Maximum file size should not exceed 10 MB",
  AlreadyBlocked: (type) => {
    return `You have already blocked this ${type}. Please refresh your screens.`
  },
  delete: (type) => {
    return `Your ${getDeleteText[type]} has been deleted`
  },
  SharedDelete: "Your shared post has been deleted",
  ProductReview: "Your tasting has been deleted",
  NoPassBookTransactions: "No transaction history available"
}

const LinkComponent = (props) => {
  const { link } = props;
  return <Link className="createbar__toast__content__link" to={link}>
    View
  </Link>
}
const ActionComponent = (props) => {
  const { handleAction, actionText } = props;
  return <span
    className="createbar__toast__content__link"
    onClick={() => {
      if (handleAction) handleAction();
    }}
  >
    {actionText}
  </span>
}
const showToaster = (...params) => {
  const [{ onClose, link, text, id, handleAction, actionText, autoClose, toastBackground = "black" }] = params;
  const ToasterComponent = (
    <div className={`createbar__toast__content ${!link && !actionText ? "isNotLink" : ""} `}>
      <span className="createbar__toast__content__text">{text}</span>
      <span>{link ? <LinkComponent link={link} /> : ""}</span>
      <span>{actionText ? <ActionComponent handleAction={handleAction} actionText={actionText} /> : ""}</span>
    </div>
  );

  return toast(ToasterComponent, {
    className: `createbar__toast ${toastBackground}`,
    position: "bottom-center",
    autoClose: autoClose || 3000,
    transition: Slide,
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    closeButton: false,
    theme: "dark",
    onClose: onClose ? () => { onClose() } : null,
    toastId: id,
  });
};

export default showToaster;
