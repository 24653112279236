import { GET_TTFB_PRODUCTS_REQUEST,
    GET_TTFB_PRODUCTS_SUCCESS,
    GET_TTFB_PRODUCTS_FAILURE,
    RESET_TTFB_PRODUCTS } from '../../actions/business/constants';

const INITIAL_STATE = {
    fetching: false,
    page: 1,
    error: false,
    columns: [],
    data: []
}

export default function partners(state = INITIAL_STATE, action) {
    switch(action.type) {
        case GET_TTFB_PRODUCTS_REQUEST:
            return {
                ...state,
                fetching: true
            }
        case GET_TTFB_PRODUCTS_SUCCESS:
            return {
                ...state,
                fetching: false,
                columns: action.payload.columns,
                data: state.page === 1
                    ? action.payload.data
                    : [...state.data,
                    ...action.payload.data],
                page: state.page + 1,
                hasEnd: (!action.payload.data.length
                    || (!!action.payload.data.length
                        && action.payload.data.length < 20)),
                hasFetched: true
            }
        case GET_TTFB_PRODUCTS_FAILURE:
            return {
                ...state,
                ...(!action.error.__CANCEL__ && {
                    error: true,
                    errorMsg: action.error
                }),
                fetching: false,
            }
        case RESET_TTFB_PRODUCTS:
            return INITIAL_STATE
        default:
            return state;
    }
}