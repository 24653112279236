import { GO_TO_NEXT_REQUEST,
    GO_TO_PREV_REQUEST } from "../../actions/onboarding/constants";

const INITIAL_STATE = {
    activePage: 'ProfileDetails',
    onboardingPages: ['ProfileDetails', 'NewJobProfile', 'Specializations']
}

const getInitialState = () => INITIAL_STATE;
export default function pages(state = getInitialState(), action)  {
    switch (action.type) {
        case GO_TO_NEXT_REQUEST:
            return {
                ...state,
                activePage: state.onboardingPages[state.onboardingPages.indexOf(action.payload) + 1]
            }
        case GO_TO_PREV_REQUEST:
            return {
                ...state,
                activePage: state.onboardingPages[state.onboardingPages.indexOf(action.payload) - 1]
            }
        default:
            return state;
    }
}

