import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AddMore from '../../../assets/images/profile/view/add-more.svg';

function EmptyField(props){
    const { title,
        subTitle,
        clickFunc,
        classNameToAppend } = props;
    return(<div className="profile-view__empty">
            <div>
                {title && <p className={`profile-view__empty__title ${classNameToAppend ? classNameToAppend : ''}`}>{title}</p>}
                {subTitle && <p className={`profile-view__empty__subtitle`}>{subTitle}</p>}
            </div>
            <button
                className="profile-view__empty__btn"
                onClick={() => {
                    if (clickFunc) clickFunc();

                }}>
                <img src={AddMore} alt={`add ${title}`} className="profile-view__empty__btn__image"/>
            </button>
        </div>)
}

EmptyField.propTypes = {
    classNameToAppend: PropTypes.string,
    subTitle: PropTypes.string,
    title: PropTypes.string,
    clickFunc: PropTypes.func
}

export default EmptyField;