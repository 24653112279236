import Axios from 'axios';
import { onRequest, onSuccess, onError, getResponseData } from '../../utils/helper';
import { getBaseUrl, landingUrls, collaborateUrls } from '../../utils/links';
import {
  GET_QUICK_LINKS,
  GET_QUICK_LINKS_SUCCESS,
  GET_QUICK_LINKS_FAILURE,
  GET_RIGHT_SIDE_DATA,
  GET_RIGHT_SIDE_DATA_SUCCESS,
  GET_RIGHT_SIDE_DATA_FAILURE,
  GET_LANDING_PAGE_DATA,
  GET_LANDING_PAGE_DATA_SUCCESS,
  GET_LANDING_PAGE_DATA_FAILURE,
  RESET_LANDING_PAGE_DATA,
  GET_FROM_FEED_REQUEST,
  GET_FROM_FEED_SUCCESS,
  GET_FROM_FEED_FAILURE,
  RESET_FROM_FEED_DETAILS,
  GET_LANDING_TOP_BAR_DATA,
  GET_LANDING_TOP_BAR_DATA_SUCCESS,
  GET_LANDING_TOP_BAR_DATA_FAILURE,
} from './constants';
const CancelToken = Axios.CancelToken;

export let getQuickLinksCancelToken = undefined;
export const getQuickLinks = (token) => {
  return (dispatch) => {
    if (getQuickLinksCancelToken) getQuickLinksCancelToken();
    dispatch(onRequest(GET_QUICK_LINKS));
    let url = `${getBaseUrl()}${landingUrls.quickLinks}`,
      options = {
        url,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        cancelToken: new CancelToken((c) => (getQuickLinksCancelToken = c)),
      };
    return Axios(options)
      .then((response) => {
        let successData = getResponseData(response);
        if (successData) {
          dispatch(
            onSuccess(GET_QUICK_LINKS_SUCCESS, {
              data: successData,
              isFromPreviousData: false,
            }),
          );
        } else {
          dispatch(onError(GET_QUICK_LINKS_FAILURE, response.data.errors));
        }
        return successData;
      })
      .catch((error) => {
        dispatch(onError(GET_QUICK_LINKS_FAILURE, error));
        return false;
      });
  };
};

export let getRightSideDataCancelToken = undefined;
export const getRightSideData = (token) => {
  return (dispatch) => {
    if (getRightSideDataCancelToken) getRightSideDataCancelToken();
    dispatch(onRequest(GET_RIGHT_SIDE_DATA));
    let url = `${getBaseUrl()}${landingUrls.rightSideData}`,
      options = {
        url,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        cancelToken: new CancelToken((c) => (getRightSideDataCancelToken = c)),
      };
    return Axios(options)
      .then((response) => {
        let successData = getResponseData(response);
        if (successData) {
          dispatch(
            onSuccess(GET_RIGHT_SIDE_DATA_SUCCESS, {
              data: successData,
              isFromPreviousData: false,
            }),
          );
        } else {
          dispatch(onError(GET_RIGHT_SIDE_DATA_FAILURE, response.data.errors));
        }
        return successData;
      })
      .catch((error) => {
        dispatch(onError(GET_RIGHT_SIDE_DATA_FAILURE, error));
        return false;
      });
  };
};

export let getLandingPageDataCancelToken = undefined;
export const getLandingPageData = (token, platform) => {
  return (dispatch) => {
    if (getLandingPageDataCancelToken) getLandingPageDataCancelToken();
    dispatch(onRequest(GET_LANDING_PAGE_DATA));
    const url = `${getBaseUrl()}${landingUrls.landingPage(platform)}`;
    const options = {
      url,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      cancelToken: new CancelToken((c) => (getLandingPageDataCancelToken = c)),
    };
    return Axios(options)
      .then((response) => {
        let successData = getResponseData(response);
        if (successData) {
          dispatch(
            onSuccess(GET_LANDING_PAGE_DATA_SUCCESS, {
              data: successData,
              isFromPreviousData: false,
            }),
          );
        } else {
          dispatch(onError(GET_LANDING_PAGE_DATA_FAILURE, response.data.errors));
        }
        return successData;
      })
      .catch((error) => {
        if (!Axios.isCancel(error)) {
          dispatch(onError(GET_LANDING_PAGE_DATA_FAILURE, error));
        }
        return false;
      });
  };
};

export const resetLandingPage = () => onSuccess(RESET_LANDING_PAGE_DATA);

export let getLimitedFeedCancelToken = undefined;
export const getLimitedFeed = (token) => {
  return (dispatch) => {
    if (getLimitedFeedCancelToken) getLimitedFeedCancelToken();
    dispatch(onRequest(GET_FROM_FEED_REQUEST));
    const url = `${getBaseUrl()}${landingUrls.feed}`;

    const options = {
      url,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      cancelToken: new CancelToken((c) => (getLimitedFeedCancelToken = c)),
    };

    return Axios(options)
      .then((response) => {
        const successData = getResponseData(response);
        if (successData) {
          dispatch(
            onSuccess(GET_FROM_FEED_SUCCESS, {
              data: successData,
            }),
          );
        } else {
          dispatch(onError(GET_FROM_FEED_FAILURE, response.data.errors));
        }
        return successData;
      })
      .catch((error) => {
        dispatch(onError(GET_FROM_FEED_FAILURE, error));
        return false;
      });
  };
};

export const resetLimitedFeed = () => onSuccess(RESET_FROM_FEED_DETAILS);

export let getAvailableProductsCancelToken = undefined;
export const getAvailableProducts = (token) => {
  if (getAvailableProductsCancelToken) getAvailableProductsCancelToken();
  let url = `${getBaseUrl()}${collaborateUrls.availableProducts}`,
    options = {
      url,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      cancelToken: new CancelToken((c) => (getAvailableProductsCancelToken = c)),
    };
  return Axios(options)
    .then((response) => {
      let successData = getResponseData(response);
      return successData;
    })
    .catch(() => {
      return false;
    });
};

// LANDING TOP DATA
export let getLandingTopBarDataCancelToken = undefined;
export const getLandingTopBarData = (token) => {
  return dispatch => {
    dispatch(onRequest(GET_LANDING_TOP_BAR_DATA));
    const url = `${getBaseUrl()}${landingUrls.topBar}`;
    const options = {
      url,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      cancelToken: new CancelToken((c) => (getLandingTopBarDataCancelToken = c)),
    };
    return Axios(options)
      .then((response) => {
        let successData = getResponseData(response);
        if (successData) {
          dispatch(
            onSuccess(GET_LANDING_TOP_BAR_DATA_SUCCESS, {
              data: successData,
              isFromPreviousData: false,
            }),
          );
        } else {
          dispatch(onError(GET_LANDING_TOP_BAR_DATA_FAILURE, response.data.errors));
        }
        return successData;
      })
      .catch((error) => {
        dispatch(onError(GET_LANDING_TOP_BAR_DATA_FAILURE, error));
        return false;
      });
  };
};
