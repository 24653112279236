import { FEED_REQUEST, FEED_SUCCESS, FEED_FAILURE } from '../constants';
import { RESET_FEED } from './constants';
import axios from 'axios';
import { onError, onRequest, onSuccess } from '../../utils/helper';
import { getBaseUrl, feedUrls } from '../../utils/links';
const CancelToken = axios.CancelToken;

export let getFeedCancelToken = undefined;
export const getFeed = (params, authToken) => {
  return (dispatch) => {
    if (getFeedCancelToken) getFeedCancelToken();
    dispatch(onRequest(FEED_REQUEST));
    let url = `${getBaseUrl()}${feedUrls.feed}`,
      options = {
        url,
        params,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        cancelToken: new CancelToken((c) => (getFeedCancelToken = c)),
      };
    return axios(options)
      .then((response) => {
        let feedData =
          response &&
          response.data &&
          (!response.data.errors.length ||
            (response.data.errors.length && response.data.errors[0] === 'No more feed'));
        if (feedData) {
          dispatch(onSuccess(FEED_SUCCESS, response.data.data));
        } else {
          dispatch(onError(FEED_FAILURE, response.data.error));
        }
        return feedData;
      })
      .catch((error) => {
        dispatch(onError(FEED_FAILURE, error));
        return false;
      });
  };
};

// reset feed
export const resetFeed = () => onSuccess(RESET_FEED);
