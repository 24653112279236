import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ProfileItemHeader from '../../profile/ProfileItemHeader';

function AgeProof(props) {
    const [user, setUser] = useState(props.user);
    useEffect(() => {
        setUser(props.user);
    }, [props.user]);
    const { isSelf,
        toggleAgeProofModal,
        addToFilledItems } = props;
    const documentMeta = user.user.profile.document_meta,
        isEditProof = documentMeta
            && documentMeta.images
            && !!documentMeta.images.length;
    useEffect(() => {
        if (props.user.user.profile.document_meta
            && props.user.user.profile.document_meta.images
            && props.user.user.profile.document_meta.images.length) {
            setTimeout(() => addToFilledItems('document_meta', true), 0);
        }
    }, []);
    return (<>
        <div className="profile-view__details__block profile-view__details__block__professional">
                <ProfileItemHeader clickFunc={() => toggleAgeProofModal()}
                    title="Age Proof"
                    isEdit={isEditProof}
                    isSelf={isSelf}
            />
            {
                documentMeta
                && !!documentMeta.images.length
                ? <p className="profile-view__details__block__personal__title">{!!documentMeta.is_verified ? 'Verified': 'Uploaded' }</p>
                : <p className="profile-view__details__block__personal__title">Add age proof</p>
            }
        </div>
    </>)
};

AgeProof.propTypes = {
    isSelf: PropTypes.bool,
    toggleAgeProofModal: PropTypes.func
}

function mapStateToProps(state) {
    return {
        user: state.user
    }
}
export default connect(mapStateToProps)(AgeProof);