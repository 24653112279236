import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import PillFiltersItems from "./PillFiltersItems";
function PillFilters(props) {
  const [filtersList, setFiltersList] = useState(props.filtersList),
    [activeFilterState, setActiveFilterState] = useState(
      props.activeFilterState
    );
  useEffect(() => {
    setFiltersList(props.filtersList);
    setActiveFilterState(props.activeFilterState);
  }, [props]);

  const filtersListItems = filtersList.map((filter, index) => (
    <PillFiltersItems
      handleClick={() => {
        props.handleClick(filter);
      }}
      isSurvey={props.isSurvey}
      isQuiz={props.isQuiz}
      isInvitation={props.isInvitation}
      filter={filter}
      disabled={props.disabled || false}
      isActive={activeFilterState && filter.state === activeFilterState.state}
      key={`filters-${index}`}
    />
  ));
  return <ul className="pill-filters__list">{filtersListItems}</ul>;
}

PillFilters.propTypes = {
  filtersList: PropTypes.array.isRequired,
  activeFilterState: PropTypes.object,
  handleClick: PropTypes.func.isRequired,
  isSurvey: PropTypes.bool,
};

export default PillFilters;
