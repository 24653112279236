import {
  GET_TTFB_LEAD_STATUS_REQUEST,
  GET_TTFB_LEAD_STATUS_SUCCESS,
  GET_TTFB_LEAD_STATUS_FAILURE,
  GET_TTFB_SIDEBAR_REQUEST,
  GET_TTFB_SIDEBAR_SUCCESS,
  GET_TTFB_SIDEBAR_FAILURE,
  RESET_TTFB_SIDEBAR,
  GET_TTFB_PROFILE_DATA_REQUEST,
  GET_TTFB_PROFILE_DATA_SUCCESS,
  GET_TTFB_PROFILE_DATA_FAILURE,
  RESET_TTFB_PROFILE_DATA,
  SET_TTFB_SIDEBAR_DROPDOWN,
} from "./constants";
import { ttfbLinks, ttfbBaseUrl } from "../../utils/links";
import {
  getResponseData,
  onSuccess,
  onError,
  onRequest,
  getResponseDataWithStatus,
  decodeString,
} from "../../utils/helper";
import Axios from "axios";
const CancelToken = Axios.CancelToken;

// method to get lead status
export let getTTFBLeadStatusCancelToken = undefined;
export const getTTFBLeadStatus = (token) => {
  return (dispatch) => {
    if (getTTFBLeadStatusCancelToken) getTTFBLeadStatusCancelToken();
    dispatch(onRequest(GET_TTFB_LEAD_STATUS_REQUEST));
    let url = `${ttfbBaseUrl()}${ttfbLinks.leadStatus}`,
      options = {
        url,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        cancelToken: new CancelToken((c) => (getTTFBLeadStatusCancelToken = c)),
      };
    return Axios(options)
      .then((response) => {
        let successData = getResponseData(response);
        if (successData) {
          dispatch(onSuccess(GET_TTFB_LEAD_STATUS_SUCCESS, successData));
        } else {
          dispatch(onError(GET_TTFB_LEAD_STATUS_FAILURE, response.data.errors));
        }
        return successData;
      })
      .catch((error) => {
        dispatch(onError(GET_TTFB_LEAD_STATUS_FAILURE, error));
        return false;
      });
  };
};

// method to get sidebar
export let getTTFBSidebarCancelToken = undefined;
export const getTTFBSidebar = (token) => {
  return (dispatch) => {
    if (getTTFBSidebarCancelToken) getTTFBSidebarCancelToken();
    dispatch(onRequest(GET_TTFB_SIDEBAR_REQUEST));
    let url = `${ttfbBaseUrl()}${ttfbLinks.sideBar}`,
      options = {
        url,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        cancelToken: new CancelToken((c) => (getTTFBSidebarCancelToken = c)),
      };
    return Axios(options)
      .then((response) => {
        let successData = getResponseDataWithStatus(response.data);
        if (successData.status) {
          dispatch(onSuccess(GET_TTFB_SIDEBAR_SUCCESS, successData.data));
        } else {
          dispatch(onError(GET_TTFB_SIDEBAR_FAILURE, successData.error));
        }
        return successData;
      })
      .catch((error) => {
        dispatch(onError(GET_TTFB_SIDEBAR_FAILURE, error));
        return getResponseDataWithStatus();
      });
  };
};

// method to reset sidebar
export const resetTTFBSidebar = () => onSuccess(RESET_TTFB_SIDEBAR);

// method to get ttfb profile
export let getTTFBProfileCancelToken = undefined;
export const getTTFBProfile = (token) => {
  return (dispatch) => {
    if (getTTFBProfileCancelToken) getTTFBProfileCancelToken();
    dispatch(onRequest(GET_TTFB_PROFILE_DATA_REQUEST));
    let url = `${ttfbBaseUrl()}${ttfbLinks.profile}`,
      options = {
        url,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        cancelToken: new CancelToken((c) => (getTTFBProfileCancelToken = c)),
      };
    return Axios(options)
      .then((response) => {
        let successData = getResponseDataWithStatus(response.data);
        if (successData.status) {
          dispatch(onSuccess(GET_TTFB_PROFILE_DATA_SUCCESS, successData.data));
        } else {
          dispatch(onError(GET_TTFB_PROFILE_DATA_FAILURE, successData.error));
        }
        return successData;
      })
      .catch((error) => {
        dispatch(onError(GET_TTFB_PROFILE_DATA_FAILURE, error));
        return getResponseDataWithStatus();
      });
  };
};

// method to reset sidebar
export const resetTTFBProfile = () => onSuccess(RESET_TTFB_PROFILE_DATA);

// method to search distributors
export const searchTTFBByType = (token, term, type) => {
  let headers = new Headers();
  headers.append("Authorization", `Bearer ${token}`);
  let init = {
    method: "GET",
    headers,
  };
  let url = `${ttfbBaseUrl()}${ttfbLinks.searchByType(type)}?q=${term}`,
    myRequest = new Request(url, init);
  return fetch(myRequest)
    .then((res) => res.json())
    .then((res) => {
      if (res && res.data && res.data.length) {
        return res.data.map((_) => ({
          value: _.user_id,
          label: decodeString(_.name),
          image: _.image_meta,
        }));
      }
      return [];
    });
};

// method will have current active sidebar id
export const setTTFBSidebarDropdown = (sidebarDropdown) =>
  onSuccess(SET_TTFB_SIDEBAR_DROPDOWN, sidebarDropdown);
