import { GET_PR_MANAGE_QUESTION_COMMENTS_REQUEST,
    GET_PR_MANAGE_QUESTION_COMMENTS_SUCCESS,
    GET_PR_MANAGE_QUESTION_COMMENTS_FAILURE,
    RESET_PR_MANAGE_QUESTION_COMMENTS } from "../../../../actions/collaborations/prManage/constants";

const INITIAL_STATE = {
    fetching: false,
    data: [],
    error: false,
    page: 1
}

export default function comments(state = INITIAL_STATE, action) {
    switch(action.type) {
        case GET_PR_MANAGE_QUESTION_COMMENTS_REQUEST:
            return {
                ...state,
                fetching: true
            }
        case GET_PR_MANAGE_QUESTION_COMMENTS_SUCCESS:
            return {
                ...state,
                data: [...state.data, ...action.payload],
                page:  state.page + 1,
                fetching: false,
                hasEnd: (!action.payload || (action.payload && !action.payload.length))
            }
        case GET_PR_MANAGE_QUESTION_COMMENTS_FAILURE:
            return {
                ...state,
                ...(!action.error.__CANCEL__ && {
                    error: true,
                    errorMsg: action.error
                }),
                fetching: false
            }
        case RESET_PR_MANAGE_QUESTION_COMMENTS:
            return INITIAL_STATE
        default:
            return state;
    }
}