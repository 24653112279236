import { GET_COLLABORATION_APPLICANTS_REQUEST,
    GET_COLLABORATION_APPLICANTS_SUCCESS,
    GET_COLLABORATION_APPLICANTS_FAILURE,
    GET_COLLABORATION_REJECTED_APPLICANTS_REQUEST,
    GET_COLLABORATION_REJECTED_APPLICANTS_SUCCESS,
    GET_COLLABORATION_REJECTED_APPLICANTS_FAILURE,
    RESET_COLLABORATION_APPLICANTS_DATA,
    REJECT_FILE_UPLOADED_CONTENT_COLLAB,
    REJECT_COLLAB_APPLICANT_SUCCESS,
    RESTORE_COLLAB_APPLICANT_SUCCESS,
    ACCEPT_AGE_PROOF_DOCUMENT_SUCCESS,
    REJECT_AGE_PROOF_DOCUMENT_SUCCESS
} from '../../actions/collaborations/constants';

const INITIAL_STATE = {
    applicantsData: {
        fetching: false,
        all: [],
        count: 0,
        page: 1,
        hasEndReached: false
    },
    rejectedApplicantsData: {
        fetching: false,
        all: [],
        count: 0,
        page: 1,
        hasEndReached: false
    }
}

const getFilteredList = (list, item, isExclude) => (isExclude
        ? list.filter(listItem => listItem[ item.isCompany ? 'company_id' : 'profile_id'] !== item.userId)
        : list.filter(listItem => listItem[ item.isCompany ? 'company_id' : 'profile_id'] === item.userId)),
    addToList = (list, itemToAdd) => [...itemToAdd, ...list],
    getCount = (count, isAdd) => (isAdd
        ? (count + 1)
        : count - 1 <= 0
        ? 0
        : count - 1);

const getApplicantsListForSubmissionDocument = (list, payload) => {
    const { applicant } = payload;
    const isApplicantCompany = !!applicant.company_id
        && !!applicant.company,
        idToCheck = isApplicantCompany
            ? applicant.company_id
            : applicant.profile.id;
    return list.map(_ => {
        if (isApplicantCompany
            && !!_.company_id
            && _.company_id === idToCheck) {
            return {
                ..._,
                submission_count: _.submission_count - 1 <= 0 ? 0 : _.submission_count - 1
            }
        }
        if (!isApplicantCompany
            && !_.company_id
            && _.profile.id === idToCheck) {
            return {
                ..._,
                submission_count: _.submission_count - 1 <= 0 ? 0 : _.submission_count - 1
            }
        }
        return _;
    })
}

export default function collabManage(state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_COLLABORATION_APPLICANTS_REQUEST:
            return {
                ...state,
                applicantsData: {
                    ...state.applicantsData,
                    fetching: true
                }
            };
        case GET_COLLABORATION_APPLICANTS_SUCCESS:
            return {
                ...state,
                applicantsData: {
                    ...state.applicantsData,
                    fetching: false,
                    all: state.applicantsData.page === 1
                        ? action.payload.application
                        : [...state.applicantsData.all, ...action.payload.application],
                    hasEndReached: !action.payload.application.length,
                    page: state.applicantsData.page + 1,
                    count: action.payload.count
                }
            }
        case GET_COLLABORATION_APPLICANTS_FAILURE:
            return {
                ...state,
                applicantsData: {
                    ...state.applicantsData,
                    fetching: false,
                    error: true,
                    errorMsg: action.error
                }
            }
        case GET_COLLABORATION_REJECTED_APPLICANTS_REQUEST:
            return {
                ...state,
                rejectedApplicantsData: {
                    ...state.rejectedApplicantsData,
                    fetching: true
                }
            };
        case GET_COLLABORATION_REJECTED_APPLICANTS_SUCCESS:
            return {
                ...state,
                rejectedApplicantsData: {
                    ...state.rejectedApplicantsData,
                    fetching: false,
                    all: state.rejectedApplicantsData.page === 1
                        ? action.payload.archived
                        : [...state.rejectedApplicantsData.all, ...action.payload.archived],
                    hasEndReached: !action.payload.archived.length,
                    page: state.rejectedApplicantsData.page + 1,
                    count: action.payload.count
                }
            }
        case GET_COLLABORATION_REJECTED_APPLICANTS_FAILURE:
            return {
                ...state,
                rejectedApplicantsData: {
                    ...state.rejectedApplicantsData,
                    fetching: false,
                    error: true,
                    errorMsg: action.error
                }
            }
        case REJECT_COLLAB_APPLICANT_SUCCESS:
            return {
                ...state,
                applicantsData: {
                    ...state.applicantsData,
                    all: getFilteredList(state.applicantsData.all,
                        action.payload,
                        true),
                    count: getCount(state.applicantsData.count)
                }
            }
        case RESTORE_COLLAB_APPLICANT_SUCCESS:
            return {
                ...state,
                rejectedApplicantsData: {
                    ...state.rejectedApplicantsData,
                    all: getFilteredList(state.rejectedApplicantsData.all,
                        action.payload,
                        true),
                    count: getCount(state.rejectedApplicantsData.count)
                }
            }
        case REJECT_FILE_UPLOADED_CONTENT_COLLAB:
            return {
                ...state,
                [action.payload.activeApplicantsType]: state[action.payload.activeApplicantsType]
                    && state[action.payload.activeApplicantsType].all
                    && state[action.payload.activeApplicantsType].all.length
                    ? {
                        ...state[action.payload.activeApplicantsType],
                        all: getApplicantsListForSubmissionDocument(state[action.payload.activeApplicantsType].all, action.payload)
                    }
                    : state[action.payload.activeApplicantsType]
            }
        case ACCEPT_AGE_PROOF_DOCUMENT_SUCCESS:
            if (action.payload.isPR) return {...state};
            const applicantsDataToUse = action.payload.isActiveApplicants
                ? 'applicantsData'
                : 'rejectedApplicantsData'
            return {
                ...state,
                [applicantsDataToUse]: state[applicantsDataToUse]
                    && state[applicantsDataToUse].all
                    && state[applicantsDataToUse].all.length
                    ? {
                        ...state[applicantsDataToUse],
                        all: state[applicantsDataToUse].all.map(_ => {
                            if (_.profile_id !== action.payload.profileId) return _;
                            return {
                                ..._,
                                documents_verified: 1
                            }
                        })
                    }
                    : state[applicantsDataToUse]
            }
        case REJECT_AGE_PROOF_DOCUMENT_SUCCESS:
            if (action.payload.isPR) return {...state};
            let applicantsDataToUseForReject = action.payload.isActiveApplicants
                ? 'applicantsData'
                : 'rejectedApplicantsData'
            return {
                ...state,
                [applicantsDataToUseForReject]: state[applicantsDataToUseForReject]
                    && state[applicantsDataToUseForReject].all
                    && state[applicantsDataToUseForReject].all.length
                    ? {
                        ...state[applicantsDataToUseForReject],
                        all: state[applicantsDataToUseForReject].all.filter(_ => _.profile_id !== action.payload.profileId),
                        count: state[applicantsDataToUseForReject].count - 1
                    }
                    : state[applicantsDataToUseForReject]
            }
        case RESET_COLLABORATION_APPLICANTS_DATA:
            return INITIAL_STATE;
        default:
            return state;
    }
}