import { GET_PROFILES_FOR_TAGGING, GET_ALL_USERS_FOR_TAGGING } from './constants';
import { onSuccess } from '../utils/helper';
import { getBaseUrl, taggingUrls } from '../utils/links';
import Axios from 'axios';

export const getProfilesForTagging = (term, token, isEveryone = false) => {
  return (dispatch) => {
    const url = isEveryone
      ? `${getBaseUrl()}${taggingUrls.all(term)}`
      : `${getBaseUrl()}${taggingUrls.profiles(term)}`;

    const options = {
      url,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return Axios(options)
      .then((response) => {
        let successData =
          response && response.data && response.data.data ? response.data.data : false;
        if (successData) {
          dispatch(
            onSuccess(isEveryone ? GET_ALL_USERS_FOR_TAGGING : GET_PROFILES_FOR_TAGGING, {
              data: successData,
              term,
            }),
          );
          return successData;
        }
        return false;
      })
      .catch(() => {
        return false;
      });
  };
};
