import React, { useEffect, useState } from 'react'
import InvitationListItem from '../invitations/InvitationListItem';
import { invitationDetailsById } from '../../actions/invitations/list';
import { genericErrorText } from '../../utils/commonVariables';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import isEmpty from 'lodash/isEmpty';
import { invitationAction } from '../../actions/invitations/update';
import Modal from './common/Modal';
import { hideInvitationDetailsModal } from '../../actions/common/modals';

function ShowInvitationDetailsModal(props) {
    const { handleCloseInvitationModal, invitationId, handleUpdateList, token, user: {
        user: { profile }
    }, notificationViewDetail = false, showInvitationModal: { data }, hideInvitationDetailsModal } = props;
    const [invitationData, setInvitationData] = useState({});
    const getInvitationData = () => {
        invitationDetailsById(token, invitationId, profile.id).then(res => {
            if (!!res && !!res.data) {
                setInvitationData({ ...res.data });
            } else {
                const { errors: { display_message } } = res
                alert(display_message || genericErrorText.general);
                hideInvitationDetailsModal();
            }
        })
    }

    const insideHandleUpdate = (e, id, type) => {
        if (!e) return;
        e.preventDefault();
        const data = {
            "accepted": type === "accept" ? 1 : 0
        }
        invitationAction(token, id, data, profile.id).then((res) => {
            if (!!res && !!res.data) {
                setInvitationData(prevData => {
                    return {
                        ...prevData,
                        ["status"]: res.data.status
                    }
                });
                handleUpdateList(res.data, id);
            }
        })
    }
    useEffect(() => {
        getInvitationData();
    }, []);
    if (isEmpty(invitationData)) return null;

    return (
        <Modal
            showHeader={false}
            className="invitation-details-modal"
            containerClassName="invitation-list"
        >
            <InvitationListItem modal={true} data={invitationData} handleCloseInvitationModal={handleCloseInvitationModal} handleUpdate={insideHandleUpdate} notificationViewDetail={notificationViewDetail} />
        </Modal>
    )
}


ShowInvitationDetailsModal.propTypes = {
    token: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
    return {
        token: state.login.token,
        user: state.user,
        showInvitationModal: state.modalReducer.collaboration.showInvitationModal,

    };
}

const mapDispatchToProps = {
    hideInvitationDetailsModal
};
export default connect(mapStateToProps, mapDispatchToProps)(ShowInvitationDetailsModal);