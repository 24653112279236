import { GET_TTFB_OVERVIEW_REQUEST,
    GET_TTFB_OVERVIEW_SUCCESS,
    GET_TTFB_OVERVIEW_FAILURE,
    RESET_TTFB_OVERVIEW } from "../../actions/business/constants";

const INITIAL_STATE = {
};

export default function overview(state = INITIAL_STATE, action) {
    switch(action.type) {
        case GET_TTFB_OVERVIEW_REQUEST:
            return {
                ...state,
                [action.payload.type]: {
                    fetching: true,
                    hasFetched: false,
                    data: []
                }
            }
        case GET_TTFB_OVERVIEW_SUCCESS:
            return {
                ...state,
                [action.payload.type]: {
                    ...(state[action.payload.type]),
                    data: action.payload.type.includes('product')
                        ? action.payload.data.overview
                        : action.payload.data,
                    fetching: false,
                    hasFetched: true
                }
            }
        case GET_TTFB_OVERVIEW_FAILURE:
            return {
                ...state,
                [action.payload.type]: {
                    fetching: false,
                    ...(state[action.payload.type]),
                    ...(!action.error.__CANCEL__ && {
                        error: true,
                        errorObj: action.error
                    })
                }
            }
        case RESET_TTFB_OVERVIEW:
            let currentState = { ...state };
            delete currentState[action.payload.type];
            return {
                ...currentState
            }
        default:
            return state;
    }
}