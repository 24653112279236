import {
    GET_COLLAB_BATCHES_REQUEST,
    GET_COLLAB_BATCHES_SUCCESS,
    GET_COLLAB_BATCHES_FAILURE,
    GET_BATCHES_COLOR_REQUEST,
    GET_BATCHES_COLOR_SUCCESS,
    GET_BATCHES_COLOR_FAILURE,
    RESET_COLLAB_BATCHES,
    CREATE_OR_EDIT_PR_PRODUCT_SUCCESS
} from '../../../actions/collaborations/batches/constants';
import { getDemogrpahyEmptyData } from '../../../utils/tastingHelper';

const INITIAL_STATE = {
    list: {
        all: [],
        fetching: false,
        error: null,
        hasFetched: false
    },
    colors: {
        all: [],
        fetching: false,
        error: null,
        hasFetched: false
    }
}

const handleProductChange = (state, payload) => {
    return state.list.all.map(_ => {
        if (_.id === payload.data.id) {
            return {
                ..._,
                ...payload.data,
            }
        }
        return _;
    })
}

export default function batches(state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_COLLAB_BATCHES_REQUEST:
        case GET_BATCHES_COLOR_REQUEST:
            return {
                ...state,
                [action.payload.type]: {
                    ...state[action.payload.type],
                    fetching: true
                }
            }
        case GET_COLLAB_BATCHES_SUCCESS:
            return {
                ...state,
                [action.payload.type]: {
                    ...state[action.payload.type],
                    fetching: false,
                    all: action.payload.data,
                    hasFetched: true
                }
            }
        case GET_BATCHES_COLOR_SUCCESS:
            return {
                ...state,
                [action.payload.type]: {
                    ...state[action.payload.type],
                    fetching: false,
                    all: action.payload.data,
                    hasFetched: true
                }
            }
        case CREATE_OR_EDIT_PR_PRODUCT_SUCCESS:
            return {
                ...state,
                list: {
                    ...state.list,
                    all: action.payload.isEdit
                        ? handleProductChange(state, action.payload)
                        : [
                            ...state.list.all,
                            action.payload.data
                        ]
                }
            }
        case GET_COLLAB_BATCHES_FAILURE:
        case GET_BATCHES_COLOR_FAILURE:
            return {
                ...state,
                [action.payload.type]: {
                    ...state[action.payload.type],
                    fetching: false,
                    error: true,
                    errorMsg: action.error
                }
            }
        case RESET_COLLAB_BATCHES:
            return {
                ...state,
                list: { ...INITIAL_STATE.list }
            }
        default:
            return state;
    }
}