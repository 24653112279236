import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect }  from 'react-redux';
import { followCompany } from '../../actions/company/follow';

function FollowCompanyBtn(props) {
    const [processing, setProcessing] = useState(false),
        { profileId,
            className,
            isCurrentUser,
            token,
            isPublic,
            publicClickAction,
            followCompany,
            companyId,
            isDisabled } = props;
    const _followCompany = (e) => {
        if (isPublic) {
            if (publicClickAction && typeof publicClickAction === "function") {
                publicClickAction();
            }
            return;
        }
        if (processing) return;
        setProcessing(true);
        followCompany(profileId,
            companyId,
            token,
            isCurrentUser || false)
            .then(_ => {
                if (!_) {
                    setProcessing(false);
                }
            });
    }
    return (<button className={className || ''}
        disabled={isDisabled || processing}
        onClick={_followCompany}>
        {props.children}
    </button>)
};

FollowCompanyBtn.propTypes = {
    profileId: PropTypes.number,
    companyId: PropTypes.number,
    isDisabled: PropTypes.bool,
    isCurrentUser: PropTypes.bool,
    className: PropTypes.string,
    isPublic: PropTypes.bool,
    publicClickAction: PropTypes.func
}

function mapStateToProps(state) {
    return {
        token: state.login.token
    }
}

export default connect(mapStateToProps, { followCompany })(FollowCompanyBtn);