import {
    FOLLOW_USER_REQUEST,
    FOLLOW_USER_SUCCESS,
    FOLLOW_USER_FAILURE,
    UNFOLLOW_USER_REQUEST,
    UNFOLLOW_USER_SUCCESS,
    UNFOLLOW_USER_FAILURE,
    GET_FOLLOWERS_LIST_REQUEST,
    GET_FOLLOWERS_LIST_SUCCESS,
    GET_FOLLOWERS_LIST_FAILURE,
    RESET_FOLLOWERS_LIST,
    GET_FOLLOWING_LIST_REQUEST,
    GET_FOLLOWING_LIST_SUCCESS,
    GET_FOLLOWING_LIST_FAILURE,
    RESET_FOLLOWING_LIST
} from './constants';
import Axios from 'axios';
import { onRequest, onSuccess, onError, getResponseData } from '../../utils/helper';
import { getBaseUrl, profileLinks } from '../../utils/links';
let CancelToken = Axios.CancelToken;

// action to follow a user
export let followUserCancelToken = undefined;
export const followUser = (profileId,
    token,
    isCurrentUser = false) => {
    return dispatch => {
        if (followUserCancelToken) followUserCancelToken();
        dispatch(onRequest(FOLLOW_USER_REQUEST));
        let url = `${getBaseUrl()}${profileLinks.follow}`,
            options = {
                url,
                method: 'POST',
                data: {
                    id: profileId
                },
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                cancelToken: new CancelToken((c) => followUserCancelToken = c)
            };
        return Axios(options)
            .then(response => {
                let successData = getResponseData(response);
                if (response) {
                    dispatch(onSuccess(FOLLOW_USER_SUCCESS, {
                        ...successData,
                        profileId,
                        isCurrentUser
                    }))
                } else {
                    dispatch(onError(FOLLOW_USER_FAILURE, response.data.errors));
                }
                return successData
            })
            .catch(error => {
                dispatch(onError(FOLLOW_USER_FAILURE, error));
                return false;
            })
    }
}

// action to unfollow user
export let unfollowUserCancelToken = undefined;
export const unfollowUser = (profileId,
    token,
    isCurrentUser = false) => {
    return dispatch => {
        if (unfollowUserCancelToken) unfollowUserCancelToken();
        dispatch(onRequest(UNFOLLOW_USER_REQUEST));
        let url = `${getBaseUrl()}${profileLinks.unfollow}`,
            options = {
                url,
                method: 'POST',
                data: {
                    id: profileId
                },
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                cancelToken: new CancelToken((c) => unfollowUserCancelToken = c)
            };
        return Axios(options)
            .then(response => {
                let successData = getResponseData(response);
                if (response) {
                    dispatch(onSuccess(UNFOLLOW_USER_SUCCESS, {
                        ...successData,
                        profileId,
                        isCurrentUser
                    }))
                } else {
                    dispatch(onError(UNFOLLOW_USER_FAILURE, response.data.errors));
                }
                return successData
            })
            .catch(error => {
                dispatch(onError(UNFOLLOW_USER_FAILURE, error));
                return false;
            })
    }
}

// get followers list
export let getFollowersCancelToken = undefined;
export const getFollowers = (profileId,
    params,
    token) => {
    return dispatch => {
        if (getFollowersCancelToken) getFollowersCancelToken();
        dispatch(onRequest(GET_FOLLOWERS_LIST_REQUEST, { type: 'followers' }));
        let url = `${getBaseUrl()}${profileLinks.getFollowers(profileId)}`,
            options = {
                url,
                params,
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                cancelToken: new CancelToken((c) => getFollowersCancelToken = c)
            };
        return Axios(options)
            .then(response => {
                let successData = getResponseData(response);
                if (successData) {
                    dispatch(onSuccess(GET_FOLLOWERS_LIST_SUCCESS, {
                        data: successData,
                        type: 'followers'
                    }));
                } else {
                    dispatch(onError(GET_FOLLOWERS_LIST_FAILURE, response.data.errors, { type: 'followers' }));
                }
                return successData;
            })
            .catch(error => {
                dispatch(onError(GET_FOLLOWERS_LIST_FAILURE, error));
                return error;
            })
    }
}

// reset followers list
export const resetFollowersList = () => onSuccess(RESET_FOLLOWERS_LIST, { type: 'followers' })

// get following list
export let getFollowingCancelToken = undefined;
export const getFollowing = (profileId,
    params,
    token) => {
    return dispatch => {
        if (getFollowingCancelToken) getFollowingCancelToken();
        dispatch(onRequest(GET_FOLLOWING_LIST_REQUEST, { type: 'following' }));
        let url = `${getBaseUrl()}${profileLinks.getFollowing(profileId)}`,
            options = {
                url,
                params,
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                cancelToken: new CancelToken((c) => getFollowingCancelToken = c)
            };
        return Axios(options)
            .then(response => {
                let successData = getResponseData(response);
                if (successData) {
                    dispatch(onSuccess(GET_FOLLOWING_LIST_SUCCESS, {
                        data: successData,
                        type: 'following'
                    }));
                } else {
                    dispatch(onError(GET_FOLLOWING_LIST_FAILURE, response.data.errors, { type: 'following' }));
                }
                return successData;
            })
            .catch(error => {
                dispatch(onError(GET_FOLLOWING_LIST_FAILURE, error));
                return error;
            })
    }
}

// reset following list
export const resetFollowingList = () => onSuccess(RESET_FOLLOWING_LIST, { type: 'following' });