export const GET_FAQS_CATEGORIES_REQUEST = 'GET_FAQS_CATEGORIES_REQUEST';
export const GET_FAQS_CATEGORIES_SUCCESS = 'GET_FAQS_CATEGORIES_SUCCESS';
export const GET_FAQS_CATEGORIES_FAILURE = 'GET_FAQS_CATEGORIES_FAILURE';

export const GET_FAQ_QUESTIONS_REQUEST = 'GET_FAQ_QUESTIONS_REQUEST';
export const GET_FAQ_QUESTIONS_SUCCESS = 'GET_FAQ_QUESTIONS_SUCCESS';
export const GET_FAQ_QUESTIONS_FAILURE = 'GET_FAQ_QUESTIONS_FAILURE';

export const RESET_FAQ = 'RESET_FAQ';

export const UPDATE_FAQ_ACTIVE_CATEGORY = 'UPDATE_FAQ_ACTIVE_CATEGORY';

export const UPDATE_FAQ_ACTIVE_CATEGORY_QUESTION = 'UPDATE_FAQ_ACTIVE_CATEGORY_QUESTION';