import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import RightArrow from '../../../assets/images/login/left-arrow.svg';
import { decodeString } from '../../../utils/helper';
import { modelRedirectFn } from '../utils';

const CarouselHeader = (props) => {
  const { data, gaFn } = props;

  let modalId = get(data, 'model_id', '');
  const modalName = get(data, 'model_name', '');
  if (modalName === 'hashtag') {
    modalId = get(data, 'model_id', '').replace('#', '').trim();
  }

  const title = get(data, 'title', '');
  const subtitle = get(data, 'sub_title', '');
  const redirectUrl = modelRedirectFn(modalName, modalId);
  const linkToSeeAll = get(data, 'see_more', false) ? redirectUrl : '';

  let extra;
  if (modalName === 'polling' && get(data, 'value', '') === 'poll_result') {
    extra = true;
  }

  return (
    <div className="home__card__header">
      <div>
        <p className="home__card__header__text__title">{decodeString(title)}</p>
        {subtitle && <p className="review-page__list__sub-title">{subtitle}</p>}
      </div>
      {linkToSeeAll && (
        <Link
          to={{ pathname: linkToSeeAll, ...(extra && { state: { extra } }) }}
          className="home__card__header__icon"
          onClick={gaFn}
        >
          <img
            className="review-page__list__sub-title-container__see-more"
            src={RightArrow}
            alt={`see more ${modalName}`}
          />
        </Link>
      )}
    </div>
  );
};

CarouselHeader.propTypes = {
  data: PropTypes.object,
  gaFn: PropTypes.func,
};
export default CarouselHeader;
