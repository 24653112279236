import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CloseIcon from '../../../assets/images/common/modals/close.svg';
import { createPassword } from '../../../actions/settings/changePassword';

const texts = {
    title: 'Create Password'
}

function CreatePasswordModal(props) {
    const [disabledSubmit, setDisabledSubmit] = useState(false),
        [success, setSuccess] = useState(false),
        { onClose,
            token,
        user: { user: { email } } } = props;

    const handleSubmit = e => {
        e.preventDefault();
        if (disabledSubmit) return;
        setDisabledSubmit(true);
        const data = {
            email
        }
        createPassword(token,
            data)
            .then(_ => {
                setDisabledSubmit(false);
                if (_) {
                    setSuccess(true);
                } else {
                    alert('Something went wrong! Please try again');
                }
            })
    }

    return (<>
        <div className="default-modal__header">
            <p className="default-modal__header__title">{texts.title}</p>
            <button className="default-modal__header__close" onClick={() => {
                if (disabledSubmit) return;
                onClose();
            }}>
                <img src={CloseIcon} alt="close modal" />
            </button>
        </div>
        <div className="default-modal__content">
            {
                success
                ? <p className="profile-form-modals__title">We've sent you create password link! Please check your inbox.</p>
                : <form onSubmit={handleSubmit}>
                    <p className="profile-form-modals__title">To create a password for this account, please click <b>Send Email</b>.</p>
                    <div className="profile-form-modals__footer profile-form-modals__footer--right">
                        <button type="submit"
                            disabled={disabledSubmit}
                            className={`profile-form-modals__btn
                            ${(disabledSubmit)
                                ? 'profile-form-modals__btn--disabled'
                                : ''}`}>Send Email</button>
                    </div>
                </form>
            }
        </div>
    </>)
};

CreatePasswordModal.propTypes = {
    onClose: PropTypes.func
}

function mapStateToProps(state) {
    return {
        token: state.login.token,
        user: state.user
    }
}

export default connect(mapStateToProps)(CreatePasswordModal);