import { GET_TTFB_LEAD_LOGS_REQUEST,
    GET_TTFB_LEAD_LOGS_SUCCESS,
    GET_TTFB_LEAD_LOGS_FAILURE,
    RESET_TTFB_LEAD_LOGS,
    CREATE_TTFB_LEAD_LOGS_COMMENT_REQUEST,
    CREATE_TTFB_LEAD_LOGS_COMMENT_SUCCESS,
    CREATE_TTFB_LEAD_LOGS_COMMENT_FAILURE,
    APPEND_TO_TTFB_LEAD_LOG} from "../../actions/business/constants";

const INITIAL_STATE = {
    fetching: false,
    all: [],
    error: false,
    hasEnd: false,
    page: 1,
    creating: false,
    hasFetched: false
}

export default function logs(state = INITIAL_STATE, action) {
    switch(action.type) {
        case GET_TTFB_LEAD_LOGS_REQUEST:
            return {
                ...state,
                fetching: true
            }
        case GET_TTFB_LEAD_LOGS_SUCCESS:
            return {
                ...state,
                fetching: false,
                all: state.page === 1
                    ? action.payload
                    : [...state.all, ...action.payload],
                page: state.page + 1,
                hasEnd: (!action.payload.length || action.payload.length < 10),
                hasFetched: true
            }
        case CREATE_TTFB_LEAD_LOGS_COMMENT_REQUEST:
            return {
                ...state,
                creating: true
            }
        case CREATE_TTFB_LEAD_LOGS_COMMENT_SUCCESS:
            return {
                ...state,
                all: [action.payload, ...state.all],
                creating: false
            }
        case CREATE_TTFB_LEAD_LOGS_COMMENT_FAILURE:
        case GET_TTFB_LEAD_LOGS_FAILURE:
            return {
                ...state,
                error: true,
                creating: false,
                fetching: false,
                errorMsg: action.payload.error
            }
        case APPEND_TO_TTFB_LEAD_LOG:
            return {
                ...state,
                all: [action.payload, ...state.all]
            }
        case RESET_TTFB_LEAD_LOGS:
            return INITIAL_STATE
        default:
            return state;
    }
}