import { combineReducers } from 'redux';
import reviews from './reviews';
import collections from './collections';

const reviewsReducer = combineReducers({
    reviews,
    collections
});

export default reviewsReducer;
