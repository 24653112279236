import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toggleProfileCompletionModal } from '../../../../actions/common/modals';
import AllergenIcon from '../../../../assets/images/profile/completion/allergic-to.svg';
import ProfileCompletionCompleted from './ProfileCompletionCompleted';

const texts = {
    title: 'Any Allergens?',
    helper: 'Select the items that you are allergic to. We will use this information to refine our product suggestions for you.',
    btn: '+Allergens'
}

class AllergensCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasProfileCompleted: (this.props.profile
                && this.props.profile.allergens
                && !!this.props.profile.allergens.length)
        }
        // binding toggle modal
        this.toggleModal = this.toggleModal.bind(this);
    }

    toggleModal() {
        const { toggleProfileCompletionModal } = this.props;
        toggleProfileCompletionModal('allergens');
    }

    componentDidUpdate() {
        if (this.props.profile
            && this.props.profile.allergens
            && this.props.profile.allergens.length
            && !this.state.hasProfileCompleted) {
            this.setState({
                hasProfileCompleted: true
            })
        }
    }

    render() {
        return (<div className="feed-common-cards profile-completion-card">
            <div className="profile-completion-card__image__container">
                <img className="profile-completion-card__image" src={AllergenIcon} alt={texts.title} />
            </div>
            <div className="profile-completion-card__details">
                    <p className="profile-completion-card__details__title">{texts.title}</p>
                    <p className="profile-completion-card__details__helper">{texts.helper}</p>
            </div>
            <div className="profile-completion-card__action">
                <button className="profile-completion-card__action__btn" onClick={this.toggleModal}>
                    <span className="profile-completion-card__action__btn__text">{texts.btn}</span>
                </button>
            </div>
            { this.state.hasProfileCompleted && <ProfileCompletionCompleted /> }
        </div>)
    }
};

function mapStateToProps(state) {
    return {
        token: state.login.token,
    };
}

const mapDispatchToProps = {
    toggleProfileCompletionModal
}

export default connect(mapStateToProps, mapDispatchToProps)(AllergensCard);