import Axios from 'axios';
import { getBaseUrl, paymentUrls } from '../../../utils/links';
import { getResponseDataWithStatus } from '../../../utils/helper';
const CancelToken = Axios.CancelToken;

export let getTesterProgramRequirementCancelToken = undefined;
export const getTesterProgramRequirement = (token, params = {}) => {
  if (getTesterProgramRequirementCancelToken) getTesterProgramRequirementCancelToken();
  const url = `${getBaseUrl()}${paymentUrls.program}`;

  const options = {
    url,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
    cancelToken: new CancelToken((c) => (getTesterProgramRequirementCancelToken = c)),
  };
  return Axios(options)
    .then((response) => {
      return getResponseDataWithStatus(response);
    })
    .catch(() => false);
};

export let postEnrollPaidTesterCancelToken = undefined;
export const postEnrollPaidTester = (token, params = {}) => {
  if (postEnrollPaidTesterCancelToken) postEnrollPaidTesterCancelToken();
  const url = `${getBaseUrl()}${paymentUrls.enroll}`;
  const options = {
    url,
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
    cancelToken: new CancelToken((c) => (postEnrollPaidTesterCancelToken = c)),
  };
  return Axios(options)
    .then((response) => {
      return getResponseDataWithStatus(response);
    })
    .catch(() => false);
};

// dynamic Button click
export let tasterDynamicAPICallCancelToken = undefined;
export const tasterDynamicAPICall = (token, url, method, params = {}) => {
  if (tasterDynamicAPICallCancelToken) tasterDynamicAPICallCancelToken();
  const apiUrl = `${getBaseUrl()}${url}`;
  const options = {
    url: apiUrl,
    method,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
    cancelToken: new CancelToken((c) => (tasterDynamicAPICallCancelToken = c)),
  };
  return Axios(options)
    .then((response) => {
      return getResponseDataWithStatus(response);
    })
    .catch(() => false);
};
