import React from "react";
import PromptFooter from "./PromptFooter";

const Gender = (props) => {
    const { form: { isUpdating, error, value },
        setForm,
        handleUpdate,
        handleClose,
    } = props;
    const handleSave = () => {
        let data = {
            _method: "PATCH",
            profile: {
                gender: value
            }
        }
        handleUpdate(data);
    };
    const handleFormUpdate = (e) => {
        if (!e) return false;
        const { value } = e.target;
        setForm((preForm) => {
            return { ...preForm, value: value }
        });
    }
    return (
        <>
            <div className="profile-prompt__content">
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '8px' }}>
                    <label className="profile-prompt__content--label">SELECT</label>
                </div>
                <>
                    <div className="input__container">
                        <input type="radio" name="gender" className="input__radio" onChange={handleFormUpdate} value="Male" id="gender-male" />
                        <label htmlFor="gender-male" className="profile-prompt__content--label">Male</label>
                    </div>
                    <div className="input__container">
                        <input type="radio" name="gender" className="input__radio" onChange={handleFormUpdate} value="Female" id="gender-female" />
                        <label htmlFor="gender-female" className="profile-prompt__content--label">Female</label>
                    </div>
                    <div className="input__container">
                        <input type="radio" name="gender" className="input__radio" onChange={handleFormUpdate} value="Other" id="gender-other" />
                        <label htmlFor="gender-other" className="profile-prompt__content--label">Other</label>
                    </div>
                </>

            </div>
            <PromptFooter
                value={value}
                error={error}
                isUpdating={isUpdating}
                handleClose={handleClose}
                handleSave={handleSave}
            />

        </>
    )
}

export default Gender;