import {
  GET_PR_PRODUCT_APPLICANTS_REQUEST,
  GET_PR_PRODUCT_APPLICANTS_SUCCESS,
  GET_PR_PRODUCT_APPLICANTS_FAILURE,
  RESET_PR_PRODUCT_APPLICANTS,
  CREATE_OR_EDIT_PR_PRODUCT_SUCCESS,
  PR_PRODUCT_ROLLBACK_APPLICANT_SUCCESS,
  PR_PRODUCT_REMOVE_APPLICANT_SUCCESS,
  PR_PRODUCT_APPLICANT_DOCUMENT_VERIFICATION_SUCCESS,
} from '../../../actions/collaborations/batches/constants';
import { PR_BEGIN_TASTING_SUCCESS } from '../../../actions/collaborations/prManage/constants';

const INITIAL_STATE = {
  fetching: false,
  data: null,
  error: false,
  page: 1,
  applicants: [],
  hasEnd: false,
  hasFetched: false
};

const handleSuccessFetching = (state, payload) => {
  const data = { ...payload.result };
  return {
    ...state,
    hasFetched: true,
    fetching: false,
    page: payload.page,
    hasEnd: !data.applicants.length,
    data: { ...data }
  }
}
export default function productApplicants(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_PR_PRODUCT_APPLICANTS_REQUEST:
      return {
        ...state,
        fetching: true,
        error: false,
        hasFetched: false,
        page: 1
      };
    case GET_PR_PRODUCT_APPLICANTS_SUCCESS:
      return handleSuccessFetching(state, action.payload)
    case CREATE_OR_EDIT_PR_PRODUCT_SUCCESS:
      return {
        ...state,
        data:
          state.hasFetched && action.payload.isEdit
            ? {
              ...state.data,
              batch:
                state.data.batch.id === action.payload.data.id
                  ? {
                    ...state.data.batch,
                    ...action.payload.data,
                  }
                  : state.batch,
            }
            : state.data,
      };
    case GET_PR_PRODUCT_APPLICANTS_FAILURE:
      return {
        ...state,
        ...(!action.error.__CANCEL__ && {
          error: true,
          errorMsg: action.error,
          hasFetched: true,
        }),
        fetching: false,
      };
    case PR_PRODUCT_ROLLBACK_APPLICANT_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          applicants: state.data.applicants.map((applicant) => {
            if (action.payload.profile_id.includes(applicant.profile.id)) {
              return {
                ...applicant,
                current_status: 0,
              };
            }
            return applicant;
          }),
        },
      };
    case PR_PRODUCT_REMOVE_APPLICANT_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          applicants: state.data.applicants.filter(
            (_) => !action.payload.profile_id.includes(_.profile_id),
          ),
        },
      };
    case PR_BEGIN_TASTING_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          applicants: state.data.applicants.map((applicant) => {
            if (action.payload.profile_id.includes(applicant.profile.id)) {
              return {
                ...applicant,
                current_status: 1,
              };
            }
            return applicant;
          }),
        },
      };
    case PR_PRODUCT_APPLICANT_DOCUMENT_VERIFICATION_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          applicants: state.data.applicants.map((applicant) => {
            if (applicant.profile.id === action.payload.profile_id) {
              return {
                ...applicant,
                bill_verified: action.payload.status,
              };
            }
            return applicant;
          }),
        },
      };
    case RESET_PR_PRODUCT_APPLICANTS:
      return INITIAL_STATE;
    default:
      return state;
  }
}
