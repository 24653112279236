import { GET_PRODUCT_DETAILS_REQUEST,
    GET_PRODUCT_DETAILS_SUCCESS,
    GET_PRODUCT_DETAILS_FAILURE,
    RESET_PRODUCT_DETAILS,
    GET_PRODUCTS_SAMPLE_REQUEST,
    GET_PRODUCTS_SAMPLE_SUCCESS,
    GET_PRODUCTS_SAMPLE_FAILURE } from "../../actions/products/constants";

const INITIAL_STATE = {
    fetching: false,
    hasFetched: false,
    product: null,
    meta: null,
    seoTags: null,
    error: false,
    errorMsg: null
}

export default function details(state = INITIAL_STATE, action) {
    switch(action.type) {
        case GET_PRODUCT_DETAILS_REQUEST:
            return {
                ...state,
                fetching: true,
                hasFetched: false
            }
        case GET_PRODUCT_DETAILS_SUCCESS:
            return {
                ...state,
                fetching: false,
                hasFetched: true,
                product: action.payload.product,
                meta: action.payload.meta,
                seoTags: action.payload.seoTags || null
            }
        case GET_PRODUCT_DETAILS_FAILURE:
            return {
                ...state,
                fetching: false,
                error: true
            };
        case RESET_PRODUCT_DETAILS:
            return INITIAL_STATE
        case GET_PRODUCTS_SAMPLE_SUCCESS:
            return {
                ...state,
                ...(state.hasFetched && {
                    meta : {
                        ...state.meta,
                        is_sample_requested: true
                    }
                })
            }
        default:
            return state;
    }
}