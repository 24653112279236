import {
    GET_SURVEY_FILTERS_FAILURE,
    GET_SURVEY_FILTERS_REQUEST,
    GET_SURVEY_FILTERS_SUCCESS,
    RESET_SURVEY_FILTERS,
} from "../../actions/survey/constants";

const INITIAL_STATE = {
    fetching: false,
    data: null,
    error: false,
};
export default function filters(state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_SURVEY_FILTERS_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case GET_SURVEY_FILTERS_SUCCESS:
            return {
                ...state,
                fetching: false,
                data: action.payload,
                error: false

            };
        case GET_SURVEY_FILTERS_FAILURE:
            return {
                ...state,
                fetching: false,
                data: null,
                error: action.payload,
            };
        case RESET_SURVEY_FILTERS:
            return INITIAL_STATE;
        default:
            return state;
    }
}
