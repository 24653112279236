import { GET_SINGLE_COLLABORATE_REQUEST,
    GET_SINGLE_COLLABORATE_FAILURE,
    GET_SINGLE_COLLABORATE_SUCCESS,
    GET_SIMILAR_COLLABORATE_REQUEST,
    GET_SIMILAR_COLLABORATE_SUCCESS,
    GET_SIMILAR_COLLABORATE_FAILURE
 } from '../.././actions/constants';

 const INITIAL_STATE = {
    allCollaborationData: [],
    fetching: false,
    error: false,
    collaborationData: {
        data: {},
        fetching: false,
        error: false
    },
    similarCollaboration: {
        data: {},
        fetching: false,
        error: false
    }
}

export default function collaborate(state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_SINGLE_COLLABORATE_REQUEST:
            return {
                ...state,
                collaborationData: {
                    ...state.collaborationData,
                    fetching: true
                }
            }
        case GET_SINGLE_COLLABORATE_SUCCESS:
            return {
                ...state,
                collaborationData: {
                    ...state.collaborationData,
                    fetching: false,
                    data: action.payload
                }
            }
        case GET_SINGLE_COLLABORATE_FAILURE:
            return {
                ...state,
                token: null,
                isLoggingIn: false,
                error: action.error
            }
        case GET_SIMILAR_COLLABORATE_REQUEST:
            return {
                ...state,
                similarCollaboration: {
                    ...state.similarCollaboration,
                    fetching: true
                }
            }
        case GET_SIMILAR_COLLABORATE_SUCCESS:
            return {
                ...state,
                similarCollaboration: {
                    ...state.similarCollaboration,
                    fetching: false,
                    data: action.payload
                }
            }
        case GET_SIMILAR_COLLABORATE_FAILURE:
            return {
                ...state,
                token: null,
                isLoggingIn: false,
                error: action.error
            }
        default:
            return state
    }
}
