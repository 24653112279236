import { TOGGLE_SHARE_A_POST_MODAL,
    TOGGLE_EDIT_A_POST_MODAL,
    SHOW_LIKE_MODAL,
    HIDE_LIKE_MODAL,
    GET_LIKES_FOR_A_POST_REQUEST,
    GET_LIKES_FOR_A_POST_FAILURE,
    SHOW_EDIT_A_POST_MODAL,
    HIDE_EDIT_A_POST_MODAL } from '../../actions/common/constants';
import { FOLLOW_USER_SUCCESS } from '../../actions/profile/constants';

const INITIAL_STATE = {
    shareAPost: {
        isVisible: false,
        post: null
    },
    editAPost: {
        isVisible: false,
        post: null,
        postType: null
    },
    likeModal: {
        isVisible: false,
        page: 1,
        profiles: [],
        postData: null,
        fetching: false,
        hasEndReached: false
    },
}

export default function postModals(state = INITIAL_STATE, action) {
    switch (action.type) {
        case TOGGLE_SHARE_A_POST_MODAL:
        case TOGGLE_EDIT_A_POST_MODAL:
            return {
                ...state,
                [action.payload.type]: {
                    post: state[action.payload.type].isVisible
                        ? null
                        : action.payload.post,
                    isVisible: !state[action.payload.type].isVisible
                }
            }
        case SHOW_LIKE_MODAL:
            return {
                ...state,
                likeModal: {
                    isVisible: true,
                    page: state.likeModal.page + 1,
                    profiles: [...state.likeModal.profiles, ...action.payload.profile],
                    count: action.payload.count,
                    hasLiked: state.likeModal.page === 1
                        ? action.payload.hasLiked
                        : state.likeModal.hasLiked, //API_ALERT: doing this because hasLiked in different in second page of api
                    postData: action.payload.postData,
                    fetching: false,
                    hasEndReached: !!!action.payload.profile.length
                }
            }
        case HIDE_LIKE_MODAL:
            return {
                ...state,
                likeModal: INITIAL_STATE.likeModal
            }
        case GET_LIKES_FOR_A_POST_REQUEST:
            return {
                ...state,
                likeModal: {
                    ...state.likeModal,
                    fetching: true
                }
            }
        case GET_LIKES_FOR_A_POST_FAILURE:
            return {
                ...state,
                likeModal: {
                    ...state.likeModal,
                    fetching: false
                }
            }
        case SHOW_EDIT_A_POST_MODAL:
            return {
                ...state,
                editAPost: {
                    isVisible: true,
                    post: action.payload.post,
                    postType: action.payload.postType
                }

            }
        case HIDE_EDIT_A_POST_MODAL:
            return {
                ...state,
                editAPost: INITIAL_STATE.editAPost
            }
        case FOLLOW_USER_SUCCESS:
            return {
                ...state,
                likeModal: {
                    ...state.likeModal,
                    ...(state.likeModal.isVisible
                        && state.likeModal.profiles.length
                        && {
                        profiles: state.likeModal.profiles.map(_ => {
                            if (_.id !== action.payload.profileId) return _;
                            return {
                                ..._,
                                isFollowing: true
                            }
                        })
                    })
                }
            }
        default:
            return state;
    }
}

