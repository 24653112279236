import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { formatDate, decodeString } from "../../../../utils/helper";
import TrashIcon from "../../../../assets/images/feed/options/trash.svg";
import DownVoteIcon from "../../../../assets/images/feed/options/thumb-down.svg";
import EditIcon from "../../../../assets/images/feed/options/edit.svg";
import {
  deleteAPost,
  showEditAPostModal,
  blockProfile,
} from "../../../../actions/feed/common";
import Avatar from "../../../../components/user/Avatar";
import {
  getInternalLink,
  companiesLink,
  homeLink,
  quizLinks,
  surveyLinks,
} from "../../../../utils/internalLinks";
import LoggedoutUserBar from "../../../../components/bars/LoggedoutUserBar";
import {
  collaborationInternalPath,
  typesOfCollaborations,
} from "../../../../utils/collaborationHelper";
import ReportContentModal from "../../../../components/modals/flag/ReportContentModal";
import UserName from "../../../../components/profile/UserName";
import BlockProfileIcon from "../../../../assets/images/feed/options/block-user.svg";
import DiscardPostDraft from "../../../../components/modals/common/DiscardPost";
import ReportFlag from "../../../../assets/images/feed/options/report-flag.svg";
import { toast } from "react-toastify";
import { availableFeedCardPostTypes, genericErrorText } from "../../../../utils/commonVariables";
import showToaster, { TOAST_MESSAGE } from "../../../../components/misc/Toaster";
import get from "lodash/get";
class CompanyBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showOptions: false,
      showReportModal: false,
      showBlockProfileModal: false,
      isRemovePost: false,
      postText: "",
    };
    this.toggleFeedCardOptions = this.toggleFeedCardOptions.bind(this);

    this.toggleShowBlockProfileModal =
      this.toggleShowBlockProfileModal.bind(this);
    this.toggleShowReportModal = this.toggleShowReportModal.bind(this);
    // Creating ref for input bar
    this.optionRef = React.createRef();
    // deleting a post
    this._handleRemovePost = this._handleRemovePost.bind(this);
    this._handlePostRemove = this._handlePostRemove.bind(this);
    // binding editing a post
    this._handleEditAPost = this._handleEditAPost.bind(this);
  }

  _handleUnblockProfile = () => {
    const { token, company, blockProfile } = this.props;
    blockProfile(token, company.id, "company", false).then((res) => {
      const isError = get(res, "errors.status", false)
      if (!!res && !isError) {
        showToaster({ text: TOAST_MESSAGE.UnblockProfile });
      } else {
        const errorText = get(res, "errors.display_message", genericErrorText.general);
        alert(errorText);
      }
    });
  };
  _handleBlockProfile = (event) => {
    const { token, company, blockProfile } = this.props;
    blockProfile(token, company.id, "company", true).then((res) => {
      const isError = get(res, "errors.status", false)
      if (!!res && !isError) {
        showToaster({
          text: TOAST_MESSAGE.BlockedProfile, id: company.id,
          handleAction: this._handleUnblockProfile, actionText: "Unblock"
        });
        this.setState({ showBlockProfileModal: false });
      } else {
        const errorText = get(res, "errors.display_message", genericErrorText.general);
        this.setState({ showBlockProfileModal: false });
        alert(errorText);
      }
    });
  };

  // Function to handle delete a post
  async _handleRemovePost(event) {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    const {
      postId,
      postType,
      isShared,
      sharedId,
      deleteAPost,
      token,
      isDetailsPage,
      history,
      profileId,
      company,
      cardData,
    } = this.props;
    const { collaborate, shared } = cardData;
    if (
      !postId || !postType
    ) {
      return;
    }
    await deleteAPost(
      postType,
      postId,
      token,
      isShared,
      sharedId,
      profileId,
      company.id
    ).then((val) => {
      const isError = get(val, "errors.status", false)
      if (!!val && !isError) {
        this.setState({ isRemovePost: false });
        if (isDetailsPage) {
          history.replace(homeLink);
        }
        if (shared) {
          showToaster({ text: TOAST_MESSAGE.SharedDelete });
          return;
        } else if (
          collaborate &&
          collaborate.collaborate_type === "product-review"
        ) {
          showToaster({ text: TOAST_MESSAGE.delete("tasting") });
          return;
        }
        showToaster({ text: TOAST_MESSAGE.delete(postType) });
      } else {
        alert(genericErrorText.general);
        return;
      }
    });

  }

  _handlePostRemove() {
    const { postType, cardData } = this.props;
    const { collaborate, shared } = cardData;
    let text = ""
    if (collaborate && collaborate.collaborate_type === "product-review"
    ) {
      text = "Tasting"
    } else if (shared) {
      text = "Shared Post"
    } else {
      switch (postType) {
        case "quiz":
          text = "Quiz";
          break;
        case "polling":
          text = "Poll";
          break;
        case "surveys":
          text = "Survey"
          break;
        case "photo":
          text = "Photo";
          break;
        case "shoutout":
          text = "Post";
          break;
        case "collaborate":
          text = "Collaboration";
          break;
        default:
          text = "Post"
          break;
      }
    }
    this.setState({ postText: text, isRemovePost: true });
  }

  //function to handle edit a post
  _handleEditAPost() {
    const { postType, cardData, showEditAPostModal, history, postId } =
      this.props;
    if (postType === "collaborate") {
      history.push(
        collaborationInternalPath.edit(
          cardData[postType].collaborate_type,
          postId
        )
      );
      return;
    }
    if (postType === availableFeedCardPostTypes.survey) {
      history.push(surveyLinks.edit(postId));
      return;
    }
    if (postType === availableFeedCardPostTypes.quiz) {
      history.push(quizLinks.edit(postId));
      return;
    }
    showEditAPostModal(cardData, postType);
  }

  toggleShowBlockProfileModal() {
    this.setState({
      showBlockProfileModal: !this.state.showBlockProfileModal,
    });
  }

  toggleFeedCardOptions() {
    if (this.state.showOptions) {
      document.removeEventListener("click", this.toggleFeedCardOptions);
    } else {
      document.addEventListener("click", this.toggleFeedCardOptions);
    }
    this.setState({
      showOptions: !this.state.showOptions,
    });
  }

  toggleShowReportModal() {
    this.setState({
      showReportModal: !this.state.showReportModal,
    });
  }

  render() {
    const {
      company,
      createdAt,
      showOptionsIcon,
      postId,
      cardData,
      hideMetaBar,
      isAd,
      postType,
      showFlagOptions,
      isPublic,
      publicClickAction,
      isOwner,
    } = this.props,
      directLinkToPost = getInternalLink(
        postType,
        postId,
        null,
        true,
        cardData &&
          cardData.collaborate &&
          cardData.collaborate.collaborate_type
          ? cardData.collaborate.collaborate_type
          : null
      ),
      linkToCompany = companiesLink.view(company.id),
      companyImage = company.logo_meta
        ? JSON.parse(company.logo_meta) &&
          JSON.parse(company.logo_meta).original_photo
          ? JSON.parse(company.logo_meta).original_photo
          : ""
        : "",
      isEdited =
        cardData &&
          cardData[postType] &&
          cardData[postType].created_at &&
          cardData[postType].updated_at
          ? cardData[postType].created_at !== cardData[postType].updated_at
          : false;
    const isEditedDetail =
      cardData && cardData.created_at && cardData.updated_at
        ? cardData.created_at !== cardData.updated_at
        : false;
    if (isPublic)
      return (
        <LoggedoutUserBar
          name={decodeString(company.name)}
          createdAt={formatDate.post(createdAt)}
          isEdited={isEdited}
          isCompany={true}
          isVerified={!!company.verified}
          publicClickAction={publicClickAction}
          image={companyImage}
        />
      );
    return (
      <>
        <div className="user-block">
          <div className="user-block__details" to={"/feed"}>
            <Link to={linkToCompany}>
              <Avatar
                isCompany={true}
                className="user-block__image company"
                alt={decodeString(company.name)}
                src={companyImage}
              />
            </Link>
            <div className="user-block__info">
              <Link to={linkToCompany} className="user-block__info__name">
                <UserName name={company.name} isVerified={!!company.verified} />
              </Link>
              {(createdAt || isAd || isEdited) && (
                <p className="user-block__info__secondary">
                  {isAd && <span>Promoted</span>}
                  {(isEdited || isEditedDetail) && !isAd && (
                    <>
                      <span>Edited</span>
                    </>
                  )}
                  {(isEditedDetail || isEdited || isAd) && createdAt && (
                    <span className="user-block__info__secondary--divider">
                      &bull;
                    </span>
                  )}
                  <Link
                    to={{ pathname: directLinkToPost, state: { cardData } }}
                    className="user-block__info__secondary user-block__info__secondary--link"
                  >
                    {formatDate.post(createdAt)}
                  </Link>
                </p>
              )}
            </div>
          </div>
          {showOptionsIcon ? (
            <div className="user-block__action">
              <button
                onClick={this.toggleFeedCardOptions}
                className={`user-block__action__btn
                            ${this.state.showOptions
                    ? "user-block__action__btn--active"
                    : ""
                  }`}
              >
                <span className="user-block__action__btn__text">•••</span>
              </button>
              {this.state.showOptions && (
                <div className="user-block__action__container">
                  <ul className="user-block__action__container__list">
                    {this.props.children}
                    {
                      <li
                        onClick={this._handleEditAPost}
                        className="user-block__action__container__list__item"
                      >
                        <button
                          tabIndex="0"
                          className="user-block__action__container__list__item__btn"
                        >
                          <img
                            className="user-block__action__container__list__item__btn__icon"
                            src={EditIcon}
                            alt="edit post"
                          />
                          <span className="user-block__action__container__list__item__btn__text">
                            Edit Post
                          </span>
                        </button>
                      </li>
                    }
                    <li className="user-block__action__container__list__item">
                      <button
                        onClick={this._handlePostRemove}
                        tabIndex="0"
                        className="user-block__action__container__list__item__btn"
                      >
                        <img
                          className="user-block__action__container__list__item__btn__icon"
                          src={TrashIcon}
                          alt="remove post"
                        />
                        <span className="user-block__action__container__list__item__btn__text user-block__action__container__list__item__btn__text--red">
                          Remove
                        </span>
                      </button>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          ) : hideMetaBar || isAd || !showFlagOptions ? null : (
            <div className="user-block__action">
              <button
                onClick={this.toggleFeedCardOptions}
                className={`user-block__action__btn
                            ${this.state.showOptions
                    ? "user-block__action__btn--active"
                    : ""
                  }`}
              >
                <span className="user-block__action__btn__text">•••</span>
              </button>
              {this.state.showOptions && (
                <div className="user-block__action__container">
                  <ul
                    ref={this.optionsRef}
                    className="user-block__action__container__list"
                  >
                    <li className="user-block__action__container__list__item">
                      <button
                        onClick={this.toggleShowReportModal}
                        tabIndex="0"
                        className="user-block__action__container__list__item__btn"
                      >
                        <img
                          className="user-block__action__container__list__item__btn__icon"
                          src={ReportFlag}
                          alt="report post"
                        />
                        <span className="user-block__action__container__list__item__btn__text">
                          Report Content
                        </span>
                      </button>
                    </li>
                    <li className="user-block__action__container__list__item">
                      <button
                        onClick={this.toggleShowBlockProfileModal}
                        tabIndex="0"
                        className="user-block__action__container__list__item__btn"
                      >
                        <img
                          className="user-block__action__container__list__item__btn__icon"
                          src={BlockProfileIcon}
                          alt="report post"
                        />
                        <span className="user-block__action__container__list__item__btn__text">
                          Block Profile
                        </span>
                      </button>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          )}
        </div>
        {this.state.showReportModal && (
          <ReportContentModal
            {...this.props}
            isOpen={this.state.showReportsModal}
            type="content"
            handleClose={this.toggleShowReportModal}
          />
        )}
        {this.state.showBlockProfileModal && (
          <DiscardPostDraft
            modalTitle={`Block ${company.name}?`}
            text={`${company.name} will no longer be able to follow or message you, and you will not see
          notifications from ${company.name}`}
            handleClose={() => this.setState({ showBlockProfileModal: false })}
            handleAction={this._handleBlockProfile}
            actionText="Block"
          />
        )}
        {this.state.isRemovePost && (
          <DiscardPostDraft
            modalTitle={`Delete ${this.state.postText}?`}
            text={`This action cannot be undone. Are you sure you want to delete this ${this.state.postText.toLowerCase()}?`}
            handleClose={() => this.setState({ isRemovePost: false })}
            handleAction={this._handleRemovePost}
            actionText="Delete"
          />
        )}
      </>
    );
  }
}
CompanyBar.propTypes = {
  company: PropTypes.object.isRequired,
  isShared: PropTypes.bool,
  showOptionsIcon: PropTypes.bool,
  postId: PropTypes.any,
  postType: PropTypes.string,
  sharedId: PropTypes.number,
  profileId: PropTypes.number,
  isOwner: PropTypes.bool,
  isAd: PropTypes.bool,
  isDetailsPage: PropTypes.bool,
  isPublic: PropTypes.bool,
  publicClickAction: PropTypes.func,
  hideMetaBar: PropTypes.bool,
  showFlagOptions: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    token: state.login.token,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    deleteAPost,
    showEditAPostModal,
    blockProfile,
  })(CompanyBar)
);
