
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { miscLinks, onboardingLink } from '../../../utils/internalLinks';
import { otpLength, } from '../../../utils/commonVariables';
import CloseIcon from '../../../assets/images/login/close.svg';
import { emailOtpVerification, emailVerification } from '../../../actions/login/register';
import { forgotChangeEmail, forgotChangeOtp } from '../../../actions/settings/changePassword';
import {
  getTwoDigits, handleAuthState,
  isValidNumber,
} from "../../../utils/helper";
import { useEffect } from 'react';
import get from "lodash/get";
import { format, addSeconds } from 'date-fns';

function SignupEmailOtpForm(props) {
  const { type = "", handleSubmit, email, phone, countryCode, history, token, toggleEmailForm } = props;
  const [emailOtp, setEmailOtp] = useState(''),
    [emailOtpError, setEmailOtpError] = useState(''),
    [canResendOtp, setCanResendOtp] = useState(false),
    [otpSeconds, setOtpSeconds] = useState(60);

  function resendOtpMail() {
    const { email, token } = props;
    if (type === "forgot") {
      let dataToSend = {
        ...(!!email && { "email": email }),
        ...(!!phone && {
          "country_code": countryCode,
          "phone": phone
        }),
      }
      forgotChangeEmail(dataToSend)
        .then(val => {
          if (!!val && !!val.data) {
            setOtpSeconds(60)
            setCanResendOtp(false)
            setEmailOtpError("");
            setEmailOtp("");
          } else {
            const { status, display_message } = val.errors;
            const errMsg = display_message ? display_message : "Something Went Wrong!"
            if (!!status) {
              setEmailOtpError(errMsg);
            }
          }
        }).catch((err) => {
          setEmailOtpError("Something Went Wrong!");
        })
    } else {
      let dataToSend = {
        email: email,
        platform: "web"
      };
      setEmailOtpError("")
      setEmailOtp("")
      emailVerification(dataToSend, token).then((res) => {
        if (res && !get(res, "errors", []).length) {
          setOtpSeconds(60);
          setCanResendOtp(false);
          setEmailOtpError("");
          setEmailOtp("");
        } else {
          const errorMsg = get(res, "errors");
          if (errorMsg) {
            setEmailOtpError(errorMsg);
            setCanResendOtp(false)
          } else {
            setEmailOtpError("")
          }
        }
      });
    }
  }
  function OtpVerify() {
    if (type === "forgot") {
      let dataToSend = {
        ...(!!email && { "email": email, "otp": emailOtp }),
        ...(!!phone && {
          "country_code": countryCode,
          "phone": phone,
          "otp": emailOtp
        }),
      }
      forgotChangeOtp(dataToSend)
        .then(val => {
          if (!!val && !!val.data) {
            handleSubmit(val.data.temp_token);
          } else {
            const { status, display_message } = val.errors;
            const errMsg = display_message ? display_message : "Something Went Wrong!"
            if (!!status) {
              setEmailOtpError(errMsg);
            }
          }
        }).catch((err) => {
          setEmailOtpError("Something Went Wrong!");
        })

    } else {
      const data = {
        email,
        otp: emailOtp
      }
      const { emailOtpVerification } = props;
      emailOtpVerification(data, token, "login").then((val) => {
        if (val.data) {
          handleAuthState.add(token);
          history.replace({
            pathname: onboardingLink,
            state: {
              publicPageLink: props.publicPageLink || "",
            },
          });
        } else {
          setEmailOtpError(val.errors);
        }
      })
    }
  }

  const handleChangeOtp = (e) => {
    setEmailOtp(isValidNumber(e.target.value.slice(0, 6)));
    setEmailOtpError("")
  }
  useEffect(() => {
    document.body.style.overflow = "hidden"
    const interval = setInterval(() => {
      if (otpSeconds === 0) {
        clearInterval(interval);
        setCanResendOtp(true);
        setEmailOtpError("");
        return null
      } else {
        setOtpSeconds(otpSeconds - 1);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
      document.body.style.overflow = "auto"
    };
  }, [otpSeconds]);
  const isOtpLength = emailOtp.length < otpLength;
  return (
    <div className="login-form">
      <div className="login-form__backdrop">
        <Link to={miscLinks.home} className="login-form__cancel">
          <img src={CloseIcon} alt="go to homepage" />
        </Link>
        <div className="login-form__container" style={{ padding: "48px 27px" }}>
          <div className="login-form__header" style={{ marginBottom: "12px" }}>
            <p className="login-form__title">Enter OTP</p>
          </div>
          <p className="login-form__otp-form--confirm_title">
            {type === "forgot" ? `Confirm that this ${!!props.email ? "Email" : ""} ${!!props.phone ? "phone number" : ""} belongs to you: Enter the OTP sent to` : "Please confirm if this email belongs to you by entering the OTP sent to"}
            {
              type === "forgot" ?
                <>
                  {!!props.email && <span className="black"> {props.email}</span>}
                  {!!props.phone && <span className="black"> {props.phone}</span>}
                </>
                : <span className="black"> {props.email}</span>
            } </p>
          <div className="login-form__form__group otp-screen" style={{ padding: "12px 75px" }}>
            <input
              type="text"
              placeholder="OTP"
              autoFocus={true}
              value={emailOtp}
              maxLength={otpLength}
              onChange={(e) => { handleChangeOtp(e) }}
              className={`login-form__form__input1 ${!!emailOtpError ? "red" : ""}`}
            />
            {emailOtpError && <p className="error-text">{emailOtpError}</p>}
            {(!emailOtpError || !canResendOtp) && <p className="login-form__otp-form--time">
              {format(new Date(1970, 0, 1, 0, 0, otpSeconds), 'mm:ss')}
            </p>}
          </div>
          <p className="login-form__otp-form--time">Didn’t receive the code? <button
            className="login-form__otp-form--resend_button" style={{
              color: canResendOtp ? "rgba(73, 144, 226, 1)" : "rgba(146, 188, 238, 1)"
            }} disabled={!canResendOtp} onClick={resendOtpMail}>Resend now</button></p>
          {type === "forgot" && <hr className="hr" />}
          <button className={`login-form__otp-form--continue_button ${!!isOtpLength ? "disabled" : ""}`} disabled={!!isOtpLength} onClick={OtpVerify}>{type !== "forgot" ? "Continue" : "Submit"}</button>
          {
            type !== "forgot" && <div className="login-form__otp-form--update_email_div">
              <span className="login-form__otp-form--update_email"
                onClick={toggleEmailForm}>Update email</span>
            </div>
          }
        </div>
      </div>
    </div>
  )
}

SignupEmailOtpForm.propTypes = {
  token: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  toggleEmailForm: PropTypes.func,
  history: PropTypes.object,
  type: PropTypes.string
};

export default connect(null, { emailOtpVerification })(SignupEmailOtpForm);
