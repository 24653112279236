import { UPDATE_A_POLL_REQUEST,
    UPDATE_A_POLL_SUCCESS,
    UPDATE_A_POLL_FAILURE } from './constants';
import { onRequest, getResponseData, onSuccess, onError } from '../../utils/helper';
import { getBaseUrl, pollUrls } from '../../utils/links';
import Axios from 'axios';

// function to update polls
export const updateAPoll = (pollId,
    data,
    token) => {
    return dispatch => {
        dispatch(onRequest(UPDATE_A_POLL_REQUEST));
        let url = `${getBaseUrl()}${pollUrls.updatePoll(pollId)}`,
            options = {
                url,
                method: 'PATCH',
                data,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };
        return Axios(options)
            .then(response => {
                let successData = getResponseData(response);
                if (successData) {
                    dispatch(onSuccess(UPDATE_A_POLL_SUCCESS, {
                        postType: 'polling',
                        data: successData
                    }));
                    return true;
                } else {
                    dispatch(onError(UPDATE_A_POLL_FAILURE, response.data.errors));
                    return false;
                }
            })
            .catch(error => {
                dispatch(onError(UPDATE_A_POLL_FAILURE, error));
                return false;
            })
    }
}

// action to delete a post option
export const deleteOption = (pollId,
    data,
    token) => {
    return dispatch => {
        let url = `${getBaseUrl()}${pollUrls.deleteAnOption(pollId)}`,
            options = {
                url,
                method: 'DELETE',
                data,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };
        return Axios(options)
            .then(response => {
                return response.data
            })
            .catch(error => {
                return false;
            })
    }
}