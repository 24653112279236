import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateUser } from '../../../actions/profile/update';
import { getEatingHabits } from '../../../actions/profile/getEatingHabits';
import EatingHabits from '../../../assets/images/profile/completion/eating-habits.svg';

const texts = {
    title: 'What are your eating habits?',
    helper: 'Here are some of the classifications based on the dietary choices. Select the one that resembles you the most.',
}

class EatingHabitsModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            foodie_type_id: props.profile.foodie_type_id
        }
        // binding function to see eating habits
        this.handleEatingHabitsSelect = this.handleEatingHabitsSelect.bind(this);
        // binding function to save profile
        this.handleProfileSave = this.handleProfileSave.bind(this);
    }

    componentDidMount() {
        const { token,
            eatingHabits,
            getEatingHabits } = this.props;
        if (!eatingHabits.all.length) {
            getEatingHabits(token);
        }
    }

    handleEatingHabitsSelect(e) {
        let value = parseInt(e.target.value);
        this.setState({
            foodie_type_id: value
        })
    }

    handleProfileSave() {
        const { foodie_type_id } = this.state;
        if (!foodie_type_id) return;
        const { token,
            updateUser,
            profile,
            onClose } = this.props,
            dataToSend = {
                '_method': 'PATCH',
                profile: {
                    foodie_type_id: foodie_type_id
                }
            };
        updateUser(profile.id,
            dataToSend,
            token)
            .then(val => {
                if (val) {
                    onClose();
                }
            })
    }

    render() {
        const { eatingHabits,
            onClose } = this.props,
            { foodie_type_id } = this.state;
        return (<div className="profile-completion-modal__content">
            <div className="profile-completion-modal__header">
                <img src={EatingHabits} alt="eating-habits" className="profile-completion-modal__header__icon" />
                <p className="profile-completion-modal__header__title">{texts.title}</p>
                <p className="profile-completion-modal__header__helper">{texts.helper}</p>
            </div>
            <div className="profile-completion-modal__body">
                {
                    eatingHabits
                        && eatingHabits.fetching
                        ? (null)
                        : eatingHabits
                        && eatingHabits.all
                        && !!eatingHabits.all.length
                        && (<ul className="profile-completion-modal__pill-input-list">
                            {
                                eatingHabits.all.map((eatingHabit, eatingHabitIndex) => {
                                    return (
                                        <li className="profile-completion-modal__pill-input-list__item" key={`eating-habit-${eatingHabitIndex}-key`}>
                                            <label className="profile-pill-label" htmlFor={`eating-habit-${eatingHabitIndex}`}>
                                                <input type="radio"
                                                    onChange={this.handleEatingHabitsSelect}
                                                    id={`eating-habit-${eatingHabitIndex}`}
                                                    name="foodie_type_id"
                                                    className="profile-pill-label__input"
                                                    checked={(this.state.foodie_type_id === eatingHabit.id)}
                                                    value={`${eatingHabit.id}`} />
                                                <div className="profile-pill-label__eating-list">
                                                    <p className="profile-pill-label__list-text">{eatingHabit.name}</p>
                                                    <p className="profile-pill-label__list-text__helper">{eatingHabit.description}</p>
                                                </div>
                                            </label>
                                        </li>
                                    )
                                })
                            }
                        </ul>)
                }
            </div>
            <div className="profile-completion-btn__btn">
                <button className="profile-completion-btn__btn__cancel-btn"
                    onClick={onClose}>
                    <span>Cancel</span>
                </button>
                <button className={`profile-completion-btn__btn__submit-btn ${!foodie_type_id ? "disabled" : ""}`}
                    onClick={this.handleProfileSave} disabled={!foodie_type_id}>
                    <span>Done</span>
                </button>
            </div>
        </div>)
    }
}

function mapStateToProps(state) {
    return {
        token: state.login.token,
        eatingHabits: state.profileReducer.eatingHabits,
        profile: state.user.user.profile
    }
}

const mapDispatchToProps = {
    updateUser,
    getEatingHabits
}

EatingHabitsModal.propTypes = {
    onClose: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(EatingHabitsModal);