import { combineReducers } from 'redux';
import quickLinks from './quickLinks';
import rightSideData from './rightSideData';
import landingPageData from './landingPageData';
import fromFeed from './fromFeed';
import topBar from './topBar';

const landingPageReducer = combineReducers({
  quickLinks,
  rightSideData,
  landingPageData,
  fromFeed,
  topBar,
});

export default landingPageReducer;
