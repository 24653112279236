import { GET_PROFILE_BY_ID_REQUEST,
    GET_PROFILE_BY_ID_SUCCESS,
    GET_PROFILE_BY_ID_FAILURE,
    PROFILE_WORK_EXP_CREATE_OR_UPDATE_SUCCESS,
    PROFILE_EDUCATION_CREATE_OR_UPDATE_SUCCESS,
    PROFILE_TRAINING_CREATE_OR_UPDATE_SUCCESS,
    PROFILE_UPDATE_SUCCESS,
    RESET_PROFILE_PAGE,
    UNFOLLOW_USER_SUCCESS,
    FOLLOW_USER_SUCCESS,
    UPDATE_ALLERGENS_SUCCESS,
    DELETE_WORK_EXP_SUCCESS,
    DELETE_TRAINING_SUCCESS,
    DELETE_EDUCATION_SUCCESS,PROFILE_IMAGE_REMOVE_SUCSESS,COVER_IMAGE_REMOVE_SUCSESS } from '../../actions/profile/constants';
import { FOLLOW_COMPANY_SUCCESS,
    UNFOLLOW_COMPANY_SUCCESS } from '../../actions/company/constants';
import { decodeString } from '../../utils/helper';
import { DELETE_A_POST_SUCCESS, DONATION_PREFERENCE_SUCCESS, UPLOAD_DOCUMENTS_SUCCESS } from '../../actions/common/constants';

const INITIAL_STATE = {
    fetching: false,
    error: false,
    errorMsg: null,
    profile: {},
    hasFetched: false
}

const canUpdateProfile = (state) => state.hasFetched && !!state.profile.profile; // checking if profile has been fetched

export default function view(state = INITIAL_STATE, action)  {
    switch (action.type) {
        case GET_PROFILE_BY_ID_REQUEST:
            return {
                ...state,
                fetching: true,
            }
        case GET_PROFILE_BY_ID_SUCCESS:
            return {
                ...state,
                profile: {
                    ...action.payload,
                    ...(action.payload.profile && {
                        ...action.payload.profile,
                        ...(action.payload.profile.about && {
                            about: decodeString(action.payload.profile.about)
                        }),
                        ...(action.payload.profile.tagline && {
                            tagline: decodeString(action.payload.profile.tagline)
                        })
                    })
                },
                fetching: false,
                hasFetched: true
            }
        case  GET_PROFILE_BY_ID_FAILURE:
            return {
                ...state,
                fetching: false,
                error: true,
                errorMsg: action.error
            }
        case PROFILE_UPDATE_SUCCESS:
            return {
                ...state,
                profile: canUpdateProfile(state)
                    ? {
                        ...state.profile,
                        profile: {
                            ...state.profile.profile,
                            ...(action.payload
                                && {
                                    ...action.payload,
                                    tagline: decodeString(action.payload.tagline),
                                    about: decodeString(action.payload.about)
                                })
                        }
                    }
                    : state.profile
            }
        case PROFILE_WORK_EXP_CREATE_OR_UPDATE_SUCCESS:
        case PROFILE_EDUCATION_CREATE_OR_UPDATE_SUCCESS:
        case PROFILE_TRAINING_CREATE_OR_UPDATE_SUCCESS:
            return {
                ...state,
                profile: canUpdateProfile(state)
                    ? {
                        ...state.profile,
                        profile: {
                            ...state.profile.profile,
                            [action.payload.type]: action.payload.obj
                                ? action.payload.isEdit
                                ? state.profile.profile[action.payload.type].map(_ => {
                                    if (_.id !== action.payload.obj.id) return _;
                                    return {
                                        ..._,
                                        ...action.payload.obj
                                    }
                                })
                                : [action.payload.obj, ...state.profile.profile[action.payload.type]]
                                : state.profile.profile[action.payload.type]
                        }
                    }
                    : state.profile
            }
        case DELETE_WORK_EXP_SUCCESS:
        case DELETE_TRAINING_SUCCESS:
        case DELETE_EDUCATION_SUCCESS:
            return {
                ...state,
                profile: canUpdateProfile(state)
                    ? {
                        ...state.profile,
                        profile: {
                            ...state.profile.profile,
                            [action.payload.type]: action.payload.id
                                ? state.profile.profile[action.payload.type].filter(_ => _.id !== action.payload.id)
                                : state.profile.profile[action.payload.type]
                        }
                    }
                    : state.profile
            }
        case FOLLOW_USER_SUCCESS:
            return {
                ...state,
                profile: canUpdateProfile(state)
                    ? {
                        ...state.profile,
                        ...(state.profile.profile && {
                            profile: {
                                ...state.profile.profile,
                                isFollowing: action.payload.profileId === state.profile.profile.id,
                                ...(action.payload.isCurrentUser && {
                                    followingProfiles: {
                                        ...state.profile.profile.followingProfiles,
                                        count: state.profile.profile.followingProfiles.count
                                            ? state.profile.profile.followingProfiles.count + 1
                                            : 1
                                    }
                                })
                            }
                        })
                    }
                    : state.profile
            }
        case FOLLOW_COMPANY_SUCCESS:
            return {
                ...state,
                profile: canUpdateProfile(state) && action.payload.isCurrentUser
                    ? {
                        ...state.profile,
                        ...(state.profile.profile && {
                            profile: {
                                ...state.profile.profile,
                                followingProfiles: {
                                    ...state.profile.profile.followingProfiles,
                                    count: state.profile.profile.followingProfiles.count
                                        ? state.profile.profile.followingProfiles.count + 1
                                        : 1
                                }
                            }
                        })
                    }
                    : state.profile

            }
        case UNFOLLOW_COMPANY_SUCCESS:
            return {
                ...state,
                profile: canUpdateProfile(state) && action.payload.isCurrentUser
                    ? {
                        ...state.profile,
                        ...(state.profile.profile && {
                            profile: {
                                ...state.profile.profile,
                                followingProfiles: {
                                    ...state.profile.profile.followingProfiles,
                                    count: state.profile.profile.followingProfiles.count
                                        ? state.profile.profile.followingProfiles.count - 1 < 0
                                            ? 0
                                            : state.profile.profile.followingProfiles.count - 1
                                        : 0
                                }
                            }
                        })
                    }
                    : state.profile
            }
        case UNFOLLOW_USER_SUCCESS:
            return {
                ...state,
                profile: canUpdateProfile(state)
                    ? {
                        ...state.profile,
                        profile: {
                            ...state.profile.profile,
                            isFollowing: action.payload.profileId === state.profile.profile.id ? false : state.profile.profile.isFollowing,
                            ...(action.payload.isCurrentUser && {
                                followingProfiles: {
                                    ...state.profile.profile.followingProfiles,
                                    count: state.profile.profile.followingProfiles.count
                                        ? (state.profile.profile.followingProfiles.count - 1 < 0)
                                            ? 0
                                            : state.profile.profile.followingProfiles.count -1
                                        : 0
                                }
                            })
                        }
                    }
                    : state.profile
            }
        case UPDATE_ALLERGENS_SUCCESS:
                return {
                    ...state,
                    profile: canUpdateProfile(state)
                        ? {
                            ...state.profile,
                            profile: {
                                ...state.profile.profile,
                                allergens: action.payload
                            }
                        }
                        : state.profile
                }
        case DONATION_PREFERENCE_SUCCESS: 
            return {
                ...state,
                profile: canUpdateProfile(state)
                    ? {
                        ...state.profile,
                        profile: {
                            ...state.profile.profile,
                            is_donation: action.payload.is_donation,
                            donation_organisation: action.payload.donation_organisation
                        }
                    }
                    : state.profile
            }
        case UPLOAD_DOCUMENTS_SUCCESS:
                return {
                    ...state,
                    profile:{
                        ...state.profile,
                        profile: {
                            ...state.profile.profile,
                            document_meta: {
                                images: action.payload.images
                            }
                        }
                    }
                }
        case DELETE_A_POST_SUCCESS:
            return {
                ...state,
                profile: canUpdateProfile(state)
                    ? {
                        ...state.profile,
                        profile: {
                            ...state.profile.profile,
                            totalPostCount: state.profile.profile.totalPostCount - 1 < 0
                                ? 0
                                : state.profile.profile.totalPostCount - 1,
                            imagePostCount: action.payload.postType === 'photo'
                                ? (state.profile.profile.imagePostCount - 1 < 0)
                                ? 0
                                : state.profile.profile.imagePostCount - 1
                                : state.profile.profile.imagePostCount
                        }
                    }
                    : state.profile
            }
            case PROFILE_IMAGE_REMOVE_SUCSESS :
                return {
                    ...state,
                    profile:{
                        ...state.profile,
                        profile:{
                            ...state.profile.profile,
                            image_meta:null
                        }
                    }
                }

                case COVER_IMAGE_REMOVE_SUCSESS :
                    return {
                        ...state,profile:{
                            ...state.profile,profile:{
                                ...state.profile.profile,
                                hero_image_meta:null,
                                heroImageUrl:null
                            }
                        }
                       
            
        }
                  

        case RESET_PROFILE_PAGE:
            return INITIAL_STATE;
        default:
            return state;
    }
}