import { PROFILE_UPDATE_REQUEST,
    PROFILE_UPDATE_FAILURE,
    PROFILE_UPDATE_SUCCESS
 } from '../../actions/profile/constants';

const INITIAL_STATE = {
    updating: false,
    error: false,
    errorMsg: null
}

export default function update(state = INITIAL_STATE, action) {
    switch(action.type) {
        case PROFILE_UPDATE_REQUEST:
            return {
               ...INITIAL_STATE,
               updating: true
            }
        case PROFILE_UPDATE_FAILURE:
            return {
                ...state,
                updating: false,
                error: true,
                errorMsg: action.error
            }
        case PROFILE_UPDATE_SUCCESS:
            return {
                ...state,
                updating: false
            }
        default:
            return state;
    }
}