import { CHANGE_PASSWORD_REQUEST,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAILURE} from './constants';
import { onError,
onRequest,
onSuccess,
getResponseData } from '../../utils/helper';
import { getBaseUrl,
    SettingsUrl} from '../../utils/links';
import Axios from 'axios';
import { LOGIN_USER_SUCCESS } from '../login/constants';
const CancelToken = Axios.CancelToken;

export const changePassword = (data,
    token) => {
        let url = `${getBaseUrl()}${SettingsUrl.changePassword}`,
            options = {
                url,
                data,
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
        return Axios(options)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                return false;
            })
}

export const forgotChangeEmail = (data) => {
        let url = `${getBaseUrl()}${SettingsUrl.forgotChangeEmail}`,
            options = {
                url,
                data,
                method: 'POST',
            }
        return Axios(options)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                return false;
            })
}

export const forgotChangeOtp = (data) => {
    let url = `${getBaseUrl()}${SettingsUrl.forgotChangeOtp}`,
        options = {
            url,
            data,
            method: 'POST',
        }
    return Axios(options)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            return false;
        })
}

export const forgotChangePassword = (data) => {
    return (dispatch)=>{
        let url = `${getBaseUrl()}${SettingsUrl.forgotChangeReset}`,
        options = {
            url,
            data,
            method: 'POST',
        }
    return Axios(options)
        .then(response => {
            dispatch(onSuccess(LOGIN_USER_SUCCESS, { token: response.data.data.token }));
            return response.data;
        })
        .catch(error => {
            return false;
        })
    }
   
}

export const changePasswordVerifyOtp = (data,
    token) => {
        let url = `${getBaseUrl()}${SettingsUrl.changePasswordVerifyOtp}`,
            options = {
                url,
                data,
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
        return Axios(options)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                return false;
            })
}

// method to create passwordd email
export let createPasswordCancelToken = undefined;
export const createPassword = (token = null, data) => {
    if (createPasswordCancelToken) createPasswordCancelToken();
    let url = `${getBaseUrl()}${SettingsUrl.createPassword}`,
        options = {
            url,
            ...(token && {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            }),
            data,
            method: 'POST',
            cancelToken: new CancelToken((c) => createPasswordCancelToken = c)
        };
    return Axios(options)
        .then(response => {
            let successData = getResponseData(response);
            return successData;
        })
        .catch(_ => false);
}