/* eslint-disable no-undef */
import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import Cropper from "react-easy-crop";
import Modal from "../../components/modals/common/Modal";
import CropFileSelectIcon from "../../assets/images/feed/create/crop-file-select.svg";
import CropIcon from "../../assets/images/feed/create/crop-icon.svg";
import RotateIcon from "../../assets/images/feed/create/rotate-icon.svg";
import { getCroppedImg, previewWithAspectRatio } from "./utils";
import { QUIZ_ACCENT_COLOR } from "../../utils/quizHelper";

const ImageCropperModal = (props) => {
  const {
    modalTitle,
    closeModal,
    image,
    aspectRatio,
    handleImageChange,
    handleUploadImage,
    buttonColorFor,
    containerClassName,
  } = props;

  const [imgSrc, setImgSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  useEffect(() => {
    onFileChange(image);
  }, [image]);

  const onFileChange = async (file) => {
    if (!file) return;
    if (!!file) {
      previewWithAspectRatio(file, aspectRatio, (previewImage) => {
        setImgSrc(previewImage);
        setCrop({ x: 0, y: 0 }); // resetting cropper values when image changes
        setRotation(0);
        setZoom(1);
      });
    }
  };

  const handleCropButton = useCallback(async () => {
    const previewedImageWhiteFilled = await new Promise((resolve) => {
      previewWithAspectRatio(image, aspectRatio, (previewImage) => {
        resolve(previewImage);
      }, "#fff");
    });

    const croppedImage = await getCroppedImg(
      previewedImageWhiteFilled,
      croppedAreaPixels,
      rotation
    );
    handleUploadImage(croppedImage);
  }, [image, aspectRatio, croppedAreaPixels, rotation]);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleRotate = () => {
    setRotation((prevVal) => {
      if (prevVal === 270) {
        return 0;
      }
      return prevVal + 90;
    });
  };

  const cropperHeight =
    document.querySelector(".default-modal__container") &&
      containerClassName === "cropperOverlap"
      ? document
        .querySelector(".default-modal__container")
        .getBoundingClientRect().height - 133
      : null;

  const cropperStyle = {
    cropAreaStyle: {
      border: '2px dashed #fff'
    }
  };

  return (
    <Modal
      showHeader
      containerClassName={containerClassName}
      className="post-bar__cropmodal cropperOverlap"
      handleClose={closeModal}
      title={modalTitle}
    >
      <div
        className="post-bar__cropmodal__content__new"
        style={{ height: cropperHeight }}
      >
        {imgSrc && (
          <Cropper
            image={imgSrc}
            crop={crop}
            rotation={rotation}
            zoom={zoom}
            aspect={aspectRatio}
            onCropChange={setCrop}
            onRotationChange={setRotation}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            showGrid={false}
            style={cropperStyle}
          />
        )}
      </div>

      <div className="post-bar__cropmodal__footer">
        <label className="cursor-pointer post-bar__cropmodal__footer__file">
          <img
            src={CropFileSelectIcon}
            alt="select file"
            className="post-bar__cropmodal__footer__file__icon"
          />
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            className="post-bar__cropmodal__footer__file__input"
          />
        </label>

        <img
          src={RotateIcon}
          alt="rotate"
          className="cursor-pointer post-bar__cropmodal__footer__rotatebtn"
          onClick={handleRotate}
        />

        <button
          className="cursor-pointer post-bar__cropmodal__footer__cropbtn"
          onClick={handleCropButton}
          style={{ background: buttonColorFor == "quiz" ? "#F47816" : "" }}
        >
          <img
            src={CropIcon}
            alt="crop"
            className="post-bar__cropmodal__footer__cropbtn__icon"
          />
          <span className="post-bar__cropmodal__footer__cropbtn__text">
            Crop
          </span>
        </button>
      </div>
    </Modal>
  );
};

ImageCropperModal.propTypes = {
  modalTitle: PropTypes.string,
  aspectRatio: PropTypes.number,
  aspectRatioText: PropTypes.string,
  image: PropTypes.string,
  closeModal: PropTypes.func,
  handleImageChange: PropTypes.func,
  handleUploadImage: PropTypes.func,
  buttonColorFor: PropTypes.string,
  containerClassName: PropTypes.string,
};

export default ImageCropperModal;
