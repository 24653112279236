import {
  GET_ACCOUNT_MANAGEMENT_OPTIONS_REQUEST,
  GET_ACCOUNT_MANAGEMENT_OPTIONS_SUCCESS,
  GET_ACCOUNT_MANAGEMENT_OPTIONS_FAILURE,
  GET_ACCOUNT_MANAGEMENT_ACTIVITY_REQUEST,
  GET_ACCOUNT_MANAGEMENT_ACTIVITY_SUCCESS,
  GET_ACCOUNT_MANAGEMENT_ACTIVITY_FAILURE,
  RESET_ACCOUNT_MANAGEMENT_ACTIVITY,
  GET_ACCOUNT_MANAGEMENT_REASONS_REQUEST,
  GET_ACCOUNT_MANAGEMENT_REASONS_SUCCESS,
  GET_ACCOUNT_MANAGEMENT_REASONS_FAILURE,
  RESET_ACCOUNT_MANAGEMENT,
} from "../actions/accountManagement/constants";

const INITIAL_STATE = {
  fetching: false,
  options: null,
  activity: null,
  reasons: null,
  error: null,
};

export default function settings(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_ACCOUNT_MANAGEMENT_OPTIONS_REQUEST:
    case GET_ACCOUNT_MANAGEMENT_ACTIVITY_REQUEST:
    case GET_ACCOUNT_MANAGEMENT_REASONS_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case GET_ACCOUNT_MANAGEMENT_OPTIONS_FAILURE:
    case GET_ACCOUNT_MANAGEMENT_ACTIVITY_FAILURE:
    case GET_ACCOUNT_MANAGEMENT_REASONS_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error,
      };
    case GET_ACCOUNT_MANAGEMENT_OPTIONS_SUCCESS:
      return {
        ...state,
        fetching: false,
        options: action.payload,
        error: false,
      };
    case GET_ACCOUNT_MANAGEMENT_ACTIVITY_SUCCESS:
      return {
        ...state,
        fetching: false,
        activity: action.payload,
        error: false,
      };
    case GET_ACCOUNT_MANAGEMENT_REASONS_SUCCESS:
      return {
        ...state,
        fetching: false,
        reasons: action.payload,
        error: false,
      };
    case RESET_ACCOUNT_MANAGEMENT_ACTIVITY:
      return { ...state, activity: INITIAL_STATE.activity };
    case RESET_ACCOUNT_MANAGEMENT:
      return INITIAL_STATE;
    default:
      return state;
  }
}
