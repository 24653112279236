import {
    HIDE_PROFILE_PROMPT_MODAL,
    SHOW_PROFILE_PROMPT_MODAL,
} from '../../actions/common/constants';

const INITIAL_STATE = {
    profilePrompts: {
        isVisible: false,
    },
};

export default function promptModals(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SHOW_PROFILE_PROMPT_MODAL:
            return {
                ...state,
                profilePrompts: {
                    ...action.payload,
                    isVisible: true,
                },
            };
        case HIDE_PROFILE_PROMPT_MODAL:
            return {
                ...state,
                profilePrompts: {
                    ...INITIAL_STATE.profilePrompts
                },
            };
        default:
            return state;
    }
}
