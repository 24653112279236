import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { feedPostTypes } from "../../utils/commonVariables";
import CommonAd from "../../containers/loggedIn/Feed/cards/ads/CommonAd";
import SharedCard from "../../containers/loggedIn/Feed/cards/SharedCard";
import CommonCard from "../../containers/loggedIn/Feed/cards/Common";
import { decodeString } from "../../utils/helper";
import { gaEvents } from "../../utils/events";

const SuggestedPost = (props) => {
  const { data, comments } = props;
  const postData = get(data, "suggestion", {});
  const profile = get(props, "user.user.profile", {});

  const title = get(data, "title", "");
  const subtitle = get(data, "sub_title", "");
  const totalCount = get(data, "total_count", 0);
  const profiles = get(data, "profiles", []);

  const gaClickEvent = () => {
    if (
      get(data, "suggestion.type", "") === "polling" &&
      !isEmpty(get(data, "suggestion.meta.self_vote", {}))
    ) {
      gaEvents.landingpage.pollResultSuggestionCard();
    } else {
      gaEvents.landingpage.suggestionCard(get(postData, "type"));
    }
  };

  const secondaryClass =
    !postData[postData.type] ||
    postData.type === "suggestion" ||
    postData.type === "feedCard" ||
    (postData.type === feedPostTypes.advertisement &&
      postData[feedPostTypes.advertisement] &&
      (postData[feedPostTypes.advertisement].type === "image" ||
        (postData.meta && !postData.meta.count)));

  const getComponent = (feedData) => {
    if (
      !feedData ||
      (feedData.shared && !feedData.sharedBy) ||
      (!feedData.shared && feedData.sharedBy)
    ) {
      return null;
    }
    const { type } = feedData;
    const profileId = profile ? profile.id : false;
    const isSuggestion = type === "suggestion";
    const isAd = type === "advertisement";
    const isFeedCard = type === "feedCard";
    if (
      !feedData[type] ||
      (!feedData.profile &&
        !feedData.company &&
        !feedData.product &&
        !isAd &&
        !isFeedCard &&
        !isSuggestion) ||
      !feedData
    ) {
      return null;
    }
    // Ad engine card
    if (isAd) {
      return (
        <CommonAd
          comments={comments}
          cardData={feedData}
          showOptionsIcon={false}
        />
      );
    }
    // Shared card
    if (feedData.shared) {
      const commentShareObj = `${type}-${feedData[type].id}-share-${feedData.shared.id}`,
        commentsData = comments.byPostId[commentShareObj] || null,
        showOptionsIcon = feedData.shared.profile_id === profileId; // only to shared user
      return (
        <SharedCard
          showOptionsIcon={showOptionsIcon}
          showFlagOptions={true}
          commentsData={commentsData}
          cardData={feedData}
        />
      );
    }
    const commentsData =
        comments.byPostId[`${type}-${feedData[type].id}`] || null,
      showOptionsIcon = feedData.company
        ? feedData.meta
          ? feedData.meta.isAdmin
          : false
        : feedData.profile
        ? feedData.profile.id === profileId
        : false;
    return (
      <CommonCard
        type={type}
        showFlagOptions={true}
        showOptionsIcon={showOptionsIcon}
        commentsData={commentsData}
        cardData={feedData}
      />
    );
  };

  return (
    <div className="home__card__content">
      <ul id="feed-list" className="feed-list">
        <li
          className={`feed-list__item ${
            secondaryClass ? "feed-list__item--secondary" : ""
          }`}
          key={`feed-card-${postData.type}`}
          onClick={gaClickEvent}
        >
          <div>
            <div className="home__suggested">
              <p className="home__suggested__title">{title}</p>
              {totalCount > 0 ? (
                <div className="home__suggested__subtitle">
                  {profiles.map((profileObj, i) => {
                    const name = `${decodeString(get(profileObj, "name", ""))}${
                      totalCount > i + 2 && i < 2 ? ", " : ""
                    }`;

                    const link = get(profileObj, "handle", "")
                      ? `/@${get(profileObj, "handle", "")}`
                      : "";

                    return (
                      <React.Fragment key={i}>
                        {totalCount > 1 &&
                          totalCount <= 3 &&
                          profiles.length === i + 1 && (
                            <span className="home__suggested__subtitle__text">
                              &nbsp;and&nbsp;
                            </span>
                          )}
                        <Link
                          key={i}
                          to={link}
                          className="home__suggested__subtitle__userlink"
                        >
                          {name}
                        </Link>
                      </React.Fragment>
                    );
                  })}
                  {totalCount > 3 && (
                    <>
                      <span className="home__suggested__subtitle__text">
                        &nbsp;and&nbsp;
                      </span>
                      <span className="home__suggested__subtitle__userlink">
                        {`${totalCount - 3}`}
                      </span>
                    </>
                  )}
                  <span className="home__suggested__subtitle__text">{` ${subtitle}`}</span>
                </div>
              ) : null}
            </div>
            {getComponent(postData)}
          </div>
        </li>
      </ul>
    </div>
  );
};

SuggestedPost.propTypes = {
  data: PropTypes.object,
  comments: PropTypes.object,
};
export default SuggestedPost;
