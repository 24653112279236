import { RESET_REVIEWS_COLLECTION,
    GET_REVIEWS_COLLECTION_FAILURE,
    GET_REVIEWS_COLLECTION_REQUEST,
    GET_REVIEWS_COLLECTION_ELEMENTS_REQUEST,
    GET_REVIEWS_COLLECTION_ELEMENTS_SUCCESS,
    GET_REVIEWS_COLLECTION_ELEMENTS_FAILURE,
    GET_REVIEWS_COLLECTION_SUCCESS } from "../../actions/reviews/constants";

const INITIAL_STATE = {
    fetching: false,
    data: null,
    elements: [],
    error: false,
    page: 0
}

export default function collections(state = INITIAL_STATE, action) {
    switch(action.type) {
        case GET_REVIEWS_COLLECTION_REQUEST:
        case GET_REVIEWS_COLLECTION_ELEMENTS_REQUEST:
            return {
                ...state,
                fetching: true
            }
        case GET_REVIEWS_COLLECTION_SUCCESS:
            return {
                ...state,
                data: action.payload,
                hasEnd: action.payload.count <= 20,
                fetching: false,
                page: (state.page + 1)
            }
        case GET_REVIEWS_COLLECTION_FAILURE:
        case GET_REVIEWS_COLLECTION_ELEMENTS_FAILURE:
            return {
                ...state,
                ...(!action.error.__CANCEL__ && {
                    error: true,
                    errorMsg: action.error
                }),
                fetching: false
            }
        case GET_REVIEWS_COLLECTION_ELEMENTS_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    elements: [...state.data.elements, ...action.payload],
                },
                hasEnd: !action.payload.length,
                page: (state.page + 1),
                fetching: false
            }
        case RESET_REVIEWS_COLLECTION:
            return INITIAL_STATE
        default:
            return state;
    }
}