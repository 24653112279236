import { getResponseData } from "../../utils/helper";
import { getBaseUrl, surveyLinks } from "../../utils/links";
import Axios from "axios";
import get from "lodash/get"
const CancelToken = Axios.CancelToken;

export let surveyPreviewDownloadCancelToken = undefined;
export const surveyPreviewDownload = (token, id) => {
    if (surveyPreviewDownloadCancelToken) surveyPreviewDownloadCancelToken();
    let url = `${getBaseUrl()}${surveyLinks.downloadSurveyPreview(id)}`,
        options = {
            url,
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            cancelToken: new CancelToken((c) => (surveyPreviewDownloadCancelToken = c)),
        };
    return Axios(options)
        .then((response) => {
            let successData = response.data;
            return successData;
        })
        .catch((_) => false);
};

export let verifySurveyPreviewCancelToken = undefined;
export const verifySurveyPreview = (data, id) => {
    if (verifySurveyPreviewCancelToken) verifySurveyPreviewCancelToken();
    let url = `${getBaseUrl()}${surveyLinks.surveyPreviewVerify(id)}`,
        options = {
            url,
            method: "POST",
            data,
        };
    return Axios(options)
        .then((response) => {
            let successData = response.data;
            return successData;
        })
        .catch((_) => false);
};