import React from 'react';
import PropTypes from 'prop-types';
import ChevronRight from '../../../assets/images/photos/chevron-right.svg';

function SliderArrow(props){
    const { onClick,
        to,
        currentSlide,
        classNameToAppend,
        slideCount,
        totalSlides } = props,
        isDisabled = ((to === 'prev' && currentSlide === 0) || (to === 'next' && parseInt(currentSlide) === slideCount - totalSlides));
        return (<button aria-hidden="true"
            aria-disabled={isDisabled}
            type="button"
            disabled={isDisabled}
            className={`suggestions__arrow suggestions__arrow--${to}
                review-page__suggestions__arrow
                ${classNameToAppend || ''}
                ${isDisabled ? 'suggestions__arrow--disabled' : ''}`}
            onClick={onClick}>
                <img className={`suggestions__arrow__icon suggestions__arrow__icon--${to}`}
                    src={ChevronRight} alt="go to next slide" />
        </button>)
}

SliderArrow.propTypes = {
    classNameToAppend: PropTypes.string
}

export default SliderArrow;