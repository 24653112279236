import { GET_ALL_COMPANIES_REQUEST,
    GET_ALL_COMPANIES_SUCCESS,
    GET_ALL_COMPANIES_FAILURE,
    RESET_ALL_COMPANIES,
    FOLLOW_COMPANY_SUCCESS} from "../../actions/company/constants";

const INITIAL_STATE = {
    page: 1,
    fetching: false,
    data: [],
    error: false
}
export default function all(state = INITIAL_STATE, action) {
    switch(action.type) {
        case GET_ALL_COMPANIES_REQUEST:
            return {
                ...state,
                fetching: true
            }
        case GET_ALL_COMPANIES_SUCCESS:
            return {
                ...state,
                fetching: false,
                page: state.page + 1,
                data: action.payload
                    && action.payload.company
                    ? [...state.data, ...(action.payload.company
                        && action.payload.company)]
                    : state.data,
                hasEnd: !action.payload.company
                    || (action.payload.company
                        && !action.payload.company.length),
                hasFetched: true
            }
        case GET_ALL_COMPANIES_FAILURE:
            return {
                ...state,
                fetching: false,
                ...(!action.error.__CANCEL__ && {
                    error: true,
                    errorMsg: action.error
                }),
            }
        case FOLLOW_COMPANY_SUCCESS:
            return {
                ...state,
                ...(state.hasFetched && {
                    data: state.data.map(_ => {
                        if (_.id !== action.payload.companyId) {
                            return _
                        }
                        return {
                            ..._,
                            isFollowing: true
                        }
                    })
                })
            }
        case RESET_ALL_COMPANIES:
            return INITIAL_STATE
        default:
            return state;
    }
}