import { SET_SINGLE_PRODUCT_REVIEW,
    GET_PRODUCT_REVIEW_COMMMENTS_REQUEST,
    GET_PRODUCT_REVIEW_COMMMENTS_SUCCESS,
    GET_PRODUCT_REVIEW_COMMMENTS_FAILURE,
    GET_SINGLE_PRODUCT_REVIEW_REQUEST,
    GET_SINGLE_PRODUCT_REVIEW_SUCCESS,
    RESET_PRODUCT_REVIEW_COMMENTS,
    GET_SINGLE_PRODUCT_REVIEW_FAILURE,
    RESET_SINGLE_PRODUCT_REVIEW,
    DELETE_PRODUCT_REVIEW_COMMENTS_SUCCESS,
    POST_PRODUCT_REVIEW_COMMMENT_SUCCESS } from "../../actions/products/constants";

const INITIAL_STATE = {
    fetching: false,
    review: null,
    showModal: false,
    comments: {
        fetching: false,
        page: 1,
        error: false,
        data: null,
    },
    error: false
}

export default function review(state = INITIAL_STATE, action) {
    switch(action.type) {
        case GET_PRODUCT_REVIEW_COMMMENTS_REQUEST:
            return {
                ...state,
                comments: {
                    ...state.comments,
                    fetching: true
                }
            }
        case POST_PRODUCT_REVIEW_COMMMENT_SUCCESS:
            return {
                ...state,
                comments: {
                    ...state.comments,
                    data: state.comments.data
                        ? {
                            ...state.comments.data,
                            comments: [...state.comments.data.comments, action.payload.comment]
                        }
                        : {
                            comments: [action.payload.comment]
                        }
                },
                review: {
                    ...state.review,
                    comment_count: state.review.comment_count + 1
                }
            }
        case DELETE_PRODUCT_REVIEW_COMMENTS_SUCCESS:
            return {
                ...state,
                comments: {
                    ...state.comments,
                    ...(state.comments.data && {
                        data: {
                            ...state.comments.data,
                            ...(state.comments.data.comments
                                && !!state.comments.data.comments.length
                                && {
                                    comments: state.comments.data.comments.filter(_ => _.id !== action.payload.commentId)
                                })
                        }
                    })
                },
                review: {
                    ...state.review,
                    comment_count: state.review.comment_count - 1 < 0
                        ? 0
                        : state.review.comment_count - 1
                }
            }
        case GET_PRODUCT_REVIEW_COMMMENTS_SUCCESS:
            return {
                ...state,
                comments: {
                    ...state.comments,
                    fetching: false,
                    data: state.comments.page === 1
                        ? {
                            ...action.payload,
                            comments: action.payload.comments.reverse()
                        }
                        : {
                            ...state.comments.data,
                            ...action.payload,
                            comments: [
                                ...action.payload.comments.reverse(),
                                ...state.comments.data.comments
                            ]
                        },
                    page: state.comments.page + 1
                }
            }
        case GET_PRODUCT_REVIEW_COMMMENTS_FAILURE:
            return {
                ...state,
                comments: {
                    ...state.comments,
                    fetching: false,
                    error: true,
                    errorMsg: action.error
                }
            }
        case SET_SINGLE_PRODUCT_REVIEW:
        case GET_SINGLE_PRODUCT_REVIEW_SUCCESS:
            return {
                ...state,
                review: action.payload,
                comments: INITIAL_STATE.comments,
                fetching: false,
                showModal: true,
            }
        case GET_SINGLE_PRODUCT_REVIEW_REQUEST:
            return {
                ...state,
                fetching: true,
                comments: INITIAL_STATE.comments
            }
        case GET_SINGLE_PRODUCT_REVIEW_FAILURE:
            return {
                ...state,
                fetching: false,
                error: true,
                errorMsg: action.error
            }
        case RESET_SINGLE_PRODUCT_REVIEW:
        case RESET_PRODUCT_REVIEW_COMMENTS:
            return INITIAL_STATE
        default:
            return state;
    }
}