import React from "react";
import PropTypes from "prop-types";
import LoginViaPhone from "./LoginViaPhone";
import LoginViaEmailForm from "./LoginViaEmailForm";

const LoginForm = (props) => {
  const { loginVia, path } = props;
  return (
    <React.Fragment>
      {loginVia === "phone" ? (
        <LoginViaPhone {...props} />
      ) : (
        <LoginViaEmailForm {...props} path={path} />
      )}
    </React.Fragment>
  );
};

LoginForm.propTypes = {
  loginVia: PropTypes.string,
};

export default LoginForm;
