import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import get from "lodash/get";
import {
  getQuickLinks,
  getQuickLinksCancelToken,
} from "../../actions/landingPage";

import {
  productLinks,
  surveyLinks,
  hashtagLinks,
  collaborationInternalLinks,
  polling,
  quizLinks,
} from "../../utils/internalLinks";
import { gaEvents } from "../../utils/events";

const quickLinks = {
  collaborate: collaborationInternalLinks.all,
  "product-review": collaborationInternalLinks.productReviews,
  product: productLinks.all,
  polling: polling.all,
  surveys: surveyLinks.all,
  hashtag: hashtagLinks.trending,
  quiz: quizLinks.all,
};

const handleQuickLink = (link) => {
  const title = get(link, "title");
  if (title !== "Quizzes") {
    gaEvents.landingpage.quickLink(title);
  } else {
    gaEvents.landingpage.quickLink("Quiz");
  }
};

const DesktopQuickLinks = ({ data }) => {
  if (!data || (data && !data.length)) return null;
  return (
    <div className="feed-common-cards feed-quick-link" id="quick-links">
      <p className="feed-quick-link__title">Quick Links</p>
      <div>
        {data.map((obj, i) => (
          <Link
            key={get(obj, "id", `title-${i}`)}
            to={get(quickLinks, `[${get(obj, "model_name")}]`, "")}
            className="feed-quick-link__item"
            onClick={() => handleQuickLink(obj)}
          >
            <img
              src={get(obj, "image", "")}
              alt={`${get(obj, "title")}-image`}
              className="feed-quick-link__item__image"
            />
            <span className="feed-quick-link__item__text">
              {get(obj, "title", "")}
            </span>
          </Link>
        ))}
      </div>
    </div>
  );
};

const MobileQuickLinks = ({ data }) => {
  return (
    <div className="feed-quick-link-mobile" id="mobilie-quick-links">
      {data.map((obj) => (
        <Link
          key={get(obj, "id")}
          to={get(quickLinks, `[${get(obj, "model_name")}]`, "")}
          className="feed-quick-link-mobile__item quick-links"
        >
          <img
            src={get(obj, "image")}
            alt={`${get(obj, "title")}-image`}
            className="feed-quick-link-mobile__item__image"
          />
          <span className="feed-quick-link-mobile__item__text">
            {get(obj, "title")}
          </span>
        </Link>
      ))}
    </div>
  );
};

const QuickLinks = (props) => {
  const {
    token,
    isMobile,
    mobileData,
    getQuickLinks,
    getQuickLinksCancelToken,
    quickLinksData,
  } = props;

  const [linksData, setLinksData] = useState([]);

  useEffect(() => {
    if (get(mobileData, "elements", []).length > 0)
      setLinksData(get(mobileData, "elements", []));
    else {
      getQuickLinks(token);
    }

    return () => {
      if (getQuickLinksCancelToken) getQuickLinksCancelToken();
    };
  }, [isMobile]);

  useEffect(() => {
    if (get(quickLinksData, "data[0].elements", []).length > 0) {
      setLinksData(get(quickLinksData, "data[0].elements", []));
    }
  }, [quickLinksData]);
  return (
    <>
      {!isMobile ? (
        <DesktopQuickLinks data={linksData} />
      ) : (
        <MobileQuickLinks data={linksData} />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.login.token,
    quickLinksData: state.landingPageReducer.quickLinks,
  };
};

const mapDispatchToProps = {
  getQuickLinks,
  getQuickLinksCancelToken,
};

DesktopQuickLinks.propTypes = {
  data: PropTypes.array,
};

MobileQuickLinks.propTypes = {
  data: PropTypes.array,
};

QuickLinks.propTypes = {
  token: PropTypes.string,
  isMobile: PropTypes.bool,
  getQuickLinks: PropTypes.func,
  getQuickLinksCancelToken: PropTypes.func,
  quickLinksData: PropTypes.object,
  mobileData: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuickLinks);
