
// get organizations 

import Axios from "axios";
import { donationLinks, getBaseUrl } from "../../utils/links";
import { getResponseData, onRequest, onError, onSuccess } from "../../utils/helper";
import { PROFILE_UPDATE_FAILURE, PROFILE_UPDATE_REQUEST, PROFILE_UPDATE_SUCCESS } from "../profile/constants";
import { DONATION_PREFERENCE_FALIURE, DONATION_PREFERENCE_REQUEST, DONATION_PREFERENCE_SUCCESS } from "./constants";
const CancelToken = Axios.CancelToken;

export let getDonationOrganizationsToken = undefined;
export const getDonationOrganizations = (token) => {
    if (getDonationOrganizationsToken) getDonationOrganizationsToken();
    let url = `${getBaseUrl()}${donationLinks.getOrganisations}`,
        options = {
            url,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            cancelToken: new CancelToken((c) => (getDonationOrganizationsToken = c)),
        };
    return Axios(options)
        .then((response) => {
            return getResponseData(response);
        })
        .catch((_) => {
            return false;
        });
};


export let postDonationOrganizationToken = undefined;
export const postDonationOrganization = (token,data) => {
    return (dispatch)=>{
        if (getDonationOrganizationsToken) getDonationOrganizationsToken();
        dispatch(onRequest(DONATION_PREFERENCE_REQUEST));
        let url = `${getBaseUrl()}${donationLinks.postDonation}`,
            options = {
                url,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                method: "POST",
                data,
                cancelToken: new CancelToken((c) => (getDonationOrganizationsToken = c)),
            };
        return Axios(options)
            .then((response) => {
                let successData = getResponseData(response);
                if (successData) {
                  dispatch(onSuccess(DONATION_PREFERENCE_SUCCESS, {
                    is_donation: data.is_donation,
                    donation_organisation: data.donation_organisation,
                  }));
                } else {
                  dispatch(onError(DONATION_PREFERENCE_FALIURE, response.data.errors));
                }
                return successData;
            })
            .catch((error) => {
                dispatch(onError(DONATION_PREFERENCE_FALIURE, error));
                return false;
            });
    }
   
};