import React from 'react';

function VerifiedLogo(props) {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" {...props} viewBox="0 0 20 20">
        <g fill="none" fillRule="evenodd">
            <path fill="#FFF" d="M9.392 16.281c2.761 0 7.06-5.645 7.06-8.406C16.453 5.114 12.763 5 10 5c-2.761 0-6.077 2.239-6.077 5s2.708 6.281 5.469 6.281z"/>
            <path fill="#4990E2" fillRule="nonzero" d="M6.893 8.932l1.835 1.98 4.379-4.662L15 8.294l-4.379 4.662L8.728 15l-1.894-2.044L5 10.976l1.893-2.044zM.89 11.326c.42.365.549.995.323 1.526l-.194.464c-.291.73.097 1.591.84 1.79l.484.133c.55.166.905.63.937 1.227l.033.53c.032.796.743 1.393 1.518 1.26l.517-.099c.55-.1 1.099.166 1.357.663l.227.464c.355.697 1.26.929 1.874.465l.42-.299c.452-.331 1.066-.331 1.518 0l.42.299c.646.464 1.519.265 1.906-.431l.259-.465c.258-.497.808-.762 1.357-.663l.517.1c.775.132 1.486-.431 1.519-1.227l.032-.53c.032-.564.42-1.062.937-1.194l.484-.133c.744-.199 1.131-1.061.84-1.79l-.193-.498c-.226-.53-.065-1.127.355-1.525l.388-.332c.581-.53.581-1.459 0-1.99l-.388-.331c-.42-.365-.55-.995-.323-1.525l.194-.465c.29-.729-.097-1.591-.84-1.79l-.485-.133c-.55-.166-.905-.63-.937-1.227l-.032-.53c-.032-.796-.743-1.393-1.519-1.26l-.517.1c-.549.099-1.098-.167-1.357-.664l-.29-.53c-.356-.697-1.26-.929-1.875-.465l-.42.299C10.323.88 9.71.88 9.257.55L8.837.25C8.19-.213 7.318-.014 6.93.682l-.259.465c-.258.497-.808.762-1.357.663l-.517-.1c-.775-.132-1.486.431-1.519 1.227l-.032.53c-.032.564-.42 1.062-.937 1.194l-.484.133c-.744.199-1.131 1.061-.84 1.79l.193.498c.226.53.065 1.127-.355 1.525l-.388.332c-.581.53-.581 1.459 0 1.99l.453.397z"/>
        </g>
    </svg>)
};

export default VerifiedLogo;