import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { homeLink } from '../../../../utils/internalLinks';
import CloseIcon from '../../../../assets/images/login/close.svg';
import { postVerifyOTP } from '../../../../actions/login/login';
import { getTwoDigits, handleAuthState, isValidNumber } from '../../../../utils/helper';
import { postOTPSend, postOTPSendCancelToken } from '../../../../actions/profile/phone';
import { otpLength } from '../../../../utils/commonVariables';

const errorTexts = {
  otp: 'Please enter a valid OTP',
};
const maxOtpLength = 6;

const LoginViaPhone2 = (props) => {
  const { setStepNo, phone, countryCode, isWhatsAppOpted } = props;
  const history = useHistory();
  const [otp, setOtp] = useState('');
  const [invalidOTP, setInvalidOTPError] = useState(false);
  const [otpError, setOtpError] = useState('');

  const [submitting, setSubmitting] = useState(false);
  const [canResend, setCanResendOtp] = useState(false);
  const [otpSeconds, setOtpSeconds] = useState(59);
  const [inputClass, setInputClass] = useState(
    'loginphone__container loginphone__container__noerror',
  );

  let timerRef = useRef(null);
  useEffect(() => {
    timerRef.current = setInterval(() => {
      setOtpSeconds(otpSeconds === 0 ? 0 : otpSeconds - 1);
      if (otpSeconds === 0) {
        clearInterval(timerRef.current);
        setCanResendOtp(true);
      }
    }, 1000);
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
      if (postOTPSendCancelToken) postOTPSendCancelToken();
    };
  }, [otpSeconds]);

  const handleOTPBlur = () => {
    let invalidOtp;

    if (!otp || otp.trim().length !== 6) invalidOtp = true;
    else invalidOtp = false;

    setInvalidOTPError(invalidOtp);

    if (invalidOtp || otpError) {
      setInputClass('loginphone__container loginphone__container__error');
    } else {
      setInputClass('loginphone__container loginphone__container__noerror');
    }

    if (otpError) setOtpError('');
  };
  const handleOTPChange = (e) => {
    const otp = isValidNumber(e.target.value.slice(0, 6));
    setOtp(otp);

    if (otp.trim().length === 6 && invalidOTP) {
      setInvalidOTPError(!invalidOTP);
      setInputClass('loginphone__container loginphone__container__noerror');
    }

    if (otpError) setOtpError('');
  };

  const resendOTP = (e) => {
    e.preventDefault();

    const dataToSend = {
      profile: {
        country_code: countryCode,
        mobile: phone,
        platform: 'web',
      },
    };
    postOTPSend(dataToSend).then((response) => {
      if (response.status) {
        if (get(response, 'data.data')) {
          setCanResendOtp(false);
          setOtpSeconds(59);
        } else {
          const error = get(response, 'data.errors');
          setOtpError(error);
        }
      } else {
        alert('Some error occured');
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { postVerifyOTP } = props;
    setSubmitting(true);

    const dataToSend = {
      profile: {
        country_code: countryCode,
        mobile: phone,
        platform: 'web',
        whatsapp_opt_in: +isWhatsAppOpted
      },
      otp,
    };

    postVerifyOTP(dataToSend).then((val) => {
      if (val.status) {
        handleAuthState.add(val.token);
        history.replace(homeLink);
      } else {
        const error = get(val, 'error');
        setSubmitting(false);
        setOtpError(error);
        setInputClass('loginphone__container loginphone__container__error');
      }
    });
  };

  return (
    <div className="login-form">
      <div className="login-form__backdrop">
        <button type="button" className="login-form__cancel" onClick={props.toggleLoginForm}>
          <img src={CloseIcon} alt="go to homepage" />
        </button>
        <div className="login-form__container">
          <div className="otp-form__header">
            <p className="otp-form__header__title">Enter OTP</p>
          </div>
          <p className="otp-form__subtitle">{`We've sent an OTP to ${phone}.`}</p>
          <form
            className="login-form__form"
            onSubmit={handleSubmit}
            disabled={submitting || otp.trim().length !== maxOtpLength}
          >
            <div className={inputClass}>
              <input
                type="text"
                placeholder="OTP"
                maxLength={otpLength}
                value={otp}
                autoFocus
                onChange={handleOTPChange}
                onBlur={handleOTPBlur}
                className="otp-form__otp"
              />
            </div>
            <div className="otp-form__errors">
              {invalidOTP && <p className="error-text">{errorTexts.otp}</p>}
              {otpError && <p className="error-text">{otpError}</p>}
            </div>

            <div className="otp-form__resend__countdown">
              <span> {`00:${getTwoDigits(otpSeconds)}`}</span>
            </div>

            <div className="otp-form__resend__button-div">
              <span className="otp-form__resend__button-div__text">
                Didn&apos;t receive the code?
              </span>
              <button
                type="button"
                disabled={!canResend}
                className="otp-form__resend__button-div__button"
                onClick={resendOTP}
              >
                Resend now
              </button>
            </div>
            <button
              type="submit"
              disabled={submitting || otp.trim().length !== maxOtpLength}
              className={`login-form__action__btn login-form__action__btn--default`}
            >
              Continue
            </button>
          </form>
          <p className="otp-form__updatecontact cursor-pointer" onClick={() => setStepNo(1)}>
            Update contact info
          </p>
        </div>
      </div>
    </div>
  );
};

LoginViaPhone2.propTypes = {
  toggleLoginForm: PropTypes.func,
  publicPageLink: PropTypes.string,
  history: PropTypes.object,
  login: PropTypes.func,
  phone: PropTypes.string,
  countryCode: PropTypes.string,
  setStepNo: PropTypes.func,
  postVerifyOTP: PropTypes.func,
};

export default withRouter(connect(null, { postVerifyOTP })(LoginViaPhone2));
