import { GET_SETTINGS_REQUEST,
    GET_SETTINGS_SUCCESS,
    GET_SETTINGS_FAILURE,
    GET_COMPANY_SETTINGS_SUCCESS,
    GET_COMPANY_SETTINGS_REQUEST,
    POST_SETTINGS_REQUEST } from '../actions/settings/constants';

const INITIAL_STATE = {
    fetching: false,
    hasEndReached: false,
    settings: null,
}

export default function settings(state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_SETTINGS_REQUEST:
        case GET_COMPANY_SETTINGS_REQUEST:
            return {
                ...state,
                fetching: true
            }
        case GET_SETTINGS_SUCCESS:
        case GET_COMPANY_SETTINGS_SUCCESS:
            return {
                ...state,
                fetching: false,
                settings: action.payload
            }
        case GET_SETTINGS_FAILURE:
            return {
                ...state,
                fetching: false,
                error: true,
                errorMsg: action.error
            }
        case POST_SETTINGS_REQUEST:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    [action.payload.type]: state.settings[action.payload.type]
                        .map(_ => {
                            if (_.group_name !== action.payload.groupName) return _;
                            return {
                                ..._,
                                items: _.items.map(_ => {
                                    if (_.id !== action.payload.setting_id) return _;
                                    return {
                                        ..._,
                                        value: !!action.payload.value
                                    }
                                })
                            }
                        })
                }
            }
        default:
            return state;
    }
}