import React from 'react';
import PropTypes from 'prop-types';

function YoutubeEmbed(props) {
    const { youtubeId } = props;
    return (<div className="post-details__video-container post-details__video-container--youtube">
        <iframe title={`youtube video - id ${youtubeId}`} className="post-details__video-container--youtube__player"
            src={`https://www.youtube.com/embed/${youtubeId}?enablejsapi=1`}
            frameBorder="0" />
    </div>)
};

YoutubeEmbed.propTypes = {
    youtubeId: PropTypes.string.isRequired
}

export default YoutubeEmbed;