// add to feed via socket
export const ADD_TO_FEED_VIA_SOCKET = 'ADD_TO_FEED_VIA_SOCKET';

// add to socket feed - to show pup up
export const ADD_TO_SOCKET_FEED = 'ADD_TO_SOCKET_FEED';

// remove from socket feed
export const EMPTY_SOCKET_FEED = 'EMPTY_SOCKET_FEED';

// preview constants
export const GET_PREVIEW_REQUEST = 'GET_PREVIEW_REQUEST';
export const GET_PREVIEW_SUCCESS = 'GET_PREVIEW_SUCCESS';
export const GET_PREVIEW_FAILURE = 'GET_PREVIEW_FAILURE';
export const SET_PREVIEW = 'SET_PREVIEW';

export const EMPTY_PREVIEW = 'EMPTY_PREVIEW'; // empty a given preview
export const RESET_ALL_PREVIEW = 'RESET_ALL_PREVIEW'; // reset all preview

// get a post comments
export const GET_A_POST_COMMENT_REQUEST = 'GET_A_POST_COMMENT_REQUEST';
export const GET_A_POST_COMMENT_SUCCESS = 'GET_A_POST_COMMENT_SUCCESS';
export const GET_A_POST_COMMENT_FAILURE = 'GET_A_POST_COMMENT_FAILURE';

// delete a comment
export const DELETE_A_COMMENT_REQUEST = 'DELETE_A_COMMENT_REQUEST';
export const DELETE_A_COMMENT_SUCCESS = 'DELETE_A_COMMENT_SUCCESS';
export const DELETE_A_COMMENT_FAILURE = 'DELETE_A_COMMENT_FAILURE';

// toggle a comment container
export const TOGGLE_POST_COMMENT_CONTAINER = 'TOGGLE_POST_COMMENT_CONTAINER';

// reset comments
export const RESET_COMMENTS = 'RESET_COMMENTS';

// resetting feed
export const RESET_FEED = 'RESET_FEED';