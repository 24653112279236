import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createAComment } from '../../../../actions/feed/common';
import { getAPostComment, deleteAComment } from '../../../../actions/feed/comments';
import { transformTaggedContent, insertNewProfileToTaggedContent } from '../../../../utils/helper';
import TaggableTextarea from '../../../../components/TaggableTextarea';
import CommentDisabled from '../../../../assets/images/feed/options/create-comment-disabled.svg';
import CommentEnabled from '../../../../assets/images/feed/options/create-comment.svg';
import { postMaxLength } from '../../../../utils/commonVariables';
import CommentsList from '../../../../components/comments/CommentsList';
import CommentsLoader from '../../../../components/comments/CommentsLoader';
import CommentsLoadButton from '../../../../components/comments/CommentsLoadButton';

class PostComments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: '',
      transformedContent: '',
      autoFocus: false,
      focusNow: false,
    };
    this.fetchMoreComments = this.fetchMoreComments.bind(this);
    this.postComment = this.postComment.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleReply = this.handleReply.bind(this);
  }

  handleInputChange(content) {
    this.setState({
      content,
      transformedContent: transformTaggedContent(content),
    });
  }

  postComment() {
    const {
      commentsData: { updating },
    } = this.props;
    if (updating) return;
    const { transformedContent } = this.state;
    if (!transformedContent || (transformedContent && !transformedContent.trim().length)) return;
    const { postId, postType, isShared, sharedId, isAd, createAComment, token } = this.props,
      data = {
        content: transformedContent.trim(),
      };
    createAComment(postType, postId, data, token, isShared, sharedId, isAd).then((val) => {
      if (val) {
        this.handleInputChange('');
      }
    });
  }

  componentDidMount() {
    this.setState({
      autoFocus: typeof this.props.autoFocus === 'boolean' ? this.props.autoFocus : true,
    });
  }

  fetchMoreComments(fetchType, page) {
    const { postId, postType, isShared, sharedId, isAd, getAPostComment, token } = this.props;
    getAPostComment(postType, postId, token, page, fetchType, isShared, sharedId, isAd);
  }

  handleReply(comment) {
    const { content } = this.state;
    if (content.length >= postMaxLength) return;
    const { newContent } = insertNewProfileToTaggedContent(content, comment);
    this.handleInputChange(newContent);
    this.setState(
      {
        focusNow: true,
      },
      () => {
        this.setState({
          focusNow: false,
        });
      },
    );
  }

  handleDelete(id) {
    if (!id) return;
    const { postId, postType, isShared, sharedId, token, isAd, deleteAComment } = this.props;
    deleteAComment(id, postType, postId, token, isShared, sharedId, isAd);
  }

  render() {
    const {
        commentsData,
        showOptionsIcon,
        user: {
          user: { profile },
        },
      } = this.props,
      {
        comments,
        updating,
        fetching,
        previousPage,
        nextPage,
        fetchingPrev,
        fetchingNext,
        showComments,
      } = commentsData;
    if (!showComments && !fetching) return null;
    return (
      <div>
        <div className="post-comments-containers">
          {fetching ? (
            <CommentsLoader />
          ) : (
            <>
              {previousPage ? (
                fetchingPrev ? (
                  <CommentsLoader />
                ) : (
                  <CommentsLoadButton
                    title="Load previous comments..."
                    handleClick={() => this.fetchMoreComments('prev', previousPage)}
                  />
                )
              ) : null}
              {comments && !!comments.length && (
                <CommentsList
                  comments={comments}
                  showOptionsIcon={showOptionsIcon}
                  profileId={profile.id}
                  handleReply={this.handleReply}
                  handleDelete={this.handleDelete}
                />
              )}
              {nextPage ? (
                fetchingNext ? (
                  <CommentsLoader />
                ) : (
                  <CommentsLoadButton
                    title="Load more comments..."
                    handleClick={() => this.fetchMoreComments('prev', previousPage)}
                  />
                )
              ) : null}
            </>
          )}
        </div>
        {!fetching && (
          <div className="post-comments-create-container">
            <TaggableTextarea
              isEveryone={true}
              maxLength={postMaxLength}
              className="post-comments-create-container__input"
              autoFocus={this.state.autoFocus}
              focusNow={this.state.focusNow}
              onChange={this.handleInputChange}
              value={this.state.content}
            />
            <button
              className={`post-comments-create-container__action-btn
                    ${
                      updating ||
                      !this.state.content ||
                      (this.state.content && !this.state.content.trim().length)
                        ? 'post-comments-create-container__action-btn--disabled'
                        : ''
                    }`}
              disabled={
                updating ||
                !this.state.content ||
                (this.state.content && !this.state.content.trim().length)
              }
              onClick={this.postComment}
            >
              <img
                src={
                  this.state.content && this.state.content.trim().length
                    ? CommentEnabled
                    : CommentDisabled
                }
                alt="post comment"
                className="post-comments-create-container__action-btn__icon"
              />
            </button>
          </div>
        )}
      </div>
    );
  }
}

PostComments.propTypes = {
  commentsData: PropTypes.object.isRequired,
  postType: PropTypes.string.isRequired,
  postId: PropTypes.any.isRequired,
  isShared: PropTypes.bool,
  sharedId: PropTypes.number,
  autoFocus: PropTypes.bool,
  showOptionsIcon: PropTypes.bool,
  isAd: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    token: state.login.token,
    user: state.user,
  };
}

const mapDispatchToProps = {
  getAPostComment,
  createAComment,
  deleteAComment,
};

export default connect(mapStateToProps, mapDispatchToProps)(PostComments);
