import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import CloseIcon from "../../assets/images/common/modals/close.svg";
import CreatePost from "../../components/create/CreatePost";
import CreatePoll from "../../components/create/CreatePoll";
import { hideEditAPostModal } from "../../actions/feed/common";
import { Prompt } from "react-router-dom";
import DiscardPostDraft from "./common/DiscardPost";

class EditAPostModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formIsHalfFilledOut: true,
      discardPost: false,
    };
  }
  _renderPost = (postType, post) => {
    switch (postType) {
      case "photo":
      case "shoutout":
        return (
          <CreatePost
            isEdit={true}
            editablePostType={postType}
            editablePost={post}
          />
        );
      case "polling":
        return <CreatePoll isEdit={true} editablePost={post} />;
      default:
        return null;
    }
  };

  handleClose = () => {
    const { hideEditAPostModal } = this.props;
    hideEditAPostModal();
    this.setState({ formIsHalfFilledOut: false });
  };

  componentWillUnmount() {
    if (this.props.editAPost.isVisible) {
      this.handleClose();
    }
    window.onbeforeunload = null;
  }

  render() {
    const { post, postType } = this.props;
    return (
      <div className="default-modal edit-a-post-modal">
        <div className="default-modal__backdrop">
          <div className="default-modal__container">
            <div className="default-modal__header">
              <p className="default-modal__header__title">
                Edit {postType === "polling" ? "poll" : "post"}
              </p>
              <button className="default-modal__header__close">
                <img
                  src={CloseIcon}
                  onClick={() => {
                    this.setState({ discardPost: true });
                  }}
                  alt="close modal"
                />
              </button>
            </div>
            <div className="default-modal__content">
              {postType && post && this._renderPost(postType, post)}
            </div>
          </div>
        </div>
        {this.state.discardPost && (
          <DiscardPostDraft
            modalTitle={"Discard draft?"}
            handleClose={() => this.setState({ discardPost: false })}
            handleAction={() => {
              window.onbeforeunload = null;
              this.handleClose();
            }}
            actionText="Discard"
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    token: state.login.token,
    editAPost: state.modalReducer.postModals.editAPost,
  };
}

const mapDispatchToProps = {
  hideEditAPostModal,
};

EditAPostModal.propTypes = {
  post: PropTypes.object.isRequired,
  postType: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditAPostModal);
