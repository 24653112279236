import { typesOfCollaborations, collaborationInternalPath } from './collaborationHelper';
import { surveyLinks } from './internalLinks';

export const internalNotificationLink = (notification) => {
  if (!notification) return '#';
  const { data } = notification;
  const { action, actionModel, model, profile } = data;

  if (action === 'deletemodel') return '#'; // returning if delete model
  if (model.name === 'review' && model.type === 'product') {
    return `/reviews/products/${model.product_id}?reviewId=${model.id}`; // returning if review
  }
  let modelNameToShow = model.name === 'collaborate' ? 'collaborations' : model.name;

  if (model.shared && action !== 'comment')
    return `/shared/${model.share_id}/${modelNameToShow}/${model.id}`; // Shared post
  // collaboration links
  if (
    model.name === 'collaborate' &&
    model.collaborate_type === typesOfCollaborations.productReview
  ) {
    if (action === 'invitationacceptforreview' || action === 'invitationrejectedforreview') {
      return `/${modelNameToShow}/${model.id}/product-review/manage#applicants`;
    } else if (action === 'begintasting') {
      return `/${modelNameToShow}/${model.id}/product-review?showBatches=1`;
    } else if (action === 'report_upload') {
      return collaborationInternalPath.reportsDownload(model.id);
    } else {
      return `/${modelNameToShow}/${model.id}/product-review`;
    }
  }
  // manage
  if (action === 'document_submission' && model.name === 'collaborate')
    return collaborationInternalPath.manage(model.collaborate_type, model.id);
  if (action === 'document_rejection' && model.name === 'collaborate')
    return `${collaborationInternalPath.view(
      model.collaborate_type,
      model.id,
    )}?rejected_documents=1`;
  // normal collaboration link
  if (model.name === 'collaborate' && action !== 'comment')
    return `/${modelNameToShow}/${model.id}`;
  //comments
  if (action === 'comment') {
    if (model.shared)
      return `/shared/${model.share_id}/${modelNameToShow}/${model.id}?commentId=${actionModel.id}`;
    return `/${modelNameToShow}/${model.id}?commentId=${actionModel.id}`;
  }
  // follow or user join
  if (action === 'follow' || action === 'joinfriend') return `/@${profile.handle}`;
  // if added as an admin
  if (action === 'admin') return `/companies/${model.id}`;

  //   survey
  if (model.name === 'surveys' && action === 'survey_manage') return surveyLinks.manage(model.id);
  if (model.name === 'surveys' && action === 'fill_survey') return surveyLinks.fill(model.id);

  // default return
  return `/${modelNameToShow}/${model.id}`;
};
