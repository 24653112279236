import React, { useEffect } from 'react'
import Modal from './Modal'
import { connect } from 'react-redux'
import { toggleSuccessOtpModal } from '../../../actions/common/modals'
import SuccessGIF from "../../../assets/images/quiz/play/success.gif";
import { logoutUserLocally } from '../../../actions/login/logout';


function SuccessOtpModal(props) {
    const { toggleSuccessOtpModal, logoutUserLocally, profileModals } = props;
    const handleClose = async () => {
        await toggleSuccessOtpModal("loggedIn");
    }
    useEffect(() => {
        setTimeout(() => {
            logoutUserLocally()
        }, 2000)
    }, [])
    return (
        <Modal
            title={""}
            showHeader={true}
            handleClose={handleClose}
            containerClassName="common-otp-modal"
        >
            <img src={SuccessGIF} width="auto" height="130px" className="success-gif" />
            <p className="success-gif-title" >Your password has been {!!profileModals && !!profileModals.otpModal && profileModals.otpModal.type === "Change_Password" ? "Change" : "Create"} successfully!</p>
            <p className="success-gif-subtitle">For security reasons, you will now be logged out.
                Please sign in again using your new password.</p>
        </Modal>
    )
}

function mapStateToProps(state) {
    return {
        token: state.login.token,
        profileModals: state.modalReducer.profileModals
    }
}

const mapDispatchToProps = {
    toggleSuccessOtpModal,
    logoutUserLocally
}

export default connect(mapStateToProps, mapDispatchToProps)(SuccessOtpModal)