// Get account management options
export const GET_ACCOUNT_MANAGEMENT_OPTIONS_REQUEST =
  "GET_ACCOUNT_MANAGEMENT_OPTIONS_REQUEST";
export const GET_ACCOUNT_MANAGEMENT_OPTIONS_SUCCESS =
  "GET_ACCOUNT_MANAGEMENT_OPTIONS_SUCCESS";
export const GET_ACCOUNT_MANAGEMENT_OPTIONS_FAILURE =
  "GET_ACCOUNT_MANAGEMENT_OPTIONS_FAILURE";

// Get account management activity
export const GET_ACCOUNT_MANAGEMENT_ACTIVITY_REQUEST =
  "GET_ACCOUNT_MANAGEMENT_ACTIVITY_REQUEST";
export const GET_ACCOUNT_MANAGEMENT_ACTIVITY_SUCCESS =
  "GET_ACCOUNT_MANAGEMENT_ACTIVITY_SUCCESS";
export const GET_ACCOUNT_MANAGEMENT_ACTIVITY_FAILURE =
  "GET_ACCOUNT_MANAGEMENT_ACTIVITY_FAILURE";
export const RESET_ACCOUNT_MANAGEMENT_ACTIVITY =
  "RESET_ACCOUNT_MANAGEMENT_ACTIVITY";

// get account management reasons
export const GET_ACCOUNT_MANAGEMENT_REASONS_REQUEST =
  "GET_ACCOUNT_MANAGEMENT_REASONS_REQUEST";
export const GET_ACCOUNT_MANAGEMENT_REASONS_SUCCESS =
  "GET_ACCOUNT_MANAGEMENT_REASONS_SUCCESS";
export const GET_ACCOUNT_MANAGEMENT_REASONS_FAILURE =
  "GET_ACCOUNT_MANAGEMENT_REASONS_FAILURE";

export const RESET_ACCOUNT_MANAGEMENT = "RESET_ACCOUNT_MANAGEMENT";
