import { GET_SPECIALIZATION_REQUEST,
    GET_SPECIALIZATION_SUCCESS,
    GET_SPECIALIZATION_FAILURE,
    GET_PEOPLE_BY_SPECIALIZATION_REQUEST,
    GET_PEOPLE_BY_SPECIALIZATION_SUCCESS,
    GET_PEOPLE_BY_SPECIALIZATION_FAILURE,
    RESET_PROFILE_BY_SPECIALIZATION} from './constants';
import axios from 'axios';
import { getBaseUrl, profileLinks } from '../../utils/links';
import {onRequest, onError, onSuccess, getResponseData} from '../../utils/helper';
const CancelToken = axios.CancelToken;

// action to get specializations
export let getSpecializationsCancelToken = undefined;
export function getSpecializations(token) {
    return dispatch => {
        if (getSpecializationsCancelToken) getSpecializationsCancelToken();
        dispatch(onRequest(GET_SPECIALIZATION_REQUEST));
        let url = `${getBaseUrl()}${profileLinks.specialisations}`,
            options = {
                method: 'GET',
                url,
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                cancelToken: new CancelToken((c) => getSpecializationsCancelToken = c)
            };
        return axios(options)
            .then(response => {
                let data = response.data && response.data.data ? response.data.data : null;
                if (data) {
                    dispatch(onSuccess(GET_SPECIALIZATION_SUCCESS, data));
                } else {
                    let err = response.errors;
                    dispatch(onError(GET_SPECIALIZATION_FAILURE, err));
                }
            })
            .catch(err => {
                dispatch(onError(GET_SPECIALIZATION_FAILURE, err));
            });
    };
}

// get all people from specialization
export let getPeopleBySpecializationIdCancelToken = undefined;
export const getPeopleBySpecializationId = (token,
    id,
    params = {}) => {
    return dispatch => {
        if (getPeopleBySpecializationIdCancelToken) getPeopleBySpecializationIdCancelToken();
        dispatch(onRequest(GET_PEOPLE_BY_SPECIALIZATION_REQUEST));
        let url = `${getBaseUrl()}${profileLinks.getAllPeopleBySpecialization(id)}`,
            options = {
                url,
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                params,
                cancelToken: new CancelToken((c) => getPeopleBySpecializationIdCancelToken = c)
            };
        return axios(options)
            .then(response => {
                let successData = getResponseData(response);
                if (successData) {
                    dispatch(onSuccess(GET_PEOPLE_BY_SPECIALIZATION_SUCCESS, successData));
                } else {
                    dispatch(onError(GET_PEOPLE_BY_SPECIALIZATION_FAILURE, response.data.errors));
                }
                return successData;
            })
            .catch(error => {
                dispatch(onError(GET_PEOPLE_BY_SPECIALIZATION_FAILURE, error));
                return false
            })
    }
}

// reset specialization profiles
export const resetSpecializationsProfiles = () => onSuccess(RESET_PROFILE_BY_SPECIALIZATION);