import axios from "axios";
import { commonLinks, getBaseUrl } from "../../utils/links";
const CancelToken = axios.CancelToken;

export let verifyCaptchaCancelToken = undefined;
export const verifyCaptcha = (data) => {
    let url = `${getBaseUrl()}${commonLinks.captcha}`,
        options = {
            url,
            data,
            method: 'POST',
            cancelToken: new CancelToken((c) => (verifyCaptchaCancelToken = c)),
        }
    return axios(options)
        .then(response => {
            let successData = JSON.parse(response.data.data);
            return successData;
        })
        .catch(_ => false)
}