import UploadPhotoIcon from "../../../assets/images/profile/prompts/photo.svg";
import PhoneIcon from "../../../assets/images/profile/prompts/phone.svg";
import AboutIcon from "../../../assets/images/profile/prompts/about.svg";
import CalendarIcon from "../../../assets/images/profile/prompts/dob.svg";
import GenderIcon from "../../../assets/images/profile/prompts/gender.svg";
import CityIcon from "../../../assets/images/profile/prompts/current_city.svg";
import HometownIcon from "../../../assets/images/profile/prompts/hometown.svg";

export const PROMPT_UPDATE_SUCCESS_TEXT = "Profile updated successfully";
export const PROMPT_UPDATE_FAILURE_TEXT = "Oops! Something went wrong";


export const PROFILE_KEYS = {
    PROFILE_PHOTO: 'image_meta',
    PHONE: 'phone',
    DATE_OF_BIRTH: 'dob',
    GENDER: 'gender',
    CITY: 'city',
    HOMETOWN: 'hometown',
    ABOUT: 'about',
}

export const promptTexts = {
    'image_meta': {
        image: UploadPhotoIcon,
        title: "Looks like you’ve skipped a step!",
        subtitle: "Connect the dots of your digital presence start with your profile picture!",
        actionText: "Upload your photo",
    },
    'phone': {
        image: PhoneIcon,
        title: "Unlock a world of convenience!",
        subtitle: "Add your mobile number to your profile and stay in the loop with all our latest updates.",
        actionText: "Add your phone",
        formModalTitle: "Validate Your Contact Details",
    },
    'dob': {
        image: CalendarIcon,
        title: "It’s More Than Just a Date!",
        subtitle: "Your birthday helps us tailor your experience. Don’t miss out on birthday exclusives!",
        actionText: "Add your birthday",
        formModalTitle: "Birthday",
    },
    'gender': {
        image: GenderIcon,
        title: "Be Proud, Be You",
        subtitle: "Be proud of who you are. Let us know your gender and wear it proudly on your profile.",
        actionText: "Add gender",
        formModalTitle: "Gender",
    },
    'city': {
        image: CityIcon,
        title: "Make your experience more local!",
        subtitle: "Update your current city now and connect with the community around you.",
        actionText: "Add current city",
        formModalTitle: "Current City",

    },
    'hometown': {
        image: HometownIcon,
        title: "Connect with Your Culinary Heritage!",
        subtitle: "Your hometown holds the key to unique culinary experiences. Update your profile with your hometown and receive updates on local cuisine tasting opportunities.",
        actionText: "Add hometown",
        formModalTitle: "Hometown",
    },
    'about': {
        image: AboutIcon,
        title: "Connect the dots",
        subtitle: "Help others understand what makes you, you. Fill out the ‘About’ section and start weaving connections.",
        actionText: "Add about",
        formModalTitle: "About",
    },
};

export const PROMPT_TYPE = {
    SESSION: 'session',
    INTERACTION: "interaction",
}

export const PROFILE_COMPLETION_GA = {
    VIEW: "profile_completion_prompt_view",
    SKIP: "profile_completion_prompt_skip",
    INTERACT: "profile_completion_prompt_interact",
};

export const PROFILE_COMPLETION_GA_KEYS = {
    [PROFILE_KEYS.PROFILE_PHOTO]: 'profile_photo',
    [PROFILE_KEYS.PHONE]: 'mobile_number',
    [PROFILE_KEYS.DATE_OF_BIRTH]: 'birthday',
    [PROFILE_KEYS.GENDER]: 'gender',
    [PROFILE_KEYS.CITY]: 'current_city',
    [PROFILE_KEYS.HOMETOWN]: 'hometown',
    [PROFILE_KEYS.ABOUT]: 'about',
};
