import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ProfileItemHeader from '../../profile/ProfileItemHeader';
import ShowInterestAddressCheckBox from '../showInterest/AddressList';

function ShippingAddresses(props) {
    const { toggleShippingAddressModal,
        addToFilledItems,
        isMandatoryItem } = props;
    const [shippingAddresses, setShippingAddresses] = useState(props.user.user.profile.shippingaddress || []),
        [addressSelected, setAddressSelected] = useState(props.user.user.profile.shippingaddress.length ? props.user.user.profile.shippingaddress[0].id : null),
        [addressValue, setAddressValue] = useState(props.user.user.profile.shippingaddress.length ? props.user.user.profile.shippingaddress[0] : null);

    // adding value to mandatory modal
    useEffect(() => {
        if (props.user.user.profile.shippingaddress
            && props.user.user.profile.shippingaddress.length) {
            addToFilledItems('address', props.user.user.profile.shippingaddress[0]);
        }
    }, []);

    useEffect(() => {
        setShippingAddresses(props.user.user.profile.shippingaddress);
        setAddressSelected(props.user.user.profile.shippingaddress.length ? props.user.user.profile.shippingaddress[0].id : null);
        setAddressValue(props.user.user.profile.shippingaddress.length ? props.user.user.profile.shippingaddress[0] : null);
        if (props.user.user.profile.shippingaddress
            && props.user.user.profile.shippingaddress.length) {
            addToFilledItems('address', props.user.user.profile.shippingaddress[0]);
        }
    }, [props.user.user.profile.shippingaddress]);


    return (<div className="profile-view__details__block profile-view__details__block__professional">
        <ProfileItemHeader
            clickFunc={() => toggleShippingAddressModal()}
            title="Delivery Addresses"
            isSelf={true}
        />
        {
            !!shippingAddresses.length
                ? <ShowInterestAddressCheckBox handleSelect={(id, address) => {
                    setAddressSelected(id);
                    setAddressValue(address);
                    addToFilledItems('address', address);
                }}
                    selectedId={addressSelected}
                    addresses={shippingAddresses} />
                : <p className="profile-view__details__block__personal__title">Add delivery address</p>
        }
    </div>)
};

function mapStateToProps(state) {
    return {
        user: state.user
    }
}

ShippingAddresses.propTypes = {
    toggleShippingAddressModal: PropTypes.func,
    isMandatoryItem: PropTypes.bool,
    addToFilledItems: PropTypes.func
}

export default connect(mapStateToProps)(ShippingAddresses);