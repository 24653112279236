import React from 'react';
import PropTypes from 'prop-types';
import AddMore from '../../assets/images/profile/view/add-more.svg';
import EditIcon from '../../assets/images/profile/edit/edit.svg';

const ProfileItemHeader = (props) => {
    const { title, isSelf, clickFunc, isEdit } = props;
    return(<div className="profile-view__details__block__professional__edit-container">
        <p className="profile-view__details__block__professional__title">{title}</p>
        {   isSelf &&
            (<button onClick={() => {
                if (clickFunc) clickFunc();
            }} className="profile-view__details__add-button">
                {
                    isEdit
                    ? <img src={EditIcon} className="profile-view__details__edit-button__image" alt="edit"/>
                    : <img src={AddMore} className="profile-view__details__add-button__image" alt="add"/>
                }
            </button>)
        }
    </div>)
}

ProfileItemHeader.propTypes = {
    title: PropTypes.string,
    isSelf: PropTypes.bool,
    clickFunc: PropTypes.func
}

export default ProfileItemHeader;