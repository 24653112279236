// TTFB profile
export const GET_TTFB_PROFILE_DATA_REQUEST = 'GET_TTFB_PROFILE_DATA_REQUEST';
export const GET_TTFB_PROFILE_DATA_SUCCESS = 'GET_TTFB_PROFILE_DATA_SUCCESS';
export const GET_TTFB_PROFILE_DATA_FAILURE = 'GET_TTFB_PROFILE_DATA_FAILURE';

export const RESET_TTFB_PROFILE_DATA = 'RESET_TTFB_PROFILE_DATA';

// Side bar menu
export const GET_TTFB_SIDEBAR_REQUEST = 'GET_TTFB_SIDEBAR_REQUEST';
export const GET_TTFB_SIDEBAR_SUCCESS = 'GET_TTFB_SIDEBAR_SUCCESS';
export const GET_TTFB_SIDEBAR_FAILURE = 'GET_TTFB_SIDEBAR_FAILURE';
export const SET_TTFB_SIDEBAR_DROPDOWN = 'SET_TTFB_SIDEBAR_DROPDOWN';

export const RESET_TTFB_SIDEBAR = 'RESET_TTFB_SIDEBAR';

// Status
export const GET_TTFB_LEAD_STATUS_REQUEST = 'GET_TTFB_LEAD_STATUS_REQUEST';
export const GET_TTFB_LEAD_STATUS_SUCCESS = 'GET_TTFB_LEAD_STATUS_SUCCESS';
export const GET_TTFB_LEAD_STATUS_FAILURE = 'GET_TTFB_LEAD_STATUS_FAILURE';

// OVERVIEW
export const GET_TTFB_OVERVIEW_REQUEST = 'GET_TTFB_OVERVIEW_REQUEST';
export const GET_TTFB_OVERVIEW_SUCCESS = 'GET_TTFB_OVERVIEW_SUCCESS';
export const GET_TTFB_OVERVIEW_FAILURE = 'GET_TTFB_OVERVIEW_FAILURE';

export const RESET_TTFB_OVERVIEW = 'RESET_TTFB_OVERVIEW';

// LEADS
export const CREATE_TTFB_LEAD_REQUEST = 'CREATE_TTFB_LEAD_REQUEST';
export const CREATE_TTFB_LEAD_SUCCESS = 'CREATE_TTFB_LEAD_SUCCESS';
export const CREATE_TTFB_LEAD_FAILURE = 'CREATE_TTFB_LEAD_FAILURE';

export const UPDATE_TTFB_LEAD_REQUEST = 'UPDATE_TTFB_LEAD_REQUEST';
export const UPDATE_TTFB_LEAD_SUCCESS = 'UPDATE_TTFB_LEAD_SUCCESS';
export const UPDATE_TTFB_LEAD_FAILURE = 'UPDATE_TTFB_LEAD_FAILURE';

export const GET_TTFB_LEAD_BY_ID_REQUEST = 'GET_TTFB_LEAD_BY_ID_REQUEST';
export const GET_TTFB_LEAD_BY_ID_SUCCESS = 'GET_TTFB_LEAD_BY_ID_SUCCESS';
export const GET_TTFB_LEAD_BY_ID_FAILURE = 'GET_TTFB_LEAD_BY_ID_FAILURE';

export const RESET_TTFB_LEAD_BY_ID = 'RESET_TTFB_LEAD_BY_ID';

// append to lead table
export const APPEND_TO_TTFB_PRODUCT_LEAD_TABLE = 'APPEND_TO_TTFB_PRODUCT_LEAD_TABLE';

// update lead table item
export const UPDATE_TTFB_PRODUCT_LEAD_ITEM = 'UPDATE_TTFB_PRODUCT_LEAD_ITEM';

export const DELETE_TTFB_LEAD_REQUEST = 'DELETE_TTFB_LEAD_REQUEST';
export const DELETE_TTFB_LEAD_SUCCESS = 'DELETE_TTFB_LEAD_SUCCESS';
export const DELETE_TTFB_LEAD_FAILURE = 'DELETE_TTFB_LEAD_FAILURE';

export const GET_TTFB_PRODUCT_LEADS_REQUEST = 'GET_TTFB_PRODUCT_LEADS_REQUEST';
export const GET_TTFB_PRODUCT_LEADS_SUCCESS = 'GET_TTFB_PRODUCT_LEADS_SUCCESS';
export const GET_TTFB_PRODUCT_LEADS_FAILURE = 'GET_TTFB_PRODUCT_LEADS_FAILURE';

export const RESET_TTFB_PRODUCT_LEADS = 'RESET_TTFB_PRODUCT_LEADS';

// LOGS
export const GET_TTFB_LEAD_LOGS_REQUEST = 'GET_TTFB_LEAD_LOGS_REQUEST';
export const GET_TTFB_LEAD_LOGS_SUCCESS = 'GET_TTFB_LEAD_LOGS_SUCCESS';
export const GET_TTFB_LEAD_LOGS_FAILURE = 'GET_TTFB_LEAD_LOGS_FAILURE';

export const CREATE_TTFB_LEAD_LOGS_COMMENT_REQUEST = 'CREATE_TTFB_LEAD_LOGS_COMMENT_REQUEST';
export const CREATE_TTFB_LEAD_LOGS_COMMENT_SUCCESS = 'CREATE_TTFB_LEAD_LOGS_COMMENT_SUCCESS';
export const CREATE_TTFB_LEAD_LOGS_COMMENT_FAILURE = 'CREATE_TTFB_LEAD_LOGS_COMMENT_FAILURE';

export const RESET_TTFB_LEAD_LOGS = 'RESET_TTFB_LEAD_LOGS';

export const APPEND_TO_TTFB_LEAD_LOG = 'APPEND_TO_TTFB_LEAD_LOG';

// Distributors
export const GET_TTFB_DISTRIBUTORS_REQUEST = 'GET_TTFB_DISTRIBUTORS_REQUEST';
export const GET_TTFB_DISTRIBUTORS_SUCCESS = 'GET_TTFB_DISTRIBUTORS_SUCCESS';
export const GET_TTFB_DISTRIBUTORS_FAILURE = 'GET_TTFB_DISTRIBUTORS_FAILURE';

export const RESET_TTFB_DISTRIBUTORS = 'RESET_TTFB_DISTRIBUTORS';

// Partners
export const GET_TTFB_PARTNERS_REQUEST = 'GET_TTFB_PARTNERS_REQUEST';
export const GET_TTFB_PARTNERS_SUCCESS = 'GET_TTFB_PARTNERS_SUCCESS';
export const GET_TTFB_PARTNERS_FAILURE = 'GET_TTFB_PARTNERS_FAILURE';

export const RESET_TTFB_PARTNERS = 'RESET_TTFB_PARTNERS';

// Manufacturers
export const GET_TTFB_MANUFACTURERS_REQUEST = 'GET_TTFB_MANUFACTURERS_REQUEST';
export const GET_TTFB_MANUFACTURERS_SUCCESS = 'GET_TTFB_MANUFACTURERS_SUCCESS';
export const GET_TTFB_MANUFACTURERS_FAILURE = 'GET_TTFB_MANUFACTURERS_FAILURE';

export const RESET_TTFB_MANUFACTURERS = 'RESET_TTFB_MANUFACTURERS';

// Products
export const GET_TTFB_PRODUCTS_REQUEST = 'GET_TTFB_PRODUCTS_REQUEST';
export const GET_TTFB_PRODUCTS_SUCCESS = 'GET_TTFB_PRODUCTS_SUCCESS';
export const GET_TTFB_PRODUCTS_FAILURE = 'GET_TTFB_PRODUCTS_FAILURE';

export const RESET_TTFB_PRODUCTS = 'RESET_TTFB_PRODUCTS';

export const GET_TTFB_PRODUCT_DETAILS_REQUEST = 'GET_TTFB_PRODUCT_DETAILS_REQUEST';
export const GET_TTFB_PRODUCT_DETAILS_SUCCESS = 'GET_TTFB_PRODUCT_DETAILS_SUCCESS';
export const GET_TTFB_PRODUCT_DETAILS_FAILURE = 'GET_TTFB_PRODUCT_DETAILS_FAILURE';

export const RESET_TTFB_PRODUCT_DETAILS = 'RESET_TTFB_PRODUCT_DETAILS';

// active products
export const GET_TTFB_ACTIVE_PRODUCT_REQUEST = 'GET_TTFB_ACTIVE_PRODUCT_REQUEST';
export const GET_TTFB_ACTIVE_PRODUCT_SUCCESS = 'GET_TTFB_ACTIVE_PRODUCT_SUCCESS';
export const GET_TTFB_ACTIVE_PRODUCT_FAILURE = 'GET_TTFB_ACTIVE_PRODUCT_FAILURE';

export const RESET_TTFB_ACTIVE_PRODUCT = 'RESET_TTFB_ACTIVE_PRODUCT';