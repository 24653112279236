import { GET_FEED_CARD_DETAILS_REQUEST,
    GET_FEED_CARD_DETAILS_SUCCESS,
    GET_FEED_CARD_DETAILS_FAILURE,
    RESET_FEED_CARD_DETAILS } from "../../actions/details/constants";
import { FOLLOW_USER_SUCCESS } from "../../actions/profile/constants";
import { FOLLOW_COMPANY_SUCCESS } from "../../actions/company/constants";

const INITIAL_STATE = {
    fetching: false,
    data: null,
    error: false
}

const updateFeedCardIsFollowing = (data, action) => {
    const isCurrentCompanyFollow = action.type === 'FOLLOW_COMPANY_SUCCESS'
        && data.meta.type
        && data.meta.type === 'company'
        && data.feedCard.company
        && data.feedCard.company.id === action.payload.companyId,
        isCurrentProfileFollow = action.type === 'FOLLOW_USER_SUCCESS'
            && data.meta.type
            && data.meta.type === 'profile'
            && data.feedCard.profile
            && data.feedCard.profile.id === action.payload.profileId;
    return {
        ...data,
        meta: {
            ...data.meta,
            isFollowing: (isCurrentProfileFollow || isCurrentCompanyFollow)
        }
    }
}

export default function feedCard(state = INITIAL_STATE, action) {
    switch(action.type) {
        case GET_FEED_CARD_DETAILS_REQUEST:
            return {
                ...state,
                fetching: true
            }
        case GET_FEED_CARD_DETAILS_SUCCESS:
            return {
                ...state,
                fetching: false,
                data: action.payload
            }
        case GET_FEED_CARD_DETAILS_FAILURE:
            return {
                ...state,
                fetching: false,
                ...(!action.error.__CANCEL__ && {
                    error: true,
                    errorMsg: action.error
                })
            }
        case FOLLOW_USER_SUCCESS:
        case FOLLOW_COMPANY_SUCCESS:
            return {
                ...state,
                ...(state.data && {
                    data: updateFeedCardIsFollowing(state.data, action)
                })
            }
        case RESET_FEED_CARD_DETAILS:
            return INITIAL_STATE;
        default:
            return state;
    }
}