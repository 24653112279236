import React from 'react';
import { Redirect,
        Switch } from 'react-router-dom';

function RedirectionRoute({ from, to }) {
    return <Switch>
        <Redirect from={from} to={to}/>
    </Switch>
};

export default RedirectionRoute;