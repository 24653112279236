import React, { useState, useEffect } from 'react'
import Modal from './Modal'
import { connect } from 'react-redux'
import { toggleSuccessOtpModal, toggleOtpModal } from '../../../actions/common/modals';
import { genericErrorText, otpLength } from '../../../utils/commonVariables';
import {
    getTwoDigits, handleAuthState,
    isValidNumber,
} from "../../../utils/helper";
import get from "lodash/get";
import { changePassword, changePasswordVerifyOtp } from '../../../actions/settings/changePassword';
import { logoutUserLocally } from '../../../actions/login/logout';
import { format, addSeconds } from 'date-fns';
import { verifySurveyPreview } from '../../../actions/survey/preview';
import CloseIcon from "../../../assets/images/common/modals/close.svg";

const OtpInput = (props) => {
    const { isPreview, OtpError, handleChangeOtp, canResendOtp, otpSeconds, Otp, resendOtpMail } = props;
    return (
        <div className={`login-form__form__group ${isPreview ? "isPreview" : ""}`}>
            <input
                type="text"
                placeholder="OTP"
                autoFocus
                value={Otp}
                maxLength={otpLength}
                onChange={(e) => { handleChangeOtp(e) }}
                className={`login-form__form__input1 ${OtpError ? "red" : null}`}
            />
            {OtpError && <p className="error-text">{OtpError}</p>}
            {!isPreview && (!OtpError || !canResendOtp) && <p className="login-form__otp-form--time">
                {format(new Date(1970, 0, 1, 0, 0, otpSeconds), 'mm:ss')}
            </p>}
            {!isPreview && <><p className="login-form__otp-form--time">Didn’t receive the code? <button
                    className="login-form__otp-form--resend_button" style={{
                        color: canResendOtp ? "rgba(73, 144, 226, 1)" : "rgba(146, 188, 238, 1)"
                    }} disabled={!canResendOtp} onClick={resendOtpMail}>Resend now</button></p>
                    <hr className='hr' /></>}
        </div>
    )
}

const PreviewHeader = (props) => {
    const { handlePreviewClose } = props;
    return (
        <>
            <h6 className="title">Enter OTP</h6>
            <button
                type="button"
                className="default-modal__header__close"
                onClick={handlePreviewClose}
            >
                <img src={CloseIcon} alt="close modal" />
            </button>
            <p className="sub-title">Please enter the one-time password (OTP) that was sent to your registered email. This is a confidential information that is required to access the questionnaire. </p>
        </>
    )
}

function CommonOtpModal(props) {
    const { toggleSuccessOtpModal, toggleOtpModal, user: { user: { profile, email } },
        token, profileModals, isPreview, handlePreview, emailPreview, surveyId, handlePreviewClose } = props;
    const [Otp, setOtp] = useState(""),
        [OtpError, setOtpError] = useState(''),
        [canResendOtp, setCanResendOtp] = useState(false),
        [otpSeconds, setOtpSeconds] = useState(60);

    const handleProceed = async () => {
        await toggleSuccessOtpModal("loggedIn");
        toggleOtpModal(profileModals.otpModal.type);
    }

    // submit function for preview otp modal
    const handlePreviewSubmit = () => {
        const dummyData = {
            "email": emailPreview,
            "otp": Otp
        }
        verifySurveyPreview(dummyData, surveyId).then(res => {
            const isError = get(res, "errors.status", false)
            if (!!res && !isError) {
                handlePreview(res.data.surveys);
                setOtpError("");
            } else {
                const errorText = get(res, "errors.display_message", genericErrorText.general);
                setOtpError(errorText)
            }
        }).catch((err) => {
            setOtpError("Something Went Wrong!")
        })
    }

    // submit function for common otp modal
    const handleSubmit = () => {
        const dataToSend = {
            "email": email,
            ...(profile.phone && !!profile.verified_phone && { "phone": profile.phone }),
            "otp": Otp
        }
        changePasswordVerifyOtp(dataToSend, token)
            .then((val) => {
                if (!!val.data) {
                    handleProceed();
                    setOtpError("");
                } else {
                    const { status, display_message } = val.errors;
                    const errMsg = display_message ? display_message : "Something Went Wrong!";
                    if (!!status) {
                        setOtpError(errMsg);
                    }
                }
            }).catch((err) => {
                setOtpError("Something Went Wrong!")
            })
    }

    const resendOtpMail = () => {
        if (!canResendOtp) return;
        const { email, token } = props;
        let dataToSend = {
            "password": profileModals.otpModal.password
        }
        changePassword(dataToSend, token)
            .then(val => {
                if (!!val && !!val.data) {
                    setOtpSeconds(60)
                    setCanResendOtp(false)
                    setOtpError("");
                    setOtp("");
                } else {
                    const { status, display_message } = val.errors;
                    const errMsg = display_message ? display_message : "Something Went Wrong!"
                    if (!!status) {
                        setOtpError(errMsg);
                    }
                }
            }).catch((err) => {
                setOtpError("Something Went Wrong!");
            })
    }
    const handleClose = async () => {
        await toggleOtpModal("changepassword");
    }

    const handleChangeOtp = (e) => {
        setOtp(isValidNumber(e.target.value.slice(0, 6)));
        setOtpError("");
    }
    useEffect(() => {
        document.body.style.overflow = "hidden"
        const interval = setInterval(() => {
            if (otpSeconds === 0) {
                clearInterval(interval);
                setCanResendOtp(true);
                setOtpError("");
                return null
            } else {
                setOtpSeconds(otpSeconds - 1);
            }
        }, 1000);
        return () => {
            clearInterval(interval);
            document.body.style.overflow = "auto"
        };
    }, [otpSeconds]);

    return (
        <Modal
            title={"Enter OTP"}
            showHeader={!isPreview}
            handleClose={handleClose}
            containerClassName="common-otp-modal"
        >
            <div className={`not-center-content ${isPreview ? "isPreview" : ""}`}>
                {!!isPreview &&
                    <PreviewHeader {...props} handlePreviewClose={handlePreviewClose} />
                }
                {!isPreview && <p className="login-form__otp-form--confirm_title">
                    Confirm that this Email and phone number belongs to you: Enter the OTP sent to
                    <span className='color'>{" " + email}</span> {profile.phone && profile.verified_phone && <span className='color'><span style={{ color: "rgba(116, 116, 116, 1)" }}>and</span>  {profile.country_code + " " + profile.phone}</span>}</p>}
                <OtpInput
                    {...props}
                    handleChangeOtp={handleChangeOtp}
                    Otp={Otp}
                    OtpError={OtpError}
                    canResendOtp={canResendOtp}
                    otpSeconds={otpSeconds}
                    resendOtpMail={resendOtpMail}
                />
                <button className={`login-form__otp-form--continue_button ${isPreview ? "isPreview" : ""} ${Otp.length < 6 ? "disabled" : ""}`} disabled={Otp.length < 6} onClick={!!isPreview ? handlePreviewSubmit : handleSubmit}>{!!isPreview ? "Continue" : "Submit"}</button>
            </div>
        </Modal>
    )
}

function mapStateToProps(state) {
    return {
        token: state.login.token,
        user: state.user,
        profileModals: state.modalReducer.profileModals
    }
}

const mapDispatchToProps = {
    toggleSuccessOtpModal,
    toggleOtpModal,
    logoutUserLocally
}

export default connect(mapStateToProps, mapDispatchToProps)(CommonOtpModal) 