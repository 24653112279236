import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Avatar from '../user/Avatar';
import { profileLinks } from '../../utils/internalLinks';
import Slider from 'react-slick';
import ChevronRight from '../../assets/images/photos/chevron-right.svg';
import Check from '../../assets/images/common/suggestions/check.svg';
import { followUser } from '../../actions/profile/follow';
import { screenBreakpoints } from '../../utils/commonVariables';
import { decodeString } from '../../utils/helper';
import UserName from '../profile/UserName';
import SuperTaster from '../misc/SuperTaster';

const sliderSettings = {
    dots: false,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: (<SliderArrow to="prev" />),
    nextArrow: (<SliderArrow to="next" />),
}

function SliderArrow(props){
    const { onClick,
    to,
    currentSlide,
    slideCount } = props,
    isDisabled = ((to === 'prev' && currentSlide === 0) || (to === 'next' && currentSlide === slideCount - 3));
    return (<button aria-hidden="true"
        aria-disabled={isDisabled}
        type="button"
        disabled={isDisabled}
        className={`suggestions__arrow suggestions__arrow--${to} ${isDisabled ? 'suggestions__arrow--disabled' : ''}`}
        onClick={onClick}>
            <img className={`suggestions__arrow__icon suggestions__arrow__icon--${to}`}
                src={ChevronRight} alt="go to next slide" />
    </button>)
}

const ProfileSuggestionItem = (props) => {
    const { profile,
        handleFollow,
        followRequest } = props;
    if (!profile) return null;
    const imageToShow = profile.image_meta
            ? JSON.parse(profile.image_meta).original_photo
            : '',
        name = decodeString(profile.name),
        isSuperTaster = !!profile.is_tasting_expert,
        handle = profile.handle,
        isFollowing = profile.isFollowing;
    return <div className="suggestions__profile">
        <Link to={profileLinks.view(handle)}>
            { isSuperTaster && <SuperTaster className="suggestions__profile__badge" isSmall={true} />}
            <Avatar alt={name} className="suggestions__profile__img"
                src={imageToShow} />
        </Link>
        <div className="suggestions__profile__info">
            <Link to={profileLinks.view(handle)} className={`suggestions__profile__name ${!!profile.verified && 'suggestions__profile__name--verified'}`}>
                <UserName isVerified={!!profile.verified}
                    name={name} />
            </Link>
            {
                !isFollowing
                ? <button disabled={followRequest}
                    className={`suggestions__follow-btn ${followRequest ? 'suggestions__follow-btn--disabled' : ''}`}
                    onClick={() => handleFollow(profile)}>+ FOLLOW</button>
                : <p className="suggestions__following"><img src={Check} alt="is following" />Following</p>
            }
        </div>
    </div>
}

function ProfileSuggestion(props) {
    const [suggestions, setSuggestions] = useState(props.suggestions || []),
        [subTitle, setSubTitle] = useState(props.subTitle || 'Suggested for you'),
        [token, setToken] = useState(props.token),
        [followRequest, setFollowRequest] = useState(false),
        [isMobile] = useState(window.innerWidth < screenBreakpoints.mob);
    function _handleFollow(profile) {
        setFollowRequest(true);
        props.followUser(profile.id, token)
        .then(_ => {
            setFollowRequest(false);
        })
    }
    useEffect(() => {
        setSuggestions(props.suggestions);
        setSubTitle(props.subTitle);
        setToken(props.token)
    }, [props.suggestions, props.title, props.subTitle, props.token]);
    if (!suggestions.length) return null;
    return (<div>
            <div className="suggestions__info">
                <p className="suggestions__info__title">{subTitle}</p>
            </div>
            {
                isMobile
                ? <div className="suggestions suggestions--profile">
                    {
                        suggestions.map((_, i) => {
                            return <ProfileSuggestionItem profile={_}
                                handleFollow={_handleFollow}
                                followRequest={followRequest}
                                key={`profile-suggestion-${_.id}-${i}`}  />
                        })
                    }
                </div>
                : <Slider {...sliderSettings} className="suggestions">
                    {
                        suggestions.map((_, i) => {
                            return <ProfileSuggestionItem profile={_}
                            followRequest={followRequest}
                            handleFollow={_handleFollow}
                            key={`profile-suggestion-${_.id}-${i}`}  />
                        })
                    }
                </Slider>
            }
        </div>)
};

function mapStateToProps(state) {
    return {
        token: state.login.token
    }
}

export default connect(mapStateToProps, { followUser })(ProfileSuggestion);