import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PlacesAutocomplete from 'react-places-autocomplete';

function GooglePlacesAutoComplete(props) {
  const { location,
    searchOptions = {},
    handleLocationChange,
    className,
    suggestionContainerClassName,
    suggestionClassName
  } = props;

  const [address, setAddress] = useState(location);

  const handleChange = (address) => {
    setAddress(address);
  };

  const handleBlur = (event, suggestions) => {
    if (address === location) return;
    let isValidSelection;
    if (suggestions && suggestions.length) {
      isValidSelection = suggestions.some(
        suggestion => suggestion.description.split(',')[0] === event.target.value
      );
    }
    if (!isValidSelection) {
      handleLocationChange('');
      setAddress('');
    }
  };

  const handleSelect = (address) => {
    setAddress(address);
    handleLocationChange(address);
  };


  const classNameToAdd = className || '';

  useEffect(() => {
    if (location) {
      setAddress(location)
    }
  }, [location]);

  return (
    <PlacesAutocomplete
      value={address}
      highlightFirstSuggestion={true}
      onSelect={(address) => handleSelect(address.split(',')[0])}
      searchOptions={searchOptions}
      required
      onChange={(address) => handleChange(address.split(',')[0])}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          <input
            {...getInputProps({
              placeholder: 'Eg. New Delhi',
              className: `places-id location-search-input ${classNameToAdd}`,
              onBlur: (event) => handleBlur(event, suggestions)
            })}
          />
          {suggestions && suggestions.length ? (
            <div
              className={`autocomplete-dropdown-container ${suggestionContainerClassName || ''
                }`}
            >
              {loading && <div className={suggestionClassName}>Loading...</div>}
              {suggestions.map((suggestion, index) => {
                const keyName = `${suggestion.description}-${index}`,
                  className = suggestion.active
                    ? `suggestion-item--active ${suggestionClassName}`
                    : `suggestion-item ${suggestionClassName}`;
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                    key={keyName}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>
      )}
    </PlacesAutocomplete>
  );
}

GooglePlacesAutoComplete.propTypes = {
  className: PropTypes.string,
  suggestionContainerClassName: PropTypes.string,
  suggestionClassName: PropTypes.string,
  handleLocationChange: PropTypes.func,
  location: PropTypes.string,
  searchOptions: PropTypes.object,
};

export default GooglePlacesAutoComplete;
