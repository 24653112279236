import {
  CREATE_A_POST_REQUEST,
  CREATE_A_POST_SUCCESS,
  CREATE_A_POST_FAILURE,
  UPLOAD_PHOTO_REQUEST,
  UPLOAD_VIDEO_REQUEST,
  UPLOAD_VIDEO_SUCCESS,
  UPLOAD_VIDEO_FAILURE,
  CREATE_A_PHOTO_POST_REQUEST,
  CREATE_A_PHOTO_POST_SUCCESS,
  CREATE_A_PHOTO_POST_FAILURE,
} from "./constants";
import {
  onRequest,
  onSuccess,
  onError,
  createAPostMeta,
  twoDecimal,
} from "../../utils/helper";
import { getBaseUrl, postUrls, photoUrls, videoUrls } from "../../utils/links";
import Axios from "axios";
import {
  updateUploadingRequest,
  resetUploadingRequest,
} from "../feed/uploading";
const CancelToken = Axios.CancelToken;

export let createAPostCancelRequest = undefined;
export const createAPost = (data, token, creatorProfile) => {
  return (dispatch) => {
    if (createAPostCancelRequest) createAPostCancelRequest();
    dispatch(onRequest(CREATE_A_POST_REQUEST));
    let url = `${getBaseUrl()}${postUrls.createShoutout}`,
      options = {
        url,
        method: "POST",
        data,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        cancelToken: new CancelToken((c) => (createAPostCancelRequest = c)),
        onUploadProgress: (progressEvent) => {
          if (progressEvent.lengthComputable) {
            let percentage = twoDecimal(
                (progressEvent.loaded / progressEvent.total) * 100
              ),
              type = "create-post";
            dispatch(updateUploadingRequest(type, percentage));
          }
        },
      };
    return Axios(options)
      .then((response) => {
        let successData =
          response && response.data && response.data.data
            ? response.data.data
            : false;
        if (successData) {
          let dataToSend = {
            shoutout: successData,
            meta: createAPostMeta(), // Creating fake meta because backend doesn't provide
            ...creatorProfile,
            type: "shoutout",
          };
          dispatch(onSuccess(CREATE_A_POST_SUCCESS, dataToSend));
        } else {
          dispatch(onError(CREATE_A_POST_FAILURE, response.data.error));
        }
        dispatch(resetUploadingRequest());
        return successData;
      })
      .catch((error) => {
        dispatch(onError(CREATE_A_POST_FAILURE, error));
        dispatch(resetUploadingRequest());
        return false;
      });
  };
};

export let globalUploadPhotoWithoutActionCancelToken = undefined;
export const globalUploadPhotoWithoutAction = (data, token) => {
  let url = `${getBaseUrl()}${photoUrls.globalUpload}`,
    options = {
      url,
      data,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
      cancelToken: new CancelToken(
        (c) => (globalUploadPhotoWithoutActionCancelToken = c)
      ),
    };
  return Axios(options)
    .then((response) => {
      let successData =
        response && response.data && response.data.data
          ? response.data.data
          : false;
      return successData;
    })
    .catch((_) => {
      return false;
    });
};

export const globalUploadPhoto = (data,
    token) => {
    return dispatch => {
        dispatch(onRequest(UPLOAD_PHOTO_REQUEST));
        let url = `${getBaseUrl()}${photoUrls.globalUpload}`,
            options = {
                url,
                data,
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            }
        return Axios(options)
            .then(response => {
                let successData = response
                    && response.data
                    && response.data.data
                    ? response.data.data
                    : false;
                return successData;
            })
            .catch(_ => {
                return false;
            })
    }

}

export const createAPhotoPost = (data,
    token,
    creatorProfile) => {
    return dispatch => {
        dispatch(onRequest(CREATE_A_PHOTO_POST_REQUEST));
        let url = `${getBaseUrl()}${photoUrls.createPhotoPost}`,
            options = {
                url,
                method: 'POST',
                data,
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            };
        return Axios(options)
            .then((response) => {
                let successData = response
                    && response.data
                    && response.data.data
                    ? response.data.data
                    : false;
                if (successData) {
                    let dataToSend = {
                        photo: successData,
                        meta: createAPostMeta(), // Creating fake meta because backend doesn't provide
                        ...creatorProfile,
                        type: 'photo'
                    }
                    dispatch(onSuccess(CREATE_A_PHOTO_POST_SUCCESS, dataToSend));
                } else {
                    dispatch(onError(CREATE_A_PHOTO_POST_FAILURE, response.data.error));
                }
                return successData;
            })
            .catch(error => {
                dispatch(onError(CREATE_A_PHOTO_POST_FAILURE, error));
                return false;
            })
    }
}

export let globalVideoUploadCancelRequest = undefined;
export const globalUploadVideo = (data, type,
    token) => {
    return dispatch => {
      if (globalVideoUploadCancelRequest) globalVideoUploadCancelRequest();
        dispatch(onRequest(UPLOAD_VIDEO_REQUEST));
        let url = `${getBaseUrl()}${videoUrls.globalUpload(type)}`,
            options = {
                url,
                data,
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                cancelToken: new CancelToken((c) => globalVideoUploadCancelRequest = c),
                onUploadProgress: (progressEvent) => {
                    if (progressEvent.lengthComputable) {
                        let percentage = twoDecimal((progressEvent.loaded / progressEvent.total) * 100),
                            type = 'video';
                        dispatch(updateUploadingRequest(type, percentage));
                    }
                }
            }
        return Axios(options)
            .then(response => {
                let successData = response
                    && response.data
                    && response.data.data
                    ? response.data.data
                    : false;
                dispatch(onSuccess(UPLOAD_VIDEO_SUCCESS, successData));
                dispatch(resetUploadingRequest());
                return successData;
            })
            .catch(_ => {
              if (Axios.isCancel(_)) {
                console.log('Request was cancelled by the user:', _.message);
              } else {
                dispatch(onError(UPLOAD_VIDEO_FAILURE, _));
                dispatch(resetUploadingRequest());
              }
              return false
            })
    }

}

export let globalUploadVideoWithoutActionCancelToken = undefined;
export const globalUploadVideoWithoutAction = (data, type,
    token) => {
    let url = `${getBaseUrl()}${videoUrls.globalUpload(type)}`,
        options = {
            url,
            data,
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            cancelToken: new CancelToken((c) => globalUploadVideoWithoutActionCancelToken = c)
        }
    return Axios(options)
        .then(response => {
            let successData = response
                && response.data
                && response.data.data
                ? response.data.data
                : false;
            return successData;
        })
        .catch(_ => {
            return false;
        });
}
