// GET ALL SETTINGS REQUEST
export const GET_SETTINGS_REQUEST = 'GET_SETTINGS_REQUEST';
export const GET_SETTINGS_SUCCESS = 'GET_SETTINGS_SUCCESS';
export const GET_SETTINGS_FAILURE = 'GET_SETTINGS_FAILURE';
// CHANGE PASSWORD
export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';

export const POST_SETTINGS_REQUEST = 'POST_SETTINGS_REQUEST';
export const POST_SETTINGS_SUCCESS = 'POST_SETTINGS_SUCCESS';
export const POST_SETTINGS_FAILURE = 'POST_SETTINGS_FAILURE';

export const GET_COMPANY_SETTINGS_REQUEST = 'GET_COMPANY_SETTINGS_REQUEST';
export const GET_COMPANY_SETTINGS_SUCCESS = 'GET_COMPANY_SETTINGS_SUCCESS';
export const GET_COMPANY_SETTINGS_FAILURE = 'GET_COMPANY_SETTINGS_FAILURE';
