import { SEND_DIRECT_MESSAGE_REQUEST,
    SEND_DIRECT_MESSAGE_SUCCESS,
    SEND_DIRECT_MESSAGE_FAILURE } from './constants';
import { ChatUrls, getBaseUrl } from '../../utils/links';
import { onRequest,
    getResponseData,
    onSuccess,
    onError } from '../../utils/helper';
import axios from 'axios';
const CancelToken = axios.CancelToken;

export let sendDirectMessageCancelToken = undefined;
export const sendDirectMessage = (data, token) => {
    return dispatch => {
        if (sendDirectMessageCancelToken) sendDirectMessageCancelToken();
        dispatch(onRequest(SEND_DIRECT_MESSAGE_REQUEST));
        let url = `${getBaseUrl()}${ChatUrls.sendDirectMessage}`,
            options = {
                url,
                method: 'POST',
                data,
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                cancelToken: new CancelToken((c) => sendDirectMessageCancelToken = c)
            };
        return axios(options)
            .then(response => {
                let successData = getResponseData(response);
                if (successData) {
                    dispatch(onSuccess(SEND_DIRECT_MESSAGE_SUCCESS));
                } else {
                    dispatch(onError(SEND_DIRECT_MESSAGE_FAILURE, response.data.errors));
                }
                return successData;
            })
            .catch(error => {
                dispatch(onError(SEND_DIRECT_MESSAGE_FAILURE, error));
                return false;
            })
    }
}