import React from "react";
import PropTypes from "prop-types";
import LoadingImage from "../../assets/images/common/icons/loader.svg";
import LoadingImageWhite from "../../assets/images/common/icons/loader-white.svg";

function Loader({ className, isCentered, isWhite, withMargin, isBothCentered }) {
  return (
    <div
      className={`generic-loader
        ${className || ""}
        ${isCentered ? "generic-loader--centered" : ""}
        ${withMargin ? "generic-loader--with-margin" : ""}
        ${isBothCentered ? 'generic-loader--centered-both' : ""}`}
    >
      <img src={isWhite ? LoadingImageWhite : LoadingImage} alt="loading..." />
    </div>
  );
}

Loader.propTypes = {
  className: PropTypes.string,
  isCentered: PropTypes.bool,
  withMargin: PropTypes.bool,
  isWhite: PropTypes.bool,
};

export default Loader;
