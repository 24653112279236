import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import Modal from '../../components/modals/common/Modal';
import ChevronRight from '../../assets/images/business/chevron-right.svg';
import BeginIcon from '../../assets/images/collaborations/view/multimedia-music-player-play-button.svg';
import CompleteIcon from '../../assets/images/collaborations/view/check.svg';
import ResumeIcon from '../../assets/images/collaborations/view/fast-forward.svg';
import { getProductReviewStatusText } from '../../utils/collaborationHelper';
import { collaborationInternalLinks } from '../../utils/internalLinks';
import { decodeString } from '../../utils/helper';
import { showProfilePassbookModal } from '../../actions/common/modals';
import { startPrivateReview } from '../../actions/products';

const AvailableProductsModal = (props) => {
  const { history, closeModal, data, showProfilePassbookModal, token, handleClickButton, startPrivateReview } = props;
  const [showConsistencyModal, setShowConsistencyModal] = useState(false);
  const [activeBatch, setActiveBatch] = useState(null);

  const productsWithBatches = data.filter((obj) => get(obj, 'batches', []).length > 0);
  const btnIcons = {
    begin: BeginIcon,
    complete: CompleteIcon,
    resume: ResumeIcon,
  };

  return (
    <Modal
      showHeader
      className="home__productmodal"
      handleClose={closeModal}
      title="Assigned Products"
    >
      <div className="home__productmodal__content">
        <p className="home__productmodal__content__desc">
          Following is the list of products which are waiting for your feedback
        </p>

        <div className="home__productmodal__content__products">
          <p className="home__productmodal__content__products__title">Products</p>

          {productsWithBatches.map((collaboration, i) => {
            const prLink = collaborationInternalLinks.productReviewPage(get(collaboration, 'id'));
            const collaborationId = collaboration.id;
            return (
              <div key={i} className="home__productmodal__content__products__item">
                <Link className="home__productmodal__content__products__item__title" to={prLink}>
                  <p className="home__productmodal__content__products__item__title__text">
                    {decodeString(get(collaboration, 'title', ''))}
                  </p>
                  <img
                    src={ChevronRight}
                    className="home__productmodal__content__products__item__title__icon"
                    alt="right arrow"
                  />
                </Link>

                {get(collaboration, 'batches', []).map((batch, j) => {
                  const createdDate = get(batch, 'created_at', '')
                    ? format(parseISO(batch.created_at), 'dd MMM yyyy')
                    : '';

                  const isPaid = get(batch, 'isPaid');
                  const btnText = getProductReviewStatusText(batch.current_status);
                  const imageIcon = btnIcons[btnText];
                  const link = collaborationInternalLinks.tastingProcess(collaborationId, batch.id);

                  return (
                    <div key={j} className="home__productmodal__content__products__item__content">
                      <div
                        className="pr-manage__sidebar__list__box home__productmodal__content__products__item__content__color"
                        style={{ backgroundColor: get(batch, 'color.name') }}
                      />
                      <div className="home__productmodal__content__products__item__content__batch">
                        <p className="home__productmodal__content__products__item__content__batch__title">
                          {decodeString(get(batch, 'name', ''))}
                        </p>
                        <p className="home__productmodal__content__products__item__content__batch__date">
                          {`Created on ${createdDate}`}
                        </p>
                      </div>
                      <button
                        className={`home__productmodal__content__products__item__content__batch__button samples-modal__btn ${btnText === 'complete' ? 'samples-modal__btn--secondary' : ''
                          }`}
                        onClick={() => {
                          if (btnText === 'complete') return;
                          // to do - check if its consistency app and show select city modal
                          if (
                            (btnText === 'begin' || (btnText === 'resume' && !batch.address_id)) &&
                            !!collaboration.track_consistency &&
                            collaboration.product_review_type &&
                            !!collaboration.product_review_type.city_required
                          ) {
                            // handleConsistencyShow(batch);
                            setActiveBatch(batch);
                            setShowConsistencyModal(true);
                            return;
                          }
                          if (
                            btnText === 'resume' &&
                            !!batch.address_id &&
                            !!collaboration.track_consistency &&
                            collaboration.product_review_type &&
                            !!collaboration.product_review_type.city_required
                          ) {
                            const link = collaborationInternalLinks.tastingProcess(
                              collaborationId,
                              batch.id,
                            );
                            history.push({
                              pathname: link,
                              search: `?address_id=${batch.address_id}`,
                              state: { addressId: batch.address_id },
                            });
                            return;
                          }
                          startPrivateReview(token, collaborationId, batch.id).then((res) => {
                            if (res && !!res.data.status) {
                              history.push(link);
                            } else if (res && !!res.errors.status) {
                              let { errors } = res;
                              const directLink = collaborationInternalLinks.productReviews;
                              alert(errors && errors.display_message || "Something went wrong");
                              return history.replace(directLink)
                            } else {
                              handleClickButton();
                            }
                          })
                        }}
                      >
                        {imageIcon && <img src={imageIcon} alt={btnText} />}
                        {btnText}
                      </button>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </Modal>
  );
};

AvailableProductsModal.propTypes = {
  history: PropTypes.object,
  closeModal: PropTypes.func,
  data: PropTypes.array,
  showProfilePassbookModal: PropTypes.func,
};

const mapDispatchToProps = {
  showProfilePassbookModal,
  startPrivateReview
};

export default withRouter(connect(null, mapDispatchToProps)(AvailableProductsModal));
