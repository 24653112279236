import { feedPostTypes } from "./commonVariables";

export const _handleReportedContent = (feedCard, payload) => {
    // not handling advertisement or suggestions card
    if (feedCard.type === feedPostTypes.advertisement
        || feedCard.type === feedPostTypes.suggestion) {
        return true;
    }
    // not handling data mismatch
    if ((payload.is_shared && !feedCard.shared) // share mismatch
        || (feedCard.shared && !payload.is_shared) // share mismatch
        || (feedCard.shared
            && payload.is_shared
            && payload.shared_id
            && feedCard.shared.id
            && feedCard.shared.id !==  payload.shared_id) // share mismatch
        || (!feedCard.shared
            && !payload.is_shared
            && feedCard.type !== payload.data_type) // different data types
        || (!feedCard.shared
            && !payload.is_shared
            && feedCard.type === payload.data_type
            && feedCard[feedCard.type].id !== parseInt(payload.data_id))) {
        return true;
    }
    return false
}