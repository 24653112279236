import React from 'react';
import PropTypes from 'prop-types';
import ImageOptionItem from './ImageOption';

function ImageOptions(props) {
  const { options, handleRemoveOptions, isEdit, disabled, handleOptionsChange, editablePost } = props;
  if (!options || (options && !options.length)) return null;
  const optionItems = options.map((option, index) => (
    <ImageOptionItem
      key={`poll-option-${index}`}
      handleOptionsChange={handleOptionsChange}
      handleRemoveOptions={handleRemoveOptions}
      isEdit={isEdit}
      disabled={disabled}
      index={index}
      option={option}
      editablePost={editablePost}
      options={options}
    />
  ));
  return <ul className="poll-create__image__option__container">{optionItems}</ul>;
}

ImageOptions.propTypes = {
  options: PropTypes.array,
  handleOptionsChange: PropTypes.func,
  handleRemoveOptions: PropTypes.func,
  isEdit: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default ImageOptions;
