import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import DefaultAvatar from '../../assets/images/default/avatar.jpg';
import CompanyAvatar from '../../assets/images/default/default_company_avatar.jpg';
import Supartaster from '../../assets/images/collaborations/pr/manage/supertaster.svg';
import SuperTaster from '../misc/SuperTaster';

const Avatar = (props) => {
  const { src, alt, className, smallSuperTaster, noSuperTaster, is_tasting_expert, isCompany } =
    props;
  const profile = get(props, 'profile', {});
  const isSuperTaster = is_tasting_expert || get(profile, 'is_tasting_expert', 0);
  const isSmallSuperTaster = smallSuperTaster
    ? 'useravatar__icon useravatar__icon__small'
    : 'useravatar__icon';
  if (noSuperTaster) {
    return (
      <img
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = isCompany ? CompanyAvatar : DefaultAvatar;
        }}
        className={className}
        src={src || (isCompany ? CompanyAvatar : DefaultAvatar)}
        alt={alt}
      />
    );
  } else {
    return (
      <div className="useravatar">
        {!!isSuperTaster && !isCompany && (
          //   <abbr title="SuparTaster">
          <img src={Supartaster} alt="supartaster" className={isSmallSuperTaster} />
          //   </abbr>
        )}
        <img
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = isCompany ? CompanyAvatar : DefaultAvatar;
          }}
          className={className}
          src={src || (isCompany ? CompanyAvatar : DefaultAvatar)}
          alt={alt}
        />
      </div>
    );
  }
};

Avatar.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
  isCompany: PropTypes.bool,
  smallSuperTaster: PropTypes.bool,
  noSuperTaster: PropTypes.bool,
};

export default Avatar;
