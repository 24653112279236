import React, { Component } from 'react';
import { connect } from 'react-redux';
import SeoHeadMeta from '../../components/misc/SeoHeadMeta';
import { careerLinks, miscLinks } from '../../utils/internalLinks';
import CareerModal from './modals/Career';
class Careers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        };
        this.toggleModal = this.toggleModal.bind(this);
        this.init = this.init.bind(this);
    }

    componentDidMount() {
        this.init();
    }

    init() {
        const { careers, match: { params: { type , url } } } = this.props;
        if (type && url && careers[type].filter((career) => career.url === url).length) {
            this.setState({
                isOpen: true,
                selectedJob: careers[type].filter((career) => career.url === url)[0]
            })
        } else {
            this.setState({
                isOpen: false,
                selectedJob: null
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.url !== prevProps.match.url) {
            this.init();
        }
    }

    toggleModal(type, url){
        if (this.state.isOpen) {
            this.props.history.push(miscLinks.career);
        } else {
            this.props.history.push(careerLinks(type, url))
        }
    }

    componentWillUnmount() {
        document.querySelector('body').classList.remove('overflow-hidden')
    }

    render() {
        const { fulltime, internship } = this.props.careers,
            { selectedJob } = this.state;
        return(
            <div className='career-page'>
                {
                    selectedJob
                    ? <SeoHeadMeta title={`TagTaste | Career | ${selectedJob.designation}`}>
                        <title>TagTaste | Career | {selectedJob.designation}</title>
                    </SeoHeadMeta>
                    : <SeoHeadMeta title="TagTaste | Career">
                        <title>TagTaste | Career</title>
                    </SeoHeadMeta>
                }
                <div className='career-image-container'>
                    <p className='career-image-text'>JOIN OUR TEAM</p>
                </div>
                <div className='career-content'>
                    <h2 className='career-title'>Because…</h2>
                    <p className=' career-description career-description--with-margin'>We're excited to share our passion for food and technology with you! We've built TagTaste to help food professionals realise their full potential. Our platform is designed for users to quickly search other food professionals, to collaborate with them and to reach existing and new customers digitally.</p>
                    <h2 className='career-subhead'>Full Time Positions</h2>
                    <ul className='career-list'>
                        {
                            fulltime.map((career, careerIndex) => {
                                return(
                                    <li key={careerIndex} className='career-list-item' onClick={() => this.toggleModal('fulltime', career.url)}>
                                        <div className='career-designation'>
                                            <div>
                                                <p className='designation-name'>{`${career.designation}`}</p>
                                                <p className='designation-description'>
                                                    <span>{`${career.location}`}</span>
                                                    {
                                                        career.experience
                                                        ? (<>
                                                            <span className="designation-description-divider"> &bull; </span>
                                                            <span>{`${career.experience}`}</span>
                                                        </>)
                                                        : (null)
                                                    }
                                                </p>
                                            </div>
                                            <div><button className='apply-button'><span>Apply</span></button></div>
                                        </div>
                                    </li>
                                )
                            })
                        }
                    </ul>
                    <h2 className='career-subhead second-head'>Internship Positions</h2>
                    <ul className='career-list'>
                        {
                            internship.map((career, careerIndex) => {
                                return(
                                    <li key={careerIndex} className='career-list-item' onClick={() => this.toggleModal('internship', career.url)}>
                                        <div className='career-designation'>
                                            <div>
                                                <p className='designation-name'>{`${career.designation}`}</p>
                                                <p className='designation-description'><span>{`${career.location}`}</span></p>
                                            </div>
                                            <div><button className='apply-button'><span>Apply</span></button></div>
                                        </div>
                                    </li>
                                )
                            })
                        }
                    </ul>
                    {

                        this.state.isOpen
                        && (<CareerModal
                            onClose={this.toggleModal}
                            selectedJob={this.state.selectedJob} />)
                    }
                </div>
            </div>
        )
    }
}
//export default Career;
function mapStateToProps(state) {
    return {
        careers: state.careers
    }
}
export default connect(mapStateToProps)(Careers);