import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import UserBar from '../common/UserBar';
import { Link } from 'react-router-dom';
import { productHelper, twoDecimal } from '../../../../utils/helper';
import ProductCheck from '../../../../assets/images/products/check.svg';
import ProductStar from '../../../../assets/images/products/star-favorite.svg';
import ProductPlaceholder from '../../../../assets/images/products/product-placeholder.svg';
import PaidRibbon from '../../../../components/profile/MyPassbook/PaidRibbon';
import CommonPlayer from '../../../../components/CommonPlayer';

class ProductCard extends Component {
  render() {
    const { cardData, hideMetaBar, isAd, isShared } = this.props;
    const { product, meta, shared } = cardData;
    const productName = product.name || '';
    const productId = product.id;
    const productImage =
      product.images_meta && product.images_meta.length
      && product.images_meta[0].original_photo;
    const productVideo = get(product, 'videos_meta', []);
    const helperText = product.brand_name
      ? product.brand_name
      : product.company_name
        ? product.company_name
        : '';
    const productMeta = meta || null;
    const productStatusText = meta
      ? productHelper.statusText(productMeta.current_status)
      : productHelper.statusText();
    const productReviewText =
      meta && meta.overall_rating
        ? productHelper.reviewText(meta.overall_rating.count, !!!meta.overall_rating.overall_rating)
        : false;
    const productColorCode =
      meta && meta.overall_rating && meta.overall_rating.color_code
        ? meta.overall_rating.color_code
        : '#7e9b42';
    const showReviewCount =
      !meta.overall_rating ||
      (meta.overall_rating && (!meta.overall_rating.overall_rating || !meta.overall_rating.count));

    const isPaid = get(meta, 'isPaid');

    return (
      <div className="feed-card">
        {cardData && cardData.sharedBy && !!!isShared && !hideMetaBar && (
          <UserBar isAd={isAd} profile={cardData.sharedBy} />
        )}
        <Link className="product-cards" to={`/reviews/products/${productId}`}>
          <div className="product-cards__image-container">
            {productImage ? (
              <img
                className="product-cards__image-container__image"
                src={productImage}
                alt={productName}
              />
            ) : Array.isArray(productVideo) && !!productVideo.length ?
              <CommonPlayer
                playerId={`${productId}-${isShared ? `shared-card-${shared.id}` : ''}`}
                videosMeta={productVideo}
              />
              :
              <img
                className="product-cards__image-container__image"
                src={ProductPlaceholder}
                alt={productName}
              />
            }
            {
              <React.Fragment>
                {isPaid && (
                  <div className="feedcardribbon__product__ribbon">
                    <PaidRibbon />
                  </div>
                )}
                <div className="product-cards__image-container__rating-container">
                  <div
                    style={{
                      background: showReviewCount ? '' : productColorCode,
                    }}
                    className={`product-rating ${showReviewCount ? 'product-rating--disabled' : ''
                      }`}
                  >
                    <img className="product-rating__icon" src={ProductStar} alt="rating-star" />
                    {meta &&
                      meta.overall_rating &&
                      meta.overall_rating.overall_rating &&
                      meta.overall_rating.max_rating && (
                        <span className="product-rating__text">
                          {twoDecimal(meta.overall_rating.overall_rating)} /{' '}
                          {meta.overall_rating.max_rating}
                        </span>
                      )}
                  </div>
                  {/* <p className="product-cards__image-container__rating-container__text">{productReviewText}</p> TODO */}
                </div>
              </React.Fragment>
            }
          </div>
          <div className="product-cards__details">
            <div className="product-cards__details__meta">
              <p className="product-cards__details__meta__text--primary">{productName}</p>
              <p className="product-cards__details__meta__text--secondary">{helperText}</p>
            </div>
            <button className="product-cards__details__action-btn">
              {productMeta.current_status === 2 && (
                <img
                  src={ProductCheck}
                  className="product-cards__details__action-btn__image"
                  alt="product reviewed"
                />
              )}
              <span className="product-cards__details__action-btn__text">
                {productStatusText.text}
              </span>
            </button>
          </div>
        </Link>
      </div>
    );
  }
}

ProductCard.propTypes = {
  cardData: PropTypes.object.isRequired,
  hideMetaBar: PropTypes.bool,
  isShared: PropTypes.bool,
  isAd: PropTypes.bool,
};

export default ProductCard;
