import { GET_COLLABORATION_ROLES_REQUEST,
    GET_COLLABORATION_ROLES_SUCCESS,
    GET_COLLABORATION_ROLES_FAILURE,
    RESET_COLLABORATION_ROLES,
    COLLABORATION_ROLES_ADD_PROIFLE_SUCCESS,
    COLLABORATION_ROLES_REMOVE_PROFILE_SUCCESS } from "../../../actions/collaborations/roles/constants";

const INITIAL_STATE = {
    fetching: false,
    data: null,
    error: false
}
export default function roles(state = INITIAL_STATE, action) {
    switch(action.type) {
        case GET_COLLABORATION_ROLES_REQUEST:
            return {
                ...state,
                fetching: true
            }
        case GET_COLLABORATION_ROLES_FAILURE:
            return {
                ...state,
                fetching: false,
                ...(!action.error.__CANCEL__ && {
                    error: true,
                    errorMsg: action.error
                })
            }
        case GET_COLLABORATION_ROLES_SUCCESS:
            return {
                ...state,
                fetching: false,
                data: action.payload
            }
        case COLLABORATION_ROLES_ADD_PROIFLE_SUCCESS:
            return {
                ...state,
                data: state.data
                    ? state.data.map(_ => {
                        if (_.role_id !== action.payload.roleId) {
                            return _;
                        }
                        return {
                            ..._,
                            profiles: [..._.profiles, action.payload.profile]
                        }
                    })
                    : state.data
            }
        case COLLABORATION_ROLES_REMOVE_PROFILE_SUCCESS:
            return {
                ...state,
                data: state.data
                    ?   state.data.map(_ => {
                        if (_.role_id !== action.payload.roleId) {
                            return _;
                        }
                        return {
                            ..._,
                            profiles: _.profiles.filter(_ => _.id !== action.payload.profileId)
                        }
                    })
                    : state.data
            }
        case RESET_COLLABORATION_ROLES:
            return INITIAL_STATE
        default:
            return state;
    }
}