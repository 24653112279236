import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import ChevronRight from '../../assets/images/photos/chevron-right.svg';
import { productHelper, twoDecimal, decodeString } from '../../utils/helper';
import ProductStar from '../../assets/images/products/star-favorite.svg';
import { productLinks } from '../../utils/internalLinks';
import { screenBreakpoints } from '../../utils/commonVariables';
import ProductPlaceholder from '../../assets/images/products/product-placeholder.svg';

const sliderSettings = {
    dots: false,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: (<SliderArrow to="prev" />),
    nextArrow: (<SliderArrow to="next" />),
    centerPadding: 190
}

function SliderArrow(props){
    const { onClick,
    to,
    currentSlide,
    slideCount } = props,
    isDisabled = ((to === 'prev' && currentSlide === 0) || (to === 'next' && currentSlide === slideCount - 3));
    return (<button aria-hidden="true"
        aria-disabled={isDisabled}
        type="button"
        disabled={isDisabled}
        className={`suggestions__arrow suggestions__arrow--${to} ${isDisabled ? 'suggestions__arrow--disabled' : ''}`}
        onClick={onClick}>
            <img className={`suggestions__arrow__icon suggestions__arrow__icon--${to}`}
                src={ChevronRight} alt="go to next slide" />
    </button>)
}

const ProductSuggestionItem = (props) => {
    const { productDetails } = props;
    const { product, meta } = productDetails;
    if (!product || !meta) return null;
    const productName = product.name
            ? product.name.length > 40
            ? `${product.name.substring(0, 37)}...`
            : product.name
            : '',
        productId = product.id,
        imageToShow = product.images_meta
            && product.images_meta.length
            ? product.images_meta[0].original_photo
            : ProductPlaceholder,
        companyName = decodeString(product.company_name),
        { current_status,
            overall_rating } = meta;
        if (!overall_rating) return null;
        const overallRating = overall_rating.overall_rating,
        maxRating = overall_rating.max_rating,
        count = overall_rating.count,
        colorCode = overall_rating.color_code;
    return (<div className="suggestions__product">
        <Link to={productLinks.view(productId)} className="suggestions__product__overlay">
            <p className="suggestions__product__overlay__link" >{productHelper.statusText(current_status).text}</p>
        </Link>
        <div className="suggestions__product__container">
            <img className="suggestions__product__img" src={imageToShow} alt={productName} />
            <div className="suggestions__product__info">
                <div className="suggestions__product__info__header">
                    <p className="suggestions__product__title">{decodeString(productName)}</p>
                    <p className="suggestions__product__helper suggestions__product__helper--company">by {companyName}</p>
                </div>
                <div className="suggestions__product__meta">
                    <div style={{background: colorCode}} className={`suggestions__product__rating ${!overallRating ? 'suggestions__product__rating--empty' : ''}`}>
                        <img src={ProductStar} alt="rating-star" />
                        { overallRating && <span className="suggestions__product__rating__title">{twoDecimal(overallRating)} / {maxRating}</span> }
                    </div>
                    <p className="suggestions__product__helper">{productHelper.reviewText(count, !overallRating)}</p>
                </div>
            </div>
        </div>
    </div>)
}

function ProductSuggestion(props) {
    const [suggestions, setSuggestions] = useState(props.suggestions || []),
        [subTitle, setSubTitle] = useState(props.subTitle || 'Suggested for you'),
        [isMobile] = useState(window.innerWidth < screenBreakpoints.mob);
    useEffect(() => {
        setSuggestions(props.suggestions);
        setSubTitle(props.subTitle);
    }, [props.suggestions, props.title, props.subTitle]);
    if (!suggestions.length) return null;
    return (<div className="suggestions">
            <div className="suggestions__info suggestions__info--multi">
                <div>
                    <p className="suggestions__info__title">{subTitle}</p>
                </div>
                <Link className="suggestions__info__link" to={productLinks.all}>SEE ALL</Link>
            </div>
            {
                isMobile
                ? <div className="suggestions suggestions--product">
                    {
                        suggestions.map((_, i) => {
                            return <ProductSuggestionItem productDetails={_}
                                key={`product-suggestion-${_.id}-${i}`} />
                        })
                    }
                </div>
                : <Slider {...sliderSettings}>
                    {
                        suggestions.map((_, i) => {
                            return <ProductSuggestionItem productDetails={_}
                                key={`product-suggestion-${_.id}-${i}`} />
                        })
                    }
                </Slider>
            }
        </div>)
};

export default ProductSuggestion;