import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getPremiumCompanies } from '../../actions/premium';
import { collaborationInternalLinks } from '../../utils/internalLinks';
import ReviewCreate from '../modals/premium/ReviewCreate';

function ReviewCreateButton(props) {
  const {
    user: {
      premiumCompanies,
      user: { profile },
      user
    },
    history,
    getPremiumCompanies,
    token,
    isCreateMobile,
    isReviewShow,
    handleShowCollabModal,
  } = props;
  const [showCollabModal, setShowCollabModal] = useState(false);
  const [classNameToAppend] = useState(props.classNameToAppend || '');

  useEffect(() => {
    if (isReviewShow) handleReviewCreate();
  }, [isReviewShow]);

  const toggleCollabCreateModal = (type) => {
    setShowCollabModal(typeof type !== 'undefined' ? type : !showCollabModal);

    if (handleShowCollabModal && !type) handleShowCollabModal();
  };

  const handleReviewCreate = () => {
    if (!premiumCompanies) history.push(collaborationInternalLinks.create());
    // checking for premium companies now
    if (!premiumCompanies.hasFetched) {
      // has not fetched premium comapnies should fetch now
      getPremiumCompanies(token).then((val) => {
        if (val && val.length) {
          toggleCollabCreateModal(true);
        } else {
          if (!profile.is_premium) {
            history.push(collaborationInternalLinks.create());
          } else {
            toggleCollabCreateModal(true);
          }
        }
      });
    } else {
      // has fetched premium companies
      const { companies } = premiumCompanies;
      if (!companies.length && !profile.is_premium) {
        history.push(collaborationInternalLinks.create());
        return;
      }
      // show modal
      toggleCollabCreateModal(true);
    }
  };

  return (
    <>
      {!isCreateMobile ? (
        <button onClick={handleReviewCreate} className={classNameToAppend}>
          {props.children}
        </button>
      ) : null}
      {showCollabModal && <ReviewCreate history={history} user={user} handleClose={() => toggleCollabCreateModal(false)} />}
    </>
  );
}

ReviewCreateButton.propTypes = {
  classNameToAppend: PropTypes.string,
  user: PropTypes.object,
  history: PropTypes.object,
  getPremiumCompanies: PropTypes.func,
  token: PropTypes.string,
  children: PropTypes.any,
  isCreateMobile: PropTypes.bool,
  handleShowCollabModal: PropTypes.func,
  isReviewShow: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    token: state.login.token,
    user: state.user,
  };
}
export default withRouter(connect(mapStateToProps, { getPremiumCompanies })(ReviewCreateButton));
