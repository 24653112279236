import { ADD_COMPANY_ADMIN_SUCCESS } from "../company/constants";

// Getting all eating habits
export const GET_EATING_HABITS_REQUEST = 'GET_EATING_HABITS_REQUEST';
export const GET_EATING_HABITS_SUCCESS = 'GET_EATING_HABITS_SUCCESS';
export const GET_EATING_HABITS_FAILURE = 'GET_EATING_HABITS_FAILURE';

// getting all job profiles
export const GET_JOB_PROFILE_REQUEST = 'GET_JOB_PROFILE_REQUEST';
export const GET_JOB_PROFILE_SUCCESS = 'GET_JOB_PROFILE_SUCCESS';
export const GET_JOB_PROFILE_FAILURE = 'GET_JOB_PROFILE_FAILURE';

// getting all specializations
export const GET_SPECIALIZATION_REQUEST = 'GET_SPECIALIZATION_REQUEST';
export const GET_SPECIALIZATION_SUCCESS = 'GET_SPECIALIZATION_SUCCESS';
export const GET_SPECIALIZATION_FAILURE = 'GET_SPECIALIZATION_FAILURE';

// getting all cuisine
export const GET_CUISINES_REQUEST = 'GET_CUISINES_REQUEST';
export const GET_CUISINES_SUCCESS = 'GET_CUISINES_SUCCESS';
export const GET_CUISINES_FAILURE = 'GET_CUISINES_FAILURE';

// profile update request
export const PROFILE_UPDATE_REQUEST = 'PROFILE_UPDATE_REQUEST';
export const PROFILE_UPDATE_SUCCESS = 'PROFILE_UPDATE_SUCCESS';
export const PROFILE_UPDATE_FAILURE = 'PROFILE_UPDATE_FAILURE';

// follow a user
export const FOLLOW_USER_REQUEST = 'FOLLOW_USER_REQUEST';
export const FOLLOW_USER_SUCCESS = 'FOLLOW_USER_SUCCESS';
export const FOLLOW_USER_FAILURE = 'FOLLOW_USER_FAILURE';

// unfollow a user
export const UNFOLLOW_USER_REQUEST = 'UNFOLLOW_USER_REQUEST';
export const UNFOLLOW_USER_SUCCESS = 'UNFOLLOW_USER_SUCCESS';
export const UNFOLLOW_USER_FAILURE = 'UNFOLLOW_USER_FAILURE';

// FOLLOWERS LIST
export const GET_FOLLOWERS_LIST_REQUEST = 'GET_FOLLOWERS_LIST_REQUEST';
export const GET_FOLLOWERS_LIST_SUCCESS = 'GET_FOLLOWERS_LIST_SUCCESS';
export const GET_FOLLOWERS_LIST_FAILURE = 'GET_FOLLOWERS_LIST_FAILURE';
export const RESET_FOLLOWERS_LIST = 'RESET_FOLLOWERS_LIST';

// FOLLOWING LIST
export const GET_FOLLOWING_LIST_REQUEST = 'GET_FOLLOWING_LIST_REQUEST';
export const GET_FOLLOWING_LIST_SUCCESS = 'GET_FOLLOWING_LIST_SUCCESS';
export const GET_FOLLOWING_LIST_FAILURE = 'GET_FOLLOWING_LIST_FAILURE';
export const RESET_FOLLOWING_LIST = 'RESET_FOLLOWING_LIST';
export const ADD_TO_FOLLOWING_LIST = 'ADD_TO_FOLLOWING_LIST';
export const REMOVE_FROM_FOLLOWING_LIST = 'REMOVE_FROM_FOLLOWING_LIST';

// Allergens
export const GET_PROFILE_ALLERGENS_REQUEST = 'GET_PROFILE_ALLERGENS_REQUEST';
export const GET_PROFILE_ALLERGENS_SUCCESS = 'GET_PROFILE_ALLERGENS_SUCCESS';
export const GET_PROFILE_ALLERGENS_FAILURE = 'GET_PROFILE_ALLERGENS_FAILURE';

//add shipping address
export const ADD_ADDRESS_REQUEST = 'ADD_ADDRESS_REQUEST';
export const ADD_ADDRESS_SUCCESS = 'ADD_ADDRESS_SUCCESS';
export const ADD_ADDRESS_FAILURE = 'ADD_ADDRESS_FAILURE';

// delete shipping address
export const DELETE_SHIPPING_ADDRESS_REQUEST = 'DELETE_SHIPPING_ADDRESS_REQUEST';
export const DELETE_SHIPPING_ADDRESS_SUCCESS = 'DELETE_SHIPPING_ADDRESS_SUCCESS';
export const DELETE_SHIPPING_ADDRESS_FAILURE = 'DELETE_SHIPPING_ADDRESS_FAILURE';

// update shipping address
export const UPDATE_SHIPPING_ADDRESS_REQUEST = 'UPDATE_SHIPPING_ADDRESS_REQUEST';
export const UPDATE_SHIPPING_ADDRESS_SUCCESS = 'UPDATE_SHIPPING_ADDRESS_SUCCESS';
export const UPDATE_SHIPPING_ADDRESS_FAILURE = 'UPDATE_SHIPPING_ADDRESS_FAILURE';

// invite contants
export const INVITE_CONTACT_REQUEST = 'INVITE_CONTACT_REQUEST';
export const INVITE_CONTACT_SUCCESS = 'INVITE_CONTACT_SUCCESS';
export const INVITE_CONTACT_FAILURE = 'INVITE_CONTACT_FAILURE';

// education
export const PROFILE_EDUCATION_CREATE_OR_UPDATE_REQUEST = 'PROFILE_EDUCATION_CREATE_OR_UPDATE_REQUEST';
export const PROFILE_EDUCATION_CREATE_OR_UPDATE_SUCCESS = 'PROFILE_EDUCATION_CREATE_OR_UPDATE_SUCCESS';
export const PROFILE_EDUCATION_CREATE_OR_UPDATE_FAILURE = 'PROFILE_EDUCATION_CREATE_OR_UPDATE_FAILURE';

// deleting education
export const DELETE_EDUCATION_REQUEST = 'DELETE_EDUCATION_REQUEST';
export const DELETE_EDUCATION_SUCCESS = 'DELETE_EDUCATION_SUCCESS';
export const DELETE_EDUCATION_FAILURE = 'DELETE_EDUCATION_FAILURE';

// work experience
export const PROFILE_WORK_EXP_CREATE_OR_UPDATE_REQUEST = 'PROFILE_WORK_EXP_CREATE_OR_UPDATE_REQUEST';
export const PROFILE_WORK_EXP_CREATE_OR_UPDATE_SUCCESS = 'PROFILE_WORK_EXP_CREATE_OR_UPDATE_SUCCESS';
export const PROFILE_WORK_EXP_CREATE_OR_UPDATE_FAILURE = 'PROFILE_WORK_EXP_CREATE_OR_UPDATE_FAILURE';

// deleting work experience
export const DELETE_WORK_EXP_REQUEST = 'DELETE_WORK_EXP_REQUEST';
export const DELETE_WORK_EXP_SUCCESS = 'DELETE_WORK_EXP_SUCCESS';
export const DELETE_WORK_EXP_FAILURE = 'DELETE_WORK_EXP_FAILURE';

// training
export const PROFILE_TRAINING_CREATE_OR_UPDATE_REQUEST = 'PROFILE_TRAINING_CREATE_OR_UPDATE_REQUEST';
export const PROFILE_TRAINING_CREATE_OR_UPDATE_SUCCESS = 'PROFILE_TRAINING_CREATE_OR_UPDATE_SUCCESS';
export const PROFILE_TRAINING_CREATE_OR_UPDATE_FAILURE = 'PROFILE_TRAINING_CREATE_OR_UPDATE_FAILURE';

// deleting training
export const DELETE_TRAINING_REQUEST = 'DELETE_TRAINING_REQUEST';
export const DELETE_TRAINING_SUCCESS = 'DELETE_TRAINING_SUCCESS';
export const DELETE_TRAINING_FAILURE = 'DELETE_TRAINING_FAILURE';

// OTP verify
export const OTP_VERIFY_REQUEST = 'OTP_VERIFY_REQUEST';
export const OTP_VERIFY_SUCCESS = 'OTP_VERIFY_SUCCESS';
export const OTP_VERIFY_FAILURE = 'OTP_VERIFY_FAILURE';

//get Profile
export const GET_PROFILE_REQUEST = 'GET_PROFILE_REQUEST';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_FAILURE = 'GET_PROFILE_FAILURE';

//get profile by Id
export const GET_PROFILE_BY_ID_REQUEST = ' GET_PROFILE_BY_ID_REQUEST';
export const GET_PROFILE_BY_ID_SUCCESS = 'GET_PROFILE_BY_ID_SUCCESS';
export const GET_PROFILE_BY_ID_FAILURE = 'GET_PROFILE_BY_ID_FAILURE';

//get training data
export const GET_TRAINING_DATA_REQUEST = 'GET_TRAINING_DATA_REQUEST';
export const GET_TRAINING_DATA_SUCCESS = 'GET_TRAINING_DATA_SUCCESS';
export const GET_TRAINING_DATA_FAILURE = 'GET_TRAINING_DATA_FAILURE';

//get profile photos
export const GET_PROFILE_PHOTO_REQUEST = 'GET_PROFILE_PHOTO_REQUEST';
export const GET_PROFILE_PHOTO_SUCCESS = 'GET_PROFILE_PHOTO_SUCCESS';
export const GET_PROFILE_PHOTO_FAILURE = 'GET_PROFILE_PHOTO_FAILURE';
// reset profile photo
export const RESET_PROFILE_PHOTOS = 'RESET_PROFILE_PHOTOS';
// setting first page from state
export const SET_PROFILE_PHOTO_DATA = 'SET_PROFILE_PHOTO_DATA';

//get profile feed
export const GET_PROFILE_ACTIVITY_REQUEST = 'GET_PROFILE_ACTIVITY_REQUEST';
export const GET_PROFILE_ACTIVITY_SUCCESS = 'GET_PROFILE_ACTIVITY_SUCCESS';
export const GET_PROFILE_ACTIVITY_FAILURE = 'GET_PROFILE_ACTIVITY_FAILURE';
export const PROFILE_IMAGE_REMOVE_SUCSESS = 'PROFILE_IMAGE_REMOVE_SUCSESS';
export const COVER_IMAGE_REMOVE_SUCSESS  = 'COVER_IMAGE_REMOVE_SUCSESS';
export const REMOVE_COMPANY_IMAGE_SUCCESS = 'REMOVE_COMPANY_IMAGE_SUCCESS';
export const REMOVE_COMPANY_COVER_IMAGE_SUCCESS = 'REMOVE_COMPANY_COVER_IMAGE_SUCCESS';


// reset profile activity
export const RESET_PROFILE_ACTIVITY = 'RESET_PROFILE_ACTIVITY';
export const RESET_PHONE_NUMBER = 'RESET_PHONE_NUMBER';

// reset profile
export const RESET_PROFILE_PAGE = 'RESET_PROFILE_PAGE';

// add allergens
export const UPDATE_ALLERGENS_REQUEST = 'UPDATE_ALLERGENS_REQUEST';
export const UPDATE_ALLERGENS_SUCCESS = 'UPDATE_ALLERGENS_SUCCESS';
export const UPDATE_ALLERGENS_FAILURE = 'UPDATE_ALLERGENS_FAILURE';

// get premium companies
export const GET_PREMIUM_COMPANIES_REQUEST = 'GET_PREMIUM_COMPANIES_REQUEST';
export const GET_PREMIUM_COMPANIES_SUCCESS = 'GET_PREMIUM_COMPANIES_SUCCESS';
export const GET_PREMIUM_COMPANIES_FAILURE = 'GET_PREMIUM_COMPANIES_FAILURE';

// getting profile
export const GET_PROFILE_MY_COLLABORATIONS_REQUEST = 'GET_PROFILE_MY_COLLABORATIONS_REQUEST';
export const GET_PROFILE_MY_COLLABORATIONS_SUCCESS = 'GET_PROFILE_MY_COLLABORATIONS_SUCCESS';
export const GET_PROFILE_MY_COLLABORATIONS_FAILURE = 'GET_PROFILE_MY_COLLABORATIONS_FAILURE';
export const RESET_PROFILE_MY_COLLABORATIONS = 'RESET_PROFILE_MY_COLLABORATIONS';

// people
export const GET_ALL_PEOPLE_REQUEST = 'GET_ALL_PEOPLE_REQUEST';
export const GET_ALL_PEOPLE_SUCCESS = 'GET_ALL_PEOPLE_SUCCESS';
export const GET_ALL_PEOPLE_FAILURE = 'GET_ALL_PEOPLE_FAILURE';
export const RESET_ALL_PEOPLE = 'RESET_ALL_PEOPLE';

// all people from specialization
export const GET_PEOPLE_BY_SPECIALIZATION_REQUEST = 'GET_PEOPLE_BY_SPECIALIZATION_REQUEST';
export const GET_PEOPLE_BY_SPECIALIZATION_SUCCESS = 'GET_PEOPLE_BY_SPECIALIZATION_SUCCESS';
export const GET_PEOPLE_BY_SPECIALIZATION_FAILURE = 'GET_PEOPLE_BY_SPECIALIZATION_FAILURE';
export const RESET_PROFILE_BY_SPECIALIZATION = 'RESET_PROFILE_BY_SPECIALIZATION';