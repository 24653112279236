import React from 'react';
import PropTypes from 'prop-types';

const FieldError = ({ text }) => {
  return <div className="biz__fielderror">{text}</div>;
};

FieldError.propTypes = {
  text: PropTypes.string,
};
export default FieldError;
