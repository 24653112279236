import React, { useState, useRef } from 'react'
import ShowInvitationDetailsModal from '../modals/ShowInvitationDetailsModal';
import DateGreyIcon from "../../../src/assets/images/rsvp/date-grey.svg";
import TimeGreyIcon from "../../../src/assets/images/rsvp/time-grey.svg";
import LocationGreyIcon from "../../../src/assets/images/rsvp/location-grey.svg";
import ArrowWhiteIcon from "../../../src/assets/images/rsvp/arrow-white.svg";
import ArrowBlackIcon from "../../../src/assets/images/rsvp/arrow-black.svg";
import InvitationAction from './InvitationAction';
import Slider from "react-slick";
import { screenBreakpoints } from "../../utils/commonVariables";
import _ from "lodash";
import InvitationTimer from './InvitationTimer';
import GoogleMapIcon from "../../../src/assets/images/rsvp/goggle-map.svg";
import VenueIcon from "../../../src/assets/images/rsvp/venue.svg";
import DateIcon from "../../../src/assets/images/rsvp/date.svg";
import TimeIcon from "../../../src/assets/images/rsvp/time.svg";
import LocationIcon from "../../../src/assets/images/rsvp/location.svg";
import { Link } from "react-router-dom";
import CompanyBar from '../../containers/loggedIn/Feed/common/CompanyBar';
import UserBar from '../../containers/loggedIn/Feed/common/UserBar';
import { format, parse } from 'date-fns';
import { handleInvitation } from '../collaborations/prManage/schedule/helper';
import { INVITATIONS_KEYS, invitationGAData } from '../collaborations/prManage/schedule/constants';
import CommonPortal from '../modals/common/CommonPortal';
import { hideInvitationDetailsModal, showInvitationDetailsModal } from '../../actions/common/modals';
import { connect } from 'react-redux';
import get from "lodash/get";
import ReactTooltip from "react-tooltip";

const SliderComponent = (props) => {
    const { handleSlider, cardIndex, isFeed, count, index } = props;
    const isRightArrowDisabled = cardIndex === count;
    const isLeftArrowDisabled = cardIndex === 1;
    const ArrowImg = !!isFeed ? ArrowBlackIcon : ArrowWhiteIcon;
    return (
        <>
            {count > 1 && <div className="invited-count-div">
                <p className="text"><span className={`${!!isLeftArrowDisabled ? "disabled" : ""}`}>{cardIndex}</span> of <span className={`${!!isRightArrowDisabled ? "disabled" : ""}`}>{count}</span></p>
                <button className={`arrow-img left ${!!isLeftArrowDisabled ? "disabled" : ""}`} onClick={(e) => { handleSlider(e, "right") }}><img src={ArrowImg} /></button>
                <button className={`arrow-img ${!!isRightArrowDisabled ? "disabled" : ""}`} onClick={(e) => handleSlider(e, "left")}><img src={ArrowImg} /></button>
            </div>}
        </>

    )
}
const CollabInvitationDetailsView = (props) => {
    const { handleInvitationModal, data, handleUpdate, count, handleModal, index, cardIndex, isOverFlow } = props;
    const { date, reporting_time } = data;
    const parsedTime = reporting_time ? parse(reporting_time, 'HH:mm:ss', new Date()) : '';
    const formattedTime = parsedTime ? format(parsedTime, 'hh:mm a') : '';
    const parsedDate = date ? parse(date, 'yyyy-MM-dd', new Date()) : "";
    const formattedDate = parsedDate ? format(parsedDate, 'dd MMM yyyy').toUpperCase() : '';
    return (
        <div className="invitation-details" style={{ display: index + 1 !== cardIndex && !!isOverFlow ? "none" : "" }} onClick={handleModal}>
            <div className="invitation-details__left">
                <p className="title">{data.info.title}</p>
                <p className="subtitle">{data.info.sub_title}</p>
                <SliderComponent {...props} count={count} />
            </div>
            <div className="invitation-details__right">
                {(parseInt(data.status) === 2 || parseInt(data.status) === 3) && <p className="timer-details">
                    <span className="timer-details__item"><img src={TimeGreyIcon} />{formattedDate}</span>
                    <span className="timer-details__item"><img src={DateGreyIcon} />{formattedTime}</span>
                    <span className="timer-details__item"><img src={LocationGreyIcon} />{data.tasting_schedule_city.name}</span></p>}
                {parseInt(data.status) === 1 && <InvitationTimer isDetails={true} date={data.date}
                    reportingTime={data.reporting_time} />}
                <div className="action">

                    <InvitationAction handleInvitationModal={handleInvitationModal} invitation={data} isDetails={true} handleUpdate={handleUpdate} />
                </div>
            </div>
        </div>
    )
}

const FeedInvitationDetailsView = (props) => {
    const { data, handleUpdate } = props;
    const { date, reporting_time } = data;
    const parsedTime = reporting_time ? parse(reporting_time, 'HH:mm:ss', new Date()) : '';
    const formattedTime = parsedTime ? format(parsedTime, 'hh:mm a') : '';
    const parsedDate = date ? parse(date, 'yyyy-MM-dd', new Date()) : "";
    const formattedDate = parsedDate ? format(parsedDate, 'dd MMM yyyy').toUpperCase() : '';
    const link = get(data, "tasting_schedule_city.location.link", "");
    const handleVenue = () => {
        const data = invitationGAData(INVITATIONS_KEYS.DIRECTION, INVITATIONS_KEYS.CLICK, INVITATIONS_KEYS.LANDING_PAGE)
        handleInvitation(data);
    }
    if (!data) return null
    return (

        <div className="container">
            <div className="first">
                <Link to={`/collaborations/${data.collaboration.id}/product-review`}
                    target="_blank" className="invitation-tasting"
                >{data.collaboration.title}</Link>
                {data.collaboration.company ? <CompanyBar company={data.collaboration.company} /> : data.collaboration.profile ? <UserBar profile={data.collaboration.profile} /> : null}
            </div>
            <div className="second">
                <>
                    <p className="text"><img src={DateIcon} width={"auto"} height={"16px"} />{formattedDate}</p>
                    <p className="text"><img src={TimeIcon} width={"auto"} height={"16px"} />{formattedTime}</p>
                    <p className="text"><img src={LocationIcon} width={"auto"} height={"16px"} />{data.tasting_schedule_city.name}</p>
                </>
                <p className="location">
                    {data.tasting_schedule_city.location.title}
                </p>
                <div onClick={handleVenue}><a href={link} target="blank" rel="noopener noreferrer" className="venue"><img src={GoogleMapIcon} />Venue <img src={VenueIcon} /></a></div>
                <div className="action">
                    <InvitationAction invitation={data} isDetails={false} handleUpdate={handleUpdate} />
                </div>
            </div>
        </div>

    )
}

function InvitationDetails(props) {
    const { data, isFeed, handlePage, handleUpdate, count, collaboration, handleUpdateList, hideInvitationDetailsModal, showInvitationDetailsModal, showInvitationModal: { isVisible, data: { model } } } = props;
    const [isOverFlow, setIsOverflow] = useState(false);
    const [cardIndex, setCardIndex] = useState(1);
    let sliderRef = useRef(null);
    const modalData = data && data.filter((val, index) => index + 1 === cardIndex)[0];
    const handleInvitationModal = () => {
        const data = invitationGAData(INVITATIONS_KEYS.INFO_DETAILS, INVITATIONS_KEYS.CLICK, INVITATIONS_KEYS.TASTING_DETAILS)
        handleInvitation(data);
        setIsOverflow(false);
        const reduxdata = {
            invitaion_id: modalData.id,
            model: "details",
        }
        showInvitationDetailsModal(reduxdata)
    }

    const handleCloseInvitationModal = () => {
        hideInvitationDetailsModal();
    }

    const handleSlider = (e, type) => {
        if (!e) return;
        e.preventDefault();
        e.stopPropagation();
        setIsOverflow(false);
        if (type === "left") {
            sliderRef.slickNext();
        } else {
            sliderRef.slickPrev();
        }
        handleSwipe(type);
    }
    const handleClickButton = (e, id, type) => {
        if (!e) return;
        e.preventDefault();
        if (e.target.tagName.toLowerCase() === "add-to-calendar-button") {
            setIsOverflow(true);
        } else {
            setIsOverflow(false);
        }
        handleUpdate(e, id, type)
    }

    const handleModal = (e) => {
        if (!e) return;
        e.preventDefault();
        setIsOverflow(false);
        const reduxdata = {
            invitaion_id: modalData.id,
            model: "details",
        }
        const data = invitationGAData(INVITATIONS_KEYS.INFO_DETAILS, INVITATIONS_KEYS.VIEW, INVITATIONS_KEYS.TASTING_DETAILS);
        handleInvitation(data);
        showInvitationDetailsModal(reduxdata)
    }

    const handleSwipe = (type) => {
        if (!!isFeed && count > data.length && cardIndex === (data.length - 1)) {
            handlePage();
        }
        if (type === "left") {
            setCardIndex(val => val + 1);
        } else {
            setCardIndex(val => val - 1);
        }
    }

    const sliderSettings = {
        dots: false,
        infinite: false,
        arrows: false,
        ...(!!isOverFlow && { className: "slider-div" }),
        swipe: false,
        draggable: false,
        responsive: [
            {
                breakpoint: screenBreakpoints.tab,
                slidesToShow: 0,
                slidesToScroll: 0,
            },
        ],
    };
    const ComponentToUse = !!isFeed ? FeedInvitationDetailsView : CollabInvitationDetailsView
    return (
        <div className={`${!!isFeed ? "feed-invitation-details" : ""}`}>
            {
                !!isFeed && <div className="header">
                    <p className="text">You're Invited!</p>
                    <SliderComponent {...props} cardIndex={cardIndex} count={count} handleSlider={handleSlider} />
                </div>
            }
            <Slider {...sliderSettings} ref={slider => {
                sliderRef = slider;
            }}>
                {
                    data && data.map((val, index) => {
                        const invitationData = { collaboration: { ...collaboration }, ...val, }
                        return <ComponentToUse
                            data={invitationData}
                            handleInvitationModal={handleInvitationModal}
                            handleSlider={handleSlider}
                            totalCard={data.length}
                            cardIndex={cardIndex}
                            handleUpdate={handleClickButton}
                            count={count}
                            handleModal={handleModal}
                            index={index}
                            isOverFlow={isOverFlow}
                        />
                    })
                }

            </Slider>
            {
                model === "details" && isVisible &&
                <CommonPortal>
                    <ShowInvitationDetailsModal
                        handleCloseInvitationModal={handleCloseInvitationModal} invitationId={modalData.id} handleUpdateList={handleUpdateList} />
                </CommonPortal>
            }
        </div>
    )
}

function mapStateToProps(state) {
    return {
        token: state.login.token,
        user: state.user,
        showInvitationModal: state.modalReducer.collaboration.showInvitationModal,
    };
}

const mapDispatchToProps = {
    hideInvitationDetailsModal,
    showInvitationDetailsModal
};

export default connect(mapStateToProps, mapDispatchToProps)(InvitationDetails);