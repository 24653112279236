import { GET_TTFB_PRODUCT_LEADS_REQUEST,
    GET_TTFB_PRODUCT_LEADS_SUCCESS,
    GET_TTFB_PRODUCT_LEADS_FAILURE,
    RESET_TTFB_PRODUCT_LEADS,
    APPEND_TO_TTFB_PRODUCT_LEAD_TABLE,
    UPDATE_TTFB_PRODUCT_LEAD_ITEM } from '../../actions/business/constants';

const INITIAL_STATE = {
    fetching: false,
    data: null,
    error: false,
    page: 1
}
export default function leads(state = INITIAL_STATE, action) {
    switch(action.type) {
        case GET_TTFB_PRODUCT_LEADS_REQUEST:
            return {
                ...state,
                fetching: true
            }
        case GET_TTFB_PRODUCT_LEADS_SUCCESS:
            return {
                ...state,
                fetching: false,
                data: state.page === 1
                    ? action.payload
                    : {
                        ...state.data,
                        data: [...state.data.data,
                            ...action.payload.data]
                    },
                hasFetched: true,
                page: state.page + 1,
                hasEnd: (!action.payload.data.length
                    || (!!action.payload.data.length
                        && action.payload.data.length < 20))
            }
        case GET_TTFB_PRODUCT_LEADS_FAILURE:
            return {
                ...state,
                fetching: false,
                ...(!action.error.__CANCEL__ && {
                    error: true,
                    errorMsg: action.error
                }),
            }
        case APPEND_TO_TTFB_PRODUCT_LEAD_TABLE:
            return {
                ...state,
                data: {
                    ...state.data,
                    data: state.data.data.length
                        ? [action.payload, ...state.data.data]
                        : [action.payload]
                }
            }
        case UPDATE_TTFB_PRODUCT_LEAD_ITEM:
            return {
                ...state,
                data: {
                    ...state.data,
                    data: state.data.data.map(_ => {
                        if (_.id !== action.payload.id) {
                            return _;
                        }
                        return action.payload;
                    })
                }
            }
        case RESET_TTFB_PRODUCT_LEADS:
            return INITIAL_STATE
        default:
            return state;
    }
}