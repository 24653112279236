import {
  GET_COLLABORATIONS_REQUEST,
  GET_COLLABORATIONS_SUCCESS,
  GET_COLLABORATIONS_FAILURE,
  APPLY_PRODUCT_COLLABORATION_SUCCESS,
  APPLY_COLLABORATION_SUCCESS,
  RESET_COLLABORATIONS,
} from '../../actions/collaborations/constants';

const INITIAL_STATE = {
  page: 1,
  fetching: false,
  hasEnd: false,
  all: [],
};

export default function all(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_COLLABORATIONS_REQUEST:
      return {
        ...state,
        fetching: true,
        error: false,
        errorMsg: "",
      };
    case GET_COLLABORATIONS_SUCCESS:
      return {
        ...state,
        fetching: false,
        all:
          state.page === 1
            ? action.payload
              ? action.payload
              : []
            : [...state.all, ...(action.payload && action.payload)],
        count: action.payload.count,
        page: state.page + 1,
        hasEnd: !action.payload || (action.payload && !action.payload.length),
      };
    case GET_COLLABORATIONS_FAILURE:
      return {
        ...state,
        fetching: false,
        error: true,
        errorMsg: action.error,
      };
    case APPLY_COLLABORATION_SUCCESS:
      return {
        ...state,
        all: state.all.map((collaboration) => {
          if (collaboration.collaboration.id !== action.payload.collabId) return collaboration;
          return {
            collaboration: collaboration.collaboration,
            meta: {
              ...collaboration.meta,
              interested: true,
              interestedCount: collaboration.meta.interestedCount + 1,
            },
          };
        }),
      };
    case APPLY_PRODUCT_COLLABORATION_SUCCESS:
      return {
        ...state,
        all: state.all.map((collaboration) => {
          if (collaboration.collaboration.id !== action.payload.collabId) return collaboration;
          return {
            collaboration: {
              ...collaboration.collaboration,
              product_review_meta: {
                ...collaboration.collaboration.product_review_meta,
                is_interested: true,
              },
            },
          };
        }),
      };
    case RESET_COLLABORATIONS:
      return INITIAL_STATE;
    default:
      return state;
  }
}
