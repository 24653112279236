import React from "react";
import GooglePlacesAutoComplete from "../../misc/GooglePlacesAutoComplete";
import PromptFooter from "./PromptFooter";

const City = (props) => {
    const { form: { isUpdating, error, value },
        setForm,
        handleUpdate,
        handleClose,
        type
    } = props;
    const handleSave = () => {
        const key = type === "city" ? "city" : "hometown";
        let data = {
            _method: "PATCH",
            profile: {
                [key]: value
            }
        }
        handleUpdate(data);
    }
    return (
        <>
            <div className="profile-prompt__content">
                <GooglePlacesAutoComplete
                    key={value}
                    className="form-input form-input--secondary"
                    searchOptions={{ types: ['(cities)'] }}
                    handleLocationChange={(city) => (
                        setForm((preForm) => {
                            return { ...preForm, value: city.split(',')[0] }
                        })
                    )}
                    suggestionContainerClassName="collaboration-form__suggestion__container"
                    suggestionClassName="collaboration-form__suggestion"
                    location={value}
                />
            </div>
            <PromptFooter
                value={value}
                error={error}
                isUpdating={isUpdating}
                handleClose={handleClose}
                handleSave={handleSave}
            />
        </>
    )
}

export default City;