import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import PlayIcon from "../../../assets/images/poll/record.svg";
import { decodeString, twoDecimal } from "../../../utils/helper";

const TextOption = ({
  option,
  optionSelected,
  selfVote,
  isExpired,
  isPublic,
  publicClickAction,
  isCreator,
  pollId,
  handlePollSubmit,
  voteCount,
  index,
}) => {
  return (
    <li className="poll-card__details__options__item">
      <div
        className={
          selfVote && option.id === optionSelected
            ? "poll-card__details__options__item__selected"
            : "poll-card__details__options__item__unselected"
        }
      >
        <div
          onClick={() => {
            if (isExpired) return;
            if (isPublic) {
              publicClickAction();
              return;
            }
            if (isCreator) {
              alert(`You can't participate in your own poll.`);
              return;
            }
            if (selfVote) {
              alert(`You have already voted.`);
              return;
            }
            handlePollSubmit(pollId, option.id);
          }}
          className={`poll-card__details__options__option
                ${
                  optionSelected === option.id
                    ? "poll-card__details__options__option--selected"
                    : ""
                }
                ${
                  selfVote || isExpired || isCreator
                    ? "poll-card__details__options__option--unplayable"
                    : ""
                }`}
          key={`poll-${pollId}-${index}-${option.id}`}
        >
          {selfVote || isExpired || isCreator ? (
            <div
              style={{ width: `${option.count}%` }}
              className="poll-card__details__options__option__filled"
            ></div>
          ) : null}
          <div className="poll-card__details__options__option__details">
            <p className="poll-card__details__options__option__text">
              {decodeString(option.text)}
            </p>
          </div>
          {(selfVote || isExpired || isCreator) &&
          !!voteCount &&
          !!option.count ? (
            <p className="poll-card__details__options__option__text">
              {twoDecimal(option.count, option.count === 100)}%
            </p>
          ) : null}
        </div>
      </div>
    </li>
  );
};

function TextOptions(props) {
  const [options, setOptions] = useState(props.options || []);
  useEffect(() => {
    setOptions(props.options);
  }, [props.options]);
  if (!options.length) return null;
  const optionItems = options.map((option, index) => (
    <TextOption
      option={option}
      {...props}
      key={`current-poll-option=${index}`}
    />
  ));
  return <ul className="poll-card__details__options">{optionItems}</ul>;
}

TextOptions.propTypes = {
  options: PropTypes.array,
  optionSelected: PropTypes.any,
  selfVote: PropTypes.any,
  isExpired: PropTypes.bool,
  isPublic: PropTypes.bool,
  publicClickAction: PropTypes.func,
  handlePollSubmit: PropTypes.func,
};

export default TextOptions;
