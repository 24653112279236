import { GET_SINGLE_CAREER_OBJ } from '../actions/constants';
const location = "Noida,India",
      aboutTagTaste = "TagTaste is an online community for food professionals to discover, network and collaborate with each other. Our vision is to make TagTaste, the de-facto platform for any kind of knowledge sharing, idea sharing, professional assistance, jobs & internship opportunities, and collaborations that happens around food & beverages across the world. The beta version of our platform was launched in Oct 2017. Since then, we have on-boarded thousands of professionals and influencers across the industry."
const INITIAL_STATE = {
    selectedJob: {},
    fulltime: [
        {
            url: "ui-ux-designer",
            designation: "UI/UX Designer",
            location,
            experience: "Mid-senior Level",
            aboutTagTaste,
            jobTagTaste:"TagTaste is a full stack platform with host of services such as profile builder, social-professional networking, chat, job portal, collaboration portal, recipe portal, built-in search engine and a lot of such services in the pipeline.",
            description:"We are looking for some great designers to join our Product Design (UI/UX) team. We are working on some very interesting ideas and you will have an opportunity to work on every aspect of the product / ecosystem development – Web, mobile (Android & iOS) and marketing. Ideal candidate would have 1-4 years of relevant work experience in developing great UI/UX designs for new age web & mobile applications (Startup experience would be additional). If the idea of owning the design element of an entire product line from its inception to scale excites you then this profile is meant just for you.",
            roles:[
                "Collaborate with product managers and other stakeholders to freeze project requirements;",
                "Create design elements for various stages of the product development life cycle – user experience flow, wireframes, sketches and interactive prototypes, high resolution mockups, marketing creatives and design assets",
                "Being an advocate of the end consumer, developing designs accordingly & getting other stakeholders on the same plate;",
                "In short – conceptualize the product flow from users point of view, work with teams to refine your designs, provide UI elements of those designs to developers and contribute to spread the word once the product is ready!"],
            requirements :[
                "Deep understanding of various design patterns with an eye of when is the right time to design for broader used cases keeping the end goal in mind;",
                "Strong experience in shipping products, getting engineering team together on your design, motivating them to get the right design implemented;",
                "Ability to understand user requirements and converting them to a meaningful & working design;",
                "Imagination to come up with good designs with limited information, tools and time at your disposal;",
                "You don’t necessarily have to be able to build your own web app but you’re fluent in the technologies required to create the framework. Bonus points if you’re savvy in HTML/CSS.",
                "Ability to come up with great prototypes in small duration;",
                "Track record of developing cross platform designs – Web / apps, different browsers, smartphones (iOS / Android) – Understanding of various platforms and their featureset to enable a faster development with up-to-date design principles;",
            ],
            preferred :[
                "A track record of successfully shipped product designs",
                "Apps / websites / tools that you’ve designed that you’re proud to share",
                "Your work till now – Behance profile etc."
            ]
        },
        {
            url: "ios-software-developer",
            designation: "iOS Software Developer",
            location,
            experience:"Mid-senior Level",
            aboutTagTaste,
            jobTagTaste:"TagTaste is a full stack platform with host of services such as profile builder, social-professional networking, chat, job portal, collaboration portal, recipe portal, built-in search engine and a lot of such services in the pipeline.",
            description:"We are looking for seasoned iOS developers with 2-5 years of prior work experience of developing highly scalable iPhone / iPad / Mac applications. You would be joining our core engineering team that is responsible to design, develop & maintain our entire iOS suite of products. The work is challenging and will require you to work with multiple stakeholders simultaneously. Those who have passion to create an awesome mobile user experiences with a razor sharp focus on the usability of your apps by the end consumers would love to be a part of this team.",
            roles:[
                "Collaborate with Program Managers to freeze your project requirements;",
                "Design the architecture – Databases, security, UI/UX flow, consumer endpoints etc.;",
                "Code, code & code some more;",
                "Testing & bug fixes;",
                "Collaborate with stakeholders – Sales & marketing, support, UI/UX designers to ensure timely delivery at highest quality;",
                "Work with customer support from tech side;",
                "Manage product in growth phase;",
            ],
            requirements :[
                "Strong expertise in shippping iOS applications to a large number of users",
                "Expertise in iOS (Apple) SDKs, Xcode, Objective C, Swift and frameworks like foundation, UI Kit, Core Data etc.",
                "Experience in HTTPS, JSON, OAuth, SQL and RESTful APIs",
                "Mobile Analytics/Reporting",
                "Strong programming fundamentals – Data structures & algorithms",
                "Strong understanding of database designs",
                "Experience of debugging, performance measurement, and test-driven development",
                "Obsession of creating the highest quality product on time",
                "B.Tech./B.E./BS/MS/Phd in Computer Science & Engineering or related field"
            ],
            preferred:[
                "A track record of successfully shipped software",
                "Apps you’ve shipped on the Appstore that you’re proud to share",
                "Experience with growth projects and environments",
                "An open source profile (such as Github) with some cool projects"
            ]
        },
        {
            url: "android-software-developer",
            designation: "Android Software Developer",
            location,
            experience:"Mid-senior Level",
            aboutTagTaste,
            jobTagTaste:"TagTaste is a full stack platform with host of services such as profile builder, social-professional networking, chat, job portal, collaboration portal, recipe portal, built-in search engine and a lot of such services in the pipeline.",
            description:"We are looking for seasoned Android developers with 2-5 years of prior work experience of developing highly scalable Android / Java / mobile applications. You would be joining our core engineering team that is responsible to design, develop & maintain our entire Android suite of products. The work is challenging and will require you to work with multiple stakeholders simultaneously. Those who have passion to create an awesome mobile user experiences with a razor sharp focus on the usability of your apps by the end consumers would love to be a part of this team.",
            roles:[
                "Collaborate with Program Managers to freeze your project requirements;",
                "Design the architecture – Databases, security, UI/UX flow, consumer endpoints etc.;",
                "Code, code & code some more;",
                "Testing & bug fixes;",
                "Collaborate with stakeholders – Sales & marketing, support, UI/UX designers to ensure timely delivery at highest quality;",
                "Work with customer support from tech side;",
                "Manage product in growth phase;"

            ],
            requirements :[
                "Strong expertise in Java",
                "Working knowledge of Android SDKs",
                "RXJava",
                "Unit Testing: JUnit, Roboelectric, Mockito",
                "Mobile Analytics/Reporting",
                "Strong programming fundamentals – Data structures & algorithms",
                "Strong understanding of database designs",
                "Experience of debugging, performance measurement, and test-driven development",
                "B.Tech./B.E./BS/MS/Phd in Computer Science & Engineering or related field",
                "Experience in HTTPS, JSON, OAuth, SQL and RESTful APIs",
            ],
            preferred:[
                "A track record of successfully shipped software",
                "Apps you’ve shipped on the store that you’re proud to share",
                "Experience with growth projects and environments",
                "An open source profile (such as Github) with some cool projects"

            ]
        },
        {
            url: "backend-devops-developer",
            designation: "Backend/Devops Developer",
            location,
            experience:"3-6 Years",
            aboutTagTaste,
            jobTagTaste:"TagTaste is a full stack platform with host of services such as profile builder, social-professional networking, chat, job portal, collaboration portal, recipe portal, built-in search engine and a lot of such services in the pipeline.",
            description:"We are looking for seasoned senior backend and devops developer with 3-6 years of prior work experience of developing highly scalable web applications. You would be joining our core engineering team that is responsible to design, develop & maintain our entire suite of products. The work is challenging and will require you to work with multiple stakeholders simultaneously. Those who love to create a system from scratch and watch it scale right in front of their eyes would love this profile.",
            roles:[
                "Collaborate with Program Managers to freeze your project requirements;",
                "Design the architecture – Databases, backend APIs, security, dashboards, UI/UX flow, consumer endpoints etc.;",
                "Code, code & code some more;",
                "Testing & bug fixes;",
                "Collaborate with stakeholders – Sales & marketing, support, UI/UX designers to ensure timely delivery at highest quality;",
                "Work with customer support from tech side;",
                "Manage product in growth phase;"
            ],
            requirements:[
                "3-6 years experience of developing good web based projects (startup people will be preferred)",
                "Working knowledge of PHP and other web scripting languages",
                "Sound fundamentals of data structures & algorithms",
                "Proficient in Javascript, Bootstrap framework and Laravel framework",
                "2-5 years of experience with Python other scripting languages such as Perl, Ruby etc",
                "Proficient in DevOps delivery pipeline and AWS cloud with experience in source code management",
                "Proficient in working with MySQL (must) and NoSQL (optional) and distributed databases like Cassandra / Redis (optional)",
                "Understanding & prior experience of cross browser development",
                "An eye for detailing with great understanding of scalable architecture at best performance",
                "Experience of debugging, performance measurement, and test-driven development",
                "An open source profile (such as Github) with some cool projects"
            ],
            preferred:[
                "A track record of successfully shipped software",
                "Websites you’ve shipped on the store that you’re proud to share",
                "Experience with growth projects and environments",
                "An open source profile (such as Github) with some cool projects"

            ]


        },
        {
            url: "frontend-developer",
            designation: "Frontend Developer",
            location,
            experience:"2-5 Years",
            aboutTagTaste,
            jobTagTaste:"TagTaste is a full stack platform with host of services such as profile builder, social-professional networking, chat, job portal, collaboration portal, recipe portal, built-in search engine and a lot of such services in the pipeline.",
            description:"We are looking for seasoned Front-end Developers with 2-5 years of prior work experience in developing highly scalable web applications. You would be joining our core engineering team that is responsible to design, develop & maintain our entire suite of products. The work is challenging and will require you to work with multiple stakeholders simultaneously. Those who love to create a system from scratch and watch it scale right in front of their eyes would love this profile.",
            roles:[
                "Collaborate with Program Managers to freeze your project requirements;",
                "Design the architecture - UI/UX flow, consumer endpoints etc.;",
                "Code, code & code some more;",
                "Testing & bug fixes;",
                "Collaborate with stakeholders – Sales & marketing, support, UI/UX designers to ensure timely delivery at highest quality;",
                "Work with customer support from tech side;",
                "Manage product in growth phase;"
            ],
            requirements:[
                "2-5 years experience of developing good web based projects (startup people will be preferred)",
                "Develop new user-facing features",
                "Sound fundamentals of data structures & algorithms",
                "Proficient understanding of web markup, including HTML5, CSS3",
                "Good understanding of server-side CSS pre-processing platforms, such as LESS and SASS",
                "Proficient understanding of client-side scripting and JavaScript frameworks, including jQuery",
                "Good understanding of advanced JavaScript libraries and frameworks, such as AngularJS, ReactJS etc.",
                "Good understanding of SEO principles and ensuring that application will adhere to them",
                "Build reusable code and libraries for future use",
                "Ensure the technical feasibility of UI/UX designs",
                "Optimize application for maximum speed and scalability",
                "Collaborate with other team members and stakeholders"
            ],
            preferred:[
                "A track record of successfully shipped software",
                "Websites you’ve shipped on the store that you’re proud to share",
                "Experience with growth projects and environments",
                "An open source profile (such as Github) with some cool projects"

            ]
        },
        {
            url: "product-manager",
            designation: "Product Manager",
            location,
            experience:"2-5 Years",
            aboutTagTaste,
            jobTagTaste:"TagTaste is a full stack platform with host of services such as profile builder, social-professional networking, chat, job portal, collaboration portal, recipe portal, built-in search engine and a lot of such services in the pipeline.",
            description:"We are looking to hire seasoned professionals with 2-5 years of relevant work experience to join us as Product Managers (PM). PMs are responsible for the design, development and launch of modules in their assigned areas. They are the bridge between the tech team and outside world and anyone with a strong technical background coupled with a business acumen would be an ideal candidate for this profile.",
            roles:[
                "Completely own the product vision, roadmap and implementation of your assigned areas",
                "Develop a deep understanding of the user base / target audience of our platform",
                "Work with various stakeholders to come up with improvements and / or required features",
                "Define project timelines, track & report the status with various stakeholders",
                "Work with the design team to create wireframes and the final pages / screens",
                "Work with the development team to implement the modules",
                "Work with business development, marketing and PR team to launch the features and ensure maximum usage by our users",
                "Take user feedback and repeat above steps"
            ],
            requirements:[
                "2-5 years of relevant work experience in shipping high-quality consumer or enterprise internet products at scale",
                "Strong background of data and analytical tools such as Google Analytics, A/B testing etc. and a demonstrated capability of making data driven decisions",
                "Extreme focus on building the right user experience that suits our target audience",
                "Excellent communication skills to communicate seamlessly and clearly with engineering, design, executive management and user of TagTaste",
                "Willingness to work and lead in a highly dynamic environment with a young team and strict timelines",
                "Prior experience of managing all aspects of a successful product throughout its lifecycle",
                "A engineering degree (B.E. / B.Tech.), or equivalent experience in a high growth tech company or startup."
            ],
            preferred:[
                "Your past projects where you have contributed in architecture design and/or development in either of the following languages: PHP, Java, Android, iOS (Swift / Objective C)",
                "Your past projects of working as a PM (product and/or project manager)"
            ]
        },
        {
            url: "content-associate",
            designation: "Content Associate",
            location,
            experience:"0-1 Years",
            aboutTagTaste,
            jobTagTaste:"TagTaste is a full stack platform with host of services such as profile builder, social-professional networking, chat, job portal, collaboration portal, recipe portal, built-in search engine and a lot of such services in the pipeline.",
            roles:[
                "Making sure that we have all the information required to onboard people onto the platform.",
                "Ensuring the quality of the content is maintained throughout the platform.",
                "Tracking and making sure the website is free of any malicious content.",
                "You will be the face of Company, responding and resolving all the issues/queries that people might have.",
                "Interest in writing"
            ],
            requirements:[
                "Strong expertise in understanding Social Media",
                "Expertise in Content Management.",
                "Strong English Grammar Fundamentals",
                "Experience of performance measurement, and test-driven development",
                "Obsession of creating the highest quality product on time",
                "B.Tech./B.E./BS/MS/Phd in Computer Science & Engineering or related field",
                "Strong analytical skills and data-driven thinking",
                "Excellent Communication and written skills",
                "Graduate degree (BA, B-tech, B-Com etc)",
                "Strong interest in content research.",
                "Comprehensive thinking."
            ],
            preferred:[
                "Your past projects where you have contributed in architecture design and/or development in either of the following languages: PHP, Java, Android, iOS (Swift / Objective C)",
                "Your past projects of working as a PM (product and/or project manager)"
            ]
        },
        {
            url: "devOps",
            designation: "devOps",
            location,
            experience: "2-5 years",
            description:"We are looking for DevOps with 2-5 years of prior work experience of developing highly scalable web applications. You would be joining our core engineering team that is responsible to design, develop & maintain our entire suite of products. The work is challenging and will require you to work with multiple stakeholders simultaneously. Those who love to create a system from scratch and watch it scale right in front of their eyes would love this profile.",
            aboutTagTaste,
            jobTagTaste:"TagTaste is a full stack platform with host of services such as profile builder, social-professional networking, chat, job portal, collaboration portal, recipe portal, built-in search engine and a lot of such services in the pipeline.",
            roles:[
                "Collaborate with Program Managers to freeze your project requirements",
                "Design the architecture – Databases, servers, backups, API optimizations, security, dashboards, server uptime, resource optimization etc.",
                "Closely work with the Backend Developers to optimize our platform for speed and quality",
                "Code, test, and deploy",
                "Ensure 99.99999% server uptime"
            ],
            requirements:[
                "Bachelor's degree in Computer Science or equivalent",
                "2-5 years of experience with Python other scripting languages such as Perl, Ruby etc",
                "Data management skills",
                "Comfort with collaboration, open communication and reaching across functional borders",
                "Proficient in DevOps delivery pipeline and AWS cloud with experience in source code management",
                "Good knowledge and expertise on AWS infra architecting methods and best practices ",
                "Experience in developing and maintaining CI/CD process for enterprise SaaS and on-prem applications using tools like Git, Gerrit, Jenkins, Maven, Gradle, etc",
                "Expertise in Linux System Admin and Bash Scripting",
                "Experience with configuration management tools such as Ansible, Chef, Fabric, Puppet or SaltStack",
                "Experience in designing and maintaining cloud-based solutions",
                "Application and network security",
                "Cloud deployment infrastructure"
            ],
            preferred:[
                "A track record of successfully shipped software",
                "Websites you’ve shipped on the store that you’re proud to share",
                "Experience with growth projects and environments",
                "An open source profile (such as Github) with some cool projects"
            ]
        },
        {
            url: "backend-developer",
            designation: "Backend Developer",
            location,
            experience: "2-5 years",
            description:"We are looking for seasoned DevOps Engineer with 2-5 years of prior relevant work experience in managing highly scalable web applications. You would be joining our core engineering team that is responsible to design, develop & maintain our entire suite of products. The work is challenging and will require you to work with multiple stakeholders simultaneously. Those who love to create a system from scratch and watch it scale right in front of their eyes would love this profile.",
            aboutTagTaste,
            jobTagTaste:"TagTaste is a full stack platform with a host of services such as profile builder, social-professional networking, chat, job portal, collaboration portal, recipe portal, built-in search engine and a lot of such services in the pipeline.",
            roles:[
                "Collaborate with Program Managers to freeze your project requirements",
                "Design the architecture – Databases, servers, backups, API optimizations, security, dashboards, server uptime, resource optimization etc.",
                "Closely work with the Backend Developers to optimize our platform for speed and quality",
                "Code, test, and deploy",
                "Ensure 99.99999% server uptime"
            ],
            requirements:[
                "Bachelor's degree in Computer Science or equivalent",
                "2-5 years of experience with Python other scripting languages such as Perl, Ruby etc",
                "Data management skills",
                "expert in PHP (Laravel), NodeJs, AWS ",
                "Comfort with collaboration, open communication and reaching across functional borders",
                "Proficient in DevOps delivery pipeline and AWS cloud with experience in source code management",
                "Good knowledge and expertise on AWS infra architecting methods and best practices ",
                "Experience in developing and maintaining CI/CD process for enterprise SaaS and on-prem applications using tools like Git, Gerrit, Jenkins, Maven, Gradle, etc",
                "Expertise in Linux System Admin and Bash Scripting",
                "Experience with configuration management tools such as Ansible, Chef, Fabric, Puppet or SaltStack",
                "Experience in designing and maintaining cloud-based solutions",
                "Application and network security",
                "Cloud deployment infrastructure"
            ],
            preferred:[
                "A track record of successfully shipped software",
                "Websites you’ve shipped on the store that you’re proud to share",
                "Experience with growth projects and environments",
                "An open source profile (such as Github) with some cool projects"
            ]
        },
        {
            url: "web-developer-full-stack",
            designation: "Web Developer Full Stack",
            location,
            experience: "2-5 years",
            description: "We are looking for seasoned full stack web developers with 2-5 years of prior work experience of developing highly scalable web applications. You would be joining our core engineering team that is responsible to design, develop & maintain our entire suite of products. The work is challenging and will require you to work with multiple stakeholders simultaneously. Those who love to create a system from scratch and watch it scale right in front of their eyes would love this profile.",
            aboutTagTaste,
            jobTagTaste:"TagTaste is a full stack platform with a host of services such as profile builder, social-professional networking, chat, job portal, collaboration portal, recipe portal, built-in search engine and a lot of such services in the pipeline.",
            roles: [
                "Collaborate with Program Managers to freeze your project requirements;",
                "Design the architecture – Databases, backend APIs, security, dashboards, UI/UX flow, consumer endpoints etc.;",
                "Code, code & code some more;",
                "Testing & bug fixes;",
                "Collaborate with stakeholders – Sales & marketing, support, UI/UX designers to ensure timely delivery at highest quality;",
                "Work with customer support from tech side;",
                "Manage product in growth phase;"
            ],
            requirements:[
                "2-5 years experience of developing good web based projects (startup people will be preferred)",
                "Working knowledge of PHP and other web scripting languages",
                "Sound fundamentals of data structures & algorithms",
                "Proficient in Javascript, Bootstrap framework and Laravel framework",
                "Proficient in working with MySQL (must) and NoSQL (optional) and distributed databases like Cassandra / Redis (optional)",
                "Sound knowledge of creating RESTful APIs and efficient algorithms / DB queries to create an optimal solution for DB record & fetching",
                "Experience of developing admin dashboards & front end websites",
                "Understanding & prior experience of cross browser development",
                "An eye for detailing with great understanding of scalable architecture at best performance",
                "B.Tech./B.E./BS/MS/Phd in Computer Science & Engineering or related field",
                "Experience of debugging, performance measurement, and test-driven development",
                "An open source profile (such as Github) with some cool projects"
            ],
            preferred: [
                "A track record of successfully shipped software",
                "Websites you’ve shipped on the store that you’re proud to share",
                "Experience with growth projects and environments",
                "An open source profile (such as Github) with some cool projects"
            ]
        }
    ],
    internship: [
        {
            url: "ui-ux-designer",
            designation: "UI/UX Designer",
            location,
            aboutTagTaste,
            jobTagTaste:"TagTaste is a full stack platform with host of services such as profile builder, social-professional networking, chat, job portal, collaboration portal, recipe portal, built-in search engine and a lot of such services in the pipeline.",
            roles:[
                "Collaborate with product managers and other stakeholders to freeze project requirements;",
                "Create design elements for various stages of the product development life cycle – user experience flow, wireframes, sketches and interactive prototypes, high resolution mockups, marketing creatives and design assets",
                "Being an advocate of the end consumer, developing designs accordingly & getting other stakeholders on the same plate;",
                "In short – conceptualize the product flow from users point of view, work with teams to refine your designs, provide UI elements of those designs to developers and contribute to spread the word once the product is ready!"],
            requirements :[
                "Deep understanding of various design patterns with an eye of when is the right time to design for broader used cases keeping the end goal in mind;",
                "Strong experience in shipping products, getting engineering team together on your design, motivating them to get the right design implemented;",
                "Ability to understand user requirements and converting them to a meaningful & working design;",
                "Imagination to come up with good designs with limited information, tools and time at your disposal;",
                "You don’t necessarily have to be able to build your own web app but you’re fluent in the technologies required to create the framework. Bonus points if you’re savvy in HTML/CSS.",
                "Ability to come up with great prototypes in small duration;",
                "Track record of developing cross platform designs – Web / apps, different browsers, smartphones (iOS / Android) – Understanding of various platforms and their featureset to enable a faster development with up-to-date design principles;",
            ],
            preferred :[
                "A track record of successfully shipped product designs",
                "Apps / websites / tools that you’ve designed that you’re proud to share",
                "Your work till now – Behance profile etc."
            ]
        },
        {
            url: "ios-software-developer",
            designation: "IOS Software Developer",
            location,
            aboutTagTaste,
            jobTagTaste:"TagTaste is a full stack platform with host of services such as profile builder, social-professional networking, chat, job portal, collaboration portal, recipe portal, built-in search engine and a lot of such services in the pipeline.",
            roles:[
                "Collaborate with Program Managers to freeze your project requirements;",
                "Design the architecture – Databases, security, UI/UX flow, consumer endpoints etc.;",
                "Code, code & code some more;",
                "Testing & bug fixes;",
                "Collaborate with stakeholders – Sales & marketing, support, UI/UX designers to ensure timely delivery at highest quality;",
                "Work with customer support from tech side;",
                "Manage product in growth phase;",
            ],
            requirements :[
                "Strong expertise in shippping iOS applications to a large number of users",
                "Expertise in iOS (Apple) SDKs, Xcode, Objective C, Swift and frameworks like foundation, UI Kit, Core Data etc.",
                "Experience in HTTPS, JSON, OAuth, SQL and RESTful APIs",
                "Mobile Analytics/Reporting",
                "Strong programming fundamentals – Data structures & algorithms",
                "Strong understanding of database designs",
                "Experience of debugging, performance measurement, and test-driven development",
                "Obsession of creating the highest quality product on time",
                "B.Tech./B.E./BS/MS/Phd in Computer Science & Engineering or related field"
            ],
            preferred:[
                "A track record of successfully shipped software",
                "Apps you’ve shipped on the Appstore that you’re proud to share",
                "Experience with growth projects and environments",
                "An open source profile (such as Github) with some cool projects"
            ]
        },
        {
            url: "android-software-developer",
            designation: "Android Software Developer",
            location,
            aboutTagTaste,
            jobTagTaste:"TagTaste is a full stack platform with host of services such as profile builder, social-professional networking, chat, job portal, collaboration portal, recipe portal, built-in search engine and a lot of such services in the pipeline.",
            roles:[
                "Collaborate with Program Managers to freeze your project requirements;",
                "Design the architecture – Databases, security, UI/UX flow, consumer endpoints etc.;",
                "Code, code & code some more;",
                "Testing & bug fixes;",
                "Collaborate with stakeholders – Sales & marketing, support, UI/UX designers to ensure timely delivery at highest quality;",
                "Work with customer support from tech side;",
                "Manage product in growth phase;"

            ],
            requirements :[
                "Strong expertise in Java",
                "Working knowledge of Android SDKs",
                "RXJava",
                "Unit Testing: JUnit, Roboelectric, Mockito",
                "Mobile Analytics/Reporting",
                "Strong programming fundamentals – Data structures & algorithms",
                "Strong understanding of database designs",
                "Experience of debugging, performance measurement, and test-driven development",
                "B.Tech./B.E./BS/MS/Phd in Computer Science & Engineering or related field",
                "Experience in HTTPS, JSON, OAuth, SQL and RESTful APIs",
            ],
            preferred:[
                "A track record of successfully shipped software",
                "Apps you’ve shipped on the store that you’re proud to share",
                "Experience with growth projects and environments",
                "An open source profile (such as Github) with some cool projects"

            ]
        },
        {
            url: "web-developer-full-stack",
            designation: "Web Developer Full Stack",
            location,
            aboutTagTaste,
            jobTagTaste:"TagTaste is a full stack platform with host of services such as profile builder, social-professional networking, chat, job portal, collaboration portal, recipe portal, built-in search engine and a lot of such services in the pipeline.",
            roles:[
                "Collaborate with Program Managers to freeze your project requirements;",
                "Design the architecture – Databases, backend APIs, security, dashboards, UI/UX flow, consumer endpoints etc.;",
                "Code, code & code some more;",
                "Testing & bug fixes;",
                "Collaborate with stakeholders – Sales & marketing, support, UI/UX designers to ensure timely delivery at highest quality;",
                "Work with customer support from tech side;",
                "Manage product in growth phase;"
            ],
            requirements:[
                "Working knowledge of PHP and other web scripting languages",
                "Sound fundamentals of data structures & algorithms",
                "Proficient in Javascript, Bootstrap framework and Laravel framework",
                "Proficient in working with MySQL (must) and NoSQL (optional) and distributed databases like Cassandra / Redis (optional)",
                "Sound knowledge of creating RESTful APIs and efficient algorithms / DB queries to create an optimal solution for DB record & fetching",
                "Experience of developing admin dashboards & front end websites",
                "Understanding & prior experience of cross browser development",
                "An eye for detailing with great understanding of scalable architecture at best performance",
                "B.Tech./B.E./BS/MS/Phd in Computer Science & Engineering or related field",
                "Experience of debugging, performance measurement, and test-driven development",
                "An open source profile (such as Github) with some cool projects"
            ],
            preferred:[
                "A track record of successfully shipped software",
                "Websites you’ve shipped on the store that you’re proud to share",
                "Experience with growth projects and environments",
                "An open source profile (such as Github) with some cool projects"

            ]


        },
        {
            url:"frontend-developer",
            designation: "Frontend Developer",
            location,
            aboutTagTaste,
            jobTagTaste:"TagTaste is a full stack platform with host of services such as profile builder, social-professional networking, chat, job portal, collaboration portal, recipe portal, built-in search engine and a lot of such services in the pipeline.",
            roles:[
                "Collaborate with Program Managers to freeze your project requirements;",
                "Design the architecture - UI/UX flow, consumer endpoints etc.;",
                "Code, code & code some more;",
                "Testing & bug fixes;",
                "Collaborate with stakeholders – Sales & marketing, support, UI/UX designers to ensure timely delivery at highest quality;",
                "Work with customer support from tech side;",
                "Manage product in growth phase;"
            ],
            requirements:[
                "Develop new user-facing features",
                "Sound fundamentals of data structures & algorithms",
                "Proficient understanding of web markup, including HTML5, CSS3",
                "Good understanding of server-side CSS pre-processing platforms, such as LESS and SASS",
                "Proficient understanding of client-side scripting and JavaScript frameworks, including jQuery",
                "Good understanding of advanced JavaScript libraries and frameworks, such as AngularJS, ReactJS etc.",
                "Good understanding of SEO principles and ensuring that application will adhere to them",
                "Build reusable code and libraries for future use",
                "Ensure the technical feasibility of UI/UX designs",
                "Optimize application for maximum speed and scalability",
                "Collaborate with other team members and stakeholders"
            ],
            preferred:[
                "A track record of successfully shipped software",
                "Websites you’ve shipped on the store that you’re proud to share",
                "Experience with growth projects and environments",
                "An open source profile (such as Github) with some cool projects"

            ]
        },
        {
            url: "content-associate",
            designation: "Content Associate",
            location,
            aboutTagTaste,
            jobTagTaste:"TagTaste is a full stack platform with host of services such as profile builder, social-professional networking, chat, job portal, collaboration portal, recipe portal, built-in search engine and a lot of such services in the pipeline.",
            roles:[
                "Making sure that we have all the information required to onboard people onto the platform.",
                "Ensuring the quality of the content is maintained throughout the platform.",
                "Tracking and making sure the website is free of any malicious content.",
                "You will be the face of Company, responding and resolving all the issues/queries that people might have.",
                "Interest in writing"
            ],
            requirements:[
                "Strong expertise in understanding Social Media",
                "Expertise in Content Management.",
                "Strong English Grammar Fundamentals",
                "Experience of performance measurement, and test-driven development",
                "Obsession of creating the highest quality product on time",
                "B.Tech./B.E./BS/MS/Phd in Computer Science & Engineering or related field",
                "Strong analytical skills and data-driven thinking",
                "Excellent Communication and written skills",
                "Graduate degree (BA, B-tech, B-Com etc)",
                "Strong interest in content research.",
                "Comprehensive thinking."
            ],
            preferred:[
                "Your past projects where you have contributed in architecture design and/or development in either of the following languages: PHP, Java, Android, iOS (Swift / Objective C)",
                "Your past projects of working as a PM (product and/or project manager)"
            ]
        }
    ]
}
export default function careers(state = INITIAL_STATE, action) {
    switch(action.type) {
        case GET_SINGLE_CAREER_OBJ:
            const selectedJob = state[action.payload.type].filter((jobObj) => jobObj.url === action.payload.url).length ? state[action.payload.type].filter((jobObj) => jobObj.url === action.payload.url)[0] : false
            return {
                ...state,
                selectedJob: selectedJob
            }
        default:
            return state;
    }
}