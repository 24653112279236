import {
    GET_CHAT_LIST_REQUEST,
    GET_CHAT_LIST_SUCCESS,
    MARK_MESSAGE_READ_SUCCESS,
    NEW_MESSAGE_SENT_FROM_SOCKET,
    UPDATE_OR_APPEND_CHAT_INFO,
    CREATE_NEW_GROUP_SUCCESS,
    NEW_CHAT_MESSAGE_SUCCESS,
    CLEAR_CHAT_SUCCESS,
    DELETE_MEMBER_FROM_CHAT_SUCCESS,
    RESET_CHAT,
    UPDATE_GROUP_INFO_SUCCESS
} from '../../actions/chat/constants';
const INITIAL_STATE = {
    fetching: false,
    errors: false,
    hasChecked: false,
    isInvalidated: false,
    hasEndReached: false,
    recentChats: [],
    page: 1
}
// Function to update recent chat count when reading a message
const updateRecentChatCount = (chatList, payload) => {
    return chatList.map(chat => {
        if (parseInt(chat.id) === parseInt(payload.chatId)) {
            chat.unreadMessageCount = 0
            // chat.unreadMessageCount = chat.unreadMessageCount - payload.data
        }
        return chat;
    });
}

// Disabling a chat
const disableChat = (chatList, payload) => {
    let currentChatListItem = chatList.findIndex(chat => parseInt(chat.id) === parseInt(payload.chatId));
    if (currentChatListItem === -1) {
        return chatList;
    }
    return chatList.map(chat => {
        if (parseInt(chat.id) === parseInt(payload.chatId) && parseInt(payload.memberId) === parseInt(chat.profile_id)) {
            chat.is_enabled = false;
        }
        return chat;
    })
}
const clearMessageFromSelectedChat = (list, payload) => {
    let chatId = payload.chatId,
        currentIndex = list.findIndex(item => parseInt(item.id) === parseInt(chatId));
    if (currentIndex === -1) {
        return;
    }
    let chatToUpdate = list[currentIndex];
    chatToUpdate['latestMessages'] = null;
    chatToUpdate['unreadMessageCount'] = 0;
    return list;
}
// Function to update or append to chat item
const updateOrAppendChatInfo = (chatList, payload) => {
    // Checking if chat item exists
    let currentChatListItemIndex = chatList.findIndex(chat => parseInt(chat.id) === parseInt(payload.id));
    // Returning new list with appended chat item
    if (currentChatListItemIndex === -1) {
        // Returning new chat list
        return [...[payload], ...chatList]
    }
    // Checking if signature available. If available -> replacing the content else putting it on the top
    let currentChatItem = chatList[currentChatListItemIndex];
    if (currentChatItem.latestMessages && currentChatItem.latestMessages.signature === payload.latestMessages.signature) {
        chatList[currentChatListItemIndex] = payload;
        return chatList;
    }
    let chatItemsWithoutCurrent = chatList.filter(chat => parseInt(chat.id) !== parseInt(payload.id)),
        newChatItem = payload
    return [...[newChatItem], ...chatItemsWithoutCurrent]
}

// Function to bring current message on top after sending a message
const updateRecentChatList = (chatList, payload) => {
    let currentChatListItem = chatList.filter(chat => parseInt(chat.id) === parseInt(payload.chatId));
    // If chat Item exists
    if (currentChatListItem.length) {
        currentChatListItem = currentChatListItem[0];
        let chatListWithoutCurrentChatItem = chatList.filter(chat => parseInt(chat.id) !== parseInt(payload.chatId)),
            updatedCurrentChatListItem = [{
                ...currentChatListItem,
                latestMessages: {
                    ...currentChatListItem.latestMessages,
                    message: payload.message
                },
                signature: payload.signature
            }];
        return [...updatedCurrentChatListItem, ...chatListWithoutCurrentChatItem];
    } else {
        // If chat item doesn't exists
        return chatList;
    }
}
const getInitialState = () => INITIAL_STATE;
export default function leftPanel(state = getInitialState(), action) {
    switch (action.type) {
        case GET_CHAT_LIST_REQUEST:
            return {
                ...state,
                fetching: true
            };
        case GET_CHAT_LIST_SUCCESS:
            return {
                ...state,
                recentChats: state.page === 1 ? [...action.payload] : [...state.recentChats, ...action.payload],
                page: state.page + 1,
                hasEndReached: !action.payload.length,
                fetching: false
            }
        case MARK_MESSAGE_READ_SUCCESS:
            return {
                ...state,
                recentChats: updateRecentChatCount(state.recentChats, action.payload)
            }
        case NEW_MESSAGE_SENT_FROM_SOCKET:
            return {
                ...state,
                recentChats: updateRecentChatList(state.recentChats, action.payload)
            }
        case CREATE_NEW_GROUP_SUCCESS:
        case UPDATE_GROUP_INFO_SUCCESS:
            return {
                ...state,
                recentChats: updateOrAppendChatInfo(state.recentChats, action.payload),
                fetching: false
            }
        case UPDATE_OR_APPEND_CHAT_INFO:
        case NEW_CHAT_MESSAGE_SUCCESS:
            return {
                ...state,
                recentChats: updateOrAppendChatInfo(state.recentChats, action.payload)
            }
        case CLEAR_CHAT_SUCCESS:
            return {
                ...state,
                recentChats: clearMessageFromSelectedChat(state.recentChats, action.payload),
            }
        case DELETE_MEMBER_FROM_CHAT_SUCCESS:
            return {
                ...state,
                recentChats: disableChat(state.recentChats, action.payload)
            }
        case RESET_CHAT:
            return INITIAL_STATE
        default:
            return state;
    }
}
