//get tating headers
export const GET_TASTING_HEADERS_REQUEST = 'GET_TASTING_HEADERS_REQUEST';
export const GET_TASTING_HEADERS_SUCCESS = 'GET_TASTING_HEADERS_SUCCESS';
export const GET_TASTING_HEADERS_FAILURE = 'GET_TASTING_HEADERS_FAILURE';
export const RESET_TASTING_HEADERS = 'RESET_TASTING_HEADERS';
//get tasting data by header
export const GET_TASTING_DATA_BY_HEADER_REQUEST = 'GET_TASTING_DATA_BY_HEADER_REQUEST';
export const GET_TASTING_DATA_BY_HEADER_SUCCESS = 'GET_TASTING_DATA_BY_HEADER_SUCCESS';
export const GET_TASTING_DATA_BY_HEADER_FAILURE = 'GET_TASTING_DATA_BY_HEADER_FAILURE';

//submit tasting data by header
export const SUBMIT_TASTING_DATA_BY_HEADER_REQUEST = 'SUBMIT_TASTING_DATA_BY_HEADER_REQUEST';
export const SUBMIT_TASTING_DATA_BY_HEADER_SUCCESS = 'SUBMIT_TASTING_DATA_BY_HEADER_SUCCESS';
export const SUBMIT_TASTING_DATA_BY_HEADER_FAILURE = 'SUBMIT_TASTING_DATA_BY_HEADER_FAILURE';

//update answer
export const UPDATE_ANSWER_REQUEST = 'UPDATE_ANSWER_REQUEST';

//show Intensity Option
export const SHOW_INTENSITY_OPTION = 'SHOW_INTENSITY_OPTION';

// toggle textual intensity
export const TOGGLE_TEXTUAL_INTENSITY_OPTION = 'TOGGLE_TEXTUAL_INTENSITY_OPTION';

//upload selfie
export const UPLOAD_SELFIE_REQUEST = 'UPLOAD_SELFIE_REQUEST';
export const START_REVIEW_SUCCESS = 'START_REVIEW_SUCCESS';
