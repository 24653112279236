// get posts constants
export const GET_SHOUTOUT_DETAILS_REQUEST = 'GET_SHOUTOUT_DETAILS_REQUEST';
export const GET_SHOUTOUT_DETAILS_SUCCESS = 'GET_SHOUTOUT_DETAILS_SUCCESS';
export const GET_SHOUTOUT_DETAILS_FAILURE = 'GET_SHOUTOUT_DETAILS_FAILURE';
export const RESET_SHOUTOUT_DETAILS = 'RESET_SHOUTOUT_DETAILS';

// get photo constants
export const GET_PHOTO_DETAILS_REQUEST = 'GET_PHOTO_DETAILS_REQUEST';
export const GET_PHOTO_DETAILS_SUCCESS = 'GET_PHOTO_DETAILS_SUCCESS';
export const GET_PHOTO_DETAILS_FAILURE = 'GET_PHOTO_DETAILS_FAILURE';
export const RESET_PHOTO_DETAILS = 'RESET_PHOTO_DETAILS';

// get shared post details
export const GET_SHARED_POST_DETAILS_REQUEST = 'GET_SHARED_POST_DETAILS_REQUEST';
export const GET_SHARED_POST_DETAILS_SUCCESS = 'GET_SHARED_POST_DETAILS_SUCCESS';
export const GET_SHARED_POST_DETAILS_FAILURE = 'GET_SHARED_POST_DETAILS_FAILURE';
export const RESET_SHARED_POST_DETAILS = 'RESET_SHARED_POST_DETAILS';

// get polling details
export const GET_POLL_DETAILS_REQUEST = 'GET_POLL_DETAILS_REQUEST';
export const GET_POLL_DETAILS_SUCCESS = 'GET_POLL_DETAILS_SUCCESS';
export const GET_POLL_DETAILS_FAILURE = 'GET_POLL_DETAILS_FAILURE';
export const RESET_POLL_DETAILS = 'RESET_POLL_DETAILS';

// get feed card details
export const GET_FEED_CARD_DETAILS_REQUEST = 'GET_FEED_CARD_DETAILS_REQUEST';
export const GET_FEED_CARD_DETAILS_SUCCESS = 'GET_FEED_CARD_DETAILS_SUCCESS';
export const GET_FEED_CARD_DETAILS_FAILURE = 'GET_FEED_CARD_DETAILS_FAILURE';
export const RESET_FEED_CARD_DETAILS = 'RESET_FEED_CARD_DETAILS';