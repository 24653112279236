import React from 'react'
import TickIconWhite from '../../../src/assets/images/collaborations/form/check-white.svg';
import CrossIcon from '../../../src/assets/images/collaborations/form/close.svg';
import AddToCalendar from '../misc/AddToCalendar';

const IamIn = (props) => {
    const { handleClick } = props;
    return <button className={`schedule__action  full-width cursor-pointer`} name="accept" onClick={(e) => handleClick(e)}><img src={TickIconWhite} width="auto" height="12px" />I am in</button>
}

const IamOut = (props) => {
    const { handleClick, isDetails } = props;
    return <button className={`schedule__action secondary slider-action ${!!isDetails ? "no-background" : "dark"} $ full-width cursor-pointer`} name="decline" onClick={(e) => handleClick(e)}><img src={CrossIcon} width="auto" height="12px" />I am out</button>
}

const ViewDetails = (props) => {
    const { handleInvitationModal } = props;
    return <button className={`schedule__action full-width no-background cursor-pointer`} onClick={handleInvitationModal}>View Detail</button>
}

const GetDirection = (props) => {
    const { link } = props;
    return <button className={`schedule__action full-width no-background cursor-pointer`} onClick={(e) => { e.preventDefault(); window.open(link, '_blank', 'noopener,noreferrer'); }}>Get Direction</button>
}

const OptedOut = (props) => {
    return <button className="schedule__action danger slider-action full-width">You have opted out</button>
}


function InvitationAction(props) {
    const { handleInvitationModal, isDetails, invitation, handleUpdate, isNotification = false, link, notificationViewDetail = false } = props;

    const handleClick = (e) => {
        if (!e) return;
        e.preventDefault();
        const { name } = e.target;
        handleUpdate(e, invitation.id, name);
    }

    const isPending = invitation.status === 2;
    const isAccepted = invitation.status === 1;
    const isDeclined = invitation.status === 3;

    return (
        <div className={`invitation-action ${!!isNotification ? "notification-btn" : ""}`}>
            {!!isDetails && (!!isNotification || isAccepted) && <ViewDetails handleInvitationModal={handleInvitationModal} />}
            {!isNotification && isAccepted && <>
                <AddToCalendar invitation={invitation} handleUpdate={handleUpdate} isDetails={isDetails} />
            </>}
            {!isNotification && isPending && <>
                <IamOut handleClick={handleClick} isDetails={isDetails} />
                <IamIn handleClick={handleClick} />
            </>}
            {!isNotification && !notificationViewDetail && isDeclined && <OptedOut />}
            {!!isNotification && isAccepted && <GetDirection link={link} />}
        </div>
    )
}

export default InvitationAction