import React from 'react';
import PropTypes from 'prop-types';
import PollCheckImage from '../../assets/images/poll/create/poll-check.svg';
import TemplateA from '../../assets/images/poll/create/template-a.svg';
import TemplateB from '../../assets/images/poll/create/template-b.svg';;

function ImageContainerPoll({ handleSelect,handleImagePoll,handleImagePollType}) {
  return (
    <div className="poll-create__image__template">
      <p className="poll-create__image__template__title">Please select a template</p>
      <div className="poll-create__image__template__container">
        <div
          tabIndex="0"
          onKeyDown={(e) => {
            if (e.keyCode === 13 || e.which === 13) {
              e.target.click();
            }
          }}
          aria-label="create poll with question image"
          onClick={() => {handleSelect(1);
           handleImagePoll()
           handleImagePollType()}}
        >
          <img
            className="poll-create__image__template__image"
            src={TemplateA}
            style={{ width: '100%' }}
            alt="question with option"
          />
          <img
            className="poll-create__image__template__check"
            src={PollCheckImage}
            alt="check poll"
            aria-hidden={true}
          />
        </div>
        <div
          onKeyDown={(e) => {
            if (e.keyCode === 13 || e.which === 13) {
              e.target.click();
            }
          }}
          aria-label="create poll with option image"
          tabIndex="0"
          onClick={() => {handleSelect(2)
             handleImagePoll()
             handleImagePollType()}}
        >
          <img src={TemplateB} style={{ width: '100%' }} alt="poll with option" />
          <img
            className="poll-create__image__template__check"
            src={PollCheckImage}
            alt="check poll"
            aria-hidden={true}
          />
        </div>
      </div>
    </div>
  );
}

ImageContainerPoll.propTypes = {
  handleSelect: PropTypes.func.isRequired,
};

export default ImageContainerPoll;
