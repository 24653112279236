import React, { useState,
    useEffect,
    useRef } from 'react';
import { decodeString, twoDecimal } from '../../../../utils/helper';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ReadMoreText from '../../../../containers/loggedIn/Feed/common/ReadMoreText';
import { profileLinks } from '../../../../utils/internalLinks';
import FollowBtn from '../../../profile/FollowBtn';
import FeedCardShareBtn from './FeedCardShareBtn';
import Check from '../../../../assets/images/profile/followers/check.svg';
import UserName from '../../../profile/UserName';

function ProfileFeedCard({ cardData,
    ...props }) {
    const { meta, feedCard } = cardData,
        { user: { user } } = props;
    if (!meta
        || (!feedCard || (feedCard && !feedCard.profile))) return null;
    const { profile,
        description,
        icon,
        id,
        title,
        subtitle,
        tagline,
        image_meta,
        name } = feedCard,
        { isFollowing } = meta,
        nameToShow = decodeString(name) || decodeString(profile.name),
        profileLink = profileLinks.view(profile.handle);
    const [width, setWidth] = useState(null),
        [height, setHeight] = useState(null),
        ratio = 1.84,
        ref = useRef(null);
    useEffect(() => {
        if (ref.current) {
            const parentElementWidth = ref.current.offsetWidth;
            if (parentElementWidth < 570) {
                setWidth(twoDecimal(parentElementWidth));
                setHeight(twoDecimal(parentElementWidth/ratio))
            }
        }
    }, []);
    return (<div className="feed-card" ref={ref}>
        { title && <p className="feed-card__title">{decodeString(title)}</p> }
        { subtitle && <p className="feed-card__title feed-card__title--secondary">{decodeString(subtitle)}</p> }
        <div className="feed-card__card">
            <Link className="feed-card__image__container" to={profileLink}>
                {icon && <img className="feed-card__image__icon" alt={title} src={icon} />}
                <img alt={nameToShow}
                    className="feed-card__image__img"
                    style={{ ...(width
                        && height
                        && {
                            width: `${width}px`,
                            height: `${height}px`
                        })}}
                    src={image_meta.original_photo} />
            </Link>
            <div className="feed-card__details">
                <div className="feed-card__details__meta">
                    <Link className={`feed-card__details__title ${!!profile.verified && 'feed-card__details__title--verified'}`} to={profileLink}>
                        <UserName isVerified={!!profile.verified}
                            name={nameToShow} />
                    </Link>
                    {
                        ((user
                        && user.profile
                        && user.profile.id !== profile.id)
                        || props.isPublic)
                        && <>
                            {
                                isFollowing
                                ? <button className="following-btn">
                                    <img src={Check} alt="following" />
                                    Following
                                </button>
                                : <FollowBtn isPublic={props.isPublic}
                                    className={`follow-btn ${props.isDetailsPage || props.isPublic ? 'follow-btn--big' : ''}`}
                                    publicClickAction={props.publicClickAction}
                                    profileId={profile.id}>
                                    + FOLLOW
                                </FollowBtn>
                            }
                        </>
                    }
                </div>
                { tagline && <p className="feed-card__details__tagline">{decodeString(tagline)}</p> }
                <ReadMoreText className="feed-card__details__text"
                    isPublic={props.isPublic}
                    publicClickAction={props.publicClickAction}
                    isOpen={!!props.isDetailsPage}
                    text={description} />
                <FeedCardShareBtn isPublic={props.isPublic}
                        publicClickAction={props.publicClickAction}
                        postId={id} />
            </div>
        </div>
    </div>);
};

function mapStateToProps(state) {
    return {
        user: state.user
    }
}

export default connect(mapStateToProps)(ProfileFeedCard);