import { combineReducers } from 'redux';
import summary from './summary';
import report from './report';
import comments from './comments';
import graphFilters from './graphFilters';

const reportReducer = combineReducers({
  summary,
  report,
  comments,
  graphFilters,
});

export default reportReducer;
