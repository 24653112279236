/* eslint-disable react/display-name */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import { modelRedirectFn, SliderArrow } from './utils';
import BigBannerPlaceholder from '../../assets/images/home/placeholder-bigbanner.svg';
import { gaEvents } from '../../utils/events';

const BigBanner = (props) => {
  const { data, isMobile } = props;
  const sliderRef = useRef();
  const defaultTime = 3000;
  const defaultWaitTime = 10000;

  const userClick = () => {
    if (get(data, 'autoplay', false)) {
      const timer = defaultWaitTime - get(data, 'autoplay_duration', defaultTime);
      sliderRef.current.slickPause();
      setTimeout(() => {
        if (sliderRef.current) sliderRef.current.slickPlay();
      }, timer);
    }
  };

  const handleBannerClick = (obj) => {
    const modal = get(obj, 'model_name', '');
    gaEvents.landingpage.bigbanner(modal);
  };

  const sliderSettings = {
    dots: true,
    infinite: get(data, 'loop', false),
    autoplay: get(data, 'autoplay', false),
    autoplaySpeed: get(data, 'autoplay_duration', defaultTime),
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    cssEase: 'linear',
    prevArrow: <SliderArrow to="prev" userClick={userClick} isBigbanner />,
    nextArrow: <SliderArrow to="next" userClick={userClick} isBigbanner />,
    arrows: !isMobile,
    pauseOnHover: false,
    appendDots: (dots) => {
      return (
        <div
          style={{
            marginBottom: '50px',
          }}
        >
          <ul className="feed-bigbanner__ul">
            {dots.map((item, index) => {
              return (
                <li
                  key={index}
                  className={item.props.className}
                  onClick={() => {
                    if (userClick) userClick();
                  }}
                >
                  {item.props.children}
                </li>
              );
            })}
          </ul>
        </div>
      );
    },
    customPaging: () => <div className="feed-bigbanner__customdot"></div>,
  };

  return (
    <div className="feed-bigbanner">
      <Slider ref={(re) => (sliderRef.current = re)} {...sliderSettings} className="suggestions">
        {get(data, 'elements', []).map((obj, i) => {
          const redirectUrl = modelRedirectFn(get(obj, 'model_name'), get(obj, 'model_id'));
          return (
            <Link
              key={`link-${i}`}
              className="cursor-pointer feed-bigbanner__slideritem"
              to={redirectUrl}
              onClick={() => handleBannerClick(obj)}
            >
              <img src={get(obj, 'images_meta.original_photo', BigBannerPlaceholder)} />
            </Link>
          );
        })}
      </Slider>
    </div>
  );
};

BigBanner.propTypes = {
  data: PropTypes.object,
  isMobile: PropTypes.bool,
};

export default React.memo(BigBanner);
