import React from 'react';

const PaidRibbon = () => {
  return (
    <div className="paidribbon__rectangle">
      <div className="paidribbon__paid">
        <div className="paidribbon__glow"></div>
        PAID
      </div>
    </div>
  );
};

export default PaidRibbon;
