import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import LoggedOutBar from '../../components/bars/LoggedOutBar';
import { miscLinks } from '../../utils/internalLinks';
import { screenBreakpoints, seoTitles } from '../../utils/commonVariables';
import { NewlineText } from '../../utils/helper';
import { debounce } from '../../utils/helper';
import FirstImage from '../../assets/images/home/1.png';
import SecondImage from '../../assets/images/home/2.png';
import ThirdImage from '../../assets/images/home/3.png';
import FourthImage from '../../assets/images/home/4.png';
import FifthImage from '../../assets/images/home/5.png';
import SixthImage from '../../assets/images/home/6.png';
import SeventhImage from '../../assets/images/home/7.png';
import EighthImage from '../../assets/images/home/8.png';
import NinethImage from '../../assets/images/home/9.png';
import TenthImage from '../../assets/images/home/10.png';
import AppImage from '../../assets/images/home/11.png';
import AppStoreImage from '../../assets/images/home/12.png';
import PlayStoreImage from '../../assets/images/home/13.png';
import MobileSecondImage from '../../assets/images/home/mobile/2.png';
import MobileThirdImage from '../../assets/images/home/mobile/3.png';
import MobileFourthImage from '../../assets/images/home/mobile/4.png';
import MobileFifthImage from '../../assets/images/home/mobile/5.png';
import MobileSixthImage from '../../assets/images/home/mobile/6.png';
import MobileSeventhImage from '../../assets/images/home/mobile/7.png';
import MobileEighthImage from '../../assets/images/home/mobile/8.png';
import MobileNinethImage from '../../assets/images/home/mobile/9.png';
import MobileTenthImage from '../../assets/images/home/mobile/10.png';
import MobileAppImage from '../../assets/images/home/mobile/11.png';
import Footer from '../../components/Footer';
import SeoHeadMeta from '../../components/misc/SeoHeadMeta';
import QuestionnaireOTP from '../loggedIn/Collaborations/Questionnaire/QuestionnaireOTP';

const moreTagtaste = [{
    title: 'Profile Pages',
    desc: 'Show your foodie side to the world with our specially designed profile pages.',
    image: SixthImage,
    mobileImage: MobileSixthImage
}, {
    title: 'Find Professionals',
    desc: 'Reach out to the right food professional for your needs with dedicated search pages.',
    image: SeventhImage,
    mobileImage: MobileSeventhImage
}, {
    title: 'Collaborations',
    desc: 'Discover interesting opportunities or call the community for help.',
    image: EighthImage,
    mobileImage: MobileEighthImage
}, {
    title: 'Personalized feed',
    desc: 'Stay updated with the latest news, trends, and analytics from the industry.',
    image: NinethImage,
    mobileImage: MobileNinethImage
}]
    , resetEmailText = (email) => `You will recieve an email on ${email} containing instructions on how to reset your password.`,
    resetPasswordText = `Your password has been reset successfully! Please login to continue.`,
    createPasswordText = `Your password has been created successfully! Please login to continue.`

function LoggedOutHome(props) {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= screenBreakpoints.mob),
        [hasCheckedForFB, setHasCheckedForFB] = useState(false),
        [hasCheckedForGoogle, setHasCheckedForGoogle] = useState(false),
        [emailForReset, setEmailForReset] = useState(null),
        [hasCheckedForResetEmail, setHasCheckedForResetEmail] = useState(false),
        [hasCheckedForResetPassword, setHasCheckedForResetPassword] = useState(false),
        [hasCheckedForCreatePassword, setHasCheckedForCreatePassword] = useState(false),
        [showEmailPopUp, setShowEmailPopUp] = useState(false),
        [showPasswordPopUp, setShowPasswordPopUp] = useState(false),
        [inView, setInView] = useState(true),
        [actionMessage, setActionMessage] = useState({
            status: false,
            title: false,
            description: false,
            sub_title: false,
        });
    useEffect(() => {
        document.querySelector('body').classList.add('white-bg');
        window.scroll(0, 0);
        return () => {
            document.querySelector('body').classList.remove('white-bg');
        }
    }, []);

    useEffect(() => {
        const handleResize = debounce(function () {
            setIsMobile(window.innerWidth <= screenBreakpoints.mob);
        }, 100);
        window.addEventListener('resize', handleResize);
        return function () {
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    const _handleScroll = () => {
        const ele = document.querySelector('.login__container__section__action'),
            rect = ele.getBoundingClientRect(),
            elePosFromTop = rect.top,
            minVal = 15;
        setInView(!(elePosFromTop < minVal));
    }

    useEffect(() => {
        document.addEventListener('scroll', _handleScroll);
        return () => {
            document.removeEventListener('scroll', _handleScroll);
        }
    }, []);


    useEffect(() => {
        if (props.location
            && props.location.state
            && props.location.state.email
            && !hasCheckedForResetEmail) {
            setHasCheckedForResetEmail(true);
            setEmailForReset(props.location.state.email);
            setShowEmailPopUp(true);
            props.history.replace('', null);
        }
        if (props.location
            && props.location.state
            && props.location.state.passwordReset
            && !hasCheckedForResetPassword) {
            if (props.location.state.isCreatePassword) {
                setHasCheckedForCreatePassword(true);
            }
            setHasCheckedForResetPassword(true);
            setShowPasswordPopUp(true);
            props.history.replace('', null);
        }
    }, [props.location]);
    useEffect(() => {
        if (props.logoutMessage && !actionMessage.status) {
            setActionMessage({
                status: true,
                ...props.logoutMessage
            });
        }
    }, [props.logoutMessage])

    useEffect(() => {
        if (!window.FB && !hasCheckedForFB) {
            setHasCheckedForFB(true);
            const script = document.createElement("script");
            script.src = "https://connect.facebook.net/en_US/sdk.js";
            script.async = true;
            script.defer = true;
            script.type = "text/javascript";
            script.onload = function () {
                window.fbAsyncInit = function () {
                    if (window.FB) {
                        window.FB.init({
                            appId: process.env.REACT_APP_FACEBOOK_ID,
                            autoLogAppEvents: true,
                            xfbml: true,
                            version: process.env.REACT_APP_FACEBOOK_VERSION
                        });
                    }
                };
            }
            document.body.appendChild(script);
        }
    }, [window.FB]);
    useEffect(() => {
        function initSigninV2() {
            window.auth2 = window.gapi.auth2.init({
                client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
                scope: 'profile'
            });
        }
        if (!window.gapi && !hasCheckedForGoogle) {
            setHasCheckedForGoogle(true);
            const script = document.createElement("script");
            script.src = "https://apis.google.com/js/platform.js";
            script.async = true;
            script.defer = true;
            script.type = "text/javascript";
            script.onload = function () {
                if (window.gapi) {
                    window.gapi.load('auth2', initSigninV2);
                }
            }
            document.body.appendChild(script);
        }
    }, [window.gapi]);
    return (<>
        {props && props.showMeta && <SeoHeadMeta title={seoTitles.loggedOut.home}>
            <title>{seoTitles.loggedOut.home}</title>
        </SeoHeadMeta>}
        <LoggedOutBar inView={inView} />
        <div className="login__container">
            {
                emailForReset
                && showEmailPopUp
                && <p className="login__container__reset">{resetEmailText(emailForReset)}</p>
            }
            {
                showPasswordPopUp
                && <p className="login__container__reset">{hasCheckedForCreatePassword ? createPasswordText : resetPasswordText}</p>
            }
            {
                actionMessage.status
                && <>
                    {actionMessage.title && <NewlineText text={actionMessage.title} className='login__container__reset' />}
                    {actionMessage.description && <NewlineText text={actionMessage.description} className='login__container__reset' />}
                    {actionMessage.sub_title && <NewlineText text={actionMessage.sub_title} className='login__container__reset' />}
                </>
            }
            <section className="login__container__section">
                <div className="login__container__section__centered login__container__section--main">
                    <div className="login__container__section__info">
                        <h1 className="login__title">Welcome To TagTaste</h1>
                        <p className="login__desc">World’s first online community for food professionals to <strong>Discover, Network &amp; Collaborate</strong> with each other.</p>
                        <div id="login-action" className="login__container__section__action">
                            <Link className="login__container__section__action__btn" to={miscLinks.register}>Sign up</Link>
                            <Link className="login__container__section__action__btn login__container__section__action__btn--secondary" to={miscLinks.login}>Log In</Link>
                        </div>
                    </div>
                    {
                        !isMobile && <div className="login__form__image__container">
                            <img className="login__form__image" src={FirstImage} alt="welcome to tagtaste" />
                        </div>
                    }
                </div>
            </section>
            <section className="login__container__section">
                <div className="login__container__section__centered login__container__section--multi">
                    {
                        isMobile && <div className="login__container__section__icon__container">
                            <img className="login__container__section__icon" src={MobileSecondImage} alt="be a professional taster" />
                        </div>
                    }
                    <div className="login__container__section__info">
                        <p className="login__helper">GROW WITH TAGTASTE</p>
                        <p className="login__title login__title--secondary">Be a Professional Taster</p>
                        <p className="login__desc">Understand the world of sensory reviews. Get a first-hand experience of what it takes to develop and launch a product into the market. Review products being developed by startups and larger corporates including MNCs. Earn while you Learn.</p>
                    </div>
                    {
                        !isMobile && <div className="login__container__section__icon__container">
                            <img className="login__container__section__icon" src={SecondImage} alt="be a professional taster" />
                        </div>
                    }
                </div>
            </section>
            <section className="login__container__section login__container__section--teal">
                <div className="login__container__section__centered login__container__section--multi">
                    <div className="login__container__section__icon__container">
                        <img className="login__container__section__icon" src={isMobile ? MobileThirdImage : ThirdImage} alt="Manage product lifecycle" />
                    </div>
                    <div className="login__container__section__info">
                        <p className="login__helper login__helper--orange">FROM START TO FINISH</p>
                        <p className="login__title login__title--secondary">Manage Your Products</p>
                        <p className="login__desc">Engage with our strong network of food professionals to explore, develop & launch new product ideas into the market. The platform enables you to manage the entire lifecycle of your new as well as existing products.</p>
                    </div>
                </div>
            </section>
            <section className="login__container__section">
                <div className="login__container__section__centered login__container__section--multi">
                    {
                        isMobile && <div className="login__container__section__icon__container">
                            <img className="login__container__section__icon" src={MobileFourthImage} alt="Product benchmarkin" />
                        </div>
                    }
                    <div className="login__container__section__info">
                        <p className="login__helper">STANDARDIZE</p>
                        <p className="login__title login__title--secondary">Product Benchmarking</p>
                        <p className="login__desc">Benchmark your products against your competition. Get actionable insights from professional tasters that represent your target audience from over 70 cities across India. Asses the market readiness of your products before spending millions on inventory and marketing.</p>
                    </div>
                    {
                        !isMobile && <div className="login__container__section__icon__container">
                            <img className="login__container__section__icon" src={FourthImage} alt="Product benchmarkin" />
                        </div>
                    }
                </div>
            </section>
            <section className="login__container__section  login__container__section--yellow">
                <div className="login__container__section__centered login__container__section--depth login__container__section--centered">
                    {
                        isMobile && <div className="login__icon">
                            <img className="login__icon" src={isMobile ? MobileFifthImage : FifthImage} alt="In-depth product reviews" />
                        </div>
                    }
                    <p className="login__title">Product Reviews</p>
                    <p className="login__desc">Get in-depth sensory reviews of products from your favorite brands & restaurants. Stay updated with the latest products in the market along with their reviews. Understand what makes your favorite products truly memorable.</p>
                    {
                        !isMobile && <div className="login__icon">
                            <img className="login__icon" src={isMobile ? MobileFifthImage : FifthImage} alt="In-depth product reviews" />
                        </div>
                    }
                </div>
            </section>
            <section className="login__container__section">
                <div className="login__container__section__centered login__container__section--centered">
                    <p className="login__title">Do more with TagTaste</p>
                    <p className="login__desc">TagTaste is a professional network designed specifically to enhance industry collaboration.</p>
                    <div className="login__info">
                        <ul className="login__info__list">
                            {
                                moreTagtaste.map(_ => {
                                    return (<li key={_.title} className="login__info__list__item">
                                        <div className="login__info__list__img">
                                            <img src={isMobile ? _.mobileImage : _.image} alt={_.title} className="login__info__list__img" />
                                        </div>
                                        <div className="login__info__list__info">
                                            <p className="login__info__list__title">{_.title}</p>
                                            <p className="login__info__list__desc">{_.desc}</p>
                                        </div>
                                    </li>)
                                })
                            }
                        </ul>
                    </div>
                </div>
            </section>
            <section className="login__container__section login__container__section--customers login__container__section--centered login__container__section--teal">
                <p className="login__title">Customers &amp; Partners</p>
                <div className="login__icon">
                    <img className="login__icon" src={isMobile ? MobileTenthImage : TenthImage} alt="our partners" />
                </div>
            </section>
            <section className="login__container__section">
                <div className="login__container__section__centered login__container__section--pocket login__container__section--multi" style={{ paddingBottom: 0 }}>
                    {
                        !isMobile && <div className="login__container__section__icon__container">
                            <img className="login__container__section__icon" src={AppImage} alt="TagTaste in your Pocket" />
                        </div>
                    }
                    <div className="login__container__section__info login__container__section__info--mobile">
                        <p className="login__helper login__helper--orange">GO MOBILE</p>
                        <p className="login__title login__title--secondary">TagTaste on the GO!</p>
                        <p className="login__desc">Experience the magic on your Android and iOS applications.</p>
                        <div className="login__container__section__store-links__container">
                            <a href={process.env.REACT_APP_APP_STORE_LINK} target="_blank" rel="noopener noreferrer" className="login__container__section__store-links"><img className="login__container__section__store-links__icon" src={PlayStoreImage} alt="Play store" /></a>
                            <a href={process.env.REACT_APP_PLAY_STORE_LINK} target="_blank" rel="noopener noreferrer" className="login__container__section__store-links"><img className="login__container__section__store-links__icon" src={AppStoreImage} alt="App store" /></a>
                        </div>
                    </div>
                    {
                        isMobile && <div className="login__container__section__icon__container">
                            <img className="login__container__section__icon" src={MobileAppImage} alt="TagTaste in your Pocket" />
                        </div>
                    }
                </div>
            </section>
        </div>
        {props.isPreview ? <QuestionnaireOTP isModal id={props.id} email={props.email} /> : null}
        <Footer />
    </>)
}

export default connect((state) => ({ logoutMessage: state.login.logoutMessage }), {})(LoggedOutHome);