// GET COLLAB ROLES
export const GET_COLLABORATION_ROLES_REQUEST = 'GET_COLLABORATION_ROLES_REQUEST';
export const GET_COLLABORATION_ROLES_SUCCESS = 'GET_COLLABORATION_ROLES_SUCCESS';
export const GET_COLLABORATION_ROLES_FAILURE = 'GET_COLLABORATION_ROLES_FAILURE';
export const RESET_COLLABORATION_ROLES = 'RESET_COLLABORATION_ROLES';

// SAVE COLLAB ROLES
export const COLLABORATION_ROLES_ADD_PROIFLE_REQUEST = 'COLLABORATION_ROLES_ADD_PROIFLE_REQUEST';;
export const COLLABORATION_ROLES_ADD_PROIFLE_SUCCESS = 'COLLABORATION_ROLES_ADD_PROIFLE_SUCCESS';;
export const COLLABORATION_ROLES_ADD_PROIFLE_FAILURE = 'COLLABORATION_ROLES_ADD_PROIFLE_FAILURE';;

// remove from roles
export const COLLABORATION_ROLES_REMOVE_PROFILE_REQUEST = 'COLLABORATION_ROLES_REMOVE_PROFILE_REQUEST';
export const COLLABORATION_ROLES_REMOVE_PROFILE_SUCCESS = 'COLLABORATION_ROLES_REMOVE_PROFILE_SUCCESS';
export const COLLABORATION_ROLES_REMOVE_PROFILE_FAILURE = 'COLLABORATION_ROLES_REMOVE_PROFILE_FAILURE';