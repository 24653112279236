import React, { Component } from "react";
import PropTypes from "prop-types";
import PostCard from "./PostCard";
import PhotoCard from "./PhotoCard";
import CollaborateCard from "./CollaborateCard";
import PollingCard from "./PollingCard";
import ProductCard from "./ProductCard";
import SurveyCard from "./SurveyCard";
import QuizCard from "./QuizCard";
import ErrorBoundary from "../../ErrorBoundary";
import Suggestions from "../../../../components/suggestions/CommonSuggestion";
import FeedCard from "../../../../components/feed/cards/feedCard/FeedCard";
import { availableFeedCardPostTypes } from "../../../../utils/commonVariables";

class CommonCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
    this.renderComponent = this.renderComponent.bind(this);
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  renderComponent() {
    const { type } = this.props;
    switch (type) {
      case "shoutout":
        return <PostCard {...this.props} />;
      case "photo":
        return <PhotoCard {...this.props} />;
      case "collaborate":
        return <CollaborateCard {...this.props} />;
      case "polling":
        return <PollingCard {...this.props} />;
      case "product":
        return <ProductCard {...this.props} />;
      case "suggestion":
        return <Suggestions {...this.props} />;
      case "feedCard":
        return <FeedCard {...this.props} />;
      case availableFeedCardPostTypes.survey:
        return <SurveyCard {...this.props} />;
      case availableFeedCardPostTypes.quiz:
        return <QuizCard {...this.props} />;
      default:
        return null;
    }
  }

  render() {
    if (this.state.hasError) return null;
    return <ErrorBoundary>{this.renderComponent()}</ErrorBoundary>;
  }
}

CommonCard.propTypes = {
  type: PropTypes.string,
};
export default CommonCard;
