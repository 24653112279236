import {
    GET_ALL_PRODUCTS_REQUEST,
    GET_ALL_PRODUCTS_SUCCESS,
    GET_ALL_PRODUCTS_FAILURE,
    RESET_ALL_PRODUCTS
} from "../../actions/products/constants";

const INITIAL_STATE = {
    fetching: false,
    page: 1,
    products: []
}

export default function all(state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_ALL_PRODUCTS_REQUEST:
            return {
                ...state,
                fetching: true,
                error: false
            }
        case GET_ALL_PRODUCTS_SUCCESS:
            return {
                ...state,
                fetching: false,
                page: state.page + 1,
                products: state.page === 1
                    ? action.payload
                        && action.payload.product
                        ? action.payload.product
                        : []
                    : [...state.products, ...((action.payload
                        && action.payload.product) ? action.payload.product : [])],
                hasEnd: (!action.payload.product
                    || (action.payload.product
                        && !action.payload.product.length))
            }
        case GET_ALL_PRODUCTS_FAILURE:
            return {
                ...state,
                ...(!action.error.__CANCEL__ && {
                    error: true,
                    errorMsg: action.error
                }),
                fetching: false,
            }
        case RESET_ALL_PRODUCTS:
            return INITIAL_STATE
        default:
            return state;
    }
}