import React from 'react';
import PropTypes from 'prop-types';

function CommentsLoadButton({ handleClick,
    title }) {
    return <div className="post-comments-containers__action-container">
        <button onClick={handleClick} className="post-comments-containers__action-btn">
            <span className="post-comments-containers__action-btn__text">{title || 'Load Comments...'}</span>
        </button>
    </div>;
};

CommentsLoadButton.propTypes = {
    handleClick: PropTypes.func,
    title: PropTypes.string,
}

export default CommentsLoadButton;