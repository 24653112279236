import React, { Component } from "react";
import PropTypes from "prop-types";
import Chef from "../../../../assets/images/profile/completion/chef.svg";
import { getTwoDigits, getDaysInMonth } from "../../../../utils/helper";
import ProfileCompletionCompleted from "./ProfileCompletionCompleted";

const texts = {
  title: "Your Birthday?",
};

class BirthdayCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: "",
      month: "",
      year: "",
      hasError: "",
      submitting: false,
      hasSubmit: !!props.profile.dob,
      currentMonth: new Date().getMonth(),
      currentDate: new Date().getDate(),
    };
    this.renderDate = this.renderDate.bind(this);
    this.renderMonth = this.renderMonth.bind(this);
    this.renderYear = this.renderYear.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { profile } = this.props;
    if (profile.dob) {
      const currentDate = new Date(profile.dob.split("-").reverse().join("-"));
      this.setState({
        date: currentDate.getDate(),
        month: currentDate.getMonth(),
        year: currentDate.getFullYear(),
      });
    }
  }
  renderDate() {
    let dates = [
      <option key="default-date" placeholder="dd">
        DD
      </option>,
    ];
    const daysInCurrentMonth = getDaysInMonth(
      this.state.year,
      this.state.month == ""
        ? new Date().getMonth() + 1
        : parseInt(this.state.month) + 1
    );
    for (let i = 1; i <= daysInCurrentMonth; i++) {
      const value = getTwoDigits(i);
      dates.push(
        <option
          key={`date-${i}`}
          disabled={
            this.state.year == new Date().getFullYear() - 13 &&
            this.state.month == this.state.currentMonth
              ? value <= this.state.currentDate
                ? false
                : true
              : false
          }
          value={value}
        >
          {value}
        </option>
      );
    }
    return dates;
  }

  renderMonth() {
    let months = [
      <option key="default-month" placeholder="mm">
        MM
      </option>,
    ];
    for (let i = 0; i < 12; i++) {
      months.push(
        <option
          value={i}
          disabled={
            this.state.year == new Date().getFullYear() - 13
              ? i < this.state.currentMonth + 1
                ? false
                : true
              : false
          }
          key={`month-${i}`}
        >{`${
          parseInt(i) + 1 < 10 ? "0" + (parseInt(i) + 1) : parseInt(i) + 1
        }`}</option>
      );
    }
    return months;
  }

  renderYear() {
    let years = [
      <option key="default-year" placeholder="yyyy">
        YYYY
      </option>,
    ];
    for (let i = 1900; i <= new Date().getFullYear() - 13; i++) {
      years.push(
        <option value={i} key={`year-${i}`}>
          {i}
        </option>
      );
    }
    return years;
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (
      e.target.name == "year" &&
      e.target.value == new Date().getFullYear() - 13
    ) {
      if (this.state.month > this.state.currentMonth) {
        this.setState({
          date: new Date().getDate(),
          month: new Date().getMonth(),
        });
      }
      if (
        this.state.month == this.state.currentMonth &&
        this.state.date > this.state.currentDate
      ) {
        this.setState({
          date: new Date().getDate(),
        });
      }
    }
    if (
      e.target.name == "month" &&
      this.state.year == new Date().getFullYear() - 13 &&
      e.target.value == new Date().getMonth()
    ) {
      this.setState({
        date: new Date().getDate(),
      });
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({
      hasError: "",
    });
    const { date, month, year, submitting } = this.state,
      { profile, handleUserUpdate } = this.props;
    if (!date || !month || !year || submitting) return;
    if (date > getDaysInMonth(year, parseInt(month) + 1)) return;
    let dateToCheck = new Date(`${year}-${month}-${date}`),
      dob = `${date}-${parseInt(month) + 1}-${year}`,
      dataToSend = {
        profile: {
          dob,
        },
      };
    if (String(dateToCheck) !== "Invalid Date") {
      this.setState({
        submitting: true,
      });
      handleUserUpdate(profile.id, dataToSend, () => {
        this.setState({
          submitting: false,
          hasSubmit: true,
        });
      });
    } else {
      this.setState({
        hasError: "Invalid Date",
      });
    }
  }

  render() {
    const { submitting, hasSubmit } = this.state;
    return (
      <form
        onSubmit={this.handleSubmit}
        className="feed-common-cards profile-updation-data__container"
      >
        <div className="profile-updation-data__image-container">
          <img
            className="profile-updation-data__image"
            src={Chef}
            alt="Your Birthday?"
          />
        </div>
        <div className="profile-updation-data__details">
          <p className="profile-updation-data__title">{texts.title}</p>
          <p className="profile-updation-data__meta profile-updation-data__date__container">
            <select
              className="profile-updation-data__date__select"
              name="date"
              onChange={this.handleChange}
              value={getTwoDigits(this.state.date)}
            >
              {this.renderDate()}
            </select>
            <span className="profile-updation-data__date__separator">/</span>
            <select
              className="profile-updation-data__date__select profile-updation-data__date__select--month"
              name="month"
              value={this.state.month}
              onChange={this.handleChange}
            >
              {this.renderMonth()}
            </select>
            <span className="profile-updation-data__date__separator">/</span>
            <select
              className="profile-updation-data__date__select"
              name="year"
              value={this.state.year}
              onChange={this.handleChange}
            >
              {this.renderYear()}
            </select>
          </p>
        </div>
        <div className="profile-updation-data__btn-container">
          {this.state.hasError && (
            <p className="profile-updation-data__error">
              {this.state.hasError}
            </p>
          )}
          <button
            disabled={submitting}
            className={`profile-updation-data__btn
                    ${
                      submitting ? "profile-updation-data__btn--disabled" : ""
                    }`}
          >
            Save
          </button>
        </div>
        {hasSubmit && <ProfileCompletionCompleted />}
      </form>
    );
  }
}

BirthdayCard.propTypes = {
  profile: PropTypes.object,
  handleUserUpdate: PropTypes.func,
};

export default BirthdayCard;
