import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ProfileUrls } from '../../../../utils/links';
import { decodeString } from '../../../../utils/helper';
import DefaultCoverPhoto from '../../../../assets/images/profile/view/cover-page.png';
import Avatar from '../../../../components/user/Avatar';
import UserName from '../../../../components/profile/UserName';
import SuperTaster from '../../../../components/misc/SuperTaster';

function UserInfo(props) {
  const [user, setUser] = useState(props.user);
  const [profile, setProfile] = useState(user.profile);
  const profileLink = ProfileUrls.userProfileLink(profile.handle, profile.id),
    followersLink = ProfileUrls.followers(profile.id),
    followingLink = ProfileUrls.following(profile.id);
  useEffect(() => {
    setUser(props.user);
    setProfile(props.user.profile);
  }, [props.user]);
  return (
    <div className="feed-common-cards feed-user-info">
      <Link className="feed-user-info__media" to={profileLink}>
        <div className="feed-user-info__cover">
          <img
            className="feed-user-info__cover"
            src={
              profile.hero_image_meta
                ? JSON.parse(profile.hero_image_meta).original_photo
                : DefaultCoverPhoto
            }
            alt={profile.name}
          />
        </div>
        <div className="feed-user-info__image-container">
          <Avatar
            src={
              profile.image_meta ? JSON.parse(profile.image_meta).original_photo : profile.imageUrl
            }
            className="feed-user-info__image"
            alt={profile.name}
          />
        </div>
      </Link>
      <div className="feed-user-info__details">
        <Link to={profileLink} className="feed-user-info__details__meta">
          <p className="feed-user-info__details__meta__text">
            <UserName
              isVerified={!!profile.verified}
              styles={{ verticalAlign: 'text-bottom' }}
              textStyle={{ verticalAlign: 'middle' }}
              name={user.name}
            />
          </p>
          <p className="feed-user-info__details__meta__text--secondary">@{profile.handle}</p>
          {!!profile.is_tasting_expert && (
            <SuperTaster className="feed-user-info__details__meta__badge" />
          )}
          {profile.tagline && (
            <p className="feed-user-info__details__meta__text--handle">
              {decodeString(profile.tagline)}
            </p>
          )}
        </Link>
      </div>
      <div className="feed-user-info__followers_info">
        <Link to={followersLink} className="feed-user-info__followers_info__block">
          <p className="feed-user-info__followers_info__text--header">FOLLOWERS</p>
          <p className="feed-user-info__followers_info__text--primary">
            {profile.followerProfiles && profile.followerProfiles.count
              ? profile.followerProfiles.count
              : 0}
          </p>
        </Link>
        <Link to={followingLink} className="feed-user-info__followers_info__block">
          <p className="feed-user-info__followers_info__text--header">FOLLOWING</p>
          <p className="feed-user-info__followers_info__text--primary">
            {profile.followingProfiles && profile.followingProfiles.count
              ? profile.followingProfiles.count
              : 0}
          </p>
        </Link>
      </div>
    </div>
  );
}

UserInfo.propTypes = {
  user: PropTypes.object.isRequired,
};

export default UserInfo;
