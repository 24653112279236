import { GET_PR_MANAGE_REPORT_SUMMARY_REQUEST,
    GET_PR_MANAGE_REPORT_SUMMARY_SUCCESS,
    GET_PR_MANAGE_REPORT_SUMMARY_FAILURE,
    RESET_PR_MANAGE_REPORT_SUMMARY,
    UPDATE_PR_HEADER_WEIGHT} from "../../../../actions/collaborations/prManage/constants";

const INITIAL_STATE = {
    fetching: false,
    data: null,
    error: false
}

export default function summary(state = INITIAL_STATE, action) {
    switch(action.type) {
        case GET_PR_MANAGE_REPORT_SUMMARY_REQUEST:
            return {
                ...state,
                fetching: true
            }
        case GET_PR_MANAGE_REPORT_SUMMARY_SUCCESS:
            return {
                ...state,
                fetching: false,
                data: action.payload,
                hasFetched: true
            }
        case GET_PR_MANAGE_REPORT_SUMMARY_FAILURE:
            return {
                ...state,
                ...(!action.error.__CANCEL__ && {
                    error: true,
                    errorObj: action.error
                }),
                fetching: false
            }
        case UPDATE_PR_HEADER_WEIGHT:
            return {
                ...state,
                ...(state.data
                    && {
                        data: {
                            ...state.data,
                            weight: action.payload
                        }
                    })
            }
        case RESET_PR_MANAGE_REPORT_SUMMARY:
            return INITIAL_STATE;
        default:
            return state;
    }
}