import get from 'lodash/get';
import {
  GET_QUICK_LINKS,
  GET_QUICK_LINKS_SUCCESS,
  GET_QUICK_LINKS_FAILURE,
} from '../../actions/landingPage/constants';

const INITIAL_STATE = {
  fetching: false,
  data: [],
};

export default function quickLinks(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_QUICK_LINKS:
      return {
        ...state,
        fetching: true,
      };
    case GET_QUICK_LINKS_SUCCESS:
      return {
        ...state,
        fetching: false,
        data: get(action, 'payload.data', []),
      };
    case GET_QUICK_LINKS_FAILURE:
      return {
        ...state,
        fetching: false,
        error: true,
        errorMsg: action.error,
      };

    default:
      return state;
  }
}
