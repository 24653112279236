import {
  TOGGLE_PROFILE_COMPLETION_MODAL,
  TOGGLE_PROFILE_MENU_MODAL,
  TOGGLE_PROFILE_EDIT_MODAL,
  SHOW_PROFILE_PASSBOOK_MODAL,
  HIDE_PROFILE_MODAL,
  TOGGLE_OTP_MODAL,
  TOGGLE_SUCCESS_OTP_MODAL,
} from '../../actions/common/constants';

const INITIAL_STATE = {
  profileCompletion: {
    isVisible: false,
    type: null, // cuisine || eating || job || specialization
  },
  profileMenu: {
    isVisible: false,
    type: null,
  },
  profileEdit: {
    isVisible: false,
    type: null, // edit | education | personal | training | work
  },
  profilePassbook: {
    isVisible: false,
    type: null,
    data: null,
  },
  otpModal: {
    isVisible: false,
    type: null,
    password: null
  },
  successOtpModal: {
    isVisible: false,
    type: null
  }
};

export default function profileModals(state = INITIAL_STATE, action) {
  switch (action.type) {
    case TOGGLE_PROFILE_COMPLETION_MODAL:
      return {
        ...state,
        profileCompletion: {
          type: action.payload || null,
          isVisible: !!action.payload ? !state.profileCompletion.isVisible : false,
        },
      };
    case TOGGLE_PROFILE_MENU_MODAL:
      return {
        ...state,
        profileMenu: {
          type: action.payload || null,
          isVisible: !state.profileMenu.isVisible,
        },
      };
    case TOGGLE_PROFILE_EDIT_MODAL:
      return {
        ...state,
        profileEdit: {
          type: action.payload || null,
          isVisible: !state.profileEdit.isVisible,
        },
      };
    case TOGGLE_OTP_MODAL:
      return {
        ...state,
        otpModal: {
          type: action.payload.type || null,
          password: action.payload.password || null,
          isVisible: !state.otpModal.isVisible,
        },
      };
    case TOGGLE_SUCCESS_OTP_MODAL:
      return {
        ...state,
        successOtpModal: {
          type: action.payload || null,
          isVisible: !state.successOtpModal.isVisible,
        },
      };
    case SHOW_PROFILE_PASSBOOK_MODAL:
      return {
        ...state,
        profilePassbook: {
          isVisible: !state.profilePassbook.isVisible,
          data: action.payload.data,
          type: action.payload.type,
        },
      };
    case HIDE_PROFILE_MODAL:
      return INITIAL_STATE;

    default:
      return state;
  }
}
