import React from 'react';
import PropTypes from 'prop-types';

const FreeSlider = (props) => {
  return <div className="review-page__scroll">{props.children}</div>;
};

FreeSlider.propTypes = {
  children: PropTypes.any,
};

export default FreeSlider;
