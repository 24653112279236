// get polling details
export const GET_POLLS_REQUEST = 'GET_POLLS_REQUEST';
export const GET_POLLS_SUCCESS = 'GET_POLLS_SUCCESS';
export const GET_POLLS_FAILURE = 'GET_POLLS_FAILURE';
export const RESET_POLLS_DETAILS = 'RESET_POLLS_DETAILS';

// get polling details
export const GET_REVIEWS_REQUEST = 'GET_REVIEWS_REQUEST';
export const GET_REVIEWS_SUCCESS = 'GET_REVIEWS_SUCCESS';
export const GET_REVIEWS_FAILURE = 'GET_REVIEWS_FAILURE';
export const RESET_REVIEWS_DETAILS = 'RESET_REVIEWS_DETAILS';
export const UPDATE_CURRENT_VIDEO = "UPDATE_CURRENT_VIDEO";
