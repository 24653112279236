import React, { Component } from "react";
import PillFilters from "../../../components/filters/PillFilters";
import CancelIcon from "../../../assets/images/hashtags/cancel-button.svg";
class BlockAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: ""
        }
    }
    render() {
        return (
            <div className="container container--with-height">
                <div className="see-all-container">
                    <div className="see-all-container__header">
                        <p className="see-all-container__title">Blocked Accounts</p>
                        <p className="see-all-container__helper">
                            All your blocked Account appear here
                        </p>
                    </div>
                    <div>
                        <div className="see-all-container__filter-search my-collaborations__filters">
                            <div className="my-collaborations__filters__container">
                                <div className="see-all-container__search-input__container">
                                    <input
                                        type="text"
                                        value={this.state.title}
                                        className="see-all-container__search-input see-all-container__search-input--with-clear"
                                        placeholder="Search Account..."
                                        onChange={(e) => this.setState({
                                            title: e.target.value
                                        })}
                                    />
                                    {this.state.title && (
                                        <img
                                            className="see-all-container__search-input__clear"
                                            onClick={() => this.setState({
                                                title: ""
                                            }) }
                                            src={CancelIcon}
                                            alt="remove input"
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        )
    }



}

export default BlockAccount;