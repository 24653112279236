// GETTING DETAILS
export const GET_PRODUCT_DETAILS_REQUEST = 'GET_PRODUCT_DETAILS_REQUEST';
export const GET_PRODUCT_DETAILS_SUCCESS = 'GET_PRODUCT_DETAILS_SUCCESS';
export const GET_PRODUCT_DETAILS_FAILURE = 'GET_PRODUCT_DETAILS_FAILURE';
// RESET DETAILS
export const RESET_PRODUCT_DETAILS = 'RESET_PRODUCT_DETAILS';

// GETTING PRODUCT HEADERS
export const GET_PRODUCT_HEADERS_REQUEST = 'GET_PRODUCT_HEADERS_REQUEST';
export const GET_PRODUCT_HEADERS_SUCCESS = 'GET_PRODUCT_HEADERS_SUCCESS';
export const GET_PRODUCT_HEADERS_FAILURE = 'GET_PRODUCT_HEADERS_FAILURE';

// RESET PRODUCT HEADERS
export const RESET_PRODUCT_HEADERS = 'RESET_PRODUCT_HEADERS';

// GETTING PRODUCT REVIEWS
export const GET_PRODUCT_REVIEWS_REQUEST = 'GET_PRODUCT_REVIEWS_REQUEST';
export const GET_PRODUCT_REVIEWS_SUCCESS = 'GET_PRODUCT_REVIEWS_SUCCESS';
export const GET_PRODUCT_REVIEWS_FAILURE = 'GET_PRODUCT_REVIEWS_FAILURE';
// RESET PRODUCT REVIEWS
export const RESET_PRODUCT_REVIEWS = 'RESET_PRODUCT_REVIEWS';
export const RESET_PRODUCT_REVIEWS_PAGE = 'RESET_PRODUCT_REVIEWS_PAGE';

// GETTING REPORT SUMMARY
export const GET_PRODUCT_REPORT_SUMMARY_REQUEST = 'GET_PRODUCT_REPORT_SUMMARY_REQUEST';
export const GET_PRODUCT_REPORT_SUMMARY_SUCCESS = 'GET_PRODUCT_REPORT_SUMMARY_SUCCESS';
export const GET_PRODUCT_REPORT_SUMMARY_FAILURE = 'GET_PRODUCT_REPORT_SUMMARY_FAILURE';
// RESET REPORT SUMMARY
export const RESET_PRODUCT_REPORT_SUMMARY = 'RESET_PRODUCT_REPORT_SUMMARY';

// GETTING HEADER WISE REPORT
export const GET_PRODUCT_HEADER_REPORT_REQUEST = 'GET_PRODUCT_HEADER_REPORT_REQUEST';
export const GET_PRODUCT_HEADER_REPORT_SUCCESS = 'GET_PRODUCT_HEADER_REPORT_SUCCESS';
export const GET_PRODUCT_HEADER_REPORT_FAILURE = 'GET_PRODUCT_HEADER_REPORT_FAILURE';
// RESET HEADER REPORT
export const RESET_HEADER_REPORT = 'RESET_HEADER_REPORT';

// PRODUCT FOOD SHOTS
export const GET_PRODUCT_FOOD_SHOTS_REQUEST = 'GET_PRODUCT_FOOD_SHOTS_REQUEST';
export const GET_PRODUCT_FOOD_SHOTS_SUCCESS = 'GET_PRODUCT_FOOD_SHOTS_SUCCESS';
export const GET_PRODUCT_FOOD_SHOTS_FAILURE = 'GET_PRODUCT_FOOD_SHOTS_FAILURE';
export const RESET_PRODUCT_FOOD_SHOTS = 'RESET_PRODUCT_FOOD_SHOTS';

// Product Filters
export const GET_PRODUCT_FILTERS_REQUEST = 'GET_PRODUCT_FILTERS_REQUEST';
export const GET_PRODUCT_FILTERS_SUCCESS = 'GET_PRODUCT_FILTERS_SUCCESS';
export const GET_PRODUCT_FILTERS_FAILURE = 'GET_PRODUCT_FILTERS_FAILURE';

// Single Review
export const GET_SINGLE_PRODUCT_REVIEW_REQUEST = 'GET_SINGLE_PRODUCT_REVIEW_REQUEST';
export const GET_SINGLE_PRODUCT_REVIEW_SUCCESS = 'GET_SINGLE_PRODUCT_REVIEW_SUCCESS';
export const GET_SINGLE_PRODUCT_REVIEW_FAILURE = 'GET_SINGLE_PRODUCT_REVIEW_FAILURE';
// reset single review
export const RESET_SINGLE_PRODUCT_REVIEW = 'RESET_SINGLE_PRODUCT_REVIEW';
export const SET_SINGLE_PRODUCT_REVIEW = 'SET_SINGLE_PRODUCT_REVIEW';

// Review comments
export const GET_PRODUCT_REVIEW_COMMMENTS_REQUEST = 'GET_PRODUCT_REVIEW_COMMMENTS_REQUEST';
export const GET_PRODUCT_REVIEW_COMMMENTS_SUCCESS = 'GET_PRODUCT_REVIEW_COMMMENTS_SUCCESS';
export const GET_PRODUCT_REVIEW_COMMMENTS_FAILURE = 'GET_PRODUCT_REVIEW_COMMMENTS_FAILURE';
export const RESET_PRODUCT_REVIEW_COMMENTS = 'RESET_PRODUCT_REVIEW_COMMENTS';
//deleting review comments
export const DELETE_PRODUCT_REVIEW_COMMENTS_REQUEST = 'DELETE_PRODUCT_REVIEW_COMMENTS_REQUEST';
export const DELETE_PRODUCT_REVIEW_COMMENTS_SUCCESS = 'DELETE_PRODUCT_REVIEW_COMMENTS_SUCCESS';
export const DELETE_PRODUCT_REVIEW_COMMENTS_FAILURE = 'DELETE_PRODUCT_REVIEW_COMMENTS_FAILURE';

// Post Review Comments
export const POST_PRODUCT_REVIEW_COMMMENT_REQUEST = 'POST_PRODUCT_REVIEW_COMMMENT_REQUEST';
export const POST_PRODUCT_REVIEW_COMMMENT_SUCCESS = 'POST_PRODUCT_REVIEW_COMMMENT_SUCCESS';
export const POST_PRODUCT_REVIEW_COMMMENT_FAILURE = 'POST_PRODUCT_REVIEW_COMMMENT_FAILURE';

// ANY OTHER DATA
export const GET_PRODUCT_ANY_OTHER_DATA_REQUEST = 'GET_PRODUCT_ANY_OTHER_DATA_REQUEST';
export const GET_PRODUCT_ANY_OTHER_DATA_SUCCESS = 'GET_PRODUCT_ANY_OTHER_DATA_SUCCESS';
export const GET_PRODUCT_ANY_OTHER_DATA_FAILURE = 'GET_PRODUCT_ANY_OTHER_DATA_FAILURE';
// RESET ANY OTHER DATA
export const RESET_PRODUCT_ANY_OTHER_DATA = 'RES_PRODUCT_ANY_OTHER_DATA';

// get all products
export const GET_ALL_PRODUCTS_REQUEST = 'GET_ALL_PRODUCTS_REQUEST';
export const GET_ALL_PRODUCTS_SUCCESS = 'GET_ALL_PRODUCTS_SUCCESS';
export const GET_ALL_PRODUCTS_FAILURE = 'GET_ALL_PRODUCTS_FAILURE';
export const RESET_ALL_PRODUCTS = 'RESET_ALL_PRODUCTS';

//get samples
export const GET_PRODUCTS_SAMPLE_REQUEST = 'GET_PRODUCTS_SAMPLE_REQUEST';
export const GET_PRODUCTS_SAMPLE_SUCCESS = 'GET_PRODUCTS_SAMPLE_SUCCESS';
export const GET_PRODUCTS_SAMPLE_FAILURE = 'GET_PRODUCTS_SAMPLE_FAILURE';
