import { GET_LINK_PREVIEW_REQUEST,
    GET_LINK_PREVIEW_SUCCESS,
    GET_LINK_PREVIEW_FAILURE,
    RESET_CHAT } from '../../actions/chat/constants';

const INITIAL_STATE = {
    fetching: false,
    error: false,
    linkPreviewObj: null
}

const getInitialState = () => INITIAL_STATE;

export default function linkPreview(state = getInitialState(), action) {
    switch (action.type) {
        case GET_LINK_PREVIEW_REQUEST:
            return {
                ...state,
                fetching: true,
                linkPreviewObj: null
            }
        case GET_LINK_PREVIEW_SUCCESS:
            return {
                ...state,
                linkPreviewObj: action.payload
            }
        case GET_LINK_PREVIEW_FAILURE:
            return {
                ...state,
                fetching: false,
                error: true,
                linkPreviewObj: null,
                errorMsg: action.error
            }
        case RESET_CHAT:
            return INITIAL_STATE
        default:
            return state;
    }
}