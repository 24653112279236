// Current user
export const GET_LOGGED_IN_USER_REQUEST = 'GET_LOGGED_IN_USER_REQUEST';
export const GET_LOGGED_IN_USER_SUCCESS = 'GET_LOGGED_IN_USER_SUCCESS';
export const GET_LOGGED_IN_USER_FAILURE = 'GET_LOGGED_IN_USER_FAILURE';

export const GET_CAREER_REQUEST = 'GET_CAREER_REQUEST';
export const GET_CAREER_SUCCESS = 'GET_CAREER_SUCCESS';
export const GET_CAREER_FAILURE = 'GET_CAREER_FAILURE';

export const GET_SINGLE_CAREER_OBJ = 'GET_SINGLE_CAREER_OBJ';

export const GET_SINGLE_COLLABORATE_REQUEST = 'GET_SINGLE_COLLABORATE_REQUEST';
export const GET_SINGLE_COLLABORATE_SUCCESS = 'GET_SINGLE_COLLABORATE_SUCCESS';
export const GET_SINGLE_COLLABORATE_FAILURE = 'GET_SINGLE_COLLABORATE_FAILURE';

export const GET_SIMILAR_COLLABORATE_REQUEST = 'GET_SIMILAR_COLLABORATE_REQUEST';
export const GET_SIMILAR_COLLABORATE_SUCCESS = 'GET_SIMILAR_COLLABORATE_SUCCESS';
export const GET_SIMILAR_COLLABORATE_FAILURE = 'GET_SIMILAR_COLLABORATE_FAILURE';

export const GET_COLLABORATE_APPLICANTS_REQUEST = 'GET_COLLABORATE_APPLICANTS_REQUEST';
export const GET_COLLABORATE_APPLICANTS_SUCCESS = 'GET_COLLABORATE_APPLICANTS_SUCCESS';
export const GET_COLLABORATE_APPLICANTS_FAILURE = 'GET_COLLABORATE_APPLICANTS_FAILURE';

export const POST_COLLABORATE_APPLICANTS_REJECT_REQUEST =
  'POST_COLLABORATE_APPLICANTS_REJECT_REQUEST';
export const POST_COLLABORATE_APPLICANTS_REJECT_SUCCESS =
  'POST_COLLABORATE_APPLICANTS_REJECT_SUCCESS';
export const POST_COLLABORATE_APPLICANTS_REJECT_FAILURE =
  'POST_COLLABORATE_APPLICANTS_REJECT_FAILURE';

export const GET_COLLABORATE_REJECTED_APPLICANTS_REQUEST =
  'GET_COLLABORATE_REJECTED_APPLICANTS_REQUEST';
export const GET_COLLABORATE_REJECTED_APPLICANTS_SUCCESS =
  'GET_COLLABORATE_REJECTED_APPLICANTS_SUCCESS';
export const GET_COLLABORATE_REJECTED_APPLICANTS_FAILURE =
  'GET_COLLABORATE_REJECTED_APPLICANTS_FAILURE';

export const POST_COLLABORATE_APPLICANTS_RESTORE_REQUEST =
  'POST_COLLABORATE_APPLICANTS_RESTORE_REQUEST';
export const POST_COLLABORATE_APPLICANTS_RESTORE_SUCCESS =
  'POST_COLLABORATE_APPLICANTS_RESTORE_SUCCESS';
export const POST_COLLABORATE_APPLICANTS_RESTORE_FAILURE =
  'POST_COLLABORATE_APPLICANTS_RESTORE_FAILURE';

export const FEED_REQUEST = 'FEED_REQUEST';
export const FEED_SUCCESS = 'FEED_SUCCESS';
export const FEED_FAILURE = 'FEED_FAILURE';
export const PROFILE_REQUEST = 'PROFILE_REQUEST';

// LIKE A POST
export const LIKE_A_POST_REQUEST = 'LIKE_A_POST_REQUEST';
export const LIKE_A_POST_SUCCESS = 'LIKE_A_POST_SUCCESS';
export const LIKE_A_POST_FAILURE = 'LIKE_A_POST_FAILURE';

// COMMENTS
export const GET_A_POST_COMMENT_REQUEST = 'GET_A_POST_COMMENT_REQUEST';
export const GET_A_POST_COMMENT_SUCCESS = 'GET_A_POST_COMMENT_SUCCESS';
export const GET_A_POST_COMMENT_FAILURE = 'GET_A_POST_COMMENT_FAILURE';

// messages constants
export const MARK_MESSAGE_READ_REQUEST = 'MARK_MESSAGE_READ_REQUEST';
export const MARK_MESSAGE_READ_SUCCESS = 'MARK_MESSAGE_READ_SUCCESS';
export const MARK_MESSAGE_READ_FAILURE = 'MARK_MESSAGE_READ_FAILURE';

//new message sent from socket
export const NEW_MESSAGE_SENT_FROM_SOCKET = 'NEW_MESSAGE_SENT_FROM_SOCKET';
export const NEW_MESSAGE_READ_FROM_SOCKET = 'NEW_MESSAGE_READ_FROM_SOCKET';
export const UPDATE_OR_APPEND_CHAT_INFO = 'UPDATE_OR_APPEND_CHAT_INFO';

export const NEW_CHAT_MESSAGE_SUCCESS = 'NEW_CHAT_MESSAGE_SUCCESS';
export const UPDATE_AND_SHOW_NEW_MESSAGE_COUNT = 'UPDATE_AND_SHOW_NEW_MESSAGE_COUNT';
export const RESET_AND_HIDE_NEW_MESSAGE_COUNT = 'RESET_AND_HIDE_NEW_MESSAGE_COUNT';

// Tagging
export const GET_PROFILES_FOR_TAGGING = 'GET_PROFILES_FOR_TAGGING';
export const GET_ALL_USERS_FOR_TAGGING = 'GET_ALL_USERS_FOR_TAGGING';

// BLOCK USER
export const BLOCK_USER_REQUEST = 'BLOCK_USER_REQUEST';
export const BLOCK_USER_SUCCESS = 'BLOCK_USER_SUCCESS';