import { UPDATE_UPLOAD_PERCENTAGE,
    RESET_UPLOAD_PERCENTAGE } from "../../actions/common/constants";

const INITIAL_STATE = {
    current: null,
    percentage: 0
};

export default function uploading(state = INITIAL_STATE, action) {
    switch(action.type) {
        case UPDATE_UPLOAD_PERCENTAGE:
            return {
                current: action.payload.type,
                percentage: action.payload.percentage
            }
        case RESET_UPLOAD_PERCENTAGE:
            return INITIAL_STATE
        default:
            return state;
    }
}