import { GET_ALL_PEOPLE_REQUEST,
    GET_ALL_PEOPLE_SUCCESS,
    GET_ALL_PEOPLE_FAILURE,
    RESET_ALL_PEOPLE,
    FOLLOW_USER_SUCCESS} from "../../actions/profile/constants";

const INITIAL_STATE = {
    page: 1,
    fetching: false,
    error: null,
    people: [],
    hasEnd: false,
    hasFetched: false
}
export default function all(state = INITIAL_STATE, action) {
    switch(action.type) {
        case GET_ALL_PEOPLE_REQUEST:
            return {
                ...state,
                fetching: true,
                error: false
            }
        case GET_ALL_PEOPLE_SUCCESS:
                return {
                    ...state,
                    fetching: false,
                    page: state.page + 1,
                    // people: state.page === 1
                    //     ? action.payload.data
                    //     : [...state.people, ...action.payload.data],
                    // hasEnd: !action.payload.data.length,
                    people: state.page === 1
                        ? action.payload
                            && action.payload.profile
                                ? action.payload.profile
                                : []
                        : [...state.people, ...(action.payload.profile
                            && action.payload.profile)],
                    hasEnd: (!action.payload.profile
                        || (action.payload.profile
                            && !action.payload.profile.length)),
                    hasFetched: true
                }
        case GET_ALL_PEOPLE_FAILURE:
                return {
                    ...state,
                    ...(!action.error.__CANCEL__ && {
                        error: true,
                        errorMsg: action.error
                    }),
                    fetching: false,
                }
        case FOLLOW_USER_SUCCESS:
            return {
                ...state,
                people: !!state.people.length
                    ? state.people.map((profile) => {
                        if (profile.id !== action.payload.profileId) return profile;
                        return {
                            ...profile,
                            isFollowing: true
                        }
                    })
                    : state.people
            }
        case RESET_ALL_PEOPLE:
            return INITIAL_STATE;
        default:
            return state;
    }
}