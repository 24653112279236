import { GET_TTFB_PARTNERS_REQUEST,
    GET_TTFB_PARTNERS_SUCCESS,
    GET_TTFB_PARTNERS_FAILURE,
    RESET_TTFB_PARTNERS } from '../../actions/business/constants';

const INITIAL_STATE = {
    fetching: false,
    page: 1,
    data: null,
    error: false
}

export default function partners(state = INITIAL_STATE, action) {
    switch(action.type) {
        case GET_TTFB_PARTNERS_REQUEST:
            return {
                ...state,
                fetching: true
            }
        case GET_TTFB_PARTNERS_SUCCESS:
            return {
                ...state,
                fetching: false,
                page: state.page + 1,
                data: state.page === 1
                    ? action.payload
                    : {
                        ...state.data,
                        data: [...state.data.data,
                        ...action.payload.data]
                    },
                hasFetched: true,
                hasEnd: (!action.payload.data.length
                    || (!!action.payload.data.length
                        && action.payload.data.length < 20))
            }
        case GET_TTFB_PARTNERS_FAILURE:
            return {
                ...state,
                ...(!action.error.__CANCEL__ && {
                    error: true,
                    errorMsg: action.error
                }),
                fetching: false,
            }
        case RESET_TTFB_PARTNERS:
            return INITIAL_STATE
        default:
            return state;
    }
}