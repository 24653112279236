import { GET_SINGLE_CAREER_OBJ,
} from './constants';
import axios from 'axios';
import { getResponseData } from '../utils/helper';
import { getBaseUrl,
        CareerUrls} from '../utils/links';

export const getSingleCareer = (payload) => {
    return {
        type: GET_SINGLE_CAREER_OBJ,
        payload
    }
}

export const postApplication = (data) => {
    let url = `${getBaseUrl()}${CareerUrls.postCareer}`,
        options = {
            url,
            data,
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
    return axios(options)
        .then(response => {
            let successData = getResponseData(response);
            return successData;
        })
        .catch(_ => false)
}