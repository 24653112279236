import get from 'lodash/get';
import { TransactionIdColumn, AmountColumn, StatusColumn } from './CommonElements';

export const getAllTableElement = (columnId) => {
  let output;
  switch (columnId) {
    case 'transaction_id':
      output = TransactionIdColumn;
      break;
    case 'amount':
      output = AmountColumn;
      break;
    case 'status':
      output = StatusColumn;
      break;

    default:
  }
  return output;
};

export const getStateColumnCssClasses = (status) => {
  const id = get(status, 'id');
  const value = get(status, 'value');
  const output = {
    statusText: value,
    cssClass: 'passbook__table__body__row__col__status__text',
    chevronClass: 'passbook__table__body__row__col__status__chevron',
  };

  switch (id) {
    case 2:
      output.statusText = 'Pending';
      output.cssClass = 'passbook__table__body__row__col__status__redeem';
      output.chevronClass += ' passbook__table__body__row__col__status__chevron__marginbottom';
      break;
    case 3:
      output.statusText = 'Redeemed';
      break;

    default:
  }
  return output;
};
