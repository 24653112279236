import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getPostPreview } from '../../actions/feed/common';

function SendAsAMessageButton(props) {
  const [postType] = useState(props.postType),
    [postId] = useState(props.postId),
    [shareId] = useState(props.shareId || false),
    [className] = useState(props.className || ''),
    [token] = useState(props.token);
  function fetchPreview() {
    const { getPostPreview } = props;
    getPostPreview(token, postType, postId, shareId);
  }
  return (
    <>
      <button className={className} onClick={fetchPreview}>
        {props.children}
      </button>
    </>
  );
}

function mapStateToProps(state) {
  return {
    token: state.login.token,
  };
}

SendAsAMessageButton.propTypes = {
  postId: PropTypes.any.isRequired,
  postType: PropTypes.string.isRequired,
  shareId: PropTypes.number,
};

export default connect(mapStateToProps, { getPostPreview })(SendAsAMessageButton);
