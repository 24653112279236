import React, { Component } from 'react';
import { connect } from 'react-redux';
import { socketLinks } from '../../../utils/links';
import io from 'socket.io-client';
import { readNotificationFromSocket,
    updateChatNotificationCount,
    updateNotificationCount } from '../../../actions/notifications/index';
class NotificationSocket extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSocketConnected: false
        }
    }

    componentDidMount() {
        const { token,
            user,
            updateNotificationCount,
            readNotificationFromSocket,
            updateChatNotificationCount } = this.props,
            { isSocketConnected } = this.state;
        if (!isSocketConnected && token) {
            const notificationSocketLink = `${socketLinks.notifications}?token=${token}`,
                notificationSocket = io(notificationSocketLink);
            this.setState({
                notificationSocketLink,
                notificationSocket
            });
            notificationSocket.on('connect', () => {
            });
            notificationSocket.on('message', function(payload) {
                if (!payload) return;
                let currentNoficiation = JSON.parse(payload);
                // checking if chat or message
                if (currentNoficiation.data
                    && currentNoficiation.data.action
                    && (currentNoficiation.data.action === "chat"
                    || currentNoficiation.data.action === "message")) {
                    if(window.location.pathname === "/messenger") {
                        return;
                    }
                    updateChatNotificationCount();
                    return;
                }
                // checking if self notification
                if (user
                    && user.user
                    && user.user.profile
                    && currentNoficiation.data
                    && currentNoficiation.data.profile
                    && currentNoficiation.data.profile.id
                    && currentNoficiation.data.profile.id === user.user.profile.id) {
                    return;
                }
                readNotificationFromSocket(currentNoficiation)
                updateNotificationCount();
            })
        }
    }

    render() {
        return (<div></div>)
    };
}

function mapStateToProps(state) {
    return {
        token: state.login.token,
        user: state.user
    }
}

const mapDispatchToProps = {
    readNotificationFromSocket,
    updateNotificationCount,
    updateChatNotificationCount
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationSocket);