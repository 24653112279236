import React, { useState,
    useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types'
import EmptyField from '../../../containers/loggedIn/Profile/EmptyField';
import EditIcon from '../../../assets/images/profile/edit/edit.svg';
import { toggleProfileCompletionModal } from '../../../actions/common/modals';

const texts = {
    subTitle: 'What do you Specialise in?',
    title: 'Specialisation'
}

function ProfileSpecialisation(props) {
    const [view, setView] = useState(props.view),
        [showAll, setShowAll] = useState(false),
        specialisationHiddenLimit = 7,
        { isSelf,
            isMandatoryModal,
            toggleSpecialisationModal,
            toggleProfileCompletionModal } = props;
    useEffect(() => {
        setView(props.view)
    }, [props.view]);
    // doing this because of bad reducer
    if (!isSelf
        && (!view
        || (view && !view.profile)
        || (view && view.profile && !view.profile.profile)
        || (view && view.profile && view.profile.profile && !view.profile.profile.profile))) return null
    const profile = isSelf
        ? props.user.user.profile
        : view.profile.profile.profile,
        specialisations = profile.profile_specializations,
        specialisationsToShow = showAll
            || (specialisations && specialisations.length < specialisationHiddenLimit)
            ? specialisations
            : specialisations && specialisations.length
            ? specialisations.filter((_, i) => i < specialisationHiddenLimit)
            : [];
    if (!specialisationsToShow.length && !isSelf) return specialisationsToShow;
    const toggleModal = () => isMandatoryModal
        ? toggleSpecialisationModal()
        : toggleProfileCompletionModal('specialization');
    return (<>
        {
            !specialisationsToShow.length
            ? <div className="profile-view__details__block">
                <EmptyField clickFunc={() => toggleModal()}
                    title={texts.title}
                    subTitle={texts.subTitle} />
            </div>
            : <div className="profile-view__details__block profile-view__details__block__specialisation">
                <p className="profile-view__details__block__specialisation__title">
                    {texts.title}
                    { isSelf && (<button onClick={() => toggleModal()}
                        className="profile-view__details__edit-button">
                        <img src={EditIcon} alt="edit profile_specialisation" className="profile-view__details__edit-button__image"/>
                    </button>) }
                </p>
                <ul className="profile-view__details__block__specialisation__list">
                    {
                        specialisationsToShow.map((specialisation, index) => {
                            return <li key={index} className="profile-view__details__block__specialisation__list__item">
                                { specialisation.image && <img className="profile-view__details__block__specialisation__list__icon" src={specialisation.image} alt={specialisation.name} /> }
                                <p>{specialisation.name}</p>
                            </li>
                        })
                    }
                    {
                        !showAll
                        && specialisations.length > specialisationHiddenLimit
                        && <li className="profile-view__details__block__specialisation__list__item">
                            <button className="profile-view__details__block__specialisation__more-btn"
                                onClick={() => setShowAll(true)}>+{(specialisations.length - specialisationHiddenLimit)} more</button>
                        </li>
                    }
                </ul>
            </div>
        }
    </>)
};

function mapStateToProps(state) {
    return {
        user: state.user,
        token: state.login.token,
        view: state.profileReducer.view,
    }
}

ProfileSpecialisation.propTypes = {
    isSelf: PropTypes.bool,
    toggleSpecialisationModal: PropTypes.func
}

export default connect(mapStateToProps, { toggleProfileCompletionModal })(ProfileSpecialisation);