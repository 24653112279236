import { combineReducers } from 'redux';
import leftPanel from './leftPanel';
import currentChat from './currentChat';
import linkPreview from './linkPreview';
import chatSearch from './chatSearch';
import followers from './followers';

const chatReducer = combineReducers({
    leftPanel,
    chatSearch,
    currentChat,
    linkPreview,
    followers
})

export default chatReducer;