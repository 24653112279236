import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import FacebookShareIcon from '../assets/images/common/sharing/facebook.svg';
import TwitterShareIcon from '../assets/images/common/sharing/twitter.svg';
import LinkedInShareIcon from '../assets/images/common/sharing/linkedin.svg';
import MessageIcon from '../assets/images/common/sharing/chat-temp.svg';
import FeedShareIcon from '../assets/images/common/sharing/post-update.svg';
import CopyShareIcon from '../assets/images/common/sharing/copyLink.svg';
import { shareViaFB, shareViaTwitter, shareViaLinkedIn } from '../actions/common/share';
import SendAsAMessageButton from './messages/SendAsAMessageButton';
import {
  getASharedPost,
  getASharedPostCancelToken,
  resetSharedDetailPost,
} from '../actions/publicView/shared';
import { getPostData, getPostDataCancelToken, resetPublicView } from '../actions/publicView/view';
import copy from 'copy-to-clipboard';

function SharePopUp(props) {
  const {
    shareOnFeed,
    getASharedPost,
    getPostData,
    resetPublicView,
    resetSharedDetailPost,
    token,
    postId,
    hideInternalSharing,
    isProfile,
    handle,
    postType,
    onlyExternal,
    shareLink,
    sharedId,
  } = props;
  const [copying, setCopying] = useState(false);
  const [link, setLink] = useState(null);

  const loadFb = () => {
    if (!window.FB) {
      const script = document.createElement('script');
      script.src = 'https://connect.facebook.net/en_US/sdk.js';
      script.async = true;
      script.defer = true;
      script.type = 'text/javascript';
      script.onload = function () {
        window.fbAsyncInit = function () {
          if (window.FB) {
            window.FB.init({
              appId: process.env.REACT_APP_FACEBOOK_ID,
              autoLogAppEvents: true,
              xfbml: true,
              version: process.env.REACT_APP_FACEBOOK_VERSION,
            });
          }
        };
      };
      document.body.appendChild(script);
    }
  };

  const getLink = () => {
    if (!postType || !postId) return;
    if (sharedId) {
      getASharedPost(sharedId, postType, postId, token).then((postData) => {
        if (postData && postData.social) {
          setLink(postData.social.deeplink);
        }
        resetSharedDetailPost();
      });
    } else {
      getPostData(postType, postId).then((postData) => {
        if (postData && postData.social) {
          setLink(postData.social.deeplink);
        }
        resetPublicView();
      });
    }
  };

  useEffect(() => {
    loadFb();
    getLink();
    return () => {
      if (getPostDataCancelToken) getPostDataCancelToken();
      if (getASharedPostCancelToken) getASharedPostCancelToken();
    };
  }, []);

  const _handleFbShare = () => {
    shareViaFB(isProfile ? handle : postId, postType, sharedId, shareLink || link);
  };

  const _handleTwitterShare = () => {
    shareViaTwitter(isProfile ? handle : postId, postType, '', sharedId, shareLink || link);
  };

  const _handleLinkedInShare = () => {
    shareViaLinkedIn(isProfile ? handle : postId, postType, sharedId, shareLink || link);
  };

  const _copyLink = () => {
    if (link) {
      copy(link);
      document.querySelector('#root').click();
    } else if (shareLink) {
      copy(shareLink);
      document.querySelector('#root').click();
    } else {
      setCopying(true);
      if (sharedId) {
        getASharedPost(sharedId, postType, postId, token).then((postData) => {
          if (postData) {
            setCopying(false);
            copy(postData.social.deeplink);
            resetSharedDetailPost();
            document.querySelector('#root').click();
          }
        });
      } else {
        getPostData(postType, postId).then((postData) => {
          if (postData) {
            setCopying(false);
            copy(postData.social.deeplink);
            resetPublicView();
            document.querySelector('#root').click();
          } else {
            setCopying(false);
            alert('Something went wrong!');
          }
        });
      }
    }
  };

  const secondaryListClassName = onlyExternal ? '' : 'post-share-popup-up__list--secondary';

  return (
    <div className="post-share-popup-up">
      {!onlyExternal && (
        <ul className="post-share-popup-up__list">
          {!hideInternalSharing && (
            <li onClick={shareOnFeed} className="post-share-popup-up__list__item">
              <img
                className="post-share-popup-up__list__item__icon"
                src={FeedShareIcon}
                alt="share on feed"
              />
              <span className="post-share-popup-up__list__item__text">Share on feed</span>
            </li>
          )}
          <li className="post-share-popup-up__list__item">
            <SendAsAMessageButton
              postId={postId}
              shareId={sharedId}
              className="post-share-popup-up__list__item__btn"
              postType={postType}
            >
              <img
                className="post-share-popup-up__list__item__icon"
                src={MessageIcon}
                alt="send as a message"
              />
              <span className="post-share-popup-up__list__item__text">Send as a message</span>
            </SendAsAMessageButton>
          </li>
        </ul>
      )}
      <ul className={`post-share-popup-up__list ${secondaryListClassName}`}>
        <li onClick={_handleFbShare} className="post-share-popup-up__list__item">
          <img
            className="post-share-popup-up__list__item__icon"
            src={FacebookShareIcon}
            alt="share on facebook"
          />
          <span className="post-share-popup-up__list__item__text">Facebook</span>
        </li>
        <li onClick={_handleTwitterShare} className="post-share-popup-up__list__item">
          <img
            className="post-share-popup-up__list__item__icon"
            src={TwitterShareIcon}
            alt="share on twitter"
          />
          <span className="post-share-popup-up__list__item__text">Twitter</span>
        </li>
        <li onClick={_handleLinkedInShare} className="post-share-popup-up__list__item">
          <img
            className="post-share-popup-up__list__item__icon"
            src={LinkedInShareIcon}
            alt="share on linkedin"
          />
          <span className="post-share-popup-up__list__item__text">LinkedIn</span>
        </li>
        <li
          className="post-share-popup-up__list__item"
          onClick={(e) => {
            e.nativeEvent.stopImmediatePropagation();
            _copyLink();
          }}
        >
          <img
            className="post-share-popup-up__list__item__icon"
            src={CopyShareIcon}
            alt="copy link"
          />
          <span className="post-share-popup-up__list__item__text">
            {copying ? 'Copying...' : 'Copy Link'}
          </span>
        </li>
      </ul>
    </div>
  );
}

SharePopUp.propTypes = {
  shareOnFeed: PropTypes.func,
  hideInternalSharing: PropTypes.bool,
  isProfileOrCompany: PropTypes.bool,
  isProfile: PropTypes.bool,
  handle: PropTypes.string,
  shareLink: PropTypes.string,
  onlyExternal: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    token: state.login.token,
    preview: state.feedReducer.preview,
  };
}

const mapDispatchToProps = {
  getPostData,
  getASharedPost,
  resetSharedDetailPost,
  resetPublicView,
};

export default connect(mapStateToProps, mapDispatchToProps)(SharePopUp);
