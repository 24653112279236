import React, { useState } from 'react';
import { makePlaylistForReactPlayer } from '../utils/helper';
import ReactPlayer from 'react-player';
import { InView } from 'react-intersection-observer';
import { useEffect } from 'react';
import { updatedCurrentVideo } from '../actions/common/player';
import { connect } from 'react-redux';
export function generateUniqueId(prefix = 'player-') {
  return `${prefix}${Date.now()}-${Math.floor(Math.random() * 1000000)}`;
}


function CommonPlayer(props) {
  const {
    videosMeta,
    video,
    updatedCurrentVideo
  } = props;

  //had to do this because copiedSection / copiedQuestion did not have any id
  const [playerId] = useState(generateUniqueId); // it MUST be uniue throughout the app

  const handlePlay = (videoId) => {
    updatedCurrentVideo(videoId)
  }

  const handlePause = () => {
    updatedCurrentVideo(null)
  }
  useEffect(() => {
    // to stop the video if the component is unmounted
    return () => {
      if (video) {
        updatedCurrentVideo(null)
      }
    };
  }, []);

  return (
    <div>
      {videosMeta && Array.isArray(videosMeta) && videosMeta.length
        ? videosMeta.map((video, index) => {
          const videoId = playerId || index;
          const playlist = (video && makePlaylistForReactPlayer(video.media_json)) || [];
          const isPlaying = props.video === videoId
          return (
            <InView
              key={videoId}
              as="div"
              threshold={0}
              onChange={(inView) => {
                if (!inView && props.video === videoId) {
                  handlePause(); // Pause video if it goes out of view
                }
              }}
            >
              <ReactPlayer
                className="react-player__video"
                url={playlist}
                controls={true}
                playing={isPlaying}
                onPlay={() => handlePlay(videoId)}
                // onPause={() => { if (!isPlaying) handlePause() }}
                height="100%"
                width="100%"
                onContextMenu={(e) => e.preventDefault()}
                config={{
                  file: {
                    attributes: {
                      controlsList: "nofullscreen nodownload noremoteplayback noplaybackrate",
                      disablePictureInPicture: 'true'
                    },
                  },
                }}
              />
            </InView>
          );
        })
        : null}
    </div>
  );
}


function mapStateToProps(state) {
  return {
    video: state.commonReducer.player.current_video,
  };
}

const mapDispatchToProps = {
  updatedCurrentVideo
};

export default connect(mapStateToProps, mapDispatchToProps)(CommonPlayer)
