import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CloseIcon from '../../assets/images/common/modals/close.svg';
import { Link } from 'react-router-dom';
import Gender from '../collaborations/profileMandatory/Gender';
import Email from '../collaborations/profileMandatory/Email';
import Allergens from '../collaborations/profileMandatory/Allergens';
import Specialisation from '../collaborations/profileMandatory/Specialisation';
import Hometown from '../collaborations/profileMandatory/Hometown';
import City from '../collaborations/profileMandatory/City';
import DateOfBirth from '../collaborations/profileMandatory/DateOfBirth';
import AgeProof from '../collaborations/profileMandatory/AgeProof';
import PhoneNumber from '../collaborations/profileMandatory/PhoneNumber';
import Modal from './common/Modal';
import SpecializationsModal from './profileCompletion/SpecializationsModal';
import AllergensModal from './profileCompletion/AllergensModal';
import AlcoholDocumentProof from './collaborations/AlcoholDocumentProof';
import ShippingAddressEditOrUpdate from './profileEdit/ShippingAddressEditOrUpdate';
import { updateUser } from '../../actions/profile/update';
import ShippingAddresses from '../collaborations/profileMandatory/ShippingAddresses';
import { miscLinks } from '../../utils/internalLinks';

class MandatoryInformationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filledItems: new Map(),
      sendToProfile: [],
      itemsThatWillBeSavedInProfiles: ['gender', 'hometown', 'dob', 'city'],
      showModal: null,
      selectedShippingAddress: null,
      availableComponents: [
        'gender',
        'verified_email',
        'allergens',
        'profile_specializations',
        'hometown',
        'dob',
        'city',
        'document_meta',
        'address',
        'phone',
        'verified_phone',
      ],
      isChecked: false,
      processing: false,
    };
    this.addToFilledItems = this.addToFilledItems.bind(this);
    this.removeFromFilledItems = this.removeFromFilledItems.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.checkForMandatoryItemsFilled = this.checkForMandatoryItemsFilled.bind(this);
    this.proceedButton = this.proceedButton.bind(this);
    this.addItemsToBeSavedInProfileOnLoad = this.addItemsToBeSavedInProfileOnLoad.bind(this);
    this.segregateItemsThatAreHandled = this.segregateItemsThatAreHandled.bind(this);
    this.checkForSubmittedItems = this.checkForSubmittedItems.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  toggleModal(type = null) {
    this.setState({
      showModal: type,
    });
  }

  proceedButton() {
    // checking if anything has to be sent to profile
    const { sendToProfile, filledItems, processing } = this.state,
      { user, token, updateUser, proceedAction } = this.props;
    if (processing) return;
    this.setState({ processing: true });
    if (sendToProfile.length) {
      let profileItems = {};
      for (let [key, value] of filledItems.entries()) {
        if (sendToProfile.includes(key)) {
          profileItems = {
            ...profileItems,
            ...value,
          };
        }
      }
      const data = {
        _method: 'PATCH',
        profile: {
          ...profileItems,
        },
      };
      updateUser(user.user.profile.id, data, token).then((_) => {
        this.setState({ processing: false });
        if (_) {
          proceedAction(filledItems);
        }
      });
    } else {
      proceedAction(filledItems);
    }
  }

  addItemsToBeSavedInProfileOnLoad() {
    const { mandatoryFields } = this.props,
      { itemsThatWillBeSavedInProfiles } = this.state;
    // adding items that will be saved in profile
    let element = [];
    mandatoryFields.forEach((item) => {
      if (item !== 'phone' && itemsThatWillBeSavedInProfiles.includes(item)) {
        element.push(item);
      }
    });
    if (mandatoryFields.includes('phone') && !mandatoryFields.includes('verified_phone')) {
      element.push('phone');
    }
    this.setState({
      sendToProfile: element,
    });
  }

  segregateItemsThatAreHandled() {
    const { mandatoryFields } = this.props,
      { availableComponents } = this.state;
    let elementsAvailable = [],
      elementsNotAvailable = [];
    mandatoryFields.forEach((item) => {
      if (availableComponents.includes(item)) {
        elementsAvailable.push(item);
      } else {
        elementsNotAvailable.push(item);
      }
    });
    this.setState(
      {
        elementsAvailable,
        elementsNotAvailable,
      },
      () => {
        this.setState({
          isMounted: true,
        });
        this.checkForSubmittedItems();
      },
    );
  }

  checkForSubmittedItems() {
    /*
                'phone',
                'verified_phone'],
        */
    const { elementsAvailable } = this.state,
      {
        user: {
          user: { verified_at, profile },
        },
      } = this.props;
    // checking if gender filled
    if (elementsAvailable.includes('gender') && profile.gender) {
      this.addToFilledItems('gender', { gender: profile.gender });
    }
    // checking if gender filled
    if (elementsAvailable.includes('dob') && profile.dob) {
      this.addToFilledItems('dob', { dob: profile.dob });
    }
    // checking if verified email
    if (elementsAvailable.includes('verified_email') && verified_at) {
      this.addToFilledItems('verified_email', true);
    }
    // checking if allergens filled
    if (elementsAvailable.includes('allergens') && profile.allergens && profile.allergens.length) {
      this.addToFilledItems(
        'allergens',
        profile.allergens.map((_) => _.id),
      );
    }
    // checking if specialisation filled
    if (
      elementsAvailable.includes('profile_specializations') &&
      profile.profile_specializations &&
      profile.profile_specializations.length
    ) {
      this.addToFilledItems(
        'profile_specializations',
        profile.profile_specializations.map((_) => _.id),
      );
    }
    // checking if hometown filled
    if (elementsAvailable.includes('hometown') && profile.hometown) {
      this.addToFilledItems('hometown', {
        hometown: profile.hometown,
      });
    }
    // checking if city filled
    if (elementsAvailable.includes('city') && profile.city) {
      this.addToFilledItems('city', {
        city: profile.city,
      });
    }
  }

  componentDidMount() {
    this.addItemsToBeSavedInProfileOnLoad();
    this.segregateItemsThatAreHandled();
    document.body.style.overflow = "hidden";
  }
  componentWillUnmount () {
    document.body.style.overflow = "auto";
  }

  addToFilledItems(item, value) {
    // let newFilledItems = new Map(this.state.filledItems);
    // newFilledItems.set(item, value);
    this.setState((prevState) => {
      const filledItems = new Map(prevState.filledItems);
      filledItems.set(item, value);
      return {
        ...prevState,
        filledItems,
      };
    });
  }

  removeFromFilledItems(item) {
    let newFilledItems = new Map(this.state.filledItems);
    newFilledItems.delete(item);
    this.setState({
      filledItems: newFilledItems,
    });
  }

  checkForMandatoryItemsFilled = () => {
    // const { collaborationMandatoryFields } = this.props,
    const { elementsAvailable } = this.state;
    const doesItContainsBothVerifiedAndPhoneNumber =
      elementsAvailable.includes('phone') && elementsAvailable.includes('verified_phone');
    let lengthOfMandatoryFieldsToCheck = doesItContainsBothVerifiedAndPhoneNumber
      ? elementsAvailable.length - 1
      : elementsAvailable.length;
    return elementsAvailable.includes('document_meta')
      ? this.state.filledItems.size !== lengthOfMandatoryFieldsToCheck || !this.state.isChecked
      : this.state.filledItems.size !== lengthOfMandatoryFieldsToCheck;
  };

  handleClose() {
    if (this.props.handleCloseForNonFilledState) {
      this.props.handleCloseForNonFilledState();
    } else {
      this.props.closeModal();
    }
  }
  render() {
    const { closeModal, isSurvey, isProcessing,  useFor = "", mandatoryFields } = this.props,
      { showModal, elementsAvailable, isMounted, processing, elementsNotAvailable } = this.state;
    if (!isMounted) return null;
    const doesMandatoryItemsContainsPersonalInformation =
      elementsAvailable &&
      elementsAvailable.length &&
      (elementsAvailable.includes('gender') ||
        elementsAvailable.includes('dob') ||
        elementsAvailable.includes('phone') ||
        elementsAvailable.includes('verified_phone') ||
        elementsAvailable.includes('verified_email')) ||
        elementsAvailable.includes('city') ||
        elementsAvailable.includes('hometown'),
      isProceedButtonDisabled = this.checkForMandatoryItemsFilled() || processing || isProcessing;
    return (
      <>
        <div className="default-modal mandatory-infromation-product-modal">
          <div className="default-modal__backdrop">
            <div className={`default-modal__container mandatory-infromation-product-modal__message 
            ${useFor === "creation" ? "mandatory-infromation-product-modal__message--creation" : ""}`}>
              <div className="default-modal__header">
                <p className="default-modal__header__title">
                 {useFor === "creation" ? "Validate Your Contact Details" : "Details Required"} 
                </p>
                <button className="default-modal__header__close">
                  <img src={CloseIcon} alt="close modal" onClick={this.handleClose} />
                </button>
              </div>
              <div>
                <p className="mandatory-infromation-product-modal__sub-title">
                  {
                    useFor === "creation" ?
                    `For optimal experience and to create collab, polls, surveys and quizzes we need to verify your ${mandatoryFields.length == 2 ? "email and phone" : mandatoryFields.length == 1 && mandatoryFields[0] === "verified_email" ? "email" : "phone"}.
                     Please share ${mandatoryFields.length == 2 ? "these" : "the"} details for verification.`
                    : `Please validate/update the following sections to show Interest in this
                    ${isSurvey ? 'survey' : 'collaboration'}. Information shared here will be shared
                    with the admin.`
                  }
                 
                </p>
                {doesMandatoryItemsContainsPersonalInformation && (
                  <div className="mandatory-infromation-product-modal__personal">
                    {
                      useFor !== "creation"  && 
                      <p className="mandatory-infromation-product-modal__personal__title profile-view__details__block__professional__title">
                      Personal
                    </p>
                    }
                  
                    {(elementsAvailable.includes('gender') ||
                      elementsAvailable.includes('dob')) && (
                        <div className="profile-edit-modal__form__group profile-edit-modal__form__group--multi">
                          {elementsAvailable.includes('gender') && (
                            <Gender
                              addToFilledItems={this.addToFilledItems}
                              removeFromFilledItems={this.removeFromFilledItems}
                              isSelf={true}
                            />
                          )}
                          {elementsAvailable.includes('dob') && (
                            <DateOfBirth
                              addToFilledItems={this.addToFilledItems}
                              removeFromFilledItems={this.removeFromFilledItems}
                            />
                          )}
                        </div>
                      )}
                    {elementsAvailable.includes('verified_email') && (
                      <Email
                        addToFilledItems={this.addToFilledItems}
                        shouldCheckForNewProfile={true}
                        removeFromFilledItems={this.removeFromFilledItems}
                      />
                    )}
                    {(elementsAvailable.includes('phone') ||
                      elementsAvailable.includes('verified_phone')) && (
                        <PhoneNumber
                          addToFilledItems={this.addToFilledItems}
                          removeFromFilledItems={this.removeFromFilledItems}
                          isVerification={elementsAvailable.includes('verified_phone')}
                          useFor={useFor}
                        />
                      )}
                    {elementsAvailable.includes('city') && (
                      <City
                        addToFilledItems={this.addToFilledItems}
                        removeFromFilledItems={this.removeFromFilledItems}
                      />
                    )}
                    {elementsAvailable.includes('hometown') && (
                      <Hometown
                        addToFilledItems={this.addToFilledItems}
                        removeFromFilledItems={this.removeFromFilledItems}
                      />
                    )}
                  </div>
                )}
                {elementsAvailable.includes('profile_specializations') && (
                  <Specialisation
                    isSelf={true}
                    isMandatoryModal={true}
                    toggleSpecialisationModal={() => this.toggleModal('specialisation')}
                  />
                )}
                {elementsAvailable.includes('address') && (
                  <ShippingAddresses
                    toggleShippingAddressModal={(shippingAddress = null) => {
                      this.toggleModal('address');
                      this.setState({
                        shippingAddress,
                      });
                    }}
                    addToFilledItems={this.addToFilledItems}
                    isMandatoryItem={true}
                    isSelf={true}
                  />
                )}
                {elementsAvailable.includes('allergens') && (
                  <Allergens
                    isMandatoryModal={true}
                    toggleAllergensModal={() => this.toggleModal('allergens')}
                    isSelf={true}
                  />
                )}
                {elementsAvailable.includes('document_meta') && (
                  <>
                    <AgeProof
                      toggleAgeProofModal={() => this.toggleModal('document_meta')}
                      addToFilledItems={this.addToFilledItems}
                      removeFromFilledItems={this.removeFromFilledItems}
                      isSelf={true}
                    />
                    <label className="document-upload-modal__checkbox-container">
                      <input
                        type="checkbox"
                        className="document-upload-modal__checkbox-container__checkbox"
                        checked={this.state.isChecked}
                        onChange={() => {
                          this.setState(
                            {
                              isChecked: !this.state.isChecked,
                            },
                            () => {
                              this.props.toggleTermsAccepted(this.state.isChecked);
                            },
                          );
                        }}
                      />
                      <span className="document-upload-modal__checkbox-container__text">
                        Check to indicate that you have read and agree to the terms presented in the{' '}
                        <Link
                          to={miscLinks.terms}
                          target="_blank"
                          className="document-upload-modal__checkbox-container__link"
                        >
                          Terms and Conditions
                        </Link>{' '}
                        agreement.
                      </span>
                    </label>
                  </>
                )}
              </div>
              <div className="mandatory-infromation-product-modal__footer">
                <button
                  type="button"
                  onClick={this.handleClose}
                  className="mandatory-infromation-product-modal__footer__cancel"
                >
                  Cancel
                </button>
                <button
                  onClick={this.proceedButton}
                  disabled={isProceedButtonDisabled}
                  className={`mandatory-infromation-product-modal__footer__proceed
                            ${isProceedButtonDisabled ? 'mandatory-infromation-product-modal__footer__proceed--disabled' : ''}`}
                >
                  Proceed
                </button>
              </div>
            </div>
          </div>
        </div>
        {showModal === 'specialisation' && (
          <Modal onClose={() => this.toggleModal(null)} className="profile-completion-modal">
            <SpecializationsModal
              handleMandatorySave={(items) => {
                if (items && items.length) {
                  this.addToFilledItems('profile_specializations', items);
                } else {
                  this.removeFromFilledItems('profile_specializations');
                }
              }}
              onClose={() => this.toggleModal(null)}
            />
          </Modal>
        )}
        {showModal === 'allergens' && (
          <Modal onClose={() => this.toggleModal(null)} className="profile-completion-modal">
            <AllergensModal
              handleMandatorySave={(items) => {
                if (items && items.length) {
                  this.addToFilledItems('allergens', items);
                } else {
                  this.removeFromFilledItems('allergens');
                }
              }}
              onClose={() => this.toggleModal(null)}
            />
          </Modal>
        )}
        {showModal === 'document_meta' && (
          <AlcoholDocumentProof
            isMandatoryItem={true}
            addToFilledItems={this.addToFilledItems}
            removeFromFilledItems={this.removeFromFilledItems}
            closeModal={() => this.toggleModal(null)}
          />
        )}
        {showModal === 'address' && (
          <ShippingAddressEditOrUpdate
            handleClose={() => {
              this.toggleModal(null);
              this.setState({
                selectedShippingAddress: null,
              });
            }}
            shippingAddress={this.state.shippingAddress}
          />
        )}
      </>
    );
  }
}
MandatoryInformationModal.propTypes = {
  mandatoryFields: PropTypes.array,
  proceedAction: PropTypes.func,
  toggleTermsAccepted: PropTypes.func,
  isProcessing: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    token: state.login.token,
    user: state.user,
  };
}
export default connect(mapStateToProps, { updateUser })(MandatoryInformationModal);
