import { GET_PRODUCT_HEADER_REPORT_REQUEST,
    GET_PRODUCT_HEADER_REPORT_SUCCESS,
    GET_PRODUCT_HEADER_REPORT_FAILURE,
    RESET_HEADER_REPORT } from "../../actions/products/constants";

const INITIAL_STATE = {
    fetching: false,
    hasFetched: false,
    data: null,
    error: false,
    errorMsg: null,
    showModal: false
}

export default function reports(state = INITIAL_STATE, action) {
    switch(action.type) {
        case GET_PRODUCT_HEADER_REPORT_REQUEST:
            return {
                ...state,
                fetching: true
            }
        case GET_PRODUCT_HEADER_REPORT_SUCCESS:
            return {
                ...state,
                fetching: false,
                data: action.payload.reportData,
                headerId: action.payload.headerId,
                showModal: true
            }
        case GET_PRODUCT_HEADER_REPORT_FAILURE:
            return {
                ...state,
                error: true,
                fetching: false,
                errorMsg: action.error
            }
        case RESET_HEADER_REPORT:
            return INITIAL_STATE
        default:
            return state;
    }
}