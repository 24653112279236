import { GET_PR_MANAGE_HEADER_REPORT_REQUEST,
    GET_PR_MANAGE_HEADER_REPORT_SUCCESS,
    GET_PR_MANAGE_HEADER_REPORT_FAILURE,
    RESET_PR_MANAGE_HEADER_REPORT } from "../../../../actions/collaborations/prManage/constants";

const INITIAL_STATE = {
    fetching: false,
    data: null,
    error: false
}
export default function reports(state = INITIAL_STATE, action) {
    switch(action.type) {
        case GET_PR_MANAGE_HEADER_REPORT_REQUEST:
            return {
                ...state,
                fetching: true
            }
        case GET_PR_MANAGE_HEADER_REPORT_SUCCESS:
            return {
                ...state,
                data: action.payload,
                hasFetched: true,
                fetching: false
            }
        case GET_PR_MANAGE_HEADER_REPORT_FAILURE:
            return {
                ...state,
                ...(!action.error.__CANCEL__ && {
                    error: true,
                    errorMsg: action.error
                }),
                fetching: false
            }
        case RESET_PR_MANAGE_HEADER_REPORT:
            return INITIAL_STATE
        default:
            return state;
    }
}