import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import { hashtagLinks } from '../../utils/internalLinks';
import { gaEvents } from '../../utils/events';
import { decodeString } from '../../utils/helper';

const TrendingHashtagListItem = ({ hashtag }) => {
  const tagWithoutHash = hashtag.tag.replace('#', '').trim();
  const link = hashtagLinks.feed(tagWithoutHash);
  const tag = get(hashtag, 'tag', '');
  const count = get(hashtag, 'total_count', 0);

  return (
    <li className="hashtag__trending__list__item">
      <Link
        className="hashtag__trending__list__link"
        onClick={() => gaEvents.landingpage.trendingHashtag(tagWithoutHash)}
        to={link}
      >
        <p className="hashtag__trending__list__title">{tag}</p>
        <p className="hashtag__trending__list__helper">
          {count} post{count === 1 ? '' : 's'}
        </p>
      </Link>
    </li>
  );
};

function TrendingHashtag(props) {
  const { data, eventLabel } = props;

  const title = get(data, 'title', '');
  const seeMore = get(data, 'see_more', false);

  const [trending, setTrending] = useState([]);
  const [maxCount, setMaxCount] = useState(props.maxCount || null);

  useEffect(() => {
    if (get(data, 'elements', []).length > 0) setTrending(get(data, 'elements', []));
  }, [get(data, 'elements', [])]);

  useEffect(() => {
    setMaxCount(props.maxCount);
  }, [props.maxCount]);

  if (!trending.length) return null;
  const isWithMaxCount = maxCount && trending.length > maxCount;
  const itemToUse = isWithMaxCount ? trending.slice(0, maxCount) : [...trending];

  const hashtagItems = itemToUse.map((hashtag, index) => (
    <TrendingHashtagListItem
      eventLabel={eventLabel}
      key={`trending-hashtag-${index}`}
      hashtag={hashtag}
    />
  ));

  return (
    <div className="landingpage-hashtag">
      <p className="hashtag__trending__title">{decodeString(title)}</p>
      <div className="hashtag__trending">
        <ul className="hashtag__trending__list">
          {hashtagItems}

          {seeMore && (
            <li className="hashtag__trending__list__item">
              <Link
                className="hashtag__trending__list__link hashtag__trending__list__link--see-more"
                onClick={() => {
                  gaEvents.landingpage.trendingHashtag('See More');
                }}
                to={hashtagLinks.trending}
              >
                SEE MORE
              </Link>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
}

TrendingHashtagListItem.propTypes = {
  hashtag: PropTypes.object,
  eventLabel: PropTypes.string,
};
TrendingHashtag.propTypes = {
  maxCount: PropTypes.number,
  showAllAction: PropTypes.func,
  eventLabel: PropTypes.string,
  data: PropTypes.object,
};

export default TrendingHashtag;
