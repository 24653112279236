import get from 'lodash/get';
import {
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAILURE,
  REGISTER_USER_REQUEST,
} from './constants';
import { getBaseUrl, loggedOutUrls } from '../../utils/links';
import {
  onSuccess,
  onError,
  onRequest,
  handleAuthState,
  getResponseData,
} from '../../utils/helper';
import axios from 'axios';
import { getCurrentUser } from "../../actions/user";
import { PREVIEW_USER_SUCCESS } from '../collaborations/constants';

//Login action
export const login = (data) => {
  return async (dispatch) => {
    dispatch(onRequest(LOGIN_USER_REQUEST));
    let url = `${getBaseUrl()}${loggedOutUrls.login}`,
      options = {
        url,
        method: 'POST',
        data,
      };
    try {
      const response = await axios(options)
      if (response.data.token) {
        // handleAuthState.add(response.data.token);
        const profileData = await dispatch(getCurrentUser(response.data.token));
        const { verified_at: isVerified } = profileData;
        if (!!isVerified || !!profileData.profile.onboarding_complete) {
          dispatch(onSuccess(LOGIN_USER_SUCCESS, { token: response.data.token }));
          return { status: true, isVerified: isVerified, onBoardingComplete: profileData.profile.onboarding_complete, token: response.data.token };
        }
        return { status: true, isVerified: isVerified, onBoardingComplete: profileData.profile.onboarding_complete, token: response.data.token};
      } else {
        dispatch(onError(LOGIN_USER_FAILURE));
        return { status: false };
      }
    } catch (error) {
      let apiResponse = error.response ? error.response : null;
      dispatch(onError(LOGIN_USER_FAILURE, error));
      return { status: false, error: apiResponse.data.message || "" };
    }

  };
};

// social login
export const socialLogin = (provider, params) => {
  return (dispatch) => {
    dispatch(onRequest(REGISTER_USER_REQUEST));
    let url = `${getBaseUrl()}${loggedOutUrls.socialLogin(provider)}`,
      options = {
        url,
        params,
      };
    return axios(options)
      .then((response) => {
        if (response && response.data && response.data.status === 'success') {
          handleAuthState.add(response.data.result.token);
          dispatch(
            onSuccess(REGISTER_USER_SUCCESS, {
              token: response.data.result.token,
            }),
          );
          return { status: true, newRegistered: response.data.newRegistered };
        } else {
          dispatch(onError(REGISTER_USER_FAILURE, response.data.errors));
          return {
            status: false,
            error:
              response.data.errors && response.data.errors.email
                ? response.data.errors.email
                : null,
          };
        }
      })
      .catch((error) => {
        dispatch(onError(RESET_PASSWORD_FAILURE, error));
        return {
          status: false,
          error,
        };
      });
  };
};

// social linkedin
export const linkedInSocial = (code) => {
  let url = `${getBaseUrl()}${loggedOutUrls.linkedInLogin}`,
    options = {
      url,
      method: 'POST',
      data: {
        code,
        redirect_uri: process.env.REACT_APP_LINKEDIN_REDIRECT_URI,
      },
    };
  return axios(options)
    .then((response) => {
      let successData = response && response.data && response.data.email ? response.data : false;
      return successData;
    })
    .catch((_) => {
      return false;
    });
};

//forgot password action
export const forgotPassword = (data) => {
  return (dispatch) => {
    dispatch(onRequest(FORGOT_PASSWORD_REQUEST));
    let url = `${getBaseUrl()}${loggedOutUrls.forgotPassword}`,
      options = {
        url,
        method: 'POST',
        data,
      };
    return axios(options)
      .then((response) => {
        let successData = getResponseData(response);
        if (successData) {
          dispatch(onSuccess(FORGOT_PASSWORD_SUCCESS));
          return { status: true };
        } else {
          dispatch(onError(FORGOT_PASSWORD_FAILURE, response.data.errors));
          return {
            status: false,
            message:
              response.data.messages && response.data.messages.email
                ? response.data.messages.email
                : 'Something went wrong! Please try again',
          };
        }
      })
      .catch((error) => {
        dispatch(onError(FORGOT_PASSWORD_FAILURE, error));
        return { status: false, message: 'Something went wrong! Please try again' };
      });
  };
};

// reset password action
export const resetPassword = (data) => {
  return (dispatch) => {
    dispatch(onRequest(RESET_PASSWORD_REQUEST));
    let url = `${getBaseUrl()}${loggedOutUrls.resetPassword}`,
      options = {
        url,
        method: 'POST',
        data,
      };
    return axios(options)
      .then((response) => {
        let successData = getResponseData(response);
        if (successData) {
          dispatch(onSuccess(RESET_PASSWORD_SUCCESS));
          return { status: true };
        } else {
          dispatch(onError(RESET_PASSWORD_FAILURE, response.data.errors));
          return {
            state: false,
            message:
              response.data.messages && response.data.messages.email
                ? response.data.messages.email
                : 'Something went wrong! Please try again.',
          };
        }
      })
      .catch((error) => {
        dispatch(onError(RESET_PASSWORD_FAILURE));
        return { status: false, message: 'Something went wrong! Please try again' };
      });
  };
};

// OTPVerify
export const postVerifyOTP = (data) => {
  return (dispatch) => {
    dispatch(onRequest(LOGIN_USER_REQUEST));
    let url = `${getBaseUrl()}${loggedOutUrls.verifyOTP}`,
      options = {
        url,
        method: 'POST',
        data,
      };
    return axios(options)
      .then((response) => {
        if (get(response, 'data.data.token')) {
          const token = get(response, 'data.data.token');
          // handleAuthState.add(token);
          dispatch(onSuccess(LOGIN_USER_SUCCESS, { token: token }));
          return { status: true, token: token };
        } else {
          const error = get(response, 'data.errors');
          dispatch(onError(LOGIN_USER_FAILURE));
          return { status: false, error };
        }
      })
      .catch((error) => {
        dispatch(onError(LOGIN_USER_FAILURE, error));
        return { status: false };
      });
  };
};
