import { createStore, compose, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import rootReducer from "../reducers/index";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

let middleware = [thunkMiddleware];

if (process.env.REACT_APP_NODE_ENV !== "production") {
  const logger = require("redux-logger").default;
  // middleware = [...middleware];
  middleware = [...middleware, logger];
}

const store = createStore(
  rootReducer,
  process.env.REACT_APP_NODE_ENV !== "production"
    ? composeEnhancers(applyMiddleware(...middleware))
    : applyMiddleware(...middleware)
);

export default store;
