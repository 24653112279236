import {
  OTP_VERIFY_REQUEST,
  OTP_VERIFY_SUCCESS,
  OTP_VERIFY_FAILURE,
  PROFILE_UPDATE_SUCCESS,
  PROFILE_UPDATE_REQUEST,
  PROFILE_UPDATE_FAILURE,
} from './constants';
import Axios from 'axios';
import { getBaseUrl, profileLinks, loggedOutUrls } from '../../utils/links';
import { onError, getResponseData, onSuccess, onRequest } from '../../utils/helper';
import { getResponseDataWithStatus } from '../../utils/helper';
const CancelToken = Axios.CancelToken;

export const getOTP = (data, token) => {
  return (dispatch) => {
    dispatch(onRequest(PROFILE_UPDATE_REQUEST));
    let url = `${getBaseUrl()}${profileLinks.newPhoneVerify}`,
      options = {
        url,
        method: 'POST',
        data,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
    return Axios(options)
      .then((response) => {
        let successData = getResponseData(response);
        if (successData) {
          dispatch(onRequest(PROFILE_UPDATE_SUCCESS, successData));
        } else {
          dispatch(onRequest(PROFILE_UPDATE_FAILURE, response.data.errors));
        }
        return response.data;
        // return successData;
      })
      .catch((error) => {
        dispatch(onRequest(PROFILE_UPDATE_FAILURE, error));
        return false;
      });
  };
};

export let otpVerifyCancelToken = undefined;
export const otpVerify = (data, token) => {
  return (dispatch) => {
    if (otpVerifyCancelToken) otpVerifyCancelToken();
    dispatch(onRequest(OTP_VERIFY_REQUEST));
    let url = `${getBaseUrl()}${profileLinks.newOtpVerify}`,
      options = {
        url,
        method: 'POST',
        data,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        cancelToken: new CancelToken((c) => (otpVerifyCancelToken = c)),
      };
    return Axios(options)
      .then((response) => {
        let successData = getResponseData(response);
        if (successData) {
          dispatch(onSuccess(OTP_VERIFY_SUCCESS, successData));
        } else {
          dispatch(onError(OTP_VERIFY_FAILURE, response.data.errors));
        }
        return response.data;
      })
      .catch((error) => {
        dispatch(onError(OTP_VERIFY_FAILURE, error));
        return false;
      });
  };
};

// loginWithOTP
// OTPSend
export let postOTPSendCancelToken = undefined;
export const postOTPSend = (data = {}) => {
  if (postOTPSendCancelToken) postOTPSendCancelToken();
  const url = `${getBaseUrl()}${loggedOutUrls.loginOTPSend}`;

  const options = {
    url,
    method: 'POST',
    data,
    cancelToken: new CancelToken((c) => (postOTPSendCancelToken = c)),
  };
  return Axios(options)
    .then((response) => {
      return getResponseDataWithStatus(response);
    })
    .catch(() => false);
};
