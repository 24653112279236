import { CREATE_A_POLL_REQUEST,
    CREATE_A_POLL_SUCCESS,
    CREATE_A_POLL_FAILURE } from './constants';
import { onRequest, onSuccess, onError } from '../../utils/helper';
import { getBaseUrl, pollUrls } from '../../utils/links';
import Axios from 'axios';

export const createAPoll = (data,
    token,
    creatorProfile) => {
    return dispatch => {
        dispatch(onRequest(CREATE_A_POLL_REQUEST));
        let url = `${getBaseUrl()}${pollUrls.createOrUpdatePoll}`,
            options = {
                url,
                method: 'POST',
                data,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };
        return Axios(options)
            .then(response => {
                let successData = response
                    && response.data
                    && response.data.data
                    ? response.data.data
                    : false;
                if (successData) {
                    let dataToSend = {
                        ...successData,
                        type: "polling",
                        ...creatorProfile
                    };
                    dispatch(onSuccess(CREATE_A_POLL_SUCCESS, dataToSend));
                } else {
                    dispatch(onError(CREATE_A_POLL_FAILURE, response.data.errors));
                }
                return successData;
            })
            .catch(error => {
                dispatch(onError(CREATE_A_POLL_FAILURE, error))
                return false;
            })
    }
}