import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    updateAllergens,
    updateAllergensCancelToken
} from '../../../actions/profile/update';
import { getAllergens } from '../../../actions/profile/allergens';
import AllergenIcon from '../../../assets/images/profile/completion/allergic-to.svg';

const texts = {
    title: 'Any Allergens?',
    helper: 'Select the items that you are allergic to. We will use this information to refine our product suggestions for you.'
}

class AllergensModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allergens_id: []
        }
        // binding function to see all allergens
        this.handleAllergensSelect = this.handleAllergensSelect.bind(this);
        // binding function to save profile
        this.handleProfileSave = this.handleProfileSave.bind(this);
    }

    componentDidMount() {
        const { token,
            allergens,
            getAllergens,
            profile } = this.props;
        let selectedAllergens = [];
        if (!allergens.all.length) {
            getAllergens(token)
                .then(val => {
                    if (profile.allergens && profile.allergens.length) {
                        selectedAllergens = val.filter(_ => profile.allergens.map(_ => _.name).includes(_.name)).map(_ => _.id);
                        if (selectedAllergens.length) {
                            this.setState({
                                allergens_id: selectedAllergens
                            })
                        }
                    }
                })
        } else {
            selectedAllergens = allergens.all.filter(_ => profile.allergens.map(_ => _.name).includes(_.name)).map(_ => _.id);
            if (selectedAllergens.length) {
                this.setState({
                    allergens_id: selectedAllergens
                });
            }
        }
    }

    handleAllergensSelect(e) {
        let isChecked = e.target.checked,
            value = parseInt(e.target.value);
        this.setState({
            allergens_id: isChecked
                ? this.state.allergens_id.concat(value)
                : this.state.allergens_id.filter(allergen => allergen !== value)
        })
    }

    handleProfileSave() {
        const { allergens_id } = this.state;
        const { token,
            updateAllergens,
            onClose,
            handleMandatorySave } = this.props,
            dataToSend = {
                allergens_id
            };
        updateAllergens(dataToSend,
            token)
            .then(val => {
                if (val) {
                    if (handleMandatorySave) {
                        handleMandatorySave(allergens_id);
                    }
                    onClose();
                }
            })
    }


    componentWillUnmount() {
        if (updateAllergensCancelToken) updateAllergensCancelToken();
    }

    render() {
        const { allergens,
            onClose } = this.props;
        return (<div className="profile-completion-modal__content">
            <div className="profile-completion-modal__header">
                <img src={AllergenIcon} alt="job-profile" className="profile-completion-modal__header__icon" />
                <p className="profile-completion-modal__header__title">{texts.title}</p>
                <p className="profile-completion-modal__header__helper">{texts.helper}</p>
            </div>
            <div className="profile-completion-modal__body">
                {
                    allergens
                        && allergens.fetching
                        ? (null)
                        : allergens
                        && allergens.all
                        && !!allergens.all.length
                        && (<ul className="profile-completion-modal__pill-input-list">
                            {
                                allergens.all.map((allergen, allergenIndex) => {
                                    return (
                                        <li className="profile-completion-modal__pill-input-list__item" key={`allergens-${allergenIndex}-key`}>
                                            <label className="profile-pill-label" htmlFor={`allergens-${allergenIndex}`}>
                                                <input type="checkbox"
                                                    className="profile-pill-label__input"
                                                    onChange={this.handleAllergensSelect}
                                                    name="allergens_id[]"
                                                    id={`allergens-${allergenIndex}`}
                                                    checked={(this.state.allergens_id.includes(allergen.id))}
                                                    value={allergen.id} />
                                                <div className="profile-pill-label__allergens-list">
                                                    <img src={allergen.image}
                                                        className="profile-pill-label__specialisation-image profile-pill-label__specialisation-image--allergens"
                                                        alt="occupation"
                                                    />
                                                    <p className="profile-pill-label__list-text">{allergen.name}</p>
                                                </div>
                                            </label>
                                        </li>
                                    )
                                })
                            }
                        </ul>)
                }
            </div>
            <div className="profile-completion-btn__btn">
                <button className="profile-completion-btn__btn__cancel-btn"
                    onClick={onClose}>
                    <span>Cancel</span>
                </button>
                <button className={`profile-completion-btn__btn__submit-btn`}
                    onClick={this.handleProfileSave}>
                    <span>Done</span>
                </button>
            </div>
        </div>)
    }
}

function mapStateToProps(state) {
    return {
        token: state.login.token,
        allergens: state.profileReducer.allergens,
        profile: state.user.user.profile
    }
}

const mapDispatchToProps = {
    updateAllergens,
    getAllergens
}

AllergensModal.propTypes = {
    onClose: PropTypes.func,
    handleMandatorySave: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(AllergensModal);
