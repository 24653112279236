import React, { useEffect, useState } from 'react'
import InvitationDetails from '../invitations/InvitationDetailsView'
import { INVITATIONS_STATES, offsetLimits } from '../../utils/helper';
import { genericErrorText } from '../../utils/commonVariables';
import { INVITATIONS_KEYS, invitationGAData } from '../collaborations/prManage/schedule/constants';
import { handleInvitation } from '../collaborations/prManage/schedule/helper';
import { invitationAction } from '../../actions/invitations/update';
import { collabInvitationsList, invitationsList } from '../../actions/invitations/list';

function InvitationList(props) {
    const { token, isFeed, collaboration, profile } = props;
    const [page, setPage] = useState(1);
    const [list, setList] = useState([]);
    const [invitationListInfo, setInvitationListInfo] = useState({
        count: 0,
        fetching: false
    })
    const gaDataLabel = !!isFeed ? INVITATIONS_KEYS.LANDING_PAGE : INVITATIONS_KEYS.TASTING_DETAILS
    const getParams = () => {
        const params = {
            page: page,
            limit: offsetLimits.invitation.landingInvitations,
        }
        if (!!isFeed) {
            params["status"] = INVITATIONS_STATES.PENDING
        }
        return params;
    }
    const handlePage = () => {
        setPage(val => val + 1);
    }
    const handleRemoveCard = (id) => {
        const newList = list.filter(item => item.id !== id);
        setList([...newList]);
        setInvitationListInfo((prevData) => {
            return {
                ...prevData,
                count: prevData.count - 1
            }
        })
    }

    const handleUpdateList = (data, id) => {
        setList((prevData) => {
            return prevData.map((val, index) => {
                if (val.id !== id) return val;
                return {
                    ...val, ["status"]: data.status, ["info"]: !!isFeed ? {} : {
                        ...data.info
                    }
                }
            })
        });
    }
    const handlePendingAction = (id, type) => {
        const data = {
            "accepted": type === "accept" ? 1 : 0
        }
        invitationAction(token, id, data, profile.id).then((res) => {
            if (!!res && !!res.data) {
                handleUpdateList(res.data, id);
                const data = invitationGAData(type === "accept" ? INVITATIONS_KEYS.ACCEPT : INVITATIONS_KEYS.REJECT, INVITATIONS_KEYS.CLICK, gaDataLabel)
                handleInvitation(data);
                if (!!isFeed) {
                    setTimeout(() => {
                        handleRemoveCard(id)
                    }, 300)
                }
            }
        })
    }

    const handleAcceptAcion = () => {
        const data = invitationGAData(INVITATIONS_KEYS.ADD_CALENDAR, INVITATIONS_KEYS.CLICK, gaDataLabel)
        handleInvitation(data);
    }

    const handleUpdate = (e, id, type) => {
        if (!e) return;
        e.preventDefault();
        e.stopPropagation();
        if (type === "addToCalendar") {
            handleAcceptAcion();
        } else {
            handlePendingAction(id, type);
        }
    }

    const getInvitions = () => {
        const params = getParams();
        setInvitationListInfo((prevData) => {
            return {
                ...prevData,
                fetching: true
            }
        })
        if (!!isFeed) {
            invitationsList(token, params, profile.id).then((res) => {
                if (!!res && !!res.data) {
                    setList(
                        params.page === 1
                            ? [...(res.data.invitations && res.data.invitations)]
                            : [...list, ...(res.data.invitations && res.data.invitations)]
                    );
                    setInvitationListInfo((prevData) => {
                        return {
                            ...prevData,
                            count: res.data.count,
                            fetching: false
                        }
                    })
                } else {
                    alert(genericErrorText.general);
                }
            })
        } else {
            collabInvitationsList(token, collaboration.id, profile.id).then((res) => {
                if (!!res && !!res.data) {
                    setList(
                        params.page === 1
                            ? [...(res.data.invitations && res.data.invitations)]
                            : [...list, ...(res.data.invitations && res.data.invitations)]
                    );
                    setInvitationListInfo((prevData) => {
                        return {
                            ...prevData,
                            count: res.data.count
                        }
                    })
                } else {
                    alert(genericErrorText.general);
                }
            })
        }
    }
    useEffect(() => {
        if (profile) {
            getInvitions();
        }
    }, [page])
    return (
        <>
            {list && list.length >= 1 && <InvitationDetails data={list} isFeed={!!isFeed} collaboration={collaboration} count={invitationListInfo.count} handlePage={handlePage} handleUpdate={handleUpdate} handleUpdateList={handleUpdateList} />}
        </>

    )
}

export default InvitationList