import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { getLimitedFeed, getLimitedFeedCancelToken } from '../../actions/landingPage';
import { feedPostTypes } from '../../utils/commonVariables';
import { gaEvents } from '../../utils/events';
import CommonAd from '../../containers/loggedIn/Feed/cards/ads/CommonAd';
import SharedCard from '../../containers/loggedIn/Feed/cards/SharedCard';
import CommonCard from '../../containers/loggedIn/Feed/cards/Common';
import CarouselHeader from './Carousel/Header';

const FromFeed = (props) => {
  const {
    token,
    data,
    user: {
      user: { profile },
    },
    comments,
    getLimitedFeed,
    getLimitedFeedCancelToken,
    feed,
  } = props;
  data.model_name = 'feed';

  const totalCount = get(data, 'total_count', 5);
  const [feedData, setFeedData] = useState([]);

  useEffect(() => {
    getLimitedFeed(token);

    return () => {
      if (getLimitedFeedCancelToken) getLimitedFeedCancelToken();
    };
  }, []);

  useEffect(() => {
    const data = get(feed, 'data', []);
    const feedData = data.length > totalCount ? data.slice(0, totalCount) : data;
    setFeedData(feedData);
  }, [feed]);

  const getComponent = (feedData) => {
    if (
      !feedData ||
      (feedData.shared && !feedData.sharedBy) ||
      (!feedData.shared && feedData.sharedBy)
    ) {
      return null;
    }
    const { type } = feedData;
    const profileId = profile ? profile.id : false;
    const isSuggestion = type === 'suggestion';
    const isAd = type === 'advertisement';
    const isFeedCard = type === 'feedCard';
    if (
      !feedData[type] ||
      (!feedData.profile &&
        !feedData.company &&
        !feedData.product &&
        !isAd &&
        !isFeedCard &&
        !isSuggestion) ||
      !feedData
    ) {
      return null;
    }
    // Ad engine card
    if (isAd) {
      return <CommonAd comments={comments} cardData={feedData} showOptionsIcon={false} />;
    }
    // Shared card
    if (feedData.shared) {
      const commentShareObj = `${type}-${feedData[type].id}-share-${feedData.shared.id}`,
        commentsData = comments.byPostId[commentShareObj] || null,
        showOptionsIcon = feedData.shared.profile_id === profileId; // only to shared user
      return (
        <SharedCard
          showOptionsIcon={showOptionsIcon}
          showFlagOptions={true}
          commentsData={commentsData}
          cardData={feedData}
        />
      );
    }
    const commentsData = comments.byPostId[`${type}-${feedData[type].id}`] || null,
      showOptionsIcon = feedData.company
        ? feedData.meta
          ? feedData.meta.isAdmin
          : false
        : feedData.profile
        ? feedData.profile.id === profileId
        : false;
    return (
      <CommonCard
        type={type}
        showFlagOptions={true}
        showOptionsIcon={showOptionsIcon}
        commentsData={commentsData}
        cardData={feedData}
      />
    );
  };
  return (
    <div className="home__card">
      <CarouselHeader data={data} gaFn={() => gaEvents.landingpage.fromYourFeed('See More')} />
      <div className="home__card__content">
        <ul id="feed-list" className="feed-list">
          {feedData.map((feedData, feedDataIndex) => {
            const secondaryClass =
              !feedData[feedData.type] ||
              feedData.type === 'suggestion' ||
              feedData.type === 'feedCard' ||
              (feedData.type === feedPostTypes.advertisement &&
                feedData[feedPostTypes.advertisement] &&
                (feedData[feedPostTypes.advertisement].type === 'image' ||
                  (feedData.meta && !feedData.meta.count)));

            return (
              <li
                className={`feed-list__item ${secondaryClass ? 'feed-list__item--secondary' : ''}`}
                key={`feed-card-${feedDataIndex}`}
                onClick={() => gaEvents.landingpage.fromYourFeed(get(feedData, 'type'))}
              >
                {getComponent(feedData)}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    token: state.login.token,
    // feed: state.feedReducer.feed,
    comments: state.feedReducer.comments,
    user: state.user,
    feed: state.landingPageReducer.fromFeed,
  };
}

const mapDispatchToProps = {
  getLimitedFeed,
  getLimitedFeedCancelToken,
};

FromFeed.propTypes = {
  data: PropTypes.object,
  comments: PropTypes.object,
  user: PropTypes.object,
  token: PropTypes.string,
  getLimitedFeed: PropTypes.func,
  getLimitedFeedCancelToken: PropTypes.func,
  feed: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(FromFeed);
