import React, { useState, useEffect } from 'react';
import CuisinesCard from './CuisinesCard';
// import FollowPeopleCard from './FollowPeopleCard';
import SpecialisationsCard from './SpecialisationsCard';
import JobProfileCard from './JobProfileCard';
import LocationCard from './LocationCard';
import EatingHabitsCard from './EatingHabitsCard';
import BirthdayCard from './BirthdayCard';
// import FbConnectCard from './FbConnectCard';
import TaglineCard from './TaglineCard';
import AllergensCard from './AllergensCard';
import { shouldShowCollaboration } from '../../../../utils/profileHelper';

const availableCards = {
    cuisines: CuisinesCard,
    profile_specializations: SpecialisationsCard,
    profile_occupations: JobProfileCard,
    city: LocationCard,
    foodie_type_id: EatingHabitsCard,
    dob: BirthdayCard,
    tagline: TaglineCard,
    allergens: AllergensCard,
    // FbConnectCard: FbConnectCard,
    // FollowPeopleCard: FollowPeopleCard,
    // verified_email: undefined,
};

function CommonProfileCompletionCard(props) {
    // return (<>
    //     <CuisinesCard {...props} />
    //     <SpecialisationsCard {...props} />
    //     <JobProfileCard {...props} />
    //     <LocationCard {...props} />
    //     <EatingHabitsCard {...props} />
    //     <BirthdayCard {...props} />
    //     <TaglineCard {...props} />
    //     <AllergensCard {...props} />
    // </>)
    const [selectedCardName, setSelectedCardName] = useState(null),
        // [percentComplete, setCompletePercent] = useState(0),
        handledFields = ['cuisines', 'profile_specializations', 'profile_occupations',
            'city', 'foodie_type_id', 'dob', 'tagline', 'allergens'];
    useEffect(() => {
        const emptyFieldsInProfile = [
            ...props.profile.profileCompletion.mandatory_remaining_field,
            ...props.profile.profileCompletion.optional_remaining_field,
            ...props.profile.profileCompletion.additional_optional_field
        ],
        emptyArrayIntersection = emptyFieldsInProfile.filter(_ => -1 !== handledFields.indexOf(_));
        if (emptyArrayIntersection && emptyArrayIntersection.length) {
            let selectedIndex = Math.floor(Math.random() * emptyArrayIntersection.length);
            setSelectedCardName(emptyArrayIntersection[selectedIndex]);
        }
    }, []);
    // useEffect(() => {
    //     let currentMandatoryFields = props.profile.profileCompletion
    //             && props.profile.profileCompletion.mandatory_remaining_field
    //             ? props.profile.profileCompletion.mandatory_remaining_field
    //             : [],
    //         currentOptionalFields = props.profile.profileCompletion
    //             && props.profile.profileCompletion.optional_remaining_field
    //             ? props.profile.profileCompletion.optional_remaining_field
    //             : [],
    //         cuurrentProfileCompletionPercentage = props.profile.profileCompletion
    //             && props.profile.profileCompletion.complete_percentage
    //             ? props.profile.profileCompletion.complete_percentage
    //             : 0;
    //     setCompletePercent(cuurrentProfileCompletionPercentage);
    //     setMandatoryFields(currentMandatoryFields);
    //     setOptionalFields(currentOptionalFields);
    //     let isFromMandatory = !!(currentMandatoryFields
    //             && currentMandatoryFields.length),
    //         selectedCardIndex= isFromMandatory
    //             ? Math.floor(Math.random() * currentMandatoryFields.length)
    //             : currentOptionalFields
    //             && currentOptionalFields.length
    //             ? Math.floor(Math.random() * currentOptionalFields.length)
    //             : null,
    //         currentSelectedCardName = selectedCardIndex
    //             ? isFromMandatory
    //             ? currentMandatoryFields[selectedCardIndex]
    //             : currentOptionalFields[selectedCardIndex]
    //             : null;
    //     setSelectedCardName(currentSelectedCardName);
    // }, []);
    if (!selectedCardName
        || (selectedCardName && !availableCards[selectedCardName])
        || props.profile.profileCompletion.percentComplete === 100) return null;
    const Component = availableCards[selectedCardName];
    return <Component {...props} />;
}

export default CommonProfileCompletionCard;