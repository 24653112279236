import get from 'lodash/get';
import {
  GET_REVIEWS_REQUEST,
  GET_REVIEWS_SUCCESS,
  GET_REVIEWS_FAILURE,
  RESET_REVIEWS_DETAILS,
} from '../../actions/seeAllPages/constants';

const INITIAL_STATE = {
  page: 1,
  fetching: false,
  hasEnd: false,
  data: [],
};

export default function reviews(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_REVIEWS_REQUEST:
      return {
        ...state,
        fetching: true,
        error: false,
        errorMsg: "",
      };
    case GET_REVIEWS_SUCCESS:
      return {
        ...state,
        fetching: false,
        data:
          state.page === 1
            ? get(action, 'payload.data', [])
            : [...state.data, ...get(action, 'payload.data', [])],
        page: state.page + 1,
        hasEnd: !action.payload.data || (action.payload.data && !action.payload.data.length),
        isFromPreviousData: !!action.payload.isFromPreviousData,
      };
    case GET_REVIEWS_FAILURE:
      return {
        ...state,
        fetching: false,
        error: true,
        errorMsg: action.error,
      };

    case RESET_REVIEWS_DETAILS:
      return INITIAL_STATE;
    default:
      return state;
  }
}
