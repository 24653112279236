import { GET_PROFILE_ALLERGENS_REQUEST,
    GET_PROFILE_ALLERGENS_SUCCESS,
    GET_PROFILE_ALLERGENS_FAILURE } from '../../actions/profile/constants';

const INITIAL_STATE = {
    fetching: false,
    error: false,
    errorMsg: null,
    all: []
}

export default function allergens(state = INITIAL_STATE, action)  {
    switch (action.type) {
        case GET_PROFILE_ALLERGENS_REQUEST:
            return {
                ...state,
                fetching: true,
            }
        case GET_PROFILE_ALLERGENS_SUCCESS:
            return {
                ...state,
                all: action.payload,
                fetching: false,
            }
        case  GET_PROFILE_ALLERGENS_FAILURE:
            return {
                ...state,
                fetching: false,
                error: true,
                errorMsg: action.error
            }
        default:
            return state;
    }
}