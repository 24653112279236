export const GET_REVIEWS_DATA_REQUEST = 'GET_REVIEWS_DATA_REQUEST';
export const GET_REVIEWS_DATA_SUCCESS = 'GET_REVIEWS_DATA_SUCCESS';
export const GET_REVIEWS_DATA_FAILURE = 'GET_REVIEWS_DATA_FAILURE';

export const RESET_REVIEWS_DATA = 'RESET_REVIEWS_DATA';

export const GET_REVIEWS_COLLECTION_REQUEST = 'GET_REVIEWS_COLLECTION_REQUEST';
export const GET_REVIEWS_COLLECTION_SUCCESS = 'GET_REVIEWS_COLLECTION_SUCCESS';
export const GET_REVIEWS_COLLECTION_FAILURE = 'GET_REVIEWS_COLLECTION_FAILURE';

export const GET_REVIEWS_COLLECTION_ELEMENTS_REQUEST = 'GET_REVIEWS_COLLECTION_ELEMENTS_REQUEST';
export const GET_REVIEWS_COLLECTION_ELEMENTS_SUCCESS = 'GET_REVIEWS_COLLECTION_ELEMENTS_SUCCESS';
export const GET_REVIEWS_COLLECTION_ELEMENTS_FAILURE = 'GET_REVIEWS_COLLECTION_ELEMENTS_FAILURE';

export const RESET_REVIEWS_COLLECTION = 'RESET_REVIEWS_COLLECTION';