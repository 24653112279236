import {
    GET_FOLLOWERS_LIST_REQUEST,
    GET_FOLLOWERS_LIST_SUCCESS,
    GET_FOLLOWERS_LIST_FAILURE
} from '../profile/constants';
import {
    FOLLOW_COMPANY_REQUEST,
    FOLLOW_COMPANY_SUCCESS,
    FOLLOW_COMPANY_FAILURE,
    UNFOLLOW_COMPANY_REQUEST,
    UNFOLLOW_COMPANY_SUCCESS,
    UNFOLLOW_COMPANY_FAILURE
} from '../company/constants';
import Axios from 'axios';
import {
    onRequest,
    onSuccess,
    onError,
    getResponseData
} from '../../utils/helper';
import { getBaseUrl, companyUrls } from '../../utils/links';
let CancelToken = Axios.CancelToken;

// get company followers list
export let getCompanyFollowersCancelToken = undefined;
export const getCompanyFollowers = (profileId,
    companyId,
    params,
    token) => {
    return dispatch => {
        if (getCompanyFollowersCancelToken) getCompanyFollowersCancelToken();
        dispatch(onRequest(GET_FOLLOWERS_LIST_REQUEST));
        let url = `${getBaseUrl()}${companyUrls.followers(profileId, companyId)}`,
            options = {
                url,
                params,
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                cancelToken: new CancelToken((c) => getCompanyFollowersCancelToken = c)
            };
        return Axios(options)
            .then(response => {
                let successData = getResponseData(response);
                if (successData) {
                    dispatch(onSuccess(GET_FOLLOWERS_LIST_SUCCESS, {
                        data: successData,
                        type: 'followers'
                    }));
                } else {
                    dispatch(onError(GET_FOLLOWERS_LIST_FAILURE, response.data.errors, { type: 'followers' }));
                }
                return successData;
            })
            .catch(error => {
                dispatch(onError(GET_FOLLOWERS_LIST_FAILURE, error));
                return error;
            })
    }
}

// action to follow a user
export let followCompanyCancelToken = undefined;
export const followCompany = (profileId,
    companyId,
    token,
    isCurrentUser = false) => {
    return dispatch => {
        if (followCompanyCancelToken) followCompanyCancelToken();
        dispatch(onRequest(FOLLOW_COMPANY_REQUEST));
        let url = `${getBaseUrl()}${companyUrls.follow(profileId, companyId)}`,
            options = {
                url,
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                cancelToken: new CancelToken((c) => followCompanyCancelToken = c)
            };
        return Axios(options)
            .then(response => {
                let successData = getResponseData(response);
                if (response) {
                    dispatch(onSuccess(FOLLOW_COMPANY_SUCCESS, {
                        ...successData,
                        companyId,
                        profileId,
                        isCurrentUser
                    }))
                } else {
                    dispatch(onError(FOLLOW_COMPANY_FAILURE, response.data.errors));
                }
                return successData
            })
            .catch(error => {
                dispatch(onError(FOLLOW_COMPANY_FAILURE, error));
                return false;
            })
    }
}

// action to unfollow user
export let unfollowCompanyCancelToken = undefined;
export const unfollowCompany = (profileId,
    companyId,
    token,
    isCurrentUser = false) => {
    return dispatch => {
        if (unfollowCompanyCancelToken) unfollowCompanyCancelToken();
        dispatch(onRequest(UNFOLLOW_COMPANY_REQUEST));
        let url = `${getBaseUrl()}${companyUrls.unfollow(profileId, companyId)}`,
            options = {
                url,
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                cancelToken: new CancelToken((c) => unfollowCompanyCancelToken = c)
            };
        return Axios(options)
            .then(response => {
                let successData = getResponseData(response);
                if (response) {
                    dispatch(onSuccess(UNFOLLOW_COMPANY_SUCCESS, {
                        ...successData,
                        companyId,
                        profileId,
                        isCurrentUser
                    }))
                } else {
                    dispatch(onError(UNFOLLOW_COMPANY_FAILURE, response.data.errors));
                }
                return successData
            })
            .catch(error => {
                dispatch(onError(UNFOLLOW_COMPANY_FAILURE, error));
                return false;
            })
    }
}