const INITIAL_STATE = {
    members: [
        {
            name: "Jaspal Singh",
            designation: "Co-founder & CEO",
            image_color: "/images/icons/team/jaspal_color.png",
            image_bw: "/images/icons/team/jaspal_color.png",
            description: "",
            social:{
                facebook: "http://facebook.com/",
                instagram: "http://instagram.com/",
                linkedin: "http://linkedin.com/"
            },
            profile_id:"10"
        },
        {
            name: "Arun Tangri",
            designation: "Co-founder & COO",
            image_color: "/images/icons/team/arun_color.png",
            image_bw: "/images/icons/team/arun_color.png",
            description: "",
            social:{
                facebook: "http://facebook.com/",
                instagram: "http://instagram.com/",
                linkedin: "http://linkedin.com/"
            },
            profile_id:"1"
        },
        {
            name: "Varun Tangri",
            designation: "Co-founder & CTO",
            image_color: "/images/icons/team/varun_color.png",
            image_bw: "/images/icons/team/varun_color.png",
            description: "",
            social:{
                facebook: "http://facebook.com/",
                instagram: "http://instagram.com/",
                linkedin: "http://linkedin.com/"
            },
            profile_id:"2"
        },
        {
            name: "Rakesh Banga",
            designation: "Partner - Strategic Alliance",
            image_color: "/images/icons/team/rakesh_color.png",
            image_bw: "/images/icons/team/rakesh_color.png",
            description: "",
            social:{
                facebook: "http://facebook.com/",
                instagram: "http://instagram.com/",
                linkedin: "http://linkedin.com/"
            },
            profile_id:"165"
        },
        {
            name: "Bill Marchetti",
            designation: "Sr. Adviser - Knowledge Management",
            image_color: "/images/icons/team/bill_color.png",
            image_bw: "/images/icons/team/bill_color.png",
            description: "",
            social:{
                facebook: "http://facebook.com/",
                instagram: "http://instagram.com/",
                linkedin: "http://linkedin.com/"
            },
            profile_id:"44"
        },
        {
            name: "Arun Chopra",
            designation: "Sr. Adviser - Knowledge Management",
            image_color: "/images/icons/team/arun_chopra_color.png",
            image_bw: "/images/icons/team/arun_chopra_color.png",
            description: "",
            social:{
                facebook: "http://facebook.com/",
                instagram: "http://instagram.com/",
                linkedin: "http://linkedin.com/"
            },
            profile_id:"32"
        },
        {
            name: "Nitin Kacharia",
            designation: "User Experience Designer",
            image_color: "/images/icons/team/nitin_color.png",
            image_bw: "/images/icons/team/nitin_color.png",
            description: "",
            social:{
                facebook: "http://facebook.com/",
                instagram: "http://instagram.com/",
                linkedin: "http://linkedin.com/"
            },
            profile_id:"4"
        },
        {
            name: "Aashita Khurana",
            designation: "Software Engineer",
            image_color: "/images/icons/team/aashita_color.jpg",
            image_bw: "/images/icons/team/aashita_color.jpg",
            description: "",
            social:{
                facebook: "http://facebook.com/",
                instagram: "http://instagram.com/",
                linkedin: "http://linkedin.com/"
            },
            profile_id:"12"
        },
        {
            name: "Ashima Singla",
            designation: "Web Developer",
            image_color: "/images/icons/team/aashima_color.png",
            image_bw: "/images/icons/team/aashima_color.png",
            description: "",
            social:{
                facebook: "http://facebook.com/",
                instagram: "http://instagram.com/",
                linkedin: "http://linkedin.com/"
            },
            profile_id:"6"
        },
        {
            name: "Ashok Manda",
            designation: "Web Developer",
            image_color: "/images/icons/team/ashok_color.png",
            image_bw: "/images/icons/team/ashok_color.png",
            description: "",
            social:{
                facebook: "http://facebook.com/",
                instagram: "http://instagram.com/",
                linkedin: "http://linkedin.com/"
            },
            profile_id:"7"
        },
        {
            name: "Upandra Rai",
            designation: "UX/UI Desinger",
            image_color: "/images/icons/team/upendra_color.png",
            image_bw: "/images/icons/team/upendra_color.png",
            description: "",
            social:{
                facebook: "http://facebook.com/",
                instagram: "http://instagram.com/",
                linkedin: "http://linkedin.com/"
            },
            profile_id:"8"
        },
        {
            name: "Nehmat Kaur",
            designation: "Head, Marketing & PR",
            image_color: "/images/icons/team/nehmat.jpeg",
            image_bw: "/images/icons/team/nehmat.jpeg",
            description: "",
            social:{
                facebook: "http://facebook.com/",
                instagram: "http://instagram.com/",
                linkedin: "http://linkedin.com/"
            },
            profile_id:"11"
        },
        {
            name: "Sonika Kamra",
            designation: "Ambassador of Buzz",
            image_color: "/images/icons/team/sonica_color.png",
            image_bw: "/images/icons/team/sonica_color.png",
            description: "",
            social:{
                facebook: "http://facebook.com/",
                instagram: "http://instagram.com/",
                linkedin: "http://linkedin.com/"
            },
            profile_id:"14"
        },
        {
            name: "Ankita Aggarwal",
            designation: "Digtal marketing Associate",
            image_color: "/images/icons/team/ankita_color.png",
            image_bw: "/images/icons/team/ankita_color.png",
            description: "",
            social:{
                facebook: "http://facebook.com/",
                instagram: "http://instagram.com/",
                linkedin: "http://linkedin.com/"
            },
            profile_id:"15"
        },
        {
            name: "Gaurav Khurana",
            designation: "Finance & Accounting",
            image_color: "/images/icons/team/gaurav_color.png",
            image_bw: "/images/icons/team/gaurav_color.png",
            description: "",
            social:{
                facebook: "http://facebook.com/",
                instagram: "http://instagram.com/",
                linkedin: "http://linkedin.com/"
            },
             profile_id:"16"
        },
        {
            name: "Tanvi Sharma",
            designation: "Project Meanie",
            image_color: "/images/icons/team/Tanvi.jpg",
            image_bw: "/images/icons/team/Tanvi.jpg",
            description: "",
            social:{
                facebook: "http://facebook.com/",
                instagram: "http://instagram.com/",
                linkedin: "http://linkedin.com/"
            },
            profile_id:"13"

        },
        {
            name: "Nikhil Jha",
            designation: "Full Stack – Web Developer",
            image_color: "/images/icons/team/nikhil-jha.jpg",
            image_bw: "/images/icons/team/nikhil-jha.jpg",
            description: "",
            social:{
                facebook: "http://facebook.com/",
                instagram: "http://instagram.com/",
                linkedin: "http://linkedin.com/"
            },
            profile_id:"19"

        },
        {
            name: "Sandeep Kumar",
            designation: "Software Lieutenant",
            image_color: "/images/icons/team/sandy.jpg",
            image_bw: "/images/icons/team/sandy.jpg",
            description: "",
            social:{
                facebook: "http://facebook.com/",
                instagram: "http://instagram.com/",
                linkedin: "http://linkedin.com/"
            },
            profile_id:"21"
        },
        {
            name: "Himanshu Jain",
            designation: "Lead Developer",
            image_color: "/images/icons/team/Himanshu.jpg",
            image_bw: "/images/icons/team/Himanshu.jpg",
            description: "",
            social:{
                facebook: "http://facebook.com/",
                instagram: "http://instagram.com/",
                linkedin: "http://linkedin.com/"
            },
              profile_id:"17"
        },
        {
            name: "Naman Khator",
            designation: "Full Stack – Web Developer",
            image_color: "/images/icons/team/Naman.jpg",
            image_bw: "/images/icons/team/Naman.jpg",
            description: "",
            social:{
                facebook: "http://facebook.com/",
                instagram: "http://instagram.com/",
                linkedin: "http://linkedin.com/"
            },
            profile_id:"18"
        },
         {
            name: "Samarth Gupta",
            designation: "Lead Developer",
            image_color: "/images/icons/team/Samarth.jpg",
            image_bw: "/images/icons/team/Samarth.jpg",
            description: "",
            social:{
                facebook: "http://facebook.com/",
                instagram: "http://instagram.com/",
                linkedin: "http://linkedin.com/"
            },
            profile_id:"20"
        },
        {
            name: "Vivek Singh",
            designation: "Full Stack – Web Developer",
            image_color: "/images/icons/team/Vivek.png",
            image_bw: "/images/icons/team/Vivek.png",
            description: "",
            social:{
                facebook: "http://facebook.com/",
                instagram: "http://instagram.com/",
                linkedin: "http://linkedin.com/"
            },
            profile_id:"161"
        },
        {
            name: "Hasan Ali",
            designation: "Content Associate",
            image_color: "/images/icons/team/hasan.jpg",
            image_bw: "/images/icons/team/hasan.jpg",
            description: "",
            social:{
                facebook: "http://facebook.com/",
                instagram: "http://instagram.com/",
                linkedin: "http://linkedin.com/"
            },
            profile_id:"359"
        }
    ]
}
export default function team (state = INITIAL_STATE, action) {
    switch(action.type) {
        default:
            return state;
    }
}