import { GET_PROFILES_FOR_TAGGING,
    GET_ALL_USERS_FOR_TAGGING } from '../actions/constants';

const INITIAL_STATE = {
    allByTerm: {},
    profilesByTerm: {}
}

export default function tagging(state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_PROFILES_FOR_TAGGING:
            return {
                ...state,
                profilesByTerm: {
                    ...state.profilesByTerm,
                    [action.payload.term]: {
                        data: action.payload.data,
                        fetchTime: Date.parse(new Date()) // For comparison
                    },
                }
            }
        case GET_ALL_USERS_FOR_TAGGING:
            return {
                ...state,
                allByTerm: {
                    ...state.allByTerm,
                    [action.payload.term]: {
                        data: action.payload.data,
                        fetchTime: Date.parse(new Date()) // For comparison
                    }
                }
            }
        default:
            return state;
    }
}