import { combineReducers } from 'redux';
import status from './status'
import logs from './logs';
import manufacturers from './manufacturers';
import distributors from './distributors';
import partners from './partners';
import products from './products';
import product from './product';
import leads from './leads';
import lead from './lead';
import sideBar from './sidebarMenu';
import profile from './profile';
import overview from './overview';
import activeProducts from './activeProducts';

const businessReducer = combineReducers({
    status,
    logs,
    manufacturers,
    distributors,
    partners,
    products,
    product,
    leads,
    lead,
    sideBar,
    profile,
    overview,
    activeProducts
});

export default businessReducer;