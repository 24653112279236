import React, { Component } from "react";
import PropTypes from "prop-types";
import UserBar from "../common/UserBar";
import MetaBar from "../common/MetaBar";
import CompanyBar from "../common/CompanyBar";
import SmallPreview from "../common/SmallPreview";
import PostComments from "../common/PostComments";
import TaggedText from "../common/TaggedText";
import {
  makePlaylistForJWPlayer,
  isYoutubeVideo,
  makePlaylistForReactPlayer,
} from "../../../../utils/helper";
import ReactJWPlayer from "react-jw-player";
import YoutubeEmbed from "../common/YoutubeEmbed";
import PlayIcon from "../../../../assets/images/posts/icons_play.svg";
import CommonPlayer from "../../../../components/CommonPlayer";
class PostCard extends Component {
  componentDidMount() {
    window.onbeforeunload = null;
  }

  render() {
    const {
      cardData,
      commentsData,
      hideMetaBar,
      showOptionsIcon,
      isAd,
      showFlagOptions,
      publicClickAction,
      isShared,
      isDetailsPage,
      isPublic,
    } = this.props,
      { shoutout, shared } = cardData,
      preview = shoutout && shoutout.preview ? shoutout.preview : false,
      postText =
        shoutout && shoutout.content
          ? shoutout.content.text
            ? shoutout.content.text
            : shoutout.content
          : "",
      postProfiles =
        shoutout && shoutout.content && shoutout.content.profiles
          ? shoutout.content.profiles
          : [],
      postId = shoutout ? shoutout.id : null,
      isMediaJson =
        shoutout && shoutout.media_json ? shoutout.media_json : false,
      youtubeId = postText ? isYoutubeVideo(postText) : null,
      createdAt = shoutout && shoutout.created_at ? shoutout.created_at : null,
      jwPlayerId = isShared
        ? `shared-${cardData.shared.id}-shoutout-${shoutout.id}`
        : `shoutout-${shoutout.id}`;
    return (
      <div className="feed-card">
        {cardData && cardData.profile ? (
          <UserBar
            showFlagOptions={showFlagOptions}
            postType="shoutout"
            postId={postId}
            cardData={cardData}
            isAd={isAd}
            isDetailsPage={isDetailsPage}
            showOptionsIcon={showOptionsIcon}
            createdAt={createdAt}
            hideMetaBar={hideMetaBar}
            isPublic={isPublic}
            publicClickAction={publicClickAction}
            profile={cardData.profile}
          />
        ) : cardData && cardData.company ? (
          <CompanyBar
            postType="shoutout"
            postId={postId}
            showFlagOptions={showFlagOptions}
            profileId={
              cardData.company && cardData.company.profile_id
                ? cardData.company.profile_id
                : null
            }
            cardData={cardData}
            isAd={isAd}
            publicClickAction={publicClickAction}
            isDetailsPage={isDetailsPage}
            showOptionsIcon={showOptionsIcon}
            createdAt={createdAt}
            isPublic={isPublic}
            hideMetaBar={hideMetaBar}
            company={cardData.company}
          />
        ) : null}
        <div className="post-details">
          <TaggedText
            isPublic={isPublic}
            hashtagEventLabel="Shoutout"
            isLinkified={true}
            isOpen={!!isDetailsPage}
            className="post-details__text"
            profiles={postProfiles}
            text={postText}
          />
          {preview && !youtubeId && <SmallPreview preview={preview} />}
          {isMediaJson && (
            <div className="post-details__video-container">
              {isMediaJson && !hideMetaBar ? (
                <CommonPlayer
                  playerId={`feed-card-${postId}-${isShared ? `shared-card-${shared.id}` : ''}`}
                  videosMeta={[shoutout] || []}
                  withoverlay={false}
                />
              ) : (
                <img
                  className="post-details__video-container__icon"
                  src={isMediaJson.thumbnail}
                  alt="video thum"
                />
              )}
            </div>
          )}
          {youtubeId && !isMediaJson && <YoutubeEmbed youtubeId={youtubeId} />}
          {isPublic && shoutout && shoutout.thumbnail && (
            <div className="post-details__video-container">
              <img
                className="post-details__video-container__icon"
                src={shoutout.thumbnail}
                alt="video thum"
              />
              <img
                className="post-details__video-container__play-btn"
                src={PlayIcon}
                alt="faux play"
              />
            </div>
          )}
        </div>
        {!isShared && !hideMetaBar && cardData.meta && (
          <>
            <MetaBar
              isPublic={isPublic}
              postType="shoutout"
              postId={postId}
              isAd={isAd}
              likeActionProcessing={cardData.likeActionProcessing}
              fetchingComments={commentsData && commentsData.fetching}
              postToShare={cardData}
              publicClickAction={publicClickAction}
              meta={cardData.meta}
            />
          </>
        )}
        {commentsData && !isShared && !hideMetaBar && (
          // && commentsData.showComments
          <PostComments
            showOptionsIcon={showOptionsIcon}
            postType="shoutout"
            isAd={isAd}
            commentsData={commentsData}
            postId={postId}
          />
        )}
      </div>
    );
  }
}

PostCard.propTypes = {
  cardData: PropTypes.object.isRequired,
  commentsData: PropTypes.object,
  isShared: PropTypes.bool,
  showOptionsIcon: PropTypes.bool,
  hideMetaBar: PropTypes.bool,
  isDetailsPage: PropTypes.bool,
  isPublic: PropTypes.bool,
  publicClickAction: PropTypes.func,
  isAd: PropTypes.bool,
  showFlagOptions: PropTypes.bool,
};

export default PostCard;
