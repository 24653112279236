import {
  GET_GRAPH_FILTERS_SUCCESS,
  GET_GRAPH_FILTERS_FAILURE,
  UPDATE_GRAPH_FILTERS_SELECTED_FILTERS,
  RESET_GRAPH_FILTERS_SELECTED_FILTERS,
} from '../../../../actions/collaborations/prManage/constants';

const INITIAL_STATE = {
  data: null,
  error: false,
  selectedFilters: {},
};

export default function graphFilters(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_GRAPH_FILTERS_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };
    case GET_GRAPH_FILTERS_FAILURE:
      return {
        ...state,
        ...(!action.error.__CANCEL__ && {
          error: true,
          errorMsg: action.error,
        }),
      };
    case UPDATE_GRAPH_FILTERS_SELECTED_FILTERS:
      return {
        ...state,
        selectedFilters: action.payload,
      };
    case RESET_GRAPH_FILTERS_SELECTED_FILTERS:
      return {
        ...state,
        selectedFilters: INITIAL_STATE.selectedFilters,
      };
    default:
      return state;
  }
}
