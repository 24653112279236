import React from "react";
import EmptyIcon from '../../assets/images/404/icon_something_went_wrong.svg';
import RetryIcon from '../../assets/images/feed/renew-feed.svg';


const RetryButton = ({ handleClick, isPaginated }) => {
    return (
        <button onClick={() => handleClick()} className="action cursor-pointer">
            {isPaginated ? <img src={RetryIcon} alt="retry" /> : null}
            Try again
        </button>
    )
}

const RetryFetch = ({ handleClick, containerClassName, title, subtitle, isPaginated }) => {
    const titleToShow = title || "Something went wrong. Please try again!";
    const subtitleToShow = subtitle || "We are facing a technical glitch at the moment."
    return (
        <div className={`retry-container ${!isPaginated ? 'feed-list__item' : ''} ${containerClassName}`}>
            {!isPaginated ? <>
                <div className="icon__container">
                    <img className="icon" src={EmptyIcon} alt="empty-state" />
                </div>
                <h5 className="title">{titleToShow}</h5>
                <p className="subtitle">{subtitleToShow}</p>
            </> : null}
            {handleClick ? <RetryButton handleClick={handleClick} isPaginated={isPaginated} /> : null}
        </div>
    )
};

export default RetryFetch;