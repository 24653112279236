export const getShareLink = (postId, postType, shareId) => {
  const hostName = window.location.hostname,
    host =
      hostName.indexOf('www.tagtaste') > -1
        ? `${window.location.protocol}//${window.location.hostname}${
            window.location.port ? ':' + window.location.port : ''
          }`
        : 'https://dev.tagtaste.com';
  if (shareId) return `${host}/shared/${shareId}/${postType}/${postId}`;
  switch (postType) {
    case 'photo':
      return `${host}/photo/${postId}`;
    case 'shoutout':
      return `${host}/shoutout/${postId}`;
    case 'collaborate':
      return `${host}/collaborations/${postId}`;
    case 'profile':
      return `${host}/@${postId}`;
    case 'company':
      return `${host}/companies/${postId}`;
    case 'product':
      return `${host}/reviews/products/${postId}`;
    case 'polling':
      return `${host}/polling/${postId}`;
    case 'feedCard':
      return `${host}/feed/card/${postId}`;
    case 'surveys':
      return `${host}/surveys/${postId}`;
    default:
      return host;
  }
};

// sharing via facebook
export const shareViaFB = (postId, postType, sharedId, url = null) => {
  if ((!postId || !postType) && !url) return;
  if (window.FB) {
    let urlToUse = url || getShareLink(postId, postType, sharedId);
    window.FB.ui({
      method: 'share',
      href: urlToUse,
    });
  }
};

// sharing via twitter
export const shareViaTwitter = (postId, postType, title, sharedId, url = null) => {
  if ((!postId || !postType) && !url) return;
  const urlToUse = url || getShareLink(postId, postType, sharedId);
  window.open(
    `https://twitter.com/intent/tweet?text=${
      title || ''
    }&url=${urlToUse}&hashtags=tagtaste, food&via=tagtaste`,
    'shareTwitter',
    'width=650,height=300',
  );
};

// sharing via linkedin
export const shareViaLinkedIn = (postId, postType, sharedId, url = null) => {
  if ((!postId || !postType) && !url) return;
  const urlTouse = url || getShareLink(postId, postType, sharedId);
  window.open(
    `https://www.linkedin.com/shareArticle?mini=true&url=${urlTouse}&source=TagTaste`,
    'shareLinkedIn',
    'width=650,height=650',
  );
};
