import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MentionsInput, Mention } from 'react-mentions';
import { getProfilesForTagging } from '../actions/tagging';
import DefaultAvatar from '../assets/images/default/avatar.jpg';
import { getLinkPreview, emptyAPreview } from '../actions/feed/common';
import * as linkify from 'linkifyjs';
import UserName from './profile/UserName';
import { decodeString } from '../utils/helper';
import Avatar from './user/Avatar';

class TaggableTextarea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      isAddingMention: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.fetchUsers = this.fetchUsers.bind(this);
    this.renderSuggestion = this.renderSuggestion.bind(this);
    this.focusTextInput = this.focusTextInput.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    // Creating Input ref
    this.textInput = React.createRef();
  }

  focusTextInput() {
    this.textInput.current.focus();
  }

  componentDidMount() {
    const { autoFocus } = this.props;
    if (autoFocus) {
      this.focusTextInput();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.focusNow && !prevProps.focusNow) {
      this.focusTextInput();
    }
  }

  fetchUsers(query, callback) {
    if (!query) return;
    const { tagging, isEveryone } = this.props,
      { allByTerm, profilesByTerm } = tagging;
    let taggingObj = isEveryone ? allByTerm[query] : profilesByTerm[query];
    if (
      taggingObj &&
      taggingObj.fetchTime &&
      (Date.parse(new Date()) - taggingObj.fetchTime) / (1000 * 60) <= 5
    ) {
      let profiles = taggingObj.data.map((user) => ({
        display: decodeString(user.name),
        imageMeta: user.image_meta,
        imageUrl: user.imageUrl,
        verified: !!user.verified,
        id: user.id,
        is_tasting_expert: user.is_tasting_expert,
      }));
      callback(profiles);
      return;
    }
    const { getProfilesForTagging, token } = this.props;
    getProfilesForTagging(query, token, isEveryone).then((response) => {
      if (!response) return callback([]);
      let profiles = response.map((user) => ({
        display: decodeString(user.name),
        id: user.id,
        verified: !!user.verified,
        imageMeta: user.image_meta,
        imageUrl: user.imageUrl,
        is_tasting_expert: user.is_tasting_expert,
      }));
      callback(profiles);
      return;
    });
  }

  handleSubmit(e) {
    const { submitOnEnter } = this.props;
    if (!submitOnEnter) return;
    if (this.state.isAddingMention) {
      this.setState({
        isAddingMention: false,
      });
      return;
    }
    if (
      ((e.key && e.key === 'Enter') || (e.which && e.which === 13)) &&
      !e.shiftKey &&
      e.target.value &&
      e.target.value.trim().length
    ) {
      submitOnEnter();
    }
  }

  renderSuggestion(entry) {
    const imageURL = entry.imageMeta ? JSON.parse(entry.imageMeta).original_photo : entry.imageUrl;
    const name = entry.display;
    return (
      <>
        <Avatar
          alt={name}
          src={imageURL ? imageURL : DefaultAvatar}
          className="tag-box__suggestions__item__icon"
          profile={entry}
          smallSuperTaster
        />
        <div>
          <UserName name={name} isVerified={entry.verified || false} />
        </div>
      </>
    );
  }

  handleChange(e) {
    const value = e.target.value,
      { onChange, shouldGeneratePreview, previewType, token, getLinkPreview, hasGeneratedPreview } =
        this.props;
    onChange(value);
    if (
      shouldGeneratePreview &&
      // && (!handleYoutubeCheck || (handleYoutubeCheck && !isYoutubeVideo(value)))
      !hasGeneratedPreview &&
      previewType &&
      value &&
      value.trim().length
    ) {
      let linksInText = linkify.find(value, 'url');
      if (linksInText && linksInText.length) {
        getLinkPreview(linksInText[0].href, token, previewType);
      }
    }
  }

  render() {
    const {
      value,
      className,
      maxLength,
      isDisabled,
      placeholder,
      user: {
        user: { profile },
      },
    } = this.props;
    return (
      <MentionsInput
        maxLength={maxLength || 2000}
        className={`tag-box ${className ? className : ''}`}
        value={value}
        onKeyUp={this.handleSubmit}
        disabled={!!isDisabled}
        allowSpaceInQuery={true}
        inputRef={this.textInput}
        placeholder={placeholder || 'Add a comment...'}
        onBlur={(e, clickedSuggestion) => {
          if (clickedSuggestion) {
            this.setState((prevState) => {
              return {
                ...prevState,
                isAddingMention: false,
              };
            });
          }
        }}
        onChange={this.handleChange}
      >
        <Mention
          className="tag-box__item"
          trigger="@"
          onAdd={() => {
            this.setState((prevState) => {
              return {
                ...prevState,
                isAddingMention: true,
              };
            });
          }}
          renderSuggestion={this.renderSuggestion}
          appendSpaceOnAdd={true}
          data={this.fetchUsers}
        />
      </MentionsInput>
    );
  }
}

function mapStateToProps(state) {
  return {
    token: state.login.token,
    tagging: state.tagging,
    user: state.user,
  };
}

TaggableTextarea.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  minLength: PropTypes.number,
  isEveryone: PropTypes.bool,
  autoFocus: PropTypes.bool,
  shouldGeneratePreview: PropTypes.bool,
  hasGeneratedPreview: PropTypes.bool,
  previewType: PropTypes.string,
  maxLength: PropTypes.number,
  submitOnEnter: PropTypes.func,
  isDisabled: PropTypes.bool,
  placeholder: PropTypes.string,
  focusNow: PropTypes.bool,
};

export default connect(mapStateToProps, { getProfilesForTagging, getLinkPreview, emptyAPreview })(
  TaggableTextarea,
);
