import { combineReducers } from 'redux';
import polls from './polls';
import reviews from './reviews';

const seeAllPageReducer = combineReducers({
  polls,
  reviews,
});

export default seeAllPageReducer;
