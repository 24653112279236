import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { getNotificationsCancelToken,
    getNotifications,
    resetNotifications,
    markAllNotificationsAsRead,
    markAllNotificationsAsReadCancelToken,
    markNotificationSeenCancelToken,
    markNotificationSeen } from '../../../actions/notifications';
import NotificationListItem from './NotificationListItem';
import Loader from '../../../components/misc/Loader';
import { pageEndReached,
    debounce } from '../../../utils/helper';
import SeoHeadMeta from '../../../components/misc/SeoHeadMeta';
import { seoTitles } from '../../../utils/commonVariables';

const texts = {
    blank: `You're up to date.`
}
class Notifications extends Component {
    constructor(props) {
        super(props);
        this.state = {
            readingAllNotifications: false
        }
        // binding scroll function
        this.handleNotificationsScroll = debounce(this.handleNotificationsScroll.bind(this), 1000);
        // binding mark all as read
        this._handleMarkAllAsRead = this._handleMarkAllAsRead.bind(this);
    }

    handleNotificationsScroll() {
        const { token,
            notifications,
            getNotifications } = this.props;
        if (pageEndReached() && !notifications.fetching && !notifications.hasEndReached) {
            getNotifications(notifications.page,
                token);
        }
    }

    componentDidMount() {
        const { token,
            getNotifications,
            notifications,
            markNotificationSeen,
            isPopUp } = this.props;
        if (notifications.page === 1) {
            getNotifications(notifications.page,
                token);
        }
        markNotificationSeen(token);
        // adding scroll event listener
        if (!isPopUp) {
            document.addEventListener('scroll', this.handleNotificationsScroll);
            window.scrollTo(0, 0);
        }
    }

    _handleMarkAllAsRead() {
        if (this.state.readingAllNotifications) return;
        this.setState({
            readingAllNotifications: TextTrackCueList
        })
        const { token,
            markAllNotificationsAsRead } = this.props;
        markAllNotificationsAsRead(token)
        .then(_ => {
            this.setState({
                readingAllNotifications: false
            })
        })
    }

    componentWillUnmount() {
        const { isPopUp } = this.props;
        if (window.location.pathname !== "/notifications") {
            if (markAllNotificationsAsReadCancelToken) markAllNotificationsAsReadCancelToken();
            if (getNotificationsCancelToken) getNotificationsCancelToken();
            if (markNotificationSeenCancelToken) markNotificationSeenCancelToken();
            // this.props.resetNotifications();
        }
        // removing scroll event
        if (!isPopUp) {
            document.removeEventListener('scroll', this.handleNotificationsScroll);
        }
    }

    render() {
        const { notifications: { fetching,
            all,
            error,
            page },
            isPopUp,
            user } = this.props,
            { readingAllNotifications } = this.state,
            notificationsToShow = isPopUp
                ? all
                && !!all.length
                ? all.slice(0, 7)
                : null
                : all,
            isDisabled = (!notificationsToShow
                || (notificationsToShow
                    && !notificationsToShow.length)
                || readingAllNotifications);
        return (<div className={`${isPopUp ? '' : 'container container--with-height'}`}>
            {
                !isPopUp && <SeoHeadMeta title={seoTitles.loggedIn.notifications}>
                    <title>{seoTitles.loggedIn.notifications}</title>
                </SeoHeadMeta>
            }
            <div className="notification">
            {
                !isPopUp
                && (<div className="notification__header">
                    <p className="notification__header__title">Notifications {(user
                        && user.user
                        && user.user.profile
                        && user.user.profile.notificationCount
                        && user.user.profile.notificationCount > 0)
                        ? `• ${user.user.profile.notificationCount} New`
                        : '' }</p>
                    <div className="notification__header__action">
                        <button onClick={() => {
                            if (isDisabled) return;
                            this._handleMarkAllAsRead()
                        }}
                            disabled={isDisabled}
                            className={`notification__header__action__btn ${isDisabled ? 'notification__header__action__btn--disabled' : 'cursor-pointer'}`}>Mark all as Read</button>
                        <span className="notification__header__action__btn notification__header__action__btn--no-hover">&bull;</span>
                        <Link className="notification__header__action__btn" to="/settings">Settings</Link>
                    </div>
                </div>)
            }
            <div className="notification__list__container">
                {
                    fetching
                    && page === 1
                    && (<div className="notification__list__loader">
                        <Loader isCentered={true} />
                    </div>)
                }
                {
                    notificationsToShow
                    && notificationsToShow.length
                    ? (!fetching || (fetching && page > 1))
                    && (<ul className="notification__list">
                        {
                            notificationsToShow.map((notificationItem, notificationIndex) => {
                                return (<li key={`notification-${notificationIndex}`}>
                                    <NotificationListItem notificationItem={notificationItem} />
                                </li>)
                            })
                        }
                    </ul>)
                    : !fetching
                    && !error
                    && <p className="notification__list__loader">{texts.blank}</p>
                }
                {
                    fetching
                    && page > 1
                    && !isPopUp
                    && <Loader withMargin={true}
                        isCentered={true} />
                }
            </div>
        </div>
        </div>)
    };
}

function mapStateToProps(state) {
    return {
        token: state.login.token,
        notifications: state.notifications,
        user: state.user
    }
}

const mapDispatchToProps = {
    getNotifications,
    markAllNotificationsAsRead,
    resetNotifications,
    markNotificationSeen,
}

Notifications.propTypes = {
    isPopUp: PropTypes.bool
}

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);