import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// import STBig from '../../assets/images/profile/view/st-big.svg';
import ST from "../../assets/images/profile/view/st.svg";
import { screenBreakpoints } from "../../utils/commonVariables";
import { debounce } from "../../utils/helper";

const SuperTaster = ({ isSmall, className, updateOnResize }) => {
  const [smallIcon, setSmallIcon] = useState(
    isSmall || window.innerWidth <= screenBreakpoints.mob
  );
  const _handleResize = debounce(() => {
    setSmallIcon(window.innerWidth <= screenBreakpoints.mob);
  }, 1000);

  useEffect(() => {
    if (updateOnResize) {
      window.addEventListener("resize", _handleResize);
    }
    return () => {
      if (updateOnResize) {
        window.removeEventListener("resize", _handleResize);
      }
    };
  }, []);

  return (
    <>
      {/* {smallIcon ? (
        <div className={`profile-view__supertastersmall ${className}`}>
          <img src={ST} alt="super taster" className="profile-view__supertastersmall__image" />
        </div>
      ) : ( */}
      <div className={`profile-view__supertaster ${className}`}>
        <img
          className="profile-view__supertaster__img"
          src={ST}
          alt="super taster"
        />
        <span className="profile-view__supertaster__text">SuperTaster</span>
      </div>
      {/* )} */}
    </>
  );
};

SuperTaster.propTypes = {
  isSmall: PropTypes.bool,
  className: PropTypes.string,
  updateOnResize: PropTypes.bool,
};

export default SuperTaster;
