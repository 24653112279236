import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { changePassword } from '../../../actions/settings/changePassword';
import CloseIcon from '../../../assets/images/common/modals/close.svg';
import { logoutUserLocally } from '../../../actions/login/logout';
import { miscLinks } from '../../../utils/internalLinks';
import EyeOpen from '../../../assets/images/login/eye-view.svg';
import EyeClose from '../../../assets/images/login/eye-close.svg';
import { passwordLength } from '../../../utils/commonVariables';
import { newPasswordLength, passwordRegex } from '../../../utils/helper';

const texts = {
    title: 'Change Password',
    password: 'New Password',
    password_confirmation: 'Confirm Password',
    errorMsg: 'Please enter new password.'
}

class ChangePasswordModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            password_confirmation: '',
            errorMsg: '',
            showPassword: {
                new_password: false,
                confirm_password: false
            }
        }
        this._handleInputChange = this._handleInputChange.bind(this);
        this._handleNewPasswordUpdate = this._handleNewPasswordUpdate.bind(this);
        this.togglePassword = this.togglePassword.bind(this);
        this.handleTogglePasswordVisibility = this.handleTogglePasswordVisibility.bind(this);
    }

    _handleInputChange(e) {
        const { name, value } = e.target
        this.setState({
            [name]: value
        })
    }
    togglePassword(e) {
        if (!e) return;
        const { id } = e.target;
        if (id === this.state.showPassword) {
            this.setState({ showPassword: null })
        } else {
            this.setState({ showPassword: id })
        }
    }

    _handleNewPasswordUpdate(e) {
        e.preventDefault();
        const { password } = this.state;
        const { token, handleSubmit } = this.props;
        let dataToSend = { password }
        changePassword(dataToSend, token)
            .then(val => {
                if (!!val && !!val.data) {
                    handleSubmit(password);
                } else {
                    const { status, display_message } = val.errors;
                    if (!!status) {
                        this.setState({ errorMsg: display_message ? display_message : "Something Went Wrong!" });
                    }
                }
            }).catch((err) => {
                this.setState({ errorMsg: "Something Went Wrong!" });
            })
    }
    handleTogglePasswordVisibility(type) {
        if (type === "password") {
            this.setState((prevState) => ({
                showPassword: {
                    ...prevState.showPassword,
                    new_password: !this.state.showPassword.new_password
                }
            }))
        } else {
            this.setState((prevState) => ({
                showPassword: {
                    ...prevState.showPassword,
                    confirm_password: !this.state.showPassword.confirm_password
                }
            }))
        }
    }
    render() {
        const { onClose, type } = this.props,
            { password,
                password_confirmation,
                disabledSubmit, errorMsg, showPassword: { new_password, confirm_password } } = this.state,
            isConfirmPassword = password !== password_confirmation,
            isMatching = (!password_confirmation || isConfirmPassword),
            isDisabled = (password.length < 6 || !passwordRegex.test(password) || isMatching),
            isConfirmPasswordError = !!password_confirmation && isConfirmPassword,
            isError = !!password && (password.length < newPasswordLength || !passwordRegex.test(password) || isConfirmPasswordError),
            isPasswordLength = !!password && password.length < newPasswordLength,
            isPassowrdRegExMatch = !!password && !passwordRegex.test(password);
        return (<>
            <div className="default-modal__header">
                <p className="default-modal__header__title capatalize">{type.split("_").join(" ")}</p>
                <button className="default-modal__header__close" onClick={() => {
                    if (disabledSubmit) return;
                    onClose();
                }}>
                    <img src={CloseIcon} alt="close modal" />
                </button>
            </div>
            <div className="default-modal__content profile-form-modals__form">
                <form>
                    <p className="label">New Password</p>
                    <div className="login-form__form__group" style={{ marginBottom: "0px" }}>
                        <input
                            type={!!new_password ? 'text' : 'password'}
                            placeholder={texts.password}
                            name="password"
                            id="password"
                            value={password}
                            maxLength={passwordLength}
                            onChange={this._handleInputChange}
                            className={`login-form__form__input login-form__form__input--password ${!!isError ? "red" : ""} `}
                            required
                        />
                        <button
                            type="button"
                            onClick={() => this.handleTogglePasswordVisibility("password")}
                            className="login-form__form__helper-btn cursor-pointer"
                        >
                            <img src={!!new_password ? EyeOpen : EyeClose} alt="show password" />
                        </button>
                    </div>
                    <p className="label" style={{ marginTop: "20px" }}>Confirm Password</p>
                    <div className="login-form__form__group" >
                        <input
                            type={!!confirm_password ? 'text' : 'password'}
                            placeholder={texts.password_confirmation}
                            name="password_confirmation"
                            id="confirm-password"
                            value={password_confirmation}
                            maxLength={passwordLength}
                            onChange={this._handleInputChange}
                            className={`login-form__form__input login-form__form__input--password ${!!isConfirmPasswordError ? "red" : ""}`}
                            required
                        />
                        <button
                            type="button"
                            onClick={() => this.handleTogglePasswordVisibility("confirm-password")}
                            className="login-form__form__helper-btn cursor-pointer"
                        >
                            <img src={!!confirm_password ? EyeOpen : EyeClose} alt="show password" />
                        </button>
                    </div>
                    <div>
                        <ul className='profile-form-modals__form-confirm-list'>
                            {!!isConfirmPasswordError && <li className={`item ${!!isConfirmPassword ? "red" : ""}`}>New and Confirm password not matching.</li>}
                            <li className={`item ${!!isPasswordLength ? "red" : ""}`}>Min length as {newPasswordLength} characters.</li>
                            <li className={`item ${!!isPassowrdRegExMatch ? "red" : ""}`}>One Caps, One Small, One numeric and One Special character validation ex. @#%*&.</li>
                        </ul>
                    </div>
                    {errorMsg && <p className="error-text" style={{
                        textAlign: "center",
                        position: "unset",
                        margin: "0px",
                        marginTop: "10px",
                        marginBottom: "10px"
                    }}>{errorMsg}</p>}
                    <p className='info-text'>We’ll send a verification code to your email or phone number if it matches an existing Tagtaste Account.</p>
                    <hr color="#EDEDED" style={{ marginTop: "24px" }} />
                    <button type='button' className={`button  ${!!isDisabled ? "disabled" : ""}`} disabled={!!isDisabled} onClick={this._handleNewPasswordUpdate}>
                        Send OTP
                    </button>
                </form>
            </div>
        </>);
    }
}

function mapStateToProps(state) {
    return {
        token: state.login.token
    }
}

const mapDispatchToProps = {
    logoutUserLocally
}

ChangePasswordModal.propTypes = {
    onClose: PropTypes.func,
    handleSubmit: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordModal);