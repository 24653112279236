import { GET_COLLABORATION_TYPES_REQUEST,
    GET_COLLABORATION_TYPES_SUCCESS,
    GET_COLLABORATION_TYPES_FAILURE } from '../../../actions/collaborations/constants';

const INITIAL_STATE = {
    all: [],
    fetching: false,
    error: null
}

export default function types(state = INITIAL_STATE, action) {
    switch(action.type) {
        case GET_COLLABORATION_TYPES_REQUEST:
            return {
                ...state,
                fetching: true
            }
        case GET_COLLABORATION_TYPES_SUCCESS:
            return {
                ...state,
                all: action.payload,
                fetching: false
            }
        case GET_COLLABORATION_TYPES_FAILURE:
            return {
                ...state,
                fetching: false,
                error: true,
                errorMsg: action.error
            }
        default:
            return state;
    }
}