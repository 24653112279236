import { PLAY_A_POLL_REQUEST,
    PLAY_A_POLL_SUCCESS,
    PLAY_A_POLL_FAILURE,
    RENEW_A_POLL_REQUEST,
    RENEW_A_POLL_SUCCESS,
    RENEW_A_POLL_FAILURE } from './constants';
import { onRequest, onError, onSuccess, getResponseData } from '../../utils/helper';
import { getBaseUrl, pollUrls } from '../../utils/links';
import Axios from 'axios';

export const voteAPoll = (pollId,
    optionId,
    token) => {
    return dispatch => {
        dispatch(onRequest(PLAY_A_POLL_REQUEST));
        let url = `${getBaseUrl()}${pollUrls.voteAPoll(pollId)}`,
            data = {
                poll_option_id: optionId
            },
            options = {
                url,
                method: 'POST',
                data,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };
        return Axios(options)
            .then(response => {
                let successData = response
                    && response.data
                    && response.data.data
                    && !!Object.keys(response.data.data).length
                    ? response.data.data
                    : false;
                if (successData) {
                    dispatch(onSuccess(PLAY_A_POLL_SUCCESS, successData));
                    return {
                        ...successData,
                        status: true
                    };
                } else {
                    dispatch(onError(PLAY_A_POLL_FAILURE, response.data.errors))
                    return {
                        status: false,
                        error: response.data.errors
                    }
                }
            })
            .catch(error => {
                dispatch(onError(PLAY_A_POLL_FAILURE, error));
                return {
                    status: false,
                    error: `Something went wrong. Please try again.`
                }
            })
    }
}

export const renewAPoll = (pollId,
        token) => {
    return dispatch => {
        dispatch(onRequest(RENEW_A_POLL_REQUEST));
        let url = `${getBaseUrl()}${pollUrls.renew(pollId)}`,
            options = {
                url,
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };
        return Axios(options)
            .then(response => {
                let successData = getResponseData(response);
                if (successData) {
                    dispatch(onSuccess(RENEW_A_POLL_SUCCESS, {
                        pollId,
                    }));
                } else {
                    dispatch(onError(RENEW_A_POLL_FAILURE, response.data.errors))
                }
                return successData;
            })
            .catch(_ => {
                dispatch(onError(RENEW_A_POLL_FAILURE, _));
                return false;
            })
    }
}