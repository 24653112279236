import React, { useState, useEffect } from 'react';
import * as linkify from 'linkifyjs';
import { getLinkPreviewOnlyAction, getLinkPreviewOnlyActionCancelToken } from '../../actions/feed/common';
import TextareaAutosize from 'react-autosize-textarea/lib';
import SmallPreview from '../../containers/loggedIn/Feed/common/SmallPreview';
import RemovePreviewIcon from '../../assets/images/common/icons/close-white.svg';
import { pollTextLimits } from '../../utils/commonVariables';

function PollText(props) {
    const { isDisabled,
        handleTitleChange,
        token,
        handlePreview,
        handleGeneratingPreview,
        preview
    } = props,
        [text, setText] = useState(props.title || ''),
        [generatedPreview, setGeneratedPreview] = useState(preview || null),
        [fetchingPreview, setFetchingPreview] = useState(null),
        [linkForPreview, setLinkForPreview] = useState(null),
        [isMounted, setIsMounted] = useState(false);
    // checking if preview link is already generated or not
    const createPreviewIfLinkAndNotAlreadyGenetated = (text) => {
        if (!text
            // || preview
            || isDisabled
        || generatedPreview
            || (text && !text.trim())) return;
        const doesItHaveLink = linkify.find(text, 'url');
        if (doesItHaveLink
            && doesItHaveLink.length) {
            let link = doesItHaveLink[0].value;
            // if (link !== linkForPreview) {
                setLinkForPreview(link);
            // }
        }
    }
    // checking condiitons and getting preview
    const checkAndGetPreview = () => {
        if (!linkForPreview
            || fetchingPreview
            || generatedPreview
            || isDisabled) return;
        setFetchingPreview(true);
        handleGeneratingPreview(true);
        const data = {
            url: linkForPreview
        }
        getLinkPreviewOnlyAction(token,
            data)
            .then(_ => {
                setFetchingPreview(false);
                handleGeneratingPreview(false);
                if (_
                    && !Array.isArray(_)
                    && typeof _ === "object") {
                    setGeneratedPreview(_);
                } else {
                    setGeneratedPreview(null);
                }
            })
    }
    // listen to link in preview changes
    useEffect(() => {
        if (linkForPreview) checkAndGetPreview();
    }, [linkForPreview]);
    // updating preview in parent prop
    useEffect(() => {
        handlePreview(generatedPreview);
    }, [generatedPreview]);
    useEffect(() => {
        setIsMounted(true);
        return () => {
            if (getLinkPreviewOnlyActionCancelToken) getLinkPreviewOnlyActionCancelToken();
        }
    }, []);
    // handle text change
    useEffect(() => {
        handleTitleChange(text);
        if (isMounted) {
            createPreviewIfLinkAndNotAlreadyGenetated(text);
        }
    }, [text]);

    return (<>
        <TextareaAutosize className="poll-create-container__title"
            type="text"
            name="title"
            placeholder="Your question..."
            value={text}
            onChange={e => setText(e.target.value)}
            autoComplete="off"
            maxLength={pollTextLimits.title}
            disabled={isDisabled}
            autoFocus={!isDisabled} />
        { fetchingPreview && <p className="poll-create-container__preview__helper">Fetching preview...</p> }
        {
            generatedPreview
            && <div className="poll-create-container__preview__container">
                <SmallPreview preview={generatedPreview} />
                {
                    !isDisabled && <button onClick={() => setGeneratedPreview(null)} className="post-bar__post__preview-container__close">
                        <img src={RemovePreviewIcon} alt="remove preview" />
                    </button>
                }
            </div>
        }
    </>)
};

export default PollText;